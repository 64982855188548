package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryButtonModel
import com.ustadmobile.lib.db.entities.ContentEntryContentJobItemParams
import com.ustadmobile.lib.db.entities.ContentEntryWithBlockAndLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentJobItemProgressAndStatus
import com.ustadmobile.lib.db.entities.DistinctCategorySchema
import com.ustadmobile.lib.db.entities.DownloadJobSizeInfo
import com.ustadmobile.lib.db.entities.LangUidAndName
import com.ustadmobile.lib.db.entities.Language
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContentEntryDao : BaseDao<ContentEntry> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(entityList: List<ContentEntry>): Unit

  public actual abstract suspend fun findEntryWithLanguageByEntryIdAsync(entryUuid: Long):
      ContentEntryWithLanguage?

  public actual abstract suspend fun findEntryWithBlockAndLanguageByUidAsync(entityUid: Long):
      ContentEntryWithBlockAndLanguage?

  public actual abstract suspend fun findEntryWithContainerByEntryId(entryUuid: Long):
      ContentEntryWithMostRecentContainer?

  public actual abstract fun findEntryWithContainerByEntryIdLive(entryUuid: Long):
      LiveData<ContentEntryWithMostRecentContainer?>

  public actual abstract fun findBySourceUrl(sourceUrl: String): ContentEntry?

  public actual abstract suspend fun findTitleByUidAsync(contentEntryUid: Long): String?

  public actual abstract fun getChildrenByParentUid(parentUid: Long):
      DataSourceFactory<Int, ContentEntry>

  public actual abstract suspend fun getChildrenByParentAsync(parentUid: Long): List<ContentEntry>

  public actual abstract suspend fun getContentJobItemParamsByParentUid(
    parentUid: Long,
    limit: Int,
    offset: Int,
  ): List<ContentEntryContentJobItemParams>

  public actual abstract suspend fun getCountNumberOfChildrenByParentUUidAsync(parentUid: Long): Int

  public actual abstract suspend fun getContentByUuidAsync(parentUid: Long): ContentEntry?

  public actual abstract suspend fun findAllLanguageRelatedEntriesAsync(entryUuid: Long):
      List<ContentEntry>

  public actual abstract suspend fun findListOfCategoriesAsync(parentUid: Long):
      List<DistinctCategorySchema>

  public actual abstract suspend fun findUniqueLanguagesInListAsync(parentUid: Long): List<Language>

  public actual abstract suspend fun findUniqueLanguageWithParentUid(parentUid: Long):
      List<LangUidAndName>

  public actual abstract override fun update(entity: ContentEntry): Unit

  public actual abstract suspend fun findByUidAsync(entryUid: Long): ContentEntry?

  public actual abstract suspend fun findByUidWithLanguageAsync(uid: Long):
      ContentEntryWithLanguage?

  public actual abstract fun findByUid(entryUid: Long): ContentEntry?

  public actual abstract fun findByTitle(title: String): LiveData<ContentEntry?>

  public actual abstract suspend fun isMeteredAllowedForEntry(contentEntryUid: Long): Boolean

  public actual abstract suspend fun findBySourceUrlWithContentEntryStatusAsync(sourceUrl: String):
      ContentEntry?

  public actual abstract fun getChildrenByParentUidWithCategoryFilterOrderByName(
    parentUid: Long,
    langParam: Long,
    categoryParam0: Long,
    personUid: Long,
    showHidden: Boolean,
    onlyFolder: Boolean,
    sortOrder: Int,
  ): DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>

  public actual abstract fun getContentFromMyCourses(personUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>

  public actual abstract fun getContentByOwner(personUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>

  public actual abstract suspend fun updateAsync(entity: ContentEntry): Int

  public actual abstract fun getChildrenByAll(parentUid: Long): List<ContentEntry>

  public actual abstract fun findLiveContentEntry(parentUid: Long): LiveData<ContentEntry?>

  public actual abstract fun getContentEntryUidFromXapiObjectId(objectId: String): Long

  public actual abstract fun findSimilarIdEntryForKhan(sourceUrl: String): List<ContentEntry>

  public actual abstract suspend fun getRecursiveDownloadTotals(contentEntryUid: Long):
      DownloadJobSizeInfo?

  public actual abstract fun getAllEntriesRecursively(contentEntryUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndMostRecentContainer>

  public actual abstract fun getAllEntriesRecursivelyAsList(contentEntryUid: Long):
      List<ContentEntryWithParentChildJoinAndMostRecentContainer>

  public actual abstract fun updateContentEntryInActive(
    contentEntryUid: Long,
    ceInactive: Boolean,
    changedTime: Long,
  ): Unit

  public actual abstract fun updateContentEntryContentFlag(
    contentFlag: Int,
    contentEntryUid: Long,
    changedTime: Long,
  ): Unit

  public actual abstract fun replaceList(entries: List<ContentEntry>): Unit

  public actual abstract suspend fun getContentEntryFromUids(contentEntryUids: List<Long>):
      List<UidAndLabel>

  public actual abstract fun insertWithReplace(entry: ContentEntry): Unit

  public actual abstract fun findAllLive(): LiveData<List<ContentEntryWithLanguage>>

  public actual abstract suspend fun personHasPermissionWithContentEntry(
    accountPersonUid: Long,
    contentEntryUid: Long,
    permission: Long,
  ): Boolean

  public actual abstract suspend fun updateContentEntryActiveByContentJobUid(
    jobId: Long,
    inactive: Boolean,
    changedTime: Long,
  ): Unit

  public actual abstract suspend fun toggleVisibilityContentEntryItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long,
  ): Unit

  public actual abstract suspend
      fun findContentEntriesWhereIsLeafAndLatestContainerHasNoEntriesOrHasZeroFileSize():
      List<ContentEntry>

  public actual abstract suspend fun buttonsToShowForContentEntry(contentEntryUid: Long,
      platformDownloadEnabled: Boolean): ContentEntryButtonModel?

  public actual abstract suspend fun statusForDownloadDialog(contentEntryUid: Long): Int

  public actual abstract suspend fun statusForContentEntryList(contentEntryUid: Long):
      ContentJobItemProgressAndStatus?
}
