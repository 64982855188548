package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.UserSession
import com.ustadmobile.lib.db.entities.UserSessionAndPerson
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class UserSessionDao_JdbcKt(
  public val _db: RoomDatabase,
) : UserSessionDao() {
  public val _insertAdapterUserSession_: EntityInsertionAdapter<UserSession> = object :
      EntityInsertionAdapter<UserSession>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO UserSession (usUid, usPcsn, usLcsn, usLcb, usLct, usPersonUid, usClientNodeId, usStartTime, usEndTime, usStatus, usReason, usAuth, usSessionType) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: UserSession):
        Unit {
      if(entity.usUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.usUid)
      }
      stmt.setLong(2, entity.usPcsn)
      stmt.setLong(3, entity.usLcsn)
      stmt.setInt(4, entity.usLcb)
      stmt.setLong(5, entity.usLct)
      stmt.setLong(6, entity.usPersonUid)
      stmt.setLong(7, entity.usClientNodeId)
      stmt.setLong(8, entity.usStartTime)
      stmt.setLong(9, entity.usEndTime)
      stmt.setInt(10, entity.usStatus)
      stmt.setInt(11, entity.usReason)
      stmt.setString(12, entity.usAuth)
      stmt.setInt(13, entity.usSessionType)
    }
  }

  public override suspend fun insertSession(session: UserSession): Long {
    val _retVal = _insertAdapterUserSession_.insertAndReturnIdAsync(session)
    return _retVal
  }

  public override suspend fun updateReplicationTrackers(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM ChangeLog
    |                JOIN UserSession UserSessionSubject
    |                     ON ChangeLog.chTableId = 679
    |                        AND ChangeLog.chEntityPk = UserSessionSubject.usUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                JOIN Person
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |                    64
    |                    /* Modify second part of query - remove requirement for session to be active.
    |                     * This ensures that deactivations are distributed
    |                     */
    |                    ) > 0
    |                     JOIN PersonGroupMember AS PrsGrpMbr
    |                          ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                     JOIN UserSession
    |                          ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |          WHERE UserSessionSubject.usClientNodeId = UserSessionSubject.usClientNodeId                
    |          --notpsql              
    |            AND UserSessionSubject.usLct != COALESCE(
    |                (SELECT usVersionId
    |                   FROM UserSessionReplicate
    |                  WHERE UserSessionReplicate.usPk = UserSessionSubject.usUid
    |                    AND UserSessionReplicate.usDestination = UserSession.usClientNodeId), 0)
    |          --endnotpsql                       
    |        /*psql ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |         */         
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM ChangeLog
    |                JOIN UserSession UserSessionSubject
    |                     ON ChangeLog.chTableId = 679
    |                        AND ChangeLog.chEntityPk = UserSessionSubject.usUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                JOIN Person
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |                    64
    |                    /* Modify second part of query - remove requirement for session to be active.
    |                     * This ensures that deactivations are distributed
    |                     */
    |                    ) > 0
    |                     JOIN PersonGroupMember AS PrsGrpMbr
    |                          ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                     JOIN UserSession
    |                          ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |          WHERE UserSessionSubject.usClientNodeId = UserSessionSubject.usClientNodeId                
    |         ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |                  
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateReplicationTrackersOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM UserSession 
    |                JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |                JOIN UserSession UserSessionSubject
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                        AND UserSessionSubject.usClientNodeId = ?
    |          WHERE UserSession.usClientNodeId = ?
    |          --notpsql
    |            AND UserSessionSubject.usLct != COALESCE(
    |                (SELECT usVersionId
    |                   FROM UserSessionReplicate
    |                  WHERE UserSessionReplicate.usPk = UserSessionSubject.usUid
    |                    AND UserSessionReplicate.usDestination = UserSession.usClientNodeId), 0)
    |          --endnotpsql          
    |         /*psql ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |         */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM UserSession 
    |                JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |                JOIN UserSession UserSessionSubject
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                        AND UserSessionSubject.usClientNodeId = ?
    |          WHERE UserSession.usClientNodeId = ?
    |          ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findSessionsByPerson(personUid: Long): List<UserSession> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT UserSession.*
  |          FROM UserSession
  |         WHERE usPersonUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_usUid = _result.getLong("usUid")
        val _tmp_usPcsn = _result.getLong("usPcsn")
        val _tmp_usLcsn = _result.getLong("usLcsn")
        val _tmp_usLcb = _result.getInt("usLcb")
        val _tmp_usLct = _result.getLong("usLct")
        val _tmp_usPersonUid = _result.getLong("usPersonUid")
        val _tmp_usClientNodeId = _result.getLong("usClientNodeId")
        val _tmp_usStartTime = _result.getLong("usStartTime")
        val _tmp_usEndTime = _result.getLong("usEndTime")
        val _tmp_usStatus = _result.getInt("usStatus")
        val _tmp_usReason = _result.getInt("usReason")
        val _tmp_usAuth = _result.getString("usAuth")
        val _tmp_usSessionType = _result.getInt("usSessionType")
        UserSession().apply {
          this.usUid = _tmp_usUid
          this.usPcsn = _tmp_usPcsn
          this.usLcsn = _tmp_usLcsn
          this.usLcb = _tmp_usLcb
          this.usLct = _tmp_usLct
          this.usPersonUid = _tmp_usPersonUid
          this.usClientNodeId = _tmp_usClientNodeId
          this.usStartTime = _tmp_usStartTime
          this.usEndTime = _tmp_usEndTime
          this.usStatus = _tmp_usStatus
          this.usReason = _tmp_usReason
          this.usAuth = _tmp_usAuth
          this.usSessionType = _tmp_usSessionType
        }
      }
    }
  }

  public override fun findAllLocalSessionsLive(): LiveData<List<UserSessionAndPerson>> =
      LiveDataImpl(_db, listOf("UserSession", "Person", "SyncNode"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |            SELECT UserSession.*, Person.*
    |              FROM UserSession
    |                   JOIN Person ON UserSession.usPersonUid = Person.personUid
    |             WHERE UserSession.usClientNodeId = (
    |                   SELECT COALESCE(
    |                          (SELECT nodeClientId 
    |                            FROM SyncNode
    |                           LIMIT 1), 0))
    |               AND UserSession.usStatus = 1        
    |            
    """.trimMargin() )) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_Person_nullCount = 0
          val _tmp_personUid = _result.getLong("personUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_username = _result.getString("username")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_firstNames = _result.getString("firstNames")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_lastName = _result.getString("lastName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_emailAddr = _result.getString("emailAddr")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_phoneNum = _result.getString("phoneNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_gender = _result.getInt("gender")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_active = _result.getBoolean("active")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_admin = _result.getBoolean("admin")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personNotes = _result.getString("personNotes")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherName = _result.getString("fatherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherName = _result.getString("motherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherNum = _result.getString("motherNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personAddress = _result.getString("personAddress")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personOrgId = _result.getString("personOrgId")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLct = _result.getLong("personLct")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personCountry = _result.getString("personCountry")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personType = _result.getInt("personType")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
          var _tmp_UserSession_nullCount = 0
          val _tmp_usUid = _result.getLong("usUid")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usPcsn = _result.getLong("usPcsn")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usLcsn = _result.getLong("usLcsn")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usLcb = _result.getInt("usLcb")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usLct = _result.getLong("usLct")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usPersonUid = _result.getLong("usPersonUid")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usClientNodeId = _result.getLong("usClientNodeId")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usStartTime = _result.getLong("usStartTime")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usEndTime = _result.getLong("usEndTime")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usStatus = _result.getInt("usStatus")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usReason = _result.getInt("usReason")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usAuth = _result.getString("usAuth")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_usSessionType = _result.getInt("usSessionType")
          if(_result.wasNull()) _tmp_UserSession_nullCount++
          val _tmp_UserSession_isAllNull = _tmp_UserSession_nullCount == 13
          UserSessionAndPerson().apply {
            if(!_tmp_Person_isAllNull) {
              this.person = Person().apply {
                this.personUid = _tmp_personUid
                this.username = _tmp_username
                this.firstNames = _tmp_firstNames
                this.lastName = _tmp_lastName
                this.emailAddr = _tmp_emailAddr
                this.phoneNum = _tmp_phoneNum
                this.gender = _tmp_gender
                this.active = _tmp_active
                this.admin = _tmp_admin
                this.personNotes = _tmp_personNotes
                this.fatherName = _tmp_fatherName
                this.fatherNumber = _tmp_fatherNumber
                this.motherName = _tmp_motherName
                this.motherNum = _tmp_motherNum
                this.dateOfBirth = _tmp_dateOfBirth
                this.personAddress = _tmp_personAddress
                this.personOrgId = _tmp_personOrgId
                this.personGroupUid = _tmp_personGroupUid
                this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                this.personLastChangedBy = _tmp_personLastChangedBy
                this.personLct = _tmp_personLct
                this.personCountry = _tmp_personCountry
                this.personType = _tmp_personType
              }
            }
            if(!_tmp_UserSession_isAllNull) {
              this.userSession = UserSession().apply {
                this.usUid = _tmp_usUid
                this.usPcsn = _tmp_usPcsn
                this.usLcsn = _tmp_usLcsn
                this.usLcb = _tmp_usLcb
                this.usLct = _tmp_usLct
                this.usPersonUid = _tmp_usPersonUid
                this.usClientNodeId = _tmp_usClientNodeId
                this.usStartTime = _tmp_usStartTime
                this.usEndTime = _tmp_usEndTime
                this.usStatus = _tmp_usStatus
                this.usReason = _tmp_usReason
                this.usAuth = _tmp_usAuth
                this.usSessionType = _tmp_usSessionType
              }
            }
          }
        }
      }
    }
  }

  public override suspend fun findAllLocalSessionsAsync(): List<UserSessionAndPerson> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |            SELECT UserSession.*, Person.*
  |              FROM UserSession
  |                   JOIN Person ON UserSession.usPersonUid = Person.personUid
  |             WHERE UserSession.usClientNodeId = (
  |                   SELECT COALESCE(
  |                          (SELECT nodeClientId 
  |                            FROM SyncNode
  |                           LIMIT 1), 0))
  |               AND UserSession.usStatus = 1        
  |            
  """.trimMargin() )) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        var _tmp_UserSession_nullCount = 0
        val _tmp_usUid = _result.getLong("usUid")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usPcsn = _result.getLong("usPcsn")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usLcsn = _result.getLong("usLcsn")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usLcb = _result.getInt("usLcb")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usLct = _result.getLong("usLct")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usPersonUid = _result.getLong("usPersonUid")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usClientNodeId = _result.getLong("usClientNodeId")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usStartTime = _result.getLong("usStartTime")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usEndTime = _result.getLong("usEndTime")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usStatus = _result.getInt("usStatus")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usReason = _result.getInt("usReason")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usAuth = _result.getString("usAuth")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_usSessionType = _result.getInt("usSessionType")
        if(_result.wasNull()) _tmp_UserSession_nullCount++
        val _tmp_UserSession_isAllNull = _tmp_UserSession_nullCount == 13
        UserSessionAndPerson().apply {
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
          if(!_tmp_UserSession_isAllNull) {
            this.userSession = UserSession().apply {
              this.usUid = _tmp_usUid
              this.usPcsn = _tmp_usPcsn
              this.usLcsn = _tmp_usLcsn
              this.usLcb = _tmp_usLcb
              this.usLct = _tmp_usLct
              this.usPersonUid = _tmp_usPersonUid
              this.usClientNodeId = _tmp_usClientNodeId
              this.usStartTime = _tmp_usStartTime
              this.usEndTime = _tmp_usEndTime
              this.usStatus = _tmp_usStatus
              this.usReason = _tmp_usReason
              this.usAuth = _tmp_usAuth
              this.usSessionType = _tmp_usSessionType
            }
          }
        }
      }
    }
  }

  public override suspend fun countAllLocalSessionsAsync(maxDateOfBirth: Long): Int =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT COUNT(*)
  |          FROM UserSession
  |               JOIN Person 
  |                    ON UserSession.usPersonUid = Person.personUid
  |         WHERE UserSession.usClientNodeId = (
  |                   SELECT COALESCE(
  |                          (SELECT nodeClientId 
  |                            FROM SyncNode
  |                           LIMIT 1), 0))
  |           AND UserSession.usStatus = 1                
  |           AND (? = 0 OR Person.dateOfBirth < ?)                 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,maxDateOfBirth)
    _stmt.setLong(2,maxDateOfBirth)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  public override suspend fun endSession(
    sessionUid: Long,
    newStatus: Int,
    reason: Int,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE UserSession
    |           SET usAuth = null,
    |               usStatus = ?,
    |               usReason = ?,
    |               usLcb = COALESCE(
    |                               (SELECT nodeClientId
    |                                  FROM SyncNode
    |                                 LIMIT 1), 0)
    |         WHERE UserSession.usUid = ?                        
    |               
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,newStatus)
      _stmt.setInt(2,reason)
      _stmt.setLong(3,sessionUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUidLive(sessionUid: Long): LiveData<UserSession?> = LiveDataImpl(_db,
      listOf("UserSession"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT UserSession.*
    |          FROM UserSession
    |         WHERE UserSession.usUid = ?
    |         LIMIT 1
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,sessionUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_usUid = _result.getLong("usUid")
          val _tmp_usPcsn = _result.getLong("usPcsn")
          val _tmp_usLcsn = _result.getLong("usLcsn")
          val _tmp_usLcb = _result.getInt("usLcb")
          val _tmp_usLct = _result.getLong("usLct")
          val _tmp_usPersonUid = _result.getLong("usPersonUid")
          val _tmp_usClientNodeId = _result.getLong("usClientNodeId")
          val _tmp_usStartTime = _result.getLong("usStartTime")
          val _tmp_usEndTime = _result.getLong("usEndTime")
          val _tmp_usStatus = _result.getInt("usStatus")
          val _tmp_usReason = _result.getInt("usReason")
          val _tmp_usAuth = _result.getString("usAuth")
          val _tmp_usSessionType = _result.getInt("usSessionType")
          UserSession().apply {
            this.usUid = _tmp_usUid
            this.usPcsn = _tmp_usPcsn
            this.usLcsn = _tmp_usLcsn
            this.usLcb = _tmp_usLcb
            this.usLct = _tmp_usLct
            this.usPersonUid = _tmp_usPersonUid
            this.usClientNodeId = _tmp_usClientNodeId
            this.usStartTime = _tmp_usStartTime
            this.usEndTime = _tmp_usEndTime
            this.usStatus = _tmp_usStatus
            this.usReason = _tmp_usReason
            this.usAuth = _tmp_usAuth
            this.usSessionType = _tmp_usSessionType
          }
        }
      }
    }
  }

  public override suspend fun endOtherSessions(
    personUid: Long,
    exemptNodeId: Long,
    newStatus: Int,
    reason: Int,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE UserSession
    |           SET usAuth = null,
    |               usStatus = ?,
    |               usReason = ?,
    |               usLct = ?
    |         WHERE usPersonUid = ?
    |           AND usClientNodeId != ?
    |           AND usStatus != ?                     
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,newStatus)
      _stmt.setInt(2,reason)
      _stmt.setLong(3,changeTime)
      _stmt.setLong(4,personUid)
      _stmt.setLong(5,exemptNodeId)
      _stmt.setInt(6,newStatus)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findActiveNodeIdsByPersonUids(personUids: List<Long>): List<Long> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT DISTINCT UserSession.usClientNodeId
  |          FROM UserSession
  |         WHERE UserSession.usPersonUid IN (?)
  |           AND UserSession.usStatus = 1
  |    
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        personUids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun findActiveNodesIdsByGroupUids(groupUids: List<Long>): List<Long> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT DISTINCT UserSession.usClientNodeId
  |          FROM UserSession
  |               JOIN PersonGroupMember 
  |                    ON PersonGroupMember.groupMemberPersonUid = UserSession.usPersonUid
  |         WHERE PersonGroupMember.groupMemberGroupUid IN (?)            
  |    
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        groupUids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun findAllActiveNodeIdsWithClazzBasedPermission(clazzUids: List<Long>):
      List<Long> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT UserSession.usClientNodeId
  |          FROM ScopedGrant
  |               JOIN PersonGroupMember 
  |                    ON PersonGroupMember.groupMemberGroupUid = ScopedGrant.sgGroupUid
  |               JOIN UserSession
  |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
  |         WHERE (ScopedGrant.sgTableId = 6 AND ScopedGrant.sgEntityUid IN (?))
  |            OR (ScopedGrant.sgTableId = 164 AND ScopedGrant.sgEntityUid IN 
  |                (SELECT clazzSchoolUid
  |                   FROM Clazz
  |                  WHERE clazzUid IN (?)))
  |          
  |    
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        clazzUids.toTypedArray()))
    _stmt.setArray(2, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        clazzUids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids: List<Long>):
      List<Long> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT UserSession.usClientNodeId
  |          FROM ScopedGrant
  |               JOIN PersonGroupMember 
  |                    ON PersonGroupMember.groupMemberGroupUid = ScopedGrant.sgGroupUid
  |               JOIN UserSession
  |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
  |         WHERE ScopedGrant.sgTableId = 164 
  |           AND ScopedGrant.sgEntityUid IN (?) 
  |    
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        schoolUids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }
}
