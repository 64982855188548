package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.AgentEntity
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class AgentDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: AgentDao,
) : AgentDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun getAgentByAnyId(
    openId: String?,
    mbox: String?,
    account: String?,
    homepage: String?,
    sha1: String?,
  ): AgentEntity? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun getAgentFromPersonUsername(endpoint: String, username: String):
      AgentEntity? = _dao.getAgentFromPersonUsername(endpoint, username)

  public override fun insert(entity: AgentEntity): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: AgentEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.agentUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(68)
      entity.agentUid = _newPk
    }
    entity.agentLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.agentUid
  }

  public override fun insertList(entityList: List<AgentEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<AgentEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: AgentEntity): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
