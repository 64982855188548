package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.PersonGroup
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class PersonGroupDao : BaseDao<PersonGroup> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun replicateOnChangeClazzBased(): Unit

  public actual abstract suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChangePersonBased(): Unit

  public actual abstract suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChangeSchoolBased(): Unit

  public actual abstract suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit

  public actual abstract fun findByUid(uid: Long): PersonGroup?

  public actual abstract suspend fun findByUidAsync(uid: Long): PersonGroup?

  public actual abstract fun findByUidLive(uid: Long): LiveData<PersonGroup?>

  public actual abstract suspend fun updateAsync(entity: PersonGroup): Int

  public actual abstract suspend fun findNameByGroupUid(groupUid: Long): String?
}
