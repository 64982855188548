package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContainerEntry
import com.ustadmobile.lib.db.entities.ContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryWithContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryWithMd5
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContainerEntryDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContainerEntryDao() {
  public val _insertAdapterContainerEntry_: EntityInsertionAdapter<ContainerEntry> = object :
      EntityInsertionAdapter<ContainerEntry>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ContainerEntry (ceUid, ceContainerUid, cePath, ceCefUid) VALUES(?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContainerEntry):
        Unit {
      if(entity.ceUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ceUid)
      }
      stmt.setLong(2, entity.ceContainerUid)
      stmt.setString(3, entity.cePath)
      stmt.setLong(4, entity.ceCefUid)
    }
  }

  public override suspend fun insertListAsync(containerEntryList: List<ContainerEntry>): Unit {
    _insertAdapterContainerEntry_.insertListAsync(containerEntryList)
  }

  public override fun insert(entity: ContainerEntry): Long {
    val _retVal = _insertAdapterContainerEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerEntry): Long {
    val _retVal = _insertAdapterContainerEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContainerEntry>): Unit {
    _insertAdapterContainerEntry_.insertList(entityList)
  }

  public override fun updateList(entityList: List<ContainerEntry>): Unit {
    val _sql =
        "UPDATE ContainerEntry SET ceContainerUid = ?, cePath = ?, ceCefUid = ? WHERE ceUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.ceContainerUid)
        _stmt.setString(2, _entity.cePath)
        _stmt.setLong(3, _entity.ceCefUid)
        _stmt.setLong(4, _entity.ceUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContainerEntry): Unit {
    val _sql =
        "UPDATE ContainerEntry SET ceContainerUid = ?, cePath = ?, ceCefUid = ? WHERE ceUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ceContainerUid)
      _stmt.setString(2, entity.cePath)
      _stmt.setLong(3, entity.ceCefUid)
      _stmt.setLong(4, entity.ceUid)
      _stmt.executeUpdate()
    }
  }

  public override fun deleteList(entries: List<ContainerEntry>): Unit {
    var _numChanges = 0
    _db.prepareAndUseStatement("DELETE FROM ContainerEntry WHERE ceUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entries) {
        _stmt.setLong(1, _entity.ceUid)
        _numChanges += _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun findByContainer(containerUid: Long):
      List<ContainerEntryWithContainerEntryFile> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        var _tmp_ContainerEntryFile_nullCount = 0
        val _tmp_cefUid = _result.getLong("cefUid")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefMd5 = _result.getString("cefMd5")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefPath = _result.getString("cefPath")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_compression = _result.getInt("compression")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ContainerEntryFile_isAllNull = _tmp_ContainerEntryFile_nullCount == 7
        ContainerEntryWithContainerEntryFile().apply {
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
          if(!_tmp_ContainerEntryFile_isAllNull) {
            this.containerEntryFile = ContainerEntryFile().apply {
              this.cefUid = _tmp_cefUid
              this.cefMd5 = _tmp_cefMd5
              this.cefPath = _tmp_cefPath
              this.ceTotalSize = _tmp_ceTotalSize
              this.ceCompressedSize = _tmp_ceCompressedSize
              this.compression = _tmp_compression
              this.lastModified = _tmp_lastModified
            }
          }
        }
      }
    }
  }

  public override fun findByPathInContainer(containerUid: Long, pathInContainer: String):
      ContainerEntryWithContainerEntryFile? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ? AND ContainerEntry.cePath = ?"
      )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.setString(2,pathInContainer)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        var _tmp_ContainerEntryFile_nullCount = 0
        val _tmp_cefUid = _result.getLong("cefUid")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefMd5 = _result.getString("cefMd5")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefPath = _result.getString("cefPath")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_compression = _result.getInt("compression")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ContainerEntryFile_isAllNull = _tmp_ContainerEntryFile_nullCount == 7
        ContainerEntryWithContainerEntryFile().apply {
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
          if(!_tmp_ContainerEntryFile_isAllNull) {
            this.containerEntryFile = ContainerEntryFile().apply {
              this.cefUid = _tmp_cefUid
              this.cefMd5 = _tmp_cefMd5
              this.cefPath = _tmp_cefPath
              this.ceTotalSize = _tmp_ceTotalSize
              this.ceCompressedSize = _tmp_ceCompressedSize
              this.compression = _tmp_compression
              this.lastModified = _tmp_lastModified
            }
          }
        }
      }
    }
  }

  public override fun findByContainerWithMd5(containerUid: Long): List<ContainerEntryWithMd5> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.cefMd5 AS cefMd5 FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        ContainerEntryWithMd5().apply {
          this.cefMd5 = _tmp_cefMd5
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
        }
      }
    }
  }

  public override suspend fun findByContainerAsync(containerUid: Long):
      List<ContainerEntryWithContainerEntryFile> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        var _tmp_ContainerEntryFile_nullCount = 0
        val _tmp_cefUid = _result.getLong("cefUid")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefMd5 = _result.getString("cefMd5")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefPath = _result.getString("cefPath")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_compression = _result.getInt("compression")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ContainerEntryFile_isAllNull = _tmp_ContainerEntryFile_nullCount == 7
        ContainerEntryWithContainerEntryFile().apply {
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
          if(!_tmp_ContainerEntryFile_isAllNull) {
            this.containerEntryFile = ContainerEntryFile().apply {
              this.cefUid = _tmp_cefUid
              this.cefMd5 = _tmp_cefMd5
              this.cefPath = _tmp_cefPath
              this.ceTotalSize = _tmp_ceTotalSize
              this.ceCompressedSize = _tmp_ceCompressedSize
              this.compression = _tmp_compression
              this.lastModified = _tmp_lastModified
            }
          }
        }
      }
    }
  }

  public override fun deleteByContainerUid(containerUid: Long): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("DELETE FROM ContainerEntry WHERE ceContainerUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,containerUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun deleteContainerEntriesCreatedByJobs(jobId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |            DELETE FROM ContainerEntry 
    |             WHERE ceContainerUid 
    |                IN (SELECT cjiContainerUid 
    |                      FROM ContentJobItem
    |                      JOIN ContentJob 
    |                           ON ContentJobItem.cjiJobUid = ContentJob.cjUid
    |                     WHERE ContentJob.cjUid = ?)
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,jobId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun deleteByContentEntryUid(contentEntryUid: Long): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("""
    |
    |        DELETE FROM ContainerEntry
    |         WHERE ceContainerUid
    |            IN (SELECT containerUid
    |                  FROM Container
    |                 WHERE containerContentEntryUid = ?) 
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertWithMd5SumsAsync(
    containerUid: Long,
    path: String,
    md5: String,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        INSERT INTO ContainerEntry(ceContainerUid, cePath, ceCefUid) 
    |        SELECT ? AS ceContainerUid, ? AS cePath, 
    |               (SELECT COALESCE(
    |                      (SELECT cefUid 
    |                         FROM ContainerEntryFile
    |                        WHERE cefMd5 = ?
    |                        LIMIT 1), 0))  
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,containerUid)
      _stmt.setString(2,path)
      _stmt.setString(3,md5)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
