package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzLogAttendanceRecordDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzLogAttendanceRecordDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzLogAttendanceRecordDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entities: List<ClazzLogAttendanceRecord>): Unit {
    _dao.insertListAsync(entities)
  }

  public override fun findByUid(uid: Long): ClazzLogAttendanceRecord? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun updateListAsync(entities: List<ClazzLogAttendanceRecord>): Unit {
    _dao.updateListAsync(entities)
  }

  public override suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson> {
    val _result = _dao.findByClazzLogUid(clazzLogUid)
    return _result
  }

  public override fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long,
  ): Unit {
    _dao.updateRescheduledClazzLogUids(oldClazzLogUid, newClazzLogUid, changedTime)
  }

  public override fun insert(entity: ClazzLogAttendanceRecord): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzLogAttendanceRecord): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<ClazzLogAttendanceRecord>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<ClazzLogAttendanceRecord>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ClazzLogAttendanceRecord): Unit {
    _dao.update(entity)
  }
}
