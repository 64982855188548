package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class DiscussionPostDao_JdbcKt(
  public val _db: RoomDatabase,
) : DiscussionPostDao() {
  public val _insertAdapterDiscussionPost_: EntityInsertionAdapter<DiscussionPost> = object :
      EntityInsertionAdapter<DiscussionPost>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO DiscussionPost (discussionPostUid, discussionPostTitle, discussionPostMessage, discussionPostStartDate, discussionPostDiscussionTopicUid, discussionPostVisible, discussionPostArchive, discussionPostStartedPersonUid, discussionPostClazzUid, discussionPostLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: DiscussionPost):
        Unit {
      if(entity.discussionPostUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.discussionPostUid)
      }
      stmt.setString(2, entity.discussionPostTitle)
      stmt.setString(3, entity.discussionPostMessage)
      stmt.setLong(4, entity.discussionPostStartDate)
      stmt.setLong(5, entity.discussionPostDiscussionTopicUid)
      stmt.setBoolean(6, entity.discussionPostVisible)
      stmt.setBoolean(7, entity.discussionPostArchive)
      stmt.setLong(8, entity.discussionPostStartedPersonUid)
      stmt.setLong(9, entity.discussionPostClazzUid)
      stmt.setLong(10, entity.discussionPostLct)
    }
  }

  public override fun insert(entity: DiscussionPost): Long {
    val _retVal = _insertAdapterDiscussionPost_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: DiscussionPost): Long {
    val _retVal = _insertAdapterDiscussionPost_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<DiscussionPost>): Unit {
    _insertAdapterDiscussionPost_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: DiscussionPost): Int {
    var _result = 0
    val _sql =
        "UPDATE DiscussionPost SET discussionPostTitle = ?, discussionPostMessage = ?, discussionPostStartDate = ?, discussionPostDiscussionTopicUid = ?, discussionPostVisible = ?, discussionPostArchive = ?, discussionPostStartedPersonUid = ?, discussionPostClazzUid = ?, discussionPostLct = ? WHERE discussionPostUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.discussionPostTitle)
      _stmt.setString(2, entity.discussionPostMessage)
      _stmt.setLong(3, entity.discussionPostStartDate)
      _stmt.setLong(4, entity.discussionPostDiscussionTopicUid)
      _stmt.setBoolean(5, entity.discussionPostVisible)
      _stmt.setBoolean(6, entity.discussionPostArchive)
      _stmt.setLong(7, entity.discussionPostStartedPersonUid)
      _stmt.setLong(8, entity.discussionPostClazzUid)
      _stmt.setLong(9, entity.discussionPostLct)
      _stmt.setLong(10, entity.discussionPostUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<DiscussionPost>): Unit {
    val _sql =
        "UPDATE DiscussionPost SET discussionPostTitle = ?, discussionPostMessage = ?, discussionPostStartDate = ?, discussionPostDiscussionTopicUid = ?, discussionPostVisible = ?, discussionPostArchive = ?, discussionPostStartedPersonUid = ?, discussionPostClazzUid = ?, discussionPostLct = ? WHERE discussionPostUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.discussionPostTitle)
        _stmt.setString(2, _entity.discussionPostMessage)
        _stmt.setLong(3, _entity.discussionPostStartDate)
        _stmt.setLong(4, _entity.discussionPostDiscussionTopicUid)
        _stmt.setBoolean(5, _entity.discussionPostVisible)
        _stmt.setBoolean(6, _entity.discussionPostArchive)
        _stmt.setLong(7, _entity.discussionPostStartedPersonUid)
        _stmt.setLong(8, _entity.discussionPostClazzUid)
        _stmt.setLong(9, _entity.discussionPostLct)
        _stmt.setLong(10, _entity.discussionPostUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: DiscussionPost): Unit {
    val _sql =
        "UPDATE DiscussionPost SET discussionPostTitle = ?, discussionPostMessage = ?, discussionPostStartDate = ?, discussionPostDiscussionTopicUid = ?, discussionPostVisible = ?, discussionPostArchive = ?, discussionPostStartedPersonUid = ?, discussionPostClazzUid = ?, discussionPostLct = ? WHERE discussionPostUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.discussionPostTitle)
      _stmt.setString(2, entity.discussionPostMessage)
      _stmt.setLong(3, entity.discussionPostStartDate)
      _stmt.setLong(4, entity.discussionPostDiscussionTopicUid)
      _stmt.setBoolean(5, entity.discussionPostVisible)
      _stmt.setBoolean(6, entity.discussionPostArchive)
      _stmt.setLong(7, entity.discussionPostStartedPersonUid)
      _stmt.setLong(8, entity.discussionPostClazzUid)
      _stmt.setLong(9, entity.discussionPostLct)
      _stmt.setLong(10, entity.discussionPostUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |      SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostPk,
    |             ? AS discussionPostDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |            JOIN DiscussionPost 
    |                 ON DiscussionPost.discussionPostClazzUid = Clazz.clazzUid
    |                 
    |       WHERE DiscussionPost.discussionPostLct != COALESCE(
    |             (SELECT discussionPostVersionId
    |                FROM discussionPostReplicate
    |               WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                 AND discussionPostDestination = ?), 0) 
    |      /*psql ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |      SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostPk,
    |             ? AS discussionPostDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |            JOIN DiscussionPost 
    |                 ON DiscussionPost.discussionPostClazzUid = Clazz.clazzUid
    |                 
    |       WHERE DiscussionPost.discussionPostLct != COALESCE(
    |             (SELECT discussionPostVersionId
    |                FROM discussionPostReplicate
    |               WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                 AND discussionPostDestination = ?), 0) 
    |       ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |          SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostUid,
    |                 UserSession.usClientNodeId AS discussionPostDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionPost
    |                     ON ChangeLog.chTableId = 132
    |                        AND ChangeLog.chEntityPk = DiscussionPost.discussionPostUid
    |                        
    |                        
    |                 JOIN Clazz 
    |                      ON Clazz.clazzUid = DiscussionPost.discussionPostClazzUid
    |                      
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionPost.discussionPostLct != COALESCE(
    |                 (SELECT discussionPostVersionId
    |                    FROM discussionPostReplicate
    |                   WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                     AND DiscussionPostDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |          SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostUid,
    |                 UserSession.usClientNodeId AS discussionPostDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionPost
    |                     ON ChangeLog.chTableId = 132
    |                        AND ChangeLog.chEntityPk = DiscussionPost.discussionPostUid
    |                        
    |                        
    |                 JOIN Clazz 
    |                      ON Clazz.clazzUid = DiscussionPost.discussionPostClazzUid
    |                      
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionPost.discussionPostLct != COALESCE(
    |                 (SELECT discussionPostVersionId
    |                    FROM discussionPostReplicate
    |                   WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                     AND DiscussionPostDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getPostsByDiscussionTopic(discussionTopicUid: Long):
      DataSourceFactory<Int, DiscussionPostWithDetails> = object :
      DataSourceFactory<Int, DiscussionPostWithDetails>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<DiscussionPostWithDetails>> = LiveDataImpl(_db, listOf("Message",
        "DiscussionPost", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT DiscussionPost.*,
      |            Person.firstNames as authorPersonFirstNames,
      |            Person.lastName as authorPersonLastName,
      |            (
      |                SELECT Message.messageText 
      |                  FROM Message 
      |                 WHERE Message.messageTableId = 132
      |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
      |                 ORDER BY messageTimestamp 
      |                  DESC LIMIT 1
      |            ) AS postLatestMessage,
      |            (
      |                SELECT COUNT(*) 
      |                  FROM Message
      |                 WHERE Message.messageTableId = 132
      |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
      |                   
      |            ) AS postRepliesCount, 
      |            
      |            (
      |                SELECT Message.messageTimestamp 
      |                  FROM Message 
      |                 WHERE Message.messageTableId = 132
      |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
      |                 ORDER BY messageTimestamp 
      |                  DESC LIMIT 1
      |            ) AS postLatestMessageTimestamp
      |             
      |          FROM DiscussionPost     
      |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
      |         WHERE DiscussionPost.discussionPostDiscussionTopicUid = ?
      |           AND CAST(DiscussionPost.discussionPostVisible AS INTEGER) = 1
      |           AND CAST(DiscussionPost.discussionPostArchive AS INTEGER) = 0
      |      ORDER BY DiscussionPost.discussionPostStartDate DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,discussionTopicUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_authorPersonFirstNames = _result.getString("authorPersonFirstNames")
            val _tmp_authorPersonLastName = _result.getString("authorPersonLastName")
            val _tmp_postLatestMessage = _result.getString("postLatestMessage")
            val _tmp_postRepliesCount = _result.getInt("postRepliesCount")
            val _tmp_postLatestMessageTimestamp = _result.getLong("postLatestMessageTimestamp")
            val _tmp_discussionPostUid = _result.getLong("discussionPostUid")
            val _tmp_discussionPostTitle = _result.getString("discussionPostTitle")
            val _tmp_discussionPostMessage = _result.getString("discussionPostMessage")
            val _tmp_discussionPostStartDate = _result.getLong("discussionPostStartDate")
            val _tmp_discussionPostDiscussionTopicUid =
                _result.getLong("discussionPostDiscussionTopicUid")
            val _tmp_discussionPostVisible = _result.getBoolean("discussionPostVisible")
            val _tmp_discussionPostArchive = _result.getBoolean("discussionPostArchive")
            val _tmp_discussionPostStartedPersonUid =
                _result.getLong("discussionPostStartedPersonUid")
            val _tmp_discussionPostClazzUid = _result.getLong("discussionPostClazzUid")
            val _tmp_discussionPostLct = _result.getLong("discussionPostLct")
            DiscussionPostWithDetails().apply {
              this.authorPersonFirstNames = _tmp_authorPersonFirstNames
              this.authorPersonLastName = _tmp_authorPersonLastName
              this.postLatestMessage = _tmp_postLatestMessage
              this.postRepliesCount = _tmp_postRepliesCount
              this.postLatestMessageTimestamp = _tmp_postLatestMessageTimestamp
              this.discussionPostUid = _tmp_discussionPostUid
              this.discussionPostTitle = _tmp_discussionPostTitle
              this.discussionPostMessage = _tmp_discussionPostMessage
              this.discussionPostStartDate = _tmp_discussionPostStartDate
              this.discussionPostDiscussionTopicUid = _tmp_discussionPostDiscussionTopicUid
              this.discussionPostVisible = _tmp_discussionPostVisible
              this.discussionPostArchive = _tmp_discussionPostArchive
              this.discussionPostStartedPersonUid = _tmp_discussionPostStartedPersonUid
              this.discussionPostClazzUid = _tmp_discussionPostClazzUid
              this.discussionPostLct = _tmp_discussionPostLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Message",
        "DiscussionPost", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT DiscussionPost.*,
      |            Person.firstNames as authorPersonFirstNames,
      |            Person.lastName as authorPersonLastName,
      |            (
      |                SELECT Message.messageText 
      |                  FROM Message 
      |                 WHERE Message.messageTableId = 132
      |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
      |                 ORDER BY messageTimestamp 
      |                  DESC LIMIT 1
      |            ) AS postLatestMessage,
      |            (
      |                SELECT COUNT(*) 
      |                  FROM Message
      |                 WHERE Message.messageTableId = 132
      |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
      |                   
      |            ) AS postRepliesCount, 
      |            
      |            (
      |                SELECT Message.messageTimestamp 
      |                  FROM Message 
      |                 WHERE Message.messageTableId = 132
      |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
      |                 ORDER BY messageTimestamp 
      |                  DESC LIMIT 1
      |            ) AS postLatestMessageTimestamp
      |             
      |          FROM DiscussionPost     
      |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
      |         WHERE DiscussionPost.discussionPostDiscussionTopicUid = ?
      |           AND CAST(DiscussionPost.discussionPostVisible AS INTEGER) = 1
      |           AND CAST(DiscussionPost.discussionPostArchive AS INTEGER) = 0
      |      ORDER BY DiscussionPost.discussionPostStartDate DESC
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,discussionTopicUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun getPostTitle(postUid: Long): String? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT DiscussionPost.discussionPostTitle 
  |          FROM DiscussionPost 
  |         WHERE DiscussionPost.discussionPostUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,postUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }

  public override suspend fun findByUid(uid: Long): DiscussionPost? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |         FROM DiscussionPost
  |        WHERE DiscussionPost.discussionPostUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_discussionPostUid = _result.getLong("discussionPostUid")
        val _tmp_discussionPostTitle = _result.getString("discussionPostTitle")
        val _tmp_discussionPostMessage = _result.getString("discussionPostMessage")
        val _tmp_discussionPostStartDate = _result.getLong("discussionPostStartDate")
        val _tmp_discussionPostDiscussionTopicUid =
            _result.getLong("discussionPostDiscussionTopicUid")
        val _tmp_discussionPostVisible = _result.getBoolean("discussionPostVisible")
        val _tmp_discussionPostArchive = _result.getBoolean("discussionPostArchive")
        val _tmp_discussionPostStartedPersonUid = _result.getLong("discussionPostStartedPersonUid")
        val _tmp_discussionPostClazzUid = _result.getLong("discussionPostClazzUid")
        val _tmp_discussionPostLct = _result.getLong("discussionPostLct")
        DiscussionPost().apply {
          this.discussionPostUid = _tmp_discussionPostUid
          this.discussionPostTitle = _tmp_discussionPostTitle
          this.discussionPostMessage = _tmp_discussionPostMessage
          this.discussionPostStartDate = _tmp_discussionPostStartDate
          this.discussionPostDiscussionTopicUid = _tmp_discussionPostDiscussionTopicUid
          this.discussionPostVisible = _tmp_discussionPostVisible
          this.discussionPostArchive = _tmp_discussionPostArchive
          this.discussionPostStartedPersonUid = _tmp_discussionPostStartedPersonUid
          this.discussionPostClazzUid = _tmp_discussionPostClazzUid
          this.discussionPostLct = _tmp_discussionPostLct
        }
      }
    }
  }

  public override suspend fun findWithDetailsByUid(uid: Long): DiscussionPostWithDetails? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT DiscussionPost.*,
  |            Person.firstNames as authorPersonFirstNames,
  |            Person.lastName as authorPersonLastName,
  |            '' AS postLatestMessage,
  |            0 AS postRepliesCount, 
  |            DiscussionPost.discussionPostLct AS postLatestMessageTimestamp
  |             
  |          FROM DiscussionPost     
  |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
  |         WHERE DiscussionPost.discussionPostUid = ?
  |           
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_authorPersonFirstNames = _result.getString("authorPersonFirstNames")
        val _tmp_authorPersonLastName = _result.getString("authorPersonLastName")
        val _tmp_postLatestMessage = _result.getString("postLatestMessage")
        val _tmp_postRepliesCount = _result.getInt("postRepliesCount")
        val _tmp_postLatestMessageTimestamp = _result.getLong("postLatestMessageTimestamp")
        val _tmp_discussionPostUid = _result.getLong("discussionPostUid")
        val _tmp_discussionPostTitle = _result.getString("discussionPostTitle")
        val _tmp_discussionPostMessage = _result.getString("discussionPostMessage")
        val _tmp_discussionPostStartDate = _result.getLong("discussionPostStartDate")
        val _tmp_discussionPostDiscussionTopicUid =
            _result.getLong("discussionPostDiscussionTopicUid")
        val _tmp_discussionPostVisible = _result.getBoolean("discussionPostVisible")
        val _tmp_discussionPostArchive = _result.getBoolean("discussionPostArchive")
        val _tmp_discussionPostStartedPersonUid = _result.getLong("discussionPostStartedPersonUid")
        val _tmp_discussionPostClazzUid = _result.getLong("discussionPostClazzUid")
        val _tmp_discussionPostLct = _result.getLong("discussionPostLct")
        DiscussionPostWithDetails().apply {
          this.authorPersonFirstNames = _tmp_authorPersonFirstNames
          this.authorPersonLastName = _tmp_authorPersonLastName
          this.postLatestMessage = _tmp_postLatestMessage
          this.postRepliesCount = _tmp_postRepliesCount
          this.postLatestMessageTimestamp = _tmp_postLatestMessageTimestamp
          this.discussionPostUid = _tmp_discussionPostUid
          this.discussionPostTitle = _tmp_discussionPostTitle
          this.discussionPostMessage = _tmp_discussionPostMessage
          this.discussionPostStartDate = _tmp_discussionPostStartDate
          this.discussionPostDiscussionTopicUid = _tmp_discussionPostDiscussionTopicUid
          this.discussionPostVisible = _tmp_discussionPostVisible
          this.discussionPostArchive = _tmp_discussionPostArchive
          this.discussionPostStartedPersonUid = _tmp_discussionPostStartedPersonUid
          this.discussionPostClazzUid = _tmp_discussionPostClazzUid
          this.discussionPostLct = _tmp_discussionPostLct
        }
      }
    }
  }

  public override fun findWithDetailsByUidLive(uid: Long): LiveData<DiscussionPostWithDetails?> =
      LiveDataImpl(_db, listOf("DiscussionPost", "Person"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT DiscussionPost.*,
    |            Person.firstNames as authorPersonFirstNames,
    |            Person.lastName as authorPersonLastName,
    |            '' AS postLatestMessage,
    |            0 AS postRepliesCount, 
    |            DiscussionPost.discussionPostLct AS postLatestMessageTimestamp
    |             
    |          FROM DiscussionPost     
    |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
    |         WHERE DiscussionPost.discussionPostUid = ?
    |           
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_authorPersonFirstNames = _result.getString("authorPersonFirstNames")
          val _tmp_authorPersonLastName = _result.getString("authorPersonLastName")
          val _tmp_postLatestMessage = _result.getString("postLatestMessage")
          val _tmp_postRepliesCount = _result.getInt("postRepliesCount")
          val _tmp_postLatestMessageTimestamp = _result.getLong("postLatestMessageTimestamp")
          val _tmp_discussionPostUid = _result.getLong("discussionPostUid")
          val _tmp_discussionPostTitle = _result.getString("discussionPostTitle")
          val _tmp_discussionPostMessage = _result.getString("discussionPostMessage")
          val _tmp_discussionPostStartDate = _result.getLong("discussionPostStartDate")
          val _tmp_discussionPostDiscussionTopicUid =
              _result.getLong("discussionPostDiscussionTopicUid")
          val _tmp_discussionPostVisible = _result.getBoolean("discussionPostVisible")
          val _tmp_discussionPostArchive = _result.getBoolean("discussionPostArchive")
          val _tmp_discussionPostStartedPersonUid =
              _result.getLong("discussionPostStartedPersonUid")
          val _tmp_discussionPostClazzUid = _result.getLong("discussionPostClazzUid")
          val _tmp_discussionPostLct = _result.getLong("discussionPostLct")
          DiscussionPostWithDetails().apply {
            this.authorPersonFirstNames = _tmp_authorPersonFirstNames
            this.authorPersonLastName = _tmp_authorPersonLastName
            this.postLatestMessage = _tmp_postLatestMessage
            this.postRepliesCount = _tmp_postRepliesCount
            this.postLatestMessageTimestamp = _tmp_postLatestMessageTimestamp
            this.discussionPostUid = _tmp_discussionPostUid
            this.discussionPostTitle = _tmp_discussionPostTitle
            this.discussionPostMessage = _tmp_discussionPostMessage
            this.discussionPostStartDate = _tmp_discussionPostStartDate
            this.discussionPostDiscussionTopicUid = _tmp_discussionPostDiscussionTopicUid
            this.discussionPostVisible = _tmp_discussionPostVisible
            this.discussionPostArchive = _tmp_discussionPostArchive
            this.discussionPostStartedPersonUid = _tmp_discussionPostStartedPersonUid
            this.discussionPostClazzUid = _tmp_discussionPostClazzUid
            this.discussionPostLct = _tmp_discussionPostLct
          }
        }
      }
    }
  }
}
