package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.AccessToken
import com.ustadmobile.lib.db.entities.AuditLog
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAuth
import com.ustadmobile.lib.db.entities.PersonGroup
import com.ustadmobile.lib.db.entities.PersonGroupMember
import com.ustadmobile.lib.db.entities.PersonUidAndPasswordHash
import com.ustadmobile.lib.db.entities.PersonWithAccount
import com.ustadmobile.lib.db.entities.PersonWithDisplayDetails
import com.ustadmobile.lib.db.entities.PersonWithPersonParentJoin
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : PersonDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<Person>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun insertOrReplace(person: Person): Unit {
    _dao.insertOrReplace(person)
  }

  public override suspend fun findByUsernameCount(username: String): Int {
    val _result = _dao.findByUsernameCount(username)
    return _result
  }

  public override fun isValidToken(token: String, personUid: Long): Boolean {
    val _result = _dao.isValidToken(token, personUid)
    return _result
  }

  public override fun insertAccessToken(token: AccessToken): Unit {
    _dao.insertAccessToken(token)
  }

  public override suspend fun findUidAndPasswordHashAsync(username: String):
      PersonUidAndPasswordHash? {
    val _result = _dao.findUidAndPasswordHashAsync(username)
    return _result
  }

  public override suspend fun findByUsernameAndPasswordHash2(username: String,
      passwordHash: String): Person? {
    val _result = _dao.findByUsernameAndPasswordHash2(username, passwordHash)
    return _result
  }

  public override fun insertPersonAuth(personAuth: PersonAuth): Unit {
    _dao.insertPersonAuth(personAuth)
  }

  public override suspend fun personHasPermissionAsync(
    accountPersonUid: Long,
    personUid: Long,
    permission: Long,
  ): Boolean {
    val _result = _dao.personHasPermissionAsync(accountPersonUid, personUid, permission)
    return _result
  }

  public override suspend fun personIsAdmin(accountPersonUid: Long): Boolean {
    val _result = _dao.personIsAdmin(accountPersonUid)
    return _result
  }

  public override fun findByUsername(username: String?): Person? {
    val _result = _dao.findByUsername(username)
    return _result
  }

  public override suspend fun findSystemAccount(nodeId: Long): Person? {
    val _result = _dao.findSystemAccount(nodeId)
    return _result
  }

  public override fun findByUid(uid: Long): Person? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun findPersonAccountByUid(uid: Long): PersonWithAccount? {
    val _result = _dao.findPersonAccountByUid(uid)
    return _result
  }

  public override fun findByUidLive(uid: Long): LiveData<Person?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): Person? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: Person): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun insertPersonGroup(personGroup: PersonGroup): Long {
    val _result = _dao.insertPersonGroup(personGroup)
    return _result
  }

  public override suspend fun insertPersonGroupMember(personGroupMember: PersonGroupMember): Long {
    val _result = _dao.insertPersonGroupMember(personGroupMember)
    return _result
  }

  public override fun findPersonsWithPermission(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): DataSourceFactory<Int, PersonWithDisplayDetails> {
    val _result = _dao.findPersonsWithPermission(timestamp, excludeClazz, excludeSchool,
        excludeSelected, accountPersonUid, sortOrder, searchText)
    return _result
  }

  public override fun findPersonsWithPermissionAsList(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): List<Person> {
    val _result = _dao.findPersonsWithPermissionAsList(timestamp, excludeClazz, excludeSchool,
        excludeSelected, accountPersonUid, sortOrder, searchText)
    return _result
  }

  public override fun findByUidWithDisplayDetailsLive(personUid: Long, activeUserPersonUid: Long):
      LiveData<PersonWithPersonParentJoin?> {
    val _result = _dao.findByUidWithDisplayDetailsLive(personUid, activeUserPersonUid)
    return _result
  }

  public override fun insertAuditLog(entity: AuditLog): Long {
    val _result = _dao.insertAuditLog(entity)
    return _result
  }

  public override fun getAllPerson(): List<Person> {
    val _result = _dao.getAllPerson()
    return _result
  }

  public override fun insert(entity: Person): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Person): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<Person>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<Person>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Person): Unit {
    _dao.update(entity)
  }
}
