package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContextXObjectStatementJoin
import kotlin.Long
import kotlin.Unit

public actual abstract class ContextXObjectStatementJoinDao : BaseDao<ContextXObjectStatementJoin> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByStatementAndObjectUid(statementUid: Long, objectUid: Long):
      ContextXObjectStatementJoin?
}
