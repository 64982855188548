package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.GroupLearningSession
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class GroupLearningSessionDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: GroupLearningSessionDao,
) : GroupLearningSessionDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun insert(entity: GroupLearningSession): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: GroupLearningSession): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.groupLearningSessionUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(302)
      entity.groupLearningSessionUid = _newPk
    }
    entity.groupLearningSessionLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.groupLearningSessionUid
  }

  public override fun insertList(entityList: List<GroupLearningSession>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<GroupLearningSession>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: GroupLearningSession): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
