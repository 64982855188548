package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzLogAttendanceRecordDao : BaseDao<ClazzLogAttendanceRecord> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(entities: List<ClazzLogAttendanceRecord>): Unit

  public actual abstract fun findByUid(uid: Long): ClazzLogAttendanceRecord?

  public actual abstract suspend fun updateListAsync(entities: List<ClazzLogAttendanceRecord>): Unit

  public actual abstract suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson>

  public actual abstract fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long,
  ): Unit
}
