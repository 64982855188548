package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.EntityRole
import com.ustadmobile.lib.db.entities.EntityRoleWithNameAndRole
import com.ustadmobile.lib.db.entities.Role
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class EntityRoleDao_JdbcKt(
  public val _db: RoomDatabase,
) : EntityRoleDao() {
  public val _insertAdapterEntityRole_upsert: EntityInsertionAdapter<EntityRole> = object :
      EntityInsertionAdapter<EntityRole>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO EntityRole (erUid, erMasterCsn, erLocalCsn, erLastChangedBy, erLct, erTableId, erEntityUid, erGroupUid, erRoleUid, erActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: EntityRole):
        Unit {
      if(entity.erUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.erUid)
      }
      stmt.setLong(2, entity.erMasterCsn)
      stmt.setLong(3, entity.erLocalCsn)
      stmt.setInt(4, entity.erLastChangedBy)
      stmt.setLong(5, entity.erLct)
      stmt.setInt(6, entity.erTableId)
      stmt.setLong(7, entity.erEntityUid)
      stmt.setLong(8, entity.erGroupUid)
      stmt.setLong(9, entity.erRoleUid)
      stmt.setBoolean(10, entity.erActive)
    }
  }

  public override suspend fun insertOrReplace(entity: EntityRole): Unit {
    _insertAdapterEntityRole_upsert.insertAsync(entity)
  }

  public override suspend fun updateAsync(entity: EntityRole): Int {
    var _result = 0
    val _sql =
        "UPDATE EntityRole SET erMasterCsn = ?, erLocalCsn = ?, erLastChangedBy = ?, erLct = ?, erTableId = ?, erEntityUid = ?, erGroupUid = ?, erRoleUid = ?, erActive = ? WHERE erUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.erMasterCsn)
      _stmt.setLong(2, entity.erLocalCsn)
      _stmt.setInt(3, entity.erLastChangedBy)
      _stmt.setLong(4, entity.erLct)
      _stmt.setInt(5, entity.erTableId)
      _stmt.setLong(6, entity.erEntityUid)
      _stmt.setLong(7, entity.erGroupUid)
      _stmt.setLong(8, entity.erRoleUid)
      _stmt.setBoolean(9, entity.erActive)
      _stmt.setLong(10, entity.erUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun userHasTableLevelPermission(accountPersonUid: Long, permission: Long):
      Boolean = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT COALESCE((
  |               SELECT admin 
  |                 FROM Person 
  |                WHERE personUid = ?), 0)
  |            OR EXISTS(SELECT EntityRole.erUid FROM EntityRole 
  |               JOIN Role 
  |                    ON EntityRole.erRoleUid = Role.roleUid 
  |               JOIN PersonGroupMember 
  |                    ON EntityRole.erGroupUid = PersonGroupMember.groupMemberGroupUid
  |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
  |               AND (Role.rolePermissions & ?) > 0) AS hasPermission
  """.trimMargin() , postgreSql = """
  |
  |        SELECT COALESCE((
  |               SELECT admin 
  |                 FROM Person 
  |                WHERE personUid = ?), false)
  |            OR EXISTS(SELECT EntityRole.erUid FROM EntityRole 
  |               JOIN Role 
  |                    ON EntityRole.erRoleUid = Role.roleUid 
  |               JOIN PersonGroupMember 
  |                    ON EntityRole.erGroupUid = PersonGroupMember.groupMemberGroupUid
  |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
  |               AND (Role.rolePermissions & ?) > 0) AS hasPermission
  |    
  """.trimMargin())) { _stmt -> 
    _stmt.setLong(1,accountPersonUid)
    _stmt.setLong(2,accountPersonUid)
    _stmt.setLong(3,permission)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override suspend fun findByEntitiyAndPersonGroupAndRole(
    tableId: Int,
    entityUid: Long,
    groupUid: Long,
    roleUid: Long,
  ): List<EntityRole> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM EntityRole WHERE erTableId = ?  AND erEntityUid = ? AND erGroupUid = ?  AND erRoleUid = ? "
      )) { _stmt -> 
    _stmt.setInt(1,tableId)
    _stmt.setLong(2,entityUid)
    _stmt.setLong(3,groupUid)
    _stmt.setLong(4,roleUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_erUid = _result.getLong("erUid")
        val _tmp_erMasterCsn = _result.getLong("erMasterCsn")
        val _tmp_erLocalCsn = _result.getLong("erLocalCsn")
        val _tmp_erLastChangedBy = _result.getInt("erLastChangedBy")
        val _tmp_erLct = _result.getLong("erLct")
        val _tmp_erTableId = _result.getInt("erTableId")
        val _tmp_erEntityUid = _result.getLong("erEntityUid")
        val _tmp_erGroupUid = _result.getLong("erGroupUid")
        val _tmp_erRoleUid = _result.getLong("erRoleUid")
        val _tmp_erActive = _result.getBoolean("erActive")
        EntityRole().apply {
          this.erUid = _tmp_erUid
          this.erMasterCsn = _tmp_erMasterCsn
          this.erLocalCsn = _tmp_erLocalCsn
          this.erLastChangedBy = _tmp_erLastChangedBy
          this.erLct = _tmp_erLct
          this.erTableId = _tmp_erTableId
          this.erEntityUid = _tmp_erEntityUid
          this.erGroupUid = _tmp_erGroupUid
          this.erRoleUid = _tmp_erRoleUid
          this.erActive = _tmp_erActive
        }
      }
    }
  }

  public override fun filterByPersonWithExtra(personGroupUid: Long):
      DataSourceFactory<Int, EntityRoleWithNameAndRole> = object :
      DataSourceFactory<Int, EntityRoleWithNameAndRole>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<EntityRoleWithNameAndRole>> = LiveDataImpl(_db, listOf("Clazz", "Person",
        "School", "EntityRole", "Role"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |                    SELECT  
      |                    (CASE 
      |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
      |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
      |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
      |                        ELSE '' 
      |                    END) as entityRoleScopeName,
      |                    Role.*, EntityRole.* FROM EntityRole
      |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
      |                    WHERE EntityRole.erGroupUid = ?
      |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
      |                ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personGroupUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_entityRoleScopeName = _result.getString("entityRoleScopeName")
            val _tmp_erUid = _result.getLong("erUid")
            val _tmp_erMasterCsn = _result.getLong("erMasterCsn")
            val _tmp_erLocalCsn = _result.getLong("erLocalCsn")
            val _tmp_erLastChangedBy = _result.getInt("erLastChangedBy")
            val _tmp_erLct = _result.getLong("erLct")
            val _tmp_erTableId = _result.getInt("erTableId")
            val _tmp_erEntityUid = _result.getLong("erEntityUid")
            val _tmp_erGroupUid = _result.getLong("erGroupUid")
            val _tmp_erRoleUid = _result.getLong("erRoleUid")
            val _tmp_erActive = _result.getBoolean("erActive")
            var _tmp_Role_nullCount = 0
            val _tmp_roleUid = _result.getLong("roleUid")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_roleName = _result.getString("roleName")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_roleActive = _result.getBoolean("roleActive")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_roleMasterCsn = _result.getLong("roleMasterCsn")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_roleLocalCsn = _result.getLong("roleLocalCsn")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_roleLastChangedBy = _result.getInt("roleLastChangedBy")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_roleLct = _result.getLong("roleLct")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_rolePermissions = _result.getLong("rolePermissions")
            if(_result.wasNull()) _tmp_Role_nullCount++
            val _tmp_Role_isAllNull = _tmp_Role_nullCount == 8
            EntityRoleWithNameAndRole().apply {
              this.entityRoleScopeName = _tmp_entityRoleScopeName
              this.erUid = _tmp_erUid
              this.erMasterCsn = _tmp_erMasterCsn
              this.erLocalCsn = _tmp_erLocalCsn
              this.erLastChangedBy = _tmp_erLastChangedBy
              this.erLct = _tmp_erLct
              this.erTableId = _tmp_erTableId
              this.erEntityUid = _tmp_erEntityUid
              this.erGroupUid = _tmp_erGroupUid
              this.erRoleUid = _tmp_erRoleUid
              this.erActive = _tmp_erActive
              if(!_tmp_Role_isAllNull) {
                this.entityRoleRole = Role().apply {
                  this.roleUid = _tmp_roleUid
                  this.roleName = _tmp_roleName
                  this.roleActive = _tmp_roleActive
                  this.roleMasterCsn = _tmp_roleMasterCsn
                  this.roleLocalCsn = _tmp_roleLocalCsn
                  this.roleLastChangedBy = _tmp_roleLastChangedBy
                  this.roleLct = _tmp_roleLct
                  this.rolePermissions = _tmp_rolePermissions
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Clazz", "Person",
        "School", "EntityRole", "Role"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |                    SELECT  
      |                    (CASE 
      |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
      |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
      |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
      |                        ELSE '' 
      |                    END) as entityRoleScopeName,
      |                    Role.*, EntityRole.* FROM EntityRole
      |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
      |                    WHERE EntityRole.erGroupUid = ?
      |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
      |                ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personGroupUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun filterByPersonWithExtraAsList(personGroupUid: Long):
      List<EntityRoleWithNameAndRole> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |                    SELECT  
  |                    (CASE 
  |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
  |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
  |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
  |                        ELSE '' 
  |                    END) as entityRoleScopeName,
  |                    Role.*, EntityRole.* FROM EntityRole
  |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
  |                    WHERE EntityRole.erGroupUid = ?
  |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
  |                
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personGroupUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_entityRoleScopeName = _result.getString("entityRoleScopeName")
        val _tmp_erUid = _result.getLong("erUid")
        val _tmp_erMasterCsn = _result.getLong("erMasterCsn")
        val _tmp_erLocalCsn = _result.getLong("erLocalCsn")
        val _tmp_erLastChangedBy = _result.getInt("erLastChangedBy")
        val _tmp_erLct = _result.getLong("erLct")
        val _tmp_erTableId = _result.getInt("erTableId")
        val _tmp_erEntityUid = _result.getLong("erEntityUid")
        val _tmp_erGroupUid = _result.getLong("erGroupUid")
        val _tmp_erRoleUid = _result.getLong("erRoleUid")
        val _tmp_erActive = _result.getBoolean("erActive")
        var _tmp_Role_nullCount = 0
        val _tmp_roleUid = _result.getLong("roleUid")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_roleName = _result.getString("roleName")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_roleActive = _result.getBoolean("roleActive")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_roleMasterCsn = _result.getLong("roleMasterCsn")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_roleLocalCsn = _result.getLong("roleLocalCsn")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_roleLastChangedBy = _result.getInt("roleLastChangedBy")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_roleLct = _result.getLong("roleLct")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_rolePermissions = _result.getLong("rolePermissions")
        if(_result.wasNull()) _tmp_Role_nullCount++
        val _tmp_Role_isAllNull = _tmp_Role_nullCount == 8
        EntityRoleWithNameAndRole().apply {
          this.entityRoleScopeName = _tmp_entityRoleScopeName
          this.erUid = _tmp_erUid
          this.erMasterCsn = _tmp_erMasterCsn
          this.erLocalCsn = _tmp_erLocalCsn
          this.erLastChangedBy = _tmp_erLastChangedBy
          this.erLct = _tmp_erLct
          this.erTableId = _tmp_erTableId
          this.erEntityUid = _tmp_erEntityUid
          this.erGroupUid = _tmp_erGroupUid
          this.erRoleUid = _tmp_erRoleUid
          this.erActive = _tmp_erActive
          if(!_tmp_Role_isAllNull) {
            this.entityRoleRole = Role().apply {
              this.roleUid = _tmp_roleUid
              this.roleName = _tmp_roleName
              this.roleActive = _tmp_roleActive
              this.roleMasterCsn = _tmp_roleMasterCsn
              this.roleLocalCsn = _tmp_roleLocalCsn
              this.roleLastChangedBy = _tmp_roleLastChangedBy
              this.roleLct = _tmp_roleLct
              this.rolePermissions = _tmp_rolePermissions
            }
          }
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): EntityRole? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM EntityRole WHERE erUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_erUid = _result.getLong("erUid")
        val _tmp_erMasterCsn = _result.getLong("erMasterCsn")
        val _tmp_erLocalCsn = _result.getLong("erLocalCsn")
        val _tmp_erLastChangedBy = _result.getInt("erLastChangedBy")
        val _tmp_erLct = _result.getLong("erLct")
        val _tmp_erTableId = _result.getInt("erTableId")
        val _tmp_erEntityUid = _result.getLong("erEntityUid")
        val _tmp_erGroupUid = _result.getLong("erGroupUid")
        val _tmp_erRoleUid = _result.getLong("erRoleUid")
        val _tmp_erActive = _result.getBoolean("erActive")
        EntityRole().apply {
          this.erUid = _tmp_erUid
          this.erMasterCsn = _tmp_erMasterCsn
          this.erLocalCsn = _tmp_erLocalCsn
          this.erLastChangedBy = _tmp_erLastChangedBy
          this.erLct = _tmp_erLct
          this.erTableId = _tmp_erTableId
          this.erEntityUid = _tmp_erEntityUid
          this.erGroupUid = _tmp_erGroupUid
          this.erRoleUid = _tmp_erRoleUid
          this.erActive = _tmp_erActive
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<EntityRole?> = LiveDataImpl(_db,
      listOf("EntityRole"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM EntityRole WHERE erUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_erUid = _result.getLong("erUid")
          val _tmp_erMasterCsn = _result.getLong("erMasterCsn")
          val _tmp_erLocalCsn = _result.getLong("erLocalCsn")
          val _tmp_erLastChangedBy = _result.getInt("erLastChangedBy")
          val _tmp_erLct = _result.getLong("erLct")
          val _tmp_erTableId = _result.getInt("erTableId")
          val _tmp_erEntityUid = _result.getLong("erEntityUid")
          val _tmp_erGroupUid = _result.getLong("erGroupUid")
          val _tmp_erRoleUid = _result.getLong("erRoleUid")
          val _tmp_erActive = _result.getBoolean("erActive")
          EntityRole().apply {
            this.erUid = _tmp_erUid
            this.erMasterCsn = _tmp_erMasterCsn
            this.erLocalCsn = _tmp_erLocalCsn
            this.erLastChangedBy = _tmp_erLastChangedBy
            this.erLct = _tmp_erLct
            this.erTableId = _tmp_erTableId
            this.erEntityUid = _tmp_erEntityUid
            this.erGroupUid = _tmp_erGroupUid
            this.erRoleUid = _tmp_erRoleUid
            this.erActive = _tmp_erActive
          }
        }
      }
    }
  }
}
