package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ClazzLog
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzLogDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ClazzLogDao,
) : ClazzLogDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override fun replace(entity: ClazzLog): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUid(uid: Long): ClazzLog? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUidAsync(uid: Long): ClazzLog? = _dao.findByUidAsync(uid)

  public override fun findByUidLive(uid: Long): LiveData<ClazzLog?> = _dao.findByUidLive(uid)

  public override fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      DataSourceFactory<Int, ClazzLog> = _dao.findByClazzUidAsFactory(clazzUid, excludeStatus)

  public override suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int):
      List<ClazzLog> = _dao.findByClazzUidAsync(clazzUid, excludeStatus)

  public override suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> = _dao.findByClazzUidWithinTimeRangeAsync(clazzUid, fromTime, toTime,
      excludeStatusFilter, limit)

  public override fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int,
  ): LiveData<List<ClazzLog>> = _dao.findByClazzUidWithinTimeRangeLive(clazzUid, fromTime, toTime,
      statusFilter)

  public override fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int):
      LiveData<Boolean> = _dao.clazzHasScheduleLive(clazzUid, excludeStatusFilter)

  public override fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long,
  ): Unit = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun updateAsync(clazzLog: ClazzLog): Unit {
    clazzLog.clazzLogLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(clazzLog)
  }

  public override fun insert(entity: ClazzLog): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzLog): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.clazzLogUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(14)
      entity.clazzLogUid = _newPk
    }
    entity.clazzLogLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.clazzLogUid
  }

  public override fun insertList(entityList: List<ClazzLog>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ClazzLog>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzLog): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
