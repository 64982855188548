package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Chat
import com.ustadmobile.lib.db.entities.ChatWithLatestMessageAndCount
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ChatDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ChatDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ChatDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findAllChatsForUser(searchBit: String, personUid: Long):
      DataSourceFactory<Int, ChatWithLatestMessageAndCount> {
    val _result = _dao.findAllChatsForUser(searchBit, personUid)
    return _result
  }

  public override suspend fun getTitleChat(chatUid: Long, personUid: Long): String? {
    val _result = _dao.getTitleChat(chatUid, personUid)
    return _result
  }

  public override suspend fun getChatByOtherPerson(otherPersonUid: Long, loggedInPersonUid: Long):
      Chat? {
    val _result = _dao.getChatByOtherPerson(otherPersonUid, loggedInPersonUid)
    return _result
  }

  public override fun insert(entity: Chat): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Chat): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<Chat>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<Chat>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Chat): Unit {
    _dao.update(entity)
  }
}
