package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzLogAttendanceRecordDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ClazzLogAttendanceRecordDao,
) : ClazzLogAttendanceRecordDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entities: List<ClazzLogAttendanceRecord>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      if(it.clazzLogAttendanceRecordUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(15)
        it.clazzLogAttendanceRecordUid = _newPk
        _generatedPks += _newPk
      }
      it.clazzLogAttendanceRecordLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entities)
  }

  public override fun findByUid(uid: Long): ClazzLogAttendanceRecord? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun updateListAsync(entities: List<ClazzLogAttendanceRecord>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.clazzLogAttendanceRecordLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entities)
  }

  public override suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson> = _dao.findByClazzLogUid(clazzLogUid)

  public override fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long,
  ): Unit = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: ClazzLogAttendanceRecord): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzLogAttendanceRecord): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.clazzLogAttendanceRecordUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(15)
      entity.clazzLogAttendanceRecordUid = _newPk
    }
    entity.clazzLogAttendanceRecordLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.clazzLogAttendanceRecordUid
  }

  public override fun insertList(entityList: List<ClazzLogAttendanceRecord>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ClazzLogAttendanceRecord>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzLogAttendanceRecord): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
