package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.UserSession
import com.ustadmobile.lib.db.entities.UserSessionAndPerson
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class UserSessionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: UserSessionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : UserSessionDao() {
  public override suspend fun updateReplicationTrackers(): Unit {
    _dao.updateReplicationTrackers()
  }

  public override suspend fun updateReplicationTrackersOnNewNode(newNodeId: Long): Unit {
    _dao.updateReplicationTrackersOnNewNode(newNodeId)
  }

  public override suspend fun insertSession(session: UserSession): Long {
    val _result = _dao.insertSession(session)
    return _result
  }

  public override suspend fun findSessionsByPerson(personUid: Long): List<UserSession> {
    val _result = _dao.findSessionsByPerson(personUid)
    return _result
  }

  public override fun findAllLocalSessionsLive(): LiveData<List<UserSessionAndPerson>> {
    val _result = _dao.findAllLocalSessionsLive()
    return _result
  }

  public override suspend fun findAllLocalSessionsAsync(): List<UserSessionAndPerson> {
    val _result = _dao.findAllLocalSessionsAsync()
    return _result
  }

  public override suspend fun countAllLocalSessionsAsync(maxDateOfBirth: Long): Int {
    val _result = _dao.countAllLocalSessionsAsync(maxDateOfBirth)
    return _result
  }

  public override suspend fun endSession(
    sessionUid: Long,
    newStatus: Int,
    reason: Int,
  ): Unit {
    _dao.endSession(sessionUid, newStatus, reason)
  }

  public override fun findByUidLive(sessionUid: Long): LiveData<UserSession?> {
    val _result = _dao.findByUidLive(sessionUid)
    return _result
  }

  public override suspend fun endOtherSessions(
    personUid: Long,
    exemptNodeId: Long,
    newStatus: Int,
    reason: Int,
    changeTime: Long,
  ): Unit {
    _dao.endOtherSessions(personUid, exemptNodeId, newStatus, reason, changeTime)
  }

  public override suspend fun findActiveNodeIdsByPersonUids(personUids: List<Long>): List<Long> {
    val _result = _dao.findActiveNodeIdsByPersonUids(personUids)
    return _result
  }

  public override suspend fun findActiveNodesIdsByGroupUids(groupUids: List<Long>): List<Long> {
    val _result = _dao.findActiveNodesIdsByGroupUids(groupUids)
    return _result
  }

  public override suspend fun findAllActiveNodeIdsWithClazzBasedPermission(clazzUids: List<Long>):
      List<Long> {
    val _result = _dao.findAllActiveNodeIdsWithClazzBasedPermission(clazzUids)
    return _result
  }

  public override suspend fun findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids: List<Long>):
      List<Long> {
    val _result = _dao.findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids)
    return _result
  }
}
