package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.ContainerImportJob
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContainerImportJobDao : BaseDao<ContainerImportJob> {
  public actual abstract fun findBySessionId(sessionId: String): ContainerImportJob?

  public actual abstract fun findByUid(uploadId: Long): ContainerImportJob?

  public actual abstract fun findJobs(): LiveData<List<ContainerImportJob>>

  public actual abstract suspend fun setStatusToQueueAsync(uploadJobId: Long): Unit

  public actual abstract fun updateProgress(
    progress: Long,
    contentLength: Long,
    uploadJobId: Long,
  ): Unit

  public actual abstract fun updateStatus(status: Int, uploadJobId: Long): Unit

  public actual abstract fun updateImportComplete(
    importCompleted: Boolean,
    containerUid: Long,
    importJobUid: Long,
  ): Unit

  public actual abstract suspend fun getTitleOfEntry(importJobUid: Long): String?

  public actual abstract fun getImportJobLiveData(importJobUid: Long): LiveData<ContainerImportJob?>

  public actual abstract suspend fun updateSessionId(importJobUid: Long, sessionId: String): Unit
}
