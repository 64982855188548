package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Report
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ReportDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ReportDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ReportDao() {
  public override suspend fun replicateOnNewNodeTemplates(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeTemplates(newNodeId)
  }

  public override suspend fun replicateOnChangeTemplates(): Unit {
    _dao.replicateOnChangeTemplates()
  }

  public override fun getResults(query: DoorQuery): List<Report> {
    val _result = _dao.getResults(query)
    return _result
  }

  public override fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): DataSourceFactory<Int, Report> {
    val _result = _dao.findAllActiveReport(searchBit, personUid, sortOrder, isTemplate)
    return _result
  }

  public override suspend fun findByUid(entityUid: Long): Report? {
    val _result = _dao.findByUid(entityUid)
    return _result
  }

  public override suspend fun updateAsync(entity: Report): Unit {
    _dao.updateAsync(entity)
  }

  public override fun findByUidLive(uid: Long): LiveData<Report?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override fun findAllActiveReportLive(isTemplate: Boolean): LiveData<List<Report>> {
    val _result = _dao.findAllActiveReportLive(isTemplate)
    return _result
  }

  public override fun findAllActiveReportList(isTemplate: Boolean): List<Report> {
    val _result = _dao.findAllActiveReportList(isTemplate)
    return _result
  }

  public override fun findByUidList(uidList: List<Long>): List<Long> {
    val _result = _dao.findByUidList(uidList)
    return _result
  }

  public override suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ): Unit {
    _dao.toggleVisibilityReportItems(toggleVisibility, selectedItem, updateTime)
  }

  public override fun replaceList(entityList: List<Report>): Unit {
    _dao.replaceList(entityList)
  }

  public override fun insert(entity: Report): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Report): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<Report>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<Report>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Report): Unit {
    _dao.update(entity)
  }
}
