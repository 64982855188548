package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.NetworkNode
import kotlin.Array
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class NetworkNodeDao_JdbcKt(
  public val _db: RoomDatabase,
) : NetworkNodeDao() {
  public val _insertAdapterNetworkNode_upsert: EntityInsertionAdapter<NetworkNode> = object :
      EntityInsertionAdapter<NetworkNode>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO NetworkNode (nodeId, bluetoothMacAddress, ipAddress, wifiDirectMacAddress, deviceWifiDirectName, endpointUrl, lastUpdateTimeStamp, networkServiceLastUpdated, nsdServiceName, port, numFailureCount, wifiDirectDeviceStatus, groupSsid) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: NetworkNode):
        Unit {
      if(entity.nodeId == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.nodeId)
      }
      stmt.setString(2, entity.bluetoothMacAddress)
      stmt.setString(3, entity.ipAddress)
      stmt.setString(4, entity.wifiDirectMacAddress)
      stmt.setString(5, entity.deviceWifiDirectName)
      stmt.setString(6, entity.endpointUrl)
      stmt.setLong(7, entity.lastUpdateTimeStamp)
      stmt.setLong(8, entity.networkServiceLastUpdated)
      stmt.setString(9, entity.nsdServiceName)
      stmt.setInt(10, entity.port)
      stmt.setInt(11, entity.numFailureCount)
      stmt.setInt(12, entity.wifiDirectDeviceStatus)
      stmt.setString(13, entity.groupSsid)
    }
  }

  public override fun replace(node: NetworkNode): Long {
    val _retVal = _insertAdapterNetworkNode_upsert.insertAndReturnId(node)
    return _retVal
  }

  public override suspend fun insertAsync(node: NetworkNode): Long {
    val _retVal = _insertAdapterNetworkNode_upsert.insertAndReturnIdAsync(node)
    return _retVal
  }

  public override fun insertList(nodeList: List<NetworkNode>): Array<Long> {
    val _retVal = _insertAdapterNetworkNode_upsert.insertListAndReturnIds(nodeList)
    return _retVal.toTypedArray()
  }

  public override fun update(node: NetworkNode): Unit {
    val _sql =
        "UPDATE NetworkNode SET bluetoothMacAddress = ?, ipAddress = ?, wifiDirectMacAddress = ?, deviceWifiDirectName = ?, endpointUrl = ?, lastUpdateTimeStamp = ?, networkServiceLastUpdated = ?, nsdServiceName = ?, port = ?, numFailureCount = ?, wifiDirectDeviceStatus = ?, groupSsid = ? WHERE nodeId = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, node.bluetoothMacAddress)
      _stmt.setString(2, node.ipAddress)
      _stmt.setString(3, node.wifiDirectMacAddress)
      _stmt.setString(4, node.deviceWifiDirectName)
      _stmt.setString(5, node.endpointUrl)
      _stmt.setLong(6, node.lastUpdateTimeStamp)
      _stmt.setLong(7, node.networkServiceLastUpdated)
      _stmt.setString(8, node.nsdServiceName)
      _stmt.setInt(9, node.port)
      _stmt.setInt(10, node.numFailureCount)
      _stmt.setInt(11, node.wifiDirectDeviceStatus)
      _stmt.setString(12, node.groupSsid)
      _stmt.setLong(13, node.nodeId)
      _stmt.executeUpdate()
    }
  }

  public override fun all(): List<NetworkNode> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM NetworkNode" )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_nodeId = _result.getLong("nodeId")
        val _tmp_bluetoothMacAddress = _result.getString("bluetoothMacAddress")
        val _tmp_ipAddress = _result.getString("ipAddress")
        val _tmp_wifiDirectMacAddress = _result.getString("wifiDirectMacAddress")
        val _tmp_deviceWifiDirectName = _result.getString("deviceWifiDirectName")
        val _tmp_endpointUrl = _result.getString("endpointUrl")
        val _tmp_lastUpdateTimeStamp = _result.getLong("lastUpdateTimeStamp")
        val _tmp_networkServiceLastUpdated = _result.getLong("networkServiceLastUpdated")
        val _tmp_nsdServiceName = _result.getString("nsdServiceName")
        val _tmp_port = _result.getInt("port")
        val _tmp_numFailureCount = _result.getInt("numFailureCount")
        val _tmp_wifiDirectDeviceStatus = _result.getInt("wifiDirectDeviceStatus")
        val _tmp_groupSsid = _result.getString("groupSsid")
        NetworkNode().apply {
          this.nodeId = _tmp_nodeId
          this.bluetoothMacAddress = _tmp_bluetoothMacAddress
          this.ipAddress = _tmp_ipAddress
          this.wifiDirectMacAddress = _tmp_wifiDirectMacAddress
          this.deviceWifiDirectName = _tmp_deviceWifiDirectName
          this.endpointUrl = _tmp_endpointUrl
          this.lastUpdateTimeStamp = _tmp_lastUpdateTimeStamp
          this.networkServiceLastUpdated = _tmp_networkServiceLastUpdated
          this.nsdServiceName = _tmp_nsdServiceName
          this.port = _tmp_port
          this.numFailureCount = _tmp_numFailureCount
          this.wifiDirectDeviceStatus = _tmp_wifiDirectDeviceStatus
          this.groupSsid = _tmp_groupSsid
        }
      }
    }
  }

  public override fun findNodeByIpAddress(ipAddress: String): NetworkNode? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * From NetworkNode WHERE ipAddress = ?"
      )) { _stmt -> 
    _stmt.setString(1,ipAddress)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_nodeId = _result.getLong("nodeId")
        val _tmp_bluetoothMacAddress = _result.getString("bluetoothMacAddress")
        val _tmp_ipAddress = _result.getString("ipAddress")
        val _tmp_wifiDirectMacAddress = _result.getString("wifiDirectMacAddress")
        val _tmp_deviceWifiDirectName = _result.getString("deviceWifiDirectName")
        val _tmp_endpointUrl = _result.getString("endpointUrl")
        val _tmp_lastUpdateTimeStamp = _result.getLong("lastUpdateTimeStamp")
        val _tmp_networkServiceLastUpdated = _result.getLong("networkServiceLastUpdated")
        val _tmp_nsdServiceName = _result.getString("nsdServiceName")
        val _tmp_port = _result.getInt("port")
        val _tmp_numFailureCount = _result.getInt("numFailureCount")
        val _tmp_wifiDirectDeviceStatus = _result.getInt("wifiDirectDeviceStatus")
        val _tmp_groupSsid = _result.getString("groupSsid")
        NetworkNode().apply {
          this.nodeId = _tmp_nodeId
          this.bluetoothMacAddress = _tmp_bluetoothMacAddress
          this.ipAddress = _tmp_ipAddress
          this.wifiDirectMacAddress = _tmp_wifiDirectMacAddress
          this.deviceWifiDirectName = _tmp_deviceWifiDirectName
          this.endpointUrl = _tmp_endpointUrl
          this.lastUpdateTimeStamp = _tmp_lastUpdateTimeStamp
          this.networkServiceLastUpdated = _tmp_networkServiceLastUpdated
          this.nsdServiceName = _tmp_nsdServiceName
          this.port = _tmp_port
          this.numFailureCount = _tmp_numFailureCount
          this.wifiDirectDeviceStatus = _tmp_wifiDirectDeviceStatus
          this.groupSsid = _tmp_groupSsid
        }
      }
    }
  }

  public override fun findNodeById(nodeId: Long): NetworkNode? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * From NetworkNode WHERE nodeId = ?"
      )) { _stmt -> 
    _stmt.setLong(1,nodeId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_nodeId = _result.getLong("nodeId")
        val _tmp_bluetoothMacAddress = _result.getString("bluetoothMacAddress")
        val _tmp_ipAddress = _result.getString("ipAddress")
        val _tmp_wifiDirectMacAddress = _result.getString("wifiDirectMacAddress")
        val _tmp_deviceWifiDirectName = _result.getString("deviceWifiDirectName")
        val _tmp_endpointUrl = _result.getString("endpointUrl")
        val _tmp_lastUpdateTimeStamp = _result.getLong("lastUpdateTimeStamp")
        val _tmp_networkServiceLastUpdated = _result.getLong("networkServiceLastUpdated")
        val _tmp_nsdServiceName = _result.getString("nsdServiceName")
        val _tmp_port = _result.getInt("port")
        val _tmp_numFailureCount = _result.getInt("numFailureCount")
        val _tmp_wifiDirectDeviceStatus = _result.getInt("wifiDirectDeviceStatus")
        val _tmp_groupSsid = _result.getString("groupSsid")
        NetworkNode().apply {
          this.nodeId = _tmp_nodeId
          this.bluetoothMacAddress = _tmp_bluetoothMacAddress
          this.ipAddress = _tmp_ipAddress
          this.wifiDirectMacAddress = _tmp_wifiDirectMacAddress
          this.deviceWifiDirectName = _tmp_deviceWifiDirectName
          this.endpointUrl = _tmp_endpointUrl
          this.lastUpdateTimeStamp = _tmp_lastUpdateTimeStamp
          this.networkServiceLastUpdated = _tmp_networkServiceLastUpdated
          this.nsdServiceName = _tmp_nsdServiceName
          this.port = _tmp_port
          this.numFailureCount = _tmp_numFailureCount
          this.wifiDirectDeviceStatus = _tmp_wifiDirectDeviceStatus
          this.groupSsid = _tmp_groupSsid
        }
      }
    }
  }

  public override fun findNodeByIpOrWifiDirectMacAddress(ipAddress: String,
      wifiDirectMacAddress: String): NetworkNode? =
      _db.prepareAndUseStatement(PreparedStatementConfig("Select * From NetworkNode Where ((ipAddress = ? AND ipAddress IS NOT NULL) OR (wifiDirectMacAddress = ? AND wifiDirectMacAddress IS NOT NULL))"
      )) { _stmt -> 
    _stmt.setString(1,ipAddress)
    _stmt.setString(2,wifiDirectMacAddress)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_nodeId = _result.getLong("nodeId")
        val _tmp_bluetoothMacAddress = _result.getString("bluetoothMacAddress")
        val _tmp_ipAddress = _result.getString("ipAddress")
        val _tmp_wifiDirectMacAddress = _result.getString("wifiDirectMacAddress")
        val _tmp_deviceWifiDirectName = _result.getString("deviceWifiDirectName")
        val _tmp_endpointUrl = _result.getString("endpointUrl")
        val _tmp_lastUpdateTimeStamp = _result.getLong("lastUpdateTimeStamp")
        val _tmp_networkServiceLastUpdated = _result.getLong("networkServiceLastUpdated")
        val _tmp_nsdServiceName = _result.getString("nsdServiceName")
        val _tmp_port = _result.getInt("port")
        val _tmp_numFailureCount = _result.getInt("numFailureCount")
        val _tmp_wifiDirectDeviceStatus = _result.getInt("wifiDirectDeviceStatus")
        val _tmp_groupSsid = _result.getString("groupSsid")
        NetworkNode().apply {
          this.nodeId = _tmp_nodeId
          this.bluetoothMacAddress = _tmp_bluetoothMacAddress
          this.ipAddress = _tmp_ipAddress
          this.wifiDirectMacAddress = _tmp_wifiDirectMacAddress
          this.deviceWifiDirectName = _tmp_deviceWifiDirectName
          this.endpointUrl = _tmp_endpointUrl
          this.lastUpdateTimeStamp = _tmp_lastUpdateTimeStamp
          this.networkServiceLastUpdated = _tmp_networkServiceLastUpdated
          this.nsdServiceName = _tmp_nsdServiceName
          this.port = _tmp_port
          this.numFailureCount = _tmp_numFailureCount
          this.wifiDirectDeviceStatus = _tmp_wifiDirectDeviceStatus
          this.groupSsid = _tmp_groupSsid
        }
      }
    }
  }

  public override fun findNodeByBluetoothAddress(bluetoothAddress: String): NetworkNode? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * from NetworkNode WHERE bluetoothMacAddress = ?"
      )) { _stmt -> 
    _stmt.setString(1,bluetoothAddress)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_nodeId = _result.getLong("nodeId")
        val _tmp_bluetoothMacAddress = _result.getString("bluetoothMacAddress")
        val _tmp_ipAddress = _result.getString("ipAddress")
        val _tmp_wifiDirectMacAddress = _result.getString("wifiDirectMacAddress")
        val _tmp_deviceWifiDirectName = _result.getString("deviceWifiDirectName")
        val _tmp_endpointUrl = _result.getString("endpointUrl")
        val _tmp_lastUpdateTimeStamp = _result.getLong("lastUpdateTimeStamp")
        val _tmp_networkServiceLastUpdated = _result.getLong("networkServiceLastUpdated")
        val _tmp_nsdServiceName = _result.getString("nsdServiceName")
        val _tmp_port = _result.getInt("port")
        val _tmp_numFailureCount = _result.getInt("numFailureCount")
        val _tmp_wifiDirectDeviceStatus = _result.getInt("wifiDirectDeviceStatus")
        val _tmp_groupSsid = _result.getString("groupSsid")
        NetworkNode().apply {
          this.nodeId = _tmp_nodeId
          this.bluetoothMacAddress = _tmp_bluetoothMacAddress
          this.ipAddress = _tmp_ipAddress
          this.wifiDirectMacAddress = _tmp_wifiDirectMacAddress
          this.deviceWifiDirectName = _tmp_deviceWifiDirectName
          this.endpointUrl = _tmp_endpointUrl
          this.lastUpdateTimeStamp = _tmp_lastUpdateTimeStamp
          this.networkServiceLastUpdated = _tmp_networkServiceLastUpdated
          this.nsdServiceName = _tmp_nsdServiceName
          this.port = _tmp_port
          this.numFailureCount = _tmp_numFailureCount
          this.wifiDirectDeviceStatus = _tmp_wifiDirectDeviceStatus
          this.groupSsid = _tmp_groupSsid
        }
      }
    }
  }

  public override fun deleteByBluetoothAddress(bluetoothAddress: String): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("DELETE FROM NetworkNode WHERE bluetoothMacAddress = ?"
        )) { _stmt -> 
      _stmt.setString(1,bluetoothAddress)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun deleteAllAsync(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("DELETE FROM NetworkNode" )) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateRetryCountAsync(nodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("UPDATE NetworkNode SET numFailureCount = numFailureCount + 1 WHERE nodeId = ?"
        )) { _stmt -> 
      _stmt.setLong(1,nodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllActiveNodes(lastUpdatedTime: Long, maxNumFailure: Int):
      List<NetworkNode> =
      _db.prepareAndUseStatement(PreparedStatementConfig("Select * From NetworkNode WHERE lastUpdateTimeStamp >= ? AND numFailureCount <= ?"
      )) { _stmt -> 
    _stmt.setLong(1,lastUpdatedTime)
    _stmt.setInt(2,maxNumFailure)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_nodeId = _result.getLong("nodeId")
        val _tmp_bluetoothMacAddress = _result.getString("bluetoothMacAddress")
        val _tmp_ipAddress = _result.getString("ipAddress")
        val _tmp_wifiDirectMacAddress = _result.getString("wifiDirectMacAddress")
        val _tmp_deviceWifiDirectName = _result.getString("deviceWifiDirectName")
        val _tmp_endpointUrl = _result.getString("endpointUrl")
        val _tmp_lastUpdateTimeStamp = _result.getLong("lastUpdateTimeStamp")
        val _tmp_networkServiceLastUpdated = _result.getLong("networkServiceLastUpdated")
        val _tmp_nsdServiceName = _result.getString("nsdServiceName")
        val _tmp_port = _result.getInt("port")
        val _tmp_numFailureCount = _result.getInt("numFailureCount")
        val _tmp_wifiDirectDeviceStatus = _result.getInt("wifiDirectDeviceStatus")
        val _tmp_groupSsid = _result.getString("groupSsid")
        NetworkNode().apply {
          this.nodeId = _tmp_nodeId
          this.bluetoothMacAddress = _tmp_bluetoothMacAddress
          this.ipAddress = _tmp_ipAddress
          this.wifiDirectMacAddress = _tmp_wifiDirectMacAddress
          this.deviceWifiDirectName = _tmp_deviceWifiDirectName
          this.endpointUrl = _tmp_endpointUrl
          this.lastUpdateTimeStamp = _tmp_lastUpdateTimeStamp
          this.networkServiceLastUpdated = _tmp_networkServiceLastUpdated
          this.nsdServiceName = _tmp_nsdServiceName
          this.port = _tmp_port
          this.numFailureCount = _tmp_numFailureCount
          this.wifiDirectDeviceStatus = _tmp_wifiDirectDeviceStatus
          this.groupSsid = _tmp_groupSsid
        }
      }
    }
  }

  public override suspend fun updateLastSeenAsync(bluetoothAddress: String,
      lastUpdateTimeStamp: Long): Int =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("UPDATE NetworkNode set lastUpdateTimeStamp = ?, numFailureCount = 0 WHERE bluetoothMacAddress = ?"
      )) { _stmt -> 
    _stmt.setLong(1,lastUpdateTimeStamp)
    _stmt.setString(2,bluetoothAddress)
    _stmt.executeUpdateAsyncKmp()
  }

  public override fun deleteOldAndBadNode(minLastSeenTimestamp: Long, maxFailuresInPeriod: Int):
      Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("DELETE FROM NetworkNode WHERE NetworkNode.lastUpdateTimeStamp < ? OR NetworkNode.numFailureCount >= ?"
        )) { _stmt -> 
      _stmt.setLong(1,minLastSeenTimestamp)
      _stmt.setInt(2,maxFailuresInPeriod)
      _stmt.executeUpdate()
    }
  }

  public override fun updateNetworkNodeGroupSsid(
    nodeId: Long,
    groupSsid: String,
    endpointUrl: String,
  ): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("UPDATE NetworkNode SET groupSsid = ?, endpointUrl = ?  WHERE nodeId = ?"
        )) { _stmt -> 
      _stmt.setString(1,groupSsid)
      _stmt.setString(2,endpointUrl)
      _stmt.setLong(3,nodeId)
      _stmt.executeUpdate()
    }
  }

  public override fun getEndpointUrlByGroupSsid(ssid: String): String? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT endpointUrl FROM NetworkNode WHERE groupSsid = ?"
      )) { _stmt -> 
    _stmt.setString(1,ssid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }
}
