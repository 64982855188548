package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary
import com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay
import com.ustadmobile.lib.db.entities.StatementEntity
import com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay
import com.ustadmobile.lib.db.entities.XLangMapEntry
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StatementDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StatementDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StatementDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<StatementEntity>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override fun getOneStatement(): LiveData<StatementEntity?> {
    val _result = _dao.getOneStatement()
    return _result
  }

  public override fun findByStatementId(id: String): StatementEntity? {
    val _result = _dao.findByStatementId(id)
    return _result
  }

  public override fun findByStatementIdList(id: List<String>): List<StatementEntity> {
    val _result = _dao.findByStatementIdList(id)
    return _result
  }

  public override suspend fun getResults(query: DoorQuery): List<StatementReportData> {
    val _result = _dao.getResults(query)
    return _result
  }

  public override fun getListResults(query: DoorQuery):
      DataSourceFactory<Int, StatementEntityWithDisplayDetails> {
    val _result = _dao.getListResults(query)
    return _result
  }

  public override fun getPerson(): Person? {
    val _result = _dao.getPerson()
    return _result
  }

  public override fun getXLangMap(): XLangMapEntry? {
    val _result = _dao.getXLangMap()
    return _result
  }

  public override fun updateProgress(
    uid: Long,
    progress: Int,
    updateTime: Long,
  ): Unit {
    _dao.updateProgress(uid, progress, updateTime)
  }

  public override fun findPersonsWithContentEntryAttempts(
    contentEntryUid: Long,
    accountPersonUid: Long,
    searchText: String,
    sortOrder: Int,
  ): DataSourceFactory<Int, PersonWithAttemptsSummary> {
    val _result = _dao.findPersonsWithContentEntryAttempts(contentEntryUid, accountPersonUid,
        searchText, sortOrder)
    return _result
  }

  public override suspend fun getBestScoreForContentForPerson(contentEntryUid: Long,
      accountPersonUid: Long): ContentEntryStatementScoreProgress? {
    val _result = _dao.getBestScoreForContentForPerson(contentEntryUid, accountPersonUid)
    return _result
  }

  public override suspend fun findNextStudentNotMarkedForAssignment(assignmentUid: Long,
      currentStudentUid: Long): Long {
    val _result = _dao.findNextStudentNotMarkedForAssignment(assignmentUid, currentStudentUid)
    return _result
  }

  public override suspend fun findSubmittedStatementFromStudent(studentUid: Long,
      assignmentObjectUid: Long): StatementEntity? {
    val _result = _dao.findSubmittedStatementFromStudent(studentUid, assignmentObjectUid)
    return _result
  }

  public override fun findScoreStatementForStudent(studentUid: Long): StatementEntity? {
    val _result = _dao.findScoreStatementForStudent(studentUid)
    return _result
  }

  public override fun findSessionsForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, PersonWithSessionsDisplay> {
    val _result = _dao.findSessionsForPerson(contentEntryUid, accountPersonUid, personUid)
    return _result
  }

  public override fun findSessionDetailForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
    contextRegistration: String,
  ): DataSourceFactory<Int, StatementWithSessionDetailDisplay> {
    val _result = _dao.findSessionDetailForPerson(contentEntryUid, accountPersonUid, personUid,
        contextRegistration)
    return _result
  }

  public override suspend fun calculateScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? {
    val _result = _dao.calculateScoreForSession(contextRegistration)
    return _result
  }

  public override suspend fun findCompletedScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? {
    val _result = _dao.findCompletedScoreForSession(contextRegistration)
    return _result
  }

  public override suspend fun findLatestRegistrationStatement(accountPersonUid: Long,
      entryUid: Long): String? {
    val _result = _dao.findLatestRegistrationStatement(accountPersonUid, entryUid)
    return _result
  }

  public override fun insert(entity: StatementEntity): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: StatementEntity): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<StatementEntity>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<StatementEntity>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: StatementEntity): Unit {
    _dao.update(entity)
  }
}
