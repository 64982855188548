package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Clazz
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzWithDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndSchoolAndTerminology
import com.ustadmobile.lib.db.entities.ClazzWithListDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithSchool
import com.ustadmobile.lib.db.entities.CourseTerminology
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzDao_JdbcKt(
  public val _db: RoomDatabase,
) : ClazzDao() {
  public val _insertAdapterClazz_: EntityInsertionAdapter<Clazz> = object :
      EntityInsertionAdapter<Clazz>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Clazz (clazzUid, clazzName, clazzDesc, attendanceAverage, clazzHolidayUMCalendarUid, clazzScheuleUMCalendarUid, isClazzActive, clazzLocationUid, clazzStartTime, clazzEndTime, clazzFeatures, clazzSchoolUid, clazzEnrolmentPolicy, clazzTerminologyUid, clazzMasterChangeSeqNum, clazzLocalChangeSeqNum, clazzLastChangedBy, clazzLct, clazzTimeZone, clazzStudentsPersonGroupUid, clazzTeachersPersonGroupUid, clazzPendingStudentsPersonGroupUid, clazzParentsPersonGroupUid, clazzCode) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Clazz): Unit {
      if(entity.clazzUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzUid)
      }
      stmt.setString(2, entity.clazzName)
      stmt.setString(3, entity.clazzDesc)
      stmt.setFloat(4, entity.attendanceAverage)
      stmt.setLong(5, entity.clazzHolidayUMCalendarUid)
      stmt.setLong(6, entity.clazzScheuleUMCalendarUid)
      stmt.setBoolean(7, entity.isClazzActive)
      stmt.setLong(8, entity.clazzLocationUid)
      stmt.setLong(9, entity.clazzStartTime)
      stmt.setLong(10, entity.clazzEndTime)
      stmt.setLong(11, entity.clazzFeatures)
      stmt.setLong(12, entity.clazzSchoolUid)
      stmt.setInt(13, entity.clazzEnrolmentPolicy)
      stmt.setLong(14, entity.clazzTerminologyUid)
      stmt.setLong(15, entity.clazzMasterChangeSeqNum)
      stmt.setLong(16, entity.clazzLocalChangeSeqNum)
      stmt.setInt(17, entity.clazzLastChangedBy)
      stmt.setLong(18, entity.clazzLct)
      stmt.setString(19, entity.clazzTimeZone)
      stmt.setLong(20, entity.clazzStudentsPersonGroupUid)
      stmt.setLong(21, entity.clazzTeachersPersonGroupUid)
      stmt.setLong(22, entity.clazzPendingStudentsPersonGroupUid)
      stmt.setLong(23, entity.clazzParentsPersonGroupUid)
      stmt.setString(24, entity.clazzCode)
    }
  }

  public override fun insert(entity: Clazz): Long {
    val _retVal = _insertAdapterClazz_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Clazz): Long {
    val _retVal = _insertAdapterClazz_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Clazz>): Unit {
    _insertAdapterClazz_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: Clazz): Int {
    var _result = 0
    val _sql =
        "UPDATE Clazz SET clazzName = ?, clazzDesc = ?, attendanceAverage = ?, clazzHolidayUMCalendarUid = ?, clazzScheuleUMCalendarUid = ?, isClazzActive = ?, clazzLocationUid = ?, clazzStartTime = ?, clazzEndTime = ?, clazzFeatures = ?, clazzSchoolUid = ?, clazzEnrolmentPolicy = ?, clazzTerminologyUid = ?, clazzMasterChangeSeqNum = ?, clazzLocalChangeSeqNum = ?, clazzLastChangedBy = ?, clazzLct = ?, clazzTimeZone = ?, clazzStudentsPersonGroupUid = ?, clazzTeachersPersonGroupUid = ?, clazzPendingStudentsPersonGroupUid = ?, clazzParentsPersonGroupUid = ?, clazzCode = ? WHERE clazzUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.clazzName)
      _stmt.setString(2, entity.clazzDesc)
      _stmt.setFloat(3, entity.attendanceAverage)
      _stmt.setLong(4, entity.clazzHolidayUMCalendarUid)
      _stmt.setLong(5, entity.clazzScheuleUMCalendarUid)
      _stmt.setBoolean(6, entity.isClazzActive)
      _stmt.setLong(7, entity.clazzLocationUid)
      _stmt.setLong(8, entity.clazzStartTime)
      _stmt.setLong(9, entity.clazzEndTime)
      _stmt.setLong(10, entity.clazzFeatures)
      _stmt.setLong(11, entity.clazzSchoolUid)
      _stmt.setInt(12, entity.clazzEnrolmentPolicy)
      _stmt.setLong(13, entity.clazzTerminologyUid)
      _stmt.setLong(14, entity.clazzMasterChangeSeqNum)
      _stmt.setLong(15, entity.clazzLocalChangeSeqNum)
      _stmt.setInt(16, entity.clazzLastChangedBy)
      _stmt.setLong(17, entity.clazzLct)
      _stmt.setString(18, entity.clazzTimeZone)
      _stmt.setLong(19, entity.clazzStudentsPersonGroupUid)
      _stmt.setLong(20, entity.clazzTeachersPersonGroupUid)
      _stmt.setLong(21, entity.clazzPendingStudentsPersonGroupUid)
      _stmt.setLong(22, entity.clazzParentsPersonGroupUid)
      _stmt.setString(23, entity.clazzCode)
      _stmt.setLong(24, entity.clazzUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<Clazz>): Unit {
    val _sql =
        "UPDATE Clazz SET clazzName = ?, clazzDesc = ?, attendanceAverage = ?, clazzHolidayUMCalendarUid = ?, clazzScheuleUMCalendarUid = ?, isClazzActive = ?, clazzLocationUid = ?, clazzStartTime = ?, clazzEndTime = ?, clazzFeatures = ?, clazzSchoolUid = ?, clazzEnrolmentPolicy = ?, clazzTerminologyUid = ?, clazzMasterChangeSeqNum = ?, clazzLocalChangeSeqNum = ?, clazzLastChangedBy = ?, clazzLct = ?, clazzTimeZone = ?, clazzStudentsPersonGroupUid = ?, clazzTeachersPersonGroupUid = ?, clazzPendingStudentsPersonGroupUid = ?, clazzParentsPersonGroupUid = ?, clazzCode = ? WHERE clazzUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.clazzName)
        _stmt.setString(2, _entity.clazzDesc)
        _stmt.setFloat(3, _entity.attendanceAverage)
        _stmt.setLong(4, _entity.clazzHolidayUMCalendarUid)
        _stmt.setLong(5, _entity.clazzScheuleUMCalendarUid)
        _stmt.setBoolean(6, _entity.isClazzActive)
        _stmt.setLong(7, _entity.clazzLocationUid)
        _stmt.setLong(8, _entity.clazzStartTime)
        _stmt.setLong(9, _entity.clazzEndTime)
        _stmt.setLong(10, _entity.clazzFeatures)
        _stmt.setLong(11, _entity.clazzSchoolUid)
        _stmt.setInt(12, _entity.clazzEnrolmentPolicy)
        _stmt.setLong(13, _entity.clazzTerminologyUid)
        _stmt.setLong(14, _entity.clazzMasterChangeSeqNum)
        _stmt.setLong(15, _entity.clazzLocalChangeSeqNum)
        _stmt.setInt(16, _entity.clazzLastChangedBy)
        _stmt.setLong(17, _entity.clazzLct)
        _stmt.setString(18, _entity.clazzTimeZone)
        _stmt.setLong(19, _entity.clazzStudentsPersonGroupUid)
        _stmt.setLong(20, _entity.clazzTeachersPersonGroupUid)
        _stmt.setLong(21, _entity.clazzPendingStudentsPersonGroupUid)
        _stmt.setLong(22, _entity.clazzParentsPersonGroupUid)
        _stmt.setString(23, _entity.clazzCode)
        _stmt.setLong(24, _entity.clazzUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Clazz): Unit {
    val _sql =
        "UPDATE Clazz SET clazzName = ?, clazzDesc = ?, attendanceAverage = ?, clazzHolidayUMCalendarUid = ?, clazzScheuleUMCalendarUid = ?, isClazzActive = ?, clazzLocationUid = ?, clazzStartTime = ?, clazzEndTime = ?, clazzFeatures = ?, clazzSchoolUid = ?, clazzEnrolmentPolicy = ?, clazzTerminologyUid = ?, clazzMasterChangeSeqNum = ?, clazzLocalChangeSeqNum = ?, clazzLastChangedBy = ?, clazzLct = ?, clazzTimeZone = ?, clazzStudentsPersonGroupUid = ?, clazzTeachersPersonGroupUid = ?, clazzPendingStudentsPersonGroupUid = ?, clazzParentsPersonGroupUid = ?, clazzCode = ? WHERE clazzUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.clazzName)
      _stmt.setString(2, entity.clazzDesc)
      _stmt.setFloat(3, entity.attendanceAverage)
      _stmt.setLong(4, entity.clazzHolidayUMCalendarUid)
      _stmt.setLong(5, entity.clazzScheuleUMCalendarUid)
      _stmt.setBoolean(6, entity.isClazzActive)
      _stmt.setLong(7, entity.clazzLocationUid)
      _stmt.setLong(8, entity.clazzStartTime)
      _stmt.setLong(9, entity.clazzEndTime)
      _stmt.setLong(10, entity.clazzFeatures)
      _stmt.setLong(11, entity.clazzSchoolUid)
      _stmt.setInt(12, entity.clazzEnrolmentPolicy)
      _stmt.setLong(13, entity.clazzTerminologyUid)
      _stmt.setLong(14, entity.clazzMasterChangeSeqNum)
      _stmt.setLong(15, entity.clazzLocalChangeSeqNum)
      _stmt.setInt(16, entity.clazzLastChangedBy)
      _stmt.setLong(17, entity.clazzLct)
      _stmt.setString(18, entity.clazzTimeZone)
      _stmt.setLong(19, entity.clazzStudentsPersonGroupUid)
      _stmt.setLong(20, entity.clazzTeachersPersonGroupUid)
      _stmt.setLong(21, entity.clazzPendingStudentsPersonGroupUid)
      _stmt.setLong(22, entity.clazzParentsPersonGroupUid)
      _stmt.setString(23, entity.clazzCode)
      _stmt.setLong(24, entity.clazzUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzReplicate(clazzPk, clazzDestination)
    |      SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |             ? AS clazzDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ? 
    |         AND Clazz.clazzLct != COALESCE(
    |             (SELECT clazzVersionId
    |                FROM ClazzReplicate
    |               WHERE clazzPk = Clazz.clazzUid
    |                 AND clazzDestination = ?), 0) 
    |      /*psql ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |             SET clazzPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzReplicate(clazzPk, clazzDestination)
    |      SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |             ? AS clazzDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ? 
    |         AND Clazz.clazzLct != COALESCE(
    |             (SELECT clazzVersionId
    |                FROM ClazzReplicate
    |               WHERE clazzPk = Clazz.clazzUid
    |                 AND clazzDestination = ?), 0) 
    |       ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |             SET clazzPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzReplicate(clazzPk, clazzDestination)
    |  SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |         UserSession.usClientNodeId AS clazzDestination
    |    FROM ChangeLog
    |         JOIN Clazz
    |             ON ChangeLog.chTableId = 6
    |                AND ChangeLog.chEntityPk = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Clazz.clazzLct != COALESCE(
    |         (SELECT clazzVersionId
    |            FROM ClazzReplicate
    |           WHERE clazzPk = Clazz.clazzUid
    |             AND clazzDestination = UserSession.usClientNodeId), 0)
    |  /*psql ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |      SET clazzPending = true
    |   */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzReplicate(clazzPk, clazzDestination)
    |  SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |         UserSession.usClientNodeId AS clazzDestination
    |    FROM ChangeLog
    |         JOIN Clazz
    |             ON ChangeLog.chTableId = 6
    |                AND ChangeLog.chEntityPk = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Clazz.clazzLct != COALESCE(
    |         (SELECT clazzVersionId
    |            FROM ClazzReplicate
    |           WHERE clazzPk = Clazz.clazzUid
    |             AND clazzDestination = UserSession.usClientNodeId), 0)
    |   ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |      SET clazzPending = true
    |                  
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): Clazz? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzUid = ?" ))
      { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        Clazz().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<Clazz?> = LiveDataImpl(_db,
      listOf("Clazz"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * From Clazz WHERE clazzUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_clazzUid = _result.getLong("clazzUid")
          val _tmp_clazzName = _result.getString("clazzName")
          val _tmp_clazzDesc = _result.getString("clazzDesc")
          val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
          val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
          val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
          val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
          val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
          val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
          val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
          val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
          val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
          val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
          val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
          val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
          val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
          val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
          val _tmp_clazzLct = _result.getLong("clazzLct")
          val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
          val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
          val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
          val _tmp_clazzPendingStudentsPersonGroupUid =
              _result.getLong("clazzPendingStudentsPersonGroupUid")
          val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
          val _tmp_clazzCode = _result.getString("clazzCode")
          Clazz().apply {
            this.clazzUid = _tmp_clazzUid
            this.clazzName = _tmp_clazzName
            this.clazzDesc = _tmp_clazzDesc
            this.attendanceAverage = _tmp_attendanceAverage
            this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
            this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
            this.isClazzActive = _tmp_isClazzActive
            this.clazzLocationUid = _tmp_clazzLocationUid
            this.clazzStartTime = _tmp_clazzStartTime
            this.clazzEndTime = _tmp_clazzEndTime
            this.clazzFeatures = _tmp_clazzFeatures
            this.clazzSchoolUid = _tmp_clazzSchoolUid
            this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
            this.clazzTerminologyUid = _tmp_clazzTerminologyUid
            this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
            this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
            this.clazzLastChangedBy = _tmp_clazzLastChangedBy
            this.clazzLct = _tmp_clazzLct
            this.clazzTimeZone = _tmp_clazzTimeZone
            this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
            this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
            this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
            this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
            this.clazzCode = _tmp_clazzCode
          }
        }
      }
    }
  }

  public override suspend fun findByClazzCode(code: String): Clazz? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzCode = ?"
      )) { _stmt -> 
    _stmt.setString(1,code)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        Clazz().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
        }
      }
    }
  }

  public override suspend fun findByClazzCodeFromWeb(code: String): Clazz? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzCode = ?"
      )) { _stmt -> 
    _stmt.setString(1,code)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        Clazz().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
        }
      }
    }
  }

  public override fun findAllLive(): LiveData<List<Clazz>> = LiveDataImpl(_db, listOf("Clazz"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Clazz WHERE CAST(isClazzActive AS INTEGER) = 1"
        )) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_clazzUid = _result.getLong("clazzUid")
          val _tmp_clazzName = _result.getString("clazzName")
          val _tmp_clazzDesc = _result.getString("clazzDesc")
          val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
          val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
          val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
          val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
          val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
          val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
          val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
          val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
          val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
          val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
          val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
          val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
          val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
          val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
          val _tmp_clazzLct = _result.getLong("clazzLct")
          val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
          val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
          val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
          val _tmp_clazzPendingStudentsPersonGroupUid =
              _result.getLong("clazzPendingStudentsPersonGroupUid")
          val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
          val _tmp_clazzCode = _result.getString("clazzCode")
          Clazz().apply {
            this.clazzUid = _tmp_clazzUid
            this.clazzName = _tmp_clazzName
            this.clazzDesc = _tmp_clazzDesc
            this.attendanceAverage = _tmp_attendanceAverage
            this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
            this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
            this.isClazzActive = _tmp_isClazzActive
            this.clazzLocationUid = _tmp_clazzLocationUid
            this.clazzStartTime = _tmp_clazzStartTime
            this.clazzEndTime = _tmp_clazzEndTime
            this.clazzFeatures = _tmp_clazzFeatures
            this.clazzSchoolUid = _tmp_clazzSchoolUid
            this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
            this.clazzTerminologyUid = _tmp_clazzTerminologyUid
            this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
            this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
            this.clazzLastChangedBy = _tmp_clazzLastChangedBy
            this.clazzLct = _tmp_clazzLct
            this.clazzTimeZone = _tmp_clazzTimeZone
            this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
            this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
            this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
            this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
            this.clazzCode = _tmp_clazzCode
          }
        }
      }
    }
  }

  public override fun findAll(): List<Clazz> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Clazz WHERE CAST(isClazzActive AS INTEGER) = 1"
      )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        Clazz().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): Clazz? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        Clazz().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
        }
      }
    }
  }

  public override suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      ClazzWithHolidayCalendarAndSchoolAndTerminology? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Clazz.*, 
  |               HolidayCalendar.*, 
  |               School.*,
  |               CourseTerminology.*
  |          FROM Clazz 
  |               LEFT JOIN HolidayCalendar 
  |               ON Clazz.clazzHolidayUMCalendarUid = HolidayCalendar.umCalendarUid
  |               
  |               LEFT JOIN School 
  |               ON School.schoolUid = Clazz.clazzSchoolUid
  |               
  |               LEFT JOIN CourseTerminology
  |               ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
  |         WHERE Clazz.clazzUid = ?
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        var _tmp_HolidayCalendar_nullCount = 0
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarName = _result.getString("umCalendarName")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_HolidayCalendar_isAllNull = _tmp_HolidayCalendar_nullCount == 8
        var _tmp_School_nullCount = 0
        val _tmp_schoolUid = _result.getLong("schoolUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolName = _result.getString("schoolName")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolGender = _result.getInt("schoolGender")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolCode = _result.getString("schoolCode")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLct = _result.getLong("schoolLct")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_School_isAllNull = _tmp_School_nullCount == 21
        var _tmp_CourseTerminology_nullCount = 0
        val _tmp_ctUid = _result.getLong("ctUid")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_ctTitle = _result.getString("ctTitle")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_ctTerminology = _result.getString("ctTerminology")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_ctLct = _result.getLong("ctLct")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_CourseTerminology_isAllNull = _tmp_CourseTerminology_nullCount == 4
        ClazzWithHolidayCalendarAndSchoolAndTerminology().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
          if(!_tmp_HolidayCalendar_isAllNull) {
            this.holidayCalendar = HolidayCalendar().apply {
              this.umCalendarUid = _tmp_umCalendarUid
              this.umCalendarName = _tmp_umCalendarName
              this.umCalendarCategory = _tmp_umCalendarCategory
              this.umCalendarActive = _tmp_umCalendarActive
              this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
              this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
              this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
              this.umCalendarLct = _tmp_umCalendarLct
            }
          }
          if(!_tmp_School_isAllNull) {
            this.school = School().apply {
              this.schoolUid = _tmp_schoolUid
              this.schoolName = _tmp_schoolName
              this.schoolDesc = _tmp_schoolDesc
              this.schoolAddress = _tmp_schoolAddress
              this.schoolActive = _tmp_schoolActive
              this.schoolPhoneNumber = _tmp_schoolPhoneNumber
              this.schoolGender = _tmp_schoolGender
              this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
              this.schoolFeatures = _tmp_schoolFeatures
              this.schoolLocationLong = _tmp_schoolLocationLong
              this.schoolLocationLatt = _tmp_schoolLocationLatt
              this.schoolEmailAddress = _tmp_schoolEmailAddress
              this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
              this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
              this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
              this.schoolCode = _tmp_schoolCode
              this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
              this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
              this.schoolLastChangedBy = _tmp_schoolLastChangedBy
              this.schoolLct = _tmp_schoolLct
              this.schoolTimeZone = _tmp_schoolTimeZone
            }
          }
          if(!_tmp_CourseTerminology_isAllNull) {
            this.terminology = CourseTerminology().apply {
              this.ctUid = _tmp_ctUid
              this.ctTitle = _tmp_ctTitle
              this.ctTerminology = _tmp_ctTerminology
              this.ctLct = _tmp_ctLct
            }
          }
        }
      }
    }
  }

  public override suspend fun findAllClazzesBySchool(schoolUid: Long): List<Clazz> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 "
      )) { _stmt -> 
    _stmt.setLong(1,schoolUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        Clazz().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
        }
      }
    }
  }

  public override fun findAllClazzesBySchoolLive(schoolUid: Long): DataSourceFactory<Int, Clazz> =
      object : DataSourceFactory<Int, Clazz>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<Clazz>> =
        LiveDataImpl(_db, listOf("Clazz"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM (SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 ) LIMIT ? OFFSET ?"
          )) { _stmt -> 
        _stmt.setLong(1,schoolUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_clazzUid = _result.getLong("clazzUid")
            val _tmp_clazzName = _result.getString("clazzName")
            val _tmp_clazzDesc = _result.getString("clazzDesc")
            val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
            val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
            val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
            val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
            val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
            val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
            val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
            val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
            val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
            val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
            val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
            val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
            val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
            val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
            val _tmp_clazzLct = _result.getLong("clazzLct")
            val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
            val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
            val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
            val _tmp_clazzPendingStudentsPersonGroupUid =
                _result.getLong("clazzPendingStudentsPersonGroupUid")
            val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
            val _tmp_clazzCode = _result.getString("clazzCode")
            Clazz().apply {
              this.clazzUid = _tmp_clazzUid
              this.clazzName = _tmp_clazzName
              this.clazzDesc = _tmp_clazzDesc
              this.attendanceAverage = _tmp_attendanceAverage
              this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
              this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
              this.isClazzActive = _tmp_isClazzActive
              this.clazzLocationUid = _tmp_clazzLocationUid
              this.clazzStartTime = _tmp_clazzStartTime
              this.clazzEndTime = _tmp_clazzEndTime
              this.clazzFeatures = _tmp_clazzFeatures
              this.clazzSchoolUid = _tmp_clazzSchoolUid
              this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
              this.clazzTerminologyUid = _tmp_clazzTerminologyUid
              this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
              this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
              this.clazzLastChangedBy = _tmp_clazzLastChangedBy
              this.clazzLct = _tmp_clazzLct
              this.clazzTimeZone = _tmp_clazzTimeZone
              this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
              this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
              this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
              this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
              this.clazzCode = _tmp_clazzCode
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Clazz"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 ) "
          )) { _stmt -> 
        _stmt.setLong(1,schoolUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findClazzesWithPermission(
    searchQuery: String,
    accountPersonUid: Long,
    excludeSelectedClazzList: List<Long>,
    excludeSchoolUid: Long,
    sortOrder: Int,
    filter: Int,
    currentTime: Long,
    permission: Long,
    selectedSchool: Long,
  ): DataSourceFactory<Int, ClazzWithListDisplayDetails> = object :
      DataSourceFactory<Int, ClazzWithListDisplayDetails>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ClazzWithListDisplayDetails>> = LiveDataImpl(_db, listOf("Clazz",
        "ClazzEnrolment", "ScopedGrant", "PersonGroupMember", "CourseTerminology"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Clazz.*, ClazzEnrolment.*,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1000 
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1001
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
      |               '' AS teacherNames,
      |               0 AS lastRecorded,
      |               CourseTerminology.*
      |          FROM PersonGroupMember
      |               
      |               JOIN ScopedGrant
      |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                        AND (ScopedGrant.sgPermissions & 
      |        
      |                    ?
      |                    
      |                       ) > 0
      |               JOIN Clazz 
      |                    ON 
      |            ((ScopedGrant.sgTableId = -2
      |                                AND ScopedGrant.sgEntityUid = -2)
      |                            OR (ScopedGrant.sgTableId = 6
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
      |                            OR (ScopedGrant.sgTableId = 164
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
      |        
      |                  
      |               LEFT JOIN ClazzEnrolment 
      |                    ON ClazzEnrolment.clazzEnrolmentUid =
      |                       COALESCE(
      |                       (SELECT ClazzEnrolment.clazzEnrolmentUid 
      |                          FROM ClazzEnrolment
      |                         WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
      |                           AND ClazzEnrolment.clazzEnrolmentActive
      |                           AND ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid LIMIT 1), 0)
      |                LEFT JOIN CourseTerminology   
      |                ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid           
      |
      |         WHERE PersonGroupMember.groupMemberPersonUid = ?
      |           AND PersonGroupMember.groupMemberActive 
      |           AND CAST(Clazz.isClazzActive AS INTEGER) = 1
      |           AND Clazz.clazzName like ?
      |           AND (Clazz.clazzUid NOT IN (?))
      |           AND ( ? = 0 OR Clazz.clazzUid NOT IN (SELECT cl.clazzUid FROM Clazz AS cl WHERE cl.clazzSchoolUid = ?) ) 
      |           AND ( ? = 0 OR Clazz.clazzSchoolUid = 0 )
      |           AND ( ? = 0 OR (CASE WHEN ? = 5 
      |                                      THEN ? BETWEEN Clazz.clazzStartTime AND Clazz.clazzEndTime
      |                                      ELSE ? > Clazz.clazzEndTime 
      |                                      END))
      |           AND ( ? = 0 OR Clazz.clazzSchoolUid = ?)
      |      GROUP BY Clazz.clazzUid, ClazzEnrolment.clazzEnrolmentUid, CourseTerminology.ctUid
      |      ORDER BY CASE ?
      |               WHEN 3 THEN Clazz.attendanceAverage
      |               ELSE 0
      |               END ASC,
      |               CASE ?
      |               WHEN 1 THEN Clazz.clazzName
      |               ELSE ''
      |               END ASC,
      |               CASE ?
      |               WHEN 4 THEN Clazz.attendanceAverage
      |               ELSE 0
      |               END DESC,
      |               CASE ?
      |               WHEN 2 THEN clazz.Clazzname
      |               ELSE ''
      |               END DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setLong(1,currentTime)
        _stmt.setLong(2,currentTime)
        _stmt.setLong(3,permission)
        _stmt.setLong(4,accountPersonUid)
        _stmt.setLong(5,accountPersonUid)
        _stmt.setString(6,searchQuery)
        _stmt.setArray(7, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            excludeSelectedClazzList.toTypedArray()))
        _stmt.setLong(8,excludeSchoolUid)
        _stmt.setLong(9,excludeSchoolUid)
        _stmt.setLong(10,excludeSchoolUid)
        _stmt.setInt(11,filter)
        _stmt.setInt(12,filter)
        _stmt.setLong(13,currentTime)
        _stmt.setLong(14,currentTime)
        _stmt.setLong(15,selectedSchool)
        _stmt.setLong(16,selectedSchool)
        _stmt.setInt(17,sortOrder)
        _stmt.setInt(18,sortOrder)
        _stmt.setInt(19,sortOrder)
        _stmt.setInt(20,sortOrder)
        _stmt.setInt(21,_limit)
        _stmt.setInt(22,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_numStudents = _result.getInt("numStudents")
            val _tmp_numTeachers = _result.getInt("numTeachers")
            val _tmp_teacherNames = _result.getString("teacherNames")
            val _tmp_lastRecorded = _result.getLong("lastRecorded")
            val _tmp_clazzUid = _result.getLong("clazzUid")
            val _tmp_clazzName = _result.getString("clazzName")
            val _tmp_clazzDesc = _result.getString("clazzDesc")
            val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
            val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
            val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
            val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
            val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
            val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
            val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
            val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
            val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
            val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
            val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
            val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
            val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
            val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
            val _tmp_clazzLct = _result.getLong("clazzLct")
            val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
            val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
            val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
            val _tmp_clazzPendingStudentsPersonGroupUid =
                _result.getLong("clazzPendingStudentsPersonGroupUid")
            val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
            val _tmp_clazzCode = _result.getString("clazzCode")
            var _tmp_ClazzEnrolment_nullCount = 0
            val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentAttendancePercentage =
                _result.getFloat("clazzEnrolmentAttendancePercentage")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentLeavingReasonUid =
                _result.getLong("clazzEnrolmentLeavingReasonUid")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentLocalChangeSeqNum =
                _result.getLong("clazzEnrolmentLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentMasterChangeSeqNum =
                _result.getLong("clazzEnrolmentMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
            if(_result.wasNull()) _tmp_ClazzEnrolment_nullCount++
            val _tmp_ClazzEnrolment_isAllNull = _tmp_ClazzEnrolment_nullCount == 14
            var _tmp_CourseTerminology_nullCount = 0
            val _tmp_ctUid = _result.getLong("ctUid")
            if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
            val _tmp_ctTitle = _result.getString("ctTitle")
            if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
            val _tmp_ctTerminology = _result.getString("ctTerminology")
            if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
            val _tmp_ctLct = _result.getLong("ctLct")
            if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
            val _tmp_CourseTerminology_isAllNull = _tmp_CourseTerminology_nullCount == 4
            ClazzWithListDisplayDetails().apply {
              this.numStudents = _tmp_numStudents
              this.numTeachers = _tmp_numTeachers
              this.teacherNames = _tmp_teacherNames
              this.lastRecorded = _tmp_lastRecorded
              this.clazzUid = _tmp_clazzUid
              this.clazzName = _tmp_clazzName
              this.clazzDesc = _tmp_clazzDesc
              this.attendanceAverage = _tmp_attendanceAverage
              this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
              this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
              this.isClazzActive = _tmp_isClazzActive
              this.clazzLocationUid = _tmp_clazzLocationUid
              this.clazzStartTime = _tmp_clazzStartTime
              this.clazzEndTime = _tmp_clazzEndTime
              this.clazzFeatures = _tmp_clazzFeatures
              this.clazzSchoolUid = _tmp_clazzSchoolUid
              this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
              this.clazzTerminologyUid = _tmp_clazzTerminologyUid
              this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
              this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
              this.clazzLastChangedBy = _tmp_clazzLastChangedBy
              this.clazzLct = _tmp_clazzLct
              this.clazzTimeZone = _tmp_clazzTimeZone
              this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
              this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
              this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
              this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
              this.clazzCode = _tmp_clazzCode
              if(!_tmp_ClazzEnrolment_isAllNull) {
                this.clazzActiveEnrolment = ClazzEnrolment().apply {
                  this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
                  this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
                  this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
                  this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
                  this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
                  this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
                  this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
                  this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
                  this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
                  this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
                  this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
                  this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
                  this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
                  this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
                }
              }
              if(!_tmp_CourseTerminology_isAllNull) {
                this.terminology = CourseTerminology().apply {
                  this.ctUid = _tmp_ctUid
                  this.ctTitle = _tmp_ctTitle
                  this.ctTerminology = _tmp_ctTerminology
                  this.ctLct = _tmp_ctLct
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Clazz",
        "ClazzEnrolment", "ScopedGrant", "PersonGroupMember", "CourseTerminology"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Clazz.*, ClazzEnrolment.*,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1000 
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1001
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
      |               '' AS teacherNames,
      |               0 AS lastRecorded,
      |               CourseTerminology.*
      |          FROM PersonGroupMember
      |               
      |               JOIN ScopedGrant
      |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                        AND (ScopedGrant.sgPermissions & 
      |        
      |                    ?
      |                    
      |                       ) > 0
      |               JOIN Clazz 
      |                    ON 
      |            ((ScopedGrant.sgTableId = -2
      |                                AND ScopedGrant.sgEntityUid = -2)
      |                            OR (ScopedGrant.sgTableId = 6
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
      |                            OR (ScopedGrant.sgTableId = 164
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
      |        
      |                  
      |               LEFT JOIN ClazzEnrolment 
      |                    ON ClazzEnrolment.clazzEnrolmentUid =
      |                       COALESCE(
      |                       (SELECT ClazzEnrolment.clazzEnrolmentUid 
      |                          FROM ClazzEnrolment
      |                         WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
      |                           AND ClazzEnrolment.clazzEnrolmentActive
      |                           AND ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid LIMIT 1), 0)
      |                LEFT JOIN CourseTerminology   
      |                ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid           
      |
      |         WHERE PersonGroupMember.groupMemberPersonUid = ?
      |           AND PersonGroupMember.groupMemberActive 
      |           AND CAST(Clazz.isClazzActive AS INTEGER) = 1
      |           AND Clazz.clazzName like ?
      |           AND (Clazz.clazzUid NOT IN (?))
      |           AND ( ? = 0 OR Clazz.clazzUid NOT IN (SELECT cl.clazzUid FROM Clazz AS cl WHERE cl.clazzSchoolUid = ?) ) 
      |           AND ( ? = 0 OR Clazz.clazzSchoolUid = 0 )
      |           AND ( ? = 0 OR (CASE WHEN ? = 5 
      |                                      THEN ? BETWEEN Clazz.clazzStartTime AND Clazz.clazzEndTime
      |                                      ELSE ? > Clazz.clazzEndTime 
      |                                      END))
      |           AND ( ? = 0 OR Clazz.clazzSchoolUid = ?)
      |      GROUP BY Clazz.clazzUid, ClazzEnrolment.clazzEnrolmentUid, CourseTerminology.ctUid
      |      ORDER BY CASE ?
      |               WHEN 3 THEN Clazz.attendanceAverage
      |               ELSE 0
      |               END ASC,
      |               CASE ?
      |               WHEN 1 THEN Clazz.clazzName
      |               ELSE ''
      |               END ASC,
      |               CASE ?
      |               WHEN 4 THEN Clazz.attendanceAverage
      |               ELSE 0
      |               END DESC,
      |               CASE ?
      |               WHEN 2 THEN clazz.Clazzname
      |               ELSE ''
      |               END DESC
      |    ) 
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setLong(1,currentTime)
        _stmt.setLong(2,currentTime)
        _stmt.setLong(3,permission)
        _stmt.setLong(4,accountPersonUid)
        _stmt.setLong(5,accountPersonUid)
        _stmt.setString(6,searchQuery)
        _stmt.setArray(7, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            excludeSelectedClazzList.toTypedArray()))
        _stmt.setLong(8,excludeSchoolUid)
        _stmt.setLong(9,excludeSchoolUid)
        _stmt.setLong(10,excludeSchoolUid)
        _stmt.setInt(11,filter)
        _stmt.setInt(12,filter)
        _stmt.setLong(13,currentTime)
        _stmt.setLong(14,currentTime)
        _stmt.setLong(15,selectedSchool)
        _stmt.setLong(16,selectedSchool)
        _stmt.setInt(17,sortOrder)
        _stmt.setInt(18,sortOrder)
        _stmt.setInt(19,sortOrder)
        _stmt.setInt(20,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun getClassNamesFromListOfIds(ids: List<Long>): List<UidAndLabel> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT Clazz.clazzUid AS uid, Clazz.clazzName AS labelName From Clazz WHERE clazzUid IN (?)"
      ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT", ids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_uid = _result.getLong("uid")
        val _tmp_labelName = _result.getString("labelName")
        UidAndLabel().apply {
          this.uid = _tmp_uid
          this.labelName = _tmp_labelName
        }
      }
    }
  }

  public override fun findByClazzName(name: String): List<Clazz> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzName = ? and CAST(isClazzActive AS INTEGER) = 1"
      )) { _stmt -> 
    _stmt.setString(1,name)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        Clazz().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
        }
      }
    }
  }

  public override suspend fun updateClazzAttendanceAverageAsync(clazzUid: Long, timeChanged: Long):
      Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE Clazz 
    |           SET attendanceAverage = 
    |               COALESCE(CAST(
    |                    (SELECT SUM(clazzLogNumPresent) 
    |                       FROM ClazzLog 
    |                      WHERE clazzLogClazzUid = ?
    |                       AND clazzLogStatusFlag = 4) AS REAL) /
    |                    
    |                    CAST(MAX(1.0, 
    |                        (SELECT SUM(clazzLogNumPresent) + SUM(clazzLogNumPartial) + SUM(clazzLogNumAbsent)
    |                        FROM ClazzLog 
    |                       WHERE clazzLogClazzUid = ? 
    |                        AND clazzLogStatusFlag = 4)) AS REAL), 0),
    |               clazzLct = ?         
    |         WHERE clazzUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE Clazz 
    |           SET attendanceAverage = 
    |               COALESCE(CAST(
    |                    (SELECT SUM(clazzLogNumPresent) 
    |                       FROM ClazzLog 
    |                      WHERE clazzLogClazzUid = ?
    |                       AND clazzLogStatusFlag = 4) AS REAL) /
    |                    
    |                    CAST(GREATEST(1.0, 
    |                        (SELECT SUM(clazzLogNumPresent) + SUM(clazzLogNumPartial) + SUM(clazzLogNumAbsent)
    |                        FROM ClazzLog 
    |                       WHERE clazzLogClazzUid = ? 
    |                        AND clazzLogStatusFlag = 4)) AS REAL), 0),
    |               clazzLct = ?         
    |         WHERE clazzUid = ?
    |    
    """.trimMargin())) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.setLong(2,clazzUid)
      _stmt.setLong(3,timeChanged)
      _stmt.setLong(4,clazzUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun personHasPermissionWithClazz(
    accountPersonUid: Long,
    clazzUid: Long,
    permission: Long,
  ): Boolean = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT EXISTS( 
  |               SELECT PrsGrpMbr.groupMemberPersonUid
  |                  FROM Clazz
  |                       
  |            JOIN ScopedGrant
  |                 ON 
  |            ((ScopedGrant.sgTableId = -2
  |                                AND ScopedGrant.sgEntityUid = -2)
  |                            OR (ScopedGrant.sgTableId = 6
  |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
  |                            OR (ScopedGrant.sgTableId = 164
  |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
  |        
  |                    AND (ScopedGrant.sgPermissions & 
  |        
  |                          ?
  |                          
  |                                                       ) > 0
  |             JOIN PersonGroupMember AS PrsGrpMbr
  |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
  |        
  |                 WHERE Clazz.clazzUid = ?
  |                   AND PrsGrpMbr.groupMemberPersonUid = ?)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,permission)
    _stmt.setLong(2,clazzUid)
    _stmt.setLong(3,accountPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override suspend fun selectDelegatablePermissions(accountPersonUid: Long, clazzUid: Long):
      List<Long> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ScopedGrant.sgPermissions
  |          FROM Clazz
  |               JOIN ScopedGrant
  |                    ON 
  |            ((ScopedGrant.sgTableId = -2
  |                                AND ScopedGrant.sgEntityUid = -2)
  |                            OR (ScopedGrant.sgTableId = 6
  |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
  |                            OR (ScopedGrant.sgTableId = 164
  |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
  |        
  |               JOIN PersonGroupMember AS PrsGrpMbr
  |                    ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
  |         WHERE Clazz.clazzUid = ?
  |           AND (ScopedGrant.sgPermissions & 4294967296) > 0
  |           AND PrsGrpMbr.groupMemberPersonUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.setLong(2,accountPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override fun getClazzWithDisplayDetails(clazzUid: Long, currentTime: Long):
      LiveData<ClazzWithDisplayDetails?> = LiveDataImpl(_db, listOf("ClazzEnrolment", "Clazz",
      "HolidayCalendar", "School", "CourseTerminology"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT Clazz.*, 
    |               HolidayCalendar.*, 
    |               School.*,
    |               (SELECT COUNT(*) 
    |                  FROM ClazzEnrolment 
    |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
    |                   AND clazzEnrolmentRole = 1000 
    |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
    |               (SELECT COUNT(*) 
    |                  FROM ClazzEnrolment 
    |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
    |                   AND clazzEnrolmentRole = 1001 
    |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
    |                CourseTerminology.*      
    |         FROM Clazz 
    |              LEFT JOIN HolidayCalendar 
    |              ON Clazz.clazzHolidayUMCalendarUid = HolidayCalendar.umCalendarUid
    |              LEFT JOIN School 
    |              ON School.schoolUid = Clazz.clazzSchoolUid
    |              LEFT JOIN CourseTerminology
    |              ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
    |        WHERE Clazz.clazzUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,currentTime)
      _stmt.setLong(2,currentTime)
      _stmt.setLong(3,clazzUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_numStudents = _result.getInt("numStudents")
          val _tmp_numTeachers = _result.getInt("numTeachers")
          val _tmp_clazzUid = _result.getLong("clazzUid")
          val _tmp_clazzName = _result.getString("clazzName")
          val _tmp_clazzDesc = _result.getString("clazzDesc")
          val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
          val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
          val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
          val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
          val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
          val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
          val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
          val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
          val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
          val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
          val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
          val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
          val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
          val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
          val _tmp_clazzLct = _result.getLong("clazzLct")
          val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
          val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
          val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
          val _tmp_clazzPendingStudentsPersonGroupUid =
              _result.getLong("clazzPendingStudentsPersonGroupUid")
          val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
          val _tmp_clazzCode = _result.getString("clazzCode")
          var _tmp_HolidayCalendar_nullCount = 0
          val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_umCalendarName = _result.getString("umCalendarName")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
          if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
          val _tmp_HolidayCalendar_isAllNull = _tmp_HolidayCalendar_nullCount == 8
          var _tmp_School_nullCount = 0
          val _tmp_schoolUid = _result.getLong("schoolUid")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolName = _result.getString("schoolName")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolDesc = _result.getString("schoolDesc")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolAddress = _result.getString("schoolAddress")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolActive = _result.getBoolean("schoolActive")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolGender = _result.getInt("schoolGender")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolPendingStudentsPersonGroupUid =
              _result.getLong("schoolPendingStudentsPersonGroupUid")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolCode = _result.getString("schoolCode")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolLct = _result.getLong("schoolLct")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
          if(_result.wasNull()) _tmp_School_nullCount++
          val _tmp_School_isAllNull = _tmp_School_nullCount == 21
          var _tmp_CourseTerminology_nullCount = 0
          val _tmp_ctUid = _result.getLong("ctUid")
          if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
          val _tmp_ctTitle = _result.getString("ctTitle")
          if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
          val _tmp_ctTerminology = _result.getString("ctTerminology")
          if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
          val _tmp_ctLct = _result.getLong("ctLct")
          if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
          val _tmp_CourseTerminology_isAllNull = _tmp_CourseTerminology_nullCount == 4
          ClazzWithDisplayDetails().apply {
            this.numStudents = _tmp_numStudents
            this.numTeachers = _tmp_numTeachers
            this.clazzUid = _tmp_clazzUid
            this.clazzName = _tmp_clazzName
            this.clazzDesc = _tmp_clazzDesc
            this.attendanceAverage = _tmp_attendanceAverage
            this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
            this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
            this.isClazzActive = _tmp_isClazzActive
            this.clazzLocationUid = _tmp_clazzLocationUid
            this.clazzStartTime = _tmp_clazzStartTime
            this.clazzEndTime = _tmp_clazzEndTime
            this.clazzFeatures = _tmp_clazzFeatures
            this.clazzSchoolUid = _tmp_clazzSchoolUid
            this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
            this.clazzTerminologyUid = _tmp_clazzTerminologyUid
            this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
            this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
            this.clazzLastChangedBy = _tmp_clazzLastChangedBy
            this.clazzLct = _tmp_clazzLct
            this.clazzTimeZone = _tmp_clazzTimeZone
            this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
            this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
            this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
            this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
            this.clazzCode = _tmp_clazzCode
            if(!_tmp_HolidayCalendar_isAllNull) {
              this.clazzHolidayCalendar = HolidayCalendar().apply {
                this.umCalendarUid = _tmp_umCalendarUid
                this.umCalendarName = _tmp_umCalendarName
                this.umCalendarCategory = _tmp_umCalendarCategory
                this.umCalendarActive = _tmp_umCalendarActive
                this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
                this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
                this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
                this.umCalendarLct = _tmp_umCalendarLct
              }
            }
            if(!_tmp_School_isAllNull) {
              this.clazzSchool = School().apply {
                this.schoolUid = _tmp_schoolUid
                this.schoolName = _tmp_schoolName
                this.schoolDesc = _tmp_schoolDesc
                this.schoolAddress = _tmp_schoolAddress
                this.schoolActive = _tmp_schoolActive
                this.schoolPhoneNumber = _tmp_schoolPhoneNumber
                this.schoolGender = _tmp_schoolGender
                this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
                this.schoolFeatures = _tmp_schoolFeatures
                this.schoolLocationLong = _tmp_schoolLocationLong
                this.schoolLocationLatt = _tmp_schoolLocationLatt
                this.schoolEmailAddress = _tmp_schoolEmailAddress
                this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
                this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
                this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
                this.schoolCode = _tmp_schoolCode
                this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
                this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
                this.schoolLastChangedBy = _tmp_schoolLastChangedBy
                this.schoolLct = _tmp_schoolLct
                this.schoolTimeZone = _tmp_schoolTimeZone
              }
            }
            if(!_tmp_CourseTerminology_isAllNull) {
              this.terminology = CourseTerminology().apply {
                this.ctUid = _tmp_ctUid
                this.ctTitle = _tmp_ctTitle
                this.ctTerminology = _tmp_ctTerminology
                this.ctLct = _tmp_ctLct
              }
            }
          }
        }
      }
    }
  }

  public override fun findClazzesWithEffectiveHolidayCalendarAndFilter(filterUid: Long):
      List<ClazzWithHolidayCalendarAndSchoolAndTerminology> =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT Clazz.*, 
  |               HolidayCalendar.*, 
  |               School.*,
  |               CourseTerminology.*
  |         FROM Clazz 
  |              LEFT JOIN HolidayCalendar 
  |              ON ((clazz.clazzHolidayUMCalendarUid != 0 
  |                AND HolidayCalendar.umCalendarUid = clazz.clazzHolidayUMCalendarUid)
  |                OR clazz.clazzHolidayUMCalendarUid = 0 AND clazz.clazzSchoolUid = 0 
  |                AND HolidayCalendar.umCalendarUid = (SELECT schoolHolidayCalendarUid 
  |                                                       FROM School 
  |                                                      WHERE schoolUid = clazz.clazzSchoolUid))
  |              LEFT JOIN School 
  |              ON School.schoolUid = Clazz.clazzSchoolUid
  |              
  |              LEFT JOIN CourseTerminology
  |              ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
  |                
  |        WHERE ? = 0 
  |           OR Clazz.clazzUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,filterUid)
    _stmt.setLong(2,filterUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        var _tmp_HolidayCalendar_nullCount = 0
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarName = _result.getString("umCalendarName")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_HolidayCalendar_isAllNull = _tmp_HolidayCalendar_nullCount == 8
        var _tmp_School_nullCount = 0
        val _tmp_schoolUid = _result.getLong("schoolUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolName = _result.getString("schoolName")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolGender = _result.getInt("schoolGender")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolCode = _result.getString("schoolCode")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLct = _result.getLong("schoolLct")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_School_isAllNull = _tmp_School_nullCount == 21
        var _tmp_CourseTerminology_nullCount = 0
        val _tmp_ctUid = _result.getLong("ctUid")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_ctTitle = _result.getString("ctTitle")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_ctTerminology = _result.getString("ctTerminology")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_ctLct = _result.getLong("ctLct")
        if(_result.wasNull()) _tmp_CourseTerminology_nullCount++
        val _tmp_CourseTerminology_isAllNull = _tmp_CourseTerminology_nullCount == 4
        ClazzWithHolidayCalendarAndSchoolAndTerminology().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
          if(!_tmp_HolidayCalendar_isAllNull) {
            this.holidayCalendar = HolidayCalendar().apply {
              this.umCalendarUid = _tmp_umCalendarUid
              this.umCalendarName = _tmp_umCalendarName
              this.umCalendarCategory = _tmp_umCalendarCategory
              this.umCalendarActive = _tmp_umCalendarActive
              this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
              this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
              this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
              this.umCalendarLct = _tmp_umCalendarLct
            }
          }
          if(!_tmp_School_isAllNull) {
            this.school = School().apply {
              this.schoolUid = _tmp_schoolUid
              this.schoolName = _tmp_schoolName
              this.schoolDesc = _tmp_schoolDesc
              this.schoolAddress = _tmp_schoolAddress
              this.schoolActive = _tmp_schoolActive
              this.schoolPhoneNumber = _tmp_schoolPhoneNumber
              this.schoolGender = _tmp_schoolGender
              this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
              this.schoolFeatures = _tmp_schoolFeatures
              this.schoolLocationLong = _tmp_schoolLocationLong
              this.schoolLocationLatt = _tmp_schoolLocationLatt
              this.schoolEmailAddress = _tmp_schoolEmailAddress
              this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
              this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
              this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
              this.schoolCode = _tmp_schoolCode
              this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
              this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
              this.schoolLastChangedBy = _tmp_schoolLastChangedBy
              this.schoolLct = _tmp_schoolLct
              this.schoolTimeZone = _tmp_schoolTimeZone
            }
          }
          if(!_tmp_CourseTerminology_isAllNull) {
            this.terminology = CourseTerminology().apply {
              this.ctUid = _tmp_ctUid
              this.ctTitle = _tmp_ctTitle
              this.ctTerminology = _tmp_ctTerminology
              this.ctLct = _tmp_ctLct
            }
          }
        }
      }
    }
  }

  public override suspend fun getClazzWithSchool(clazzUid: Long): ClazzWithSchool? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT Clazz.*, School.* FROM Clazz LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid WHERE clazz.clazzUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_clazzName = _result.getString("clazzName")
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        val _tmp_clazzLct = _result.getLong("clazzLct")
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        val _tmp_clazzCode = _result.getString("clazzCode")
        var _tmp_School_nullCount = 0
        val _tmp_schoolUid = _result.getLong("schoolUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolName = _result.getString("schoolName")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolGender = _result.getInt("schoolGender")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolCode = _result.getString("schoolCode")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolLct = _result.getLong("schoolLct")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        if(_result.wasNull()) _tmp_School_nullCount++
        val _tmp_School_isAllNull = _tmp_School_nullCount == 21
        ClazzWithSchool().apply {
          this.clazzUid = _tmp_clazzUid
          this.clazzName = _tmp_clazzName
          this.clazzDesc = _tmp_clazzDesc
          this.attendanceAverage = _tmp_attendanceAverage
          this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
          this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
          this.isClazzActive = _tmp_isClazzActive
          this.clazzLocationUid = _tmp_clazzLocationUid
          this.clazzStartTime = _tmp_clazzStartTime
          this.clazzEndTime = _tmp_clazzEndTime
          this.clazzFeatures = _tmp_clazzFeatures
          this.clazzSchoolUid = _tmp_clazzSchoolUid
          this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
          this.clazzTerminologyUid = _tmp_clazzTerminologyUid
          this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
          this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
          this.clazzLastChangedBy = _tmp_clazzLastChangedBy
          this.clazzLct = _tmp_clazzLct
          this.clazzTimeZone = _tmp_clazzTimeZone
          this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
          this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
          this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
          this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
          this.clazzCode = _tmp_clazzCode
          if(!_tmp_School_isAllNull) {
            this.school = School().apply {
              this.schoolUid = _tmp_schoolUid
              this.schoolName = _tmp_schoolName
              this.schoolDesc = _tmp_schoolDesc
              this.schoolAddress = _tmp_schoolAddress
              this.schoolActive = _tmp_schoolActive
              this.schoolPhoneNumber = _tmp_schoolPhoneNumber
              this.schoolGender = _tmp_schoolGender
              this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
              this.schoolFeatures = _tmp_schoolFeatures
              this.schoolLocationLong = _tmp_schoolLocationLong
              this.schoolLocationLatt = _tmp_schoolLocationLatt
              this.schoolEmailAddress = _tmp_schoolEmailAddress
              this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
              this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
              this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
              this.schoolCode = _tmp_schoolCode
              this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
              this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
              this.schoolLastChangedBy = _tmp_schoolLastChangedBy
              this.schoolLct = _tmp_schoolLct
              this.schoolTimeZone = _tmp_schoolTimeZone
            }
          }
        }
      }
    }
  }
}
