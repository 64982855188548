package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LocallyAvailableContainer
import kotlin.Boolean
import kotlin.Unit
import kotlin.collections.List

public class LocallyAvailableContainerDao_JdbcKt(
  public val _db: RoomDatabase,
) : LocallyAvailableContainerDao() {
  public val _insertAdapterLocallyAvailableContainer_upsert:
      EntityInsertionAdapter<LocallyAvailableContainer> = object :
      EntityInsertionAdapter<LocallyAvailableContainer>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO LocallyAvailableContainer (laContainerUid) VALUES(?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: LocallyAvailableContainer): Unit {
      stmt.setLong(1, entity.laContainerUid)
    }
  }

  public override suspend
      fun insertList(locallyAvailableContainers: List<LocallyAvailableContainer>): Unit {
    _insertAdapterLocallyAvailableContainer_upsert.insertListAsync(locallyAvailableContainers)
  }

  public override suspend
      fun deleteList(locallyAvailableContainers: List<LocallyAvailableContainer>): Unit {
    var _numChanges = 0
    _db.prepareAndUseStatementAsync("DELETE FROM LocallyAvailableContainer WHERE laContainerUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in locallyAvailableContainers) {
        _stmt.setLong(1, _entity.laContainerUid)
        _numChanges += _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun deleteAll(): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("DELETE FROM LocallyAvailableContainer" )) {
        _stmt -> 
      _stmt.executeUpdate()
    }
  }
}
