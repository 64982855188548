package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.AccessToken
import com.ustadmobile.lib.db.entities.AuditLog
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAuth
import com.ustadmobile.lib.db.entities.PersonGroup
import com.ustadmobile.lib.db.entities.PersonGroupMember
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonUidAndPasswordHash
import com.ustadmobile.lib.db.entities.PersonWithAccount
import com.ustadmobile.lib.db.entities.PersonWithDisplayDetails
import com.ustadmobile.lib.db.entities.PersonWithPersonParentJoin
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonDao_JdbcKt(
  public val _db: RoomDatabase,
) : PersonDao() {
  public val _insertAdapterPerson_: EntityInsertionAdapter<Person> = object :
      EntityInsertionAdapter<Person>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, dateOfBirth, personAddress, personOrgId, personGroupUid, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, personLct, personCountry, personType) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Person): Unit {
      if(entity.personUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personUid)
      }
      stmt.setString(2, entity.username)
      stmt.setString(3, entity.firstNames)
      stmt.setString(4, entity.lastName)
      stmt.setString(5, entity.emailAddr)
      stmt.setString(6, entity.phoneNum)
      stmt.setInt(7, entity.gender)
      stmt.setBoolean(8, entity.active)
      stmt.setBoolean(9, entity.admin)
      stmt.setString(10, entity.personNotes)
      stmt.setString(11, entity.fatherName)
      stmt.setString(12, entity.fatherNumber)
      stmt.setString(13, entity.motherName)
      stmt.setString(14, entity.motherNum)
      stmt.setLong(15, entity.dateOfBirth)
      stmt.setString(16, entity.personAddress)
      stmt.setString(17, entity.personOrgId)
      stmt.setLong(18, entity.personGroupUid)
      stmt.setLong(19, entity.personMasterChangeSeqNum)
      stmt.setLong(20, entity.personLocalChangeSeqNum)
      stmt.setInt(21, entity.personLastChangedBy)
      stmt.setLong(22, entity.personLct)
      stmt.setString(23, entity.personCountry)
      stmt.setInt(24, entity.personType)
    }
  }

  public val _insertAdapterPerson_upsert: EntityInsertionAdapter<Person> = object :
      EntityInsertionAdapter<Person>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, dateOfBirth, personAddress, personOrgId, personGroupUid, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, personLct, personCountry, personType) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Person): Unit {
      if(entity.personUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personUid)
      }
      stmt.setString(2, entity.username)
      stmt.setString(3, entity.firstNames)
      stmt.setString(4, entity.lastName)
      stmt.setString(5, entity.emailAddr)
      stmt.setString(6, entity.phoneNum)
      stmt.setInt(7, entity.gender)
      stmt.setBoolean(8, entity.active)
      stmt.setBoolean(9, entity.admin)
      stmt.setString(10, entity.personNotes)
      stmt.setString(11, entity.fatherName)
      stmt.setString(12, entity.fatherNumber)
      stmt.setString(13, entity.motherName)
      stmt.setString(14, entity.motherNum)
      stmt.setLong(15, entity.dateOfBirth)
      stmt.setString(16, entity.personAddress)
      stmt.setString(17, entity.personOrgId)
      stmt.setLong(18, entity.personGroupUid)
      stmt.setLong(19, entity.personMasterChangeSeqNum)
      stmt.setLong(20, entity.personLocalChangeSeqNum)
      stmt.setInt(21, entity.personLastChangedBy)
      stmt.setLong(22, entity.personLct)
      stmt.setString(23, entity.personCountry)
      stmt.setInt(24, entity.personType)
    }
  }

  public val _insertAdapterAccessToken_: EntityInsertionAdapter<AccessToken> = object :
      EntityInsertionAdapter<AccessToken>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO AccessToken (token, accessTokenPersonUid, expires) VALUES(?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: AccessToken):
        Unit {
      stmt.setString(1, entity.token)
      stmt.setLong(2, entity.accessTokenPersonUid)
      stmt.setLong(3, entity.expires)
    }
  }

  public val _insertAdapterPersonAuth_: EntityInsertionAdapter<PersonAuth> = object :
      EntityInsertionAdapter<PersonAuth>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO PersonAuth (personAuthUid, passwordHash, personAuthStatus) VALUES(?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonAuth):
        Unit {
      if(entity.personAuthUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personAuthUid)
      }
      stmt.setString(2, entity.passwordHash)
      stmt.setInt(3, entity.personAuthStatus)
    }
  }

  public val _insertAdapterPersonGroup_: EntityInsertionAdapter<PersonGroup> = object :
      EntityInsertionAdapter<PersonGroup>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO PersonGroup (groupUid, groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonGroup):
        Unit {
      if(entity.groupUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupUid)
      }
      stmt.setLong(2, entity.groupMasterCsn)
      stmt.setLong(3, entity.groupLocalCsn)
      stmt.setInt(4, entity.groupLastChangedBy)
      stmt.setLong(5, entity.groupLct)
      stmt.setString(6, entity.groupName)
      stmt.setBoolean(7, entity.groupActive)
      stmt.setInt(8, entity.personGroupFlag)
    }
  }

  public val _insertAdapterPersonGroupMember_: EntityInsertionAdapter<PersonGroupMember> = object :
      EntityInsertionAdapter<PersonGroupMember>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO PersonGroupMember (groupMemberUid, groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: PersonGroupMember): Unit {
      if(entity.groupMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupMemberUid)
      }
      stmt.setBoolean(2, entity.groupMemberActive)
      stmt.setLong(3, entity.groupMemberPersonUid)
      stmt.setLong(4, entity.groupMemberGroupUid)
      stmt.setLong(5, entity.groupMemberMasterCsn)
      stmt.setLong(6, entity.groupMemberLocalCsn)
      stmt.setInt(7, entity.groupMemberLastChangedBy)
      stmt.setLong(8, entity.groupMemberLct)
    }
  }

  public val _insertAdapterAuditLog_: EntityInsertionAdapter<AuditLog> = object :
      EntityInsertionAdapter<AuditLog>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO AuditLog (auditLogUid, auditLogMasterChangeSeqNum, auditLogLocalChangeSeqNum, auditLogLastChangedBy, auditLogLct, auditLogActorPersonUid, auditLogTableUid, auditLogEntityUid, auditLogDate, notes) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: AuditLog): Unit {
      if(entity.auditLogUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.auditLogUid)
      }
      stmt.setLong(2, entity.auditLogMasterChangeSeqNum)
      stmt.setLong(3, entity.auditLogLocalChangeSeqNum)
      stmt.setInt(4, entity.auditLogLastChangedBy)
      stmt.setLong(5, entity.auditLogLct)
      stmt.setLong(6, entity.auditLogActorPersonUid)
      stmt.setInt(7, entity.auditLogTableUid)
      stmt.setLong(8, entity.auditLogEntityUid)
      stmt.setLong(9, entity.auditLogDate)
      stmt.setString(10, entity.notes)
    }
  }

  public override suspend fun insertListAsync(entityList: List<Person>): Unit {
    _insertAdapterPerson_.insertListAsync(entityList)
  }

  public override suspend fun insertOrReplace(person: Person): Unit {
    _insertAdapterPerson_upsert.insertAsync(person)
  }

  public override fun insertAccessToken(token: AccessToken): Unit {
    _insertAdapterAccessToken_.insert(token)
  }

  public override fun insertPersonAuth(personAuth: PersonAuth): Unit {
    _insertAdapterPersonAuth_.insert(personAuth)
  }

  public override suspend fun insertPersonGroup(personGroup: PersonGroup): Long {
    val _retVal = _insertAdapterPersonGroup_.insertAndReturnIdAsync(personGroup)
    return _retVal
  }

  public override suspend fun insertPersonGroupMember(personGroupMember: PersonGroupMember): Long {
    val _retVal = _insertAdapterPersonGroupMember_.insertAndReturnIdAsync(personGroupMember)
    return _retVal
  }

  public override fun insertAuditLog(entity: AuditLog): Long {
    val _retVal = _insertAdapterAuditLog_.insertAndReturnId(entity)
    return _retVal
  }

  public override fun insert(entity: Person): Long {
    val _retVal = _insertAdapterPerson_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Person): Long {
    val _retVal = _insertAdapterPerson_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Person>): Unit {
    _insertAdapterPerson_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: Person): Int {
    var _result = 0
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, personLct = ?, personCountry = ?, personType = ? WHERE personUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.username)
      _stmt.setString(2, entity.firstNames)
      _stmt.setString(3, entity.lastName)
      _stmt.setString(4, entity.emailAddr)
      _stmt.setString(5, entity.phoneNum)
      _stmt.setInt(6, entity.gender)
      _stmt.setBoolean(7, entity.active)
      _stmt.setBoolean(8, entity.admin)
      _stmt.setString(9, entity.personNotes)
      _stmt.setString(10, entity.fatherName)
      _stmt.setString(11, entity.fatherNumber)
      _stmt.setString(12, entity.motherName)
      _stmt.setString(13, entity.motherNum)
      _stmt.setLong(14, entity.dateOfBirth)
      _stmt.setString(15, entity.personAddress)
      _stmt.setString(16, entity.personOrgId)
      _stmt.setLong(17, entity.personGroupUid)
      _stmt.setLong(18, entity.personMasterChangeSeqNum)
      _stmt.setLong(19, entity.personLocalChangeSeqNum)
      _stmt.setInt(20, entity.personLastChangedBy)
      _stmt.setLong(21, entity.personLct)
      _stmt.setString(22, entity.personCountry)
      _stmt.setInt(23, entity.personType)
      _stmt.setLong(24, entity.personUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<Person>): Unit {
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, personLct = ?, personCountry = ?, personType = ? WHERE personUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.username)
        _stmt.setString(2, _entity.firstNames)
        _stmt.setString(3, _entity.lastName)
        _stmt.setString(4, _entity.emailAddr)
        _stmt.setString(5, _entity.phoneNum)
        _stmt.setInt(6, _entity.gender)
        _stmt.setBoolean(7, _entity.active)
        _stmt.setBoolean(8, _entity.admin)
        _stmt.setString(9, _entity.personNotes)
        _stmt.setString(10, _entity.fatherName)
        _stmt.setString(11, _entity.fatherNumber)
        _stmt.setString(12, _entity.motherName)
        _stmt.setString(13, _entity.motherNum)
        _stmt.setLong(14, _entity.dateOfBirth)
        _stmt.setString(15, _entity.personAddress)
        _stmt.setString(16, _entity.personOrgId)
        _stmt.setLong(17, _entity.personGroupUid)
        _stmt.setLong(18, _entity.personMasterChangeSeqNum)
        _stmt.setLong(19, _entity.personLocalChangeSeqNum)
        _stmt.setInt(20, _entity.personLastChangedBy)
        _stmt.setLong(21, _entity.personLct)
        _stmt.setString(22, _entity.personCountry)
        _stmt.setInt(23, _entity.personType)
        _stmt.setLong(24, _entity.personUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Person): Unit {
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, personLct = ?, personCountry = ?, personType = ? WHERE personUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.username)
      _stmt.setString(2, entity.firstNames)
      _stmt.setString(3, entity.lastName)
      _stmt.setString(4, entity.emailAddr)
      _stmt.setString(5, entity.phoneNum)
      _stmt.setInt(6, entity.gender)
      _stmt.setBoolean(7, entity.active)
      _stmt.setBoolean(8, entity.admin)
      _stmt.setString(9, entity.personNotes)
      _stmt.setString(10, entity.fatherName)
      _stmt.setString(11, entity.fatherNumber)
      _stmt.setString(12, entity.motherName)
      _stmt.setString(13, entity.motherNum)
      _stmt.setLong(14, entity.dateOfBirth)
      _stmt.setString(15, entity.personAddress)
      _stmt.setString(16, entity.personOrgId)
      _stmt.setLong(17, entity.personGroupUid)
      _stmt.setLong(18, entity.personMasterChangeSeqNum)
      _stmt.setLong(19, entity.personLocalChangeSeqNum)
      _stmt.setInt(20, entity.personLastChangedBy)
      _stmt.setLong(21, entity.personLct)
      _stmt.setString(22, entity.personCountry)
      _stmt.setInt(23, entity.personType)
      _stmt.setLong(24, entity.personUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonReplicate(personPk, personDestination)
    |      SELECT DISTINCT Person.personUid AS personUid,
    |             ? AS personDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                   
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                   64
    |                   
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |       WHERE Person.personType = 0
    |         AND UserSession.usClientNodeId = ?
    |         AND Person.personLct != COALESCE(
    |             (SELECT personVersionId
    |                FROM PersonReplicate
    |               WHERE personPk = Person.personUid
    |                 AND personDestination = ?), 0)              
    |      /*psql ON CONFLICT(personPk, personDestination) DO UPDATE
    |             SET personPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonReplicate(personPk, personDestination)
    |      SELECT DISTINCT Person.personUid AS personUid,
    |             ? AS personDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                   
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                   64
    |                   
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |       WHERE Person.personType = 0
    |         AND UserSession.usClientNodeId = ?
    |         AND Person.personLct != COALESCE(
    |             (SELECT personVersionId
    |                FROM PersonReplicate
    |               WHERE personPk = Person.personUid
    |                 AND personDestination = ?), 0)              
    |       ON CONFLICT(personPk, personDestination) DO UPDATE
    |             SET personPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonReplicate(personPk, personDestination)
    |  SELECT DISTINCT Person.personUid AS personUid,
    |         UserSession.usClientNodeId AS personDestination
    |    FROM ChangeLog
    |         JOIN Person
    |             ON ChangeLog.chTableId = 9
    |                AND ChangeLog.chEntityPk = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE Person.personType = 0
    |     AND UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Person.personLct != COALESCE(
    |         (SELECT personVersionId
    |            FROM PersonReplicate
    |           WHERE personPk = Person.personUid
    |             AND personDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(personPk, personDestination) DO UPDATE
    |     SET personPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonReplicate(personPk, personDestination)
    |  SELECT DISTINCT Person.personUid AS personUid,
    |         UserSession.usClientNodeId AS personDestination
    |    FROM ChangeLog
    |         JOIN Person
    |             ON ChangeLog.chTableId = 9
    |                AND ChangeLog.chEntityPk = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE Person.personType = 0
    |     AND UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Person.personLct != COALESCE(
    |         (SELECT personVersionId
    |            FROM PersonReplicate
    |           WHERE personPk = Person.personUid
    |             AND personDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(personPk, personDestination) DO UPDATE
    |     SET personPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByUsernameCount(username: String): Int =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT COUNT(*) FROM Person where Person.username = ?"
      )) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  public override fun isValidToken(token: String, personUid: Long): Boolean =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT EXISTS(SELECT token FROM AccessToken WHERE token = ?  and accessTokenPersonUid = ?)"
      )) { _stmt -> 
    _stmt.setString(1,token)
    _stmt.setLong(2,personUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override suspend fun findUidAndPasswordHashAsync(username: String):
      PersonUidAndPasswordHash? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Person.personUid, Person.admin, Person.firstNames, Person.lastName, 
  |               PersonAuth.passwordHash
  |          FROM Person
  |               JOIN PersonAuth
  |                    ON Person.personUid = PersonAuth.personAuthUid
  |         WHERE Person.username = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_passwordHash = _result.getString("passwordHash")
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_admin = _result.getBoolean("admin")
        PersonUidAndPasswordHash().apply {
          this.passwordHash = _tmp_passwordHash
          this.personUid = _tmp_personUid
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.admin = _tmp_admin
        }
      }
    }
  }

  public override suspend fun findByUsernameAndPasswordHash2(username: String,
      passwordHash: String): Person? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Person.*
  |          FROM Person
  |               JOIN PersonAuth2
  |                    ON Person.personUid = PersonAuth2.pauthUid
  |         WHERE Person.username = ? 
  |               AND PersonAuth2.pauthAuth = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.setString(2,passwordHash)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override suspend fun personHasPermissionAsync(
    accountPersonUid: Long,
    personUid: Long,
    permission: Long,
  ): Boolean = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT EXISTS(
  |                SELECT 1
  |                  FROM Person
  |                  JOIN ScopedGrant
  |                       ON 
  |                ((ScopedGrant.sgTableId = -2
  |                    AND ScopedGrant.sgEntityUid = -2)
  |                 OR (ScopedGrant.sgTableId = 9
  |                    AND ScopedGrant.sgEntityUid = Person.personUid)
  |                 OR (ScopedGrant.sgTableId = 6       
  |                    AND Person.personUid IN (
  |                        SELECT DISTINCT clazzEnrolmentPersonUid
  |                          FROM ClazzEnrolment
  |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
  |                           AND ClazzEnrolment.clazzEnrolmentActive))
  |                 OR (ScopedGrant.sgTableId = 164
  |                    AND Person.personUid IN (
  |                        SELECT DISTINCT schoolMemberPersonUid
  |                          FROM SchoolMember
  |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
  |                           AND schoolMemberActive))
  |                           )    
  |        
  |                  JOIN PersonGroupMember 
  |                       ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
  |                 WHERE Person.personUid = ?
  |                   AND (ScopedGrant.sgPermissions & ?) > 0
  |                   AND PersonGroupMember.groupMemberPersonUid = ?
  |                 LIMIT 1)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.setLong(2,permission)
    _stmt.setLong(3,accountPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override suspend fun personIsAdmin(accountPersonUid: Long): Boolean =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT COALESCE((SELECT admin FROM Person WHERE personUid = ?), 0)"
      , postgreSql = "SELECT COALESCE((SELECT admin FROM Person WHERE personUid = ?), FALSE)")) {
      _stmt -> 
    _stmt.setLong(1,accountPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override fun findByUsername(username: String?): Person? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT Person.* FROM PERSON Where Person.username = ?"
      )) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override suspend fun findSystemAccount(nodeId: Long): Person? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Person.*
  |          FROM Person
  |         WHERE Person.dateOfBirth = ?
  |           AND Person.personType = 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,nodeId)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override fun findByUid(uid: Long): Person? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM PERSON WHERE Person.personUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override suspend fun findPersonAccountByUid(uid: Long): PersonWithAccount? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT Person.*, null as newPassword, null as currentPassword,null as confirmedPassword FROM PERSON WHERE Person.personUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_currentPassword = _result.getString("currentPassword")
        val _tmp_newPassword = _result.getString("newPassword")
        val _tmp_confirmedPassword = _result.getString("confirmedPassword")
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        PersonWithAccount().apply {
          this.currentPassword = _tmp_currentPassword
          this.newPassword = _tmp_newPassword
          this.confirmedPassword = _tmp_confirmedPassword
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<Person?> = LiveDataImpl(_db,
      listOf("Person"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * From Person WHERE personUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_personUid = _result.getLong("personUid")
          val _tmp_username = _result.getString("username")
          val _tmp_firstNames = _result.getString("firstNames")
          val _tmp_lastName = _result.getString("lastName")
          val _tmp_emailAddr = _result.getString("emailAddr")
          val _tmp_phoneNum = _result.getString("phoneNum")
          val _tmp_gender = _result.getInt("gender")
          val _tmp_active = _result.getBoolean("active")
          val _tmp_admin = _result.getBoolean("admin")
          val _tmp_personNotes = _result.getString("personNotes")
          val _tmp_fatherName = _result.getString("fatherName")
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          val _tmp_motherName = _result.getString("motherName")
          val _tmp_motherNum = _result.getString("motherNum")
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          val _tmp_personAddress = _result.getString("personAddress")
          val _tmp_personOrgId = _result.getString("personOrgId")
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          val _tmp_personLct = _result.getLong("personLct")
          val _tmp_personCountry = _result.getString("personCountry")
          val _tmp_personType = _result.getInt("personType")
          Person().apply {
            this.personUid = _tmp_personUid
            this.username = _tmp_username
            this.firstNames = _tmp_firstNames
            this.lastName = _tmp_lastName
            this.emailAddr = _tmp_emailAddr
            this.phoneNum = _tmp_phoneNum
            this.gender = _tmp_gender
            this.active = _tmp_active
            this.admin = _tmp_admin
            this.personNotes = _tmp_personNotes
            this.fatherName = _tmp_fatherName
            this.fatherNumber = _tmp_fatherNumber
            this.motherName = _tmp_motherName
            this.motherNum = _tmp_motherNum
            this.dateOfBirth = _tmp_dateOfBirth
            this.personAddress = _tmp_personAddress
            this.personOrgId = _tmp_personOrgId
            this.personGroupUid = _tmp_personGroupUid
            this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
            this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
            this.personLastChangedBy = _tmp_personLastChangedBy
            this.personLct = _tmp_personLct
            this.personCountry = _tmp_personCountry
            this.personType = _tmp_personType
          }
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): Person? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Person WHERE personUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override fun findPersonsWithPermission(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): DataSourceFactory<Int, PersonWithDisplayDetails> = object :
      DataSourceFactory<Int, PersonWithDisplayDetails>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<PersonWithDisplayDetails>>
        = LiveDataImpl(_db, listOf("PersonGroupMember", "ScopedGrant", "Person", "ClazzEnrolment",
        "SchoolMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |         SELECT Person.* 
      |           FROM PersonGroupMember 
      |                
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions &
      |                    64
      |                    
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |         WHERE PersonGroupMember.groupMemberPersonUid = ?
      |           AND PersonGroupMember.groupMemberActive 
      |           AND (? = 0 OR ? NOT IN
      |                    (SELECT clazzEnrolmentClazzUid 
      |                       FROM ClazzEnrolment 
      |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
      |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
      |           AND ClazzEnrolment.clazzEnrolmentActive))
      |           AND (? = 0 OR ? NOT IN
      |                    (SELECT schoolMemberSchoolUid
      |                      FROM SchoolMember 
      |                     WHERE schoolMemberPersonUid = Person.personUid 
      |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
      |                            AND SchoolMember.schoolMemberLeftDate ))
      |           AND Person.personType = 0                  
      |           AND (Person.personUid NOT IN (?))
      |           AND (? = '%' 
      |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
      |      GROUP BY Person.personUid
      |      ORDER BY CASE(?)
      |               WHEN 1 THEN Person.firstNames
      |               WHEN 3 THEN Person.lastName
      |               ELSE ''
      |               END ASC,
      |               CASE(?)
      |               WHEN 2 THEN Person.firstNames
      |               WHEN 4 THEN Person.lastName
      |               ELSE ''
      |               END DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,excludeClazz)
        _stmt.setLong(3,excludeClazz)
        _stmt.setLong(4,timestamp)
        _stmt.setLong(5,excludeSchool)
        _stmt.setLong(6,excludeSchool)
        _stmt.setLong(7,timestamp)
        _stmt.setArray(8, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            excludeSelected.toTypedArray()))
        _stmt.setString(9,searchText)
        _stmt.setString(10,searchText)
        _stmt.setInt(11,sortOrder)
        _stmt.setInt(12,sortOrder)
        _stmt.setInt(13,_limit)
        _stmt.setInt(14,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_personUid = _result.getLong("personUid")
            val _tmp_username = _result.getString("username")
            val _tmp_firstNames = _result.getString("firstNames")
            val _tmp_lastName = _result.getString("lastName")
            val _tmp_emailAddr = _result.getString("emailAddr")
            val _tmp_phoneNum = _result.getString("phoneNum")
            val _tmp_gender = _result.getInt("gender")
            val _tmp_active = _result.getBoolean("active")
            val _tmp_admin = _result.getBoolean("admin")
            val _tmp_personNotes = _result.getString("personNotes")
            val _tmp_fatherName = _result.getString("fatherName")
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            val _tmp_motherName = _result.getString("motherName")
            val _tmp_motherNum = _result.getString("motherNum")
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            val _tmp_personAddress = _result.getString("personAddress")
            val _tmp_personOrgId = _result.getString("personOrgId")
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            val _tmp_personLct = _result.getLong("personLct")
            val _tmp_personCountry = _result.getString("personCountry")
            val _tmp_personType = _result.getInt("personType")
            PersonWithDisplayDetails().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("PersonGroupMember",
        "ScopedGrant", "Person", "ClazzEnrolment", "SchoolMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |         SELECT Person.* 
      |           FROM PersonGroupMember 
      |                
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions &
      |                    64
      |                    
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |         WHERE PersonGroupMember.groupMemberPersonUid = ?
      |           AND PersonGroupMember.groupMemberActive 
      |           AND (? = 0 OR ? NOT IN
      |                    (SELECT clazzEnrolmentClazzUid 
      |                       FROM ClazzEnrolment 
      |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
      |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
      |           AND ClazzEnrolment.clazzEnrolmentActive))
      |           AND (? = 0 OR ? NOT IN
      |                    (SELECT schoolMemberSchoolUid
      |                      FROM SchoolMember 
      |                     WHERE schoolMemberPersonUid = Person.personUid 
      |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
      |                            AND SchoolMember.schoolMemberLeftDate ))
      |           AND Person.personType = 0                  
      |           AND (Person.personUid NOT IN (?))
      |           AND (? = '%' 
      |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
      |      GROUP BY Person.personUid
      |      ORDER BY CASE(?)
      |               WHEN 1 THEN Person.firstNames
      |               WHEN 3 THEN Person.lastName
      |               ELSE ''
      |               END ASC,
      |               CASE(?)
      |               WHEN 2 THEN Person.firstNames
      |               WHEN 4 THEN Person.lastName
      |               ELSE ''
      |               END DESC
      |    ) 
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,excludeClazz)
        _stmt.setLong(3,excludeClazz)
        _stmt.setLong(4,timestamp)
        _stmt.setLong(5,excludeSchool)
        _stmt.setLong(6,excludeSchool)
        _stmt.setLong(7,timestamp)
        _stmt.setArray(8, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            excludeSelected.toTypedArray()))
        _stmt.setString(9,searchText)
        _stmt.setString(10,searchText)
        _stmt.setInt(11,sortOrder)
        _stmt.setInt(12,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findPersonsWithPermissionAsList(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): List<Person> = _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |         SELECT Person.* 
  |           FROM PersonGroupMember 
  |                
  |            JOIN ScopedGrant
  |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
  |                    AND (ScopedGrant.sgPermissions &
  |                    64
  |                    
  |                                                    ) > 0
  |            JOIN Person 
  |                 ON 
  |                ((ScopedGrant.sgTableId = -2
  |                    AND ScopedGrant.sgEntityUid = -2)
  |                 OR (ScopedGrant.sgTableId = 9
  |                    AND ScopedGrant.sgEntityUid = Person.personUid)
  |                 OR (ScopedGrant.sgTableId = 6       
  |                    AND Person.personUid IN (
  |                        SELECT DISTINCT clazzEnrolmentPersonUid
  |                          FROM ClazzEnrolment
  |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
  |                           AND ClazzEnrolment.clazzEnrolmentActive))
  |                 OR (ScopedGrant.sgTableId = 164
  |                    AND Person.personUid IN (
  |                        SELECT DISTINCT schoolMemberPersonUid
  |                          FROM SchoolMember
  |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
  |                           AND schoolMemberActive))
  |                           )    
  |        
  |        
  |         WHERE PersonGroupMember.groupMemberPersonUid = ?
  |           AND PersonGroupMember.groupMemberActive 
  |           AND (? = 0 OR ? NOT IN
  |                    (SELECT clazzEnrolmentClazzUid 
  |                       FROM ClazzEnrolment 
  |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
  |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
  |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
  |           AND ClazzEnrolment.clazzEnrolmentActive))
  |           AND (? = 0 OR ? NOT IN
  |                    (SELECT schoolMemberSchoolUid
  |                      FROM SchoolMember 
  |                     WHERE schoolMemberPersonUid = Person.personUid 
  |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
  |                            AND SchoolMember.schoolMemberLeftDate ))
  |           AND Person.personType = 0                  
  |           AND (Person.personUid NOT IN (?))
  |           AND (? = '%' 
  |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
  |      GROUP BY Person.personUid
  |      ORDER BY CASE(?)
  |               WHEN 1 THEN Person.firstNames
  |               WHEN 3 THEN Person.lastName
  |               ELSE ''
  |               END ASC,
  |               CASE(?)
  |               WHEN 2 THEN Person.firstNames
  |               WHEN 4 THEN Person.lastName
  |               ELSE ''
  |               END DESC
  |    
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setLong(1,accountPersonUid)
    _stmt.setLong(2,excludeClazz)
    _stmt.setLong(3,excludeClazz)
    _stmt.setLong(4,timestamp)
    _stmt.setLong(5,excludeSchool)
    _stmt.setLong(6,excludeSchool)
    _stmt.setLong(7,timestamp)
    _stmt.setArray(8, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        excludeSelected.toTypedArray()))
    _stmt.setString(9,searchText)
    _stmt.setString(10,searchText)
    _stmt.setInt(11,sortOrder)
    _stmt.setInt(12,sortOrder)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override fun findByUidWithDisplayDetailsLive(personUid: Long, activeUserPersonUid: Long):
      LiveData<PersonWithPersonParentJoin?> = LiveDataImpl(_db, listOf("Person",
      "PersonParentJoin"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT Person.*, PersonParentJoin.* 
    |          FROM Person
    |     LEFT JOIN PersonParentJoin on ppjUid = (
    |                SELECT ppjUid 
    |                  FROM PersonParentJoin
    |                 WHERE ppjMinorPersonUid = ? 
    |                       AND ppjParentPersonUid = ? 
    |                LIMIT 1)     
    |         WHERE Person.personUid = ?
    |        
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.setLong(2,activeUserPersonUid)
      _stmt.setLong(3,personUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_personUid = _result.getLong("personUid")
          val _tmp_username = _result.getString("username")
          val _tmp_firstNames = _result.getString("firstNames")
          val _tmp_lastName = _result.getString("lastName")
          val _tmp_emailAddr = _result.getString("emailAddr")
          val _tmp_phoneNum = _result.getString("phoneNum")
          val _tmp_gender = _result.getInt("gender")
          val _tmp_active = _result.getBoolean("active")
          val _tmp_admin = _result.getBoolean("admin")
          val _tmp_personNotes = _result.getString("personNotes")
          val _tmp_fatherName = _result.getString("fatherName")
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          val _tmp_motherName = _result.getString("motherName")
          val _tmp_motherNum = _result.getString("motherNum")
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          val _tmp_personAddress = _result.getString("personAddress")
          val _tmp_personOrgId = _result.getString("personOrgId")
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          val _tmp_personLct = _result.getLong("personLct")
          val _tmp_personCountry = _result.getString("personCountry")
          val _tmp_personType = _result.getInt("personType")
          var _tmp_PersonParentJoin_nullCount = 0
          val _tmp_ppjUid = _result.getLong("ppjUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjPcsn = _result.getLong("ppjPcsn")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLcsn = _result.getLong("ppjLcsn")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLcb = _result.getInt("ppjLcb")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLct = _result.getLong("ppjLct")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjParentPersonUid = _result.getLong("ppjParentPersonUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjMinorPersonUid = _result.getLong("ppjMinorPersonUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjRelationship = _result.getInt("ppjRelationship")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjEmail = _result.getString("ppjEmail")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjPhone = _result.getString("ppjPhone")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjInactive = _result.getBoolean("ppjInactive")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjStatus = _result.getInt("ppjStatus")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjApprovalTiemstamp = _result.getLong("ppjApprovalTiemstamp")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjApprovalIpAddr = _result.getString("ppjApprovalIpAddr")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_PersonParentJoin_isAllNull = _tmp_PersonParentJoin_nullCount == 14
          PersonWithPersonParentJoin().apply {
            this.personUid = _tmp_personUid
            this.username = _tmp_username
            this.firstNames = _tmp_firstNames
            this.lastName = _tmp_lastName
            this.emailAddr = _tmp_emailAddr
            this.phoneNum = _tmp_phoneNum
            this.gender = _tmp_gender
            this.active = _tmp_active
            this.admin = _tmp_admin
            this.personNotes = _tmp_personNotes
            this.fatherName = _tmp_fatherName
            this.fatherNumber = _tmp_fatherNumber
            this.motherName = _tmp_motherName
            this.motherNum = _tmp_motherNum
            this.dateOfBirth = _tmp_dateOfBirth
            this.personAddress = _tmp_personAddress
            this.personOrgId = _tmp_personOrgId
            this.personGroupUid = _tmp_personGroupUid
            this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
            this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
            this.personLastChangedBy = _tmp_personLastChangedBy
            this.personLct = _tmp_personLct
            this.personCountry = _tmp_personCountry
            this.personType = _tmp_personType
            if(!_tmp_PersonParentJoin_isAllNull) {
              this.parentJoin = PersonParentJoin().apply {
                this.ppjUid = _tmp_ppjUid
                this.ppjPcsn = _tmp_ppjPcsn
                this.ppjLcsn = _tmp_ppjLcsn
                this.ppjLcb = _tmp_ppjLcb
                this.ppjLct = _tmp_ppjLct
                this.ppjParentPersonUid = _tmp_ppjParentPersonUid
                this.ppjMinorPersonUid = _tmp_ppjMinorPersonUid
                this.ppjRelationship = _tmp_ppjRelationship
                this.ppjEmail = _tmp_ppjEmail
                this.ppjPhone = _tmp_ppjPhone
                this.ppjInactive = _tmp_ppjInactive
                this.ppjStatus = _tmp_ppjStatus
                this.ppjApprovalTiemstamp = _tmp_ppjApprovalTiemstamp
                this.ppjApprovalIpAddr = _tmp_ppjApprovalIpAddr
              }
            }
          }
        }
      }
    }
  }

  public override fun getAllPerson(): List<Person> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Person" )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }
}
