package com.ustadmobile.core.db.dao

import com.ustadmobile.door.SyncNode
import kotlin.Long
import kotlin.Unit

public actual abstract class SyncNodeDao {
  public actual abstract fun replace(syncNode: SyncNode): Unit

  public actual abstract suspend fun getLocalNodeClientId(): Long
}
