package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.AccessToken
import com.ustadmobile.lib.db.entities.AuditLog
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAuth
import com.ustadmobile.lib.db.entities.PersonGroup
import com.ustadmobile.lib.db.entities.PersonGroupMember
import com.ustadmobile.lib.db.entities.PersonUidAndPasswordHash
import com.ustadmobile.lib.db.entities.PersonWithAccount
import com.ustadmobile.lib.db.entities.PersonWithDisplayDetails
import com.ustadmobile.lib.db.entities.PersonWithPersonParentJoin
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class PersonDao : BaseDao<Person> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(entityList: List<Person>): Unit

  public actual abstract suspend fun insertOrReplace(person: Person): Unit

  public actual abstract suspend fun findByUsernameCount(username: String): Int

  public actual abstract fun isValidToken(token: String, personUid: Long): Boolean

  public actual abstract fun insertAccessToken(token: AccessToken): Unit

  public actual abstract suspend fun findUidAndPasswordHashAsync(username: String):
      PersonUidAndPasswordHash?

  public actual abstract suspend fun findByUsernameAndPasswordHash2(username: String,
      passwordHash: String): Person?

  public actual abstract fun insertPersonAuth(personAuth: PersonAuth): Unit

  public actual abstract suspend fun personHasPermissionAsync(
    accountPersonUid: Long,
    personUid: Long,
    permission: Long,
  ): Boolean

  public actual abstract suspend fun personIsAdmin(accountPersonUid: Long): Boolean

  public actual abstract fun findByUsername(username: String?): Person?

  public actual abstract suspend fun findSystemAccount(nodeId: Long): Person?

  public actual abstract fun findByUid(uid: Long): Person?

  public actual abstract suspend fun findPersonAccountByUid(uid: Long): PersonWithAccount?

  public actual abstract fun findByUidLive(uid: Long): LiveData<Person?>

  public actual abstract suspend fun findByUidAsync(uid: Long): Person?

  public actual abstract suspend fun updateAsync(entity: Person): Int

  public actual abstract suspend fun insertPersonGroup(personGroup: PersonGroup): Long

  public actual abstract suspend fun insertPersonGroupMember(personGroupMember: PersonGroupMember):
      Long

  public actual abstract fun findPersonsWithPermission(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): DataSourceFactory<Int, PersonWithDisplayDetails>

  public actual abstract fun findPersonsWithPermissionAsList(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): List<Person>

  public actual abstract fun findByUidWithDisplayDetailsLive(personUid: Long,
      activeUserPersonUid: Long): LiveData<PersonWithPersonParentJoin?>

  public actual abstract fun insertAuditLog(entity: AuditLog): Long

  public actual abstract fun getAllPerson(): List<Person>
}
