package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ClazzLog
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzLogDao_JdbcKt(
  public val _db: RoomDatabase,
) : ClazzLogDao() {
  public val _insertAdapterClazzLog_upsert: EntityInsertionAdapter<ClazzLog> = object :
      EntityInsertionAdapter<ClazzLog>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO ClazzLog (clazzLogUid, clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzLog): Unit {
      if(entity.clazzLogUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogUid)
      }
      stmt.setLong(2, entity.clazzLogClazzUid)
      stmt.setLong(3, entity.logDate)
      stmt.setLong(4, entity.timeRecorded)
      stmt.setBoolean(5, entity.clazzLogDone)
      stmt.setString(6, entity.cancellationNote)
      stmt.setBoolean(7, entity.clazzLogCancelled)
      stmt.setInt(8, entity.clazzLogNumPresent)
      stmt.setInt(9, entity.clazzLogNumAbsent)
      stmt.setInt(10, entity.clazzLogNumPartial)
      stmt.setLong(11, entity.clazzLogScheduleUid)
      stmt.setInt(12, entity.clazzLogStatusFlag)
      stmt.setLong(13, entity.clazzLogMSQN)
      stmt.setLong(14, entity.clazzLogLCSN)
      stmt.setInt(15, entity.clazzLogLCB)
      stmt.setLong(16, entity.clazzLogLastChangedTime)
    }
  }

  public val _insertAdapterClazzLog_: EntityInsertionAdapter<ClazzLog> = object :
      EntityInsertionAdapter<ClazzLog>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ClazzLog (clazzLogUid, clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzLog): Unit {
      if(entity.clazzLogUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogUid)
      }
      stmt.setLong(2, entity.clazzLogClazzUid)
      stmt.setLong(3, entity.logDate)
      stmt.setLong(4, entity.timeRecorded)
      stmt.setBoolean(5, entity.clazzLogDone)
      stmt.setString(6, entity.cancellationNote)
      stmt.setBoolean(7, entity.clazzLogCancelled)
      stmt.setInt(8, entity.clazzLogNumPresent)
      stmt.setInt(9, entity.clazzLogNumAbsent)
      stmt.setInt(10, entity.clazzLogNumPartial)
      stmt.setLong(11, entity.clazzLogScheduleUid)
      stmt.setInt(12, entity.clazzLogStatusFlag)
      stmt.setLong(13, entity.clazzLogMSQN)
      stmt.setLong(14, entity.clazzLogLCSN)
      stmt.setInt(15, entity.clazzLogLCB)
      stmt.setLong(16, entity.clazzLogLastChangedTime)
    }
  }

  public override fun replace(entity: ClazzLog): Long {
    val _retVal = _insertAdapterClazzLog_upsert.insertAndReturnId(entity)
    return _retVal
  }

  public override fun insert(entity: ClazzLog): Long {
    val _retVal = _insertAdapterClazzLog_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzLog): Long {
    val _retVal = _insertAdapterClazzLog_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ClazzLog>): Unit {
    _insertAdapterClazzLog_.insertList(entityList)
  }

  public override suspend fun updateAsync(clazzLog: ClazzLog): Unit {
    val _sql =
        "UPDATE ClazzLog SET clazzLogClazzUid = ?, logDate = ?, timeRecorded = ?, clazzLogDone = ?, cancellationNote = ?, clazzLogCancelled = ?, clazzLogNumPresent = ?, clazzLogNumAbsent = ?, clazzLogNumPartial = ?, clazzLogScheduleUid = ?, clazzLogStatusFlag = ?, clazzLogMSQN = ?, clazzLogLCSN = ?, clazzLogLCB = ?, clazzLogLastChangedTime = ? WHERE clazzLogUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, clazzLog.clazzLogClazzUid)
      _stmt.setLong(2, clazzLog.logDate)
      _stmt.setLong(3, clazzLog.timeRecorded)
      _stmt.setBoolean(4, clazzLog.clazzLogDone)
      _stmt.setString(5, clazzLog.cancellationNote)
      _stmt.setBoolean(6, clazzLog.clazzLogCancelled)
      _stmt.setInt(7, clazzLog.clazzLogNumPresent)
      _stmt.setInt(8, clazzLog.clazzLogNumAbsent)
      _stmt.setInt(9, clazzLog.clazzLogNumPartial)
      _stmt.setLong(10, clazzLog.clazzLogScheduleUid)
      _stmt.setInt(11, clazzLog.clazzLogStatusFlag)
      _stmt.setLong(12, clazzLog.clazzLogMSQN)
      _stmt.setLong(13, clazzLog.clazzLogLCSN)
      _stmt.setInt(14, clazzLog.clazzLogLCB)
      _stmt.setLong(15, clazzLog.clazzLogLastChangedTime)
      _stmt.setLong(16, clazzLog.clazzLogUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateList(entityList: List<ClazzLog>): Unit {
    val _sql =
        "UPDATE ClazzLog SET clazzLogClazzUid = ?, logDate = ?, timeRecorded = ?, clazzLogDone = ?, cancellationNote = ?, clazzLogCancelled = ?, clazzLogNumPresent = ?, clazzLogNumAbsent = ?, clazzLogNumPartial = ?, clazzLogScheduleUid = ?, clazzLogStatusFlag = ?, clazzLogMSQN = ?, clazzLogLCSN = ?, clazzLogLCB = ?, clazzLogLastChangedTime = ? WHERE clazzLogUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.clazzLogClazzUid)
        _stmt.setLong(2, _entity.logDate)
        _stmt.setLong(3, _entity.timeRecorded)
        _stmt.setBoolean(4, _entity.clazzLogDone)
        _stmt.setString(5, _entity.cancellationNote)
        _stmt.setBoolean(6, _entity.clazzLogCancelled)
        _stmt.setInt(7, _entity.clazzLogNumPresent)
        _stmt.setInt(8, _entity.clazzLogNumAbsent)
        _stmt.setInt(9, _entity.clazzLogNumPartial)
        _stmt.setLong(10, _entity.clazzLogScheduleUid)
        _stmt.setInt(11, _entity.clazzLogStatusFlag)
        _stmt.setLong(12, _entity.clazzLogMSQN)
        _stmt.setLong(13, _entity.clazzLogLCSN)
        _stmt.setInt(14, _entity.clazzLogLCB)
        _stmt.setLong(15, _entity.clazzLogLastChangedTime)
        _stmt.setLong(16, _entity.clazzLogUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzLog): Unit {
    val _sql =
        "UPDATE ClazzLog SET clazzLogClazzUid = ?, logDate = ?, timeRecorded = ?, clazzLogDone = ?, cancellationNote = ?, clazzLogCancelled = ?, clazzLogNumPresent = ?, clazzLogNumAbsent = ?, clazzLogNumPartial = ?, clazzLogScheduleUid = ?, clazzLogStatusFlag = ?, clazzLogMSQN = ?, clazzLogLCSN = ?, clazzLogLCB = ?, clazzLogLastChangedTime = ? WHERE clazzLogUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzLogClazzUid)
      _stmt.setLong(2, entity.logDate)
      _stmt.setLong(3, entity.timeRecorded)
      _stmt.setBoolean(4, entity.clazzLogDone)
      _stmt.setString(5, entity.cancellationNote)
      _stmt.setBoolean(6, entity.clazzLogCancelled)
      _stmt.setInt(7, entity.clazzLogNumPresent)
      _stmt.setInt(8, entity.clazzLogNumAbsent)
      _stmt.setInt(9, entity.clazzLogNumPartial)
      _stmt.setLong(10, entity.clazzLogScheduleUid)
      _stmt.setInt(11, entity.clazzLogStatusFlag)
      _stmt.setLong(12, entity.clazzLogMSQN)
      _stmt.setLong(13, entity.clazzLogLCSN)
      _stmt.setInt(14, entity.clazzLogLCB)
      _stmt.setLong(15, entity.clazzLogLastChangedTime)
      _stmt.setLong(16, entity.clazzLogUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzLogReplicate(clPk, clDestination)
    |      SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |             ? AS clDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |       WHERE ClazzLog.clazzLogLastChangedTime != COALESCE(
    |             (SELECT clVersionId
    |                FROM ClazzLogReplicate
    |               WHERE clPk = ClazzLog.clazzLogUid
    |                 AND clDestination = ?), 0) 
    |      /*psql ON CONFLICT(clPk, clDestination) DO UPDATE
    |             SET clPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogReplicate(clPk, clDestination)
    |      SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |             ? AS clDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |       WHERE ClazzLog.clazzLogLastChangedTime != COALESCE(
    |             (SELECT clVersionId
    |                FROM ClazzLogReplicate
    |               WHERE clPk = ClazzLog.clazzLogUid
    |                 AND clDestination = ?), 0) 
    |       ON CONFLICT(clPk, clDestination) DO UPDATE
    |             SET clPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzLogReplicate(clPk, clDestination)
    |  SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |         UserSession.usClientNodeId AS clDestination
    |    FROM ChangeLog
    |         JOIN ClazzLog 
    |              ON ChangeLog.chTableId = 14 
    |             AND ClazzLog.clazzLogUid = ChangeLog.chEntityPk
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLog.clazzLogLastChangedTime != COALESCE(
    |         (SELECT clVersionId
    |            FROM ClazzLogReplicate
    |           WHERE clPk = ClazzLog.clazzLogUid
    |             AND clDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(clPk, clDestination) DO UPDATE
    |     SET clPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogReplicate(clPk, clDestination)
    |  SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |         UserSession.usClientNodeId AS clDestination
    |    FROM ChangeLog
    |         JOIN ClazzLog 
    |              ON ChangeLog.chTableId = 14 
    |             AND ClazzLog.clazzLogUid = ChangeLog.chEntityPk
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLog.clazzLogLastChangedTime != COALESCE(
    |         (SELECT clVersionId
    |            FROM ClazzLogReplicate
    |           WHERE clPk = ClazzLog.clazzLogUid
    |             AND clDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(clPk, clDestination) DO UPDATE
    |     SET clPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): ClazzLog? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM ClazzLog WHERE clazzLogUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
        val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
        val _tmp_logDate = _result.getLong("logDate")
        val _tmp_timeRecorded = _result.getLong("timeRecorded")
        val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
        val _tmp_cancellationNote = _result.getString("cancellationNote")
        val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
        val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
        val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
        val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
        val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
        val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
        val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
        val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
        val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
        val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
        ClazzLog().apply {
          this.clazzLogUid = _tmp_clazzLogUid
          this.clazzLogClazzUid = _tmp_clazzLogClazzUid
          this.logDate = _tmp_logDate
          this.timeRecorded = _tmp_timeRecorded
          this.clazzLogDone = _tmp_clazzLogDone
          this.cancellationNote = _tmp_cancellationNote
          this.clazzLogCancelled = _tmp_clazzLogCancelled
          this.clazzLogNumPresent = _tmp_clazzLogNumPresent
          this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
          this.clazzLogNumPartial = _tmp_clazzLogNumPartial
          this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
          this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
          this.clazzLogMSQN = _tmp_clazzLogMSQN
          this.clazzLogLCSN = _tmp_clazzLogLCSN
          this.clazzLogLCB = _tmp_clazzLogLCB
          this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): ClazzLog? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ClazzLog WHERE clazzLogUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
        val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
        val _tmp_logDate = _result.getLong("logDate")
        val _tmp_timeRecorded = _result.getLong("timeRecorded")
        val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
        val _tmp_cancellationNote = _result.getString("cancellationNote")
        val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
        val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
        val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
        val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
        val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
        val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
        val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
        val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
        val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
        val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
        ClazzLog().apply {
          this.clazzLogUid = _tmp_clazzLogUid
          this.clazzLogClazzUid = _tmp_clazzLogClazzUid
          this.logDate = _tmp_logDate
          this.timeRecorded = _tmp_timeRecorded
          this.clazzLogDone = _tmp_clazzLogDone
          this.cancellationNote = _tmp_cancellationNote
          this.clazzLogCancelled = _tmp_clazzLogCancelled
          this.clazzLogNumPresent = _tmp_clazzLogNumPresent
          this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
          this.clazzLogNumPartial = _tmp_clazzLogNumPartial
          this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
          this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
          this.clazzLogMSQN = _tmp_clazzLogMSQN
          this.clazzLogLCSN = _tmp_clazzLogLCSN
          this.clazzLogLCB = _tmp_clazzLogLCB
          this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<ClazzLog?> = LiveDataImpl(_db,
      listOf("ClazzLog"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ClazzLog WHERE clazzLogUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
          val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
          val _tmp_logDate = _result.getLong("logDate")
          val _tmp_timeRecorded = _result.getLong("timeRecorded")
          val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
          val _tmp_cancellationNote = _result.getString("cancellationNote")
          val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
          val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
          val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
          val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
          val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
          val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
          val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
          val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
          val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
          val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
          ClazzLog().apply {
            this.clazzLogUid = _tmp_clazzLogUid
            this.clazzLogClazzUid = _tmp_clazzLogClazzUid
            this.logDate = _tmp_logDate
            this.timeRecorded = _tmp_timeRecorded
            this.clazzLogDone = _tmp_clazzLogDone
            this.cancellationNote = _tmp_cancellationNote
            this.clazzLogCancelled = _tmp_clazzLogCancelled
            this.clazzLogNumPresent = _tmp_clazzLogNumPresent
            this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
            this.clazzLogNumPartial = _tmp_clazzLogNumPartial
            this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
            this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
            this.clazzLogMSQN = _tmp_clazzLogMSQN
            this.clazzLogLCSN = _tmp_clazzLogLCSN
            this.clazzLogLCB = _tmp_clazzLogLCB
            this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
          }
        }
      }
    }
  }

  public override fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      DataSourceFactory<Int, ClazzLog> = object : DataSourceFactory<Int, ClazzLog>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<ClazzLog>> =
        LiveDataImpl(_db, listOf("ClazzLog"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT ClazzLog.* FROM ClazzLog 
      |        WHERE clazzLogClazzUid = ?
      |        AND clazzLog.clazzLogStatusFlag != ?
      |        ORDER BY ClazzLog.logDate DESC) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.setInt(2,excludeStatus)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
            val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
            val _tmp_logDate = _result.getLong("logDate")
            val _tmp_timeRecorded = _result.getLong("timeRecorded")
            val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
            val _tmp_cancellationNote = _result.getString("cancellationNote")
            val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
            val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
            val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
            val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
            val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
            val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
            val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
            val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
            val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
            val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
            ClazzLog().apply {
              this.clazzLogUid = _tmp_clazzLogUid
              this.clazzLogClazzUid = _tmp_clazzLogClazzUid
              this.logDate = _tmp_logDate
              this.timeRecorded = _tmp_timeRecorded
              this.clazzLogDone = _tmp_clazzLogDone
              this.cancellationNote = _tmp_cancellationNote
              this.clazzLogCancelled = _tmp_clazzLogCancelled
              this.clazzLogNumPresent = _tmp_clazzLogNumPresent
              this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
              this.clazzLogNumPartial = _tmp_clazzLogNumPartial
              this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
              this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
              this.clazzLogMSQN = _tmp_clazzLogMSQN
              this.clazzLogLCSN = _tmp_clazzLogLCSN
              this.clazzLogLCB = _tmp_clazzLogLCB
              this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ClazzLog"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT ClazzLog.* FROM ClazzLog 
      |        WHERE clazzLogClazzUid = ?
      |        AND clazzLog.clazzLogStatusFlag != ?
      |        ORDER BY ClazzLog.logDate DESC) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.setInt(2,excludeStatus)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int):
      List<ClazzLog> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT ClazzLog.* FROM ClazzLog 
  |        WHERE clazzLogClazzUid = ?
  |        AND clazzLog.clazzLogStatusFlag != ?
  |        ORDER BY ClazzLog.logDate ASC
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.setInt(2,excludeStatus)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
        val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
        val _tmp_logDate = _result.getLong("logDate")
        val _tmp_timeRecorded = _result.getLong("timeRecorded")
        val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
        val _tmp_cancellationNote = _result.getString("cancellationNote")
        val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
        val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
        val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
        val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
        val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
        val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
        val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
        val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
        val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
        val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
        ClazzLog().apply {
          this.clazzLogUid = _tmp_clazzLogUid
          this.clazzLogClazzUid = _tmp_clazzLogClazzUid
          this.logDate = _tmp_logDate
          this.timeRecorded = _tmp_timeRecorded
          this.clazzLogDone = _tmp_clazzLogDone
          this.cancellationNote = _tmp_cancellationNote
          this.clazzLogCancelled = _tmp_clazzLogCancelled
          this.clazzLogNumPresent = _tmp_clazzLogNumPresent
          this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
          this.clazzLogNumPartial = _tmp_clazzLogNumPartial
          this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
          this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
          this.clazzLogMSQN = _tmp_clazzLogMSQN
          this.clazzLogLCSN = _tmp_clazzLogLCSN
          this.clazzLogLCB = _tmp_clazzLogLCB
          this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
        }
      }
    }
  }

  public override suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT ClazzLog.* FROM ClazzLog 
  |        WHERE 
  |        ClazzLog.clazzLogClazzUid = ? 
  |        AND ClazzLog.logDate BETWEEN ? AND ?
  |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0))
  |        ORDER BY ClazzLog.logDate DESC
  |        LIMIT ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.setLong(2,fromTime)
    _stmt.setLong(3,toTime)
    _stmt.setInt(4,excludeStatusFilter)
    _stmt.setInt(5,excludeStatusFilter)
    _stmt.setInt(6,limit)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
        val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
        val _tmp_logDate = _result.getLong("logDate")
        val _tmp_timeRecorded = _result.getLong("timeRecorded")
        val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
        val _tmp_cancellationNote = _result.getString("cancellationNote")
        val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
        val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
        val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
        val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
        val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
        val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
        val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
        val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
        val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
        val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
        ClazzLog().apply {
          this.clazzLogUid = _tmp_clazzLogUid
          this.clazzLogClazzUid = _tmp_clazzLogClazzUid
          this.logDate = _tmp_logDate
          this.timeRecorded = _tmp_timeRecorded
          this.clazzLogDone = _tmp_clazzLogDone
          this.cancellationNote = _tmp_cancellationNote
          this.clazzLogCancelled = _tmp_clazzLogCancelled
          this.clazzLogNumPresent = _tmp_clazzLogNumPresent
          this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
          this.clazzLogNumPartial = _tmp_clazzLogNumPartial
          this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
          this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
          this.clazzLogMSQN = _tmp_clazzLogMSQN
          this.clazzLogLCSN = _tmp_clazzLogLCSN
          this.clazzLogLCB = _tmp_clazzLogLCB
          this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
        }
      }
    }
  }

  public override fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> = _db.prepareAndUseStatement(PreparedStatementConfig("""
  |SELECT ClazzLog.* FROM ClazzLog 
  |        WHERE 
  |        ClazzLog.clazzLogClazzUid = ? 
  |        AND ClazzLog.logDate BETWEEN ? AND ?
  |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0))
  |        ORDER BY ClazzLog.logDate DESC
  |        LIMIT ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.setLong(2,fromTime)
    _stmt.setLong(3,toTime)
    _stmt.setInt(4,excludeStatusFilter)
    _stmt.setInt(5,excludeStatusFilter)
    _stmt.setInt(6,limit)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
        val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
        val _tmp_logDate = _result.getLong("logDate")
        val _tmp_timeRecorded = _result.getLong("timeRecorded")
        val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
        val _tmp_cancellationNote = _result.getString("cancellationNote")
        val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
        val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
        val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
        val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
        val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
        val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
        val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
        val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
        val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
        val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
        ClazzLog().apply {
          this.clazzLogUid = _tmp_clazzLogUid
          this.clazzLogClazzUid = _tmp_clazzLogClazzUid
          this.logDate = _tmp_logDate
          this.timeRecorded = _tmp_timeRecorded
          this.clazzLogDone = _tmp_clazzLogDone
          this.cancellationNote = _tmp_cancellationNote
          this.clazzLogCancelled = _tmp_clazzLogCancelled
          this.clazzLogNumPresent = _tmp_clazzLogNumPresent
          this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
          this.clazzLogNumPartial = _tmp_clazzLogNumPartial
          this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
          this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
          this.clazzLogMSQN = _tmp_clazzLogMSQN
          this.clazzLogLCSN = _tmp_clazzLogLCSN
          this.clazzLogLCB = _tmp_clazzLogLCB
          this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
        }
      }
    }
  }

  public override fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int,
  ): LiveData<List<ClazzLog>> = LiveDataImpl(_db, listOf("ClazzLog"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |SELECT ClazzLog.* FROM ClazzLog 
    |        WHERE 
    |        ClazzLog.clazzLogClazzUid = ? 
    |        AND ClazzLog.logDate BETWEEN ? AND ?
    |        AND (? = 0 OR ClazzLog.clazzLogStatusFlag = ?)
    |        ORDER BY ClazzLog.logDate
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.setLong(2,fromTime)
      _stmt.setLong(3,toTime)
      _stmt.setInt(4,statusFilter)
      _stmt.setInt(5,statusFilter)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_clazzLogUid = _result.getLong("clazzLogUid")
          val _tmp_clazzLogClazzUid = _result.getLong("clazzLogClazzUid")
          val _tmp_logDate = _result.getLong("logDate")
          val _tmp_timeRecorded = _result.getLong("timeRecorded")
          val _tmp_clazzLogDone = _result.getBoolean("clazzLogDone")
          val _tmp_cancellationNote = _result.getString("cancellationNote")
          val _tmp_clazzLogCancelled = _result.getBoolean("clazzLogCancelled")
          val _tmp_clazzLogNumPresent = _result.getInt("clazzLogNumPresent")
          val _tmp_clazzLogNumAbsent = _result.getInt("clazzLogNumAbsent")
          val _tmp_clazzLogNumPartial = _result.getInt("clazzLogNumPartial")
          val _tmp_clazzLogScheduleUid = _result.getLong("clazzLogScheduleUid")
          val _tmp_clazzLogStatusFlag = _result.getInt("clazzLogStatusFlag")
          val _tmp_clazzLogMSQN = _result.getLong("clazzLogMSQN")
          val _tmp_clazzLogLCSN = _result.getLong("clazzLogLCSN")
          val _tmp_clazzLogLCB = _result.getInt("clazzLogLCB")
          val _tmp_clazzLogLastChangedTime = _result.getLong("clazzLogLastChangedTime")
          ClazzLog().apply {
            this.clazzLogUid = _tmp_clazzLogUid
            this.clazzLogClazzUid = _tmp_clazzLogClazzUid
            this.logDate = _tmp_logDate
            this.timeRecorded = _tmp_timeRecorded
            this.clazzLogDone = _tmp_clazzLogDone
            this.cancellationNote = _tmp_cancellationNote
            this.clazzLogCancelled = _tmp_clazzLogCancelled
            this.clazzLogNumPresent = _tmp_clazzLogNumPresent
            this.clazzLogNumAbsent = _tmp_clazzLogNumAbsent
            this.clazzLogNumPartial = _tmp_clazzLogNumPartial
            this.clazzLogScheduleUid = _tmp_clazzLogScheduleUid
            this.clazzLogStatusFlag = _tmp_clazzLogStatusFlag
            this.clazzLogMSQN = _tmp_clazzLogMSQN
            this.clazzLogLCSN = _tmp_clazzLogLCSN
            this.clazzLogLCB = _tmp_clazzLogLCB
            this.clazzLogLastChangedTime = _tmp_clazzLogLastChangedTime
          }
        }
      }
    }
  }

  public override fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int):
      LiveData<Boolean> = LiveDataImpl(_db, listOf("ClazzLog"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT EXISTS(SELECT ClazzLog.clazzLogUid FROM ClazzLog WHERE clazzLogClazzUid = ? 
    |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0)))
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.setInt(2,excludeStatusFilter)
      _stmt.setInt(3,excludeStatusFilter)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(false) {
          _result.getBoolean(1)
        }
      }
    }
  }

  public override fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long,
  ): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("""
    |UPDATE ClazzLog 
    |        SET clazzLogStatusFlag = ?,
    |        clazzLogLastChangedTime = ?
    |        WHERE clazzLogUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,newStatus)
      _stmt.setLong(2,timeChanged)
      _stmt.setLong(3,clazzLogUid)
      _stmt.executeUpdate()
    }
  }
}
