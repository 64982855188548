package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Site
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit

public class SiteDao_JdbcKt(
  public val _db: RoomDatabase,
) : SiteDao() {
  public val _insertAdapterSite_upsert: EntityInsertionAdapter<Site> = object :
      EntityInsertionAdapter<Site>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO Site (siteUid, sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Site): Unit {
      if(entity.siteUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.siteUid)
      }
      stmt.setLong(2, entity.sitePcsn)
      stmt.setLong(3, entity.siteLcsn)
      stmt.setInt(4, entity.siteLcb)
      stmt.setLong(5, entity.siteLct)
      stmt.setString(6, entity.siteName)
      stmt.setBoolean(7, entity.guestLogin)
      stmt.setBoolean(8, entity.registrationAllowed)
      stmt.setString(9, entity.authSalt)
    }
  }

  public val _insertAdapterSite_: EntityInsertionAdapter<Site> = object :
      EntityInsertionAdapter<Site>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Site (siteUid, sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Site): Unit {
      if(entity.siteUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.siteUid)
      }
      stmt.setLong(2, entity.sitePcsn)
      stmt.setLong(3, entity.siteLcsn)
      stmt.setInt(4, entity.siteLcb)
      stmt.setLong(5, entity.siteLct)
      stmt.setString(6, entity.siteName)
      stmt.setBoolean(7, entity.guestLogin)
      stmt.setBoolean(8, entity.registrationAllowed)
      stmt.setString(9, entity.authSalt)
    }
  }

  public override suspend fun replaceAsync(site: Site): Long {
    val _retVal = _insertAdapterSite_upsert.insertAndReturnIdAsync(site)
    return _retVal
  }

  public override fun insert(site: Site): Long {
    val _retVal = _insertAdapterSite_.insertAndReturnId(site)
    return _retVal
  }

  public override suspend fun updateAsync(workspace: Site): Unit {
    val _sql =
        "UPDATE Site SET sitePcsn = ?, siteLcsn = ?, siteLcb = ?, siteLct = ?, siteName = ?, guestLogin = ?, registrationAllowed = ?, authSalt = ? WHERE siteUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, workspace.sitePcsn)
      _stmt.setLong(2, workspace.siteLcsn)
      _stmt.setInt(3, workspace.siteLcb)
      _stmt.setLong(4, workspace.siteLct)
      _stmt.setString(5, workspace.siteName)
      _stmt.setBoolean(6, workspace.guestLogin)
      _stmt.setBoolean(7, workspace.registrationAllowed)
      _stmt.setString(8, workspace.authSalt)
      _stmt.setLong(9, workspace.siteUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getSite(): Site? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Site LIMIT 1" )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_siteUid = _result.getLong("siteUid")
        val _tmp_sitePcsn = _result.getLong("sitePcsn")
        val _tmp_siteLcsn = _result.getLong("siteLcsn")
        val _tmp_siteLcb = _result.getInt("siteLcb")
        val _tmp_siteLct = _result.getLong("siteLct")
        val _tmp_siteName = _result.getString("siteName")
        val _tmp_guestLogin = _result.getBoolean("guestLogin")
        val _tmp_registrationAllowed = _result.getBoolean("registrationAllowed")
        val _tmp_authSalt = _result.getString("authSalt")
        Site().apply {
          this.siteUid = _tmp_siteUid
          this.sitePcsn = _tmp_sitePcsn
          this.siteLcsn = _tmp_siteLcsn
          this.siteLcb = _tmp_siteLcb
          this.siteLct = _tmp_siteLct
          this.siteName = _tmp_siteName
          this.guestLogin = _tmp_guestLogin
          this.registrationAllowed = _tmp_registrationAllowed
          this.authSalt = _tmp_authSalt
        }
      }
    }
  }

  public override suspend fun getSiteAsync(): Site? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Site LIMIT 1" )) {
      _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_siteUid = _result.getLong("siteUid")
        val _tmp_sitePcsn = _result.getLong("sitePcsn")
        val _tmp_siteLcsn = _result.getLong("siteLcsn")
        val _tmp_siteLcb = _result.getInt("siteLcb")
        val _tmp_siteLct = _result.getLong("siteLct")
        val _tmp_siteName = _result.getString("siteName")
        val _tmp_guestLogin = _result.getBoolean("guestLogin")
        val _tmp_registrationAllowed = _result.getBoolean("registrationAllowed")
        val _tmp_authSalt = _result.getString("authSalt")
        Site().apply {
          this.siteUid = _tmp_siteUid
          this.sitePcsn = _tmp_sitePcsn
          this.siteLcsn = _tmp_siteLcsn
          this.siteLcb = _tmp_siteLcb
          this.siteLct = _tmp_siteLct
          this.siteName = _tmp_siteName
          this.guestLogin = _tmp_guestLogin
          this.registrationAllowed = _tmp_registrationAllowed
          this.authSalt = _tmp_authSalt
        }
      }
    }
  }

  public override suspend fun getSiteAuthSaltAsync(): String? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT authSalt FROM Site LIMIT 1" ))
      { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                ? AS siteDestination
    |           FROM Site
    |          WHERE Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = ?), 0) 
    |         /*psql ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |                SET sitePending = true
    |         */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                ? AS siteDestination
    |           FROM Site
    |          WHERE Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = ?), 0) 
    |          ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |                SET sitePending = true
    |                
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN Site 
    |                    ON ChangeLog.chTableId = 189 
    |                       AND ChangeLog.chEntityPk = Site.siteUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = UserSession.usClientNodeId), 0)     
    |        /*psql  ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |            SET sitePending = true
    |         */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN Site 
    |                    ON ChangeLog.chTableId = 189 
    |                       AND ChangeLog.chEntityPk = Site.siteUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = UserSession.usClientNodeId), 0)     
    |          ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |            SET sitePending = true
    |                        
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
