package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.CourseGroupMemberPerson
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseGroupMemberDao_JdbcKt(
  public val _db: RoomDatabase,
) : CourseGroupMemberDao() {
  public val _insertAdapterCourseGroupMember_: EntityInsertionAdapter<CourseGroupMember> = object :
      EntityInsertionAdapter<CourseGroupMember>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO CourseGroupMember (cgmUid, cgmSetUid, cgmGroupNumber, cgmPersonUid, cgmLct) VALUES(?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseGroupMember): Unit {
      if(entity.cgmUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cgmUid)
      }
      stmt.setLong(2, entity.cgmSetUid)
      stmt.setInt(3, entity.cgmGroupNumber)
      stmt.setLong(4, entity.cgmPersonUid)
      stmt.setLong(5, entity.cgmLct)
    }
  }

  public override suspend fun insertListAsync(entityList: List<CourseGroupMember>): Unit {
    _insertAdapterCourseGroupMember_.insertListAsync(entityList)
  }

  public override fun insert(entity: CourseGroupMember): Long {
    val _retVal = _insertAdapterCourseGroupMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseGroupMember): Long {
    val _retVal = _insertAdapterCourseGroupMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CourseGroupMember>): Unit {
    _insertAdapterCourseGroupMember_.insertList(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CourseGroupMember>): Unit {
    val _sql =
        "UPDATE CourseGroupMember SET cgmSetUid = ?, cgmGroupNumber = ?, cgmPersonUid = ?, cgmLct = ? WHERE cgmUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cgmSetUid)
        _stmt.setInt(2, _entity.cgmGroupNumber)
        _stmt.setLong(3, _entity.cgmPersonUid)
        _stmt.setLong(4, _entity.cgmLct)
        _stmt.setLong(5, _entity.cgmUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun updateList(entityList: List<CourseGroupMember>): Unit {
    val _sql =
        "UPDATE CourseGroupMember SET cgmSetUid = ?, cgmGroupNumber = ?, cgmPersonUid = ?, cgmLct = ? WHERE cgmUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cgmSetUid)
        _stmt.setInt(2, _entity.cgmGroupNumber)
        _stmt.setLong(3, _entity.cgmPersonUid)
        _stmt.setLong(4, _entity.cgmLct)
        _stmt.setLong(5, _entity.cgmUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseGroupMember): Unit {
    val _sql =
        "UPDATE CourseGroupMember SET cgmSetUid = ?, cgmGroupNumber = ?, cgmPersonUid = ?, cgmLct = ? WHERE cgmUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cgmSetUid)
      _stmt.setInt(2, entity.cgmGroupNumber)
      _stmt.setLong(3, entity.cgmPersonUid)
      _stmt.setLong(4, entity.cgmLct)
      _stmt.setLong(5, entity.cgmUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |      SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |             ? AS cgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN CourseGroupSet
    |                    ON CourseGroupSet.cgsClazzUid = Clazz.clazzUid
    |             JOIN CourseGroupMember
    |                    ON CourseGroupMember.cgmSetUid = CourseGroupSet.cgsUid       
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseGroupMember.cgmLct != COALESCE(
    |             (SELECT cgmVersionId
    |                FROM CourseGroupMemberReplicate
    |               WHERE cgmPk = CourseGroupMember.cgmUid
    |                 AND cgmDestination = ?), 0) 
    |      /*psql ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |             SET cgmPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |      SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |             ? AS cgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN CourseGroupSet
    |                    ON CourseGroupSet.cgsClazzUid = Clazz.clazzUid
    |             JOIN CourseGroupMember
    |                    ON CourseGroupMember.cgmSetUid = CourseGroupSet.cgsUid       
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseGroupMember.cgmLct != COALESCE(
    |             (SELECT cgmVersionId
    |                FROM CourseGroupMemberReplicate
    |               WHERE cgmPk = CourseGroupMember.cgmUid
    |                 AND cgmDestination = ?), 0) 
    |       ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |             SET cgmPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |  SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |         UserSession.usClientNodeId AS cgmDestination
    |    FROM ChangeLog
    |         JOIN CourseGroupMember
    |               ON ChangeLog.chTableId = 243
    |                  AND ChangeLog.chEntityPk = CourseGroupMember.cgmUid
    |          JOIN CourseGroupSet
    |               ON CourseGroupSet.cgsUid = CourseGroupMember.cgmSetUid       
    |          JOIN Clazz 
    |               ON Clazz.clazzUid = CourseGroupSet.cgsClazzUid 
    |          
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseGroupMember.cgmLct != COALESCE(
    |         (SELECT cgmVersionId
    |            FROM CourseGroupMemberReplicate
    |           WHERE cgmPk = CourseGroupMember.cgmUid
    |             AND cgmDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |     SET cgmPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |  SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |         UserSession.usClientNodeId AS cgmDestination
    |    FROM ChangeLog
    |         JOIN CourseGroupMember
    |               ON ChangeLog.chTableId = 243
    |                  AND ChangeLog.chEntityPk = CourseGroupMember.cgmUid
    |          JOIN CourseGroupSet
    |               ON CourseGroupSet.cgsUid = CourseGroupMember.cgmSetUid       
    |          JOIN Clazz 
    |               ON Clazz.clazzUid = CourseGroupSet.cgsClazzUid 
    |          
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseGroupMember.cgmLct != COALESCE(
    |         (SELECT cgmVersionId
    |            FROM CourseGroupMemberReplicate
    |           WHERE cgmPk = CourseGroupMember.cgmUid
    |             AND cgmDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |     SET cgmPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByGroupSetAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Person.*, CourseGroupMember.* 
  |          FROM Person
  |               JOIN ClazzEnrolment 
  |               ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
  |               AND ClazzEnrolment.clazzEnrolmentRole = 1000
  |               AND ClazzEnrolment.clazzEnrolmentOutcome = 200
  |               
  |               LEFT JOIN CourseGroupMember
  |               ON CourseGroupMember.cgmPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
  |               AND CourseGroupMember.cgmSetUid = ?
  |               
  |         WHERE clazzEnrolmentClazzUid = ?
  |      ORDER BY Person.firstNames
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,setUid)
    _stmt.setLong(2,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        var _tmp_CourseGroupMember_nullCount = 0
        val _tmp_cgmUid = _result.getLong("cgmUid")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmSetUid = _result.getLong("cgmSetUid")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmGroupNumber = _result.getInt("cgmGroupNumber")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmPersonUid = _result.getLong("cgmPersonUid")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmLct = _result.getLong("cgmLct")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_CourseGroupMember_isAllNull = _tmp_CourseGroupMember_nullCount == 5
        CourseGroupMemberPerson().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          if(!_tmp_CourseGroupMember_isAllNull) {
            this.member = CourseGroupMember().apply {
              this.cgmUid = _tmp_cgmUid
              this.cgmSetUid = _tmp_cgmSetUid
              this.cgmGroupNumber = _tmp_cgmGroupNumber
              this.cgmPersonUid = _tmp_cgmPersonUid
              this.cgmLct = _tmp_cgmLct
            }
          }
        }
      }
    }
  }

  public override suspend fun findByGroupSetOrderedAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Person.*, CourseGroupMember.* 
  |          FROM Person
  |               JOIN ClazzEnrolment 
  |               ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
  |               AND ClazzEnrolment.clazzEnrolmentRole = 1000 
  |               AND ClazzEnrolment.clazzEnrolmentOutcome = 200
  |               
  |               LEFT JOIN CourseGroupMember
  |               ON CourseGroupMember.cgmPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
  |               AND CourseGroupMember.cgmSetUid = ?
  |               
  |         WHERE clazzEnrolmentClazzUid = ?
  |      ORDER BY CourseGroupMember.cgmGroupNumber, Person.firstNames
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,setUid)
    _stmt.setLong(2,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        var _tmp_CourseGroupMember_nullCount = 0
        val _tmp_cgmUid = _result.getLong("cgmUid")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmSetUid = _result.getLong("cgmSetUid")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmGroupNumber = _result.getInt("cgmGroupNumber")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmPersonUid = _result.getLong("cgmPersonUid")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_cgmLct = _result.getLong("cgmLct")
        if(_result.wasNull()) _tmp_CourseGroupMember_nullCount++
        val _tmp_CourseGroupMember_isAllNull = _tmp_CourseGroupMember_nullCount == 5
        CourseGroupMemberPerson().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          if(!_tmp_CourseGroupMember_isAllNull) {
            this.member = CourseGroupMember().apply {
              this.cgmUid = _tmp_cgmUid
              this.cgmSetUid = _tmp_cgmSetUid
              this.cgmGroupNumber = _tmp_cgmGroupNumber
              this.cgmPersonUid = _tmp_cgmPersonUid
              this.cgmLct = _tmp_cgmLct
            }
          }
        }
      }
    }
  }

  public override suspend fun findByPersonUid(groupSetUid: Long, studentUid: Long):
      CourseGroupMember? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM CourseGroupMember
  |         WHERE cgmPersonUid = ? 
  |          AND cgmSetUid = ?
  |         LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,studentUid)
    _stmt.setLong(2,groupSetUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cgmUid = _result.getLong("cgmUid")
        val _tmp_cgmSetUid = _result.getLong("cgmSetUid")
        val _tmp_cgmGroupNumber = _result.getInt("cgmGroupNumber")
        val _tmp_cgmPersonUid = _result.getLong("cgmPersonUid")
        val _tmp_cgmLct = _result.getLong("cgmLct")
        CourseGroupMember().apply {
          this.cgmUid = _tmp_cgmUid
          this.cgmSetUid = _tmp_cgmSetUid
          this.cgmGroupNumber = _tmp_cgmGroupNumber
          this.cgmPersonUid = _tmp_cgmPersonUid
          this.cgmLct = _tmp_cgmLct
        }
      }
    }
  }
}
