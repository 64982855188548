package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Report
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ReportDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ReportDao,
) : ReportDao() {
  public override suspend fun replicateOnNewNodeTemplates(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeTemplates(newNodeId)
  }

  public override suspend fun replicateOnChangeTemplates(): Unit {
    _dao.replicateOnChangeTemplates()
  }

  public override fun getResults(query: DoorQuery): List<Report> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): DataSourceFactory<Int, Report> = _dao.findAllActiveReport(searchBit, personUid, sortOrder,
      isTemplate)

  public override suspend fun findByUid(entityUid: Long): Report? = _dao.findByUid(entityUid)

  public override suspend fun updateAsync(entity: Report): Unit {
    entity.reportLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(entity)
  }

  public override fun findByUidLive(uid: Long): LiveData<Report?> = _dao.findByUidLive(uid)

  public override fun findAllActiveReportLive(isTemplate: Boolean): LiveData<List<Report>> =
      _dao.findAllActiveReportLive(isTemplate)

  public override fun findAllActiveReportList(isTemplate: Boolean): List<Report> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByUidList(uidList: List<Long>): List<Long> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ): Unit {
    _dao.toggleVisibilityReportItems(toggleVisibility, selectedItem, updateTime)
  }

  public override fun replaceList(entityList: List<Report>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: Report): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Report): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.reportUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(101)
      entity.reportUid = _newPk
    }
    entity.reportLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.reportUid
  }

  public override fun insertList(entityList: List<Report>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<Report>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Report): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
