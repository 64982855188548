package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentCategory
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentCategoryDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContentCategoryDao() {
  public val _insertAdapterContentCategory_: EntityInsertionAdapter<ContentCategory> = object :
      EntityInsertionAdapter<ContentCategory>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ContentCategory (contentCategoryUid, ctnCatContentCategorySchemaUid, name, contentCategoryLocalChangeSeqNum, contentCategoryMasterChangeSeqNum, contentCategoryLastChangedBy, contentCategoryLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentCategory):
        Unit {
      if(entity.contentCategoryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentCategoryUid)
      }
      stmt.setLong(2, entity.ctnCatContentCategorySchemaUid)
      stmt.setString(3, entity.name)
      stmt.setLong(4, entity.contentCategoryLocalChangeSeqNum)
      stmt.setLong(5, entity.contentCategoryMasterChangeSeqNum)
      stmt.setInt(6, entity.contentCategoryLastChangedBy)
      stmt.setLong(7, entity.contentCategoryLct)
    }
  }

  public override fun insert(entity: ContentCategory): Long {
    val _retVal = _insertAdapterContentCategory_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentCategory): Long {
    val _retVal = _insertAdapterContentCategory_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContentCategory>): Unit {
    _insertAdapterContentCategory_.insertList(entityList)
  }

  public override fun updateList(entityList: List<ContentCategory>): Unit {
    val _sql =
        "UPDATE ContentCategory SET ctnCatContentCategorySchemaUid = ?, name = ?, contentCategoryLocalChangeSeqNum = ?, contentCategoryMasterChangeSeqNum = ?, contentCategoryLastChangedBy = ?, contentCategoryLct = ? WHERE contentCategoryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.ctnCatContentCategorySchemaUid)
        _stmt.setString(2, _entity.name)
        _stmt.setLong(3, _entity.contentCategoryLocalChangeSeqNum)
        _stmt.setLong(4, _entity.contentCategoryMasterChangeSeqNum)
        _stmt.setInt(5, _entity.contentCategoryLastChangedBy)
        _stmt.setLong(6, _entity.contentCategoryLct)
        _stmt.setLong(7, _entity.contentCategoryUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentCategory): Unit {
    val _sql =
        "UPDATE ContentCategory SET ctnCatContentCategorySchemaUid = ?, name = ?, contentCategoryLocalChangeSeqNum = ?, contentCategoryMasterChangeSeqNum = ?, contentCategoryLastChangedBy = ?, contentCategoryLct = ? WHERE contentCategoryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ctnCatContentCategorySchemaUid)
      _stmt.setString(2, entity.name)
      _stmt.setLong(3, entity.contentCategoryLocalChangeSeqNum)
      _stmt.setLong(4, entity.contentCategoryMasterChangeSeqNum)
      _stmt.setInt(5, entity.contentCategoryLastChangedBy)
      _stmt.setLong(6, entity.contentCategoryLct)
      _stmt.setLong(7, entity.contentCategoryUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentCategoryReplicate(ccPk, ccDestination)
    |      SELECT DISTINCT ContentCategory.contentCategoryUid AS ccPk,
    |             ? AS ccDestination
    |        FROM ContentCategory
    |       WHERE ContentCategory.contentCategoryLct != COALESCE(
    |             (SELECT ccVersionId
    |                FROM ContentCategoryReplicate
    |               WHERE ccPk = ContentCategory.contentCategoryUid
    |                 AND ccDestination = ?), 0) 
    |      /*psql ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |             SET ccPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentCategoryReplicate(ccPk, ccDestination)
    |      SELECT DISTINCT ContentCategory.contentCategoryUid AS ccPk,
    |             ? AS ccDestination
    |        FROM ContentCategory
    |       WHERE ContentCategory.contentCategoryLct != COALESCE(
    |             (SELECT ccVersionId
    |                FROM ContentCategoryReplicate
    |               WHERE ccPk = ContentCategory.contentCategoryUid
    |                 AND ccDestination = ?), 0) 
    |       ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |             SET ccPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ContentCategoryReplicate(ccPk, ccDestination)
    |  SELECT DISTINCT ContentCategory.contentCategoryUid AS ccUid,
    |         UserSession.usClientNodeId AS ccDestination
    |    FROM ChangeLog
    |         JOIN ContentCategory
    |             ON ChangeLog.chTableId = 1
    |                AND ChangeLog.chEntityPk = ContentCategory.contentCategoryUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentCategory.contentCategoryLct != COALESCE(
    |         (SELECT ccVersionId
    |            FROM ContentCategoryReplicate
    |           WHERE ccPk = ContentCategory.contentCategoryUid
    |             AND ccDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |     SET ccPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentCategoryReplicate(ccPk, ccDestination)
    |  SELECT DISTINCT ContentCategory.contentCategoryUid AS ccUid,
    |         UserSession.usClientNodeId AS ccDestination
    |    FROM ChangeLog
    |         JOIN ContentCategory
    |             ON ChangeLog.chTableId = 1
    |                AND ChangeLog.chEntityPk = ContentCategory.contentCategoryUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentCategory.contentCategoryLct != COALESCE(
    |         (SELECT ccVersionId
    |            FROM ContentCategoryReplicate
    |           WHERE ccPk = ContentCategory.contentCategoryUid
    |             AND ccDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |     SET ccPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun publicContentCategories(): List<ContentCategory> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT ContentCategory.* FROM ContentCategory"
      )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentCategoryUid = _result.getLong("contentCategoryUid")
        val _tmp_ctnCatContentCategorySchemaUid = _result.getLong("ctnCatContentCategorySchemaUid")
        val _tmp_name = _result.getString("name")
        val _tmp_contentCategoryLocalChangeSeqNum =
            _result.getLong("contentCategoryLocalChangeSeqNum")
        val _tmp_contentCategoryMasterChangeSeqNum =
            _result.getLong("contentCategoryMasterChangeSeqNum")
        val _tmp_contentCategoryLastChangedBy = _result.getInt("contentCategoryLastChangedBy")
        val _tmp_contentCategoryLct = _result.getLong("contentCategoryLct")
        ContentCategory().apply {
          this.contentCategoryUid = _tmp_contentCategoryUid
          this.ctnCatContentCategorySchemaUid = _tmp_ctnCatContentCategorySchemaUid
          this.name = _tmp_name
          this.contentCategoryLocalChangeSeqNum = _tmp_contentCategoryLocalChangeSeqNum
          this.contentCategoryMasterChangeSeqNum = _tmp_contentCategoryMasterChangeSeqNum
          this.contentCategoryLastChangedBy = _tmp_contentCategoryLastChangedBy
          this.contentCategoryLct = _tmp_contentCategoryLct
        }
      }
    }
  }

  public override fun findCategoryBySchemaIdAndName(schemaId: Long, name: String): ContentCategory?
      =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM ContentCategory WHERE ctnCatContentCategorySchemaUid = ? AND name = ?"
      )) { _stmt -> 
    _stmt.setLong(1,schemaId)
    _stmt.setString(2,name)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentCategoryUid = _result.getLong("contentCategoryUid")
        val _tmp_ctnCatContentCategorySchemaUid = _result.getLong("ctnCatContentCategorySchemaUid")
        val _tmp_name = _result.getString("name")
        val _tmp_contentCategoryLocalChangeSeqNum =
            _result.getLong("contentCategoryLocalChangeSeqNum")
        val _tmp_contentCategoryMasterChangeSeqNum =
            _result.getLong("contentCategoryMasterChangeSeqNum")
        val _tmp_contentCategoryLastChangedBy = _result.getInt("contentCategoryLastChangedBy")
        val _tmp_contentCategoryLct = _result.getLong("contentCategoryLct")
        ContentCategory().apply {
          this.contentCategoryUid = _tmp_contentCategoryUid
          this.ctnCatContentCategorySchemaUid = _tmp_ctnCatContentCategorySchemaUid
          this.name = _tmp_name
          this.contentCategoryLocalChangeSeqNum = _tmp_contentCategoryLocalChangeSeqNum
          this.contentCategoryMasterChangeSeqNum = _tmp_contentCategoryMasterChangeSeqNum
          this.contentCategoryLastChangedBy = _tmp_contentCategoryLastChangedBy
          this.contentCategoryLct = _tmp_contentCategoryLct
        }
      }
    }
  }
}
