package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.ConnectivityStatus
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class ConnectivityStatusDao {
  public actual abstract fun statusLive(): LiveData<ConnectivityStatus?>

  public actual abstract fun status(): ConnectivityStatus?

  public actual abstract fun insert(connectivityStatus: ConnectivityStatus): Long

  public actual abstract suspend fun insertAsync(connectivityStatus: ConnectivityStatus): Long

  public actual abstract suspend fun updateStateAsync(connectivityState: Int): Unit

  public actual abstract suspend fun updateState(connectivityState: Int, wifiSsid: String): Unit

  public actual abstract fun updateStateSync(connectivityState: Int, wifiSsid: String): Unit
}
