package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LearnerGroup
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LearnerGroupDao_JdbcKt(
  public val _db: RoomDatabase,
) : LearnerGroupDao() {
  public val _insertAdapterLearnerGroup_: EntityInsertionAdapter<LearnerGroup> = object :
      EntityInsertionAdapter<LearnerGroup>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO LearnerGroup (learnerGroupUid, learnerGroupName, learnerGroupDescription, learnerGroupActive, learnerGroupMCSN, learnerGroupCSN, learnerGroupLCB, learnerGroupLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: LearnerGroup):
        Unit {
      if(entity.learnerGroupUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.learnerGroupUid)
      }
      stmt.setString(2, entity.learnerGroupName)
      stmt.setString(3, entity.learnerGroupDescription)
      stmt.setBoolean(4, entity.learnerGroupActive)
      stmt.setLong(5, entity.learnerGroupMCSN)
      stmt.setLong(6, entity.learnerGroupCSN)
      stmt.setInt(7, entity.learnerGroupLCB)
      stmt.setLong(8, entity.learnerGroupLct)
    }
  }

  public override fun insert(entity: LearnerGroup): Long {
    val _retVal = _insertAdapterLearnerGroup_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: LearnerGroup): Long {
    val _retVal = _insertAdapterLearnerGroup_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<LearnerGroup>): Unit {
    _insertAdapterLearnerGroup_.insertList(entityList)
  }

  public override fun updateList(entityList: List<LearnerGroup>): Unit {
    val _sql =
        "UPDATE LearnerGroup SET learnerGroupName = ?, learnerGroupDescription = ?, learnerGroupActive = ?, learnerGroupMCSN = ?, learnerGroupCSN = ?, learnerGroupLCB = ?, learnerGroupLct = ? WHERE learnerGroupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.learnerGroupName)
        _stmt.setString(2, _entity.learnerGroupDescription)
        _stmt.setBoolean(3, _entity.learnerGroupActive)
        _stmt.setLong(4, _entity.learnerGroupMCSN)
        _stmt.setLong(5, _entity.learnerGroupCSN)
        _stmt.setInt(6, _entity.learnerGroupLCB)
        _stmt.setLong(7, _entity.learnerGroupLct)
        _stmt.setLong(8, _entity.learnerGroupUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: LearnerGroup): Unit {
    val _sql =
        "UPDATE LearnerGroup SET learnerGroupName = ?, learnerGroupDescription = ?, learnerGroupActive = ?, learnerGroupMCSN = ?, learnerGroupCSN = ?, learnerGroupLCB = ?, learnerGroupLct = ? WHERE learnerGroupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.learnerGroupName)
      _stmt.setString(2, entity.learnerGroupDescription)
      _stmt.setBoolean(3, entity.learnerGroupActive)
      _stmt.setLong(4, entity.learnerGroupMCSN)
      _stmt.setLong(5, entity.learnerGroupCSN)
      _stmt.setInt(6, entity.learnerGroupLCB)
      _stmt.setLong(7, entity.learnerGroupLct)
      _stmt.setLong(8, entity.learnerGroupUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO LearnerGroupReplicate(lgPk, lgDestination)
    |      SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgPk,
    |             ? AS lgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |             JOIN LearnerGroup
    |                  ON LearnerGroup.learnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid
    |            WHERE UserSession.usClientNodeId = ?
    |              AND UserSession.usStatus = 1
    |              AND LearnerGroup.learnerGroupLct != COALESCE(
    |                  (SELECT lgVersionId
    |                     FROM LearnerGroupReplicate
    |                    WHERE lgPk = LearnerGroup.learnerGroupUid
    |                      AND lgDestination = ?), 0) 
    |      /*psql ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |             SET lgPending = true
    |      */       
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupReplicate(lgPk, lgDestination)
    |      SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgPk,
    |             ? AS lgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |             JOIN LearnerGroup
    |                  ON LearnerGroup.learnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid
    |            WHERE UserSession.usClientNodeId = ?
    |              AND UserSession.usStatus = 1
    |              AND LearnerGroup.learnerGroupLct != COALESCE(
    |                  (SELECT lgVersionId
    |                     FROM LearnerGroupReplicate
    |                    WHERE lgPk = LearnerGroup.learnerGroupUid
    |                      AND lgDestination = ?), 0) 
    |       ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |             SET lgPending = true
    |             
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO LearnerGroupReplicate(lgPk, lgDestination)
    |  SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgUid,
    |         UserSession.usClientNodeId AS lgDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroup
    |              ON ChangeLog.chTableId = 301
    |                 AND ChangeLog.chEntityPk = LearnerGroup.learnerGroupUid
    |         JOIN LearnerGroupMember
    |              ON LearnerGroupMember.learnerGroupMemberLgUid = LearnerGroup.learnerGroupUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    | /*psql ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |     SET lgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupReplicate(lgPk, lgDestination)
    |  SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgUid,
    |         UserSession.usClientNodeId AS lgDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroup
    |              ON ChangeLog.chTableId = 301
    |                 AND ChangeLog.chEntityPk = LearnerGroup.learnerGroupUid
    |         JOIN LearnerGroupMember
    |              ON LearnerGroupMember.learnerGroupMemberLgUid = LearnerGroup.learnerGroupUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |  ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |     SET lgPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findGroupsForEntryAsync(contentEntryUid: Long):
      DataSourceFactory<Int, LearnerGroup> = object : DataSourceFactory<Int, LearnerGroup>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<LearnerGroup>> =
        LiveDataImpl(_db, listOf("LearnerGroup", "GroupLearningSession"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT LearnerGroup.* FROM LearnerGroup 
      |            LEFT JOIN GroupLearningSession ON 
      |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
      |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,contentEntryUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_learnerGroupUid = _result.getLong("learnerGroupUid")
            val _tmp_learnerGroupName = _result.getString("learnerGroupName")
            val _tmp_learnerGroupDescription = _result.getString("learnerGroupDescription")
            val _tmp_learnerGroupActive = _result.getBoolean("learnerGroupActive")
            val _tmp_learnerGroupMCSN = _result.getLong("learnerGroupMCSN")
            val _tmp_learnerGroupCSN = _result.getLong("learnerGroupCSN")
            val _tmp_learnerGroupLCB = _result.getInt("learnerGroupLCB")
            val _tmp_learnerGroupLct = _result.getLong("learnerGroupLct")
            LearnerGroup().apply {
              this.learnerGroupUid = _tmp_learnerGroupUid
              this.learnerGroupName = _tmp_learnerGroupName
              this.learnerGroupDescription = _tmp_learnerGroupDescription
              this.learnerGroupActive = _tmp_learnerGroupActive
              this.learnerGroupMCSN = _tmp_learnerGroupMCSN
              this.learnerGroupCSN = _tmp_learnerGroupCSN
              this.learnerGroupLCB = _tmp_learnerGroupLCB
              this.learnerGroupLct = _tmp_learnerGroupLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("LearnerGroup",
        "GroupLearningSession"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT LearnerGroup.* FROM LearnerGroup 
      |            LEFT JOIN GroupLearningSession ON 
      |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
      |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,contentEntryUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findGroupListForEntry(contentEntryUid: Long): List<LearnerGroup> =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |SELECT LearnerGroup.* FROM LearnerGroup 
  |            LEFT JOIN GroupLearningSession ON 
  |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
  |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_learnerGroupUid = _result.getLong("learnerGroupUid")
        val _tmp_learnerGroupName = _result.getString("learnerGroupName")
        val _tmp_learnerGroupDescription = _result.getString("learnerGroupDescription")
        val _tmp_learnerGroupActive = _result.getBoolean("learnerGroupActive")
        val _tmp_learnerGroupMCSN = _result.getLong("learnerGroupMCSN")
        val _tmp_learnerGroupCSN = _result.getLong("learnerGroupCSN")
        val _tmp_learnerGroupLCB = _result.getInt("learnerGroupLCB")
        val _tmp_learnerGroupLct = _result.getLong("learnerGroupLct")
        LearnerGroup().apply {
          this.learnerGroupUid = _tmp_learnerGroupUid
          this.learnerGroupName = _tmp_learnerGroupName
          this.learnerGroupDescription = _tmp_learnerGroupDescription
          this.learnerGroupActive = _tmp_learnerGroupActive
          this.learnerGroupMCSN = _tmp_learnerGroupMCSN
          this.learnerGroupCSN = _tmp_learnerGroupCSN
          this.learnerGroupLCB = _tmp_learnerGroupLCB
          this.learnerGroupLct = _tmp_learnerGroupLct
        }
      }
    }
  }
}
