package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.GroupLearningSession
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class GroupLearningSessionDao_JdbcKt(
  public val _db: RoomDatabase,
) : GroupLearningSessionDao() {
  public val _insertAdapterGroupLearningSession_: EntityInsertionAdapter<GroupLearningSession> =
      object : EntityInsertionAdapter<GroupLearningSession>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO GroupLearningSession (groupLearningSessionUid, groupLearningSessionContentUid, groupLearningSessionLearnerGroupUid, groupLearningSessionInactive, groupLearningSessionMCSN, groupLearningSessionCSN, groupLearningSessionLCB, groupLearningSessionLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: GroupLearningSession): Unit {
      if(entity.groupLearningSessionUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupLearningSessionUid)
      }
      stmt.setLong(2, entity.groupLearningSessionContentUid)
      stmt.setLong(3, entity.groupLearningSessionLearnerGroupUid)
      stmt.setBoolean(4, entity.groupLearningSessionInactive)
      stmt.setLong(5, entity.groupLearningSessionMCSN)
      stmt.setLong(6, entity.groupLearningSessionCSN)
      stmt.setInt(7, entity.groupLearningSessionLCB)
      stmt.setLong(8, entity.groupLearningSessionLct)
    }
  }

  public override fun insert(entity: GroupLearningSession): Long {
    val _retVal = _insertAdapterGroupLearningSession_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: GroupLearningSession): Long {
    val _retVal = _insertAdapterGroupLearningSession_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<GroupLearningSession>): Unit {
    _insertAdapterGroupLearningSession_.insertList(entityList)
  }

  public override fun updateList(entityList: List<GroupLearningSession>): Unit {
    val _sql =
        "UPDATE GroupLearningSession SET groupLearningSessionContentUid = ?, groupLearningSessionLearnerGroupUid = ?, groupLearningSessionInactive = ?, groupLearningSessionMCSN = ?, groupLearningSessionCSN = ?, groupLearningSessionLCB = ?, groupLearningSessionLct = ? WHERE groupLearningSessionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.groupLearningSessionContentUid)
        _stmt.setLong(2, _entity.groupLearningSessionLearnerGroupUid)
        _stmt.setBoolean(3, _entity.groupLearningSessionInactive)
        _stmt.setLong(4, _entity.groupLearningSessionMCSN)
        _stmt.setLong(5, _entity.groupLearningSessionCSN)
        _stmt.setInt(6, _entity.groupLearningSessionLCB)
        _stmt.setLong(7, _entity.groupLearningSessionLct)
        _stmt.setLong(8, _entity.groupLearningSessionUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: GroupLearningSession): Unit {
    val _sql =
        "UPDATE GroupLearningSession SET groupLearningSessionContentUid = ?, groupLearningSessionLearnerGroupUid = ?, groupLearningSessionInactive = ?, groupLearningSessionMCSN = ?, groupLearningSessionCSN = ?, groupLearningSessionLCB = ?, groupLearningSessionLct = ? WHERE groupLearningSessionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.groupLearningSessionContentUid)
      _stmt.setLong(2, entity.groupLearningSessionLearnerGroupUid)
      _stmt.setBoolean(3, entity.groupLearningSessionInactive)
      _stmt.setLong(4, entity.groupLearningSessionMCSN)
      _stmt.setLong(5, entity.groupLearningSessionCSN)
      _stmt.setInt(6, entity.groupLearningSessionLCB)
      _stmt.setLong(7, entity.groupLearningSessionLct)
      _stmt.setLong(8, entity.groupLearningSessionUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO GroupLearningSessionReplicate(glsPk, glsDestination)
    |      SELECT DISTINCT GroupLearningSession.groupLearningSessionUid AS glsPk,
    |             ? AS glsDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |             JOIN GroupLearningSession
    |                  ON GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND GroupLearningSession.groupLearningSessionLct != COALESCE(
    |             (SELECT glsVersionId
    |                FROM GroupLearningSessionReplicate
    |               WHERE glsPk = GroupLearningSession.groupLearningSessionUid
    |                 AND glsDestination = ?), 0) 
    |      /*psql ON CONFLICT(glsPk, glsDestination) DO UPDATE
    |             SET glsPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO GroupLearningSessionReplicate(glsPk, glsDestination)
    |      SELECT DISTINCT GroupLearningSession.groupLearningSessionUid AS glsPk,
    |             ? AS glsDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |             JOIN GroupLearningSession
    |                  ON GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND GroupLearningSession.groupLearningSessionLct != COALESCE(
    |             (SELECT glsVersionId
    |                FROM GroupLearningSessionReplicate
    |               WHERE glsPk = GroupLearningSession.groupLearningSessionUid
    |                 AND glsDestination = ?), 0) 
    |       ON CONFLICT(glsPk, glsDestination) DO UPDATE
    |             SET glsPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO GroupLearningSessionReplicate(glsPk, glsDestination)
    |  SELECT DISTINCT GroupLearningSession.groupLearningSessionUid AS glsUid,
    |         UserSession.usClientNodeId AS glsDestination
    |    FROM ChangeLog
    |         JOIN GroupLearningSession
    |              ON ChangeLog.chTableId = 302
    |                 AND ChangeLog.chEntityPk = GroupLearningSession.groupLearningSessionUid
    |         JOIN LearnerGroupMember
    |              ON LearnerGroupMember.learnerGroupMemberLgUid = GroupLearningSession.groupLearningSessionLearnerGroupUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND GroupLearningSession.groupLearningSessionLct != COALESCE(
    |         (SELECT glsVersionId
    |            FROM GroupLearningSessionReplicate
    |           WHERE glsPk = GroupLearningSession.groupLearningSessionUid
    |             AND glsDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(glsPk, glsDestination) DO UPDATE
    |     SET glsPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO GroupLearningSessionReplicate(glsPk, glsDestination)
    |  SELECT DISTINCT GroupLearningSession.groupLearningSessionUid AS glsUid,
    |         UserSession.usClientNodeId AS glsDestination
    |    FROM ChangeLog
    |         JOIN GroupLearningSession
    |              ON ChangeLog.chTableId = 302
    |                 AND ChangeLog.chEntityPk = GroupLearningSession.groupLearningSessionUid
    |         JOIN LearnerGroupMember
    |              ON LearnerGroupMember.learnerGroupMemberLgUid = GroupLearningSession.groupLearningSessionLearnerGroupUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND GroupLearningSession.groupLearningSessionLct != COALESCE(
    |         (SELECT glsVersionId
    |            FROM GroupLearningSessionReplicate
    |           WHERE glsPk = GroupLearningSession.groupLearningSessionUid
    |             AND glsDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(glsPk, glsDestination) DO UPDATE
    |     SET glsPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
