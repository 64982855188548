package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionWithAttachment
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentSubmissionDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseAssignmentSubmissionDao,
) : CourseAssignmentSubmissionDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<CourseAssignmentSubmission>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.casUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(522)
        it.casUid = _newPk
        _generatedPks += _newPk
      }
      it.casTimestamp = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override fun getAllSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long):
      DataSourceFactory<Int, CourseAssignmentSubmissionWithAttachment> =
      _dao.getAllSubmissionsFromSubmitter(assignmentUid, submitterUid)

  public override suspend fun countFileSubmissionFromStudent(assignmentUid: Long,
      submitterUid: Long): Int = _dao.countFileSubmissionFromStudent(assignmentUid, submitterUid)

  public override suspend fun countSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): Int = _dao.countSubmissionsFromSubmitter(assignmentUid, submitterUid)

  public override fun getStatusOfAssignmentForSubmitter(assignmentUid: Long, submitterUid: Long):
      LiveData<Int> = _dao.getStatusOfAssignmentForSubmitter(assignmentUid, submitterUid)

  public override suspend fun findLastSubmissionFromStudent(submitterUid: Long,
      assignmentUid: Long): CourseAssignmentSubmission? =
      _dao.findLastSubmissionFromStudent(submitterUid, assignmentUid)

  public override fun checkNoSubmissionsMade(assignmentUid: Long): LiveData<Boolean> =
      _dao.checkNoSubmissionsMade(assignmentUid)

  public override fun insert(entity: CourseAssignmentSubmission): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseAssignmentSubmission): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.casUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(522)
      entity.casUid = _newPk
    }
    entity.casTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.casUid
  }

  public override fun insertList(entityList: List<CourseAssignmentSubmission>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<CourseAssignmentSubmission>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseAssignmentSubmission): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
