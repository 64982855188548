package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.CourseBlockWithCompleteEntity
import com.ustadmobile.lib.db.entities.CourseBlockWithEntityDb
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CourseBlockDao : BaseDao<CourseBlock>, OneToManyJoinDao<CourseBlock> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun findByUidAsync(uid: Long): CourseBlock?

  public actual abstract suspend fun updateAsync(entity: CourseBlock): Int

  public actual abstract suspend fun replaceListAsync(list: List<CourseBlock>): Unit

  public actual abstract suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long):
      List<CourseBlockWithEntityDb>

  public actual abstract fun findAllCourseBlockByClazzUidLive(
    clazzUid: Long,
    personUid: Long,
    collapseList: List<Long>,
    currentTime: Long,
  ): DataSourceFactory<Int, CourseBlockWithCompleteEntity>

  public actual abstract suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit
}
