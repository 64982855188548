package com.ustadmobile.lib.db.entities

import com.ustadmobile.door.attachments.EntityWithAttachment
import kotlin.Int
import kotlin.String

public inline class PersonPicture_EwaAdapter(
  private val entity: PersonPicture,
) : EntityWithAttachment {
  public override var attachmentUri: String?
    get() = entity.personPictureUri
    set(`value`) {
      entity.personPictureUri = value
    }

  public override var attachmentMd5: String?
    get() = entity.personPictureMd5
    set(`value`) {
      entity.personPictureMd5 = value
    }

  public override var attachmentSize: Int
    get() = entity.fileSize
    set(`value`) {
      entity.fileSize = value
    }

  public override val tableName: String
    get() = "PersonPicture"
}

public inline fun PersonPicture.asEntityWithAttachment(): EntityWithAttachment =
    PersonPicture_EwaAdapter(this)
