package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContainerUidAndMimeType
import com.ustadmobile.lib.db.entities.ContainerWithContentEntry
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContainerDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContainerDao() {
  public val _insertAdapterContainer_: EntityInsertionAdapter<Container> = object :
      EntityInsertionAdapter<Container>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Container (containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, cntLct, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Container): Unit {
      if(entity.containerUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.containerUid)
      }
      stmt.setLong(2, entity.cntLocalCsn)
      stmt.setLong(3, entity.cntMasterCsn)
      stmt.setInt(4, entity.cntLastModBy)
      stmt.setLong(5, entity.cntLct)
      stmt.setLong(6, entity.fileSize)
      stmt.setLong(7, entity.containerContentEntryUid)
      stmt.setLong(8, entity.cntLastModified)
      stmt.setString(9, entity.mimeType)
      stmt.setString(10, entity.remarks)
      stmt.setBoolean(11, entity.mobileOptimized)
      stmt.setInt(12, entity.cntNumEntries)
    }
  }

  public val _insertAdapterContainer_upsert: EntityInsertionAdapter<Container> = object :
      EntityInsertionAdapter<Container>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO Container (containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, cntLct, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Container): Unit {
      if(entity.containerUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.containerUid)
      }
      stmt.setLong(2, entity.cntLocalCsn)
      stmt.setLong(3, entity.cntMasterCsn)
      stmt.setInt(4, entity.cntLastModBy)
      stmt.setLong(5, entity.cntLct)
      stmt.setLong(6, entity.fileSize)
      stmt.setLong(7, entity.containerContentEntryUid)
      stmt.setLong(8, entity.cntLastModified)
      stmt.setString(9, entity.mimeType)
      stmt.setString(10, entity.remarks)
      stmt.setBoolean(11, entity.mobileOptimized)
      stmt.setInt(12, entity.cntNumEntries)
    }
  }

  public override suspend fun insertListAsync(containerList: List<Container>): Unit {
    _insertAdapterContainer_.insertListAsync(containerList)
  }

  public override fun replaceList(entries: List<Container>): Unit {
    _insertAdapterContainer_upsert.insertList(entries)
  }

  public override fun insertWithReplace(container: Container): Unit {
    _insertAdapterContainer_upsert.insert(container)
  }

  public override fun insert(entity: Container): Long {
    val _retVal = _insertAdapterContainer_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Container): Long {
    val _retVal = _insertAdapterContainer_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Container>): Unit {
    _insertAdapterContainer_.insertList(entityList)
  }

  public override fun updateList(entityList: List<Container>): Unit {
    val _sql =
        "UPDATE Container SET cntLocalCsn = ?, cntMasterCsn = ?, cntLastModBy = ?, cntLct = ?, fileSize = ?, containerContentEntryUid = ?, cntLastModified = ?, mimeType = ?, remarks = ?, mobileOptimized = ?, cntNumEntries = ? WHERE containerUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cntLocalCsn)
        _stmt.setLong(2, _entity.cntMasterCsn)
        _stmt.setInt(3, _entity.cntLastModBy)
        _stmt.setLong(4, _entity.cntLct)
        _stmt.setLong(5, _entity.fileSize)
        _stmt.setLong(6, _entity.containerContentEntryUid)
        _stmt.setLong(7, _entity.cntLastModified)
        _stmt.setString(8, _entity.mimeType)
        _stmt.setString(9, _entity.remarks)
        _stmt.setBoolean(10, _entity.mobileOptimized)
        _stmt.setInt(11, _entity.cntNumEntries)
        _stmt.setLong(12, _entity.containerUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Container): Unit {
    val _sql =
        "UPDATE Container SET cntLocalCsn = ?, cntMasterCsn = ?, cntLastModBy = ?, cntLct = ?, fileSize = ?, containerContentEntryUid = ?, cntLastModified = ?, mimeType = ?, remarks = ?, mobileOptimized = ?, cntNumEntries = ? WHERE containerUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cntLocalCsn)
      _stmt.setLong(2, entity.cntMasterCsn)
      _stmt.setInt(3, entity.cntLastModBy)
      _stmt.setLong(4, entity.cntLct)
      _stmt.setLong(5, entity.fileSize)
      _stmt.setLong(6, entity.containerContentEntryUid)
      _stmt.setLong(7, entity.cntLastModified)
      _stmt.setString(8, entity.mimeType)
      _stmt.setString(9, entity.remarks)
      _stmt.setBoolean(10, entity.mobileOptimized)
      _stmt.setInt(11, entity.cntNumEntries)
      _stmt.setLong(12, entity.containerUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         REPLACE INTO ContainerReplicate(containerPk, containerDestination)
    |          SELECT DISTINCT Container.containerUid AS containerPk,
    |                 ? AS containerDestination
    |            FROM Container
    |           WHERE Container.cntLct != COALESCE(
    |                 (SELECT containerVersionId
    |                    FROM ContainerReplicate
    |                   WHERE containerPk = Container.containerUid
    |                     AND containerDestination = ?), 0) 
    |          /*psql ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |                 SET containerPending = true
    |          */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContainerReplicate(containerPk, containerDestination)
    |          SELECT DISTINCT Container.containerUid AS containerPk,
    |                 ? AS containerDestination
    |            FROM Container
    |           WHERE Container.cntLct != COALESCE(
    |                 (SELECT containerVersionId
    |                    FROM ContainerReplicate
    |                   WHERE containerPk = Container.containerUid
    |                     AND containerDestination = ?), 0) 
    |           ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |                 SET containerPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ContainerReplicate(containerPk, containerDestination)
    |  SELECT DISTINCT Container.containerUid AS containerUid,
    |         UserSession.usClientNodeId AS containerDestination
    |    FROM ChangeLog
    |         JOIN Container
    |             ON ChangeLog.chTableId = 51
    |                AND ChangeLog.chEntityPk = Container.containerUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Container.cntLct != COALESCE(
    |         (SELECT containerVersionId
    |            FROM ContainerReplicate
    |           WHERE containerPk = Container.containerUid
    |             AND containerDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |     SET containerPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContainerReplicate(containerPk, containerDestination)
    |  SELECT DISTINCT Container.containerUid AS containerUid,
    |         UserSession.usClientNodeId AS containerDestination
    |    FROM ChangeLog
    |         JOIN Container
    |             ON ChangeLog.chTableId = 51
    |                AND ChangeLog.chEntityPk = Container.containerUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Container.cntLct != COALESCE(
    |         (SELECT containerVersionId
    |            FROM ContainerReplicate
    |           WHERE containerPk = Container.containerUid
    |             AND containerDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |     SET containerPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend
      fun getMostRecentDownloadedContainerForContentEntryAsync(contentEntry: Long): Container? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("Select Container.* FROM Container WHERE Container.containerContentEntryUid = ? ORDER BY Container.cntLastModified DESC LIMIT 1"
      )) { _stmt -> 
    _stmt.setLong(1,contentEntry)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override fun getMostRecentContainerForContentEntry(contentEntryUid: Long): Container? =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |            SELECT Container.*
  |            
  |            FROM Container
  |             WHERE Container.containerContentEntryUid = ?
  |               AND 
  |            Container.fileSize > 0
  |             
  |          ORDER BY Container.cntLastModified DESC 
  |          LIMIT 1
  |        
  |        
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override fun findByUid(uid: Long): Container? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Container WHERE containerUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override suspend fun findSizeByUid(uid: Long): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT(COALESCE((
  |               SELECT fileSize
  |                 FROM Container
  |                WHERE containerUid = ?), 0))
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend
      fun findRecentContainerToBeMonitoredWithEntriesUid(contentEntries: List<Long>):
      List<Container> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT recent.* FROM Container recent LEFT JOIN Container old ON (recent.containerContentEntryUid = old.containerContentEntryUid AND recent.cntLastModified < old.cntLastModified) WHERE old.containerUid IS NULL AND recent.containerContentEntryUid IN (?)"
      ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        contentEntries.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override suspend fun findContainersForContentEntryUid(contentEntryUid: Long):
      List<Container> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |Select Container.* FROM Container 
  |                    WHERE Container.containerContentEntryUid = ?
  |                    ORDER BY Container.cntLastModified DESC
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override fun hasContainerWithFilesToDownload(contentEntryUid: Long): LiveData<Boolean> =
      LiveDataImpl(_db, listOf("Container", "ContainerEntry"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |          SELECT EXISTS(SELECT 1
    |                          FROM Container 
    |                         WHERE Container.containerContentEntryUid = ?
    |                           AND NOT EXISTS (SELECT ContainerEntry.ceUid 
    |                                         FROM ContainerEntry
    |                                        WHERE ContainerEntry.ceContainerUid = Container.containerUid)   
    |                      ORDER BY cntLastModified DESC LIMIT 1)
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(false) {
          _result.getBoolean(1)
        }
      }
    }
  }

  public override suspend fun findContainerWithFilesByContentEntryUid(contentEntryUid: Long):
      Container? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |            SELECT Container.*
  |              FROM Container
  |             WHERE Container.containerContentEntryUid = ?
  |               AND EXISTS (SELECT ContainerEntry.ceUid 
  |                             FROM ContainerEntry
  |                            WHERE ContainerEntry.ceContainerUid = Container.containerUid)     
  |          ORDER BY Container.cntLastModified DESC LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override fun findAllPublikContainers(): List<Container> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT Container.* FROM Container LEFT JOIN ContentEntry ON ContentEntry.contentEntryUid = containerContentEntryUid WHERE ContentEntry.publik"
      )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override suspend fun findByUidAsync(containerUid: Long): Container? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * From Container WHERE Container.containerUid = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override suspend fun updateContainerSizeAndNumEntriesAsync(containerUid: Long,
      changeTime: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |            UPDATE Container 
    |               SET cntNumEntries = COALESCE(
    |                   (SELECT COUNT(*) 
    |                      FROM ContainerEntry 
    |                     WHERE ceContainerUid = Container.containerUid), 0),
    |                   fileSize = COALESCE(
    |                   (SELECT SUM(ContainerEntryFile.ceCompressedSize) AS totalSize 
    |                      FROM ContainerEntry
    |                      JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid
    |                     WHERE ContainerEntry.ceContainerUid = Container.containerUid), 0),
    |                   cntLct = ?   
    |                     
    |             WHERE containerUid = ?
    |        
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,changeTime)
      _stmt.setLong(2,containerUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findLocalAvailabilityByUid(containerUid: Long): Long =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT Container.containerUid FROM Container WHERE Container.containerUid = ? AND (SELECT COUNT(*) FROM ContainerEntry WHERE ceContainerUid = Container.containerUid) = Container.cntNumEntries"
      )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override fun findAllWithId(containerUid: Long): List<Container> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Container WHERE Container.containerUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override fun findKhanContainers(): List<ContainerWithContentEntry> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT Container.*, ContentEntry.entryId, ContentEntry.sourceUrl FROM Container LEFT JOIN ContentEntry ON Container.containerContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publisher LIKE '%Khan Academy%' AND Container.mimeType = 'video/mp4'"
      )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        ContainerWithContentEntry().apply {
          this.entryId = _tmp_entryId
          this.sourceUrl = _tmp_sourceUrl
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override fun deleteByUid(containerUid: Long): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("DELETE FROM Container WHERE containerUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,containerUid)
      _stmt.executeUpdate()
    }
  }

  public override fun updateMimeType(mimeType: String, containerUid: Long): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("UPDATE Container SET mimeType = ? WHERE Container.containerUid = ?"
        )) { _stmt -> 
      _stmt.setString(1,mimeType)
      _stmt.setLong(2,containerUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun getMostRecentContainerForContentEntryAsync(contentEntryUid: Long):
      Container? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |            SELECT Container.*
  |            
  |            FROM Container
  |             WHERE Container.containerContentEntryUid = ?
  |               AND 
  |            Container.fileSize > 0
  |             
  |          ORDER BY Container.cntLastModified DESC 
  |          LIMIT 1
  |        
  |        
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }

  public override suspend fun getMostRecentContainerUidForContentEntryAsync(contentEntryUid: Long):
      Long = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT COALESCE((
  |                SELECT containerUid 
  |                 
  |            FROM Container
  |             WHERE Container.containerContentEntryUid = ?
  |               AND 
  |            Container.fileSize > 0
  |             
  |          ORDER BY Container.cntLastModified DESC 
  |          LIMIT 1
  |        ), 0)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun getMostRecentAvailableContainerUidAndMimeType(contentEntryUid: Long,
      downloadRequired: Boolean): ContainerUidAndMimeType? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Container.containerUid, Container.mimeType 
  |          FROM Container
  |         WHERE Container.containerContentEntryUid = ?
  |           AND 
  |            Container.fileSize > 0
  |        
  |           AND (CAST(? AS INTEGER) = 0
  |                OR EXISTS (SELECT ContainerEntry.ceUid 
  |                             FROM ContainerEntry
  |                            WHERE ContainerEntry.ceContainerUid = Container.containerUid))
  |      ORDER BY Container.cntLastModified DESC 
  |         LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.setBoolean(2,downloadRequired)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_mimeType = _result.getString("mimeType")
        ContainerUidAndMimeType().apply {
          this.containerUid = _tmp_containerUid
          this.mimeType = _tmp_mimeType
        }
      }
    }
  }

  public override suspend fun getContainerSizeByUid(containerUid: Long): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT COALESCE(
  |               (SELECT fileSize
  |                  FROM Container
  |                 WHERE containerUid = ?), -1)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }
}
