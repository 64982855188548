package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class HolidayCalendarDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: HolidayCalendarDao,
) : HolidayCalendarDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findAllHolidaysWithEntriesCount():
      DataSourceFactory<Int, HolidayCalendarWithNumEntries> = _dao.findAllHolidaysWithEntriesCount()

  public override fun replaceList(list: List<HolidayCalendar>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findAllHolidaysLiveData(): LiveData<List<HolidayCalendar>> =
      _dao.findAllHolidaysLiveData()

  public override fun findByUidLive(uid: Long): LiveData<HolidayCalendar?> = _dao.findByUidLive(uid)

  public override suspend fun updateAsync(entity: HolidayCalendar): Int {
    entity.umCalendarLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override suspend fun findByUid(uid: Long): HolidayCalendar? = _dao.findByUid(uid)

  public override suspend fun findByUidAsync(uid: Long): HolidayCalendar? = _dao.findByUidAsync(uid)

  public override fun insert(entity: HolidayCalendar): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: HolidayCalendar): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.umCalendarUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(28)
      entity.umCalendarUid = _newPk
    }
    entity.umCalendarLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.umCalendarUid
  }

  public override fun insertList(entityList: List<HolidayCalendar>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<HolidayCalendar>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: HolidayCalendar): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
