package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Clazz
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazzAndAttendance
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithClazzEnrolmentDetails
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzEnrolmentDao_JdbcKt(
  public val _db: RoomDatabase,
) : ClazzEnrolmentDao() {
  public val _insertAdapterClazzEnrolment_: EntityInsertionAdapter<ClazzEnrolment> = object :
      EntityInsertionAdapter<ClazzEnrolment>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ClazzEnrolment (clazzEnrolmentUid, clazzEnrolmentPersonUid, clazzEnrolmentClazzUid, clazzEnrolmentDateJoined, clazzEnrolmentDateLeft, clazzEnrolmentRole, clazzEnrolmentAttendancePercentage, clazzEnrolmentActive, clazzEnrolmentLeavingReasonUid, clazzEnrolmentOutcome, clazzEnrolmentLocalChangeSeqNum, clazzEnrolmentMasterChangeSeqNum, clazzEnrolmentLastChangedBy, clazzEnrolmentLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzEnrolment):
        Unit {
      if(entity.clazzEnrolmentUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzEnrolmentUid)
      }
      stmt.setLong(2, entity.clazzEnrolmentPersonUid)
      stmt.setLong(3, entity.clazzEnrolmentClazzUid)
      stmt.setLong(4, entity.clazzEnrolmentDateJoined)
      stmt.setLong(5, entity.clazzEnrolmentDateLeft)
      stmt.setInt(6, entity.clazzEnrolmentRole)
      stmt.setFloat(7, entity.clazzEnrolmentAttendancePercentage)
      stmt.setBoolean(8, entity.clazzEnrolmentActive)
      stmt.setLong(9, entity.clazzEnrolmentLeavingReasonUid)
      stmt.setInt(10, entity.clazzEnrolmentOutcome)
      stmt.setLong(11, entity.clazzEnrolmentLocalChangeSeqNum)
      stmt.setLong(12, entity.clazzEnrolmentMasterChangeSeqNum)
      stmt.setInt(13, entity.clazzEnrolmentLastChangedBy)
      stmt.setLong(14, entity.clazzEnrolmentLct)
    }
  }

  public override fun insertListAsync(entityList: List<ClazzEnrolment>): Unit {
    _insertAdapterClazzEnrolment_.insertList(entityList)
  }

  public override fun insert(entity: ClazzEnrolment): Long {
    val _retVal = _insertAdapterClazzEnrolment_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzEnrolment): Long {
    val _retVal = _insertAdapterClazzEnrolment_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ClazzEnrolment>): Unit {
    _insertAdapterClazzEnrolment_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: ClazzEnrolment): Int {
    var _result = 0
    val _sql =
        "UPDATE ClazzEnrolment SET clazzEnrolmentPersonUid = ?, clazzEnrolmentClazzUid = ?, clazzEnrolmentDateJoined = ?, clazzEnrolmentDateLeft = ?, clazzEnrolmentRole = ?, clazzEnrolmentAttendancePercentage = ?, clazzEnrolmentActive = ?, clazzEnrolmentLeavingReasonUid = ?, clazzEnrolmentOutcome = ?, clazzEnrolmentLocalChangeSeqNum = ?, clazzEnrolmentMasterChangeSeqNum = ?, clazzEnrolmentLastChangedBy = ?, clazzEnrolmentLct = ? WHERE clazzEnrolmentUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzEnrolmentPersonUid)
      _stmt.setLong(2, entity.clazzEnrolmentClazzUid)
      _stmt.setLong(3, entity.clazzEnrolmentDateJoined)
      _stmt.setLong(4, entity.clazzEnrolmentDateLeft)
      _stmt.setInt(5, entity.clazzEnrolmentRole)
      _stmt.setFloat(6, entity.clazzEnrolmentAttendancePercentage)
      _stmt.setBoolean(7, entity.clazzEnrolmentActive)
      _stmt.setLong(8, entity.clazzEnrolmentLeavingReasonUid)
      _stmt.setInt(9, entity.clazzEnrolmentOutcome)
      _stmt.setLong(10, entity.clazzEnrolmentLocalChangeSeqNum)
      _stmt.setLong(11, entity.clazzEnrolmentMasterChangeSeqNum)
      _stmt.setInt(12, entity.clazzEnrolmentLastChangedBy)
      _stmt.setLong(13, entity.clazzEnrolmentLct)
      _stmt.setLong(14, entity.clazzEnrolmentUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<ClazzEnrolment>): Unit {
    val _sql =
        "UPDATE ClazzEnrolment SET clazzEnrolmentPersonUid = ?, clazzEnrolmentClazzUid = ?, clazzEnrolmentDateJoined = ?, clazzEnrolmentDateLeft = ?, clazzEnrolmentRole = ?, clazzEnrolmentAttendancePercentage = ?, clazzEnrolmentActive = ?, clazzEnrolmentLeavingReasonUid = ?, clazzEnrolmentOutcome = ?, clazzEnrolmentLocalChangeSeqNum = ?, clazzEnrolmentMasterChangeSeqNum = ?, clazzEnrolmentLastChangedBy = ?, clazzEnrolmentLct = ? WHERE clazzEnrolmentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.clazzEnrolmentPersonUid)
        _stmt.setLong(2, _entity.clazzEnrolmentClazzUid)
        _stmt.setLong(3, _entity.clazzEnrolmentDateJoined)
        _stmt.setLong(4, _entity.clazzEnrolmentDateLeft)
        _stmt.setInt(5, _entity.clazzEnrolmentRole)
        _stmt.setFloat(6, _entity.clazzEnrolmentAttendancePercentage)
        _stmt.setBoolean(7, _entity.clazzEnrolmentActive)
        _stmt.setLong(8, _entity.clazzEnrolmentLeavingReasonUid)
        _stmt.setInt(9, _entity.clazzEnrolmentOutcome)
        _stmt.setLong(10, _entity.clazzEnrolmentLocalChangeSeqNum)
        _stmt.setLong(11, _entity.clazzEnrolmentMasterChangeSeqNum)
        _stmt.setInt(12, _entity.clazzEnrolmentLastChangedBy)
        _stmt.setLong(13, _entity.clazzEnrolmentLct)
        _stmt.setLong(14, _entity.clazzEnrolmentUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzEnrolment): Unit {
    val _sql =
        "UPDATE ClazzEnrolment SET clazzEnrolmentPersonUid = ?, clazzEnrolmentClazzUid = ?, clazzEnrolmentDateJoined = ?, clazzEnrolmentDateLeft = ?, clazzEnrolmentRole = ?, clazzEnrolmentAttendancePercentage = ?, clazzEnrolmentActive = ?, clazzEnrolmentLeavingReasonUid = ?, clazzEnrolmentOutcome = ?, clazzEnrolmentLocalChangeSeqNum = ?, clazzEnrolmentMasterChangeSeqNum = ?, clazzEnrolmentLastChangedBy = ?, clazzEnrolmentLct = ? WHERE clazzEnrolmentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzEnrolmentPersonUid)
      _stmt.setLong(2, entity.clazzEnrolmentClazzUid)
      _stmt.setLong(3, entity.clazzEnrolmentDateJoined)
      _stmt.setLong(4, entity.clazzEnrolmentDateLeft)
      _stmt.setInt(5, entity.clazzEnrolmentRole)
      _stmt.setFloat(6, entity.clazzEnrolmentAttendancePercentage)
      _stmt.setBoolean(7, entity.clazzEnrolmentActive)
      _stmt.setLong(8, entity.clazzEnrolmentLeavingReasonUid)
      _stmt.setInt(9, entity.clazzEnrolmentOutcome)
      _stmt.setLong(10, entity.clazzEnrolmentLocalChangeSeqNum)
      _stmt.setLong(11, entity.clazzEnrolmentMasterChangeSeqNum)
      _stmt.setInt(12, entity.clazzEnrolmentLastChangedBy)
      _stmt.setLong(13, entity.clazzEnrolmentLct)
      _stmt.setLong(14, entity.clazzEnrolmentUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |      SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |             ? AS ceDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    64 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |             JOIN ClazzEnrolment 
    |                   ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |             (SELECT ceVersionId
    |                FROM ClazzEnrolmentReplicate
    |               WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |                 AND ceDestination = ?), 0) 
    |      /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |             SET cePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |      SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |             ? AS ceDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    64 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |             JOIN ClazzEnrolment 
    |                   ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |             (SELECT ceVersionId
    |                FROM ClazzEnrolmentReplicate
    |               WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |                 AND ceDestination = ?), 0) 
    |       ON CONFLICT(cePk, ceDestination) DO UPDATE
    |             SET cePending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateClazzEnrolmentOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |  SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |         UserSession.usClientNodeId AS ceDestination
    |    FROM ChangeLog
    |         JOIN ClazzEnrolment
    |             ON ChangeLog.chTableId = 65
    |                AND ChangeLog.chEntityPk = ClazzEnrolment.clazzEnrolmentUid
    |         JOIN Clazz
    |             ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |             2
    |             
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |         (SELECT ceVersionId
    |            FROM ClazzEnrolmentReplicate
    |           WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |             AND ceDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |     SET cePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |  SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |         UserSession.usClientNodeId AS ceDestination
    |    FROM ChangeLog
    |         JOIN ClazzEnrolment
    |             ON ChangeLog.chTableId = 65
    |                AND ChangeLog.chEntityPk = ClazzEnrolment.clazzEnrolmentUid
    |         JOIN Clazz
    |             ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |             2
    |             
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |         (SELECT ceVersionId
    |            FROM ClazzEnrolmentReplicate
    |           WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |             AND ceDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cePk, ceDestination) DO UPDATE
    |     SET cePending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByPersonUidAndClazzUidAsync(personUid: Long, clazzUid: Long):
      ClazzEnrolment? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentPersonUid = ? 
  |        AND clazzEnrolmentClazzUid = ? 
  |        AND clazzEnrolmentOutcome = 200 LIMIT 1
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.setLong(2,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
        val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
        val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
        val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
        val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
        val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
        val _tmp_clazzEnrolmentAttendancePercentage =
            _result.getFloat("clazzEnrolmentAttendancePercentage")
        val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
        val _tmp_clazzEnrolmentLeavingReasonUid = _result.getLong("clazzEnrolmentLeavingReasonUid")
        val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
        val _tmp_clazzEnrolmentLocalChangeSeqNum =
            _result.getLong("clazzEnrolmentLocalChangeSeqNum")
        val _tmp_clazzEnrolmentMasterChangeSeqNum =
            _result.getLong("clazzEnrolmentMasterChangeSeqNum")
        val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
        val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
        ClazzEnrolment().apply {
          this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
          this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
          this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
          this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
          this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
          this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
          this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
          this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
          this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
          this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
          this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
          this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
          this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
          this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
        }
      }
    }
  }

  public override fun findAllEnrolmentsByPersonAndClazzUid(personUid: Long, clazzUid: Long):
      DataSourceFactory<Int, ClazzEnrolmentWithLeavingReason> = object :
      DataSourceFactory<Int, ClazzEnrolmentWithLeavingReason>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ClazzEnrolmentWithLeavingReason>> = LiveDataImpl(_db, listOf("ClazzEnrolment",
        "LeavingReason", "Clazz", "School"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT ClazzEnrolment.*, LeavingReason.*, 
      |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
      |         FROM ClazzEnrolment LEFT JOIN
      |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
      |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
      |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
      |        WHERE clazzEnrolmentPersonUid = ? 
      |        AND ClazzEnrolment.clazzEnrolmentActive 
      |        AND clazzEnrolmentClazzUid = ? ORDER BY clazzEnrolmentDateLeft DESC) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,clazzUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_timeZone = _result.getString("timeZone")
            val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
            val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
            val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
            val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
            val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
            val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
            val _tmp_clazzEnrolmentAttendancePercentage =
                _result.getFloat("clazzEnrolmentAttendancePercentage")
            val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
            val _tmp_clazzEnrolmentLeavingReasonUid =
                _result.getLong("clazzEnrolmentLeavingReasonUid")
            val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
            val _tmp_clazzEnrolmentLocalChangeSeqNum =
                _result.getLong("clazzEnrolmentLocalChangeSeqNum")
            val _tmp_clazzEnrolmentMasterChangeSeqNum =
                _result.getLong("clazzEnrolmentMasterChangeSeqNum")
            val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
            val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
            var _tmp_LeavingReason_nullCount = 0
            val _tmp_leavingReasonUid = _result.getLong("leavingReasonUid")
            if(_result.wasNull()) _tmp_LeavingReason_nullCount++
            val _tmp_leavingReasonTitle = _result.getString("leavingReasonTitle")
            if(_result.wasNull()) _tmp_LeavingReason_nullCount++
            val _tmp_leavingReasonMCSN = _result.getLong("leavingReasonMCSN")
            if(_result.wasNull()) _tmp_LeavingReason_nullCount++
            val _tmp_leavingReasonCSN = _result.getLong("leavingReasonCSN")
            if(_result.wasNull()) _tmp_LeavingReason_nullCount++
            val _tmp_leavingReasonLCB = _result.getInt("leavingReasonLCB")
            if(_result.wasNull()) _tmp_LeavingReason_nullCount++
            val _tmp_leavingReasonLct = _result.getLong("leavingReasonLct")
            if(_result.wasNull()) _tmp_LeavingReason_nullCount++
            val _tmp_LeavingReason_isAllNull = _tmp_LeavingReason_nullCount == 6
            ClazzEnrolmentWithLeavingReason().apply {
              this.timeZone = _tmp_timeZone
              this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
              this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
              this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
              this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
              this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
              this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
              this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
              this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
              this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
              this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
              this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
              this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
              this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
              this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
              if(!_tmp_LeavingReason_isAllNull) {
                this.leavingReason = LeavingReason().apply {
                  this.leavingReasonUid = _tmp_leavingReasonUid
                  this.leavingReasonTitle = _tmp_leavingReasonTitle
                  this.leavingReasonMCSN = _tmp_leavingReasonMCSN
                  this.leavingReasonCSN = _tmp_leavingReasonCSN
                  this.leavingReasonLCB = _tmp_leavingReasonLCB
                  this.leavingReasonLct = _tmp_leavingReasonLct
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ClazzEnrolment",
        "LeavingReason", "Clazz", "School"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT ClazzEnrolment.*, LeavingReason.*, 
      |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
      |         FROM ClazzEnrolment LEFT JOIN
      |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
      |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
      |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
      |        WHERE clazzEnrolmentPersonUid = ? 
      |        AND ClazzEnrolment.clazzEnrolmentActive 
      |        AND clazzEnrolmentClazzUid = ? ORDER BY clazzEnrolmentDateLeft DESC) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,clazzUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun findEnrolmentWithLeavingReason(enrolmentUid: Long):
      ClazzEnrolmentWithLeavingReason? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT ClazzEnrolment.*, LeavingReason.*,
  |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
  |         FROM ClazzEnrolment LEFT JOIN
  |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
  |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
  |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
  |        WHERE ClazzEnrolment.clazzEnrolmentUid = ?
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,enrolmentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_timeZone = _result.getString("timeZone")
        val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
        val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
        val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
        val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
        val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
        val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
        val _tmp_clazzEnrolmentAttendancePercentage =
            _result.getFloat("clazzEnrolmentAttendancePercentage")
        val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
        val _tmp_clazzEnrolmentLeavingReasonUid = _result.getLong("clazzEnrolmentLeavingReasonUid")
        val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
        val _tmp_clazzEnrolmentLocalChangeSeqNum =
            _result.getLong("clazzEnrolmentLocalChangeSeqNum")
        val _tmp_clazzEnrolmentMasterChangeSeqNum =
            _result.getLong("clazzEnrolmentMasterChangeSeqNum")
        val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
        val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
        var _tmp_LeavingReason_nullCount = 0
        val _tmp_leavingReasonUid = _result.getLong("leavingReasonUid")
        if(_result.wasNull()) _tmp_LeavingReason_nullCount++
        val _tmp_leavingReasonTitle = _result.getString("leavingReasonTitle")
        if(_result.wasNull()) _tmp_LeavingReason_nullCount++
        val _tmp_leavingReasonMCSN = _result.getLong("leavingReasonMCSN")
        if(_result.wasNull()) _tmp_LeavingReason_nullCount++
        val _tmp_leavingReasonCSN = _result.getLong("leavingReasonCSN")
        if(_result.wasNull()) _tmp_LeavingReason_nullCount++
        val _tmp_leavingReasonLCB = _result.getInt("leavingReasonLCB")
        if(_result.wasNull()) _tmp_LeavingReason_nullCount++
        val _tmp_leavingReasonLct = _result.getLong("leavingReasonLct")
        if(_result.wasNull()) _tmp_LeavingReason_nullCount++
        val _tmp_LeavingReason_isAllNull = _tmp_LeavingReason_nullCount == 6
        ClazzEnrolmentWithLeavingReason().apply {
          this.timeZone = _tmp_timeZone
          this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
          this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
          this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
          this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
          this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
          this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
          this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
          this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
          this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
          this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
          this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
          this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
          this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
          this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
          if(!_tmp_LeavingReason_isAllNull) {
            this.leavingReason = LeavingReason().apply {
              this.leavingReasonUid = _tmp_leavingReasonUid
              this.leavingReasonTitle = _tmp_leavingReasonTitle
              this.leavingReasonMCSN = _tmp_leavingReasonMCSN
              this.leavingReasonCSN = _tmp_leavingReasonCSN
              this.leavingReasonLCB = _tmp_leavingReasonLCB
              this.leavingReasonLct = _tmp_leavingReasonLct
            }
          }
        }
      }
    }
  }

  public override suspend fun updateDateLeftByUid(
    clazzEnrolmentUid: Long,
    endDate: Long,
    updateTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ClazzEnrolment 
    |          SET clazzEnrolmentDateLeft = ?,
    |              clazzEnrolmentLct = ?
    |        WHERE clazzEnrolmentUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,endDate)
      _stmt.setLong(2,updateTime)
      _stmt.setLong(3,clazzEnrolmentUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllClazzesByPersonWithClazz(personUid: Long):
      DataSourceFactory<Int, ClazzEnrolmentWithClazzAndAttendance> = object :
      DataSourceFactory<Int, ClazzEnrolmentWithClazzAndAttendance>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ClazzEnrolmentWithClazzAndAttendance>> = LiveDataImpl(_db,
        listOf("ClazzLogAttendanceRecord", "ClazzLog", "ClazzEnrolment", "Clazz"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT ClazzEnrolment.*, Clazz.*, (SELECT ((CAST(COUNT(DISTINCT CASE WHEN 
      |        ClazzLogAttendanceRecord.attendanceStatus = 1 THEN 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid ELSE NULL END) AS REAL) / 
      |        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
      |        FROM ClazzLogAttendanceRecord LEFT JOIN ClazzLog ON 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid WHERE 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = ? 
      |        AND ClazzLog.clazzLogClazzUid = Clazz.clazzUid AND ClazzLog.logDate 
      |        BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft) 
      |        as attendance
      |        FROM ClazzEnrolment
      |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
      |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
      |        AND ClazzEnrolment.clazzEnrolmentActive
      |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() , postgreSql = """
      |SELECT ClazzEnrolment.*, Clazz.*, (SELECT ((CAST(COUNT(DISTINCT CASE WHEN 
      |        ClazzLogAttendanceRecord.attendanceStatus = 1 THEN 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid ELSE NULL END) AS REAL) / 
      |        GREATEST(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
      |        FROM ClazzLogAttendanceRecord LEFT JOIN ClazzLog ON 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid WHERE 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = ? 
      |        AND ClazzLog.clazzLogClazzUid = Clazz.clazzUid AND ClazzLog.logDate 
      |        BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft) 
      |        as attendance
      |        FROM ClazzEnrolment
      |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
      |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
      |        AND ClazzEnrolment.clazzEnrolmentActive
      |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
      |    
      """.trimMargin())) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_attendance = _result.getFloat("attendance")
            val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
            val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
            val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
            val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
            val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
            val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
            val _tmp_clazzEnrolmentAttendancePercentage =
                _result.getFloat("clazzEnrolmentAttendancePercentage")
            val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
            val _tmp_clazzEnrolmentLeavingReasonUid =
                _result.getLong("clazzEnrolmentLeavingReasonUid")
            val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
            val _tmp_clazzEnrolmentLocalChangeSeqNum =
                _result.getLong("clazzEnrolmentLocalChangeSeqNum")
            val _tmp_clazzEnrolmentMasterChangeSeqNum =
                _result.getLong("clazzEnrolmentMasterChangeSeqNum")
            val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
            val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
            var _tmp_Clazz_nullCount = 0
            val _tmp_clazzUid = _result.getLong("clazzUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzName = _result.getString("clazzName")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzDesc = _result.getString("clazzDesc")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzLct = _result.getLong("clazzLct")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzPendingStudentsPersonGroupUid =
                _result.getLong("clazzPendingStudentsPersonGroupUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_clazzCode = _result.getString("clazzCode")
            if(_result.wasNull()) _tmp_Clazz_nullCount++
            val _tmp_Clazz_isAllNull = _tmp_Clazz_nullCount == 24
            ClazzEnrolmentWithClazzAndAttendance().apply {
              this.attendance = _tmp_attendance
              this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
              this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
              this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
              this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
              this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
              this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
              this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
              this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
              this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
              this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
              this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
              this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
              this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
              this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
              if(!_tmp_Clazz_isAllNull) {
                this.clazz = Clazz().apply {
                  this.clazzUid = _tmp_clazzUid
                  this.clazzName = _tmp_clazzName
                  this.clazzDesc = _tmp_clazzDesc
                  this.attendanceAverage = _tmp_attendanceAverage
                  this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
                  this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
                  this.isClazzActive = _tmp_isClazzActive
                  this.clazzLocationUid = _tmp_clazzLocationUid
                  this.clazzStartTime = _tmp_clazzStartTime
                  this.clazzEndTime = _tmp_clazzEndTime
                  this.clazzFeatures = _tmp_clazzFeatures
                  this.clazzSchoolUid = _tmp_clazzSchoolUid
                  this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
                  this.clazzTerminologyUid = _tmp_clazzTerminologyUid
                  this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
                  this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
                  this.clazzLastChangedBy = _tmp_clazzLastChangedBy
                  this.clazzLct = _tmp_clazzLct
                  this.clazzTimeZone = _tmp_clazzTimeZone
                  this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
                  this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
                  this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
                  this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
                  this.clazzCode = _tmp_clazzCode
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db,
        listOf("ClazzLogAttendanceRecord", "ClazzLog", "ClazzEnrolment", "Clazz"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT ClazzEnrolment.*, Clazz.*, (SELECT ((CAST(COUNT(DISTINCT CASE WHEN 
      |        ClazzLogAttendanceRecord.attendanceStatus = 1 THEN 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid ELSE NULL END) AS REAL) / 
      |        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
      |        FROM ClazzLogAttendanceRecord LEFT JOIN ClazzLog ON 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid WHERE 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = ? 
      |        AND ClazzLog.clazzLogClazzUid = Clazz.clazzUid AND ClazzLog.logDate 
      |        BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft) 
      |        as attendance
      |        FROM ClazzEnrolment
      |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
      |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
      |        AND ClazzEnrolment.clazzEnrolmentActive
      |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
      |    ) 
      """.trimMargin() , postgreSql = """
      |SELECT ClazzEnrolment.*, Clazz.*, (SELECT ((CAST(COUNT(DISTINCT CASE WHEN 
      |        ClazzLogAttendanceRecord.attendanceStatus = 1 THEN 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid ELSE NULL END) AS REAL) / 
      |        GREATEST(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
      |        FROM ClazzLogAttendanceRecord LEFT JOIN ClazzLog ON 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid WHERE 
      |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = ? 
      |        AND ClazzLog.clazzLogClazzUid = Clazz.clazzUid AND ClazzLog.logDate 
      |        BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft) 
      |        as attendance
      |        FROM ClazzEnrolment
      |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
      |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
      |        AND ClazzEnrolment.clazzEnrolmentActive
      |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
      |    
      """.trimMargin())) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun findMaxEndDateForEnrolment(
    selectedClazz: Long,
    selectedPerson: Long,
    selectedEnrolment: Long,
  ): Long = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT COALESCE(MAX(clazzEnrolmentDateLeft),0) FROM ClazzEnrolment WHERE 
  |        ClazzEnrolment.clazzEnrolmentPersonUid = ? 
  |        AND ClazzEnrolment.clazzEnrolmentActive 
  |        AND clazzEnrolmentClazzUid = ? AND clazzEnrolmentUid != ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,selectedPerson)
    _stmt.setLong(2,selectedClazz)
    _stmt.setLong(3,selectedEnrolment)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun findAllClazzesByPersonWithClazzAsListAsync(personUid: Long):
      List<ClazzEnrolmentWithClazz> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT ClazzEnrolment.*, Clazz.* 
  |        FROM ClazzEnrolment 
  |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
  |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ? 
  |        AND ClazzEnrolment.clazzEnrolmentActive
  |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
        val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
        val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
        val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
        val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
        val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
        val _tmp_clazzEnrolmentAttendancePercentage =
            _result.getFloat("clazzEnrolmentAttendancePercentage")
        val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
        val _tmp_clazzEnrolmentLeavingReasonUid = _result.getLong("clazzEnrolmentLeavingReasonUid")
        val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
        val _tmp_clazzEnrolmentLocalChangeSeqNum =
            _result.getLong("clazzEnrolmentLocalChangeSeqNum")
        val _tmp_clazzEnrolmentMasterChangeSeqNum =
            _result.getLong("clazzEnrolmentMasterChangeSeqNum")
        val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
        val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
        var _tmp_Clazz_nullCount = 0
        val _tmp_clazzUid = _result.getLong("clazzUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzName = _result.getString("clazzName")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzDesc = _result.getString("clazzDesc")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_attendanceAverage = _result.getFloat("attendanceAverage")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzHolidayUMCalendarUid = _result.getLong("clazzHolidayUMCalendarUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzScheuleUMCalendarUid = _result.getLong("clazzScheuleUMCalendarUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_isClazzActive = _result.getBoolean("isClazzActive")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzLocationUid = _result.getLong("clazzLocationUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzStartTime = _result.getLong("clazzStartTime")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzEndTime = _result.getLong("clazzEndTime")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzFeatures = _result.getLong("clazzFeatures")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzSchoolUid = _result.getLong("clazzSchoolUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzEnrolmentPolicy = _result.getInt("clazzEnrolmentPolicy")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzTerminologyUid = _result.getLong("clazzTerminologyUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzMasterChangeSeqNum = _result.getLong("clazzMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzLocalChangeSeqNum = _result.getLong("clazzLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzLastChangedBy = _result.getInt("clazzLastChangedBy")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzLct = _result.getLong("clazzLct")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzTimeZone = _result.getString("clazzTimeZone")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzStudentsPersonGroupUid = _result.getLong("clazzStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzTeachersPersonGroupUid = _result.getLong("clazzTeachersPersonGroupUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzPendingStudentsPersonGroupUid =
            _result.getLong("clazzPendingStudentsPersonGroupUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzParentsPersonGroupUid = _result.getLong("clazzParentsPersonGroupUid")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_clazzCode = _result.getString("clazzCode")
        if(_result.wasNull()) _tmp_Clazz_nullCount++
        val _tmp_Clazz_isAllNull = _tmp_Clazz_nullCount == 24
        ClazzEnrolmentWithClazz().apply {
          this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
          this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
          this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
          this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
          this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
          this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
          this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
          this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
          this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
          this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
          this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
          this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
          this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
          this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
          if(!_tmp_Clazz_isAllNull) {
            this.clazz = Clazz().apply {
              this.clazzUid = _tmp_clazzUid
              this.clazzName = _tmp_clazzName
              this.clazzDesc = _tmp_clazzDesc
              this.attendanceAverage = _tmp_attendanceAverage
              this.clazzHolidayUMCalendarUid = _tmp_clazzHolidayUMCalendarUid
              this.clazzScheuleUMCalendarUid = _tmp_clazzScheuleUMCalendarUid
              this.isClazzActive = _tmp_isClazzActive
              this.clazzLocationUid = _tmp_clazzLocationUid
              this.clazzStartTime = _tmp_clazzStartTime
              this.clazzEndTime = _tmp_clazzEndTime
              this.clazzFeatures = _tmp_clazzFeatures
              this.clazzSchoolUid = _tmp_clazzSchoolUid
              this.clazzEnrolmentPolicy = _tmp_clazzEnrolmentPolicy
              this.clazzTerminologyUid = _tmp_clazzTerminologyUid
              this.clazzMasterChangeSeqNum = _tmp_clazzMasterChangeSeqNum
              this.clazzLocalChangeSeqNum = _tmp_clazzLocalChangeSeqNum
              this.clazzLastChangedBy = _tmp_clazzLastChangedBy
              this.clazzLct = _tmp_clazzLct
              this.clazzTimeZone = _tmp_clazzTimeZone
              this.clazzStudentsPersonGroupUid = _tmp_clazzStudentsPersonGroupUid
              this.clazzTeachersPersonGroupUid = _tmp_clazzTeachersPersonGroupUid
              this.clazzPendingStudentsPersonGroupUid = _tmp_clazzPendingStudentsPersonGroupUid
              this.clazzParentsPersonGroupUid = _tmp_clazzParentsPersonGroupUid
              this.clazzCode = _tmp_clazzCode
            }
          }
        }
      }
    }
  }

  public override suspend fun getAllClazzEnrolledAtTimeAsync(
    clazzUid: Long,
    date: Long,
    roleFilter: Int,
    personUidFilter: Long,
  ): List<ClazzEnrolmentWithPerson> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ClazzEnrolment.*, Person.*
  |          FROM ClazzEnrolment
  |    LEFT JOIN Person ON ClazzEnrolment.clazzEnrolmentPersonUid = Person.personUid
  |        WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ?
  |              AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
  |              AND ClazzEnrolment.clazzEnrolmentDateLeft
  |              AND CAST(clazzEnrolmentActive AS INTEGER) = 1
  |              AND (? = 0 OR ClazzEnrolment.clazzEnrolmentRole = ?)
  |              AND (? = 0 OR ClazzEnrolment.clazzEnrolmentPersonUid = ?)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.setLong(2,date)
    _stmt.setInt(3,roleFilter)
    _stmt.setInt(4,roleFilter)
    _stmt.setLong(5,personUidFilter)
    _stmt.setLong(6,personUidFilter)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
        val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
        val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
        val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
        val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
        val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
        val _tmp_clazzEnrolmentAttendancePercentage =
            _result.getFloat("clazzEnrolmentAttendancePercentage")
        val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
        val _tmp_clazzEnrolmentLeavingReasonUid = _result.getLong("clazzEnrolmentLeavingReasonUid")
        val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
        val _tmp_clazzEnrolmentLocalChangeSeqNum =
            _result.getLong("clazzEnrolmentLocalChangeSeqNum")
        val _tmp_clazzEnrolmentMasterChangeSeqNum =
            _result.getLong("clazzEnrolmentMasterChangeSeqNum")
        val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
        val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        ClazzEnrolmentWithPerson().apply {
          this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
          this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
          this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
          this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
          this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
          this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
          this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
          this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
          this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
          this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
          this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
          this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
          this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
          this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }
  }

  public override suspend fun findByUid(uid: Long): ClazzEnrolment? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
        val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
        val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
        val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
        val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
        val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
        val _tmp_clazzEnrolmentAttendancePercentage =
            _result.getFloat("clazzEnrolmentAttendancePercentage")
        val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
        val _tmp_clazzEnrolmentLeavingReasonUid = _result.getLong("clazzEnrolmentLeavingReasonUid")
        val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
        val _tmp_clazzEnrolmentLocalChangeSeqNum =
            _result.getLong("clazzEnrolmentLocalChangeSeqNum")
        val _tmp_clazzEnrolmentMasterChangeSeqNum =
            _result.getLong("clazzEnrolmentMasterChangeSeqNum")
        val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
        val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
        ClazzEnrolment().apply {
          this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
          this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
          this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
          this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
          this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
          this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
          this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
          this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
          this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
          this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
          this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
          this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
          this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
          this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<ClazzEnrolment?> = LiveDataImpl(_db,
      listOf("ClazzEnrolment"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_clazzEnrolmentUid = _result.getLong("clazzEnrolmentUid")
          val _tmp_clazzEnrolmentPersonUid = _result.getLong("clazzEnrolmentPersonUid")
          val _tmp_clazzEnrolmentClazzUid = _result.getLong("clazzEnrolmentClazzUid")
          val _tmp_clazzEnrolmentDateJoined = _result.getLong("clazzEnrolmentDateJoined")
          val _tmp_clazzEnrolmentDateLeft = _result.getLong("clazzEnrolmentDateLeft")
          val _tmp_clazzEnrolmentRole = _result.getInt("clazzEnrolmentRole")
          val _tmp_clazzEnrolmentAttendancePercentage =
              _result.getFloat("clazzEnrolmentAttendancePercentage")
          val _tmp_clazzEnrolmentActive = _result.getBoolean("clazzEnrolmentActive")
          val _tmp_clazzEnrolmentLeavingReasonUid =
              _result.getLong("clazzEnrolmentLeavingReasonUid")
          val _tmp_clazzEnrolmentOutcome = _result.getInt("clazzEnrolmentOutcome")
          val _tmp_clazzEnrolmentLocalChangeSeqNum =
              _result.getLong("clazzEnrolmentLocalChangeSeqNum")
          val _tmp_clazzEnrolmentMasterChangeSeqNum =
              _result.getLong("clazzEnrolmentMasterChangeSeqNum")
          val _tmp_clazzEnrolmentLastChangedBy = _result.getInt("clazzEnrolmentLastChangedBy")
          val _tmp_clazzEnrolmentLct = _result.getLong("clazzEnrolmentLct")
          ClazzEnrolment().apply {
            this.clazzEnrolmentUid = _tmp_clazzEnrolmentUid
            this.clazzEnrolmentPersonUid = _tmp_clazzEnrolmentPersonUid
            this.clazzEnrolmentClazzUid = _tmp_clazzEnrolmentClazzUid
            this.clazzEnrolmentDateJoined = _tmp_clazzEnrolmentDateJoined
            this.clazzEnrolmentDateLeft = _tmp_clazzEnrolmentDateLeft
            this.clazzEnrolmentRole = _tmp_clazzEnrolmentRole
            this.clazzEnrolmentAttendancePercentage = _tmp_clazzEnrolmentAttendancePercentage
            this.clazzEnrolmentActive = _tmp_clazzEnrolmentActive
            this.clazzEnrolmentLeavingReasonUid = _tmp_clazzEnrolmentLeavingReasonUid
            this.clazzEnrolmentOutcome = _tmp_clazzEnrolmentOutcome
            this.clazzEnrolmentLocalChangeSeqNum = _tmp_clazzEnrolmentLocalChangeSeqNum
            this.clazzEnrolmentMasterChangeSeqNum = _tmp_clazzEnrolmentMasterChangeSeqNum
            this.clazzEnrolmentLastChangedBy = _tmp_clazzEnrolmentLastChangedBy
            this.clazzEnrolmentLct = _tmp_clazzEnrolmentLct
          }
        }
      }
    }
  }

  public override suspend fun updateClazzEnrolmentActiveForPersonAndClazz(
    personUid: Long,
    clazzUid: Long,
    roleId: Int,
    active: Boolean,
    changeTime: Long,
  ): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |                UPDATE ClazzEnrolment
  |                   SET clazzEnrolmentActive = ?,
  |                       clazzEnrolmentLct= ?
  |                WHERE clazzEnrolmentPersonUid = ? 
  |                      AND clazzEnrolmentClazzUid = ?
  |                      AND clazzEnrolmentRole = ?
  """.trimMargin() )) { _stmt -> 
    _stmt.setBoolean(1,active)
    _stmt.setLong(2,changeTime)
    _stmt.setLong(3,personUid)
    _stmt.setLong(4,clazzUid)
    _stmt.setInt(5,roleId)
    _stmt.executeUpdateAsyncKmp()
  }

  public override fun findByClazzUidAndRole(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
  ): DataSourceFactory<Int, PersonWithClazzEnrolmentDetails> = object :
      DataSourceFactory<Int, PersonWithClazzEnrolmentDetails>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<PersonWithClazzEnrolmentDetails>> = LiveDataImpl(_db, listOf("Clazz",
        "Person", "ClazzEnrolment", "PersonGroupMember", "ScopedGrant"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Person.*, 
      |               (SELECT ((CAST(COUNT(DISTINCT 
      |                        CASE WHEN ClazzLogAttendanceRecord.attendanceStatus = 1 
      |                                  THEN ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid 
      |                             ELSE NULL 
      |                             END) 
      |                        AS REAL) / 
      |                        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
      |                   FROM ClazzLogAttendanceRecord 
      |                        JOIN ClazzLog 
      |                             ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid 
      |                  WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid 
      |                    AND ClazzLog.clazzLogClazzUid = ?)  AS attendance, 
      |        
      |    	       (SELECT MIN(ClazzEnrolment.clazzEnrolmentDateJoined) 
      |                  FROM ClazzEnrolment 
      |                 WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS earliestJoinDate, 
      |        
      |    	      (SELECT MAX(ClazzEnrolment.clazzEnrolmentDateLeft) 
      |                 FROM ClazzEnrolment 
      |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS latestDateLeft, 
      |        
      |              (SELECT clazzEnrolmentRole 
      |                 FROM clazzEnrolment 
      |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid 
      |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ? 
      |        AND ClazzEnrolment.clazzEnrolmentActive) AS enrolmentRole
      |        FROM PersonGroupMember
      |        
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions & 64 
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |         
      |        
      |         WHERE PersonGroupMember.groupMemberPersonUid = ?
      |           AND PersonGroupMember.groupMemberActive 
      |           AND Person.personUid IN (SELECT clazzEnrolmentPersonUid 
      |                                      FROM ClazzEnrolment 
      |                                     WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ? 
      |                                       AND ClazzEnrolment.clazzEnrolmentActive 
      |                                       AND ClazzEnrolment.clazzEnrolmentRole = ? 
      |                                       AND (? != 1 
      |                                        OR (? 
      |                                            BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                            AND ClazzEnrolment.clazzEnrolmentDateLeft))) 
      |          AND Person.firstNames || ' ' || Person.lastName LIKE ?
      |     GROUP BY Person.personUid
      |     ORDER BY CASE(?)
      |                WHEN 1 THEN Person.firstNames
      |                WHEN 3 THEN Person.lastName
      |                ELSE ''
      |            END ASC,
      |            CASE(?)
      |                WHEN 2 THEN Person.firstNames
      |                WHEN 4 THEN Person.lastName
      |                ELSE ''
      |            END DESC,
      |            CASE(?)
      |                WHEN 5 THEN attendance
      |                WHEN 7 THEN earliestJoinDate
      |                WHEN 9 THEN latestDateLeft
      |                ELSE 0
      |            END ASC,
      |            CASE(?)
      |                WHEN 6 THEN attendance
      |                WHEN 8 THEN earliestJoinDate
      |                WHEN 10 THEN latestDateLeft
      |                ELSE 0
      |            END DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.setLong(2,clazzUid)
        _stmt.setLong(3,accountPersonUid)
        _stmt.setLong(4,clazzUid)
        _stmt.setInt(5,roleId)
        _stmt.setInt(6,filter)
        _stmt.setLong(7,currentTime)
        _stmt.setString(8,searchText)
        _stmt.setInt(9,sortOrder)
        _stmt.setInt(10,sortOrder)
        _stmt.setInt(11,sortOrder)
        _stmt.setInt(12,sortOrder)
        _stmt.setInt(13,_limit)
        _stmt.setInt(14,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_attendance = _result.getFloat("attendance")
            val _tmp_earliestJoinDate = _result.getLong("earliestJoinDate")
            val _tmp_latestDateLeft = _result.getLong("latestDateLeft")
            val _tmp_enrolmentRole = _result.getInt("enrolmentRole")
            val _tmp_personUid = _result.getLong("personUid")
            val _tmp_username = _result.getString("username")
            val _tmp_firstNames = _result.getString("firstNames")
            val _tmp_lastName = _result.getString("lastName")
            val _tmp_emailAddr = _result.getString("emailAddr")
            val _tmp_phoneNum = _result.getString("phoneNum")
            val _tmp_gender = _result.getInt("gender")
            val _tmp_active = _result.getBoolean("active")
            val _tmp_admin = _result.getBoolean("admin")
            val _tmp_personNotes = _result.getString("personNotes")
            val _tmp_fatherName = _result.getString("fatherName")
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            val _tmp_motherName = _result.getString("motherName")
            val _tmp_motherNum = _result.getString("motherNum")
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            val _tmp_personAddress = _result.getString("personAddress")
            val _tmp_personOrgId = _result.getString("personOrgId")
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            val _tmp_personLct = _result.getLong("personLct")
            val _tmp_personCountry = _result.getString("personCountry")
            val _tmp_personType = _result.getInt("personType")
            PersonWithClazzEnrolmentDetails().apply {
              this.attendance = _tmp_attendance
              this.earliestJoinDate = _tmp_earliestJoinDate
              this.latestDateLeft = _tmp_latestDateLeft
              this.enrolmentRole = _tmp_enrolmentRole
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Clazz", "Person",
        "ClazzEnrolment", "PersonGroupMember", "ScopedGrant"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Person.*, 
      |               (SELECT ((CAST(COUNT(DISTINCT 
      |                        CASE WHEN ClazzLogAttendanceRecord.attendanceStatus = 1 
      |                                  THEN ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid 
      |                             ELSE NULL 
      |                             END) 
      |                        AS REAL) / 
      |                        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
      |                   FROM ClazzLogAttendanceRecord 
      |                        JOIN ClazzLog 
      |                             ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid 
      |                  WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid 
      |                    AND ClazzLog.clazzLogClazzUid = ?)  AS attendance, 
      |        
      |    	       (SELECT MIN(ClazzEnrolment.clazzEnrolmentDateJoined) 
      |                  FROM ClazzEnrolment 
      |                 WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS earliestJoinDate, 
      |        
      |    	      (SELECT MAX(ClazzEnrolment.clazzEnrolmentDateLeft) 
      |                 FROM ClazzEnrolment 
      |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS latestDateLeft, 
      |        
      |              (SELECT clazzEnrolmentRole 
      |                 FROM clazzEnrolment 
      |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid 
      |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ? 
      |        AND ClazzEnrolment.clazzEnrolmentActive) AS enrolmentRole
      |        FROM PersonGroupMember
      |        
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions & 64 
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |         
      |        
      |         WHERE PersonGroupMember.groupMemberPersonUid = ?
      |           AND PersonGroupMember.groupMemberActive 
      |           AND Person.personUid IN (SELECT clazzEnrolmentPersonUid 
      |                                      FROM ClazzEnrolment 
      |                                     WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ? 
      |                                       AND ClazzEnrolment.clazzEnrolmentActive 
      |                                       AND ClazzEnrolment.clazzEnrolmentRole = ? 
      |                                       AND (? != 1 
      |                                        OR (? 
      |                                            BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                            AND ClazzEnrolment.clazzEnrolmentDateLeft))) 
      |          AND Person.firstNames || ' ' || Person.lastName LIKE ?
      |     GROUP BY Person.personUid
      |     ORDER BY CASE(?)
      |                WHEN 1 THEN Person.firstNames
      |                WHEN 3 THEN Person.lastName
      |                ELSE ''
      |            END ASC,
      |            CASE(?)
      |                WHEN 2 THEN Person.firstNames
      |                WHEN 4 THEN Person.lastName
      |                ELSE ''
      |            END DESC,
      |            CASE(?)
      |                WHEN 5 THEN attendance
      |                WHEN 7 THEN earliestJoinDate
      |                WHEN 9 THEN latestDateLeft
      |                ELSE 0
      |            END ASC,
      |            CASE(?)
      |                WHEN 6 THEN attendance
      |                WHEN 8 THEN earliestJoinDate
      |                WHEN 10 THEN latestDateLeft
      |                ELSE 0
      |            END DESC
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.setLong(2,clazzUid)
        _stmt.setLong(3,accountPersonUid)
        _stmt.setLong(4,clazzUid)
        _stmt.setInt(5,roleId)
        _stmt.setInt(6,filter)
        _stmt.setLong(7,currentTime)
        _stmt.setString(8,searchText)
        _stmt.setInt(9,sortOrder)
        _stmt.setInt(10,sortOrder)
        _stmt.setInt(11,sortOrder)
        _stmt.setInt(12,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun updateClazzEnrolmentActiveForClazzEnrolment(
    clazzEnrolmentUid: Long,
    enrolled: Boolean,
    timeChanged: Long,
  ): Int = _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        UPDATE ClazzEnrolment 
  |          SET clazzEnrolmentActive = ?,
  |              clazzEnrolmentLct = ?
  |        WHERE clazzEnrolmentUid = ?
  """.trimMargin() )) { _stmt -> 
    _stmt.setBoolean(1,enrolled)
    _stmt.setLong(2,timeChanged)
    _stmt.setLong(3,clazzEnrolmentUid)
    _stmt.executeUpdate()
  }

  public override suspend fun updateClazzEnrolmentRole(
    personUid: Long,
    clazzUid: Long,
    newRole: Int,
    oldRole: Int,
    updateTime: Long,
  ): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |            UPDATE ClazzEnrolment 
  |               SET clazzEnrolmentRole = ?,
  |                   clazzEnrolmentLct = ?      
  |             -- Avoid potential for duplicate approvals if user was previously refused      
  |             WHERE clazzEnrolmentUid = COALESCE( 
  |                    (SELECT clazzEnrolmentUid
  |                       FROM ClazzEnrolment
  |                      WHERE clazzEnrolmentPersonUid = ? 
  |                            AND clazzEnrolmentClazzUid = ?
  |                            AND clazzEnrolmentRole = ?
  |                            AND CAST(clazzEnrolmentActive AS INTEGER) = 1
  |                      LIMIT 1), 0)
  """.trimMargin() )) { _stmt -> 
    _stmt.setInt(1,newRole)
    _stmt.setLong(2,updateTime)
    _stmt.setLong(3,personUid)
    _stmt.setLong(4,clazzUid)
    _stmt.setInt(5,oldRole)
    _stmt.executeUpdateAsyncKmp()
  }
}
