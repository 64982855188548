package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LanguageVariant
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class LanguageVariantDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: LanguageVariantDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : LanguageVariantDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByCode(countryCode: String): LanguageVariant? {
    val _result = _dao.findByCode(countryCode)
    return _result
  }

  public override fun insert(entity: LanguageVariant): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: LanguageVariant): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<LanguageVariant>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<LanguageVariant>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: LanguageVariant): Unit {
    _dao.update(entity)
  }
}
