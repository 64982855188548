package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.copy
import com.ustadmobile.door.ext.copyWithExtraParams
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.hasListOrArrayParams
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary
import com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay
import com.ustadmobile.lib.db.entities.StatementEntity
import com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class StatementDao_JdbcKt(
  public val _db: RoomDatabase,
) : StatementDao() {
  public val _insertAdapterStatementEntity_: EntityInsertionAdapter<StatementEntity> = object :
      EntityInsertionAdapter<StatementEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO StatementEntity (statementUid, statementId, statementPersonUid, statementVerbUid, xObjectUid, subStatementActorUid, substatementVerbUid, subStatementObjectUid, agentUid, instructorUid, authorityUid, teamUid, resultCompletion, resultSuccess, resultScoreScaled, resultScoreRaw, resultScoreMin, resultScoreMax, resultDuration, resultResponse, timestamp, stored, contextRegistration, contextPlatform, contextStatementId, fullStatement, statementMasterChangeSeqNum, statementLocalChangeSeqNum, statementLastChangedBy, statementLct, extensionProgress, contentEntryRoot, statementContentEntryUid, statementLearnerGroupUid, statementClazzUid) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: StatementEntity):
        Unit {
      if(entity.statementUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.statementUid)
      }
      stmt.setString(2, entity.statementId)
      stmt.setLong(3, entity.statementPersonUid)
      stmt.setLong(4, entity.statementVerbUid)
      stmt.setLong(5, entity.xObjectUid)
      stmt.setLong(6, entity.subStatementActorUid)
      stmt.setLong(7, entity.substatementVerbUid)
      stmt.setLong(8, entity.subStatementObjectUid)
      stmt.setLong(9, entity.agentUid)
      stmt.setLong(10, entity.instructorUid)
      stmt.setLong(11, entity.authorityUid)
      stmt.setLong(12, entity.teamUid)
      stmt.setBoolean(13, entity.resultCompletion)
      stmt.setByte(14, entity.resultSuccess)
      stmt.setFloat(15, entity.resultScoreScaled)
      stmt.setLong(16, entity.resultScoreRaw)
      stmt.setLong(17, entity.resultScoreMin)
      stmt.setLong(18, entity.resultScoreMax)
      stmt.setLong(19, entity.resultDuration)
      stmt.setString(20, entity.resultResponse)
      stmt.setLong(21, entity.timestamp)
      stmt.setLong(22, entity.stored)
      stmt.setString(23, entity.contextRegistration)
      stmt.setString(24, entity.contextPlatform)
      stmt.setString(25, entity.contextStatementId)
      stmt.setString(26, entity.fullStatement)
      stmt.setLong(27, entity.statementMasterChangeSeqNum)
      stmt.setLong(28, entity.statementLocalChangeSeqNum)
      stmt.setInt(29, entity.statementLastChangedBy)
      stmt.setLong(30, entity.statementLct)
      stmt.setInt(31, entity.extensionProgress)
      stmt.setBoolean(32, entity.contentEntryRoot)
      stmt.setLong(33, entity.statementContentEntryUid)
      stmt.setLong(34, entity.statementLearnerGroupUid)
      stmt.setLong(35, entity.statementClazzUid)
    }
  }

  public override suspend fun insertListAsync(entityList: List<StatementEntity>): Unit {
    _insertAdapterStatementEntity_.insertListAsync(entityList)
  }

  public override fun insert(entity: StatementEntity): Long {
    val _retVal = _insertAdapterStatementEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: StatementEntity): Long {
    val _retVal = _insertAdapterStatementEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<StatementEntity>): Unit {
    _insertAdapterStatementEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<StatementEntity>): Unit {
    val _sql =
        "UPDATE StatementEntity SET statementId = ?, statementPersonUid = ?, statementVerbUid = ?, xObjectUid = ?, subStatementActorUid = ?, substatementVerbUid = ?, subStatementObjectUid = ?, agentUid = ?, instructorUid = ?, authorityUid = ?, teamUid = ?, resultCompletion = ?, resultSuccess = ?, resultScoreScaled = ?, resultScoreRaw = ?, resultScoreMin = ?, resultScoreMax = ?, resultDuration = ?, resultResponse = ?, timestamp = ?, stored = ?, contextRegistration = ?, contextPlatform = ?, contextStatementId = ?, fullStatement = ?, statementMasterChangeSeqNum = ?, statementLocalChangeSeqNum = ?, statementLastChangedBy = ?, statementLct = ?, extensionProgress = ?, contentEntryRoot = ?, statementContentEntryUid = ?, statementLearnerGroupUid = ?, statementClazzUid = ? WHERE statementUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.statementId)
        _stmt.setLong(2, _entity.statementPersonUid)
        _stmt.setLong(3, _entity.statementVerbUid)
        _stmt.setLong(4, _entity.xObjectUid)
        _stmt.setLong(5, _entity.subStatementActorUid)
        _stmt.setLong(6, _entity.substatementVerbUid)
        _stmt.setLong(7, _entity.subStatementObjectUid)
        _stmt.setLong(8, _entity.agentUid)
        _stmt.setLong(9, _entity.instructorUid)
        _stmt.setLong(10, _entity.authorityUid)
        _stmt.setLong(11, _entity.teamUid)
        _stmt.setBoolean(12, _entity.resultCompletion)
        _stmt.setByte(13, _entity.resultSuccess)
        _stmt.setFloat(14, _entity.resultScoreScaled)
        _stmt.setLong(15, _entity.resultScoreRaw)
        _stmt.setLong(16, _entity.resultScoreMin)
        _stmt.setLong(17, _entity.resultScoreMax)
        _stmt.setLong(18, _entity.resultDuration)
        _stmt.setString(19, _entity.resultResponse)
        _stmt.setLong(20, _entity.timestamp)
        _stmt.setLong(21, _entity.stored)
        _stmt.setString(22, _entity.contextRegistration)
        _stmt.setString(23, _entity.contextPlatform)
        _stmt.setString(24, _entity.contextStatementId)
        _stmt.setString(25, _entity.fullStatement)
        _stmt.setLong(26, _entity.statementMasterChangeSeqNum)
        _stmt.setLong(27, _entity.statementLocalChangeSeqNum)
        _stmt.setInt(28, _entity.statementLastChangedBy)
        _stmt.setLong(29, _entity.statementLct)
        _stmt.setInt(30, _entity.extensionProgress)
        _stmt.setBoolean(31, _entity.contentEntryRoot)
        _stmt.setLong(32, _entity.statementContentEntryUid)
        _stmt.setLong(33, _entity.statementLearnerGroupUid)
        _stmt.setLong(34, _entity.statementClazzUid)
        _stmt.setLong(35, _entity.statementUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: StatementEntity): Unit {
    val _sql =
        "UPDATE StatementEntity SET statementId = ?, statementPersonUid = ?, statementVerbUid = ?, xObjectUid = ?, subStatementActorUid = ?, substatementVerbUid = ?, subStatementObjectUid = ?, agentUid = ?, instructorUid = ?, authorityUid = ?, teamUid = ?, resultCompletion = ?, resultSuccess = ?, resultScoreScaled = ?, resultScoreRaw = ?, resultScoreMin = ?, resultScoreMax = ?, resultDuration = ?, resultResponse = ?, timestamp = ?, stored = ?, contextRegistration = ?, contextPlatform = ?, contextStatementId = ?, fullStatement = ?, statementMasterChangeSeqNum = ?, statementLocalChangeSeqNum = ?, statementLastChangedBy = ?, statementLct = ?, extensionProgress = ?, contentEntryRoot = ?, statementContentEntryUid = ?, statementLearnerGroupUid = ?, statementClazzUid = ? WHERE statementUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.statementId)
      _stmt.setLong(2, entity.statementPersonUid)
      _stmt.setLong(3, entity.statementVerbUid)
      _stmt.setLong(4, entity.xObjectUid)
      _stmt.setLong(5, entity.subStatementActorUid)
      _stmt.setLong(6, entity.substatementVerbUid)
      _stmt.setLong(7, entity.subStatementObjectUid)
      _stmt.setLong(8, entity.agentUid)
      _stmt.setLong(9, entity.instructorUid)
      _stmt.setLong(10, entity.authorityUid)
      _stmt.setLong(11, entity.teamUid)
      _stmt.setBoolean(12, entity.resultCompletion)
      _stmt.setByte(13, entity.resultSuccess)
      _stmt.setFloat(14, entity.resultScoreScaled)
      _stmt.setLong(15, entity.resultScoreRaw)
      _stmt.setLong(16, entity.resultScoreMin)
      _stmt.setLong(17, entity.resultScoreMax)
      _stmt.setLong(18, entity.resultDuration)
      _stmt.setString(19, entity.resultResponse)
      _stmt.setLong(20, entity.timestamp)
      _stmt.setLong(21, entity.stored)
      _stmt.setString(22, entity.contextRegistration)
      _stmt.setString(23, entity.contextPlatform)
      _stmt.setString(24, entity.contextStatementId)
      _stmt.setString(25, entity.fullStatement)
      _stmt.setLong(26, entity.statementMasterChangeSeqNum)
      _stmt.setLong(27, entity.statementLocalChangeSeqNum)
      _stmt.setInt(28, entity.statementLastChangedBy)
      _stmt.setLong(29, entity.statementLct)
      _stmt.setInt(30, entity.extensionProgress)
      _stmt.setBoolean(31, entity.contentEntryRoot)
      _stmt.setLong(32, entity.statementContentEntryUid)
      _stmt.setLong(33, entity.statementLearnerGroupUid)
      _stmt.setLong(34, entity.statementClazzUid)
      _stmt.setLong(35, entity.statementUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO StatementEntityReplicate(sePk, seDestination)
    |      SELECT DISTINCT StatementEntity.statementUid AS sePk,
    |             ? AS seDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |             JOIN StatementEntity
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz 
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |            )         
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         -- Temporary measure to prevent admin user getting clogged up
    |         -- Restrict to the last 30 days of data
    |         AND StatementEntity.timestamp > ( 
    |       --notpsql
    |       strftime('%s', 'now') * 1000
    |       --endnotpsql
    |       /*psql
    |       ROUND(EXTRACT(epoch from NOW())*1000)
    |       */
    |       - (30 * CAST(86400000 AS BIGINT)))
    |       --notpsql
    |         AND StatementEntity.statementLct != COALESCE(
    |             (SELECT seVersionId
    |                FROM StatementEntityReplicate
    |               WHERE sePk = StatementEntity.statementUid
    |                 AND seDestination = UserSession.usClientNodeId), 0)
    |       --endnotpsql           
    |      /*psql ON CONFLICT(sePk, seDestination) DO UPDATE
    |             SET sePending = (SELECT StatementEntity.statementLct
    |            FROM StatementEntity
    |           WHERE StatementEntity.statementUid = EXCLUDED.sePk ) 
    |                 != StatementEntityReplicate.seVersionId
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO StatementEntityReplicate(sePk, seDestination)
    |      SELECT DISTINCT StatementEntity.statementUid AS sePk,
    |             ? AS seDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |             JOIN StatementEntity
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz 
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |            )         
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         -- Temporary measure to prevent admin user getting clogged up
    |         -- Restrict to the last 30 days of data
    |         AND StatementEntity.timestamp > ( 
    |       
    |       ROUND(EXTRACT(epoch from NOW())*1000)
    |       
    |       - (30 * CAST(86400000 AS BIGINT)))
    |       ON CONFLICT(sePk, seDestination) DO UPDATE
    |             SET sePending = (SELECT StatementEntity.statementLct
    |            FROM StatementEntity
    |           WHERE StatementEntity.statementUid = EXCLUDED.sePk ) 
    |                 != StatementEntityReplicate.seVersionId
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO StatementEntityReplicate(sePk, seDestination)
    |  SELECT DISTINCT StatementEntity.statementUid AS seUid,
    |         UserSession.usClientNodeId AS seDestination
    |    FROM ChangeLog
    |         JOIN StatementEntity
    |               ON ChangeLog.chTableId = 60
    |                  AND ChangeLog.chEntityPk = StatementEntity.statementUid
    |         JOIN ScopedGrant
    |              ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |             )
    |        
    |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |         JOIN PersonGroupMember
    |              ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |         JOIN UserSession
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 AND UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StatementEntity.statementLct != COALESCE(
    |         (SELECT seVersionId
    |            FROM StatementEntityReplicate
    |           WHERE sePk = StatementEntity.statementUid
    |             AND seDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sePk, seDestination) DO UPDATE
    |     SET sePending = true
    |  */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO StatementEntityReplicate(sePk, seDestination)
    |  SELECT DISTINCT StatementEntity.statementUid AS seUid,
    |         UserSession.usClientNodeId AS seDestination
    |    FROM ChangeLog
    |         JOIN StatementEntity
    |               ON ChangeLog.chTableId = 60
    |                  AND ChangeLog.chEntityPk = StatementEntity.statementUid
    |         JOIN ScopedGrant
    |              ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |             )
    |        
    |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |         JOIN PersonGroupMember
    |              ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |         JOIN UserSession
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 AND UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StatementEntity.statementLct != COALESCE(
    |         (SELECT seVersionId
    |            FROM StatementEntityReplicate
    |           WHERE sePk = StatementEntity.statementUid
    |             AND seDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sePk, seDestination) DO UPDATE
    |     SET sePending = true
    |  
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getOneStatement(): LiveData<StatementEntity?> = LiveDataImpl(_db,
      listOf("StatementEntity"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * From StatementEntity LIMIT 1"
        )) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_statementUid = _result.getLong("statementUid")
          val _tmp_statementId = _result.getString("statementId")
          val _tmp_statementPersonUid = _result.getLong("statementPersonUid")
          val _tmp_statementVerbUid = _result.getLong("statementVerbUid")
          val _tmp_xObjectUid = _result.getLong("xObjectUid")
          val _tmp_subStatementActorUid = _result.getLong("subStatementActorUid")
          val _tmp_substatementVerbUid = _result.getLong("substatementVerbUid")
          val _tmp_subStatementObjectUid = _result.getLong("subStatementObjectUid")
          val _tmp_agentUid = _result.getLong("agentUid")
          val _tmp_instructorUid = _result.getLong("instructorUid")
          val _tmp_authorityUid = _result.getLong("authorityUid")
          val _tmp_teamUid = _result.getLong("teamUid")
          val _tmp_resultCompletion = _result.getBoolean("resultCompletion")
          val _tmp_resultSuccess = _result.getByte("resultSuccess")
          val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
          val _tmp_resultScoreRaw = _result.getLong("resultScoreRaw")
          val _tmp_resultScoreMin = _result.getLong("resultScoreMin")
          val _tmp_resultScoreMax = _result.getLong("resultScoreMax")
          val _tmp_resultDuration = _result.getLong("resultDuration")
          val _tmp_resultResponse = _result.getString("resultResponse")
          val _tmp_timestamp = _result.getLong("timestamp")
          val _tmp_stored = _result.getLong("stored")
          val _tmp_contextRegistration = _result.getString("contextRegistration")
          val _tmp_contextPlatform = _result.getString("contextPlatform")
          val _tmp_contextStatementId = _result.getString("contextStatementId")
          val _tmp_fullStatement = _result.getString("fullStatement")
          val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
          val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
          val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
          val _tmp_statementLct = _result.getLong("statementLct")
          val _tmp_extensionProgress = _result.getInt("extensionProgress")
          val _tmp_contentEntryRoot = _result.getBoolean("contentEntryRoot")
          val _tmp_statementContentEntryUid = _result.getLong("statementContentEntryUid")
          val _tmp_statementLearnerGroupUid = _result.getLong("statementLearnerGroupUid")
          val _tmp_statementClazzUid = _result.getLong("statementClazzUid")
          StatementEntity().apply {
            this.statementUid = _tmp_statementUid
            this.statementId = _tmp_statementId
            this.statementPersonUid = _tmp_statementPersonUid
            this.statementVerbUid = _tmp_statementVerbUid
            this.xObjectUid = _tmp_xObjectUid
            this.subStatementActorUid = _tmp_subStatementActorUid
            this.substatementVerbUid = _tmp_substatementVerbUid
            this.subStatementObjectUid = _tmp_subStatementObjectUid
            this.agentUid = _tmp_agentUid
            this.instructorUid = _tmp_instructorUid
            this.authorityUid = _tmp_authorityUid
            this.teamUid = _tmp_teamUid
            this.resultCompletion = _tmp_resultCompletion
            this.resultSuccess = _tmp_resultSuccess
            this.resultScoreScaled = _tmp_resultScoreScaled
            this.resultScoreRaw = _tmp_resultScoreRaw
            this.resultScoreMin = _tmp_resultScoreMin
            this.resultScoreMax = _tmp_resultScoreMax
            this.resultDuration = _tmp_resultDuration
            this.resultResponse = _tmp_resultResponse
            this.timestamp = _tmp_timestamp
            this.stored = _tmp_stored
            this.contextRegistration = _tmp_contextRegistration
            this.contextPlatform = _tmp_contextPlatform
            this.contextStatementId = _tmp_contextStatementId
            this.fullStatement = _tmp_fullStatement
            this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
            this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
            this.statementLastChangedBy = _tmp_statementLastChangedBy
            this.statementLct = _tmp_statementLct
            this.extensionProgress = _tmp_extensionProgress
            this.contentEntryRoot = _tmp_contentEntryRoot
            this.statementContentEntryUid = _tmp_statementContentEntryUid
            this.statementLearnerGroupUid = _tmp_statementLearnerGroupUid
            this.statementClazzUid = _tmp_statementClazzUid
          }
        }
      }
    }
  }

  public override fun findByStatementId(id: String): StatementEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM StatementEntity WHERE statementId = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setString(1,id)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_statementUid = _result.getLong("statementUid")
        val _tmp_statementId = _result.getString("statementId")
        val _tmp_statementPersonUid = _result.getLong("statementPersonUid")
        val _tmp_statementVerbUid = _result.getLong("statementVerbUid")
        val _tmp_xObjectUid = _result.getLong("xObjectUid")
        val _tmp_subStatementActorUid = _result.getLong("subStatementActorUid")
        val _tmp_substatementVerbUid = _result.getLong("substatementVerbUid")
        val _tmp_subStatementObjectUid = _result.getLong("subStatementObjectUid")
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_instructorUid = _result.getLong("instructorUid")
        val _tmp_authorityUid = _result.getLong("authorityUid")
        val _tmp_teamUid = _result.getLong("teamUid")
        val _tmp_resultCompletion = _result.getBoolean("resultCompletion")
        val _tmp_resultSuccess = _result.getByte("resultSuccess")
        val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
        val _tmp_resultScoreRaw = _result.getLong("resultScoreRaw")
        val _tmp_resultScoreMin = _result.getLong("resultScoreMin")
        val _tmp_resultScoreMax = _result.getLong("resultScoreMax")
        val _tmp_resultDuration = _result.getLong("resultDuration")
        val _tmp_resultResponse = _result.getString("resultResponse")
        val _tmp_timestamp = _result.getLong("timestamp")
        val _tmp_stored = _result.getLong("stored")
        val _tmp_contextRegistration = _result.getString("contextRegistration")
        val _tmp_contextPlatform = _result.getString("contextPlatform")
        val _tmp_contextStatementId = _result.getString("contextStatementId")
        val _tmp_fullStatement = _result.getString("fullStatement")
        val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
        val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
        val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
        val _tmp_statementLct = _result.getLong("statementLct")
        val _tmp_extensionProgress = _result.getInt("extensionProgress")
        val _tmp_contentEntryRoot = _result.getBoolean("contentEntryRoot")
        val _tmp_statementContentEntryUid = _result.getLong("statementContentEntryUid")
        val _tmp_statementLearnerGroupUid = _result.getLong("statementLearnerGroupUid")
        val _tmp_statementClazzUid = _result.getLong("statementClazzUid")
        StatementEntity().apply {
          this.statementUid = _tmp_statementUid
          this.statementId = _tmp_statementId
          this.statementPersonUid = _tmp_statementPersonUid
          this.statementVerbUid = _tmp_statementVerbUid
          this.xObjectUid = _tmp_xObjectUid
          this.subStatementActorUid = _tmp_subStatementActorUid
          this.substatementVerbUid = _tmp_substatementVerbUid
          this.subStatementObjectUid = _tmp_subStatementObjectUid
          this.agentUid = _tmp_agentUid
          this.instructorUid = _tmp_instructorUid
          this.authorityUid = _tmp_authorityUid
          this.teamUid = _tmp_teamUid
          this.resultCompletion = _tmp_resultCompletion
          this.resultSuccess = _tmp_resultSuccess
          this.resultScoreScaled = _tmp_resultScoreScaled
          this.resultScoreRaw = _tmp_resultScoreRaw
          this.resultScoreMin = _tmp_resultScoreMin
          this.resultScoreMax = _tmp_resultScoreMax
          this.resultDuration = _tmp_resultDuration
          this.resultResponse = _tmp_resultResponse
          this.timestamp = _tmp_timestamp
          this.stored = _tmp_stored
          this.contextRegistration = _tmp_contextRegistration
          this.contextPlatform = _tmp_contextPlatform
          this.contextStatementId = _tmp_contextStatementId
          this.fullStatement = _tmp_fullStatement
          this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
          this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
          this.statementLastChangedBy = _tmp_statementLastChangedBy
          this.statementLct = _tmp_statementLct
          this.extensionProgress = _tmp_extensionProgress
          this.contentEntryRoot = _tmp_contentEntryRoot
          this.statementContentEntryUid = _tmp_statementContentEntryUid
          this.statementLearnerGroupUid = _tmp_statementLearnerGroupUid
          this.statementClazzUid = _tmp_statementClazzUid
        }
      }
    }
  }

  public override fun findByStatementIdList(id: List<String>): List<StatementEntity> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM StatementEntity WHERE statementId IN (?)"
      ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("TEXT", id.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_statementUid = _result.getLong("statementUid")
        val _tmp_statementId = _result.getString("statementId")
        val _tmp_statementPersonUid = _result.getLong("statementPersonUid")
        val _tmp_statementVerbUid = _result.getLong("statementVerbUid")
        val _tmp_xObjectUid = _result.getLong("xObjectUid")
        val _tmp_subStatementActorUid = _result.getLong("subStatementActorUid")
        val _tmp_substatementVerbUid = _result.getLong("substatementVerbUid")
        val _tmp_subStatementObjectUid = _result.getLong("subStatementObjectUid")
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_instructorUid = _result.getLong("instructorUid")
        val _tmp_authorityUid = _result.getLong("authorityUid")
        val _tmp_teamUid = _result.getLong("teamUid")
        val _tmp_resultCompletion = _result.getBoolean("resultCompletion")
        val _tmp_resultSuccess = _result.getByte("resultSuccess")
        val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
        val _tmp_resultScoreRaw = _result.getLong("resultScoreRaw")
        val _tmp_resultScoreMin = _result.getLong("resultScoreMin")
        val _tmp_resultScoreMax = _result.getLong("resultScoreMax")
        val _tmp_resultDuration = _result.getLong("resultDuration")
        val _tmp_resultResponse = _result.getString("resultResponse")
        val _tmp_timestamp = _result.getLong("timestamp")
        val _tmp_stored = _result.getLong("stored")
        val _tmp_contextRegistration = _result.getString("contextRegistration")
        val _tmp_contextPlatform = _result.getString("contextPlatform")
        val _tmp_contextStatementId = _result.getString("contextStatementId")
        val _tmp_fullStatement = _result.getString("fullStatement")
        val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
        val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
        val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
        val _tmp_statementLct = _result.getLong("statementLct")
        val _tmp_extensionProgress = _result.getInt("extensionProgress")
        val _tmp_contentEntryRoot = _result.getBoolean("contentEntryRoot")
        val _tmp_statementContentEntryUid = _result.getLong("statementContentEntryUid")
        val _tmp_statementLearnerGroupUid = _result.getLong("statementLearnerGroupUid")
        val _tmp_statementClazzUid = _result.getLong("statementClazzUid")
        StatementEntity().apply {
          this.statementUid = _tmp_statementUid
          this.statementId = _tmp_statementId
          this.statementPersonUid = _tmp_statementPersonUid
          this.statementVerbUid = _tmp_statementVerbUid
          this.xObjectUid = _tmp_xObjectUid
          this.subStatementActorUid = _tmp_subStatementActorUid
          this.substatementVerbUid = _tmp_substatementVerbUid
          this.subStatementObjectUid = _tmp_subStatementObjectUid
          this.agentUid = _tmp_agentUid
          this.instructorUid = _tmp_instructorUid
          this.authorityUid = _tmp_authorityUid
          this.teamUid = _tmp_teamUid
          this.resultCompletion = _tmp_resultCompletion
          this.resultSuccess = _tmp_resultSuccess
          this.resultScoreScaled = _tmp_resultScoreScaled
          this.resultScoreRaw = _tmp_resultScoreRaw
          this.resultScoreMin = _tmp_resultScoreMin
          this.resultScoreMax = _tmp_resultScoreMax
          this.resultDuration = _tmp_resultDuration
          this.resultResponse = _tmp_resultResponse
          this.timestamp = _tmp_timestamp
          this.stored = _tmp_stored
          this.contextRegistration = _tmp_contextRegistration
          this.contextPlatform = _tmp_contextPlatform
          this.contextStatementId = _tmp_contextStatementId
          this.fullStatement = _tmp_fullStatement
          this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
          this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
          this.statementLastChangedBy = _tmp_statementLastChangedBy
          this.statementLct = _tmp_statementLct
          this.extensionProgress = _tmp_extensionProgress
          this.contentEntryRoot = _tmp_contentEntryRoot
          this.statementContentEntryUid = _tmp_statementContentEntryUid
          this.statementLearnerGroupUid = _tmp_statementLearnerGroupUid
          this.statementClazzUid = _tmp_statementClazzUid
        }
      }
    }
  }

  public override suspend fun getResults(query: DoorQuery): List<StatementReportData> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(query.getSql(), hasListParams =
      query.hasListOrArrayParams())
  ) { _stmt -> 
    query.bindToPreparedStmt(_stmt, _db)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_yAxis = _result.getFloat("yAxis")
        val _tmp_xAxis = _result.getString("xAxis")
        val _tmp_subgroup = _result.getString("subgroup")
        StatementReportData().apply {
          this.yAxis = _tmp_yAxis
          this.xAxis = _tmp_xAxis
          this.subgroup = _tmp_subgroup
        }
      }
    }
  }

  public override fun getListResults(query: DoorQuery):
      DataSourceFactory<Int, StatementEntityWithDisplayDetails> = object :
      DataSourceFactory<Int, StatementEntityWithDisplayDetails>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<StatementEntityWithDisplayDetails>> {
      val query = query.copyWithExtraParams(
      sql = "SELECT * FROM (${query.getSql()}) LIMIT ? OFFSET ?",
      extraParams = arrayOf(_limit, _offset))
      return LiveDataImpl(_db, listOf("StatementEntity", "Person", "XLangMapEntry"))  {
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(query.getSql(), hasListParams =
            query.hasListOrArrayParams())
        ) { _stmt -> 
          query.bindToPreparedStmt(_stmt, _db)
          _stmt.executeQueryAsyncKmp().useResults{ _result -> 
            _result.mapRows {
              val _tmp_statementUid = _result.getLong("statementUid")
              val _tmp_statementId = _result.getString("statementId")
              val _tmp_statementPersonUid = _result.getLong("statementPersonUid")
              val _tmp_statementVerbUid = _result.getLong("statementVerbUid")
              val _tmp_xObjectUid = _result.getLong("xObjectUid")
              val _tmp_subStatementActorUid = _result.getLong("subStatementActorUid")
              val _tmp_substatementVerbUid = _result.getLong("substatementVerbUid")
              val _tmp_subStatementObjectUid = _result.getLong("subStatementObjectUid")
              val _tmp_agentUid = _result.getLong("agentUid")
              val _tmp_instructorUid = _result.getLong("instructorUid")
              val _tmp_authorityUid = _result.getLong("authorityUid")
              val _tmp_teamUid = _result.getLong("teamUid")
              val _tmp_resultCompletion = _result.getBoolean("resultCompletion")
              val _tmp_resultSuccess = _result.getByte("resultSuccess")
              val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
              val _tmp_resultScoreRaw = _result.getLong("resultScoreRaw")
              val _tmp_resultScoreMin = _result.getLong("resultScoreMin")
              val _tmp_resultScoreMax = _result.getLong("resultScoreMax")
              val _tmp_resultDuration = _result.getLong("resultDuration")
              val _tmp_resultResponse = _result.getString("resultResponse")
              val _tmp_timestamp = _result.getLong("timestamp")
              val _tmp_stored = _result.getLong("stored")
              val _tmp_contextRegistration = _result.getString("contextRegistration")
              val _tmp_contextPlatform = _result.getString("contextPlatform")
              val _tmp_contextStatementId = _result.getString("contextStatementId")
              val _tmp_fullStatement = _result.getString("fullStatement")
              val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
              val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
              val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
              val _tmp_statementLct = _result.getLong("statementLct")
              val _tmp_extensionProgress = _result.getInt("extensionProgress")
              val _tmp_contentEntryRoot = _result.getBoolean("contentEntryRoot")
              val _tmp_statementContentEntryUid = _result.getLong("statementContentEntryUid")
              val _tmp_statementLearnerGroupUid = _result.getLong("statementLearnerGroupUid")
              val _tmp_statementClazzUid = _result.getLong("statementClazzUid")
              var _tmp_Person_nullCount = 0
              val _tmp_personUid = _result.getLong("personUid")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_username = _result.getString("username")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_firstNames = _result.getString("firstNames")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_lastName = _result.getString("lastName")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_emailAddr = _result.getString("emailAddr")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_phoneNum = _result.getString("phoneNum")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_gender = _result.getInt("gender")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_active = _result.getBoolean("active")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_admin = _result.getBoolean("admin")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personNotes = _result.getString("personNotes")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_fatherName = _result.getString("fatherName")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_fatherNumber = _result.getString("fatherNumber")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_motherName = _result.getString("motherName")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_motherNum = _result.getString("motherNum")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personAddress = _result.getString("personAddress")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personOrgId = _result.getString("personOrgId")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personGroupUid = _result.getLong("personGroupUid")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personLct = _result.getLong("personLct")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personCountry = _result.getString("personCountry")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_personType = _result.getInt("personType")
              if(_result.wasNull()) _tmp_Person_nullCount++
              val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
              var _tmp_XLangMapEntry_nullCount = 0
              val _tmp_statementLangMapUid = _result.getLong("statementLangMapUid")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_verbLangMapUid = _result.getLong("verbLangMapUid")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_objectLangMapUid = _result.getLong("objectLangMapUid")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_languageLangMapUid = _result.getLong("languageLangMapUid")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_languageVariantLangMapUid = _result.getLong("languageVariantLangMapUid")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_valueLangMap = _result.getString("valueLangMap")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_statementLangMapMasterCsn = _result.getInt("statementLangMapMasterCsn")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_statementLangMapLocalCsn = _result.getInt("statementLangMapLocalCsn")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_statementLangMapLcb = _result.getInt("statementLangMapLcb")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_statementLangMapLct = _result.getLong("statementLangMapLct")
              if(_result.wasNull()) _tmp_XLangMapEntry_nullCount++
              val _tmp_XLangMapEntry_isAllNull = _tmp_XLangMapEntry_nullCount == 10
              StatementEntityWithDisplayDetails().apply {
                this.statementUid = _tmp_statementUid
                this.statementId = _tmp_statementId
                this.statementPersonUid = _tmp_statementPersonUid
                this.statementVerbUid = _tmp_statementVerbUid
                this.xObjectUid = _tmp_xObjectUid
                this.subStatementActorUid = _tmp_subStatementActorUid
                this.substatementVerbUid = _tmp_substatementVerbUid
                this.subStatementObjectUid = _tmp_subStatementObjectUid
                this.agentUid = _tmp_agentUid
                this.instructorUid = _tmp_instructorUid
                this.authorityUid = _tmp_authorityUid
                this.teamUid = _tmp_teamUid
                this.resultCompletion = _tmp_resultCompletion
                this.resultSuccess = _tmp_resultSuccess
                this.resultScoreScaled = _tmp_resultScoreScaled
                this.resultScoreRaw = _tmp_resultScoreRaw
                this.resultScoreMin = _tmp_resultScoreMin
                this.resultScoreMax = _tmp_resultScoreMax
                this.resultDuration = _tmp_resultDuration
                this.resultResponse = _tmp_resultResponse
                this.timestamp = _tmp_timestamp
                this.stored = _tmp_stored
                this.contextRegistration = _tmp_contextRegistration
                this.contextPlatform = _tmp_contextPlatform
                this.contextStatementId = _tmp_contextStatementId
                this.fullStatement = _tmp_fullStatement
                this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
                this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
                this.statementLastChangedBy = _tmp_statementLastChangedBy
                this.statementLct = _tmp_statementLct
                this.extensionProgress = _tmp_extensionProgress
                this.contentEntryRoot = _tmp_contentEntryRoot
                this.statementContentEntryUid = _tmp_statementContentEntryUid
                this.statementLearnerGroupUid = _tmp_statementLearnerGroupUid
                this.statementClazzUid = _tmp_statementClazzUid
                if(!_tmp_Person_isAllNull) {
                  this.person = Person().apply {
                    this.personUid = _tmp_personUid
                    this.username = _tmp_username
                    this.firstNames = _tmp_firstNames
                    this.lastName = _tmp_lastName
                    this.emailAddr = _tmp_emailAddr
                    this.phoneNum = _tmp_phoneNum
                    this.gender = _tmp_gender
                    this.active = _tmp_active
                    this.admin = _tmp_admin
                    this.personNotes = _tmp_personNotes
                    this.fatherName = _tmp_fatherName
                    this.fatherNumber = _tmp_fatherNumber
                    this.motherName = _tmp_motherName
                    this.motherNum = _tmp_motherNum
                    this.dateOfBirth = _tmp_dateOfBirth
                    this.personAddress = _tmp_personAddress
                    this.personOrgId = _tmp_personOrgId
                    this.personGroupUid = _tmp_personGroupUid
                    this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                    this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                    this.personLastChangedBy = _tmp_personLastChangedBy
                    this.personLct = _tmp_personLct
                    this.personCountry = _tmp_personCountry
                    this.personType = _tmp_personType
                  }
                }
                if(!_tmp_XLangMapEntry_isAllNull) {
                  this.xlangMapEntry = XLangMapEntry().apply {
                    this.statementLangMapUid = _tmp_statementLangMapUid
                    this.verbLangMapUid = _tmp_verbLangMapUid
                    this.objectLangMapUid = _tmp_objectLangMapUid
                    this.languageLangMapUid = _tmp_languageLangMapUid
                    this.languageVariantLangMapUid = _tmp_languageVariantLangMapUid
                    this.valueLangMap = _tmp_valueLangMap
                    this.statementLangMapMasterCsn = _tmp_statementLangMapMasterCsn
                    this.statementLangMapLocalCsn = _tmp_statementLangMapLocalCsn
                    this.statementLangMapLcb = _tmp_statementLangMapLcb
                    this.statementLangMapLct = _tmp_statementLangMapLct
                  }
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> {
      val query = query.copy(
      sql = "SELECT COUNT(*) FROM (${query.getSql()})")
      return LiveDataImpl(_db, listOf("StatementEntity", "Person", "XLangMapEntry"))  {
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(query.getSql(), hasListParams =
            query.hasListOrArrayParams())
        ) { _stmt -> 
          query.bindToPreparedStmt(_stmt, _db)
          _stmt.executeQueryAsyncKmp().useResults{ _result -> 
            _result.mapNextRow(0) {
              _result.getInt(1)
            }
          }
        }
      }
    }
  }

  public override fun getPerson(): Person? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM PERSON LIMIT 1" )) {
      _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
        }
      }
    }
  }

  public override fun getXLangMap(): XLangMapEntry? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM XLangMapEntry LIMIT 1" )) {
      _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_statementLangMapUid = _result.getLong("statementLangMapUid")
        val _tmp_verbLangMapUid = _result.getLong("verbLangMapUid")
        val _tmp_objectLangMapUid = _result.getLong("objectLangMapUid")
        val _tmp_languageLangMapUid = _result.getLong("languageLangMapUid")
        val _tmp_languageVariantLangMapUid = _result.getLong("languageVariantLangMapUid")
        val _tmp_valueLangMap = _result.getString("valueLangMap")
        val _tmp_statementLangMapMasterCsn = _result.getInt("statementLangMapMasterCsn")
        val _tmp_statementLangMapLocalCsn = _result.getInt("statementLangMapLocalCsn")
        val _tmp_statementLangMapLcb = _result.getInt("statementLangMapLcb")
        val _tmp_statementLangMapLct = _result.getLong("statementLangMapLct")
        XLangMapEntry().apply {
          this.statementLangMapUid = _tmp_statementLangMapUid
          this.verbLangMapUid = _tmp_verbLangMapUid
          this.objectLangMapUid = _tmp_objectLangMapUid
          this.languageLangMapUid = _tmp_languageLangMapUid
          this.languageVariantLangMapUid = _tmp_languageVariantLangMapUid
          this.valueLangMap = _tmp_valueLangMap
          this.statementLangMapMasterCsn = _tmp_statementLangMapMasterCsn
          this.statementLangMapLocalCsn = _tmp_statementLangMapLocalCsn
          this.statementLangMapLcb = _tmp_statementLangMapLcb
          this.statementLangMapLct = _tmp_statementLangMapLct
        }
      }
    }
  }

  public override fun updateProgress(
    uid: Long,
    progress: Int,
    updateTime: Long,
  ): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("""
    |
    |        UPDATE StatementEntity 
    |           SET extensionProgress = ?,
    |               statementLct = ? 
    |            WHERE statementUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,progress)
      _stmt.setLong(2,updateTime)
      _stmt.setLong(3,uid)
      _stmt.executeUpdate()
    }
  }

  public override fun findPersonsWithContentEntryAttempts(
    contentEntryUid: Long,
    accountPersonUid: Long,
    searchText: String,
    sortOrder: Int,
  ): DataSourceFactory<Int, PersonWithAttemptsSummary> = object :
      DataSourceFactory<Int, PersonWithAttemptsSummary>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<PersonWithAttemptsSummary>> = LiveDataImpl(_db, listOf("PersonGroupMember",
        "ScopedGrant", "Person", "StatementEntity"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT ResultSource.personUid, ResultSource.firstNames, ResultSource.lastName,
      |            COUNT(DISTINCT(ResultSource.contextRegistration)) AS attempts, 
      |            MIN(ResultSource.timestamp) AS startDate, 
      |            MAX(ResultSource.timestamp) AS endDate, 
      |            SUM(ResultSource.resultDuration) AS duration, 
      |            MAX(CASE WHEN ResultSource.contentEntryRoot 
      |                THEN resultScoreRaw
      |                ELSE 0 END) AS resultScore, 
      |            MAX(CASE WHEN ResultSource.contentEntryRoot 
      |                THEN resultScoreMax
      |                ELSE 0 END) AS resultMax,   
      |            MAX(CASE WHEN ResultSource.contentEntryRoot 
      |                THEN resultScoreScaled
      |                ELSE 0 END) AS resultScaled, 
      |            MAX(ResultSource.extensionProgress) AS progress,
      |            0 AS penalty,
      |            0 as resultWeight,
      |            'FALSE' AS contentComplete,
      |            0 AS success,
      |            
      |            CASE WHEN ResultSource.resultCompletion 
      |                THEN 1 ELSE 0 END AS totalCompletedContent,
      |                
      |            1 as totalContent, 
      |            
      |            0 as fileSubmissionStatus, 
      |         
      |            '' AS latestPrivateComment
      |        
      |         FROM (SELECT Person.personUid, Person.firstNames, Person.lastName, 
      |            StatementEntity.contextRegistration, StatementEntity.timestamp, 
      |            StatementEntity.resultDuration, StatementEntity.resultScoreRaw, 
      |            StatementEntity.resultScoreMax, StatementEntity.resultScoreScaled,
      |            StatementEntity.contentEntryRoot, StatementEntity.extensionProgress, 
      |            StatementEntity.resultCompletion
      |            FROM PersonGroupMember
      |            
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions & 549755813888 
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |             LEFT JOIN StatementEntity 
      |                ON StatementEntity.statementPersonUid = Person.personUid 
      |                    WHERE PersonGroupMember.groupMemberPersonUid = ? 
      |                        AND PersonGroupMember.groupMemberActive  
      |                        AND statementContentEntryUid = ?
      |                        AND Person.firstNames || ' ' || Person.lastName LIKE ?              
      |                   GROUP BY StatementEntity.statementUid 
      |                   ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC) AS ResultSource 
      |         GROUP BY ResultSource.personUid 
      |         ORDER BY CASE(?) 
      |                WHEN 1 THEN ResultSource.firstNames
      |                WHEN 3 THEN ResultSource.lastName
      |                ELSE ''
      |            END ASC,
      |            CASE(?)
      |                WHEN 2 THEN ResultSource.firstNames
      |                WHEN 4 THEN ResultSource.lastName
      |                ELSE ''
      |            END DESC,
      |            CASE(?)
      |                WHEN 5 THEN endDate 
      |                ELSE 0
      |            END ASC,
      |            CASE(?)
      |                WHEN 6 then endDate
      |                ELSE 0
      |            END DESC
      |         ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.setString(3,searchText)
        _stmt.setInt(4,sortOrder)
        _stmt.setInt(5,sortOrder)
        _stmt.setInt(6,sortOrder)
        _stmt.setInt(7,sortOrder)
        _stmt.setInt(8,_limit)
        _stmt.setInt(9,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_personUid = _result.getLong("personUid")
            val _tmp_firstNames = _result.getString("firstNames")
            val _tmp_lastName = _result.getString("lastName")
            val _tmp_attempts = _result.getInt("attempts")
            val _tmp_startDate = _result.getLong("startDate")
            val _tmp_endDate = _result.getLong("endDate")
            val _tmp_duration = _result.getLong("duration")
            val _tmp_latestPrivateComment = _result.getString("latestPrivateComment")
            val _tmp_fileSubmissionStatus = _result.getInt("fileSubmissionStatus")
            var _tmp_ContentEntryStatementScoreProgress_nullCount = 0
            val _tmp_resultScore = _result.getInt("resultScore")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultMax = _result.getInt("resultMax")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultScaled = _result.getFloat("resultScaled")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultWeight = _result.getInt("resultWeight")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_contentComplete = _result.getBoolean("contentComplete")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_progress = _result.getInt("progress")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_success = _result.getByte("success")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_penalty = _result.getInt("penalty")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalContent = _result.getInt("totalContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_ContentEntryStatementScoreProgress_isAllNull = _tmp_ContentEntryStatementScoreProgress_nullCount == 10
            PersonWithAttemptsSummary().apply {
              this.personUid = _tmp_personUid
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.attempts = _tmp_attempts
              this.startDate = _tmp_startDate
              this.endDate = _tmp_endDate
              this.duration = _tmp_duration
              this.latestPrivateComment = _tmp_latestPrivateComment
              this.fileSubmissionStatus = _tmp_fileSubmissionStatus
              if(!_tmp_ContentEntryStatementScoreProgress_isAllNull) {
                this.scoreProgress = ContentEntryStatementScoreProgress().apply {
                  this.resultScore = _tmp_resultScore
                  this.resultMax = _tmp_resultMax
                  this.resultScaled = _tmp_resultScaled
                  this.resultWeight = _tmp_resultWeight
                  this.contentComplete = _tmp_contentComplete
                  this.progress = _tmp_progress
                  this.success = _tmp_success
                  this.penalty = _tmp_penalty
                  this.totalContent = _tmp_totalContent
                  this.totalCompletedContent = _tmp_totalCompletedContent
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("PersonGroupMember",
        "ScopedGrant", "Person", "StatementEntity"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT ResultSource.personUid, ResultSource.firstNames, ResultSource.lastName,
      |            COUNT(DISTINCT(ResultSource.contextRegistration)) AS attempts, 
      |            MIN(ResultSource.timestamp) AS startDate, 
      |            MAX(ResultSource.timestamp) AS endDate, 
      |            SUM(ResultSource.resultDuration) AS duration, 
      |            MAX(CASE WHEN ResultSource.contentEntryRoot 
      |                THEN resultScoreRaw
      |                ELSE 0 END) AS resultScore, 
      |            MAX(CASE WHEN ResultSource.contentEntryRoot 
      |                THEN resultScoreMax
      |                ELSE 0 END) AS resultMax,   
      |            MAX(CASE WHEN ResultSource.contentEntryRoot 
      |                THEN resultScoreScaled
      |                ELSE 0 END) AS resultScaled, 
      |            MAX(ResultSource.extensionProgress) AS progress,
      |            0 AS penalty,
      |            0 as resultWeight,
      |            'FALSE' AS contentComplete,
      |            0 AS success,
      |            
      |            CASE WHEN ResultSource.resultCompletion 
      |                THEN 1 ELSE 0 END AS totalCompletedContent,
      |                
      |            1 as totalContent, 
      |            
      |            0 as fileSubmissionStatus, 
      |         
      |            '' AS latestPrivateComment
      |        
      |         FROM (SELECT Person.personUid, Person.firstNames, Person.lastName, 
      |            StatementEntity.contextRegistration, StatementEntity.timestamp, 
      |            StatementEntity.resultDuration, StatementEntity.resultScoreRaw, 
      |            StatementEntity.resultScoreMax, StatementEntity.resultScoreScaled,
      |            StatementEntity.contentEntryRoot, StatementEntity.extensionProgress, 
      |            StatementEntity.resultCompletion
      |            FROM PersonGroupMember
      |            
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions & 549755813888 
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |             LEFT JOIN StatementEntity 
      |                ON StatementEntity.statementPersonUid = Person.personUid 
      |                    WHERE PersonGroupMember.groupMemberPersonUid = ? 
      |                        AND PersonGroupMember.groupMemberActive  
      |                        AND statementContentEntryUid = ?
      |                        AND Person.firstNames || ' ' || Person.lastName LIKE ?              
      |                   GROUP BY StatementEntity.statementUid 
      |                   ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC) AS ResultSource 
      |         GROUP BY ResultSource.personUid 
      |         ORDER BY CASE(?) 
      |                WHEN 1 THEN ResultSource.firstNames
      |                WHEN 3 THEN ResultSource.lastName
      |                ELSE ''
      |            END ASC,
      |            CASE(?)
      |                WHEN 2 THEN ResultSource.firstNames
      |                WHEN 4 THEN ResultSource.lastName
      |                ELSE ''
      |            END DESC,
      |            CASE(?)
      |                WHEN 5 THEN endDate 
      |                ELSE 0
      |            END ASC,
      |            CASE(?)
      |                WHEN 6 then endDate
      |                ELSE 0
      |            END DESC
      |         ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.setString(3,searchText)
        _stmt.setInt(4,sortOrder)
        _stmt.setInt(5,sortOrder)
        _stmt.setInt(6,sortOrder)
        _stmt.setInt(7,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun getBestScoreForContentForPerson(contentEntryUid: Long,
      accountPersonUid: Long): ContentEntryStatementScoreProgress? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT 
  |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
  |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
  |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
  |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
  |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
  |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
  |                0 as resultWeight,
  |                
  |                COALESCE((CASE WHEN resultCompletion 
  |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
  |                
  |                1 as totalContent, 
  |                0 as penalty
  |                
  |        FROM ContentEntry
  |            LEFT JOIN StatementEntity
  |							ON StatementEntity.statementUid = 
  |                                (SELECT statementUid 
  |							       FROM StatementEntity 
  |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
  |							        AND StatementEntity.statementPersonUid = ?
  |							        AND contentEntryRoot 
  |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
  |                               
  |       WHERE contentEntryUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,accountPersonUid)
    _stmt.setLong(2,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_resultScore = _result.getInt("resultScore")
        val _tmp_resultMax = _result.getInt("resultMax")
        val _tmp_resultScaled = _result.getFloat("resultScaled")
        val _tmp_resultWeight = _result.getInt("resultWeight")
        val _tmp_contentComplete = _result.getBoolean("contentComplete")
        val _tmp_progress = _result.getInt("progress")
        val _tmp_success = _result.getByte("success")
        val _tmp_penalty = _result.getInt("penalty")
        val _tmp_totalContent = _result.getInt("totalContent")
        val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
        ContentEntryStatementScoreProgress().apply {
          this.resultScore = _tmp_resultScore
          this.resultMax = _tmp_resultMax
          this.resultScaled = _tmp_resultScaled
          this.resultWeight = _tmp_resultWeight
          this.contentComplete = _tmp_contentComplete
          this.progress = _tmp_progress
          this.success = _tmp_success
          this.penalty = _tmp_penalty
          this.totalContent = _tmp_totalContent
          this.totalCompletedContent = _tmp_totalCompletedContent
        }
      }
    }
  }

  public override suspend fun findNextStudentNotMarkedForAssignment(assignmentUid: Long,
      currentStudentUid: Long): Long = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |         SELECT COALESCE((
  |                SELECT DISTINCT(statementpersonUid)
  |                  FROM ClazzAssignment 
  |                      JOIN ClazzEnrolment
  |                       ON ClazzEnrolment.clazzEnrolmentClazzUid = ClazzAssignment.caClazzUid
  |                       
  |                       JOIN CourseBlock
  |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
  |                       AND CourseBlock.cbType = 103
  |                       
  |          	           JOIN StatementEntity AS SubmissionStatement
  |          	           ON SubmissionStatement.statementUid = (SELECT statementUid 
  |                                   FROM StatementEntity
  |                                  WHERE StatementEntity.statementContentEntryUid = 0
  |                                    AND xObjectUid = ClazzAssignment.caXObjectUid
  |                                    AND StatementEntity.statementPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
  |                                    AND StatementEntity.timestamp 
  |                                        BETWEEN CourseBlock.cbHideUntilDate
  |                                        AND CourseBlock.cbGracePeriodDate
  |                               ORDER BY timestamp DESC LIMIT 1)
  |                               
  |          	           LEFT JOIN XObjectEntity
  |                       ON XObjectEntity.objectStatementRefUid = SubmissionStatement.statementUid  
  |               
  |                 WHERE ClazzAssignment.caUid = ?
  |                   AND XObjectEntity.xobjectUid IS NULL
  |                   AND ClazzEnrolment.clazzEnrolmentActive
  |                   AND ClazzEnrolment.clazzEnrolmentRole = 1000
  |                   AND ClazzEnrolment.clazzEnrolmentPersonUid != ?
  |            LIMIT 1),0)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,assignmentUid)
    _stmt.setLong(2,currentStudentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun findSubmittedStatementFromStudent(studentUid: Long,
      assignmentObjectUid: Long): StatementEntity? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM StatementEntity
  |         WHERE statementPersonUid = ?
  |           AND statementVerbUid = 10008
  |           AND xObjectUid = ?    
  |      ORDER BY timestamp                
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,studentUid)
    _stmt.setLong(2,assignmentObjectUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_statementUid = _result.getLong("statementUid")
        val _tmp_statementId = _result.getString("statementId")
        val _tmp_statementPersonUid = _result.getLong("statementPersonUid")
        val _tmp_statementVerbUid = _result.getLong("statementVerbUid")
        val _tmp_xObjectUid = _result.getLong("xObjectUid")
        val _tmp_subStatementActorUid = _result.getLong("subStatementActorUid")
        val _tmp_substatementVerbUid = _result.getLong("substatementVerbUid")
        val _tmp_subStatementObjectUid = _result.getLong("subStatementObjectUid")
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_instructorUid = _result.getLong("instructorUid")
        val _tmp_authorityUid = _result.getLong("authorityUid")
        val _tmp_teamUid = _result.getLong("teamUid")
        val _tmp_resultCompletion = _result.getBoolean("resultCompletion")
        val _tmp_resultSuccess = _result.getByte("resultSuccess")
        val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
        val _tmp_resultScoreRaw = _result.getLong("resultScoreRaw")
        val _tmp_resultScoreMin = _result.getLong("resultScoreMin")
        val _tmp_resultScoreMax = _result.getLong("resultScoreMax")
        val _tmp_resultDuration = _result.getLong("resultDuration")
        val _tmp_resultResponse = _result.getString("resultResponse")
        val _tmp_timestamp = _result.getLong("timestamp")
        val _tmp_stored = _result.getLong("stored")
        val _tmp_contextRegistration = _result.getString("contextRegistration")
        val _tmp_contextPlatform = _result.getString("contextPlatform")
        val _tmp_contextStatementId = _result.getString("contextStatementId")
        val _tmp_fullStatement = _result.getString("fullStatement")
        val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
        val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
        val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
        val _tmp_statementLct = _result.getLong("statementLct")
        val _tmp_extensionProgress = _result.getInt("extensionProgress")
        val _tmp_contentEntryRoot = _result.getBoolean("contentEntryRoot")
        val _tmp_statementContentEntryUid = _result.getLong("statementContentEntryUid")
        val _tmp_statementLearnerGroupUid = _result.getLong("statementLearnerGroupUid")
        val _tmp_statementClazzUid = _result.getLong("statementClazzUid")
        StatementEntity().apply {
          this.statementUid = _tmp_statementUid
          this.statementId = _tmp_statementId
          this.statementPersonUid = _tmp_statementPersonUid
          this.statementVerbUid = _tmp_statementVerbUid
          this.xObjectUid = _tmp_xObjectUid
          this.subStatementActorUid = _tmp_subStatementActorUid
          this.substatementVerbUid = _tmp_substatementVerbUid
          this.subStatementObjectUid = _tmp_subStatementObjectUid
          this.agentUid = _tmp_agentUid
          this.instructorUid = _tmp_instructorUid
          this.authorityUid = _tmp_authorityUid
          this.teamUid = _tmp_teamUid
          this.resultCompletion = _tmp_resultCompletion
          this.resultSuccess = _tmp_resultSuccess
          this.resultScoreScaled = _tmp_resultScoreScaled
          this.resultScoreRaw = _tmp_resultScoreRaw
          this.resultScoreMin = _tmp_resultScoreMin
          this.resultScoreMax = _tmp_resultScoreMax
          this.resultDuration = _tmp_resultDuration
          this.resultResponse = _tmp_resultResponse
          this.timestamp = _tmp_timestamp
          this.stored = _tmp_stored
          this.contextRegistration = _tmp_contextRegistration
          this.contextPlatform = _tmp_contextPlatform
          this.contextStatementId = _tmp_contextStatementId
          this.fullStatement = _tmp_fullStatement
          this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
          this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
          this.statementLastChangedBy = _tmp_statementLastChangedBy
          this.statementLct = _tmp_statementLct
          this.extensionProgress = _tmp_extensionProgress
          this.contentEntryRoot = _tmp_contentEntryRoot
          this.statementContentEntryUid = _tmp_statementContentEntryUid
          this.statementLearnerGroupUid = _tmp_statementLearnerGroupUid
          this.statementClazzUid = _tmp_statementClazzUid
        }
      }
    }
  }

  public override fun findScoreStatementForStudent(studentUid: Long): StatementEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM StatementEntity
  |         WHERE statementPersonUid = ?
  |           AND statementVerbUid = 10009
  |      ORDER BY timestamp                
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,studentUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_statementUid = _result.getLong("statementUid")
        val _tmp_statementId = _result.getString("statementId")
        val _tmp_statementPersonUid = _result.getLong("statementPersonUid")
        val _tmp_statementVerbUid = _result.getLong("statementVerbUid")
        val _tmp_xObjectUid = _result.getLong("xObjectUid")
        val _tmp_subStatementActorUid = _result.getLong("subStatementActorUid")
        val _tmp_substatementVerbUid = _result.getLong("substatementVerbUid")
        val _tmp_subStatementObjectUid = _result.getLong("subStatementObjectUid")
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_instructorUid = _result.getLong("instructorUid")
        val _tmp_authorityUid = _result.getLong("authorityUid")
        val _tmp_teamUid = _result.getLong("teamUid")
        val _tmp_resultCompletion = _result.getBoolean("resultCompletion")
        val _tmp_resultSuccess = _result.getByte("resultSuccess")
        val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
        val _tmp_resultScoreRaw = _result.getLong("resultScoreRaw")
        val _tmp_resultScoreMin = _result.getLong("resultScoreMin")
        val _tmp_resultScoreMax = _result.getLong("resultScoreMax")
        val _tmp_resultDuration = _result.getLong("resultDuration")
        val _tmp_resultResponse = _result.getString("resultResponse")
        val _tmp_timestamp = _result.getLong("timestamp")
        val _tmp_stored = _result.getLong("stored")
        val _tmp_contextRegistration = _result.getString("contextRegistration")
        val _tmp_contextPlatform = _result.getString("contextPlatform")
        val _tmp_contextStatementId = _result.getString("contextStatementId")
        val _tmp_fullStatement = _result.getString("fullStatement")
        val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
        val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
        val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
        val _tmp_statementLct = _result.getLong("statementLct")
        val _tmp_extensionProgress = _result.getInt("extensionProgress")
        val _tmp_contentEntryRoot = _result.getBoolean("contentEntryRoot")
        val _tmp_statementContentEntryUid = _result.getLong("statementContentEntryUid")
        val _tmp_statementLearnerGroupUid = _result.getLong("statementLearnerGroupUid")
        val _tmp_statementClazzUid = _result.getLong("statementClazzUid")
        StatementEntity().apply {
          this.statementUid = _tmp_statementUid
          this.statementId = _tmp_statementId
          this.statementPersonUid = _tmp_statementPersonUid
          this.statementVerbUid = _tmp_statementVerbUid
          this.xObjectUid = _tmp_xObjectUid
          this.subStatementActorUid = _tmp_subStatementActorUid
          this.substatementVerbUid = _tmp_substatementVerbUid
          this.subStatementObjectUid = _tmp_subStatementObjectUid
          this.agentUid = _tmp_agentUid
          this.instructorUid = _tmp_instructorUid
          this.authorityUid = _tmp_authorityUid
          this.teamUid = _tmp_teamUid
          this.resultCompletion = _tmp_resultCompletion
          this.resultSuccess = _tmp_resultSuccess
          this.resultScoreScaled = _tmp_resultScoreScaled
          this.resultScoreRaw = _tmp_resultScoreRaw
          this.resultScoreMin = _tmp_resultScoreMin
          this.resultScoreMax = _tmp_resultScoreMax
          this.resultDuration = _tmp_resultDuration
          this.resultResponse = _tmp_resultResponse
          this.timestamp = _tmp_timestamp
          this.stored = _tmp_stored
          this.contextRegistration = _tmp_contextRegistration
          this.contextPlatform = _tmp_contextPlatform
          this.contextStatementId = _tmp_contextStatementId
          this.fullStatement = _tmp_fullStatement
          this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
          this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
          this.statementLastChangedBy = _tmp_statementLastChangedBy
          this.statementLct = _tmp_statementLct
          this.extensionProgress = _tmp_extensionProgress
          this.contentEntryRoot = _tmp_contentEntryRoot
          this.statementContentEntryUid = _tmp_statementContentEntryUid
          this.statementLearnerGroupUid = _tmp_statementLearnerGroupUid
          this.statementClazzUid = _tmp_statementClazzUid
        }
      }
    }
  }

  public override fun findSessionsForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, PersonWithSessionsDisplay> = object :
      DataSourceFactory<Int, PersonWithSessionsDisplay>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<PersonWithSessionsDisplay>> = LiveDataImpl(_db, listOf("StatementEntity",
        "ScopedGrant", "PersonGroupMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT MIN(timestamp) AS startDate, 
      |            MAX(CASE 
      |                    WHEN StatementEntity.resultSuccess > 0 
      |                    AND StatementEntity.contentEntryRoot 
      |                    THEN StatementEntity.resultSuccess 
      |                    ELSE 0 END) AS resultSuccess, 
      |            SUM(CASE 
      |                     WHEN CAST(resultCompletion AS INTEGER) > 0 
      |                     AND StatementEntity.contentEntryRoot 
      |                     THEN 1 
      |                     ELSE 0 END) AS resultComplete, 
      |            SUM(resultDuration) AS duration, contextRegistration, 
      |            MAX(CASE WHEN contentEntryRoot 
      |                     THEN resultScoreRaw ELSE 0 END) AS resultScore, 
      |            MAX(CASE WHEN contentEntryRoot 
      |                     THEN resultScoreMax ELSE 0 END) AS resultMax,
      |            MAX(CASE WHEN contentEntryRoot 
      |                     THEN resultScoreScaled ELSE 0 END) AS resultScoreScaled,
      |                       
      |            SUM(CASE WHEN resultCompletion AND StatementEntity.contentEntryRoot 
      |                THEN 1 ELSE 0 END) AS totalCompletedContent,
      |                
      |             1 as totalContent          
      |                       
      |        FROM StatementEntity 
      |             JOIN ScopedGrant 
      |                 ON 
      |            ((ScopedGrant.sgTableId = -2
      |                AND ScopedGrant.sgEntityUid = -2)
      |             OR (ScopedGrant.sgTableId = 9
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
      |             OR (ScopedGrant.sgTableId = 6
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
      |             OR (ScopedGrant.sgTableId = 164
      |                AND ScopedGrant.sgEntityUid = (
      |                    SELECT clazzSchoolUid
      |                      FROM Clazz
      |                     WHERE clazzUid = StatementEntity.statementClazzUid))
      |             )
      |        
      |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
      |             JOIN PersonGroupMember 
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
      |                AND PersonGroupMember.groupMemberPersonUid = ?
      |        WHERE statementContentEntryUid = ?   
      |          AND statementPersonUid = ? 
      |        GROUP BY StatementEntity.contextRegistration 
      |        ORDER BY startDate DESC, resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC
      |         ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.setLong(3,personUid)
        _stmt.setInt(4,_limit)
        _stmt.setInt(5,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_startDate = _result.getLong("startDate")
            val _tmp_contextRegistration = _result.getString("contextRegistration")
            val _tmp_duration = _result.getLong("duration")
            val _tmp_resultSuccess = _result.getByte("resultSuccess")
            val _tmp_resultComplete = _result.getBoolean("resultComplete")
            val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
            val _tmp_resultMax = _result.getInt("resultMax")
            val _tmp_resultScore = _result.getInt("resultScore")
            PersonWithSessionsDisplay().apply {
              this.startDate = _tmp_startDate
              this.contextRegistration = _tmp_contextRegistration
              this.duration = _tmp_duration
              this.resultSuccess = _tmp_resultSuccess
              this.resultComplete = _tmp_resultComplete
              this.resultScoreScaled = _tmp_resultScoreScaled
              this.resultMax = _tmp_resultMax
              this.resultScore = _tmp_resultScore
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("StatementEntity",
        "ScopedGrant", "PersonGroupMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT MIN(timestamp) AS startDate, 
      |            MAX(CASE 
      |                    WHEN StatementEntity.resultSuccess > 0 
      |                    AND StatementEntity.contentEntryRoot 
      |                    THEN StatementEntity.resultSuccess 
      |                    ELSE 0 END) AS resultSuccess, 
      |            SUM(CASE 
      |                     WHEN CAST(resultCompletion AS INTEGER) > 0 
      |                     AND StatementEntity.contentEntryRoot 
      |                     THEN 1 
      |                     ELSE 0 END) AS resultComplete, 
      |            SUM(resultDuration) AS duration, contextRegistration, 
      |            MAX(CASE WHEN contentEntryRoot 
      |                     THEN resultScoreRaw ELSE 0 END) AS resultScore, 
      |            MAX(CASE WHEN contentEntryRoot 
      |                     THEN resultScoreMax ELSE 0 END) AS resultMax,
      |            MAX(CASE WHEN contentEntryRoot 
      |                     THEN resultScoreScaled ELSE 0 END) AS resultScoreScaled,
      |                       
      |            SUM(CASE WHEN resultCompletion AND StatementEntity.contentEntryRoot 
      |                THEN 1 ELSE 0 END) AS totalCompletedContent,
      |                
      |             1 as totalContent          
      |                       
      |        FROM StatementEntity 
      |             JOIN ScopedGrant 
      |                 ON 
      |            ((ScopedGrant.sgTableId = -2
      |                AND ScopedGrant.sgEntityUid = -2)
      |             OR (ScopedGrant.sgTableId = 9
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
      |             OR (ScopedGrant.sgTableId = 6
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
      |             OR (ScopedGrant.sgTableId = 164
      |                AND ScopedGrant.sgEntityUid = (
      |                    SELECT clazzSchoolUid
      |                      FROM Clazz
      |                     WHERE clazzUid = StatementEntity.statementClazzUid))
      |             )
      |        
      |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
      |             JOIN PersonGroupMember 
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
      |                AND PersonGroupMember.groupMemberPersonUid = ?
      |        WHERE statementContentEntryUid = ?   
      |          AND statementPersonUid = ? 
      |        GROUP BY StatementEntity.contextRegistration 
      |        ORDER BY startDate DESC, resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC
      |         ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.setLong(3,personUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findSessionDetailForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
    contextRegistration: String,
  ): DataSourceFactory<Int, StatementWithSessionDetailDisplay> = object :
      DataSourceFactory<Int, StatementWithSessionDetailDisplay>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<StatementWithSessionDetailDisplay>> = LiveDataImpl(_db,
        listOf("StatementEntity", "ScopedGrant", "PersonGroupMember", "VerbEntity",
        "XLangMapEntry"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT StatementEntity.*, VerbEntity.*, 
      |            verbLangMap.valueLangMap AS verbDisplay, 
      |            xobjectMap.valueLangMap AS objectDisplay 
      |        FROM StatementEntity
      |                 JOIN ScopedGrant 
      |                    ON 
      |            ((ScopedGrant.sgTableId = -2
      |                AND ScopedGrant.sgEntityUid = -2)
      |             OR (ScopedGrant.sgTableId = 9
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
      |             OR (ScopedGrant.sgTableId = 6
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
      |             OR (ScopedGrant.sgTableId = 164
      |                AND ScopedGrant.sgEntityUid = (
      |                    SELECT clazzSchoolUid
      |                      FROM Clazz
      |                     WHERE clazzUid = StatementEntity.statementClazzUid))
      |             )
      |        
      |                    AND (ScopedGrant.sgPermissions & 549755813888) > 0
      |                 JOIN PersonGroupMember 
      |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
      |                AND PersonGroupMember.groupMemberPersonUid = ?
      |                LEFT JOIN VerbEntity 
      |                    ON VerbEntity.verbUid = StatementEntity.statementVerbUid 
      |                LEFT JOIN XLangMapEntry verbLangMap 
      |                    ON verbLangMap.verbLangMapUid = VerbEntity.verbUid
      |                LEFT JOIN XLangMapEntry xobjectMap 
      |                    ON xobjectMap.objectLangMapUid = StatementEntity.xObjectUid
      |         WHERE statementContentEntryUid = ? 
      |            AND statementPersonUid = ? 
      |            AND contextRegistration = ? 
      |         ORDER BY StatementEntity.timestamp DESC
      |         ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.setLong(3,personUid)
        _stmt.setString(4,contextRegistration)
        _stmt.setInt(5,_limit)
        _stmt.setInt(6,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_verbDisplay = _result.getString("verbDisplay")
            val _tmp_objectDisplay = _result.getString("objectDisplay")
            val _tmp_statementUid = _result.getLong("statementUid")
            val _tmp_statementId = _result.getString("statementId")
            val _tmp_statementPersonUid = _result.getLong("statementPersonUid")
            val _tmp_statementVerbUid = _result.getLong("statementVerbUid")
            val _tmp_xObjectUid = _result.getLong("xObjectUid")
            val _tmp_subStatementActorUid = _result.getLong("subStatementActorUid")
            val _tmp_substatementVerbUid = _result.getLong("substatementVerbUid")
            val _tmp_subStatementObjectUid = _result.getLong("subStatementObjectUid")
            val _tmp_agentUid = _result.getLong("agentUid")
            val _tmp_instructorUid = _result.getLong("instructorUid")
            val _tmp_authorityUid = _result.getLong("authorityUid")
            val _tmp_teamUid = _result.getLong("teamUid")
            val _tmp_resultCompletion = _result.getBoolean("resultCompletion")
            val _tmp_resultSuccess = _result.getByte("resultSuccess")
            val _tmp_resultScoreScaled = _result.getFloat("resultScoreScaled")
            val _tmp_resultScoreRaw = _result.getLong("resultScoreRaw")
            val _tmp_resultScoreMin = _result.getLong("resultScoreMin")
            val _tmp_resultScoreMax = _result.getLong("resultScoreMax")
            val _tmp_resultDuration = _result.getLong("resultDuration")
            val _tmp_resultResponse = _result.getString("resultResponse")
            val _tmp_timestamp = _result.getLong("timestamp")
            val _tmp_stored = _result.getLong("stored")
            val _tmp_contextRegistration = _result.getString("contextRegistration")
            val _tmp_contextPlatform = _result.getString("contextPlatform")
            val _tmp_contextStatementId = _result.getString("contextStatementId")
            val _tmp_fullStatement = _result.getString("fullStatement")
            val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
            val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
            val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
            val _tmp_statementLct = _result.getLong("statementLct")
            val _tmp_extensionProgress = _result.getInt("extensionProgress")
            val _tmp_contentEntryRoot = _result.getBoolean("contentEntryRoot")
            val _tmp_statementContentEntryUid = _result.getLong("statementContentEntryUid")
            val _tmp_statementLearnerGroupUid = _result.getLong("statementLearnerGroupUid")
            val _tmp_statementClazzUid = _result.getLong("statementClazzUid")
            var _tmp_VerbEntity_nullCount = 0
            val _tmp_verbUid = _result.getLong("verbUid")
            if(_result.wasNull()) _tmp_VerbEntity_nullCount++
            val _tmp_urlId = _result.getString("urlId")
            if(_result.wasNull()) _tmp_VerbEntity_nullCount++
            val _tmp_verbInActive = _result.getBoolean("verbInActive")
            if(_result.wasNull()) _tmp_VerbEntity_nullCount++
            val _tmp_verbMasterChangeSeqNum = _result.getLong("verbMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_VerbEntity_nullCount++
            val _tmp_verbLocalChangeSeqNum = _result.getLong("verbLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_VerbEntity_nullCount++
            val _tmp_verbLastChangedBy = _result.getInt("verbLastChangedBy")
            if(_result.wasNull()) _tmp_VerbEntity_nullCount++
            val _tmp_verbLct = _result.getLong("verbLct")
            if(_result.wasNull()) _tmp_VerbEntity_nullCount++
            val _tmp_VerbEntity_isAllNull = _tmp_VerbEntity_nullCount == 7
            StatementWithSessionDetailDisplay().apply {
              this.verbDisplay = _tmp_verbDisplay
              this.objectDisplay = _tmp_objectDisplay
              this.statementUid = _tmp_statementUid
              this.statementId = _tmp_statementId
              this.statementPersonUid = _tmp_statementPersonUid
              this.statementVerbUid = _tmp_statementVerbUid
              this.xObjectUid = _tmp_xObjectUid
              this.subStatementActorUid = _tmp_subStatementActorUid
              this.substatementVerbUid = _tmp_substatementVerbUid
              this.subStatementObjectUid = _tmp_subStatementObjectUid
              this.agentUid = _tmp_agentUid
              this.instructorUid = _tmp_instructorUid
              this.authorityUid = _tmp_authorityUid
              this.teamUid = _tmp_teamUid
              this.resultCompletion = _tmp_resultCompletion
              this.resultSuccess = _tmp_resultSuccess
              this.resultScoreScaled = _tmp_resultScoreScaled
              this.resultScoreRaw = _tmp_resultScoreRaw
              this.resultScoreMin = _tmp_resultScoreMin
              this.resultScoreMax = _tmp_resultScoreMax
              this.resultDuration = _tmp_resultDuration
              this.resultResponse = _tmp_resultResponse
              this.timestamp = _tmp_timestamp
              this.stored = _tmp_stored
              this.contextRegistration = _tmp_contextRegistration
              this.contextPlatform = _tmp_contextPlatform
              this.contextStatementId = _tmp_contextStatementId
              this.fullStatement = _tmp_fullStatement
              this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
              this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
              this.statementLastChangedBy = _tmp_statementLastChangedBy
              this.statementLct = _tmp_statementLct
              this.extensionProgress = _tmp_extensionProgress
              this.contentEntryRoot = _tmp_contentEntryRoot
              this.statementContentEntryUid = _tmp_statementContentEntryUid
              this.statementLearnerGroupUid = _tmp_statementLearnerGroupUid
              this.statementClazzUid = _tmp_statementClazzUid
              if(!_tmp_VerbEntity_isAllNull) {
                this.verb = VerbEntity().apply {
                  this.verbUid = _tmp_verbUid
                  this.urlId = _tmp_urlId
                  this.verbInActive = _tmp_verbInActive
                  this.verbMasterChangeSeqNum = _tmp_verbMasterChangeSeqNum
                  this.verbLocalChangeSeqNum = _tmp_verbLocalChangeSeqNum
                  this.verbLastChangedBy = _tmp_verbLastChangedBy
                  this.verbLct = _tmp_verbLct
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("StatementEntity",
        "ScopedGrant", "PersonGroupMember", "VerbEntity", "XLangMapEntry"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT StatementEntity.*, VerbEntity.*, 
      |            verbLangMap.valueLangMap AS verbDisplay, 
      |            xobjectMap.valueLangMap AS objectDisplay 
      |        FROM StatementEntity
      |                 JOIN ScopedGrant 
      |                    ON 
      |            ((ScopedGrant.sgTableId = -2
      |                AND ScopedGrant.sgEntityUid = -2)
      |             OR (ScopedGrant.sgTableId = 9
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
      |             OR (ScopedGrant.sgTableId = 6
      |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
      |             OR (ScopedGrant.sgTableId = 164
      |                AND ScopedGrant.sgEntityUid = (
      |                    SELECT clazzSchoolUid
      |                      FROM Clazz
      |                     WHERE clazzUid = StatementEntity.statementClazzUid))
      |             )
      |        
      |                    AND (ScopedGrant.sgPermissions & 549755813888) > 0
      |                 JOIN PersonGroupMember 
      |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
      |                AND PersonGroupMember.groupMemberPersonUid = ?
      |                LEFT JOIN VerbEntity 
      |                    ON VerbEntity.verbUid = StatementEntity.statementVerbUid 
      |                LEFT JOIN XLangMapEntry verbLangMap 
      |                    ON verbLangMap.verbLangMapUid = VerbEntity.verbUid
      |                LEFT JOIN XLangMapEntry xobjectMap 
      |                    ON xobjectMap.objectLangMapUid = StatementEntity.xObjectUid
      |         WHERE statementContentEntryUid = ? 
      |            AND statementPersonUid = ? 
      |            AND contextRegistration = ? 
      |         ORDER BY StatementEntity.timestamp DESC
      |         ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.setLong(3,personUid)
        _stmt.setString(4,contextRegistration)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun calculateScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT SUM(resultScoreRaw) AS resultScore, 
  |               SUM(resultScoreMax) AS resultMax,
  |               MAX(extensionProgress) AS progress,
  |               0 as resultWeight,
  |               0 as penalty,
  |               0 as success,
  |               'FALSE' as contentComplete,
  |               0 AS resultScaled, 
  |               COALESCE((CASE WHEN resultCompletion 
  |               THEN 1 ELSE 0 END),0) AS totalCompletedContent,
  |                
  |                1 as totalContent
  |               
  |         FROM (SELECT * 
  |                 FROM StatementEntity 
  |                WHERE contextRegistration = ?
  |                  AND NOT contentEntryRoot
  |                  AND statementVerbUid = 10007 
  |             GROUP BY xObjectUid) AS SessionStatements
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setString(1,contextRegistration)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_resultScore = _result.getInt("resultScore")
        val _tmp_resultMax = _result.getInt("resultMax")
        val _tmp_resultScaled = _result.getFloat("resultScaled")
        val _tmp_resultWeight = _result.getInt("resultWeight")
        val _tmp_contentComplete = _result.getBoolean("contentComplete")
        val _tmp_progress = _result.getInt("progress")
        val _tmp_success = _result.getByte("success")
        val _tmp_penalty = _result.getInt("penalty")
        val _tmp_totalContent = _result.getInt("totalContent")
        val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
        ContentEntryStatementScoreProgress().apply {
          this.resultScore = _tmp_resultScore
          this.resultMax = _tmp_resultMax
          this.resultScaled = _tmp_resultScaled
          this.resultWeight = _tmp_resultWeight
          this.contentComplete = _tmp_contentComplete
          this.progress = _tmp_progress
          this.success = _tmp_success
          this.penalty = _tmp_penalty
          this.totalContent = _tmp_totalContent
          this.totalCompletedContent = _tmp_totalCompletedContent
        }
      }
    }
  }

  public override suspend fun findCompletedScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT resultScoreRaw AS resultScore, 
  |               resultScoreMax AS resultMax,
  |               extensionProgress AS progress,
  |               0 AS penalty,
  |               0 as resultWeight,
  |               resultSuccess AS success,
  |               resultCompletion AS contentComplete, 
  |               resultScoreScaled AS resultScaled,
  |                1 AS totalCompletedContent,
  |                1 as totalContent
  |               
  |          FROM StatementEntity
  |         WHERE resultCompletion
  |          AND contextRegistration = ?
  |          AND contentEntryRoot
  |     ORDER BY resultScoreScaled DESC, 
  |              extensionProgress DESC, 
  |              resultSuccess DESC 
  |              LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setString(1,contextRegistration)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_resultScore = _result.getInt("resultScore")
        val _tmp_resultMax = _result.getInt("resultMax")
        val _tmp_resultScaled = _result.getFloat("resultScaled")
        val _tmp_resultWeight = _result.getInt("resultWeight")
        val _tmp_contentComplete = _result.getBoolean("contentComplete")
        val _tmp_progress = _result.getInt("progress")
        val _tmp_success = _result.getByte("success")
        val _tmp_penalty = _result.getInt("penalty")
        val _tmp_totalContent = _result.getInt("totalContent")
        val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
        ContentEntryStatementScoreProgress().apply {
          this.resultScore = _tmp_resultScore
          this.resultMax = _tmp_resultMax
          this.resultScaled = _tmp_resultScaled
          this.resultWeight = _tmp_resultWeight
          this.contentComplete = _tmp_contentComplete
          this.progress = _tmp_progress
          this.success = _tmp_success
          this.penalty = _tmp_penalty
          this.totalContent = _tmp_totalContent
          this.totalCompletedContent = _tmp_totalCompletedContent
        }
      }
    }
  }

  public override suspend fun findLatestRegistrationStatement(accountPersonUid: Long,
      entryUid: Long): String? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT contextRegistration 
  |          FROM StatementEntity
  |         WHERE statementPersonUid = ?
  |           AND statementContentEntryUid = ?
  |           AND NOT EXISTS (SELECT statementUid FROM StatementEntity
  |                            WHERE statementPersonUid = ?
  |                             AND statementContentEntryUid = ?
  |                             AND (statementVerbUid = 10001 
  |                                    OR statementVerbUid = 10004))
  |      ORDER BY timestamp DESC 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,accountPersonUid)
    _stmt.setLong(2,entryUid)
    _stmt.setLong(3,accountPersonUid)
    _stmt.setLong(4,entryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }
}
