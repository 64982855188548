package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class LanguageDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: LanguageDao,
) : LanguageDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(languageList: List<Language>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    languageList.iterator().forEach  {
      if(it.langUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(13)
        it.langUid = _newPk
        _generatedPks += _newPk
      }
      it.langLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(languageList)
  }

  public override fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      DataSourceFactory<Int, Language> = _dao.findLanguagesAsSource(sortOrder, searchText)

  public override fun findLanguagesList(): List<Language> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByName(name: String): Language? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByTwoCode(langCode: String): Language? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByTwoCodeAsync(langCode: String): Language? =
      _dao.findByTwoCodeAsync(langCode)

  public override fun findByThreeCode(langCode: String): Language? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun totalLanguageCount(): Int = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Language): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByUid(primaryLanguageUid: Long): Language? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUidAsync(primaryLanguageUid: Long): Language? =
      _dao.findByUidAsync(primaryLanguageUid)

  public override suspend fun updateAsync(entity: Language): Int {
    entity.langLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun findAllLanguageLive(): LiveData<List<Language>> = _dao.findAllLanguageLive()

  public override fun findByUidList(uidList: List<Long>): List<Long> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ): Unit {
    _dao.toggleVisibilityLanguage(toggleVisibility, selectedItem, updateTime)
  }

  public override fun replaceList(entityList: List<Language>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: Language): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Language): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.langUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(13)
      entity.langUid = _newPk
    }
    entity.langLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.langUid
  }

  public override fun insertList(entityList: List<Language>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<Language>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
