package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class HolidayCalendarDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: HolidayCalendarDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : HolidayCalendarDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findAllHolidaysWithEntriesCount():
      DataSourceFactory<Int, HolidayCalendarWithNumEntries> {
    val _result = _dao.findAllHolidaysWithEntriesCount()
    return _result
  }

  public override fun replaceList(list: List<HolidayCalendar>): Unit {
    _dao.replaceList(list)
  }

  public override fun findAllHolidaysLiveData(): LiveData<List<HolidayCalendar>> {
    val _result = _dao.findAllHolidaysLiveData()
    return _result
  }

  public override fun findByUidLive(uid: Long): LiveData<HolidayCalendar?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: HolidayCalendar): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun findByUid(uid: Long): HolidayCalendar? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): HolidayCalendar? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun insert(entity: HolidayCalendar): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: HolidayCalendar): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<HolidayCalendar>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<HolidayCalendar>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: HolidayCalendar): Unit {
    _dao.update(entity)
  }
}
