package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class SiteTermsDao : OneToManyJoinDao<SiteTerms> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun findSiteTerms(langCode: String): SiteTerms?

  public actual abstract suspend fun insertAsync(siteTerms: SiteTerms): Long

  public actual abstract suspend fun findByUidAsync(uid: Long): SiteTerms?

  public actual abstract fun findAllTermsAsFactory(): DataSourceFactory<Int, SiteTermsWithLanguage>

  public actual abstract suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage>

  public actual abstract suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit
}
