package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Message
import com.ustadmobile.lib.db.entities.MessageRead
import com.ustadmobile.lib.db.entities.MessageWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class MessageDao_JdbcKt(
  public val _db: RoomDatabase,
) : MessageDao() {
  public val _insertAdapterMessage_: EntityInsertionAdapter<Message> = object :
      EntityInsertionAdapter<Message>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Message (messageUid, messageSenderPersonUid, messageTableId, messageEntityUid, messageText, messageTimestamp, messageClazzUid, messageLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Message): Unit {
      if(entity.messageUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.messageUid)
      }
      stmt.setLong(2, entity.messageSenderPersonUid)
      stmt.setInt(3, entity.messageTableId)
      stmt.setLong(4, entity.messageEntityUid)
      stmt.setString(5, entity.messageText)
      stmt.setLong(6, entity.messageTimestamp)
      stmt.setLong(7, entity.messageClazzUid)
      stmt.setLong(8, entity.messageLct)
    }
  }

  public override fun insert(entity: Message): Long {
    val _retVal = _insertAdapterMessage_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Message): Long {
    val _retVal = _insertAdapterMessage_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Message>): Unit {
    _insertAdapterMessage_.insertList(entityList)
  }

  public override fun updateList(entityList: List<Message>): Unit {
    val _sql =
        "UPDATE Message SET messageSenderPersonUid = ?, messageTableId = ?, messageEntityUid = ?, messageText = ?, messageTimestamp = ?, messageClazzUid = ?, messageLct = ? WHERE messageUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.messageSenderPersonUid)
        _stmt.setInt(2, _entity.messageTableId)
        _stmt.setLong(3, _entity.messageEntityUid)
        _stmt.setString(4, _entity.messageText)
        _stmt.setLong(5, _entity.messageTimestamp)
        _stmt.setLong(6, _entity.messageClazzUid)
        _stmt.setLong(7, _entity.messageLct)
        _stmt.setLong(8, _entity.messageUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Message): Unit {
    val _sql =
        "UPDATE Message SET messageSenderPersonUid = ?, messageTableId = ?, messageEntityUid = ?, messageText = ?, messageTimestamp = ?, messageClazzUid = ?, messageLct = ? WHERE messageUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.messageSenderPersonUid)
      _stmt.setInt(2, entity.messageTableId)
      _stmt.setLong(3, entity.messageEntityUid)
      _stmt.setString(4, entity.messageText)
      _stmt.setLong(5, entity.messageTimestamp)
      _stmt.setLong(6, entity.messageClazzUid)
      _stmt.setLong(7, entity.messageLct)
      _stmt.setLong(8, entity.messageUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |             JOIN Message ON
    |                  ((    Message.messageTableId = 127
    |                    AND Message.messageEntityUid IN
    |                        (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                  OR UserSession.usSessionType = 2)
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |      /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |             JOIN Message ON
    |                  ((    Message.messageTableId = 127
    |                    AND Message.messageEntityUid IN
    |                        (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                  OR UserSession.usSessionType = 2)
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |       ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodePosts(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |        
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |            
    |             JOIN Message 
    |                  ON Message.messageTableId = 132
    |                  AND Message.messageClazzUid = Clazz.clazzUid
    |            
    |            
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |      /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |        
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |            
    |             JOIN Message 
    |                  ON Message.messageTableId = 132
    |                  AND Message.messageClazzUid = Clazz.clazzUid
    |            
    |            
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |       ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeChat(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 127
    |                 JOIN UserSession ON
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid
    |                              FROM ChatMember
    |                             WHERE ChatMember.chatMemberChatUid = Message.messageEntityUid))
    |                       OR UserSession.usSessionType = 2)       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 127
    |                 JOIN UserSession ON
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid
    |                              FROM ChatMember
    |                             WHERE ChatMember.chatMemberChatUid = Message.messageEntityUid))
    |                       OR UserSession.usSessionType = 2)       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangePosts(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |            
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 132
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = Message.messageClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |            
    |                       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |            
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 132
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = Message.messageClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |            
    |                       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllMessagesByChatUid(
    entityUid: Long,
    tableId: Int,
    loggedInPersonUid: Long,
  ): DataSourceFactory<Int, MessageWithPerson> = object :
      DataSourceFactory<Int, MessageWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<MessageWithPerson>> =
        LiveDataImpl(_db, listOf("Message", "Person", "MessageRead"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |       SELECT
      |              Message.*,
      |              Person.*,
      |              MessageRead.*
      |        FROM Message
      |        LEFT JOIN Person
      |          ON Message.messageSenderPersonUid = Person.personUid
      |        LEFT JOIN MessageRead
      |          ON MessageRead.messageReadMessageUid = Message.messageUid
      |             AND MessageRead.messageReadPersonUid = ?
      |       WHERE Message.messageTableId = ?
      |              AND Message.messageEntityUid = ?
      |    ORDER BY Message.messageTimestamp DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,loggedInPersonUid)
        _stmt.setInt(2,tableId)
        _stmt.setLong(3,entityUid)
        _stmt.setInt(4,_limit)
        _stmt.setInt(5,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_messageUid = _result.getLong("messageUid")
            val _tmp_messageSenderPersonUid = _result.getLong("messageSenderPersonUid")
            val _tmp_messageTableId = _result.getInt("messageTableId")
            val _tmp_messageEntityUid = _result.getLong("messageEntityUid")
            val _tmp_messageText = _result.getString("messageText")
            val _tmp_messageTimestamp = _result.getLong("messageTimestamp")
            val _tmp_messageClazzUid = _result.getLong("messageClazzUid")
            val _tmp_messageLct = _result.getLong("messageLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            var _tmp_MessageRead_nullCount = 0
            val _tmp_messageReadUid = _result.getLong("messageReadUid")
            if(_result.wasNull()) _tmp_MessageRead_nullCount++
            val _tmp_messageReadPersonUid = _result.getLong("messageReadPersonUid")
            if(_result.wasNull()) _tmp_MessageRead_nullCount++
            val _tmp_messageReadMessageUid = _result.getLong("messageReadMessageUid")
            if(_result.wasNull()) _tmp_MessageRead_nullCount++
            val _tmp_messageReadEntityUid = _result.getLong("messageReadEntityUid")
            if(_result.wasNull()) _tmp_MessageRead_nullCount++
            val _tmp_messageReadLct = _result.getLong("messageReadLct")
            if(_result.wasNull()) _tmp_MessageRead_nullCount++
            val _tmp_MessageRead_isAllNull = _tmp_MessageRead_nullCount == 5
            MessageWithPerson().apply {
              this.messageUid = _tmp_messageUid
              this.messageSenderPersonUid = _tmp_messageSenderPersonUid
              this.messageTableId = _tmp_messageTableId
              this.messageEntityUid = _tmp_messageEntityUid
              this.messageText = _tmp_messageText
              this.messageTimestamp = _tmp_messageTimestamp
              this.messageClazzUid = _tmp_messageClazzUid
              this.messageLct = _tmp_messageLct
              if(!_tmp_Person_isAllNull) {
                this.messagePerson = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
              if(!_tmp_MessageRead_isAllNull) {
                this.messageRead = MessageRead().apply {
                  this.messageReadUid = _tmp_messageReadUid
                  this.messageReadPersonUid = _tmp_messageReadPersonUid
                  this.messageReadMessageUid = _tmp_messageReadMessageUid
                  this.messageReadEntityUid = _tmp_messageReadEntityUid
                  this.messageReadLct = _tmp_messageReadLct
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Message", "Person",
        "MessageRead"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |       SELECT
      |              Message.*,
      |              Person.*,
      |              MessageRead.*
      |        FROM Message
      |        LEFT JOIN Person
      |          ON Message.messageSenderPersonUid = Person.personUid
      |        LEFT JOIN MessageRead
      |          ON MessageRead.messageReadMessageUid = Message.messageUid
      |             AND MessageRead.messageReadPersonUid = ?
      |       WHERE Message.messageTableId = ?
      |              AND Message.messageEntityUid = ?
      |    ORDER BY Message.messageTimestamp DESC
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,loggedInPersonUid)
        _stmt.setInt(2,tableId)
        _stmt.setLong(3,entityUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }
}
