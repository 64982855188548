package com.ustadmobile.core.generated.locale


        object MessageIdMap {
        
            private val strMap = mapOf(
                
        2018 to "cancel",
2783 to "my_content",
2636 to "sel_question_type_multiple_choice",
2215 to "year",
2834 to "upload",
2838 to "saved",
2096 to "no_video_file_found",
2546 to "from_to_date",
2186 to "score",
2165 to "entry_details_license",
2528 to "instructions_for_students",
2308 to "absent",
2450 to "not_recorded",
2003 to "err_registering_new_user",
2566 to "untitled",
2355 to "last_month",
2595 to "mark_complete",
2506 to "shake_feedback",
2958 to "assign_reviewers",
2063 to "optional",
2094 to "select_subtitle_video",
2780 to "anyone_with_this_can_join_school",
2613 to "public_comments",
2768 to "folder",
2693 to "strings_not_allowed",
2716 to "parent_consent_explanation",
2454 to "role",
2341 to "weekly",
2865 to "characters",
2674 to "moved_x_entries",
2628 to "connect_as_guest",
2582 to "edit_assignment",
2178 to "content_entry_export_message",
2690 to "connect",
2315 to "address",
2095 to "no_subtitle",
2577 to "clazz_work",
2000 to "app_name",
2474 to "edit_person",
2184 to "bar_chart",
2424 to "days",
2812 to "add_or_edit_assignment",
2158 to "download_cloud_availability",
2044 to "register",
2916 to "standard",
2071 to "reports",
2248 to "field_content_progress",
2882 to "edit_module",
2360 to "notes",
2736 to "continue_using_link_recommended",
2371 to "moved",
2708 to "join_school",
2765 to "content_entries_completed",
2879 to "discussion_board",
2252 to "condition_greater_than",
2946 to "submission_already_made",
2491 to "permission_activity_update",
2258 to "add_verb_filter",
2514 to "change_password",
2885 to "institution",
2446 to "attendance_percentage",
2609 to "maximum_score",
2785 to "grades_class_age",
2905 to "add_topic",
2936 to "post",
2667 to "add_student_to_school",
2685 to "edit_site",
2750 to "deleting_content",
2049 to "female",
2276 to "offline_sharing_enable_bluetooth_prompt",
2420 to "export",
2828 to "total_content_usage_duration_class",
2519 to "add",
2668 to "add_staff_to_school",
2176 to "content_editor_save_error",
2545 to "select_item",
2891 to "visibility",
2473 to "add_a_new_person",
2212 to "new_custom_date_range",
2452 to "group_setting_desc",
2108 to "article",
2432 to "users",
2466 to "role_name",
2406 to "activity_enter_any_notes",
2578 to "add_a_new_clazzwork",
2646 to "more_information",
2091 to "success",
2672 to "join_code_instructions",
2738 to "start_from_scratch",
2489 to "permission_activity_select",
2045 to "phone_number",
2805 to "error_code",
2508 to "send_feedback",
2125 to "relative_date",
2901 to "assign_to_random_groups",
2453 to "roles",
2773 to "use_the_public_lib_env",
2171 to "content_creation_folder_new_message",
2036 to "confirm_password",
2816 to "edit_clazzes",
2470 to "scope_by",
2694 to "undo",
2013 to "recycled",
2087 to "waiting_for_connection",
2253 to "condition_less_than",
2642 to "scope",
2192 to "average_usage_time_per_user",
2300 to "edit_schedule",
2038 to "first_name",
2504 to "option_value",
2872 to "add_block",
2616 to "add_class_comment",
2908 to "message",
2944 to "enrolment_policy",
2895 to "select_terminology",
2119 to "add_file_description",
2963 to "pdf",
2378 to "leaving_reason",
2423 to "age",
2034 to "username",
2246 to "field_content_completion",
2863 to "score_greater_than_zero",
2194 to "percent_students_completed",
2299 to "add_a_schedule",
2873 to "edit_block",
2224 to "xapi_options_y_axes",
2495 to "permission_sel_select",
2309 to "attendance_report",
2290 to "license_type_cc_0",
2744 to "add_from_gallery",
2354 to "toC",
2789 to "create_my_account",
2283 to "licence_type_cc_by",
2927 to "currently_enrolled",
2635 to "sel_question_type_free_text",
2167 to "multiple_choice",
2657 to "share_link",
2387 to "enrolment",
2525 to "schools",
2890 to "accept_enrolment_requests_automatically",
2398 to "partial",
2500 to "field_type",
2669 to "permission_password_reset",
2861 to "submit_grade_and_mark_next",
2027 to "error_opening_file",
2050 to "other",
2187 to "content_total_duration",
2331 to "class_description",
2513 to "save",
2436 to "gender_literal",
2832 to "number_of_active_users_over_time",
2610 to "quiz_questions",
2001 to "login",
2333 to "holiday",
2456 to "edit_role",
2496 to "permission_sel_update",
2697 to "content_pieces_completed",
2766 to "content_usage_time",
2082 to "share",
2583 to "total_score",
2412 to "add_activity_literal",
2385 to "outcome",
2745 to "add_video_or_audio_from_the_device_gallery",
2347 to "monday",
2709 to "what_is_your_date_of_birth",
2324 to "question",
2638 to "question_type",
2037 to "last_name",
2285 to "licence_type_cc_by_sa_nc",
2391 to "ascending",
2703 to "opening_link",
2334 to "add_a_holiday",
2594 to "completion_criteria",
2614 to "private_comments",
2556 to "permission_clazz_assignment_view",
2632 to "logged_in_as",
2942 to "submit_all_at_once_submission_policy",
2244 to "field_person_gender",
2706 to "you_are_already_in_school",
2590 to "points",
2752 to "add_educational_content_using_supported_formats_eg_pdf_xapi_h5p",
2854 to "file_type",
2467 to "members",
2767 to "all_permissions",
2427 to "all",
2052 to "settings",
2969 to "panic_button_explanation",
2202 to "xapi_day",
2497 to "custom_fields",
2943 to "multiple_submission_allowed_submission_policy",
2133 to "dialog_download_from_playstore_cancel",
2769 to "create_a_new_learning_env",
2681 to "guest_login_enabled",
2397 to "add_a_teacher",
2344 to "monthly",
2656 to "copy_code",
2888 to "open_enrolment",
2883 to "indent",
2152 to "download_downloading_placeholder",
2731 to "view_socioeconomic_details_of_members",
2296 to "sort_by",
2375 to "new_enrolment",
2544 to "use_device_language",
2644 to "role_description",
2442 to "connectivity_full",
2396 to "teacher",
2824 to "percentage_of_students_attending_over_time",
2114 to "and",
2295 to "today",
2351 to "friday",
2692 to "edit_terms_and_policies",
2733 to "add_person_or_group",
2272 to "import_link_content_not_supported",
2538 to "due_date",
2592 to "automatic",
2073 to "save_as_template",
2549 to "add_to",
2413 to "select_one",
2070 to "exams",
2154 to "download_cancel_label",
2325 to "add_question",
2455 to "add_a_new_role",
2896 to "add_new_terminology",
2077 to "forgot_password",
2163 to "entry_details_author",
2431 to "sel_question_set_desc",
2273 to "import_link_big_size",
2314 to "basic_details",
2120 to "error_this_device_doesnt_support_bluetooth_sharing",
2225 to "xapi_options_x_axes",
2776 to "you_must_comply_with_license",
2335 to "edit_holiday",
2035 to "password",
2957 to "from_my_courses",
2950 to "add_discussion",
2743 to "add_using_a_web_link_then",
2928 to "past_enrollments",
2076 to "add_a_student",
2093 to "completed",
2814 to "edit_basic_profile_of_members",
2502 to "dropdown",
2269 to "no_account",
2004 to "register_empty_fields",
2227 to "xapi_options_filters",
2266 to "xapi_result_header",
2441 to "connectivity_limited",
2857 to "file_document",
2369 to "graduated",
2160 to "download_entry_state_paused",
2393 to "sort_by_text",
2242 to "id_verbentity_urlid",
2645 to "assign_role",
2481 to "permission_clazz_select",
2603 to "short_text",
2107 to "document",
2926 to "edit_course",
2075 to "students",
2201 to "number_unique_students_attending",
2841 to "dont_show_before",
2876 to "formatted_text_to_show_to_course_participants",
2956 to "disabled",
2330 to "class_name",
2605 to "quiz",
2967 to "delete_app_data_from_device",
2057 to "languages",
2262 to "xapi_hours",
2547 to "num_items_with_name",
2494 to "permission_sel_question_update",
2520 to "refresh",
2570 to "not_submitted_cap",
2263 to "xapi_minutes",
2915 to "past_enrolments",
2234 to "report_filter_edit_field",
2524 to "join_existing_school",
2562 to "call",
2770 to "add_educational_content_to_new_learning_env",
2145 to "download_continue_stacked_label",
2483 to "permission_clazz_update",
2209 to "last_two_week_date_range",
2417 to "time_period",
2099 to "import_content",
2401 to "primary_user",
2827 to "content_usage_by_class",
2356 to "activity_change",
2625 to "organisation",
2559 to "add_new",
2848 to "edit_after_submission",
2400 to "participant",
2058 to "edit_language",
2311 to "mother",
2092 to "incomplete",
2336 to "holidays",
2762 to "app_store_link",
2521 to "school",
2381 to "view_profile",
2784 to "learning_environment",
2877 to "add_course_block_content_desc",
2760 to "nearby_share",
2364 to "sort_by_name_desc",
2250 to "condition_is",
2367 to "enroled",
2702 to "select_account",
2910 to "courses",
2588 to "allow_class_comments",
2302 to "last",
2759 to "recommended_for_advanced_users",
2012 to "downloaded",
2449 to "sync",
2740 to "add_permission_for_a_person",
2660 to "permission_person_delegate",
2140 to "onboarding_no_internet_subheadline",
2370 to "dropped_out",
2117 to "add_link_description",
2046 to "name",
2137 to "onboarding_get_started_label",
2112 to "unhide",
2711 to "we_sent_a_message_to_your_parent",
2031 to "next",
2531 to "percentage_score",
2793 to "almost_done",
2619 to "submit",
2788 to "add_remove_and_modify_subjects",
2069 to "classes",
2572 to "x_percent_attended",
2633 to "person_exists",
2047 to "description",
2695 to "video",
2622 to "return_and_mark_next",
2860 to "submit_grade",
2055 to "add_new_language",
2641 to "add_role_permission",
2214 to "time_range",
2180 to "open_folder",
2691 to "terms_and_policies_text",
2775 to "revoking_consent_will",
2277 to "offline_sharing_enable_wifi_promot",
2569 to "submitted_cap",
2558 to "overview",
2796 to "search_in",
2683 to "yes",
2014 to "libraries",
2323 to "student_image",
2428 to "holiday_calendars",
2193 to "number_students_completed",
2677 to "add_a_new_occurrence",
2322 to "join_existing_class",
2737 to "continue_using_the_link",
2819 to "content_already_added_to_class",
2843 to "require_text_submission",
2611 to "time",
2830 to "total_content_duration_gender",
2966 to "exit_app",
2526 to "assignments",
2042 to "memory_card",
2105 to "interactive",
2540 to "add_new_content",
2339 to "edit_holiday_calendar",
2532 to "started_date",
2386 to "person_enrolment_in_class",
2551 to "items_selected",
2028 to "logout",
2384 to "in_progress",
2718 to "i_consent",
2438 to "connectivity",
2440 to "mobile_internet_access",
2503 to "options",
2147 to "download_wifi_only",
2523 to "edit_school",
2213 to "selected_custom_range",
2260 to "dashboard",
2382 to "class_enrolment_outcome",
2487 to "permission_attendance_insert",
2408 to "uom_duration_title",
2259 to "add_content_filter",
2346 to "sunday",
2067 to "phone",
2952 to "type_here",
2373 to "edit_leaving_reason",
2162 to "no_app_found",
2462 to "audit_log_setting_desc",
2655 to "copy_link",
2291 to "syncing",
2297 to "log",
2471 to "assign_to",
2579 to "edit_clazzwork",
2955 to "enabled",
2771 to "connect_to_an_existing_learning_environment",
2892 to "hidden_enrolment_via_links_code_or_invitation",
2144 to "onboarding_stay_organized_subheading",
2948 to "update_grade",
2267 to "xapi_score",
2149 to "download_state_download",
2510 to "start_date",
2851 to "not_allowed",
2931 to "new_group",
2461 to "audit_log",
2270 to "enter_url",
2008 to "confirm_delete_message",
2230 to "xapi_options_what",
2368 to "class_enrolment",
2443 to "prefer_not_to_say",
2372 to "new_leaving_reason",
2433 to "nomination",
2836 to "drag_and_drop_or_click_to_add_file",
2637 to "question_text",
2742 to "add_using_link",
2024 to "deleting",
2589 to "after_deadline_date_error",
2182 to "invalid_register_code",
2932 to "chat",
2010 to "login_network_error",
2779 to "class_setup",
2080 to "accounts",
2871 to "course_blocks",
2527 to "None",
2207 to "xapi_clear",
2337 to "holiday_calendar",
2509 to "feedback_thanks",
2727 to "edit_class_content",
2823 to "attendance_over_time_by_class",
2903 to "num_replies",
2923 to "add_module",
2221 to "xapi_options_data_set",
2680 to "manage_site_settings",
2121 to "share_offline_zip_checkbox_label",
2159 to "insufficient_space",
2231 to "xapi_options_when",
2600 to "visible_from_date",
2210 to "last_month_date_range",
2357 to "change_implemented_if_any",
2725 to "manage_parental_consent",
2353 to "day",
2122 to "share_offline_dialog_message",
2363 to "sort_by_name_asc",
2383 to "class_enrolment_leaving",
2840 to "group",
2597 to "marked",
2103 to "licence",
2772 to "does_your_class_already_have_learning_env",
2205 to "xapi_custom_date",
2492 to "permission_sel_question_select",
2015 to "pause_download",
2652 to "ask_your_teacher_for_code",
2826 to "number_of_students_completed_time",
2217 to "done",
2181 to "action_hidden",
2185 to "line_chart",
2284 to "licence_type_cc_by_sa",
2343 to "daily",
2803 to "sorry_something_went_wrong",
2626 to "use_public_library_site",
2517 to "timezone",
2666 to "permission_school_update",
2868 to "penalty_label",
2239 to "edit_report",
2859 to "max_number_of_files",
2274 to "import_link_error",
2189 to "count_session",
2329 to "add_class",
2164 to "entry_details_publisher",
2485 to "permission_clazz_add_student",
2604 to "attachment",
2651 to "reject",
2407 to "uom_frequency_title",
2802 to "oops",
2782 to "from_my_classes",
2899 to "course_setup",
2893 to "visible_for_all",
2110 to "move",
2128 to "filed_password_no_match",
2399 to "teachers_literal",
2858 to "file_image",
2243 to "edit_filters",
2411 to "add_activity",
2968 to "hide_app",
2732 to "edit_socioeconomic_details_of_members",
2621 to "marking",
2949 to "update_grade_and_mark_next",
2303 to "last_week",
2664 to "permission_school_select",
2068 to "attendance",
2079 to "account",
2833 to "parent",
2962 to "app_language",
2255 to "condition_not_in_list",
2435 to "class_id",
2404 to "activity_went_good",
2746 to "add_file",
2479 to "permission_person_picture_insert",
2951 to "edit_discussion",
2426 to "file_selected",
2648 to "pending_requests",
2804 to "incident_id",
2889 to "enrolment_requests_must_be_approved",
2482 to "permission_clazz_insert",
2041 to "phone_memory",
2548 to "num_items_with_name_with_comma",
2881 to "new_module",
2933 to "you",
2282 to "repo_loading_status_failed_connection_error",
2130 to "profile",
2434 to "active",
2175 to "supported_files",
2662 to "permission_role_select",
2763 to "everything_works_offline",
2761 to "recommended_for_offline_installation",
2019 to "canceled",
2598 to "submitted",
2654 to "invite_link_desc",
2869 to "submissions",
2065 to "regcode",
2714 to "parent_child_register_message_subject",
2499 to "custom_fields_desc",
2707 to "join_class",
2422 to "average",
2179 to "show_hidden_items",
2458 to "role_assignment_setting_desc",
2172 to "content_creation_folder_update_message",
2734 to "lets_get_started",
2921 to "new_group_set",
2445 to "search",
2460 to "locations_setting_desc",
2377 to "add_leaving_reason",
2349 to "wednesday",
2561 to "x_teachers_y_students",
2124 to "fixed_date",
2607 to "deadline",
2870 to "add_text",
2940 to "managed_enrolment",
2498 to "custom_field",
2249 to "field_attendance_percentage",
2730 to "edit_contact_details_of_members",
2405 to "activity_went_bad",
2787 to "subjects",
2864 to "words",
2326 to "edit_question",
2350 to "thursday",
2560 to "staff",
2856 to "file_type_any",
2328 to "add_person",
2439 to "home_internet_access",
2542 to "student_progress",
2629 to "create_account",
2855 to "file_type_chosen",
2183 to "enter_register_code",
2021 to "deleted",
2059 to "two_letter_code",
2573 to "answer",
2844 to "maximum",
2141 to "onboarding_offline_sharing",
2723 to "status_consent_denied",
2567 to "present_late_absent",
2808 to "view_clazzes",
2072 to "choose_template",
2568 to "marked_cap",
2115 to "added",
2700 to "powered_by",
2930 to "new_chat",
2327 to "questions",
2053 to "language",
2156 to "download_storage_option_device",
2403 to "down",
2845 to "size_limit",
2878 to "add_assignment_block_content_desc",
2281 to "repo_loading_status_failed_noconnection",
2705 to "you_are_already_in_class",
2671 to "entity_code",
2918 to "course_code",
2591 to "minimum_score",
2150 to "download_state_downloading",
2374 to "edit_enrolment",
2155 to "download_pause_download",
2061 to "languages_description",
2563 to "organization_id",
2807 to "view_clazz",
2191 to "number_active_users",
2097 to "content_from_file",
2670 to "school_code",
2704 to "select_account_to_continue",
2756 to "add_subject",
2086 to "waiting",
2507 to "feedback_hint",
2236 to "report_filter_edit_values",
2320 to "edit_clazz",
2033 to "back",
2817 to "add_new_clazz_to_school",
2102 to "title",
2286 to "licence_type_cc_by_nc",
2539 to "content",
2358 to "duration",
2866 to "class_timezone_set",
2894 to "terminology",
2138 to "lets_get_started_label",
2090 to "passed",
2774 to "just_want_to_browse",
2366 to "date_left",
2448 to "over",
2245 to "field_person_age",
2941 to "text_file_submission_error",
2464 to "permission",
2066 to "home",
2682 to "registration_allowed",
2126 to "field_required_prompt",
2298 to "schedule",
2395 to "attendance_low_to_high",
2724 to "other_legal_guardian",
2287 to "licence_type_cc_by_nc_sa",
2799 to "alternatively_you_can_download_the_apk",
2515 to "current_password",
2550 to "remove_from",
2039 to "register_now",
2338 to "add_a_new_holiday_calendar",
2146 to "download_continue_btn_label",
2062 to "advanced",
2853 to "peers",
2900 to "number_of_groups",
2241 to "add_a_new_verb",
2852 to "marked_by",
2501 to "text",
2735 to "looks_like_installed_app_from_link",
2240 to "verb",
2023 to "loading",
2898 to "your_words_for",
2220 to "xapi_options_visual_type",
2256 to "condition_between",
2074 to "contents",
2219 to "xapi_options_report_title",
2907 to "messages",
2196 to "total_absences",
2543 to "status",
2081 to "share_application",
2475 to "permission_person_select",
2571 to "submitliteral",
2909 to "library",
2468 to "member",
2608 to "class_timezone",
2862 to "graded",
2151 to "download_state_downloaded",
2379 to "leaving_reasons",
2238 to "create_a_new_report",
2275 to "supported_link",
2530 to "percentage_complete",
2904 to "topics",
2801 to "limited_i_avoid_downloading_too_much",
2148 to "download_calculating",
2758 to "share_apk_file",
2111 to "hide",
2104 to "ebook",
2679 to "terms_and_policies",
2389 to "error_start_date_before_previous_enrolment_date",
2190 to "interaction_recorded",
2237 to "report",
2512 to "last_active",
2661 to "this_field_is_mandatory",
2280 to "repo_loading_status_loading_mirror",
2294 to "record_attendance",
2477 to "permission_person_update",
2136 to "created_partnership",
2798 to "please_download_the_app",
2541 to "add_content",
2602 to "no_submission_required",
2429 to "holiday_calendars_desc",
2792 to "add_from_contacts",
2965 to "actions_to_be_taken_in_case_of_emergency",
2753 to "current_status_consent_granted",
2409 to "uom_boolean_title",
2051 to "unset",
2935 to "posts",
2748 to "importing",
2011 to "download",
2726 to "view_class_content",
2388 to "error_start_date_before_clazz_date",
2688 to "please_enter_the_linK",
2085 to "downloading",
2457 to "rols_assignment",
2947 to "grade_out_of_range",
2846 to "limit",
2522 to "add_a_new_school",
2177 to "content_entry_export_inprogress",
2747 to "add_folder",
2906 to "add_a_reply",
2659 to "role_not_selected_error",
2584 to "late_submission",
2630 to "my",
2292 to "feed",
2463 to "entry",
2953 to "deadline_has_passed",
2307 to "present",
2505 to "errors",
2312 to "birthday",
2687 to "site_link",
2684 to "no",
2564 to "student",
2728 to "view_class_learning_records",
2815 to "view_school",
2534 to "mixed",
2020 to "delete",
2739 to "ignore_the_link",
2653 to "copied_to_clipboard",
2902 to "add_new_groups",
2376 to "select_leaving_reason",
2751 to "add_folder_description",
2016 to "ok",
2640 to "roles_and_permissions",
2754 to "select_country",
2606 to "allow_private_comments_from_students",
2689 to "or",
2195 to "total_attendances",
2419 to "last_three_months",
2698 to "content_usage_duration",
2554 to "take_new_photo_from_camera",
2197 to "total_lates",
2663 to "permission_role_insert",
2264 to "xapi_seconds",
2535 to "store_title",
2518 to "users_settings_desc",
2650 to "please_wait_for_approval",
2088 to "queued",
2029 to "about",
2701 to "invalid_email",
2254 to "condition_in_list",
2557 to "permission_clazz_asignment_edit",
2634 to "incorrect_current_password",
2757 to "share_via",
2795 to "select_content",
2132 to "dialog_download_from_playstore_ok",
2696 to "content_editor_create_new_category",
2959 to "reviews_per_user_group",
2317 to "edit",
2416 to "yes_no",
2643 to "scope_description",
2874 to "module",
2271 to "invalid_link",
2222 to "xapi_options_series",
2134 to "accept",
2809 to "enrol_and_unenrol_students",
2188 to "content_average_duration",
2536 to "store_description_short",
2778 to "see_supported_sites",
2161 to "get_app",
2755 to "enter_link",
2025 to "processing",
2960 to "assign_random_reviewers",
2623 to "return_only",
2043 to "device",
2208 to "last_week_date_range",
2954 to "unassigned_error",
2005 to "register_incorrect_email",
2048 to "male",
2627 to "create_site",
2511 to "end_date",
2831 to "unique_content_users_over_time",
2924 to "add_a_new_course",
2352 to "saturday",
2361 to "activity",
2430 to "sel_question_set",
2929 to "unread",
2100 to "update_content",
2849 to "allowed_till_deadline",
2394 to "attendance_high_to_low",
2596 to "late_penalty",
2581 to "new_assignment",
2365 to "date_enroll",
2806 to "take_me_home",
2109 to "audio",
2142 to "onboarding_offline_sharing_subheading",
2717 to "relationship",
2064 to "email",
2552 to "change_photo",
2741 to "add_permission_for_a_group",
2488 to "permission_attendance_update",
2127 to "field_password_error_min",
2056 to "select_language",
2306 to "mark_all_absent",
2587 to "end_of_grace_period",
2305 to "mark_all_present",
2939 to "error_too_long_text",
2713 to "parental_consent",
2533 to "started",
2565 to "contact_details",
2961 to "reviewer",
2639 to "add_option",
2293 to "people",
2017 to "confirm",
2478 to "permission_person_picture_select",
2173 to "error_message_load_page",
2574 to "of_content",
2313 to "first_names",
2022 to "new_tab",
2200 to "total_number_of_classes",
2811 to "view_assignments",
2722 to "status_consent_granted",
2265 to "xapi_verb_header",
2332 to "to",
2084 to "open",
2437 to "country",
2624 to "workspace",
2174 to "error_message_update_document",
2631 to "add_another",
2922 to "edit_text",
2850 to "allowed_till_grace",
2617 to "add_private_comment",
2170 to "file_required_prompt",
2837 to "extra_active_tab_warning",
2415 to "frequency",
2821 to "adult_account_required",
2402 to "up",
2229 to "xapi_options_did",
2106 to "collection",
2934 to "edit_topic",
2576 to "not_answered",
2913 to "course_comments",
2235 to "report_filter_edit_condition",
2486 to "permission_attendance_select",
2964 to "panic_button_app",
2919 to "add_group",
2410 to "uom_default_title",
2143 to "onboarding_stay_organized_headline",
2342 to "once",
2917 to "group_number",
2268 to "import_title_not_entered",
2345 to "yearly",
2970 to "hide_app_explanation",
2168 to "preview",
2880 to "add_discussion_board_desc",
2884 to "unindent",
2166 to "file_not_found",
2288 to "licence_type_public_domain",
2310 to "father",
2649 to "pending",
2129 to "update",
2665 to "permission_school_insert",
2786 to "add_remove_and_modify_grades",
2251 to "condition_is_not",
2203 to "xapi_week",
2601 to "submission_type",
2721 to "restore_consent",
2032 to "previous",
2425 to "apply",
2153 to "download_summary_title",
2822 to "blank_report",
2232 to "xapi_options_where",
2444 to "sel_question_set_to_use",
2777 to "you_can_copypaste_a_link",
2040 to "new_password",
2418 to "location",
2647 to "class_code",
2131 to "dialog_download_from_playstore_message",
2414 to "measurement_type",
2813 to "view_basic_profile_of_members",
2835 to "selected_file_summary",
2781 to "this_class",
2797 to "type",
2054 to "add_a_new_language",
2490 to "permission_activity_insert",
2060 to "three_letter_code",
2157 to "download_locally_availability",
2897 to "edit_terminology",
2278 to "enable",
2465 to "permissions",
2720 to "revoke_consent",
2218 to "xapi_options_general",
2476 to "permission_person_insert",
2810 to "enrol_and_unenrol_teachers",
2116 to "attempts",
2113 to "compress",
2484 to "permission_clazz_add_teacher",
2493 to "permission_sel_question_insert",
2575 to "nothing_here",
2764 to "swipe_to_next",
2875 to "course_module",
2580 to "clazz_assignment",
2261 to "activity_import_link",
2586 to "late_submission_penalty",
2006 to "group_activity",
2937 to "individual_submission",
2842 to "require_file_submission",
2078 to "notifications",
2204 to "xapi_month",
2233 to "time_range_all",
2101 to "publicly_accessible",
2206 to "xapi_content_entry",
2098 to "content_from_link",
2211 to "last_three_months_date_range",
2847 to "number_of_files",
2914 to "currently_enroled",
2340 to "features_enabled",
2618 to "class_comments",
2886 to "student_enrolment_policy",
2555 to "select_new_photo_from_gallery",
2820 to "your_account_needs_approved",
2710 to "parents_email_address",
2911 to "course",
2390 to "time_present",
2925 to "join_existing_course",
2228 to "xapi_options_who",
2790 to "recommend_it",
2920 to "edit_group",
2658 to "invite_with_link",
2007 to "select_group_members",
2199 to "percent_students_attended_or_late",
2585 to "mark_penalty",
2447 to "filter",
2469 to "role_assignment",
2712 to "toggle_visibility",
2825 to "content_completion",
2009 to "wrong_user_pass_combo",
2599 to "not_submitted",
2686 to "site",
2030 to "download_all",
2800 to "full_i_can_download_as_much_as_i_like",
2829 to "content_usage_over_time",
2198 to "percent_students_attended",
2612 to "submission",
2359 to "how_did_it_go",
2676 to "record_attendance_for_most_recent_occurrence",
2301 to "first",
2421 to "date",
2593 to "student_marks_content",
2938 to "unassigned",
2480 to "permission_person_picture_update",
2867 to "maximum_points",
2392 to "descending",
2529 to "not_started",
2139 to "onboarding_no_internet_headline",
2719 to "i_do_not_consent",
2912 to "reports_and_analytics",
2026 to "uploading",
2169 to "content_creation_storage_option_title",
2216 to "from",
2304 to "mark_all",
2362 to "sort_by_name",
2945 to "submission_policy",
2516 to "password_unchanged",
2839 to "individual",
2279 to "repo_loading_status_loading_cloud",
2620 to "three_num_items_with_name_with_comma",
2678 to "active_classes",
2316 to "add_person_to_class",
2226 to "xapi_options_subgroup",
2537 to "store_description_full",
2089 to "failed",
2135 to "also_available_in",
2675 to "end_is_before_start_error",
2699 to "manage_download",
2118 to "add_gallery_description",
2123 to "preparing",
2673 to "record_for_student",
2472 to "person",
2715 to "parent_child_register_message",
2887 to "teacher_enrolment_policy",
2083 to "send",
2223 to "xapi_options_axes",
2615 to "submit_your_answer",
2002 to "error",
2319 to "add_a_new_class",
2791 to "edit_permissions",
2289 to "licence_type_all_rights",
2247 to "field_content_entry",
2321 to "add_a_class",
2794 to "your_username",
2553 to "remove_photo",
2451 to "groups",
2380 to "leaving_reason_manage",
2729 to "view_contact_details_of_members",
2348 to "tuesday",
2749 to "downloading_content",
2459 to "locations",
2257 to "add_person_filter",
2318 to "clazz",
2818 to "added_to_class_content")
        operator fun get(messageId: Int) = strMap[messageId]
        
        val idMap: Map<Int, String> = strMap.toMap()
        
        }