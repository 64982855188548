package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class HolidayCalendarDao : BaseDao<HolidayCalendar> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findAllHolidaysWithEntriesCount():
      DataSourceFactory<Int, HolidayCalendarWithNumEntries>

  public actual abstract fun replaceList(list: List<HolidayCalendar>): Unit

  public actual abstract fun findAllHolidaysLiveData(): LiveData<List<HolidayCalendar>>

  public actual abstract fun findByUidLive(uid: Long): LiveData<HolidayCalendar?>

  public actual abstract suspend fun updateAsync(entity: HolidayCalendar): Int

  public actual abstract suspend fun findByUid(uid: Long): HolidayCalendar?

  public actual abstract suspend fun findByUidAsync(uid: Long): HolidayCalendar?
}
