package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryPicture
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryPictureDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContentEntryPictureDao() {
  public val _insertAdapterContentEntryPicture_: EntityInsertionAdapter<ContentEntryPicture> =
      object : EntityInsertionAdapter<ContentEntryPicture>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ContentEntryPicture (cepUid, cepContentEntryUid, cepUri, cepMd5, cepFileSize, cepTimestamp, cepMimeType, cepActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryPicture): Unit {
      if(entity.cepUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cepUid)
      }
      stmt.setLong(2, entity.cepContentEntryUid)
      stmt.setString(3, entity.cepUri)
      stmt.setString(4, entity.cepMd5)
      stmt.setInt(5, entity.cepFileSize)
      stmt.setLong(6, entity.cepTimestamp)
      stmt.setString(7, entity.cepMimeType)
      stmt.setBoolean(8, entity.cepActive)
    }
  }

  public override fun insert(entity: ContentEntryPicture): Long {
    val _retVal = _insertAdapterContentEntryPicture_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntryPicture): Long {
    val _retVal = _insertAdapterContentEntryPicture_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContentEntryPicture>): Unit {
    _insertAdapterContentEntryPicture_.insertList(entityList)
  }

  public override suspend fun updateAsync(ContentEntryPicture: ContentEntryPicture): Unit {
    val _sql =
        "UPDATE ContentEntryPicture SET cepContentEntryUid = ?, cepUri = ?, cepMd5 = ?, cepFileSize = ?, cepTimestamp = ?, cepMimeType = ?, cepActive = ? WHERE cepUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, ContentEntryPicture.cepContentEntryUid)
      _stmt.setString(2, ContentEntryPicture.cepUri)
      _stmt.setString(3, ContentEntryPicture.cepMd5)
      _stmt.setInt(4, ContentEntryPicture.cepFileSize)
      _stmt.setLong(5, ContentEntryPicture.cepTimestamp)
      _stmt.setString(6, ContentEntryPicture.cepMimeType)
      _stmt.setBoolean(7, ContentEntryPicture.cepActive)
      _stmt.setLong(8, ContentEntryPicture.cepUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateList(entityList: List<ContentEntryPicture>): Unit {
    val _sql =
        "UPDATE ContentEntryPicture SET cepContentEntryUid = ?, cepUri = ?, cepMd5 = ?, cepFileSize = ?, cepTimestamp = ?, cepMimeType = ?, cepActive = ? WHERE cepUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cepContentEntryUid)
        _stmt.setString(2, _entity.cepUri)
        _stmt.setString(3, _entity.cepMd5)
        _stmt.setInt(4, _entity.cepFileSize)
        _stmt.setLong(5, _entity.cepTimestamp)
        _stmt.setString(6, _entity.cepMimeType)
        _stmt.setBoolean(7, _entity.cepActive)
        _stmt.setLong(8, _entity.cepUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentEntryPicture): Unit {
    val _sql =
        "UPDATE ContentEntryPicture SET cepContentEntryUid = ?, cepUri = ?, cepMd5 = ?, cepFileSize = ?, cepTimestamp = ?, cepMimeType = ?, cepActive = ? WHERE cepUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cepContentEntryUid)
      _stmt.setString(2, entity.cepUri)
      _stmt.setString(3, entity.cepMd5)
      _stmt.setInt(4, entity.cepFileSize)
      _stmt.setLong(5, entity.cepTimestamp)
      _stmt.setString(6, entity.cepMimeType)
      _stmt.setBoolean(7, entity.cepActive)
      _stmt.setLong(8, entity.cepUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT cepUid AS cepPK,
    |                ? AS siteDestination
    |           FROM ContentEntryPicture
    |          WHERE ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = ?), -1) 
    |         /*psql ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |                SET cepPending = true
    |         */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT cepUid AS cepPK,
    |                ? AS siteDestination
    |           FROM ContentEntryPicture
    |          WHERE ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = ?), -1) 
    |          ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |                SET cepPending = true
    |                
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |  REPLACE INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT ContentEntryPicture.cepUid AS cepPk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntryPicture
    |                    ON ChangeLog.chTableId = 138
    |                       AND ChangeLog.chEntityPk = ContentEntryPicture.cepUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = UserSession.usClientNodeId), 0)     
    |        /*psql ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |            SET cepPending = true
    |         */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT ContentEntryPicture.cepUid AS cepPk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntryPicture
    |                    ON ChangeLog.chTableId = 138
    |                       AND ChangeLog.chEntityPk = ContentEntryPicture.cepUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = UserSession.usClientNodeId), 0)     
    |         ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |            SET cepPending = true
    |                        
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByContentEntryUidAsync(entryUid: Long): ContentEntryPicture? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ContentEntryPicture 
  |         WHERE cepContentEntryUid = ?
  |           AND cepActive
  |      ORDER BY cepTimestamp DESC 
  |         LIMIT 1
  |         
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,entryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cepUid = _result.getLong("cepUid")
        val _tmp_cepContentEntryUid = _result.getLong("cepContentEntryUid")
        val _tmp_cepUri = _result.getString("cepUri")
        val _tmp_cepMd5 = _result.getString("cepMd5")
        val _tmp_cepFileSize = _result.getInt("cepFileSize")
        val _tmp_cepTimestamp = _result.getLong("cepTimestamp")
        val _tmp_cepMimeType = _result.getString("cepMimeType")
        val _tmp_cepActive = _result.getBoolean("cepActive")
        ContentEntryPicture().apply {
          this.cepUid = _tmp_cepUid
          this.cepContentEntryUid = _tmp_cepContentEntryUid
          this.cepUri = _tmp_cepUri
          this.cepMd5 = _tmp_cepMd5
          this.cepFileSize = _tmp_cepFileSize
          this.cepTimestamp = _tmp_cepTimestamp
          this.cepMimeType = _tmp_cepMimeType
          this.cepActive = _tmp_cepActive
        }
      }
    }
  }

  public override fun findByContentEntryUidLive(entryUid: Long): LiveData<ContentEntryPicture?> =
      LiveDataImpl(_db, listOf("ContentEntryPicture"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         SELECT * 
    |          FROM ContentEntryPicture 
    |         WHERE cepContentEntryUid = ?
    |           AND cepActive
    |      ORDER BY cepTimestamp DESC 
    |         LIMIT 1
    |         
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,entryUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_cepUid = _result.getLong("cepUid")
          val _tmp_cepContentEntryUid = _result.getLong("cepContentEntryUid")
          val _tmp_cepUri = _result.getString("cepUri")
          val _tmp_cepMd5 = _result.getString("cepMd5")
          val _tmp_cepFileSize = _result.getInt("cepFileSize")
          val _tmp_cepTimestamp = _result.getLong("cepTimestamp")
          val _tmp_cepMimeType = _result.getString("cepMimeType")
          val _tmp_cepActive = _result.getBoolean("cepActive")
          ContentEntryPicture().apply {
            this.cepUid = _tmp_cepUid
            this.cepContentEntryUid = _tmp_cepContentEntryUid
            this.cepUri = _tmp_cepUri
            this.cepMd5 = _tmp_cepMd5
            this.cepFileSize = _tmp_cepFileSize
            this.cepTimestamp = _tmp_cepTimestamp
            this.cepMimeType = _tmp_cepMimeType
            this.cepActive = _tmp_cepActive
          }
        }
      }
    }
  }
}
