package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.CourseGroupMemberPerson
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CourseGroupMemberDao : BaseDao<CourseGroupMember> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun findByGroupSetAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson>

  public actual abstract suspend fun findByGroupSetOrderedAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson>

  public actual abstract suspend fun findByPersonUid(groupSetUid: Long, studentUid: Long):
      CourseGroupMember?

  public actual abstract suspend fun insertListAsync(entityList: List<CourseGroupMember>): Unit

  public actual abstract suspend fun updateListAsync(entityList: List<CourseGroupMember>): Unit
}
