package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.CommentsWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CommentsDao_JdbcKt(
  public val _db: RoomDatabase,
) : CommentsDao() {
  public val _insertAdapterComments_: EntityInsertionAdapter<Comments> = object :
      EntityInsertionAdapter<Comments>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Comments (commentsUid, commentsText, commentsEntityType, commentsEntityUid, commentsPublic, commentsStatus, commentsPersonUid, commentsToPersonUid, commentSubmitterUid, commentsFlagged, commentsInActive, commentsDateTimeAdded, commentsDateTimeUpdated, commentsMCSN, commentsLCSN, commentsLCB, commentsLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Comments): Unit {
      if(entity.commentsUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.commentsUid)
      }
      stmt.setString(2, entity.commentsText)
      stmt.setInt(3, entity.commentsEntityType)
      stmt.setLong(4, entity.commentsEntityUid)
      stmt.setBoolean(5, entity.commentsPublic)
      stmt.setInt(6, entity.commentsStatus)
      stmt.setLong(7, entity.commentsPersonUid)
      stmt.setLong(8, entity.commentsToPersonUid)
      stmt.setLong(9, entity.commentSubmitterUid)
      stmt.setBoolean(10, entity.commentsFlagged)
      stmt.setBoolean(11, entity.commentsInActive)
      stmt.setLong(12, entity.commentsDateTimeAdded)
      stmt.setLong(13, entity.commentsDateTimeUpdated)
      stmt.setLong(14, entity.commentsMCSN)
      stmt.setLong(15, entity.commentsLCSN)
      stmt.setInt(16, entity.commentsLCB)
      stmt.setLong(17, entity.commentsLct)
    }
  }

  public override fun insert(entity: Comments): Long {
    val _retVal = _insertAdapterComments_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Comments): Long {
    val _retVal = _insertAdapterComments_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Comments>): Unit {
    _insertAdapterComments_.insertList(entityList)
  }

  public override suspend fun insertListAsync(entityList: List<Comments>): Unit {
    _insertAdapterComments_.insertListAsync(entityList)
  }

  public override fun updateList(entityList: List<Comments>): Unit {
    val _sql =
        "UPDATE Comments SET commentsText = ?, commentsEntityType = ?, commentsEntityUid = ?, commentsPublic = ?, commentsStatus = ?, commentsPersonUid = ?, commentsToPersonUid = ?, commentSubmitterUid = ?, commentsFlagged = ?, commentsInActive = ?, commentsDateTimeAdded = ?, commentsDateTimeUpdated = ?, commentsMCSN = ?, commentsLCSN = ?, commentsLCB = ?, commentsLct = ? WHERE commentsUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.commentsText)
        _stmt.setInt(2, _entity.commentsEntityType)
        _stmt.setLong(3, _entity.commentsEntityUid)
        _stmt.setBoolean(4, _entity.commentsPublic)
        _stmt.setInt(5, _entity.commentsStatus)
        _stmt.setLong(6, _entity.commentsPersonUid)
        _stmt.setLong(7, _entity.commentsToPersonUid)
        _stmt.setLong(8, _entity.commentSubmitterUid)
        _stmt.setBoolean(9, _entity.commentsFlagged)
        _stmt.setBoolean(10, _entity.commentsInActive)
        _stmt.setLong(11, _entity.commentsDateTimeAdded)
        _stmt.setLong(12, _entity.commentsDateTimeUpdated)
        _stmt.setLong(13, _entity.commentsMCSN)
        _stmt.setLong(14, _entity.commentsLCSN)
        _stmt.setInt(15, _entity.commentsLCB)
        _stmt.setLong(16, _entity.commentsLct)
        _stmt.setLong(17, _entity.commentsUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Comments): Unit {
    val _sql =
        "UPDATE Comments SET commentsText = ?, commentsEntityType = ?, commentsEntityUid = ?, commentsPublic = ?, commentsStatus = ?, commentsPersonUid = ?, commentsToPersonUid = ?, commentSubmitterUid = ?, commentsFlagged = ?, commentsInActive = ?, commentsDateTimeAdded = ?, commentsDateTimeUpdated = ?, commentsMCSN = ?, commentsLCSN = ?, commentsLCB = ?, commentsLct = ? WHERE commentsUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.commentsText)
      _stmt.setInt(2, entity.commentsEntityType)
      _stmt.setLong(3, entity.commentsEntityUid)
      _stmt.setBoolean(4, entity.commentsPublic)
      _stmt.setInt(5, entity.commentsStatus)
      _stmt.setLong(6, entity.commentsPersonUid)
      _stmt.setLong(7, entity.commentsToPersonUid)
      _stmt.setLong(8, entity.commentSubmitterUid)
      _stmt.setBoolean(9, entity.commentsFlagged)
      _stmt.setBoolean(10, entity.commentsInActive)
      _stmt.setLong(11, entity.commentsDateTimeAdded)
      _stmt.setLong(12, entity.commentsDateTimeUpdated)
      _stmt.setLong(13, entity.commentsMCSN)
      _stmt.setLong(14, entity.commentsLCSN)
      _stmt.setInt(15, entity.commentsLCB)
      _stmt.setLong(16, entity.commentsLct)
      _stmt.setLong(17, entity.commentsUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateListAsync(entityList: List<Comments>): Unit {
    val _sql =
        "UPDATE Comments SET commentsText = ?, commentsEntityType = ?, commentsEntityUid = ?, commentsPublic = ?, commentsStatus = ?, commentsPersonUid = ?, commentsToPersonUid = ?, commentSubmitterUid = ?, commentsFlagged = ?, commentsInActive = ?, commentsDateTimeAdded = ?, commentsDateTimeUpdated = ?, commentsMCSN = ?, commentsLCSN = ?, commentsLCB = ?, commentsLct = ? WHERE commentsUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.commentsText)
        _stmt.setInt(2, _entity.commentsEntityType)
        _stmt.setLong(3, _entity.commentsEntityUid)
        _stmt.setBoolean(4, _entity.commentsPublic)
        _stmt.setInt(5, _entity.commentsStatus)
        _stmt.setLong(6, _entity.commentsPersonUid)
        _stmt.setLong(7, _entity.commentsToPersonUid)
        _stmt.setLong(8, _entity.commentSubmitterUid)
        _stmt.setBoolean(9, _entity.commentsFlagged)
        _stmt.setBoolean(10, _entity.commentsInActive)
        _stmt.setLong(11, _entity.commentsDateTimeAdded)
        _stmt.setLong(12, _entity.commentsDateTimeUpdated)
        _stmt.setLong(13, _entity.commentsMCSN)
        _stmt.setLong(14, _entity.commentsLCSN)
        _stmt.setInt(15, _entity.commentsLCB)
        _stmt.setLong(16, _entity.commentsLct)
        _stmt.setLong(17, _entity.commentsUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             ? AS commentsDestination
    |        FROM Comments
    |       WHERE Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = ?), 0) 
    |      /*psql ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |             SET commentsPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             ? AS commentsDestination
    |        FROM Comments
    |       WHERE Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = ?), 0) 
    |       ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |             SET commentsPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             UserSession.usClientNodeId AS commentsDestination
    |        FROM ChangeLog
    |             JOIN Comments
    |                 ON ChangeLog.chTableId = 208
    |                    AND ChangeLog.chEntityPk = Comments.commentsUid
    |             JOIN UserSession 
    |                  ON UserSession.usStatus = 1
    |       WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |         AND Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = UserSession.usClientNodeId), 0)
    |     /*psql ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |         SET commentsPending = true
    |      */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             UserSession.usClientNodeId AS commentsDestination
    |        FROM ChangeLog
    |             JOIN Comments
    |                 ON ChangeLog.chTableId = 208
    |                    AND ChangeLog.chEntityPk = Comments.commentsUid
    |             JOIN UserSession 
    |                  ON UserSession.usStatus = 1
    |       WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |         AND Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = UserSession.usClientNodeId), 0)
    |      ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |         SET commentsPending = true
    |                     
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUidAsync(uid: Long): Comments? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Comments WHERE commentsUid = ?  AND CAST(commentsInActive AS INTEGER) = 0"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_commentsUid = _result.getLong("commentsUid")
        val _tmp_commentsText = _result.getString("commentsText")
        val _tmp_commentsEntityType = _result.getInt("commentsEntityType")
        val _tmp_commentsEntityUid = _result.getLong("commentsEntityUid")
        val _tmp_commentsPublic = _result.getBoolean("commentsPublic")
        val _tmp_commentsStatus = _result.getInt("commentsStatus")
        val _tmp_commentsPersonUid = _result.getLong("commentsPersonUid")
        val _tmp_commentsToPersonUid = _result.getLong("commentsToPersonUid")
        val _tmp_commentSubmitterUid = _result.getLong("commentSubmitterUid")
        val _tmp_commentsFlagged = _result.getBoolean("commentsFlagged")
        val _tmp_commentsInActive = _result.getBoolean("commentsInActive")
        val _tmp_commentsDateTimeAdded = _result.getLong("commentsDateTimeAdded")
        val _tmp_commentsDateTimeUpdated = _result.getLong("commentsDateTimeUpdated")
        val _tmp_commentsMCSN = _result.getLong("commentsMCSN")
        val _tmp_commentsLCSN = _result.getLong("commentsLCSN")
        val _tmp_commentsLCB = _result.getInt("commentsLCB")
        val _tmp_commentsLct = _result.getLong("commentsLct")
        Comments().apply {
          this.commentsUid = _tmp_commentsUid
          this.commentsText = _tmp_commentsText
          this.commentsEntityType = _tmp_commentsEntityType
          this.commentsEntityUid = _tmp_commentsEntityUid
          this.commentsPublic = _tmp_commentsPublic
          this.commentsStatus = _tmp_commentsStatus
          this.commentsPersonUid = _tmp_commentsPersonUid
          this.commentsToPersonUid = _tmp_commentsToPersonUid
          this.commentSubmitterUid = _tmp_commentSubmitterUid
          this.commentsFlagged = _tmp_commentsFlagged
          this.commentsInActive = _tmp_commentsInActive
          this.commentsDateTimeAdded = _tmp_commentsDateTimeAdded
          this.commentsDateTimeUpdated = _tmp_commentsDateTimeUpdated
          this.commentsMCSN = _tmp_commentsMCSN
          this.commentsLCSN = _tmp_commentsLCSN
          this.commentsLCB = _tmp_commentsLCB
          this.commentsLct = _tmp_commentsLct
        }
      }
    }
  }

  public override fun findPublicByEntityTypeAndUidLive(entityType: Int, entityUid: Long):
      DataSourceFactory<Int, CommentsWithPerson> = object :
      DataSourceFactory<Int, CommentsWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<CommentsWithPerson>> =
        LiveDataImpl(_db, listOf("Comments", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Comments.*, Person.* 
      |          FROM Comments
      |                LEFT JOIN Person 
      |                ON Person.personUid = Comments.commentsPersonUid 
      |         WHERE Comments.commentsEntityType = ? 
      |           AND Comments.commentsEntityUid = ?
      |           AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |           AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |           AND CAST(Comments.commentsPublic AS INTEGER) = 1
      |      ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_commentsUid = _result.getLong("commentsUid")
            val _tmp_commentsText = _result.getString("commentsText")
            val _tmp_commentsEntityType = _result.getInt("commentsEntityType")
            val _tmp_commentsEntityUid = _result.getLong("commentsEntityUid")
            val _tmp_commentsPublic = _result.getBoolean("commentsPublic")
            val _tmp_commentsStatus = _result.getInt("commentsStatus")
            val _tmp_commentsPersonUid = _result.getLong("commentsPersonUid")
            val _tmp_commentsToPersonUid = _result.getLong("commentsToPersonUid")
            val _tmp_commentSubmitterUid = _result.getLong("commentSubmitterUid")
            val _tmp_commentsFlagged = _result.getBoolean("commentsFlagged")
            val _tmp_commentsInActive = _result.getBoolean("commentsInActive")
            val _tmp_commentsDateTimeAdded = _result.getLong("commentsDateTimeAdded")
            val _tmp_commentsDateTimeUpdated = _result.getLong("commentsDateTimeUpdated")
            val _tmp_commentsMCSN = _result.getLong("commentsMCSN")
            val _tmp_commentsLCSN = _result.getLong("commentsLCSN")
            val _tmp_commentsLCB = _result.getInt("commentsLCB")
            val _tmp_commentsLct = _result.getLong("commentsLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            CommentsWithPerson().apply {
              this.commentsUid = _tmp_commentsUid
              this.commentsText = _tmp_commentsText
              this.commentsEntityType = _tmp_commentsEntityType
              this.commentsEntityUid = _tmp_commentsEntityUid
              this.commentsPublic = _tmp_commentsPublic
              this.commentsStatus = _tmp_commentsStatus
              this.commentsPersonUid = _tmp_commentsPersonUid
              this.commentsToPersonUid = _tmp_commentsToPersonUid
              this.commentSubmitterUid = _tmp_commentSubmitterUid
              this.commentsFlagged = _tmp_commentsFlagged
              this.commentsInActive = _tmp_commentsInActive
              this.commentsDateTimeAdded = _tmp_commentsDateTimeAdded
              this.commentsDateTimeUpdated = _tmp_commentsDateTimeUpdated
              this.commentsMCSN = _tmp_commentsMCSN
              this.commentsLCSN = _tmp_commentsLCSN
              this.commentsLCB = _tmp_commentsLCB
              this.commentsLct = _tmp_commentsLct
              if(!_tmp_Person_isAllNull) {
                this.commentsPerson = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Comments", "Person"))
         {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Comments.*, Person.* 
      |          FROM Comments
      |                LEFT JOIN Person 
      |                ON Person.personUid = Comments.commentsPersonUid 
      |         WHERE Comments.commentsEntityType = ? 
      |           AND Comments.commentsEntityUid = ?
      |           AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |           AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |           AND CAST(Comments.commentsPublic AS INTEGER) = 1
      |      ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> = object :
      DataSourceFactory<Int, CommentsWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<CommentsWithPerson>> =
        LiveDataImpl(_db, listOf("Comments", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Comments.*, Person.* FROM Comments
      |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
      |        WHERE Comments.commentsEntityType = ? 
      |        AND Comments.commentsEntityUid = ?
      |        AND Comments.commentsPersonUid = ? OR Comments.commentsToPersonUid = ? 
      |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |        AND Person.personUid = ?
      |        ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,personUid)
        _stmt.setLong(4,personUid)
        _stmt.setLong(5,personUid)
        _stmt.setInt(6,_limit)
        _stmt.setInt(7,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_commentsUid = _result.getLong("commentsUid")
            val _tmp_commentsText = _result.getString("commentsText")
            val _tmp_commentsEntityType = _result.getInt("commentsEntityType")
            val _tmp_commentsEntityUid = _result.getLong("commentsEntityUid")
            val _tmp_commentsPublic = _result.getBoolean("commentsPublic")
            val _tmp_commentsStatus = _result.getInt("commentsStatus")
            val _tmp_commentsPersonUid = _result.getLong("commentsPersonUid")
            val _tmp_commentsToPersonUid = _result.getLong("commentsToPersonUid")
            val _tmp_commentSubmitterUid = _result.getLong("commentSubmitterUid")
            val _tmp_commentsFlagged = _result.getBoolean("commentsFlagged")
            val _tmp_commentsInActive = _result.getBoolean("commentsInActive")
            val _tmp_commentsDateTimeAdded = _result.getLong("commentsDateTimeAdded")
            val _tmp_commentsDateTimeUpdated = _result.getLong("commentsDateTimeUpdated")
            val _tmp_commentsMCSN = _result.getLong("commentsMCSN")
            val _tmp_commentsLCSN = _result.getLong("commentsLCSN")
            val _tmp_commentsLCB = _result.getInt("commentsLCB")
            val _tmp_commentsLct = _result.getLong("commentsLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            CommentsWithPerson().apply {
              this.commentsUid = _tmp_commentsUid
              this.commentsText = _tmp_commentsText
              this.commentsEntityType = _tmp_commentsEntityType
              this.commentsEntityUid = _tmp_commentsEntityUid
              this.commentsPublic = _tmp_commentsPublic
              this.commentsStatus = _tmp_commentsStatus
              this.commentsPersonUid = _tmp_commentsPersonUid
              this.commentsToPersonUid = _tmp_commentsToPersonUid
              this.commentSubmitterUid = _tmp_commentSubmitterUid
              this.commentsFlagged = _tmp_commentsFlagged
              this.commentsInActive = _tmp_commentsInActive
              this.commentsDateTimeAdded = _tmp_commentsDateTimeAdded
              this.commentsDateTimeUpdated = _tmp_commentsDateTimeUpdated
              this.commentsMCSN = _tmp_commentsMCSN
              this.commentsLCSN = _tmp_commentsLCSN
              this.commentsLCB = _tmp_commentsLCB
              this.commentsLct = _tmp_commentsLct
              if(!_tmp_Person_isAllNull) {
                this.commentsPerson = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Comments", "Person"))
         {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Comments.*, Person.* FROM Comments
      |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
      |        WHERE Comments.commentsEntityType = ? 
      |        AND Comments.commentsEntityUid = ?
      |        AND Comments.commentsPersonUid = ? OR Comments.commentsToPersonUid = ? 
      |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |        AND Person.personUid = ?
      |        ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,personUid)
        _stmt.setLong(4,personUid)
        _stmt.setLong(5,personUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive2(
    entityType: Int,
    entityUid: Long,
    submitterUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> = object :
      DataSourceFactory<Int, CommentsWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<CommentsWithPerson>> =
        LiveDataImpl(_db, listOf("Comments", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |            SELECT Comments.*, 
      |                   Person.* 
      |              FROM Comments
      |                   LEFT JOIN Person 
      |                   ON Person.personUid = Comments.commentsPersonUid
      |             WHERE Comments.commentsEntityType = ? 
      |               AND Comments.commentsEntityUid = ?
      |               AND Comments.commentSubmitterUid = ?  
      |               AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |               AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |               AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |          ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,submitterUid)
        _stmt.setInt(4,_limit)
        _stmt.setInt(5,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_commentsUid = _result.getLong("commentsUid")
            val _tmp_commentsText = _result.getString("commentsText")
            val _tmp_commentsEntityType = _result.getInt("commentsEntityType")
            val _tmp_commentsEntityUid = _result.getLong("commentsEntityUid")
            val _tmp_commentsPublic = _result.getBoolean("commentsPublic")
            val _tmp_commentsStatus = _result.getInt("commentsStatus")
            val _tmp_commentsPersonUid = _result.getLong("commentsPersonUid")
            val _tmp_commentsToPersonUid = _result.getLong("commentsToPersonUid")
            val _tmp_commentSubmitterUid = _result.getLong("commentSubmitterUid")
            val _tmp_commentsFlagged = _result.getBoolean("commentsFlagged")
            val _tmp_commentsInActive = _result.getBoolean("commentsInActive")
            val _tmp_commentsDateTimeAdded = _result.getLong("commentsDateTimeAdded")
            val _tmp_commentsDateTimeUpdated = _result.getLong("commentsDateTimeUpdated")
            val _tmp_commentsMCSN = _result.getLong("commentsMCSN")
            val _tmp_commentsLCSN = _result.getLong("commentsLCSN")
            val _tmp_commentsLCB = _result.getInt("commentsLCB")
            val _tmp_commentsLct = _result.getLong("commentsLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            CommentsWithPerson().apply {
              this.commentsUid = _tmp_commentsUid
              this.commentsText = _tmp_commentsText
              this.commentsEntityType = _tmp_commentsEntityType
              this.commentsEntityUid = _tmp_commentsEntityUid
              this.commentsPublic = _tmp_commentsPublic
              this.commentsStatus = _tmp_commentsStatus
              this.commentsPersonUid = _tmp_commentsPersonUid
              this.commentsToPersonUid = _tmp_commentsToPersonUid
              this.commentSubmitterUid = _tmp_commentSubmitterUid
              this.commentsFlagged = _tmp_commentsFlagged
              this.commentsInActive = _tmp_commentsInActive
              this.commentsDateTimeAdded = _tmp_commentsDateTimeAdded
              this.commentsDateTimeUpdated = _tmp_commentsDateTimeUpdated
              this.commentsMCSN = _tmp_commentsMCSN
              this.commentsLCSN = _tmp_commentsLCSN
              this.commentsLCB = _tmp_commentsLCB
              this.commentsLct = _tmp_commentsLct
              if(!_tmp_Person_isAllNull) {
                this.commentsPerson = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Comments", "Person"))
         {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |            SELECT Comments.*, 
      |                   Person.* 
      |              FROM Comments
      |                   LEFT JOIN Person 
      |                   ON Person.personUid = Comments.commentsPersonUid
      |             WHERE Comments.commentsEntityType = ? 
      |               AND Comments.commentsEntityUid = ?
      |               AND Comments.commentSubmitterUid = ?  
      |               AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |               AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |               AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |          ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,submitterUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findPrivateByEntityTypeAndUidAndPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> = object :
      DataSourceFactory<Int, CommentsWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<CommentsWithPerson>> =
        LiveDataImpl(_db, listOf("Comments", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Comments.*, Person.* FROM Comments
      |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
      |        WHERE Comments.commentsEntityType = ? 
      |        AND Comments.commentsEntityUid = ?
      |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |        AND Person.personUid = ?
      |        ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,personUid)
        _stmt.setInt(4,_limit)
        _stmt.setInt(5,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_commentsUid = _result.getLong("commentsUid")
            val _tmp_commentsText = _result.getString("commentsText")
            val _tmp_commentsEntityType = _result.getInt("commentsEntityType")
            val _tmp_commentsEntityUid = _result.getLong("commentsEntityUid")
            val _tmp_commentsPublic = _result.getBoolean("commentsPublic")
            val _tmp_commentsStatus = _result.getInt("commentsStatus")
            val _tmp_commentsPersonUid = _result.getLong("commentsPersonUid")
            val _tmp_commentsToPersonUid = _result.getLong("commentsToPersonUid")
            val _tmp_commentSubmitterUid = _result.getLong("commentSubmitterUid")
            val _tmp_commentsFlagged = _result.getBoolean("commentsFlagged")
            val _tmp_commentsInActive = _result.getBoolean("commentsInActive")
            val _tmp_commentsDateTimeAdded = _result.getLong("commentsDateTimeAdded")
            val _tmp_commentsDateTimeUpdated = _result.getLong("commentsDateTimeUpdated")
            val _tmp_commentsMCSN = _result.getLong("commentsMCSN")
            val _tmp_commentsLCSN = _result.getLong("commentsLCSN")
            val _tmp_commentsLCB = _result.getInt("commentsLCB")
            val _tmp_commentsLct = _result.getLong("commentsLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            CommentsWithPerson().apply {
              this.commentsUid = _tmp_commentsUid
              this.commentsText = _tmp_commentsText
              this.commentsEntityType = _tmp_commentsEntityType
              this.commentsEntityUid = _tmp_commentsEntityUid
              this.commentsPublic = _tmp_commentsPublic
              this.commentsStatus = _tmp_commentsStatus
              this.commentsPersonUid = _tmp_commentsPersonUid
              this.commentsToPersonUid = _tmp_commentsToPersonUid
              this.commentSubmitterUid = _tmp_commentSubmitterUid
              this.commentsFlagged = _tmp_commentsFlagged
              this.commentsInActive = _tmp_commentsInActive
              this.commentsDateTimeAdded = _tmp_commentsDateTimeAdded
              this.commentsDateTimeUpdated = _tmp_commentsDateTimeUpdated
              this.commentsMCSN = _tmp_commentsMCSN
              this.commentsLCSN = _tmp_commentsLCSN
              this.commentsLCB = _tmp_commentsLCB
              this.commentsLct = _tmp_commentsLct
              if(!_tmp_Person_isAllNull) {
                this.commentsPerson = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Comments", "Person"))
         {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Comments.*, Person.* FROM Comments
      |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
      |        WHERE Comments.commentsEntityType = ? 
      |        AND Comments.commentsEntityUid = ?
      |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |        AND Person.personUid = ?
      |        ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,personUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToLive(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> = object :
      DataSourceFactory<Int, CommentsWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<CommentsWithPerson>> =
        LiveDataImpl(_db, listOf("Comments", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Comments.*, Person.* FROM Comments
      |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
      |        WHERE Comments.commentsEntityType = ? 
      |        AND Comments.commentsEntityUid = ?
      |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |        AND (Comments.commentsToPersonUid = ? 
      |         OR Comments.commentsPersonUid = ?)
      |        ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,personFrom)
        _stmt.setLong(4,personFrom)
        _stmt.setInt(5,_limit)
        _stmt.setInt(6,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_commentsUid = _result.getLong("commentsUid")
            val _tmp_commentsText = _result.getString("commentsText")
            val _tmp_commentsEntityType = _result.getInt("commentsEntityType")
            val _tmp_commentsEntityUid = _result.getLong("commentsEntityUid")
            val _tmp_commentsPublic = _result.getBoolean("commentsPublic")
            val _tmp_commentsStatus = _result.getInt("commentsStatus")
            val _tmp_commentsPersonUid = _result.getLong("commentsPersonUid")
            val _tmp_commentsToPersonUid = _result.getLong("commentsToPersonUid")
            val _tmp_commentSubmitterUid = _result.getLong("commentSubmitterUid")
            val _tmp_commentsFlagged = _result.getBoolean("commentsFlagged")
            val _tmp_commentsInActive = _result.getBoolean("commentsInActive")
            val _tmp_commentsDateTimeAdded = _result.getLong("commentsDateTimeAdded")
            val _tmp_commentsDateTimeUpdated = _result.getLong("commentsDateTimeUpdated")
            val _tmp_commentsMCSN = _result.getLong("commentsMCSN")
            val _tmp_commentsLCSN = _result.getLong("commentsLCSN")
            val _tmp_commentsLCB = _result.getInt("commentsLCB")
            val _tmp_commentsLct = _result.getLong("commentsLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            CommentsWithPerson().apply {
              this.commentsUid = _tmp_commentsUid
              this.commentsText = _tmp_commentsText
              this.commentsEntityType = _tmp_commentsEntityType
              this.commentsEntityUid = _tmp_commentsEntityUid
              this.commentsPublic = _tmp_commentsPublic
              this.commentsStatus = _tmp_commentsStatus
              this.commentsPersonUid = _tmp_commentsPersonUid
              this.commentsToPersonUid = _tmp_commentsToPersonUid
              this.commentSubmitterUid = _tmp_commentSubmitterUid
              this.commentsFlagged = _tmp_commentsFlagged
              this.commentsInActive = _tmp_commentsInActive
              this.commentsDateTimeAdded = _tmp_commentsDateTimeAdded
              this.commentsDateTimeUpdated = _tmp_commentsDateTimeUpdated
              this.commentsMCSN = _tmp_commentsMCSN
              this.commentsLCSN = _tmp_commentsLCSN
              this.commentsLCB = _tmp_commentsLCB
              this.commentsLct = _tmp_commentsLct
              if(!_tmp_Person_isAllNull) {
                this.commentsPerson = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Comments", "Person"))
         {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Comments.*, Person.* FROM Comments
      |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
      |        WHERE Comments.commentsEntityType = ? 
      |        AND Comments.commentsEntityUid = ?
      |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
      |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
      |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
      |        AND (Comments.commentsToPersonUid = ? 
      |         OR Comments.commentsPersonUid = ?)
      |        ORDER BY Comments.commentsDateTimeAdded DESC 
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,entityType)
        _stmt.setLong(2,entityUid)
        _stmt.setLong(3,personFrom)
        _stmt.setLong(4,personFrom)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToTest(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
    personTo: Long,
  ): List<CommentsWithPerson> = _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT Comments.*, Person.* FROM Comments
  |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
  |        WHERE Comments.commentsEntityType = ? 
  |        AND Comments.commentsEntityUid = ?
  |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
  |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
  |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
  |        AND Comments.commentsPersonUid = ? 
  |        OR (? = 0 OR Comments.commentsToPersonUid = ?)
  |        ORDER BY Comments.commentsDateTimeAdded DESC 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setInt(1,entityType)
    _stmt.setLong(2,entityUid)
    _stmt.setLong(3,personFrom)
    _stmt.setLong(4,personTo)
    _stmt.setLong(5,personFrom)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_commentsUid = _result.getLong("commentsUid")
        val _tmp_commentsText = _result.getString("commentsText")
        val _tmp_commentsEntityType = _result.getInt("commentsEntityType")
        val _tmp_commentsEntityUid = _result.getLong("commentsEntityUid")
        val _tmp_commentsPublic = _result.getBoolean("commentsPublic")
        val _tmp_commentsStatus = _result.getInt("commentsStatus")
        val _tmp_commentsPersonUid = _result.getLong("commentsPersonUid")
        val _tmp_commentsToPersonUid = _result.getLong("commentsToPersonUid")
        val _tmp_commentSubmitterUid = _result.getLong("commentSubmitterUid")
        val _tmp_commentsFlagged = _result.getBoolean("commentsFlagged")
        val _tmp_commentsInActive = _result.getBoolean("commentsInActive")
        val _tmp_commentsDateTimeAdded = _result.getLong("commentsDateTimeAdded")
        val _tmp_commentsDateTimeUpdated = _result.getLong("commentsDateTimeUpdated")
        val _tmp_commentsMCSN = _result.getLong("commentsMCSN")
        val _tmp_commentsLCSN = _result.getLong("commentsLCSN")
        val _tmp_commentsLCB = _result.getInt("commentsLCB")
        val _tmp_commentsLct = _result.getLong("commentsLct")
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        CommentsWithPerson().apply {
          this.commentsUid = _tmp_commentsUid
          this.commentsText = _tmp_commentsText
          this.commentsEntityType = _tmp_commentsEntityType
          this.commentsEntityUid = _tmp_commentsEntityUid
          this.commentsPublic = _tmp_commentsPublic
          this.commentsStatus = _tmp_commentsStatus
          this.commentsPersonUid = _tmp_commentsPersonUid
          this.commentsToPersonUid = _tmp_commentsToPersonUid
          this.commentSubmitterUid = _tmp_commentSubmitterUid
          this.commentsFlagged = _tmp_commentsFlagged
          this.commentsInActive = _tmp_commentsInActive
          this.commentsDateTimeAdded = _tmp_commentsDateTimeAdded
          this.commentsDateTimeUpdated = _tmp_commentsDateTimeUpdated
          this.commentsMCSN = _tmp_commentsMCSN
          this.commentsLCSN = _tmp_commentsLCSN
          this.commentsLCB = _tmp_commentsLCB
          this.commentsLct = _tmp_commentsLct
          if(!_tmp_Person_isAllNull) {
            this.commentsPerson = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }
  }

  public override suspend fun updateInActiveByCommentUid(
    uid: Long,
    inActive: Boolean,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE Comments 
    |           SET commentsInActive = ?,
    |               commentsLct = ?
    |         WHERE Comments.commentsUid = ?
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,inActive)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,uid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
