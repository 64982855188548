package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentJob
import com.ustadmobile.lib.db.entities.ContentJobItem
import com.ustadmobile.lib.db.entities.ContentJobItemAndContentJob
import com.ustadmobile.lib.db.entities.ContentJobItemProgress
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentJobItemDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContentJobItemDao() {
  public val _insertAdapterContentJobItem_: EntityInsertionAdapter<ContentJobItem> = object :
      EntityInsertionAdapter<ContentJobItem>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ContentJobItem (cjiUid, cjiJobUid, sourceUri, cjiIsLeaf, cjiContentEntryUid, cjiParentContentEntryUid, cjiContainerUid, cjiItemProgress, cjiItemTotal, cjiRecursiveProgress, cjiRecursiveTotal, cjiStatus, cjiRecursiveStatus, cjiConnectivityNeeded, cjiPluginId, cjiAttemptCount, cjiParentCjiUid, cjiServerJobId, cjiStartTime, cjiFinishTime, cjiUploadSessionUid, cjiContentDeletedOnCancellation, cjiContainerProcessed) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentJobItem):
        Unit {
      if(entity.cjiUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cjiUid)
      }
      stmt.setLong(2, entity.cjiJobUid)
      stmt.setString(3, entity.sourceUri)
      stmt.setBoolean(4, entity.cjiIsLeaf)
      stmt.setLong(5, entity.cjiContentEntryUid)
      stmt.setLong(6, entity.cjiParentContentEntryUid)
      stmt.setLong(7, entity.cjiContainerUid)
      stmt.setLong(8, entity.cjiItemProgress)
      stmt.setLong(9, entity.cjiItemTotal)
      stmt.setLong(10, entity.cjiRecursiveProgress)
      stmt.setLong(11, entity.cjiRecursiveTotal)
      stmt.setInt(12, entity.cjiStatus)
      stmt.setInt(13, entity.cjiRecursiveStatus)
      stmt.setBoolean(14, entity.cjiConnectivityNeeded)
      stmt.setInt(15, entity.cjiPluginId)
      stmt.setInt(16, entity.cjiAttemptCount)
      stmt.setLong(17, entity.cjiParentCjiUid)
      stmt.setLong(18, entity.cjiServerJobId)
      stmt.setLong(19, entity.cjiStartTime)
      stmt.setLong(20, entity.cjiFinishTime)
      stmt.setString(21, entity.cjiUploadSessionUid)
      stmt.setBoolean(22, entity.cjiContentDeletedOnCancellation)
      stmt.setBoolean(23, entity.cjiContainerProcessed)
    }
  }

  public override suspend fun insertJobItem(jobItem: ContentJobItem): Long {
    val _retVal = _insertAdapterContentJobItem_.insertAndReturnIdAsync(jobItem)
    return _retVal
  }

  public override suspend fun insertJobItems(jobItems: List<ContentJobItem>): Unit {
    _insertAdapterContentJobItem_.insertListAsync(jobItems)
  }

  public override suspend fun findNextItemsInQueue(contentJobUid: Long, limit: Int):
      List<ContentJobItemAndContentJob> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        WITH ConnectivityStateCte(state) AS 
  |             (SELECT COALESCE(
  |                     (SELECT connectivityState 
  |                        FROM ConnectivityStatus 
  |                       LIMIT 1), 0))
  |                       
  |        SELECT ContentJobItem.*, ContentJob.*
  |          FROM ContentJobItem
  |               JOIN ContentJob
  |               ON ContentJobItem.cjiJobUid = ContentJob.cjUid
  |         WHERE ContentJobItem.cjiJobUid = ?
  |           AND (ContentJobItem.cjiStatus = 4 OR 
  |                ContentJobItem.cjiStatus = 5)
  |           AND (
  |                NOT cjiConnectivityNeeded 
  |                OR ((SELECT state FROM ConnectivityStateCte) = 4) 
  |                OR (cjIsMeteredAllowed 
  |                    AND (SELECT state FROM ConnectivityStateCte) = 3)
  |                )
  |         LIMIT ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentJobUid)
    _stmt.setInt(2,limit)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        var _tmp_ContentJobItem_nullCount = 0
        val _tmp_cjiUid = _result.getLong("cjiUid")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiJobUid = _result.getLong("cjiJobUid")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_sourceUri = _result.getString("sourceUri")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiIsLeaf = _result.getBoolean("cjiIsLeaf")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiContentEntryUid = _result.getLong("cjiContentEntryUid")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiParentContentEntryUid = _result.getLong("cjiParentContentEntryUid")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiContainerUid = _result.getLong("cjiContainerUid")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiItemProgress = _result.getLong("cjiItemProgress")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiItemTotal = _result.getLong("cjiItemTotal")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiRecursiveProgress = _result.getLong("cjiRecursiveProgress")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiRecursiveTotal = _result.getLong("cjiRecursiveTotal")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiStatus = _result.getInt("cjiStatus")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiRecursiveStatus = _result.getInt("cjiRecursiveStatus")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiConnectivityNeeded = _result.getBoolean("cjiConnectivityNeeded")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiPluginId = _result.getInt("cjiPluginId")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiAttemptCount = _result.getInt("cjiAttemptCount")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiParentCjiUid = _result.getLong("cjiParentCjiUid")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiServerJobId = _result.getLong("cjiServerJobId")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiStartTime = _result.getLong("cjiStartTime")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiFinishTime = _result.getLong("cjiFinishTime")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiUploadSessionUid = _result.getString("cjiUploadSessionUid")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiContentDeletedOnCancellation =
            _result.getBoolean("cjiContentDeletedOnCancellation")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_cjiContainerProcessed = _result.getBoolean("cjiContainerProcessed")
        if(_result.wasNull()) _tmp_ContentJobItem_nullCount++
        val _tmp_ContentJobItem_isAllNull = _tmp_ContentJobItem_nullCount == 23
        var _tmp_ContentJob_nullCount = 0
        val _tmp_cjUid = _result.getLong("cjUid")
        if(_result.wasNull()) _tmp_ContentJob_nullCount++
        val _tmp_toUri = _result.getString("toUri")
        if(_result.wasNull()) _tmp_ContentJob_nullCount++
        val _tmp_cjProgress = _result.getLong("cjProgress")
        if(_result.wasNull()) _tmp_ContentJob_nullCount++
        val _tmp_cjTotal = _result.getLong("cjTotal")
        if(_result.wasNull()) _tmp_ContentJob_nullCount++
        val _tmp_cjNotificationTitle = _result.getString("cjNotificationTitle")
        if(_result.wasNull()) _tmp_ContentJob_nullCount++
        val _tmp_cjIsMeteredAllowed = _result.getBoolean("cjIsMeteredAllowed")
        if(_result.wasNull()) _tmp_ContentJob_nullCount++
        val _tmp_params = _result.getString("params")
        if(_result.wasNull()) _tmp_ContentJob_nullCount++
        val _tmp_ContentJob_isAllNull = _tmp_ContentJob_nullCount == 7
        ContentJobItemAndContentJob().apply {
          if(!_tmp_ContentJobItem_isAllNull) {
            this.contentJobItem = ContentJobItem().apply {
              this.cjiUid = _tmp_cjiUid
              this.cjiJobUid = _tmp_cjiJobUid
              this.sourceUri = _tmp_sourceUri
              this.cjiIsLeaf = _tmp_cjiIsLeaf
              this.cjiContentEntryUid = _tmp_cjiContentEntryUid
              this.cjiParentContentEntryUid = _tmp_cjiParentContentEntryUid
              this.cjiContainerUid = _tmp_cjiContainerUid
              this.cjiItemProgress = _tmp_cjiItemProgress
              this.cjiItemTotal = _tmp_cjiItemTotal
              this.cjiRecursiveProgress = _tmp_cjiRecursiveProgress
              this.cjiRecursiveTotal = _tmp_cjiRecursiveTotal
              this.cjiStatus = _tmp_cjiStatus
              this.cjiRecursiveStatus = _tmp_cjiRecursiveStatus
              this.cjiConnectivityNeeded = _tmp_cjiConnectivityNeeded
              this.cjiPluginId = _tmp_cjiPluginId
              this.cjiAttemptCount = _tmp_cjiAttemptCount
              this.cjiParentCjiUid = _tmp_cjiParentCjiUid
              this.cjiServerJobId = _tmp_cjiServerJobId
              this.cjiStartTime = _tmp_cjiStartTime
              this.cjiFinishTime = _tmp_cjiFinishTime
              this.cjiUploadSessionUid = _tmp_cjiUploadSessionUid
              this.cjiContentDeletedOnCancellation = _tmp_cjiContentDeletedOnCancellation
              this.cjiContainerProcessed = _tmp_cjiContainerProcessed
            }
          }
          if(!_tmp_ContentJob_isAllNull) {
            this.contentJob = ContentJob().apply {
              this.cjUid = _tmp_cjUid
              this.toUri = _tmp_toUri
              this.cjProgress = _tmp_cjProgress
              this.cjTotal = _tmp_cjTotal
              this.cjNotificationTitle = _tmp_cjNotificationTitle
              this.cjIsMeteredAllowed = _tmp_cjIsMeteredAllowed
              this.params = _tmp_params
            }
          }
        }
      }
    }
  }

  public override suspend fun findActiveContentJobItems(contentEntryUid: Long):
      List<ContentJobItemProgress> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT cjiRecursiveProgress AS progress, 
  |               cjiRecursiveTotal AS total, 
  |               cjNotificationTitle as progressTitle,
  |               ContentJobItem.cjiUid
  |          FROM ContentJobItem
  |          JOIN ContentJob
  |            ON ContentJob.cjUid = ContentJobItem.cjiJobUid
  |         WHERE cjiContentEntryUid = ?
  |           AND cjiRecursiveStatus >= 4
  |           AND cjiRecursiveStatus <= 20
  |      ORDER BY cjiStartTime DESC
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_progress = _result.getInt("progress")
        val _tmp_total = _result.getInt("total")
        val _tmp_progressTitle = _result.getString("progressTitle")
        val _tmp_cjiUid = _result.getLong("cjiUid")
        ContentJobItemProgress().apply {
          this.progress = _tmp_progress
          this.total = _tmp_total
          this.progressTitle = _tmp_progressTitle
          this.cjiUid = _tmp_cjiUid
        }
      }
    }
  }

  public override suspend fun updateItemStatus(cjiUid: Long, status: Int): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem 
    |           SET cjiStatus = ?
    |         WHERE cjiUid= ?  
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,status)
      _stmt.setLong(2,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun isJobDone(jobUid: Long): Boolean =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT NOT EXISTS(
  |               SELECT cjiUid 
  |                 FROM ContentJobItem
  |                WHERE cjiJobUid = ?
  |                  AND cjiStatus < 21) 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,jobUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override fun findRootJobItemByJobId(jobUid: Long): ContentJobItem? =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ContentJobItem
  |         WHERE cjiJobUid = ? 
  |           AND cjiParentCjiUid = 0 
  |         LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,jobUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cjiUid = _result.getLong("cjiUid")
        val _tmp_cjiJobUid = _result.getLong("cjiJobUid")
        val _tmp_sourceUri = _result.getString("sourceUri")
        val _tmp_cjiIsLeaf = _result.getBoolean("cjiIsLeaf")
        val _tmp_cjiContentEntryUid = _result.getLong("cjiContentEntryUid")
        val _tmp_cjiParentContentEntryUid = _result.getLong("cjiParentContentEntryUid")
        val _tmp_cjiContainerUid = _result.getLong("cjiContainerUid")
        val _tmp_cjiItemProgress = _result.getLong("cjiItemProgress")
        val _tmp_cjiItemTotal = _result.getLong("cjiItemTotal")
        val _tmp_cjiRecursiveProgress = _result.getLong("cjiRecursiveProgress")
        val _tmp_cjiRecursiveTotal = _result.getLong("cjiRecursiveTotal")
        val _tmp_cjiStatus = _result.getInt("cjiStatus")
        val _tmp_cjiRecursiveStatus = _result.getInt("cjiRecursiveStatus")
        val _tmp_cjiConnectivityNeeded = _result.getBoolean("cjiConnectivityNeeded")
        val _tmp_cjiPluginId = _result.getInt("cjiPluginId")
        val _tmp_cjiAttemptCount = _result.getInt("cjiAttemptCount")
        val _tmp_cjiParentCjiUid = _result.getLong("cjiParentCjiUid")
        val _tmp_cjiServerJobId = _result.getLong("cjiServerJobId")
        val _tmp_cjiStartTime = _result.getLong("cjiStartTime")
        val _tmp_cjiFinishTime = _result.getLong("cjiFinishTime")
        val _tmp_cjiUploadSessionUid = _result.getString("cjiUploadSessionUid")
        val _tmp_cjiContentDeletedOnCancellation =
            _result.getBoolean("cjiContentDeletedOnCancellation")
        val _tmp_cjiContainerProcessed = _result.getBoolean("cjiContainerProcessed")
        ContentJobItem().apply {
          this.cjiUid = _tmp_cjiUid
          this.cjiJobUid = _tmp_cjiJobUid
          this.sourceUri = _tmp_sourceUri
          this.cjiIsLeaf = _tmp_cjiIsLeaf
          this.cjiContentEntryUid = _tmp_cjiContentEntryUid
          this.cjiParentContentEntryUid = _tmp_cjiParentContentEntryUid
          this.cjiContainerUid = _tmp_cjiContainerUid
          this.cjiItemProgress = _tmp_cjiItemProgress
          this.cjiItemTotal = _tmp_cjiItemTotal
          this.cjiRecursiveProgress = _tmp_cjiRecursiveProgress
          this.cjiRecursiveTotal = _tmp_cjiRecursiveTotal
          this.cjiStatus = _tmp_cjiStatus
          this.cjiRecursiveStatus = _tmp_cjiRecursiveStatus
          this.cjiConnectivityNeeded = _tmp_cjiConnectivityNeeded
          this.cjiPluginId = _tmp_cjiPluginId
          this.cjiAttemptCount = _tmp_cjiAttemptCount
          this.cjiParentCjiUid = _tmp_cjiParentCjiUid
          this.cjiServerJobId = _tmp_cjiServerJobId
          this.cjiStartTime = _tmp_cjiStartTime
          this.cjiFinishTime = _tmp_cjiFinishTime
          this.cjiUploadSessionUid = _tmp_cjiUploadSessionUid
          this.cjiContentDeletedOnCancellation = _tmp_cjiContentDeletedOnCancellation
          this.cjiContainerProcessed = _tmp_cjiContainerProcessed
        }
      }
    }
  }

  public override suspend fun updateItemProgress(
    cjiUid: Long,
    cjiProgress: Long,
    cjiTotal: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiItemProgress = ?,
    |               cjiItemTotal = ?
    |         WHERE cjiUid = ?     
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,cjiProgress)
      _stmt.setLong(2,cjiTotal)
      _stmt.setLong(3,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateConnectivityNeeded(contentJobItemId: Long,
      connectivityNeeded: Boolean): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiConnectivityNeeded = ?
    |         WHERE cjiUid = ?     
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,connectivityNeeded)
      _stmt.setLong(2,contentJobItemId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateContainerProcessed(contentJobItemId: Long,
      cjiContainerProcessed: Boolean): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiContainerProcessed = ?
    |         WHERE cjiUid = ?   
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,cjiContainerProcessed)
      _stmt.setLong(2,contentJobItemId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateJobItemAttemptCountAndStatus(
    cjiUid: Long,
    attemptCount: Int,
    status: Int,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiStatus = ?,
    |               cjiAttemptCount = ?
    |         WHERE cjiUid = ?      
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,status)
      _stmt.setInt(2,attemptCount)
      _stmt.setLong(3,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateStartTimeForJob(cjiUid: Long, startTime: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiStartTime = ?
    |         WHERE cjiUid = ?      
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,startTime)
      _stmt.setLong(2,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateFinishTimeForJob(cjiUid: Long, finishTime: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiFinishTime = ?
    |         WHERE cjiUid = ?      
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,finishTime)
      _stmt.setLong(2,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateContentEntryUid(cjiUid: Long, contentEntryUid: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobITem
    |           SET cjiContentEntryUid = ?
    |         WHERE cjiUid = ?  
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,contentEntryUid)
      _stmt.setLong(2,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateContentJobItemContainer(cjiUid: Long, containerUid: Long):
      Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiContainerUid = ?
    |         WHERE cjiUid = ?  
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,containerUid)
      _stmt.setLong(2,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findAll(): List<ContentJobItem> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ContentJobItem
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cjiUid = _result.getLong("cjiUid")
        val _tmp_cjiJobUid = _result.getLong("cjiJobUid")
        val _tmp_sourceUri = _result.getString("sourceUri")
        val _tmp_cjiIsLeaf = _result.getBoolean("cjiIsLeaf")
        val _tmp_cjiContentEntryUid = _result.getLong("cjiContentEntryUid")
        val _tmp_cjiParentContentEntryUid = _result.getLong("cjiParentContentEntryUid")
        val _tmp_cjiContainerUid = _result.getLong("cjiContainerUid")
        val _tmp_cjiItemProgress = _result.getLong("cjiItemProgress")
        val _tmp_cjiItemTotal = _result.getLong("cjiItemTotal")
        val _tmp_cjiRecursiveProgress = _result.getLong("cjiRecursiveProgress")
        val _tmp_cjiRecursiveTotal = _result.getLong("cjiRecursiveTotal")
        val _tmp_cjiStatus = _result.getInt("cjiStatus")
        val _tmp_cjiRecursiveStatus = _result.getInt("cjiRecursiveStatus")
        val _tmp_cjiConnectivityNeeded = _result.getBoolean("cjiConnectivityNeeded")
        val _tmp_cjiPluginId = _result.getInt("cjiPluginId")
        val _tmp_cjiAttemptCount = _result.getInt("cjiAttemptCount")
        val _tmp_cjiParentCjiUid = _result.getLong("cjiParentCjiUid")
        val _tmp_cjiServerJobId = _result.getLong("cjiServerJobId")
        val _tmp_cjiStartTime = _result.getLong("cjiStartTime")
        val _tmp_cjiFinishTime = _result.getLong("cjiFinishTime")
        val _tmp_cjiUploadSessionUid = _result.getString("cjiUploadSessionUid")
        val _tmp_cjiContentDeletedOnCancellation =
            _result.getBoolean("cjiContentDeletedOnCancellation")
        val _tmp_cjiContainerProcessed = _result.getBoolean("cjiContainerProcessed")
        ContentJobItem().apply {
          this.cjiUid = _tmp_cjiUid
          this.cjiJobUid = _tmp_cjiJobUid
          this.sourceUri = _tmp_sourceUri
          this.cjiIsLeaf = _tmp_cjiIsLeaf
          this.cjiContentEntryUid = _tmp_cjiContentEntryUid
          this.cjiParentContentEntryUid = _tmp_cjiParentContentEntryUid
          this.cjiContainerUid = _tmp_cjiContainerUid
          this.cjiItemProgress = _tmp_cjiItemProgress
          this.cjiItemTotal = _tmp_cjiItemTotal
          this.cjiRecursiveProgress = _tmp_cjiRecursiveProgress
          this.cjiRecursiveTotal = _tmp_cjiRecursiveTotal
          this.cjiStatus = _tmp_cjiStatus
          this.cjiRecursiveStatus = _tmp_cjiRecursiveStatus
          this.cjiConnectivityNeeded = _tmp_cjiConnectivityNeeded
          this.cjiPluginId = _tmp_cjiPluginId
          this.cjiAttemptCount = _tmp_cjiAttemptCount
          this.cjiParentCjiUid = _tmp_cjiParentCjiUid
          this.cjiServerJobId = _tmp_cjiServerJobId
          this.cjiStartTime = _tmp_cjiStartTime
          this.cjiFinishTime = _tmp_cjiFinishTime
          this.cjiUploadSessionUid = _tmp_cjiUploadSessionUid
          this.cjiContentDeletedOnCancellation = _tmp_cjiContentDeletedOnCancellation
          this.cjiContainerProcessed = _tmp_cjiContainerProcessed
        }
      }
    }
  }

  public override suspend fun findByUidAsync(cjiUid: Long): ContentJobItem? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentJobItem.*
  |          FROM ContentJobItem
  |         WHERE cjiUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,cjiUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cjiUid = _result.getLong("cjiUid")
        val _tmp_cjiJobUid = _result.getLong("cjiJobUid")
        val _tmp_sourceUri = _result.getString("sourceUri")
        val _tmp_cjiIsLeaf = _result.getBoolean("cjiIsLeaf")
        val _tmp_cjiContentEntryUid = _result.getLong("cjiContentEntryUid")
        val _tmp_cjiParentContentEntryUid = _result.getLong("cjiParentContentEntryUid")
        val _tmp_cjiContainerUid = _result.getLong("cjiContainerUid")
        val _tmp_cjiItemProgress = _result.getLong("cjiItemProgress")
        val _tmp_cjiItemTotal = _result.getLong("cjiItemTotal")
        val _tmp_cjiRecursiveProgress = _result.getLong("cjiRecursiveProgress")
        val _tmp_cjiRecursiveTotal = _result.getLong("cjiRecursiveTotal")
        val _tmp_cjiStatus = _result.getInt("cjiStatus")
        val _tmp_cjiRecursiveStatus = _result.getInt("cjiRecursiveStatus")
        val _tmp_cjiConnectivityNeeded = _result.getBoolean("cjiConnectivityNeeded")
        val _tmp_cjiPluginId = _result.getInt("cjiPluginId")
        val _tmp_cjiAttemptCount = _result.getInt("cjiAttemptCount")
        val _tmp_cjiParentCjiUid = _result.getLong("cjiParentCjiUid")
        val _tmp_cjiServerJobId = _result.getLong("cjiServerJobId")
        val _tmp_cjiStartTime = _result.getLong("cjiStartTime")
        val _tmp_cjiFinishTime = _result.getLong("cjiFinishTime")
        val _tmp_cjiUploadSessionUid = _result.getString("cjiUploadSessionUid")
        val _tmp_cjiContentDeletedOnCancellation =
            _result.getBoolean("cjiContentDeletedOnCancellation")
        val _tmp_cjiContainerProcessed = _result.getBoolean("cjiContainerProcessed")
        ContentJobItem().apply {
          this.cjiUid = _tmp_cjiUid
          this.cjiJobUid = _tmp_cjiJobUid
          this.sourceUri = _tmp_sourceUri
          this.cjiIsLeaf = _tmp_cjiIsLeaf
          this.cjiContentEntryUid = _tmp_cjiContentEntryUid
          this.cjiParentContentEntryUid = _tmp_cjiParentContentEntryUid
          this.cjiContainerUid = _tmp_cjiContainerUid
          this.cjiItemProgress = _tmp_cjiItemProgress
          this.cjiItemTotal = _tmp_cjiItemTotal
          this.cjiRecursiveProgress = _tmp_cjiRecursiveProgress
          this.cjiRecursiveTotal = _tmp_cjiRecursiveTotal
          this.cjiStatus = _tmp_cjiStatus
          this.cjiRecursiveStatus = _tmp_cjiRecursiveStatus
          this.cjiConnectivityNeeded = _tmp_cjiConnectivityNeeded
          this.cjiPluginId = _tmp_cjiPluginId
          this.cjiAttemptCount = _tmp_cjiAttemptCount
          this.cjiParentCjiUid = _tmp_cjiParentCjiUid
          this.cjiServerJobId = _tmp_cjiServerJobId
          this.cjiStartTime = _tmp_cjiStartTime
          this.cjiFinishTime = _tmp_cjiFinishTime
          this.cjiUploadSessionUid = _tmp_cjiUploadSessionUid
          this.cjiContentDeletedOnCancellation = _tmp_cjiContentDeletedOnCancellation
          this.cjiContainerProcessed = _tmp_cjiContainerProcessed
        }
      }
    }
  }

  public override suspend fun getActiveContentJobIdByContentEntryUid(contentEntryUid: Long): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT COALESCE(
  |               (SELECT ContentJobItem.cjiJobUid
  |                  FROM ContentJobItem
  |                 WHERE cjiContentEntryUid = ?
  |                   AND cjiStatus BETWEEN 4 AND 20
  |              ORDER BY cjiFinishTime DESC), 0)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun updateUploadSessionUuid(cjiUid: Long, uploadSessionUuid: String):
      Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiUploadSessionUid = ?
    |         WHERE cjiUid = ?  
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setString(1,uploadSessionUuid)
      _stmt.setLong(2,cjiUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllByJobId(jobId: Long): List<ContentJobItem> =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ContentJobItem
  |         WHERE cjiJobUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,jobId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cjiUid = _result.getLong("cjiUid")
        val _tmp_cjiJobUid = _result.getLong("cjiJobUid")
        val _tmp_sourceUri = _result.getString("sourceUri")
        val _tmp_cjiIsLeaf = _result.getBoolean("cjiIsLeaf")
        val _tmp_cjiContentEntryUid = _result.getLong("cjiContentEntryUid")
        val _tmp_cjiParentContentEntryUid = _result.getLong("cjiParentContentEntryUid")
        val _tmp_cjiContainerUid = _result.getLong("cjiContainerUid")
        val _tmp_cjiItemProgress = _result.getLong("cjiItemProgress")
        val _tmp_cjiItemTotal = _result.getLong("cjiItemTotal")
        val _tmp_cjiRecursiveProgress = _result.getLong("cjiRecursiveProgress")
        val _tmp_cjiRecursiveTotal = _result.getLong("cjiRecursiveTotal")
        val _tmp_cjiStatus = _result.getInt("cjiStatus")
        val _tmp_cjiRecursiveStatus = _result.getInt("cjiRecursiveStatus")
        val _tmp_cjiConnectivityNeeded = _result.getBoolean("cjiConnectivityNeeded")
        val _tmp_cjiPluginId = _result.getInt("cjiPluginId")
        val _tmp_cjiAttemptCount = _result.getInt("cjiAttemptCount")
        val _tmp_cjiParentCjiUid = _result.getLong("cjiParentCjiUid")
        val _tmp_cjiServerJobId = _result.getLong("cjiServerJobId")
        val _tmp_cjiStartTime = _result.getLong("cjiStartTime")
        val _tmp_cjiFinishTime = _result.getLong("cjiFinishTime")
        val _tmp_cjiUploadSessionUid = _result.getString("cjiUploadSessionUid")
        val _tmp_cjiContentDeletedOnCancellation =
            _result.getBoolean("cjiContentDeletedOnCancellation")
        val _tmp_cjiContainerProcessed = _result.getBoolean("cjiContainerProcessed")
        ContentJobItem().apply {
          this.cjiUid = _tmp_cjiUid
          this.cjiJobUid = _tmp_cjiJobUid
          this.sourceUri = _tmp_sourceUri
          this.cjiIsLeaf = _tmp_cjiIsLeaf
          this.cjiContentEntryUid = _tmp_cjiContentEntryUid
          this.cjiParentContentEntryUid = _tmp_cjiParentContentEntryUid
          this.cjiContainerUid = _tmp_cjiContainerUid
          this.cjiItemProgress = _tmp_cjiItemProgress
          this.cjiItemTotal = _tmp_cjiItemTotal
          this.cjiRecursiveProgress = _tmp_cjiRecursiveProgress
          this.cjiRecursiveTotal = _tmp_cjiRecursiveTotal
          this.cjiStatus = _tmp_cjiStatus
          this.cjiRecursiveStatus = _tmp_cjiRecursiveStatus
          this.cjiConnectivityNeeded = _tmp_cjiConnectivityNeeded
          this.cjiPluginId = _tmp_cjiPluginId
          this.cjiAttemptCount = _tmp_cjiAttemptCount
          this.cjiParentCjiUid = _tmp_cjiParentCjiUid
          this.cjiServerJobId = _tmp_cjiServerJobId
          this.cjiStartTime = _tmp_cjiStartTime
          this.cjiFinishTime = _tmp_cjiFinishTime
          this.cjiUploadSessionUid = _tmp_cjiUploadSessionUid
          this.cjiContentDeletedOnCancellation = _tmp_cjiContentDeletedOnCancellation
          this.cjiContainerProcessed = _tmp_cjiContainerProcessed
        }
      }
    }
  }

  public override fun getJobItemByUidLive(uid: Long): LiveData<ContentJobItem?> = LiveDataImpl(_db,
      listOf("ContentJobItem"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT *
    |          FROM ContentJobItem
    |         WHERE cjiUid = ?   
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_cjiUid = _result.getLong("cjiUid")
          val _tmp_cjiJobUid = _result.getLong("cjiJobUid")
          val _tmp_sourceUri = _result.getString("sourceUri")
          val _tmp_cjiIsLeaf = _result.getBoolean("cjiIsLeaf")
          val _tmp_cjiContentEntryUid = _result.getLong("cjiContentEntryUid")
          val _tmp_cjiParentContentEntryUid = _result.getLong("cjiParentContentEntryUid")
          val _tmp_cjiContainerUid = _result.getLong("cjiContainerUid")
          val _tmp_cjiItemProgress = _result.getLong("cjiItemProgress")
          val _tmp_cjiItemTotal = _result.getLong("cjiItemTotal")
          val _tmp_cjiRecursiveProgress = _result.getLong("cjiRecursiveProgress")
          val _tmp_cjiRecursiveTotal = _result.getLong("cjiRecursiveTotal")
          val _tmp_cjiStatus = _result.getInt("cjiStatus")
          val _tmp_cjiRecursiveStatus = _result.getInt("cjiRecursiveStatus")
          val _tmp_cjiConnectivityNeeded = _result.getBoolean("cjiConnectivityNeeded")
          val _tmp_cjiPluginId = _result.getInt("cjiPluginId")
          val _tmp_cjiAttemptCount = _result.getInt("cjiAttemptCount")
          val _tmp_cjiParentCjiUid = _result.getLong("cjiParentCjiUid")
          val _tmp_cjiServerJobId = _result.getLong("cjiServerJobId")
          val _tmp_cjiStartTime = _result.getLong("cjiStartTime")
          val _tmp_cjiFinishTime = _result.getLong("cjiFinishTime")
          val _tmp_cjiUploadSessionUid = _result.getString("cjiUploadSessionUid")
          val _tmp_cjiContentDeletedOnCancellation =
              _result.getBoolean("cjiContentDeletedOnCancellation")
          val _tmp_cjiContainerProcessed = _result.getBoolean("cjiContainerProcessed")
          ContentJobItem().apply {
            this.cjiUid = _tmp_cjiUid
            this.cjiJobUid = _tmp_cjiJobUid
            this.sourceUri = _tmp_sourceUri
            this.cjiIsLeaf = _tmp_cjiIsLeaf
            this.cjiContentEntryUid = _tmp_cjiContentEntryUid
            this.cjiParentContentEntryUid = _tmp_cjiParentContentEntryUid
            this.cjiContainerUid = _tmp_cjiContainerUid
            this.cjiItemProgress = _tmp_cjiItemProgress
            this.cjiItemTotal = _tmp_cjiItemTotal
            this.cjiRecursiveProgress = _tmp_cjiRecursiveProgress
            this.cjiRecursiveTotal = _tmp_cjiRecursiveTotal
            this.cjiStatus = _tmp_cjiStatus
            this.cjiRecursiveStatus = _tmp_cjiRecursiveStatus
            this.cjiConnectivityNeeded = _tmp_cjiConnectivityNeeded
            this.cjiPluginId = _tmp_cjiPluginId
            this.cjiAttemptCount = _tmp_cjiAttemptCount
            this.cjiParentCjiUid = _tmp_cjiParentCjiUid
            this.cjiServerJobId = _tmp_cjiServerJobId
            this.cjiStartTime = _tmp_cjiStartTime
            this.cjiFinishTime = _tmp_cjiFinishTime
            this.cjiUploadSessionUid = _tmp_cjiUploadSessionUid
            this.cjiContentDeletedOnCancellation = _tmp_cjiContentDeletedOnCancellation
            this.cjiContainerProcessed = _tmp_cjiContainerProcessed
          }
        }
      }
    }
  }

  public override suspend fun getContainerUidByJobItemUid(uid: Long): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT cjiContainerUid
  |          FROM ContentJobItem
  |         WHERE cjiUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun updateAllStatusesByJobUid(jobUid: Long, newStatus: Int): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiStatus = ?
    |         WHERE cjiJobUid = ?
    |           AND cjiStatus != ?
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,newStatus)
      _stmt.setLong(2,jobUid)
      _stmt.setInt(3,newStatus)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findAllContainersByJobUid(jobUid: Long): List<Long> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentJobItem.cjiContainerUid
  |          FROM ContentJobItem
  |         WHERE cjiJobUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,jobUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }
}
