package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithCourseBlock
import com.ustadmobile.lib.db.entities.PersonGroupAssignmentSummary
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzAssignmentDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ClazzAssignmentDao,
) : ClazzAssignmentDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun getAllAssignmentsByClazzUidAsync(clazzUid: Long):
      List<ClazzAssignment> = _dao.getAllAssignmentsByClazzUidAsync(clazzUid)

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  public override fun getProgressSummaryForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
  ): LiveData<AssignmentProgressSummary?> = _dao.getProgressSummaryForAssignment(assignmentUid,
      clazzUid, group)

  public override fun getSubmitterListForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
    searchText: String,
  ): DataSourceFactory<Int, PersonGroupAssignmentSummary> =
      _dao.getSubmitterListForAssignment(assignmentUid, clazzUid, group, searchText)

  public override suspend fun getSubmitterUid(assignmentUid: Long, personUid: Long): Long =
      _dao.getSubmitterUid(assignmentUid, personUid)

  public override suspend fun updateAsync(clazzAssignment: ClazzAssignment): Unit {
    clazzAssignment.caLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(clazzAssignment)
  }

  public override suspend fun findByUidAsync(uid: Long): ClazzAssignment? = _dao.findByUidAsync(uid)

  public override suspend fun getGroupUidFromAssignment(uid: Long): Long =
      _dao.getGroupUidFromAssignment(uid)

  public override suspend fun findByUidWithBlockAsync(uid: Long): ClazzAssignmentWithCourseBlock? =
      _dao.findByUidWithBlockAsync(uid)

  public override fun findClazzAssignment(): ClazzAssignment? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByUidLive(uid: Long): LiveData<ClazzAssignment?> = _dao.findByUidLive(uid)

  public override fun insert(entity: ClazzAssignment): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzAssignment): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.caUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(520)
      entity.caUid = _newPk
    }
    entity.caLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.caUid
  }

  public override fun insertList(entityList: List<ClazzAssignment>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ClazzAssignment>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzAssignment): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertListAsync(entityList: List<ClazzAssignment>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.caUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(520)
        it.caUid = _newPk
        _generatedPks += _newPk
      }
      it.caLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<ClazzAssignment>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.caLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
