package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.GroupLearningSession
import kotlin.Long
import kotlin.Unit

public actual abstract class GroupLearningSessionDao : BaseDao<GroupLearningSession> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit
}
