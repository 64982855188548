package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.CommentsWithPerson
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CommentsDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CommentsDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CommentsDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUidAsync(uid: Long): Comments? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun findPublicByEntityTypeAndUidLive(entityType: Int, entityUid: Long):
      DataSourceFactory<Int, CommentsWithPerson> {
    val _result = _dao.findPublicByEntityTypeAndUidLive(entityType, entityUid)
    return _result
  }

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> {
    val _result = _dao.findPrivateByEntityTypeAndUidAndForPersonLive(entityType, entityUid,
        personUid)
    return _result
  }

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive2(
    entityType: Int,
    entityUid: Long,
    submitterUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> {
    val _result = _dao.findPrivateByEntityTypeAndUidAndForPersonLive2(entityType, entityUid,
        submitterUid)
    return _result
  }

  public override fun findPrivateByEntityTypeAndUidAndPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> {
    val _result = _dao.findPrivateByEntityTypeAndUidAndPersonLive(entityType, entityUid, personUid)
    return _result
  }

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToLive(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
  ): DataSourceFactory<Int, CommentsWithPerson> {
    val _result = _dao.findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToLive(entityType,
        entityUid, personFrom)
    return _result
  }

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToTest(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
    personTo: Long,
  ): List<CommentsWithPerson> {
    val _result = _dao.findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToTest(entityType,
        entityUid, personFrom, personTo)
    return _result
  }

  public override suspend fun updateInActiveByCommentUid(
    uid: Long,
    inActive: Boolean,
    changeTime: Long,
  ): Unit {
    _dao.updateInActiveByCommentUid(uid, inActive, changeTime)
  }

  public override fun insert(entity: Comments): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Comments): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<Comments>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<Comments>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Comments): Unit {
    _dao.update(entity)
  }

  public override suspend fun insertListAsync(entityList: List<Comments>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<Comments>): Unit {
    _dao.updateListAsync(entityList)
  }
}
