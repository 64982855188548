package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CoursePicture
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CoursePictureDao_JdbcKt(
  public val _db: RoomDatabase,
) : CoursePictureDao() {
  public val _insertAdapterCoursePicture_: EntityInsertionAdapter<CoursePicture> = object :
      EntityInsertionAdapter<CoursePicture>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO CoursePicture (coursePictureUid, coursePictureClazzUid, coursePictureMasterCsn, coursePictureLocalCsn, coursePictureLastChangedBy, coursePictureLct, coursePictureUri, coursePictureMd5, coursePictureFileSize, coursePictureTimestamp, coursePictureMimeType, coursePictureActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CoursePicture):
        Unit {
      if(entity.coursePictureUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.coursePictureUid)
      }
      stmt.setLong(2, entity.coursePictureClazzUid)
      stmt.setLong(3, entity.coursePictureMasterCsn)
      stmt.setLong(4, entity.coursePictureLocalCsn)
      stmt.setInt(5, entity.coursePictureLastChangedBy)
      stmt.setLong(6, entity.coursePictureLct)
      stmt.setString(7, entity.coursePictureUri)
      stmt.setString(8, entity.coursePictureMd5)
      stmt.setInt(9, entity.coursePictureFileSize)
      stmt.setLong(10, entity.coursePictureTimestamp)
      stmt.setString(11, entity.coursePictureMimeType)
      stmt.setBoolean(12, entity.coursePictureActive)
    }
  }

  public override fun insert(entity: CoursePicture): Long {
    val _retVal = _insertAdapterCoursePicture_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CoursePicture): Long {
    val _retVal = _insertAdapterCoursePicture_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CoursePicture>): Unit {
    _insertAdapterCoursePicture_.insertList(entityList)
  }

  public override suspend fun updateAsync(coursePicture: CoursePicture): Unit {
    val _sql =
        "UPDATE CoursePicture SET coursePictureClazzUid = ?, coursePictureMasterCsn = ?, coursePictureLocalCsn = ?, coursePictureLastChangedBy = ?, coursePictureLct = ?, coursePictureUri = ?, coursePictureMd5 = ?, coursePictureFileSize = ?, coursePictureTimestamp = ?, coursePictureMimeType = ?, coursePictureActive = ? WHERE coursePictureUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, coursePicture.coursePictureClazzUid)
      _stmt.setLong(2, coursePicture.coursePictureMasterCsn)
      _stmt.setLong(3, coursePicture.coursePictureLocalCsn)
      _stmt.setInt(4, coursePicture.coursePictureLastChangedBy)
      _stmt.setLong(5, coursePicture.coursePictureLct)
      _stmt.setString(6, coursePicture.coursePictureUri)
      _stmt.setString(7, coursePicture.coursePictureMd5)
      _stmt.setInt(8, coursePicture.coursePictureFileSize)
      _stmt.setLong(9, coursePicture.coursePictureTimestamp)
      _stmt.setString(10, coursePicture.coursePictureMimeType)
      _stmt.setBoolean(11, coursePicture.coursePictureActive)
      _stmt.setLong(12, coursePicture.coursePictureUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateList(entityList: List<CoursePicture>): Unit {
    val _sql =
        "UPDATE CoursePicture SET coursePictureClazzUid = ?, coursePictureMasterCsn = ?, coursePictureLocalCsn = ?, coursePictureLastChangedBy = ?, coursePictureLct = ?, coursePictureUri = ?, coursePictureMd5 = ?, coursePictureFileSize = ?, coursePictureTimestamp = ?, coursePictureMimeType = ?, coursePictureActive = ? WHERE coursePictureUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.coursePictureClazzUid)
        _stmt.setLong(2, _entity.coursePictureMasterCsn)
        _stmt.setLong(3, _entity.coursePictureLocalCsn)
        _stmt.setInt(4, _entity.coursePictureLastChangedBy)
        _stmt.setLong(5, _entity.coursePictureLct)
        _stmt.setString(6, _entity.coursePictureUri)
        _stmt.setString(7, _entity.coursePictureMd5)
        _stmt.setInt(8, _entity.coursePictureFileSize)
        _stmt.setLong(9, _entity.coursePictureTimestamp)
        _stmt.setString(10, _entity.coursePictureMimeType)
        _stmt.setBoolean(11, _entity.coursePictureActive)
        _stmt.setLong(12, _entity.coursePictureUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CoursePicture): Unit {
    val _sql =
        "UPDATE CoursePicture SET coursePictureClazzUid = ?, coursePictureMasterCsn = ?, coursePictureLocalCsn = ?, coursePictureLastChangedBy = ?, coursePictureLct = ?, coursePictureUri = ?, coursePictureMd5 = ?, coursePictureFileSize = ?, coursePictureTimestamp = ?, coursePictureMimeType = ?, coursePictureActive = ? WHERE coursePictureUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.coursePictureClazzUid)
      _stmt.setLong(2, entity.coursePictureMasterCsn)
      _stmt.setLong(3, entity.coursePictureLocalCsn)
      _stmt.setInt(4, entity.coursePictureLastChangedBy)
      _stmt.setLong(5, entity.coursePictureLct)
      _stmt.setString(6, entity.coursePictureUri)
      _stmt.setString(7, entity.coursePictureMd5)
      _stmt.setInt(8, entity.coursePictureFileSize)
      _stmt.setLong(9, entity.coursePictureTimestamp)
      _stmt.setString(10, entity.coursePictureMimeType)
      _stmt.setBoolean(11, entity.coursePictureActive)
      _stmt.setLong(12, entity.coursePictureUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CoursePictureReplicate(cpPk, cpDestination)
    |      SELECT DISTINCT CoursePicture.coursePictureUid AS cpPk,
    |             ? AS cpDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 
    |              
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                 
    |             JOIN CoursePicture
    |                  ON CoursePicture.coursePictureClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CoursePicture.coursePictureLct != COALESCE(
    |             (SELECT cpVersionId
    |                FROM CoursePictureReplicate
    |               WHERE cpPk = CoursePicture.coursePictureUid
    |                 AND cpDestination = ?), 0) 
    |      /*psql ON CONFLICT(cpPk, cpDestination) DO UPDATE
    |             SET cpPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CoursePictureReplicate(cpPk, cpDestination)
    |      SELECT DISTINCT CoursePicture.coursePictureUid AS cpPk,
    |             ? AS cpDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 
    |              
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                 
    |             JOIN CoursePicture
    |                  ON CoursePicture.coursePictureClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CoursePicture.coursePictureLct != COALESCE(
    |             (SELECT cpVersionId
    |                FROM CoursePictureReplicate
    |               WHERE cpPk = CoursePicture.coursePictureUid
    |                 AND cpDestination = ?), 0) 
    |       ON CONFLICT(cpPk, cpDestination) DO UPDATE
    |             SET cpPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO CoursePictureReplicate(cpPk, cpDestination)
    |  SELECT DISTINCT CoursePicture.coursePictureUid AS cpUid,
    |         UserSession.usClientNodeId AS cpDestination
    |    FROM ChangeLog
    |         JOIN CoursePicture
    |              ON ChangeLog.chTableId = 125
    |                 AND ChangeLog.chEntityPk = CoursePicture.coursePictureUid
    |        JOIN Clazz 
    |            ON CoursePicture.coursePictureClazzUid = Clazz.clazzUid
    |  
    |        
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |           
    |        
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |        
    | 
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CoursePicture.coursePictureLct != COALESCE(
    |         (SELECT cpVersionId
    |            FROM CoursePictureReplicate
    |           WHERE cpPk = CoursePicture.coursePictureUid
    |             AND cpDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cpPk, cpDestination) DO UPDATE
    |     SET cpPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CoursePictureReplicate(cpPk, cpDestination)
    |  SELECT DISTINCT CoursePicture.coursePictureUid AS cpUid,
    |         UserSession.usClientNodeId AS cpDestination
    |    FROM ChangeLog
    |         JOIN CoursePicture
    |              ON ChangeLog.chTableId = 125
    |                 AND ChangeLog.chEntityPk = CoursePicture.coursePictureUid
    |        JOIN Clazz 
    |            ON CoursePicture.coursePictureClazzUid = Clazz.clazzUid
    |  
    |        
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |           
    |        
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |        
    | 
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CoursePicture.coursePictureLct != COALESCE(
    |         (SELECT cpVersionId
    |            FROM CoursePictureReplicate
    |           WHERE cpPk = CoursePicture.coursePictureUid
    |             AND cpDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cpPk, cpDestination) DO UPDATE
    |     SET cpPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByClazzUidAsync(clazzUid: Long): CoursePicture? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT * FROM CoursePicture 
  |        WHERE coursePictureClazzUid = ?
  |        AND CAST(coursePictureActive AS INTEGER) = 1
  |        ORDER BY coursePictureTimestamp DESC LIMIT 1
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_coursePictureUid = _result.getLong("coursePictureUid")
        val _tmp_coursePictureClazzUid = _result.getLong("coursePictureClazzUid")
        val _tmp_coursePictureMasterCsn = _result.getLong("coursePictureMasterCsn")
        val _tmp_coursePictureLocalCsn = _result.getLong("coursePictureLocalCsn")
        val _tmp_coursePictureLastChangedBy = _result.getInt("coursePictureLastChangedBy")
        val _tmp_coursePictureLct = _result.getLong("coursePictureLct")
        val _tmp_coursePictureUri = _result.getString("coursePictureUri")
        val _tmp_coursePictureMd5 = _result.getString("coursePictureMd5")
        val _tmp_coursePictureFileSize = _result.getInt("coursePictureFileSize")
        val _tmp_coursePictureTimestamp = _result.getLong("coursePictureTimestamp")
        val _tmp_coursePictureMimeType = _result.getString("coursePictureMimeType")
        val _tmp_coursePictureActive = _result.getBoolean("coursePictureActive")
        CoursePicture().apply {
          this.coursePictureUid = _tmp_coursePictureUid
          this.coursePictureClazzUid = _tmp_coursePictureClazzUid
          this.coursePictureMasterCsn = _tmp_coursePictureMasterCsn
          this.coursePictureLocalCsn = _tmp_coursePictureLocalCsn
          this.coursePictureLastChangedBy = _tmp_coursePictureLastChangedBy
          this.coursePictureLct = _tmp_coursePictureLct
          this.coursePictureUri = _tmp_coursePictureUri
          this.coursePictureMd5 = _tmp_coursePictureMd5
          this.coursePictureFileSize = _tmp_coursePictureFileSize
          this.coursePictureTimestamp = _tmp_coursePictureTimestamp
          this.coursePictureMimeType = _tmp_coursePictureMimeType
          this.coursePictureActive = _tmp_coursePictureActive
        }
      }
    }
  }

  public override fun findByClazzUidLive(clazzUid: Long): LiveData<CoursePicture?> =
      LiveDataImpl(_db, listOf("CoursePicture"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM CoursePicture where coursePictureClazzUid = ? ORDER BY  coursePictureTimestamp DESC LIMIT 1"
        )) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_coursePictureUid = _result.getLong("coursePictureUid")
          val _tmp_coursePictureClazzUid = _result.getLong("coursePictureClazzUid")
          val _tmp_coursePictureMasterCsn = _result.getLong("coursePictureMasterCsn")
          val _tmp_coursePictureLocalCsn = _result.getLong("coursePictureLocalCsn")
          val _tmp_coursePictureLastChangedBy = _result.getInt("coursePictureLastChangedBy")
          val _tmp_coursePictureLct = _result.getLong("coursePictureLct")
          val _tmp_coursePictureUri = _result.getString("coursePictureUri")
          val _tmp_coursePictureMd5 = _result.getString("coursePictureMd5")
          val _tmp_coursePictureFileSize = _result.getInt("coursePictureFileSize")
          val _tmp_coursePictureTimestamp = _result.getLong("coursePictureTimestamp")
          val _tmp_coursePictureMimeType = _result.getString("coursePictureMimeType")
          val _tmp_coursePictureActive = _result.getBoolean("coursePictureActive")
          CoursePicture().apply {
            this.coursePictureUid = _tmp_coursePictureUid
            this.coursePictureClazzUid = _tmp_coursePictureClazzUid
            this.coursePictureMasterCsn = _tmp_coursePictureMasterCsn
            this.coursePictureLocalCsn = _tmp_coursePictureLocalCsn
            this.coursePictureLastChangedBy = _tmp_coursePictureLastChangedBy
            this.coursePictureLct = _tmp_coursePictureLct
            this.coursePictureUri = _tmp_coursePictureUri
            this.coursePictureMd5 = _tmp_coursePictureMd5
            this.coursePictureFileSize = _tmp_coursePictureFileSize
            this.coursePictureTimestamp = _tmp_coursePictureTimestamp
            this.coursePictureMimeType = _tmp_coursePictureMimeType
            this.coursePictureActive = _tmp_coursePictureActive
          }
        }
      }
    }
  }
}
