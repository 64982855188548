package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.CourseDiscussion
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CourseDiscussionDao : BaseDao<CourseDiscussion>,
    OneToManyJoinDao<CourseDiscussion> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit

  public actual abstract fun getCourseDiscussionByUid(courseDiscussionUid: Long):
      LiveData<CourseDiscussion?>

  public actual abstract suspend fun replaceListAsync(list: List<CourseDiscussion>): Unit
}
