package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.StateEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class StateDao : BaseDao<StateEntity> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByStateId(
    id: String?,
    agentUid: Long,
    activityId: String?,
    registration: String?,
  ): StateEntity?

  public actual abstract fun findStateIdByAgentAndActivity(
    agentUid: Long,
    activityId: String,
    registration: String,
    since: String,
  ): List<StateEntity>

  public actual abstract fun updateStateToInActive(
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ): Unit

  public actual abstract fun setStateInActive(
    stateId: String,
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ): Unit
}
