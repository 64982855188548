package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryFileUidAndPath
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContainerEntryFileDao : BaseDao<ContainerEntryFile> {
  public actual abstract suspend fun insertListAsync(list: List<ContainerEntryFile>): Unit

  public actual abstract fun findEntriesByMd5Sums(md5Sums: List<String>): List<ContainerEntryFile>

  public actual abstract suspend fun findEntriesByMd5SumsAsync(md5Sums: List<String>):
      List<ContainerEntryFile>

  public actual abstract suspend fun findExistingMd5SumsByMd5SumsAsync(md5Sums: List<String>):
      List<String?>

  public actual abstract fun findEntriesByUids(uidList: List<Long>): List<ContainerEntryFile>

  public actual abstract fun updateFilePath(cefUid: Long, path: String): Unit

  public actual abstract fun findByUid(uid: Long): ContainerEntryFile?

  public actual abstract fun sumContainerFileEntrySizes(containerUid: Long): Long

  public actual abstract suspend fun getAllFilesForCompression(): List<ContainerEntryFile>

  public actual abstract fun updateCompressedFile(
    compression: Int,
    ceCompressedSize: Long,
    cefUid: Long,
  ): Unit

  public actual abstract fun findZombieEntries(): List<ContainerEntryFile>

  public actual abstract suspend fun findZombieUidsAndPath(limit: Int):
      List<ContainerEntryFileUidAndPath>

  public actual abstract suspend fun deleteByUidList(uidList: List<Long>): Unit

  public actual abstract fun deleteListOfEntryFiles(entriesToDelete: List<ContainerEntryFile>): Unit

  public actual abstract suspend fun findEntryByMd5Sum(md5Sum: String): ContainerEntryFile?
}
