package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.UserSession
import com.ustadmobile.lib.db.entities.UserSessionAndPerson
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class UserSessionDao {
  public actual abstract suspend fun updateReplicationTrackers(): Unit

  public actual abstract suspend fun updateReplicationTrackersOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun insertSession(session: UserSession): Long

  public actual abstract suspend fun findSessionsByPerson(personUid: Long): List<UserSession>

  public actual abstract fun findAllLocalSessionsLive(): LiveData<List<UserSessionAndPerson>>

  public actual abstract suspend fun findAllLocalSessionsAsync(): List<UserSessionAndPerson>

  public actual abstract suspend fun countAllLocalSessionsAsync(maxDateOfBirth: Long): Int

  public actual abstract suspend fun endSession(
    sessionUid: Long,
    newStatus: Int,
    reason: Int,
  ): Unit

  public actual abstract fun findByUidLive(sessionUid: Long): LiveData<UserSession?>

  public actual abstract suspend fun endOtherSessions(
    personUid: Long,
    exemptNodeId: Long,
    newStatus: Int,
    reason: Int,
    changeTime: Long,
  ): Unit

  public actual abstract suspend fun findActiveNodeIdsByPersonUids(personUids: List<Long>):
      List<Long>

  public actual abstract suspend fun findActiveNodesIdsByGroupUids(groupUids: List<Long>):
      List<Long>

  public actual abstract suspend
      fun findAllActiveNodeIdsWithClazzBasedPermission(clazzUids: List<Long>): List<Long>

  public actual abstract suspend
      fun findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids: List<Long>): List<Long>
}
