package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class VerbDao_JdbcKt(
  public val _db: RoomDatabase,
) : VerbDao() {
  public val _insertAdapterVerbEntity_upsert: EntityInsertionAdapter<VerbEntity> = object :
      EntityInsertionAdapter<VerbEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: VerbEntity):
        Unit {
      if(entity.verbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.verbUid)
      }
      stmt.setString(2, entity.urlId)
      stmt.setBoolean(3, entity.verbInActive)
      stmt.setLong(4, entity.verbMasterChangeSeqNum)
      stmt.setLong(5, entity.verbLocalChangeSeqNum)
      stmt.setInt(6, entity.verbLastChangedBy)
      stmt.setLong(7, entity.verbLct)
    }
  }

  public val _insertAdapterVerbEntity_: EntityInsertionAdapter<VerbEntity> = object :
      EntityInsertionAdapter<VerbEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: VerbEntity):
        Unit {
      if(entity.verbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.verbUid)
      }
      stmt.setString(2, entity.urlId)
      stmt.setBoolean(3, entity.verbInActive)
      stmt.setLong(4, entity.verbMasterChangeSeqNum)
      stmt.setLong(5, entity.verbLocalChangeSeqNum)
      stmt.setInt(6, entity.verbLastChangedBy)
      stmt.setLong(7, entity.verbLct)
    }
  }

  public override suspend fun replaceList(entityList: List<VerbEntity>): Unit {
    _insertAdapterVerbEntity_upsert.insertListAsync(entityList)
  }

  public override fun insert(entity: VerbEntity): Long {
    val _retVal = _insertAdapterVerbEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: VerbEntity): Long {
    val _retVal = _insertAdapterVerbEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<VerbEntity>): Unit {
    _insertAdapterVerbEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<VerbEntity>): Unit {
    val _sql =
        "UPDATE VerbEntity SET urlId = ?, verbInActive = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, verbLct = ? WHERE verbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.urlId)
        _stmt.setBoolean(2, _entity.verbInActive)
        _stmt.setLong(3, _entity.verbMasterChangeSeqNum)
        _stmt.setLong(4, _entity.verbLocalChangeSeqNum)
        _stmt.setInt(5, _entity.verbLastChangedBy)
        _stmt.setLong(6, _entity.verbLct)
        _stmt.setLong(7, _entity.verbUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: VerbEntity): Unit {
    val _sql =
        "UPDATE VerbEntity SET urlId = ?, verbInActive = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, verbLct = ? WHERE verbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.urlId)
      _stmt.setBoolean(2, entity.verbInActive)
      _stmt.setLong(3, entity.verbMasterChangeSeqNum)
      _stmt.setLong(4, entity.verbLocalChangeSeqNum)
      _stmt.setInt(5, entity.verbLastChangedBy)
      _stmt.setLong(6, entity.verbLct)
      _stmt.setLong(7, entity.verbUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO VerbEntityReplicate(vePk, veDestination)
    |      SELECT DISTINCT VerbEntity.verbUid AS vePk,
    |             ? AS veDestination
    |        FROM VerbEntity
    |       WHERE VerbEntity.verbLct != COALESCE(
    |             (SELECT veVersionId
    |                FROM VerbEntityReplicate
    |               WHERE vePk = VerbEntity.verbUid
    |                 AND veDestination = ?), 0) 
    |      /*psql ON CONFLICT(vePk, veDestination) DO UPDATE
    |             SET vePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO VerbEntityReplicate(vePk, veDestination)
    |      SELECT DISTINCT VerbEntity.verbUid AS vePk,
    |             ? AS veDestination
    |        FROM VerbEntity
    |       WHERE VerbEntity.verbLct != COALESCE(
    |             (SELECT veVersionId
    |                FROM VerbEntityReplicate
    |               WHERE vePk = VerbEntity.verbUid
    |                 AND veDestination = ?), 0) 
    |       ON CONFLICT(vePk, veDestination) DO UPDATE
    |             SET vePending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |    REPLACE INTO VerbEntityReplicate(vePk, veDestination)
    |    SELECT DISTINCT VerbEntity.verbUid AS veUid,
    |         UserSession.usClientNodeId AS veDestination
    |    FROM ChangeLog
    |         JOIN VerbEntity
    |             ON ChangeLog.chTableId = 62
    |                AND ChangeLog.chEntityPk = VerbEntity.verbUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND VerbEntity.verbLct != COALESCE(
    |         (SELECT veVersionId
    |            FROM VerbEntityReplicate
    |           WHERE vePk = VerbEntity.verbUid
    |             AND veDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(vePk, veDestination) DO UPDATE
    |     SET vePending = true
    |    */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO VerbEntityReplicate(vePk, veDestination)
    |    SELECT DISTINCT VerbEntity.verbUid AS veUid,
    |         UserSession.usClientNodeId AS veDestination
    |    FROM ChangeLog
    |         JOIN VerbEntity
    |             ON ChangeLog.chTableId = 62
    |                AND ChangeLog.chEntityPk = VerbEntity.verbUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND VerbEntity.verbLct != COALESCE(
    |         (SELECT veVersionId
    |            FROM VerbEntityReplicate
    |           WHERE vePk = VerbEntity.verbUid
    |             AND veDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(vePk, veDestination) DO UPDATE
    |     SET vePending = true
    |                   
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUrl(urlId: String?): VerbEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM VerbEntity WHERE urlId = ?"
      )) { _stmt -> 
    _stmt.setString(1,urlId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_verbUid = _result.getLong("verbUid")
        val _tmp_urlId = _result.getString("urlId")
        val _tmp_verbInActive = _result.getBoolean("verbInActive")
        val _tmp_verbMasterChangeSeqNum = _result.getLong("verbMasterChangeSeqNum")
        val _tmp_verbLocalChangeSeqNum = _result.getLong("verbLocalChangeSeqNum")
        val _tmp_verbLastChangedBy = _result.getInt("verbLastChangedBy")
        val _tmp_verbLct = _result.getLong("verbLct")
        VerbEntity().apply {
          this.verbUid = _tmp_verbUid
          this.urlId = _tmp_urlId
          this.verbInActive = _tmp_verbInActive
          this.verbMasterChangeSeqNum = _tmp_verbMasterChangeSeqNum
          this.verbLocalChangeSeqNum = _tmp_verbLocalChangeSeqNum
          this.verbLastChangedBy = _tmp_verbLastChangedBy
          this.verbLct = _tmp_verbLct
        }
      }
    }
  }

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT verbUid FROM VerbEntity WHERE verbUid IN (?)"
      ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay> =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display
  |        FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
  |         XLangMapEntry.verbLangMapUid NOT IN (?)
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_verbUid = _result.getLong("verbUid")
        val _tmp_urlId = _result.getString("urlId")
        val _tmp_display = _result.getString("display")
        VerbDisplay().apply {
          this.verbUid = _tmp_verbUid
          this.urlId = _tmp_urlId
          this.display = _tmp_display
        }
      }
    }
  }

  public override fun findAllVerbsAsc(uidList: List<Long>): DataSourceFactory<Int, VerbDisplay> =
      object : DataSourceFactory<Int, VerbDisplay>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<VerbDisplay>> =
        LiveDataImpl(_db, listOf("VerbEntity", "XLangMapEntry"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) LIMIT ? OFFSET ?
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            uidList.toTypedArray()))
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_verbUid = _result.getLong("verbUid")
            val _tmp_urlId = _result.getString("urlId")
            val _tmp_display = _result.getString("display")
            VerbDisplay().apply {
              this.verbUid = _tmp_verbUid
              this.urlId = _tmp_urlId
              this.display = _tmp_display
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("VerbEntity",
        "XLangMapEntry"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) 
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            uidList.toTypedArray()))
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findAllVerbsDesc(uidList: List<Long>): DataSourceFactory<Int, VerbDisplay> =
      object : DataSourceFactory<Int, VerbDisplay>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<VerbDisplay>> =
        LiveDataImpl(_db, listOf("VerbEntity", "XLangMapEntry"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) LIMIT ? OFFSET ?
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            uidList.toTypedArray()))
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_verbUid = _result.getLong("verbUid")
            val _tmp_urlId = _result.getString("urlId")
            val _tmp_display = _result.getString("display")
            VerbDisplay().apply {
              this.verbUid = _tmp_verbUid
              this.urlId = _tmp_urlId
              this.display = _tmp_display
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("VerbEntity",
        "XLangMapEntry"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) 
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            uidList.toTypedArray()))
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }
}
