package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ScopedGrant
import com.ustadmobile.lib.db.entities.ScopedGrantAndName
import com.ustadmobile.lib.db.entities.ScopedGrantWithName
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScopedGrantDao_JdbcKt(
  public val _db: RoomDatabase,
) : ScopedGrantDao() {
  public val _insertAdapterScopedGrant_: EntityInsertionAdapter<ScopedGrant> = object :
      EntityInsertionAdapter<ScopedGrant>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ScopedGrant (sgUid, sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ScopedGrant):
        Unit {
      if(entity.sgUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.sgUid)
      }
      stmt.setLong(2, entity.sgPcsn)
      stmt.setLong(3, entity.sgLcsn)
      stmt.setInt(4, entity.sgLcb)
      stmt.setLong(5, entity.sgLct)
      stmt.setInt(6, entity.sgTableId)
      stmt.setLong(7, entity.sgEntityUid)
      stmt.setLong(8, entity.sgPermissions)
      stmt.setLong(9, entity.sgGroupUid)
      stmt.setInt(10, entity.sgIndex)
      stmt.setInt(11, entity.sgFlags)
    }
  }

  public override suspend fun insertAsync(scopedGrant: ScopedGrant): Long {
    val _retVal = _insertAdapterScopedGrant_.insertAndReturnIdAsync(scopedGrant)
    return _retVal
  }

  public override suspend fun insertListAsync(scopedGrantList: List<ScopedGrant>): Unit {
    _insertAdapterScopedGrant_.insertListAsync(scopedGrantList)
  }

  public override suspend fun updateAsync(scopedGrant: ScopedGrant): Unit {
    val _sql =
        "UPDATE ScopedGrant SET sgPcsn = ?, sgLcsn = ?, sgLcb = ?, sgLct = ?, sgTableId = ?, sgEntityUid = ?, sgPermissions = ?, sgGroupUid = ?, sgIndex = ?, sgFlags = ? WHERE sgUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, scopedGrant.sgPcsn)
      _stmt.setLong(2, scopedGrant.sgLcsn)
      _stmt.setInt(3, scopedGrant.sgLcb)
      _stmt.setLong(4, scopedGrant.sgLct)
      _stmt.setInt(5, scopedGrant.sgTableId)
      _stmt.setLong(6, scopedGrant.sgEntityUid)
      _stmt.setLong(7, scopedGrant.sgPermissions)
      _stmt.setLong(8, scopedGrant.sgGroupUid)
      _stmt.setInt(9, scopedGrant.sgIndex)
      _stmt.setInt(10, scopedGrant.sgFlags)
      _stmt.setLong(11, scopedGrant.sgUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateListAsync(scopedGrantList: List<ScopedGrant>): Unit {
    val _sql =
        "UPDATE ScopedGrant SET sgPcsn = ?, sgLcsn = ?, sgLcb = ?, sgLct = ?, sgTableId = ?, sgEntityUid = ?, sgPermissions = ?, sgGroupUid = ?, sgIndex = ?, sgFlags = ? WHERE sgUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in scopedGrantList) {
        _stmt.setLong(1, _entity.sgPcsn)
        _stmt.setLong(2, _entity.sgLcsn)
        _stmt.setInt(3, _entity.sgLcb)
        _stmt.setLong(4, _entity.sgLct)
        _stmt.setInt(5, _entity.sgTableId)
        _stmt.setLong(6, _entity.sgEntityUid)
        _stmt.setLong(7, _entity.sgPermissions)
        _stmt.setLong(8, _entity.sgGroupUid)
        _stmt.setInt(9, _entity.sgIndex)
        _stmt.setInt(10, _entity.sgFlags)
        _stmt.setLong(11, _entity.sgUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantWithPerm.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN ScopedGrant ScopedGrantWithPerm
    |                    ON PersonsWithPerm_GroupMember.groupMemberGroupUid = ScopedGrantWithPerm.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantWithPerm.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantWithPerm.sgUid
    |                 AND sgDestination = ?), 0) 
    |      /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantWithPerm.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN ScopedGrant ScopedGrantWithPerm
    |                    ON PersonsWithPerm_GroupMember.groupMemberGroupUid = ScopedGrantWithPerm.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantWithPerm.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantWithPerm.sgUid
    |                 AND sgDestination = ?), 0) 
    |       ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |             ON ChangeLog.chTableId = 48
    |                AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |             ON ChangeLog.chTableId = 48
    |                AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                       AND ScopedGrantEntity.sgTableId = 6
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = ?), 0) 
    |      /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |      */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                       AND ScopedGrantEntity.sgTableId = 6
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = ?), 0) 
    |       ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |      
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Person
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                    
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Person
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                      
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 9
    |                       AND ScopedGrantEntity.sgEntityUid = Person.personUid 
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                                                       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 9
    |                       AND ScopedGrantEntity.sgEntityUid = Person.personUid 
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                                                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN School
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |              
    |   WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                 
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN School
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |              
    |   WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                   
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                    536870912
    |                    
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 164
    |                       AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                                                                                 
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                    536870912
    |                    
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 164
    |                       AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                                                                                   
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long):
      List<ScopedGrantAndName> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ScopedGrant.*,
  |               CASE
  |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
  |               ELSE PersonGroup.groupName 
  |               END AS name
  |          FROM ScopedGrant
  |               JOIN PersonGroup 
  |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
  |               LEFT JOIN Person
  |                         ON Person.personGroupUid = PersonGroup.groupUid
  |         WHERE ScopedGrant.sgTableId = ?
  |               AND ScopedGrant.sgEntityUid = ?  
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setInt(1,tableId)
    _stmt.setLong(2,entityUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_name = _result.getString("name")
        var _tmp_ScopedGrant_nullCount = 0
        val _tmp_sgUid = _result.getLong("sgUid")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgPcsn = _result.getLong("sgPcsn")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgLcsn = _result.getLong("sgLcsn")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgLcb = _result.getInt("sgLcb")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgLct = _result.getLong("sgLct")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgTableId = _result.getInt("sgTableId")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgEntityUid = _result.getLong("sgEntityUid")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgPermissions = _result.getLong("sgPermissions")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgGroupUid = _result.getLong("sgGroupUid")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgIndex = _result.getInt("sgIndex")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_sgFlags = _result.getInt("sgFlags")
        if(_result.wasNull()) _tmp_ScopedGrant_nullCount++
        val _tmp_ScopedGrant_isAllNull = _tmp_ScopedGrant_nullCount == 11
        ScopedGrantAndName().apply {
          this.name = _tmp_name
          if(!_tmp_ScopedGrant_isAllNull) {
            this.scopedGrant = ScopedGrant().apply {
              this.sgUid = _tmp_sgUid
              this.sgPcsn = _tmp_sgPcsn
              this.sgLcsn = _tmp_sgLcsn
              this.sgLcb = _tmp_sgLcb
              this.sgLct = _tmp_sgLct
              this.sgTableId = _tmp_sgTableId
              this.sgEntityUid = _tmp_sgEntityUid
              this.sgPermissions = _tmp_sgPermissions
              this.sgGroupUid = _tmp_sgGroupUid
              this.sgIndex = _tmp_sgIndex
              this.sgFlags = _tmp_sgFlags
            }
          }
        }
      }
    }
  }

  public override fun findByTableIdAndEntityUidWithNameAsDataSource(tableId: Int, entityUid: Long):
      DataSourceFactory<Int, ScopedGrantWithName> = object :
      DataSourceFactory<Int, ScopedGrantWithName>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<ScopedGrantWithName>> =
        LiveDataImpl(_db, listOf("ScopedGrant", "PersonGroup", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT ScopedGrant.*,
      |               CASE
      |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
      |               ELSE PersonGroup.groupName 
      |               END AS name
      |          FROM ScopedGrant
      |               JOIN PersonGroup 
      |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
      |               LEFT JOIN Person
      |                         ON Person.personGroupUid = PersonGroup.groupUid
      |         WHERE ScopedGrant.sgTableId = ?
      |               AND ScopedGrant.sgEntityUid = ?  
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,tableId)
        _stmt.setLong(2,entityUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_name = _result.getString("name")
            val _tmp_sgUid = _result.getLong("sgUid")
            val _tmp_sgPcsn = _result.getLong("sgPcsn")
            val _tmp_sgLcsn = _result.getLong("sgLcsn")
            val _tmp_sgLcb = _result.getInt("sgLcb")
            val _tmp_sgLct = _result.getLong("sgLct")
            val _tmp_sgTableId = _result.getInt("sgTableId")
            val _tmp_sgEntityUid = _result.getLong("sgEntityUid")
            val _tmp_sgPermissions = _result.getLong("sgPermissions")
            val _tmp_sgGroupUid = _result.getLong("sgGroupUid")
            val _tmp_sgIndex = _result.getInt("sgIndex")
            val _tmp_sgFlags = _result.getInt("sgFlags")
            ScopedGrantWithName().apply {
              this.name = _tmp_name
              this.sgUid = _tmp_sgUid
              this.sgPcsn = _tmp_sgPcsn
              this.sgLcsn = _tmp_sgLcsn
              this.sgLcb = _tmp_sgLcb
              this.sgLct = _tmp_sgLct
              this.sgTableId = _tmp_sgTableId
              this.sgEntityUid = _tmp_sgEntityUid
              this.sgPermissions = _tmp_sgPermissions
              this.sgGroupUid = _tmp_sgGroupUid
              this.sgIndex = _tmp_sgIndex
              this.sgFlags = _tmp_sgFlags
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ScopedGrant",
        "PersonGroup", "Person"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT ScopedGrant.*,
      |               CASE
      |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
      |               ELSE PersonGroup.groupName 
      |               END AS name
      |          FROM ScopedGrant
      |               JOIN PersonGroup 
      |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
      |               LEFT JOIN Person
      |                         ON Person.personGroupUid = PersonGroup.groupUid
      |         WHERE ScopedGrant.sgTableId = ?
      |               AND ScopedGrant.sgEntityUid = ?  
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,tableId)
        _stmt.setLong(2,entityUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findByTableIdAndEntityIdSync(tableId: Int, entityUid: Long): List<ScopedGrant>
      = _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT ScopedGrant.*
  |          FROM ScopedGrant
  |         WHERE sgTableId = ?
  |           AND sgEntityUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setInt(1,tableId)
    _stmt.setLong(2,entityUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_sgUid = _result.getLong("sgUid")
        val _tmp_sgPcsn = _result.getLong("sgPcsn")
        val _tmp_sgLcsn = _result.getLong("sgLcsn")
        val _tmp_sgLcb = _result.getInt("sgLcb")
        val _tmp_sgLct = _result.getLong("sgLct")
        val _tmp_sgTableId = _result.getInt("sgTableId")
        val _tmp_sgEntityUid = _result.getLong("sgEntityUid")
        val _tmp_sgPermissions = _result.getLong("sgPermissions")
        val _tmp_sgGroupUid = _result.getLong("sgGroupUid")
        val _tmp_sgIndex = _result.getInt("sgIndex")
        val _tmp_sgFlags = _result.getInt("sgFlags")
        ScopedGrant().apply {
          this.sgUid = _tmp_sgUid
          this.sgPcsn = _tmp_sgPcsn
          this.sgLcsn = _tmp_sgLcsn
          this.sgLcb = _tmp_sgLcb
          this.sgLct = _tmp_sgLct
          this.sgTableId = _tmp_sgTableId
          this.sgEntityUid = _tmp_sgEntityUid
          this.sgPermissions = _tmp_sgPermissions
          this.sgGroupUid = _tmp_sgGroupUid
          this.sgIndex = _tmp_sgIndex
          this.sgFlags = _tmp_sgFlags
        }
      }
    }
  }

  public override suspend fun findByUid(sgUid: Long): ScopedGrant? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ScopedGrant.*
  |          FROM ScopedGrant
  |         WHERE sgUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,sgUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_sgUid = _result.getLong("sgUid")
        val _tmp_sgPcsn = _result.getLong("sgPcsn")
        val _tmp_sgLcsn = _result.getLong("sgLcsn")
        val _tmp_sgLcb = _result.getInt("sgLcb")
        val _tmp_sgLct = _result.getLong("sgLct")
        val _tmp_sgTableId = _result.getInt("sgTableId")
        val _tmp_sgEntityUid = _result.getLong("sgEntityUid")
        val _tmp_sgPermissions = _result.getLong("sgPermissions")
        val _tmp_sgGroupUid = _result.getLong("sgGroupUid")
        val _tmp_sgIndex = _result.getInt("sgIndex")
        val _tmp_sgFlags = _result.getInt("sgFlags")
        ScopedGrant().apply {
          this.sgUid = _tmp_sgUid
          this.sgPcsn = _tmp_sgPcsn
          this.sgLcsn = _tmp_sgLcsn
          this.sgLcb = _tmp_sgLcb
          this.sgLct = _tmp_sgLct
          this.sgTableId = _tmp_sgTableId
          this.sgEntityUid = _tmp_sgEntityUid
          this.sgPermissions = _tmp_sgPermissions
          this.sgGroupUid = _tmp_sgGroupUid
          this.sgIndex = _tmp_sgIndex
          this.sgFlags = _tmp_sgFlags
        }
      }
    }
  }

  public override fun findByUidLiveWithName(sgUid: Long): LiveData<ScopedGrantWithName?> =
      LiveDataImpl(_db, listOf("ScopedGrant", "PersonGroup", "Person"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT ScopedGrant.*, 
    |               CASE
    |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
    |               ELSE PersonGroup.groupName 
    |               END AS name
    |          FROM ScopedGrant
    |               LEFT JOIN PersonGroup 
    |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
    |               LEFT JOIN Person
    |                    ON Person.personGroupUid = PersonGroup.groupUid
    |         WHERE ScopedGrant.sgUid = ? 
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,sgUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_name = _result.getString("name")
          val _tmp_sgUid = _result.getLong("sgUid")
          val _tmp_sgPcsn = _result.getLong("sgPcsn")
          val _tmp_sgLcsn = _result.getLong("sgLcsn")
          val _tmp_sgLcb = _result.getInt("sgLcb")
          val _tmp_sgLct = _result.getLong("sgLct")
          val _tmp_sgTableId = _result.getInt("sgTableId")
          val _tmp_sgEntityUid = _result.getLong("sgEntityUid")
          val _tmp_sgPermissions = _result.getLong("sgPermissions")
          val _tmp_sgGroupUid = _result.getLong("sgGroupUid")
          val _tmp_sgIndex = _result.getInt("sgIndex")
          val _tmp_sgFlags = _result.getInt("sgFlags")
          ScopedGrantWithName().apply {
            this.name = _tmp_name
            this.sgUid = _tmp_sgUid
            this.sgPcsn = _tmp_sgPcsn
            this.sgLcsn = _tmp_sgLcsn
            this.sgLcb = _tmp_sgLcb
            this.sgLct = _tmp_sgLct
            this.sgTableId = _tmp_sgTableId
            this.sgEntityUid = _tmp_sgEntityUid
            this.sgPermissions = _tmp_sgPermissions
            this.sgGroupUid = _tmp_sgGroupUid
            this.sgIndex = _tmp_sgIndex
            this.sgFlags = _tmp_sgFlags
          }
        }
      }
    }
  }
}
