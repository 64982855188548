package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.Clazz
import com.ustadmobile.lib.db.entities.ClazzWithDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndSchoolAndTerminology
import com.ustadmobile.lib.db.entities.ClazzWithListDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithSchool
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzDao : BaseDao<Clazz> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByUid(uid: Long): Clazz?

  public actual abstract fun findByUidLive(uid: Long): LiveData<Clazz?>

  public actual abstract suspend fun findByClazzCode(code: String): Clazz?

  public actual abstract suspend fun findByClazzCodeFromWeb(code: String): Clazz?

  public actual abstract fun findAllLive(): LiveData<List<Clazz>>

  public actual abstract fun findAll(): List<Clazz>

  public actual abstract suspend fun findByUidAsync(uid: Long): Clazz?

  public actual abstract suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      ClazzWithHolidayCalendarAndSchoolAndTerminology?

  public actual abstract suspend fun updateAsync(entity: Clazz): Int

  public actual abstract suspend fun findAllClazzesBySchool(schoolUid: Long): List<Clazz>

  public actual abstract fun findAllClazzesBySchoolLive(schoolUid: Long):
      DataSourceFactory<Int, Clazz>

  public actual abstract fun findClazzesWithPermission(
    searchQuery: String,
    accountPersonUid: Long,
    excludeSelectedClazzList: List<Long>,
    excludeSchoolUid: Long,
    sortOrder: Int,
    filter: Int,
    currentTime: Long,
    permission: Long,
    selectedSchool: Long,
  ): DataSourceFactory<Int, ClazzWithListDisplayDetails>

  public actual abstract suspend fun getClassNamesFromListOfIds(ids: List<Long>): List<UidAndLabel>

  public actual abstract fun findByClazzName(name: String): List<Clazz>

  public actual abstract suspend fun updateClazzAttendanceAverageAsync(clazzUid: Long,
      timeChanged: Long): Unit

  public actual abstract suspend fun personHasPermissionWithClazz(
    accountPersonUid: Long,
    clazzUid: Long,
    permission: Long,
  ): Boolean

  public actual abstract suspend fun selectDelegatablePermissions(accountPersonUid: Long,
      clazzUid: Long): List<Long>

  public actual abstract fun getClazzWithDisplayDetails(clazzUid: Long, currentTime: Long):
      LiveData<ClazzWithDisplayDetails?>

  public actual abstract fun findClazzesWithEffectiveHolidayCalendarAndFilter(filterUid: Long):
      List<ClazzWithHolidayCalendarAndSchoolAndTerminology>

  public actual abstract suspend fun getClazzWithSchool(clazzUid: Long): ClazzWithSchool?
}
