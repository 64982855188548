package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoin
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLangName
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLanguage
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryRelatedEntryJoinDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentEntryRelatedEntryJoinDao,
) : ContentEntryRelatedEntryJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun publicContentEntryRelatedEntryJoins(): List<ContentEntryRelatedEntryJoin> =
      throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findPrimaryByTranslation(contentEntryUid: Long): ContentEntryRelatedEntryJoin?
      = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findAllTranslationsForContentEntryAsync(contentEntryUid: Long):
      List<ContentEntryRelatedEntryJoinWithLangName> =
      _dao.findAllTranslationsForContentEntryAsync(contentEntryUid)

  public override fun findAllTranslationsWithContentEntryUid(contentEntryUid: Long):
      DataSourceFactory<Int, ContentEntryRelatedEntryJoinWithLanguage> =
      _dao.findAllTranslationsWithContentEntryUid(contentEntryUid)

  public override fun update(entity: ContentEntryRelatedEntryJoin): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: ContentEntryRelatedEntryJoin): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentEntryRelatedEntryJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cerejUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(8)
      entity.cerejUid = _newPk
    }
    entity.cerejLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cerejUid
  }

  public override fun insertList(entityList: List<ContentEntryRelatedEntryJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ContentEntryRelatedEntryJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
