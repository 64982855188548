package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LeavingReasonDao_JdbcKt(
  public val _db: RoomDatabase,
) : LeavingReasonDao() {
  public val _insertAdapterLeavingReason_upsert: EntityInsertionAdapter<LeavingReason> = object :
      EntityInsertionAdapter<LeavingReason>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO LeavingReason (leavingReasonUid, leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct) VALUES(?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: LeavingReason):
        Unit {
      if(entity.leavingReasonUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.leavingReasonUid)
      }
      stmt.setString(2, entity.leavingReasonTitle)
      stmt.setLong(3, entity.leavingReasonMCSN)
      stmt.setLong(4, entity.leavingReasonCSN)
      stmt.setInt(5, entity.leavingReasonLCB)
      stmt.setLong(6, entity.leavingReasonLct)
    }
  }

  public val _insertAdapterLeavingReason_: EntityInsertionAdapter<LeavingReason> = object :
      EntityInsertionAdapter<LeavingReason>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO LeavingReason (leavingReasonUid, leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct) VALUES(?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: LeavingReason):
        Unit {
      if(entity.leavingReasonUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.leavingReasonUid)
      }
      stmt.setString(2, entity.leavingReasonTitle)
      stmt.setLong(3, entity.leavingReasonMCSN)
      stmt.setLong(4, entity.leavingReasonCSN)
      stmt.setInt(5, entity.leavingReasonLCB)
      stmt.setLong(6, entity.leavingReasonLct)
    }
  }

  public override suspend fun replaceList(entityList: List<LeavingReason>): Unit {
    _insertAdapterLeavingReason_upsert.insertListAsync(entityList)
  }

  public override fun insert(entity: LeavingReason): Long {
    val _retVal = _insertAdapterLeavingReason_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: LeavingReason): Long {
    val _retVal = _insertAdapterLeavingReason_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<LeavingReason>): Unit {
    _insertAdapterLeavingReason_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: LeavingReason): Int {
    var _result = 0
    val _sql =
        "UPDATE LeavingReason SET leavingReasonTitle = ?, leavingReasonMCSN = ?, leavingReasonCSN = ?, leavingReasonLCB = ?, leavingReasonLct = ? WHERE leavingReasonUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.leavingReasonTitle)
      _stmt.setLong(2, entity.leavingReasonMCSN)
      _stmt.setLong(3, entity.leavingReasonCSN)
      _stmt.setInt(4, entity.leavingReasonLCB)
      _stmt.setLong(5, entity.leavingReasonLct)
      _stmt.setLong(6, entity.leavingReasonUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<LeavingReason>): Unit {
    val _sql =
        "UPDATE LeavingReason SET leavingReasonTitle = ?, leavingReasonMCSN = ?, leavingReasonCSN = ?, leavingReasonLCB = ?, leavingReasonLct = ? WHERE leavingReasonUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.leavingReasonTitle)
        _stmt.setLong(2, _entity.leavingReasonMCSN)
        _stmt.setLong(3, _entity.leavingReasonCSN)
        _stmt.setInt(4, _entity.leavingReasonLCB)
        _stmt.setLong(5, _entity.leavingReasonLct)
        _stmt.setLong(6, _entity.leavingReasonUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: LeavingReason): Unit {
    val _sql =
        "UPDATE LeavingReason SET leavingReasonTitle = ?, leavingReasonMCSN = ?, leavingReasonCSN = ?, leavingReasonLCB = ?, leavingReasonLct = ? WHERE leavingReasonUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.leavingReasonTitle)
      _stmt.setLong(2, entity.leavingReasonMCSN)
      _stmt.setLong(3, entity.leavingReasonCSN)
      _stmt.setInt(4, entity.leavingReasonLCB)
      _stmt.setLong(5, entity.leavingReasonLct)
      _stmt.setLong(6, entity.leavingReasonUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         REPLACE INTO LeavingReasonReplicate(lrPk, lrDestination)
    |          SELECT DISTINCT LeavingReason.leavingReasonUid AS lrPk,
    |                 ? AS lrDestination
    |            FROM LeavingReason
    |           WHERE LeavingReason.leavingReasonLct != COALESCE(
    |                 (SELECT lrVersionId
    |                    FROM LeavingReasonReplicate
    |                   WHERE lrPk = LeavingReason.leavingReasonUid
    |                     AND lrDestination = ?), 0) 
    |          /*psql ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |                 SET lrPending = true
    |          */       
    |     
    """.trimMargin() , postgreSql = """
    |INSERT INTO LeavingReasonReplicate(lrPk, lrDestination)
    |          SELECT DISTINCT LeavingReason.leavingReasonUid AS lrPk,
    |                 ? AS lrDestination
    |            FROM LeavingReason
    |           WHERE LeavingReason.leavingReasonLct != COALESCE(
    |                 (SELECT lrVersionId
    |                    FROM LeavingReasonReplicate
    |                   WHERE lrPk = LeavingReason.leavingReasonUid
    |                     AND lrDestination = ?), 0) 
    |           ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |                 SET lrPending = true
    |                 
    |     
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO LeavingReasonReplicate(lrPk, lrDestination)
    |  SELECT DISTINCT LeavingReason.leavingReasonUid AS lrUid,
    |         UserSession.usClientNodeId AS lrDestination
    |    FROM ChangeLog
    |         JOIN LeavingReason
    |              ON ChangeLog.chTableId = 410
    |                 AND ChangeLog.chEntityPk = LeavingReason.leavingReasonUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LeavingReason.leavingReasonLct != COALESCE(
    |         (SELECT lrVersionId
    |            FROM LeavingReasonReplicate
    |           WHERE lrPk = LeavingReason.leavingReasonUid
    |             AND lrDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |     SET lrPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LeavingReasonReplicate(lrPk, lrDestination)
    |  SELECT DISTINCT LeavingReason.leavingReasonUid AS lrUid,
    |         UserSession.usClientNodeId AS lrDestination
    |    FROM ChangeLog
    |         JOIN LeavingReason
    |              ON ChangeLog.chTableId = 410
    |                 AND ChangeLog.chEntityPk = LeavingReason.leavingReasonUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LeavingReason.leavingReasonLct != COALESCE(
    |         (SELECT lrVersionId
    |            FROM LeavingReasonReplicate
    |           WHERE lrPk = LeavingReason.leavingReasonUid
    |             AND lrDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |     SET lrPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllReasons(): DataSourceFactory<Int, LeavingReason> = object :
      DataSourceFactory<Int, LeavingReason>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<LeavingReason>> =
        LiveDataImpl(_db, listOf("LeavingReason"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM (SELECT * FROM LeavingReason) LIMIT ? OFFSET ?"
          )) { _stmt -> 
        _stmt.setInt(1,_limit)
        _stmt.setInt(2,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_leavingReasonUid = _result.getLong("leavingReasonUid")
            val _tmp_leavingReasonTitle = _result.getString("leavingReasonTitle")
            val _tmp_leavingReasonMCSN = _result.getLong("leavingReasonMCSN")
            val _tmp_leavingReasonCSN = _result.getLong("leavingReasonCSN")
            val _tmp_leavingReasonLCB = _result.getInt("leavingReasonLCB")
            val _tmp_leavingReasonLct = _result.getLong("leavingReasonLct")
            LeavingReason().apply {
              this.leavingReasonUid = _tmp_leavingReasonUid
              this.leavingReasonTitle = _tmp_leavingReasonTitle
              this.leavingReasonMCSN = _tmp_leavingReasonMCSN
              this.leavingReasonCSN = _tmp_leavingReasonCSN
              this.leavingReasonLCB = _tmp_leavingReasonLCB
              this.leavingReasonLct = _tmp_leavingReasonLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("LeavingReason"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT * FROM LeavingReason) "
          )) { _stmt -> 
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findAllReasonsLive(): List<LeavingReason> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM LeavingReason" )) { _stmt ->
      
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_leavingReasonUid = _result.getLong("leavingReasonUid")
        val _tmp_leavingReasonTitle = _result.getString("leavingReasonTitle")
        val _tmp_leavingReasonMCSN = _result.getLong("leavingReasonMCSN")
        val _tmp_leavingReasonCSN = _result.getLong("leavingReasonCSN")
        val _tmp_leavingReasonLCB = _result.getInt("leavingReasonLCB")
        val _tmp_leavingReasonLct = _result.getLong("leavingReasonLct")
        LeavingReason().apply {
          this.leavingReasonUid = _tmp_leavingReasonUid
          this.leavingReasonTitle = _tmp_leavingReasonTitle
          this.leavingReasonMCSN = _tmp_leavingReasonMCSN
          this.leavingReasonCSN = _tmp_leavingReasonCSN
          this.leavingReasonLCB = _tmp_leavingReasonLCB
          this.leavingReasonLct = _tmp_leavingReasonLct
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): LeavingReason? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM LeavingReason WHERE leavingReasonUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_leavingReasonUid = _result.getLong("leavingReasonUid")
        val _tmp_leavingReasonTitle = _result.getString("leavingReasonTitle")
        val _tmp_leavingReasonMCSN = _result.getLong("leavingReasonMCSN")
        val _tmp_leavingReasonCSN = _result.getLong("leavingReasonCSN")
        val _tmp_leavingReasonLCB = _result.getInt("leavingReasonLCB")
        val _tmp_leavingReasonLct = _result.getLong("leavingReasonLct")
        LeavingReason().apply {
          this.leavingReasonUid = _tmp_leavingReasonUid
          this.leavingReasonTitle = _tmp_leavingReasonTitle
          this.leavingReasonMCSN = _tmp_leavingReasonMCSN
          this.leavingReasonCSN = _tmp_leavingReasonCSN
          this.leavingReasonLCB = _tmp_leavingReasonLCB
          this.leavingReasonLct = _tmp_leavingReasonLct
        }
      }
    }
  }

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT leavingReasonUid FROM LeavingReason WHERE leavingReasonUid IN (?)"
      ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<LeavingReason?> = LiveDataImpl(_db,
      listOf("LeavingReason"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM LeavingReason WHERE leavingReasonUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_leavingReasonUid = _result.getLong("leavingReasonUid")
          val _tmp_leavingReasonTitle = _result.getString("leavingReasonTitle")
          val _tmp_leavingReasonMCSN = _result.getLong("leavingReasonMCSN")
          val _tmp_leavingReasonCSN = _result.getLong("leavingReasonCSN")
          val _tmp_leavingReasonLCB = _result.getInt("leavingReasonLCB")
          val _tmp_leavingReasonLct = _result.getLong("leavingReasonLct")
          LeavingReason().apply {
            this.leavingReasonUid = _tmp_leavingReasonUid
            this.leavingReasonTitle = _tmp_leavingReasonTitle
            this.leavingReasonMCSN = _tmp_leavingReasonMCSN
            this.leavingReasonCSN = _tmp_leavingReasonCSN
            this.leavingReasonLCB = _tmp_leavingReasonLCB
            this.leavingReasonLct = _tmp_leavingReasonLct
          }
        }
      }
    }
  }

  public override suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT LeavingReason.leavingReasonUid AS uid, 
  |            LeavingReason.leavingReasonTitle As labelName  
  |            FROM LeavingReason WHERE leavingReasonUid IN (?)
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_uid = _result.getLong("uid")
        val _tmp_labelName = _result.getString("labelName")
        UidAndLabel().apply {
          this.uid = _tmp_uid
          this.labelName = _tmp_labelName
        }
      }
    }
  }
}
