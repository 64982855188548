package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary
import com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay
import com.ustadmobile.lib.db.entities.StatementEntity
import com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class StatementDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: StatementDao,
) : StatementDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<StatementEntity>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.statementUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(60)
        it.statementUid = _newPk
        _generatedPks += _newPk
      }
      it.statementLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override fun getOneStatement(): LiveData<StatementEntity?> = _dao.getOneStatement()

  public override fun findByStatementId(id: String): StatementEntity? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByStatementIdList(id: List<String>): List<StatementEntity> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun getResults(query: DoorQuery): List<StatementReportData> =
      _dao.getResults(query)

  public override fun getListResults(query: DoorQuery):
      DataSourceFactory<Int, StatementEntityWithDisplayDetails> = _dao.getListResults(query)

  public override fun getPerson(): Person? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun getXLangMap(): XLangMapEntry? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateProgress(
    uid: Long,
    progress: Int,
    updateTime: Long,
  ): Unit = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findPersonsWithContentEntryAttempts(
    contentEntryUid: Long,
    accountPersonUid: Long,
    searchText: String,
    sortOrder: Int,
  ): DataSourceFactory<Int, PersonWithAttemptsSummary> =
      _dao.findPersonsWithContentEntryAttempts(contentEntryUid, accountPersonUid, searchText,
      sortOrder)

  public override suspend fun getBestScoreForContentForPerson(contentEntryUid: Long,
      accountPersonUid: Long): ContentEntryStatementScoreProgress? =
      _dao.getBestScoreForContentForPerson(contentEntryUid, accountPersonUid)

  public override suspend fun findNextStudentNotMarkedForAssignment(assignmentUid: Long,
      currentStudentUid: Long): Long = _dao.findNextStudentNotMarkedForAssignment(assignmentUid,
      currentStudentUid)

  public override suspend fun findSubmittedStatementFromStudent(studentUid: Long,
      assignmentObjectUid: Long): StatementEntity? =
      _dao.findSubmittedStatementFromStudent(studentUid, assignmentObjectUid)

  public override fun findScoreStatementForStudent(studentUid: Long): StatementEntity? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findSessionsForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, PersonWithSessionsDisplay> = _dao.findSessionsForPerson(contentEntryUid,
      accountPersonUid, personUid)

  public override fun findSessionDetailForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
    contextRegistration: String,
  ): DataSourceFactory<Int, StatementWithSessionDetailDisplay> =
      _dao.findSessionDetailForPerson(contentEntryUid, accountPersonUid, personUid,
      contextRegistration)

  public override suspend fun calculateScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? = _dao.calculateScoreForSession(contextRegistration)

  public override suspend fun findCompletedScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? = _dao.findCompletedScoreForSession(contextRegistration)

  public override suspend fun findLatestRegistrationStatement(accountPersonUid: Long,
      entryUid: Long): String? = _dao.findLatestRegistrationStatement(accountPersonUid, entryUid)

  public override fun insert(entity: StatementEntity): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: StatementEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.statementUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(60)
      entity.statementUid = _newPk
    }
    entity.statementLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.statementUid
  }

  public override fun insertList(entityList: List<StatementEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<StatementEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: StatementEntity): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
