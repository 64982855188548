package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScheduleDao_JdbcKt(
  public val _db: RoomDatabase,
) : ScheduleDao() {
  public val _insertAdapterSchedule_: EntityInsertionAdapter<Schedule> = object :
      EntityInsertionAdapter<Schedule>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Schedule (scheduleUid, sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Schedule): Unit {
      if(entity.scheduleUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.scheduleUid)
      }
      stmt.setLong(2, entity.sceduleStartTime)
      stmt.setLong(3, entity.scheduleEndTime)
      stmt.setInt(4, entity.scheduleDay)
      stmt.setInt(5, entity.scheduleMonth)
      stmt.setInt(6, entity.scheduleFrequency)
      stmt.setLong(7, entity.umCalendarUid)
      stmt.setLong(8, entity.scheduleClazzUid)
      stmt.setLong(9, entity.scheduleMasterChangeSeqNum)
      stmt.setLong(10, entity.scheduleLocalChangeSeqNum)
      stmt.setInt(11, entity.scheduleLastChangedBy)
      stmt.setLong(12, entity.scheduleLastChangedTime)
      stmt.setBoolean(13, entity.scheduleActive)
    }
  }

  public override fun insert(entity: Schedule): Long {
    val _retVal = _insertAdapterSchedule_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Schedule): Long {
    val _retVal = _insertAdapterSchedule_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Schedule>): Unit {
    _insertAdapterSchedule_.insertList(entityList)
  }

  public override suspend fun insertListAsync(entityList: List<Schedule>): Unit {
    _insertAdapterSchedule_.insertListAsync(entityList)
  }

  public override suspend fun updateAsync(entity: Schedule): Int {
    var _result = 0
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sceduleStartTime)
      _stmt.setLong(2, entity.scheduleEndTime)
      _stmt.setInt(3, entity.scheduleDay)
      _stmt.setInt(4, entity.scheduleMonth)
      _stmt.setInt(5, entity.scheduleFrequency)
      _stmt.setLong(6, entity.umCalendarUid)
      _stmt.setLong(7, entity.scheduleClazzUid)
      _stmt.setLong(8, entity.scheduleMasterChangeSeqNum)
      _stmt.setLong(9, entity.scheduleLocalChangeSeqNum)
      _stmt.setInt(10, entity.scheduleLastChangedBy)
      _stmt.setLong(11, entity.scheduleLastChangedTime)
      _stmt.setBoolean(12, entity.scheduleActive)
      _stmt.setLong(13, entity.scheduleUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<Schedule>): Unit {
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.sceduleStartTime)
        _stmt.setLong(2, _entity.scheduleEndTime)
        _stmt.setInt(3, _entity.scheduleDay)
        _stmt.setInt(4, _entity.scheduleMonth)
        _stmt.setInt(5, _entity.scheduleFrequency)
        _stmt.setLong(6, _entity.umCalendarUid)
        _stmt.setLong(7, _entity.scheduleClazzUid)
        _stmt.setLong(8, _entity.scheduleMasterChangeSeqNum)
        _stmt.setLong(9, _entity.scheduleLocalChangeSeqNum)
        _stmt.setInt(10, _entity.scheduleLastChangedBy)
        _stmt.setLong(11, _entity.scheduleLastChangedTime)
        _stmt.setBoolean(12, _entity.scheduleActive)
        _stmt.setLong(13, _entity.scheduleUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Schedule): Unit {
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sceduleStartTime)
      _stmt.setLong(2, entity.scheduleEndTime)
      _stmt.setInt(3, entity.scheduleDay)
      _stmt.setInt(4, entity.scheduleMonth)
      _stmt.setInt(5, entity.scheduleFrequency)
      _stmt.setLong(6, entity.umCalendarUid)
      _stmt.setLong(7, entity.scheduleClazzUid)
      _stmt.setLong(8, entity.scheduleMasterChangeSeqNum)
      _stmt.setLong(9, entity.scheduleLocalChangeSeqNum)
      _stmt.setInt(10, entity.scheduleLastChangedBy)
      _stmt.setLong(11, entity.scheduleLastChangedTime)
      _stmt.setBoolean(12, entity.scheduleActive)
      _stmt.setLong(13, entity.scheduleUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateListAsync(entityList: List<Schedule>): Unit {
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.sceduleStartTime)
        _stmt.setLong(2, _entity.scheduleEndTime)
        _stmt.setInt(3, _entity.scheduleDay)
        _stmt.setInt(4, _entity.scheduleMonth)
        _stmt.setInt(5, _entity.scheduleFrequency)
        _stmt.setLong(6, _entity.umCalendarUid)
        _stmt.setLong(7, _entity.scheduleClazzUid)
        _stmt.setLong(8, _entity.scheduleMasterChangeSeqNum)
        _stmt.setLong(9, _entity.scheduleLocalChangeSeqNum)
        _stmt.setInt(10, _entity.scheduleLastChangedBy)
        _stmt.setLong(11, _entity.scheduleLastChangedTime)
        _stmt.setBoolean(12, _entity.scheduleActive)
        _stmt.setLong(13, _entity.scheduleUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |      SELECT DISTINCT Schedule.scheduleUid AS schedulePk,
    |             ? AS scheduleDestination
    |        FROM UserSession
    |              JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |              
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |              JOIN Schedule
    |                   ON Schedule.scheduleClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Schedule.scheduleLastChangedTime != COALESCE(
    |             (SELECT scheduleVersionId
    |                FROM ScheduleReplicate
    |               WHERE schedulePk = Schedule.scheduleUid
    |                 AND scheduleDestination = ?), 0) 
    |      /*psql ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |             SET schedulePending = true
    |      */       
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |      SELECT DISTINCT Schedule.scheduleUid AS schedulePk,
    |             ? AS scheduleDestination
    |        FROM UserSession
    |              JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |              
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |              JOIN Schedule
    |                   ON Schedule.scheduleClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Schedule.scheduleLastChangedTime != COALESCE(
    |             (SELECT scheduleVersionId
    |                FROM ScheduleReplicate
    |               WHERE schedulePk = Schedule.scheduleUid
    |                 AND scheduleDestination = ?), 0) 
    |       ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |             SET schedulePending = true
    |             
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |  SELECT DISTINCT Schedule.scheduleUid AS scheduleUid,
    |         UserSession.usClientNodeId AS scheduleDestination
    |    FROM ChangeLog
    |         JOIN Schedule
    |              ON ChangeLog.chTableId = 21
    |                 AND Schedule.scheduleUid = ChangeLog.chEntityPk
    |         JOIN Clazz
    |              ON Clazz.clazzUid = Schedule.scheduleClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Schedule.scheduleLastChangedTime != COALESCE(
    |         (SELECT scheduleVersionId
    |            FROM ScheduleReplicate
    |           WHERE schedulePk = Schedule.scheduleUid
    |             AND scheduleDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |     SET schedulePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |  SELECT DISTINCT Schedule.scheduleUid AS scheduleUid,
    |         UserSession.usClientNodeId AS scheduleDestination
    |    FROM ChangeLog
    |         JOIN Schedule
    |              ON ChangeLog.chTableId = 21
    |                 AND Schedule.scheduleUid = ChangeLog.chEntityPk
    |         JOIN Clazz
    |              ON Clazz.clazzUid = Schedule.scheduleClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Schedule.scheduleLastChangedTime != COALESCE(
    |         (SELECT scheduleVersionId
    |            FROM ScheduleReplicate
    |           WHERE schedulePk = Schedule.scheduleUid
    |             AND scheduleDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |     SET schedulePending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE Schedule 
    |           SET scheduleActive = ?,
    |               scheduleLastChangedTime = ?
    |         WHERE scheduleUid = ?
    |            
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,scheduleUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): Schedule? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): Schedule? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }

  public override fun findAllSchedulesByClazzUid(clazzUid: Long): DataSourceFactory<Int, Schedule> =
      object : DataSourceFactory<Int, Schedule>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<Schedule>> =
        LiveDataImpl(_db, listOf("Schedule"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) LIMIT ? OFFSET ?"
          )) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_scheduleUid = _result.getLong("scheduleUid")
            val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
            val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
            val _tmp_scheduleDay = _result.getInt("scheduleDay")
            val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
            val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
            val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
            val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
            val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
            val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
            val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
            val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
            val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
            Schedule().apply {
              this.scheduleUid = _tmp_scheduleUid
              this.sceduleStartTime = _tmp_sceduleStartTime
              this.scheduleEndTime = _tmp_scheduleEndTime
              this.scheduleDay = _tmp_scheduleDay
              this.scheduleMonth = _tmp_scheduleMonth
              this.scheduleFrequency = _tmp_scheduleFrequency
              this.umCalendarUid = _tmp_umCalendarUid
              this.scheduleClazzUid = _tmp_scheduleClazzUid
              this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
              this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
              this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
              this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
              this.scheduleActive = _tmp_scheduleActive
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Schedule"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) "
          )) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1"
      )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }

  public override fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long): LiveData<List<Schedule>>
      = LiveDataImpl(_db, listOf("Schedule"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1"
        )) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_scheduleUid = _result.getLong("scheduleUid")
          val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
          val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
          val _tmp_scheduleDay = _result.getInt("scheduleDay")
          val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
          val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
          val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
          val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
          val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
          val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
          val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
          val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
          val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
          Schedule().apply {
            this.scheduleUid = _tmp_scheduleUid
            this.sceduleStartTime = _tmp_sceduleStartTime
            this.scheduleEndTime = _tmp_scheduleEndTime
            this.scheduleDay = _tmp_scheduleDay
            this.scheduleMonth = _tmp_scheduleMonth
            this.scheduleFrequency = _tmp_scheduleFrequency
            this.umCalendarUid = _tmp_umCalendarUid
            this.scheduleClazzUid = _tmp_scheduleClazzUid
            this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
            this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
            this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
            this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
            this.scheduleActive = _tmp_scheduleActive
          }
        }
      }
    }
  }

  public override suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 "
      )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }
}
