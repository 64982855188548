package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class VerbDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: VerbDao,
) : VerbDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUrl(urlId: String?): VerbEntity? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> =
      _dao.findByUidList(uidList)

  public override suspend fun replaceList(entityList: List<VerbEntity>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.verbUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(62)
        it.verbUid = _newPk
        _generatedPks += _newPk
      }
      it.verbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceList(entityList)
  }

  public override fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findAllVerbsAsc(uidList: List<Long>): DataSourceFactory<Int, VerbDisplay> =
      _dao.findAllVerbsAsc(uidList)

  public override fun findAllVerbsDesc(uidList: List<Long>): DataSourceFactory<Int, VerbDisplay> =
      _dao.findAllVerbsDesc(uidList)

  public override fun insert(entity: VerbEntity): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: VerbEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.verbUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(62)
      entity.verbUid = _newPk
    }
    entity.verbLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.verbUid
  }

  public override fun insertList(entityList: List<VerbEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<VerbEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: VerbEntity): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
