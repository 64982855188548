package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ClazzContentJoin
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzContentJoinDao : BaseDao<ClazzContentJoin> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun toggleVisibilityClazzContent(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long,
  ): Unit

  public actual abstract suspend fun listOfEntriesInClazz(clazzUid: Long): List<Long>
}
