package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonAuth2
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonAuth2Dao_JdbcKt(
  public val _db: RoomDatabase,
) : PersonAuth2Dao() {
  public val _insertAdapterPersonAuth2_: EntityInsertionAdapter<PersonAuth2> = object :
      EntityInsertionAdapter<PersonAuth2>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO PersonAuth2 (pauthUid, pauthMechanism, pauthAuth, pauthLcsn, pauthPcsn, pauthLcb, pauthLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonAuth2):
        Unit {
      stmt.setLong(1, entity.pauthUid)
      stmt.setString(2, entity.pauthMechanism)
      stmt.setString(3, entity.pauthAuth)
      stmt.setLong(4, entity.pauthLcsn)
      stmt.setLong(5, entity.pauthPcsn)
      stmt.setLong(6, entity.pauthLcb)
      stmt.setLong(7, entity.pauthLct)
    }
  }

  public val _insertAdapterPersonAuth2_upsert: EntityInsertionAdapter<PersonAuth2> = object :
      EntityInsertionAdapter<PersonAuth2>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO PersonAuth2 (pauthUid, pauthMechanism, pauthAuth, pauthLcsn, pauthPcsn, pauthLcb, pauthLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonAuth2):
        Unit {
      stmt.setLong(1, entity.pauthUid)
      stmt.setString(2, entity.pauthMechanism)
      stmt.setString(3, entity.pauthAuth)
      stmt.setLong(4, entity.pauthLcsn)
      stmt.setLong(5, entity.pauthPcsn)
      stmt.setLong(6, entity.pauthLcb)
      stmt.setLong(7, entity.pauthLct)
    }
  }

  public override suspend fun insertListAsync(auths: List<PersonAuth2>): Unit {
    _insertAdapterPersonAuth2_.insertListAsync(auths)
  }

  public override suspend fun insertAsync(auth: PersonAuth2): Long {
    val _retVal = _insertAdapterPersonAuth2_upsert.insertAndReturnIdAsync(auth)
    return _retVal
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonAuth2Replicate(paPk, paDestination)
    |      SELECT DISTINCT PersonAuth2.pauthUid AS paUid,
    |             ? AS paDestination
    |        FROM UserSession
    |        JOIN PersonGroupMember
    |             ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |        
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                562949953421312
    |                
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |        JOIN PersonAuth2
    |             ON PersonAuth2.pauthUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?      
    |         AND PersonAuth2.pauthLct != COALESCE(
    |             (SELECT paVersionId
    |                FROM PersonAuth2Replicate
    |               WHERE paPk = PersonAuth2.pauthUid
    |                 AND paDestination = ?), 0) 
    |      /*psql ON CONFLICT(paPk, paDestination) DO UPDATE
    |             SET paPending = true
    |      */       
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonAuth2Replicate(paPk, paDestination)
    |      SELECT DISTINCT PersonAuth2.pauthUid AS paUid,
    |             ? AS paDestination
    |        FROM UserSession
    |        JOIN PersonGroupMember
    |             ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |        
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                562949953421312
    |                
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |        JOIN PersonAuth2
    |             ON PersonAuth2.pauthUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?      
    |         AND PersonAuth2.pauthLct != COALESCE(
    |             (SELECT paVersionId
    |                FROM PersonAuth2Replicate
    |               WHERE paPk = PersonAuth2.pauthUid
    |                 AND paDestination = ?), 0) 
    |       ON CONFLICT(paPk, paDestination) DO UPDATE
    |             SET paPending = true
    |             
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonAuth2Replicate(paPk, paDestination)
    |  SELECT DISTINCT PersonAuth2.pauthUid AS paUid,
    |         UserSession.usClientNodeId AS paDestination
    |    FROM ChangeLog
    |         JOIN PersonAuth2
    |             ON ChangeLog.chTableId = 678
    |                AND ChangeLog.chEntityPk = PersonAuth2.pauthUid
    |         JOIN Person
    |              ON Person.personUid = PersonAuth2.pauthUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              562949953421312
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonAuth2.pauthLct != COALESCE(
    |         (SELECT paVersionId
    |            FROM PersonAuth2Replicate
    |           WHERE paPk = PersonAuth2.pauthUid
    |             AND paDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(paPk, paDestination) DO UPDATE
    |     SET paPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonAuth2Replicate(paPk, paDestination)
    |  SELECT DISTINCT PersonAuth2.pauthUid AS paUid,
    |         UserSession.usClientNodeId AS paDestination
    |    FROM ChangeLog
    |         JOIN PersonAuth2
    |             ON ChangeLog.chTableId = 678
    |                AND ChangeLog.chEntityPk = PersonAuth2.pauthUid
    |         JOIN Person
    |              ON Person.personUid = PersonAuth2.pauthUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              562949953421312
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonAuth2.pauthLct != COALESCE(
    |         (SELECT paVersionId
    |            FROM PersonAuth2Replicate
    |           WHERE paPk = PersonAuth2.pauthUid
    |             AND paDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(paPk, paDestination) DO UPDATE
    |     SET paPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByPersonUid(personUid: Long): PersonAuth2? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT PersonAuth2.*
  |          FROM PersonAuth2
  |         WHERE PersonAuth2.pauthUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_pauthUid = _result.getLong("pauthUid")
        val _tmp_pauthMechanism = _result.getString("pauthMechanism")
        val _tmp_pauthAuth = _result.getString("pauthAuth")
        val _tmp_pauthLcsn = _result.getLong("pauthLcsn")
        val _tmp_pauthPcsn = _result.getLong("pauthPcsn")
        val _tmp_pauthLcb = _result.getLong("pauthLcb")
        val _tmp_pauthLct = _result.getLong("pauthLct")
        PersonAuth2().apply {
          this.pauthUid = _tmp_pauthUid
          this.pauthMechanism = _tmp_pauthMechanism
          this.pauthAuth = _tmp_pauthAuth
          this.pauthLcsn = _tmp_pauthLcsn
          this.pauthPcsn = _tmp_pauthPcsn
          this.pauthLcb = _tmp_pauthLcb
          this.pauthLct = _tmp_pauthLct
        }
      }
    }
  }

  public override suspend fun findByUsername(username: String): PersonAuth2? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT PersonAuth2.*
  |          FROM PersonAuth2
  |               JOIN Person ON PersonAuth2.pauthUid = Person.personUid
  |         WHERE Person.username = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_pauthUid = _result.getLong("pauthUid")
        val _tmp_pauthMechanism = _result.getString("pauthMechanism")
        val _tmp_pauthAuth = _result.getString("pauthAuth")
        val _tmp_pauthLcsn = _result.getLong("pauthLcsn")
        val _tmp_pauthPcsn = _result.getLong("pauthPcsn")
        val _tmp_pauthLcb = _result.getLong("pauthLcb")
        val _tmp_pauthLct = _result.getLong("pauthLct")
        PersonAuth2().apply {
          this.pauthUid = _tmp_pauthUid
          this.pauthMechanism = _tmp_pauthMechanism
          this.pauthAuth = _tmp_pauthAuth
          this.pauthLcsn = _tmp_pauthLcsn
          this.pauthPcsn = _tmp_pauthPcsn
          this.pauthLcb = _tmp_pauthLcb
          this.pauthLct = _tmp_pauthLct
        }
      }
    }
  }
}
