package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.CourseGroupSet
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CourseGroupSetDao : BaseDao<CourseGroupSet> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun updateAsync(entity: CourseGroupSet): Int

  public actual abstract fun findAllCourseGroupSetForClazz(clazzUid: Long):
      DataSourceFactory<Int, CourseGroupSet>

  public actual abstract fun findAllCourseGroupSetForClazzList(clazzUid: Long): List<CourseGroupSet>

  public actual abstract suspend fun findByUidAsync(uid: Long): CourseGroupSet?
}
