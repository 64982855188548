package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ChatMember
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ChatMemberDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ChatMemberDao,
) : ChatMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun insert(entity: ChatMember): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ChatMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.chatMemberUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(128)
      entity.chatMemberUid = _newPk
    }
    entity.chatMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.chatMemberUid
  }

  public override fun insertList(entityList: List<ChatMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ChatMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ChatMember): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
