package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import com.ustadmobile.lib.db.entities.SchoolWithMemberCountAndLocation
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class SchoolDao : BaseDao<School> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun findByUidAsync(schoolUid: Long): School?

  public actual abstract suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      SchoolWithHolidayCalendar?

  public actual abstract suspend fun findBySchoolCode(code: String): School?

  public actual abstract suspend fun findBySchoolCodeFromWeb(code: String): School?

  public actual abstract suspend fun personHasPermissionWithSchool(
    accountPersonUid: Long,
    schoolUid: Long,
    permission: Long,
  ): Boolean

  public actual abstract fun findAllActiveSchoolWithMemberCountAndLocationName(
    searchBit: String,
    personUid: Long,
    permission: Long,
    sortOrder: Int,
  ): DataSourceFactory<Int, SchoolWithMemberCountAndLocation>

  public actual abstract suspend fun updateAsync(entity: School): Int
}
