package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.StateEntity
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class StateDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: StateDao,
) : StateDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByStateId(
    id: String?,
    agentUid: Long,
    activityId: String?,
    registration: String?,
  ): StateEntity? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findStateIdByAgentAndActivity(
    agentUid: Long,
    activityId: String,
    registration: String,
    since: String,
  ): List<StateEntity> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateStateToInActive(
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ): Unit = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun setStateInActive(
    stateId: String,
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ): Unit = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: StateEntity): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: StateEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.stateUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(70)
      entity.stateUid = _newPk
    }
    entity.stateLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.stateUid
  }

  public override fun insertList(entityList: List<StateEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<StateEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: StateEntity): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
