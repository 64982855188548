package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.CommentsWithPerson
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CommentsDao : BaseDao<Comments>, OneToManyJoinDao<Comments> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByUidAsync(uid: Long): Comments?

  public actual abstract fun findPublicByEntityTypeAndUidLive(entityType: Int, entityUid: Long):
      DataSourceFactory<Int, CommentsWithPerson>

  public actual abstract fun findPrivateByEntityTypeAndUidAndForPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson>

  public actual abstract fun findPrivateByEntityTypeAndUidAndForPersonLive2(
    entityType: Int,
    entityUid: Long,
    submitterUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson>

  public actual abstract fun findPrivateByEntityTypeAndUidAndPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long,
  ): DataSourceFactory<Int, CommentsWithPerson>

  public actual abstract fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToLive(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
  ): DataSourceFactory<Int, CommentsWithPerson>

  public actual abstract fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToTest(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
    personTo: Long,
  ): List<CommentsWithPerson>

  public actual abstract suspend fun updateInActiveByCommentUid(
    uid: Long,
    inActive: Boolean,
    changeTime: Long,
  ): Unit
}
