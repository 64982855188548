package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.Message
import com.ustadmobile.lib.db.entities.MessageWithPerson
import kotlin.Int
import kotlin.Long
import kotlin.Unit

public actual abstract class MessageDao : BaseDao<Message> {
  public actual abstract suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnNewNodePosts(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChangeChat(): Unit

  public actual abstract suspend fun replicateOnChangePosts(): Unit

  public actual abstract fun findAllMessagesByChatUid(
    entityUid: Long,
    tableId: Int,
    loggedInPersonUid: Long,
  ): DataSourceFactory<Int, MessageWithPerson>
}
