package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Chat
import com.ustadmobile.lib.db.entities.ChatWithLatestMessageAndCount
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ChatDao_JdbcKt(
  public val _db: RoomDatabase,
) : ChatDao() {
  public val _insertAdapterChat_: EntityInsertionAdapter<Chat> = object :
      EntityInsertionAdapter<Chat>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Chat (chatUid, chatStartDate, chatTitle, chatGroup, chatLct) VALUES(?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Chat): Unit {
      if(entity.chatUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.chatUid)
      }
      stmt.setLong(2, entity.chatStartDate)
      stmt.setString(3, entity.chatTitle)
      stmt.setBoolean(4, entity.chatGroup)
      stmt.setLong(5, entity.chatLct)
    }
  }

  public override fun insert(entity: Chat): Long {
    val _retVal = _insertAdapterChat_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Chat): Long {
    val _retVal = _insertAdapterChat_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Chat>): Unit {
    _insertAdapterChat_.insertList(entityList)
  }

  public override fun updateList(entityList: List<Chat>): Unit {
    val _sql =
        "UPDATE Chat SET chatStartDate = ?, chatTitle = ?, chatGroup = ?, chatLct = ? WHERE chatUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.chatStartDate)
        _stmt.setString(2, _entity.chatTitle)
        _stmt.setBoolean(3, _entity.chatGroup)
        _stmt.setLong(4, _entity.chatLct)
        _stmt.setLong(5, _entity.chatUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Chat): Unit {
    val _sql =
        "UPDATE Chat SET chatStartDate = ?, chatTitle = ?, chatGroup = ?, chatLct = ? WHERE chatUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.chatStartDate)
      _stmt.setString(2, entity.chatTitle)
      _stmt.setBoolean(3, entity.chatGroup)
      _stmt.setLong(4, entity.chatLct)
      _stmt.setLong(5, entity.chatUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO chatReplicate(chatPk, chatDestination)
    |      SELECT DISTINCT Chat.chatUid AS chatPk,
    |             ? AS chatDestination
    |        FROM UserSession 
    |             JOIN Chat ON 
    |                  ((Chat.chatUid IN 
    |                       (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                   OR UserSession.usSessionType = 2)
    |                  AND UserSession.usStatus = 1 
    |       WHERE UserSession.usClientNodeId = ?
    |         AND Chat.chatLct != COALESCE(
    |             (SELECT chatVersionId
    |                FROM chatReplicate
    |               WHERE chatPk = Chat.chatUid
    |                 AND chatDestination = ?), 0) 
    |      /*psql ON CONFLICT(chatPk, chatDestination) DO UPDATE
    |             SET chatPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO chatReplicate(chatPk, chatDestination)
    |      SELECT DISTINCT Chat.chatUid AS chatPk,
    |             ? AS chatDestination
    |        FROM UserSession 
    |             JOIN Chat ON 
    |                  ((Chat.chatUid IN 
    |                       (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                   OR UserSession.usSessionType = 2)
    |                  AND UserSession.usStatus = 1 
    |       WHERE UserSession.usClientNodeId = ?
    |         AND Chat.chatLct != COALESCE(
    |             (SELECT chatVersionId
    |                FROM chatReplicate
    |               WHERE chatPk = Chat.chatUid
    |                 AND chatDestination = ?), 0) 
    |       ON CONFLICT(chatPk, chatDestination) DO UPDATE
    |             SET chatPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         REPLACE INTO chatReplicate(chatPk, chatDestination)
    |          SELECT DISTINCT Chat.chatUid AS chatUid,
    |                 UserSession.usClientNodeId AS chatDestination
    |            FROM ChangeLog
    |                 JOIN Chat
    |                      ON ChangeLog.chTableId = 127
    |                         AND ChangeLog.chEntityPk = Chat.chatUid
    |                 JOIN UserSession ON 
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid 
    |                              FROM ChatMember 
    |                             WHERE ChatMember.chatMemberChatUid = Chat.chatUid))
    |                       OR UserSession.usSessionType = 2 )
    |                      AND UserSession.usStatus = 1
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Chat.chatLct != COALESCE(
    |                 (SELECT chatVersionId
    |                    FROM chatReplicate
    |                   WHERE chatPk = Chat.chatUid
    |                     AND chatDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(chatPk, chatDestination) DO UPDATE
    |             SET chatPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO chatReplicate(chatPk, chatDestination)
    |          SELECT DISTINCT Chat.chatUid AS chatUid,
    |                 UserSession.usClientNodeId AS chatDestination
    |            FROM ChangeLog
    |                 JOIN Chat
    |                      ON ChangeLog.chTableId = 127
    |                         AND ChangeLog.chEntityPk = Chat.chatUid
    |                 JOIN UserSession ON 
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid 
    |                              FROM ChatMember 
    |                             WHERE ChatMember.chatMemberChatUid = Chat.chatUid))
    |                       OR UserSession.usSessionType = 2 )
    |                      AND UserSession.usStatus = 1
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Chat.chatLct != COALESCE(
    |                 (SELECT chatVersionId
    |                    FROM chatReplicate
    |                   WHERE chatPk = Chat.chatUid
    |                     AND chatDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(chatPk, chatDestination) DO UPDATE
    |             SET chatPending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllChatsForUser(searchBit: String, personUid: Long):
      DataSourceFactory<Int, ChatWithLatestMessageAndCount> = object :
      DataSourceFactory<Int, ChatWithLatestMessageAndCount>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ChatWithLatestMessageAndCount>> = LiveDataImpl(_db, listOf("Message",
        "MessageRead", "ChatMember", "Chat", "Person", "PersonGroupMember", "ScopedGrant"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Chat.*,
      |               Message.messageText AS latestMessage,
      |               Message.messageTimestamp AS latestMessageTimestamp,
      |               op.personUid AS otherPersonUid,
      |               op.firstNames AS otherPersonFirstNames,
      |               op.lastName AS otherPersonLastName,
      |               (
      |				SELECT COUNT(*) 
      |				  FROM Message 
      |				 WHERE Message.messageTableId = 127 
      |				   AND Message.messageEntityUid = Chat.chatUid 
      |				   AND Message.messageSenderPersonUid != ?
      |				   AND Message.messageTimestamp > coalesce((
      |						SELECT MessageRead.messageReadLct FROM MessageRead 
      |						WHERE MessageRead.messageReadPersonUid = ?
      |						AND MessageRead.messageReadMessageUid = Message.messageUid 
      |				      ), 0)
      |					
      |				
      |			   ) AS unreadMessageCount,
      |        
      |               (SELECT COUNT(*)
      |                  FROM ChatMember mm
      |                  WHERE mm.chatMemberChatUid = Chat.chatUid ) AS numMembers
      |          FROM ChatMember
      |               LEFT JOIN Chat 
      |                    ON Chat.chatUid = ChatMember.chatMemberChatUid
      |               LEFT JOIN Message 
      |                    ON Message.messageUid =
      |                        (SELECT messageUid
      |                           FROM Message
      |                          WHERE messageEntityUid = Chat.chatUid
      |                            AND messageTableId = 127
      |                       ORDER BY messageTimestamp DESC
      |                          LIMIT 1)
      |               LEFT JOIN Person op 
      |                    ON op.personUid =
      |                       (SELECT pp.personUid
      |                          FROM ChatMember cm
      |                               LEFT JOIN Person pp 
      |                                    ON pp.personUid = cm.chatMemberPersonUid
      |                         WHERE cm.chatMemberChatUid = Chat.chatUid
      |                           AND cm.chatMemberPersonUid != ?
      |                           AND cm.chatMemberLeftDate = 9223372036854775807
      |                         LIMIT 1)
      |         WHERE ChatMember.chatMemberPersonUid = ?
      |           AND ChatMember.chatMemberLeftDate = 9223372036854775807
      |           AND Chat.chatUid != 0 
      |           AND op.firstNames||' '||op.lastName LIKE ? 
      |        -- When in search mode we need to add all Persons who match the search to the list, even if
      |        -- no chat has started
      |        UNION
      |        SELECT Chat.*,
      |               '' AS latestMessage,
      |                    0 AS latestMessageTimestamp,
      |                    Person.personUid AS otherPersonUid,
      |                    Person.firstNames AS otherPersonFirstNames,
      |                    Person.lastName AS otherPersonLastName,
      |                    0 AS unreadMessageCount,
      |                    0 AS numMembers
      |                              
      |          FROM PersonGroupMember
      |             
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions &
      |                    64
      |                    
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |		  
      |               LEFT JOIN Chat
      |                    ON Chat.chatUid = 0
      |         WHERE ? != '%'
      |           AND PersonGroupMember.groupMemberPersonUid = ?
      |           AND Person.personUid != ?
      |        
      |           AND Person.personUid NOT IN
      |			   (
      |				SELECT chatpeople.personUid 
      |				  FROM ChatMember cmm
      |					   LEFT JOIN Chat cc 
      |							  ON cc.chatUid = cmm.chatMemberChatUid 
      |			   
      |				 LEFT JOIN Person chatpeople 
      |                    ON chatpeople.personUid =
      |                       (SELECT chatpeopleother.personUid
      |                          FROM ChatMember cm
      |                               LEFT JOIN Person chatpeopleother 
      |                                    ON chatpeopleother.personUid = cm.chatMemberPersonUid
      |                         WHERE cm.chatMemberChatUid = cc.chatUid
      |                           AND cm.chatMemberPersonUid != ?
      |                         LIMIT 1)
      |						 
      |				 WHERE cc.chatUid != 0 
      |				   AND cmm.chatMemberPersonUid = ?
      |				 )
      |                            
      |           AND Person.firstNames||' '||Person.lastName LIKE ? 
      |         ORDER BY latestMessageTimestamp DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.setLong(3,personUid)
        _stmt.setLong(4,personUid)
        _stmt.setString(5,searchBit)
        _stmt.setString(6,searchBit)
        _stmt.setLong(7,personUid)
        _stmt.setLong(8,personUid)
        _stmt.setLong(9,personUid)
        _stmt.setLong(10,personUid)
        _stmt.setString(11,searchBit)
        _stmt.setInt(12,_limit)
        _stmt.setInt(13,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_unreadMessageCount = _result.getInt("unreadMessageCount")
            val _tmp_latestMessage = _result.getString("latestMessage")
            val _tmp_latestMessageTimestamp = _result.getLong("latestMessageTimestamp")
            val _tmp_otherPersonUid = _result.getLong("otherPersonUid")
            val _tmp_otherPersonFirstNames = _result.getString("otherPersonFirstNames")
            val _tmp_otherPersonLastName = _result.getString("otherPersonLastName")
            val _tmp_chatUid = _result.getLong("chatUid")
            val _tmp_chatStartDate = _result.getLong("chatStartDate")
            val _tmp_chatTitle = _result.getString("chatTitle")
            val _tmp_chatGroup = _result.getBoolean("chatGroup")
            val _tmp_chatLct = _result.getLong("chatLct")
            ChatWithLatestMessageAndCount().apply {
              this.unreadMessageCount = _tmp_unreadMessageCount
              this.latestMessage = _tmp_latestMessage
              this.latestMessageTimestamp = _tmp_latestMessageTimestamp
              this.otherPersonUid = _tmp_otherPersonUid
              this.otherPersonFirstNames = _tmp_otherPersonFirstNames
              this.otherPersonLastName = _tmp_otherPersonLastName
              this.chatUid = _tmp_chatUid
              this.chatStartDate = _tmp_chatStartDate
              this.chatTitle = _tmp_chatTitle
              this.chatGroup = _tmp_chatGroup
              this.chatLct = _tmp_chatLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Message",
        "MessageRead", "ChatMember", "Chat", "Person", "PersonGroupMember", "ScopedGrant"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Chat.*,
      |               Message.messageText AS latestMessage,
      |               Message.messageTimestamp AS latestMessageTimestamp,
      |               op.personUid AS otherPersonUid,
      |               op.firstNames AS otherPersonFirstNames,
      |               op.lastName AS otherPersonLastName,
      |               (
      |				SELECT COUNT(*) 
      |				  FROM Message 
      |				 WHERE Message.messageTableId = 127 
      |				   AND Message.messageEntityUid = Chat.chatUid 
      |				   AND Message.messageSenderPersonUid != ?
      |				   AND Message.messageTimestamp > coalesce((
      |						SELECT MessageRead.messageReadLct FROM MessageRead 
      |						WHERE MessageRead.messageReadPersonUid = ?
      |						AND MessageRead.messageReadMessageUid = Message.messageUid 
      |				      ), 0)
      |					
      |				
      |			   ) AS unreadMessageCount,
      |        
      |               (SELECT COUNT(*)
      |                  FROM ChatMember mm
      |                  WHERE mm.chatMemberChatUid = Chat.chatUid ) AS numMembers
      |          FROM ChatMember
      |               LEFT JOIN Chat 
      |                    ON Chat.chatUid = ChatMember.chatMemberChatUid
      |               LEFT JOIN Message 
      |                    ON Message.messageUid =
      |                        (SELECT messageUid
      |                           FROM Message
      |                          WHERE messageEntityUid = Chat.chatUid
      |                            AND messageTableId = 127
      |                       ORDER BY messageTimestamp DESC
      |                          LIMIT 1)
      |               LEFT JOIN Person op 
      |                    ON op.personUid =
      |                       (SELECT pp.personUid
      |                          FROM ChatMember cm
      |                               LEFT JOIN Person pp 
      |                                    ON pp.personUid = cm.chatMemberPersonUid
      |                         WHERE cm.chatMemberChatUid = Chat.chatUid
      |                           AND cm.chatMemberPersonUid != ?
      |                           AND cm.chatMemberLeftDate = 9223372036854775807
      |                         LIMIT 1)
      |         WHERE ChatMember.chatMemberPersonUid = ?
      |           AND ChatMember.chatMemberLeftDate = 9223372036854775807
      |           AND Chat.chatUid != 0 
      |           AND op.firstNames||' '||op.lastName LIKE ? 
      |        -- When in search mode we need to add all Persons who match the search to the list, even if
      |        -- no chat has started
      |        UNION
      |        SELECT Chat.*,
      |               '' AS latestMessage,
      |                    0 AS latestMessageTimestamp,
      |                    Person.personUid AS otherPersonUid,
      |                    Person.firstNames AS otherPersonFirstNames,
      |                    Person.lastName AS otherPersonLastName,
      |                    0 AS unreadMessageCount,
      |                    0 AS numMembers
      |                              
      |          FROM PersonGroupMember
      |             
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions &
      |                    64
      |                    
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |		  
      |               LEFT JOIN Chat
      |                    ON Chat.chatUid = 0
      |         WHERE ? != '%'
      |           AND PersonGroupMember.groupMemberPersonUid = ?
      |           AND Person.personUid != ?
      |        
      |           AND Person.personUid NOT IN
      |			   (
      |				SELECT chatpeople.personUid 
      |				  FROM ChatMember cmm
      |					   LEFT JOIN Chat cc 
      |							  ON cc.chatUid = cmm.chatMemberChatUid 
      |			   
      |				 LEFT JOIN Person chatpeople 
      |                    ON chatpeople.personUid =
      |                       (SELECT chatpeopleother.personUid
      |                          FROM ChatMember cm
      |                               LEFT JOIN Person chatpeopleother 
      |                                    ON chatpeopleother.personUid = cm.chatMemberPersonUid
      |                         WHERE cm.chatMemberChatUid = cc.chatUid
      |                           AND cm.chatMemberPersonUid != ?
      |                         LIMIT 1)
      |						 
      |				 WHERE cc.chatUid != 0 
      |				   AND cmm.chatMemberPersonUid = ?
      |				 )
      |                            
      |           AND Person.firstNames||' '||Person.lastName LIKE ? 
      |         ORDER BY latestMessageTimestamp DESC
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.setLong(3,personUid)
        _stmt.setLong(4,personUid)
        _stmt.setString(5,searchBit)
        _stmt.setString(6,searchBit)
        _stmt.setLong(7,personUid)
        _stmt.setLong(8,personUid)
        _stmt.setLong(9,personUid)
        _stmt.setLong(10,personUid)
        _stmt.setString(11,searchBit)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun getTitleChat(chatUid: Long, personUid: Long): String? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT CASE
  |                   WHEN Chat.chatGroup THEN Chat.chatTitle
  |                   ELSE Person.firstNames||' '||Person.lastName
  |               END AS title
  |        FROM Chat
  |        LEFT JOIN Person 
  |        ON CAST(Chat.chatGroup AS INTEGER) = 0
  |           AND Person.personUid =
  |          (SELECT pp.personUid
  |           FROM ChatMember cm
  |           LEFT JOIN Person pp ON pp.personUid = cm.chatMemberPersonUid
  |           WHERE cm.chatMemberChatUid = Chat.chatUid
  |             AND cm.chatMemberPersonUid != ?
  |             AND cm.chatMemberLeftDate = 9223372036854775807
  |           LIMIT 1)
  |        WHERE Chat.chatUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.setLong(2,chatUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }

  public override suspend fun getChatByOtherPerson(otherPersonUid: Long, loggedInPersonUid: Long):
      Chat? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Chat.*
  |          FROM ChatMember
  |          LEFT JOIN Chat ON Chat.chatUid = ChatMember.chatMemberChatUid
  |         WHERE ChatMember.chatMemberPersonUid = ?
  |           AND CAST(Chat.chatGroup AS INTEGER) = 0
  |           AND Chat.chatUid IN 
  |               (
  |                SELECT ChatMember.chatMemberChatUid
  |                  FROM ChatMember
  |                 WHERE ChatMember.chatMemberChatUid = Chat.chatUid
  |                   AND ChatMember.chatMemberPersonUid = ? 
  |               ) 
  |           AND ? != ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,otherPersonUid)
    _stmt.setLong(2,loggedInPersonUid)
    _stmt.setLong(3,otherPersonUid)
    _stmt.setLong(4,loggedInPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_chatUid = _result.getLong("chatUid")
        val _tmp_chatStartDate = _result.getLong("chatStartDate")
        val _tmp_chatTitle = _result.getString("chatTitle")
        val _tmp_chatGroup = _result.getBoolean("chatGroup")
        val _tmp_chatLct = _result.getLong("chatLct")
        Chat().apply {
          this.chatUid = _tmp_chatUid
          this.chatStartDate = _tmp_chatStartDate
          this.chatTitle = _tmp_chatTitle
          this.chatGroup = _tmp_chatGroup
          this.chatLct = _tmp_chatLct
        }
      }
    }
  }
}
