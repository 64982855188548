package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.AgentDao
import com.ustadmobile.core.db.dao.AgentDao_Repo
import com.ustadmobile.core.db.dao.ChatDao
import com.ustadmobile.core.db.dao.ChatDao_Repo
import com.ustadmobile.core.db.dao.ChatMemberDao
import com.ustadmobile.core.db.dao.ChatMemberDao_Repo
import com.ustadmobile.core.db.dao.ClazzAssignmentContentJoinDao
import com.ustadmobile.core.db.dao.ClazzAssignmentContentJoinDao_Repo
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao_Repo
import com.ustadmobile.core.db.dao.ClazzAssignmentRollUpDao
import com.ustadmobile.core.db.dao.ClazzAssignmentRollUpDao_Repo
import com.ustadmobile.core.db.dao.ClazzContentJoinDao
import com.ustadmobile.core.db.dao.ClazzContentJoinDao_Repo
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzDao_Repo
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao_Repo
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao_Repo
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.ClazzLogDao_Repo
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.CommentsDao_Repo
import com.ustadmobile.core.db.dao.ConnectivityStatusDao
import com.ustadmobile.core.db.dao.ContainerDao
import com.ustadmobile.core.db.dao.ContainerDao_Repo
import com.ustadmobile.core.db.dao.ContainerETagDao
import com.ustadmobile.core.db.dao.ContainerEntryDao
import com.ustadmobile.core.db.dao.ContainerEntryFileDao
import com.ustadmobile.core.db.dao.ContainerImportJobDao
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategoryDao_Repo
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao_Repo
import com.ustadmobile.core.db.dao.ContentJobDao
import com.ustadmobile.core.db.dao.ContentJobItemDao
import com.ustadmobile.core.db.dao.ContextXObjectStatementJoinDao
import com.ustadmobile.core.db.dao.ContextXObjectStatementJoinDao_Repo
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao_Repo
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionAttachmentDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionAttachmentDao_Repo
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao_Repo
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockDao_Repo
import com.ustadmobile.core.db.dao.CourseDiscussionDao
import com.ustadmobile.core.db.dao.CourseDiscussionDao_Repo
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao_Repo
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao_Repo
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CoursePictureDao_Repo
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao_Repo
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.DiscussionPostDao_Repo
import com.ustadmobile.core.db.dao.DiscussionTopicDao
import com.ustadmobile.core.db.dao.DiscussionTopicDao_Repo
import com.ustadmobile.core.db.dao.EntityRoleDao
import com.ustadmobile.core.db.dao.EntityRoleDao_Repo
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ErrorReportDao_Repo
import com.ustadmobile.core.db.dao.GroupLearningSessionDao
import com.ustadmobile.core.db.dao.GroupLearningSessionDao_Repo
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao_Repo
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.HolidayDao_Repo
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageDao_Repo
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LanguageVariantDao_Repo
import com.ustadmobile.core.db.dao.LearnerGroupDao
import com.ustadmobile.core.db.dao.LearnerGroupDao_Repo
import com.ustadmobile.core.db.dao.LearnerGroupMemberDao
import com.ustadmobile.core.db.dao.LearnerGroupMemberDao_Repo
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.LeavingReasonDao_Repo
import com.ustadmobile.core.db.dao.LocallyAvailableContainerDao
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.MessageDao_Repo
import com.ustadmobile.core.db.dao.MessageReadDao
import com.ustadmobile.core.db.dao.MessageReadDao_Repo
import com.ustadmobile.core.db.dao.NetworkNodeDao
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuth2Dao_Repo
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonDao_Repo
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupDao_Repo
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao_Repo
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao_Repo
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.PersonPictureDao_Repo
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ReportDao_Repo
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.ScheduleDao_Repo
import com.ustadmobile.core.db.dao.SchoolDao
import com.ustadmobile.core.db.dao.SchoolDao_Repo
import com.ustadmobile.core.db.dao.SchoolMemberDao
import com.ustadmobile.core.db.dao.SchoolMemberDao_Repo
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.ScopedGrantDao_Repo
import com.ustadmobile.core.db.dao.ScrapeQueueItemDao
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteDao_Repo
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.SiteTermsDao_Repo
import com.ustadmobile.core.db.dao.StateContentDao
import com.ustadmobile.core.db.dao.StateContentDao_Repo
import com.ustadmobile.core.db.dao.StateDao
import com.ustadmobile.core.db.dao.StateDao_Repo
import com.ustadmobile.core.db.dao.StatementDao
import com.ustadmobile.core.db.dao.StatementDao_Repo
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.UserSessionDao_Repo
import com.ustadmobile.core.db.dao.VerbDao
import com.ustadmobile.core.db.dao.VerbDao_Repo
import com.ustadmobile.core.db.dao.XLangMapEntryDao
import com.ustadmobile.core.db.dao.XLangMapEntryDao_Repo
import com.ustadmobile.core.db.dao.XObjectDao
import com.ustadmobile.core.db.dao.XObjectDao_Repo
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.RepositoryConfig
import com.ustadmobile.door.RepositoryConnectivityListener
import com.ustadmobile.door.RepositoryHelper
import com.ustadmobile.door.replication.ReplicationSubscriptionManager
import com.ustadmobile.door.replication.makeNewSubscriptionManager
import com.ustadmobile.door.room.InvalidationTracker
import io.ktor.client.HttpClient
import kotlin.Any
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit

@Suppress("LocalVariableName", "PropertyName", "FunctionName", "ClassName")
public class UmAppDatabase_Repo(
  public override val db: UmAppDatabase,
  dbUnwrapped: UmAppDatabase,
  public override val config: RepositoryConfig,
  public override val isRootRepository: Boolean = false,
) : UmAppDatabase(), DoorDatabaseRepository {
  public val context: Any
    get() = config.context

  private val _db: UmAppDatabase = dbUnwrapped

  public override val replicationSubscriptionManager: ReplicationSubscriptionManager? =
      if(isRootRepository && config.useReplicationSubscription) {
        makeNewSubscriptionManager()
      } else {
        null
      }


  private val _endpoint: String
    get() = config.endpoint

  public val _httpClient: HttpClient
    get() = config.httpClient

  public val _repositoryHelper: RepositoryHelper = RepositoryHelper()

  public val clientId: Long
    get() = config.nodeId

  public override val dbName: String
    get() = "Repository for [${_db.toString()}] - ${config.endpoint}"

  public override var connectivityStatus: Int
    get() = _repositoryHelper.connectivityStatus
    set(newValue) {
      _repositoryHelper.connectivityStatus = newValue
    }

  public override val dbVersion: Int
    get() = 107

  public override val networkNodeDao: NetworkNodeDao
    get() = throw IllegalStateException("NetworkNodeDao is not annotated with @Repository")

  private val _PersonDao: PersonDao_Repo by lazy {
        PersonDao_Repo(db, this, db.personDao, _httpClient, clientId, _endpoint)
      }


  public override val personDao: PersonDao
    get() =  _PersonDao

  private val _ClazzDao: ClazzDao_Repo by lazy {
        ClazzDao_Repo(db, this, db.clazzDao, _httpClient, clientId, _endpoint)
      }


  public override val clazzDao: ClazzDao
    get() =  _ClazzDao

  private val _CourseBlockDao: CourseBlockDao_Repo by lazy {
        CourseBlockDao_Repo(db, this, db.courseBlockDao, _httpClient, clientId, _endpoint)
      }


  public override val courseBlockDao: CourseBlockDao
    get() =  _CourseBlockDao

  private val _CourseTerminologyDao: CourseTerminologyDao_Repo by lazy {
        CourseTerminologyDao_Repo(db, this, db.courseTerminologyDao, _httpClient, clientId,
            _endpoint)
      }


  public override val courseTerminologyDao: CourseTerminologyDao
    get() =  _CourseTerminologyDao

  private val _CourseGroupSetDao: CourseGroupSetDao_Repo by lazy {
        CourseGroupSetDao_Repo(db, this, db.courseGroupSetDao, _httpClient, clientId, _endpoint)
      }


  public override val courseGroupSetDao: CourseGroupSetDao
    get() =  _CourseGroupSetDao

  private val _CourseGroupMemberDao: CourseGroupMemberDao_Repo by lazy {
        CourseGroupMemberDao_Repo(db, this, db.courseGroupMemberDao, _httpClient, clientId,
            _endpoint)
      }


  public override val courseGroupMemberDao: CourseGroupMemberDao
    get() =  _CourseGroupMemberDao

  private val _ClazzEnrolmentDao: ClazzEnrolmentDao_Repo by lazy {
        ClazzEnrolmentDao_Repo(db, this, db.clazzEnrolmentDao, _httpClient, clientId, _endpoint)
      }


  public override val clazzEnrolmentDao: ClazzEnrolmentDao
    get() =  _ClazzEnrolmentDao

  private val _LeavingReasonDao: LeavingReasonDao_Repo by lazy {
        LeavingReasonDao_Repo(db, this, db.leavingReasonDao, _httpClient, clientId, _endpoint)
      }


  public override val leavingReasonDao: LeavingReasonDao
    get() =  _LeavingReasonDao

  private val _ContentEntryDao: ContentEntryDao_Repo by lazy {
        ContentEntryDao_Repo(db, this, db.contentEntryDao, _httpClient, clientId, _endpoint)
      }


  public override val contentEntryDao: ContentEntryDao
    get() =  _ContentEntryDao

  private val _ContentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao_Repo by lazy {
        ContentEntryContentCategoryJoinDao_Repo(db, this, db.contentEntryContentCategoryJoinDao,
            _httpClient, clientId, _endpoint)
      }


  public override val contentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao
    get() =  _ContentEntryContentCategoryJoinDao

  private val _ContentEntryParentChildJoinDao: ContentEntryParentChildJoinDao_Repo by lazy {
        ContentEntryParentChildJoinDao_Repo(db, this, db.contentEntryParentChildJoinDao,
            _httpClient, clientId, _endpoint)
      }


  public override val contentEntryParentChildJoinDao: ContentEntryParentChildJoinDao
    get() =  _ContentEntryParentChildJoinDao

  private val _ContentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao_Repo by lazy {
        ContentEntryRelatedEntryJoinDao_Repo(db, this, db.contentEntryRelatedEntryJoinDao,
            _httpClient, clientId, _endpoint)
      }


  public override val contentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao
    get() =  _ContentEntryRelatedEntryJoinDao

  private val _ClazzContentJoinDao: ClazzContentJoinDao_Repo by lazy {
        ClazzContentJoinDao_Repo(db, this, db.clazzContentJoinDao, _httpClient, clientId, _endpoint)
      }


  public override val clazzContentJoinDao: ClazzContentJoinDao
    get() =  _ClazzContentJoinDao

  private val _ContentCategorySchemaDao: ContentCategorySchemaDao_Repo by lazy {
        ContentCategorySchemaDao_Repo(db, this, db.contentCategorySchemaDao, _httpClient, clientId,
            _endpoint)
      }


  public override val contentCategorySchemaDao: ContentCategorySchemaDao
    get() =  _ContentCategorySchemaDao

  private val _ContentCategoryDao: ContentCategoryDao_Repo by lazy {
        ContentCategoryDao_Repo(db, this, db.contentCategoryDao, _httpClient, clientId, _endpoint)
      }


  public override val contentCategoryDao: ContentCategoryDao
    get() =  _ContentCategoryDao

  private val _LanguageDao: LanguageDao_Repo by lazy {
        LanguageDao_Repo(db, this, db.languageDao, _httpClient, clientId, _endpoint)
      }


  public override val languageDao: LanguageDao
    get() =  _LanguageDao

  private val _LanguageVariantDao: LanguageVariantDao_Repo by lazy {
        LanguageVariantDao_Repo(db, this, db.languageVariantDao, _httpClient, clientId, _endpoint)
      }


  public override val languageVariantDao: LanguageVariantDao
    get() =  _LanguageVariantDao

  public override val scrapeQueueItemDao: ScrapeQueueItemDao
    get() = throw IllegalStateException("ScrapeQueueItemDao is not annotated with @Repository")

  public override val personAuthDao: PersonAuthDao
    get() = throw IllegalStateException("PersonAuthDao is not annotated with @Repository")

  private val _PersonGroupDao: PersonGroupDao_Repo by lazy {
        PersonGroupDao_Repo(db, this, db.personGroupDao, _httpClient, clientId, _endpoint)
      }


  public override val personGroupDao: PersonGroupDao
    get() =  _PersonGroupDao

  private val _PersonGroupMemberDao: PersonGroupMemberDao_Repo by lazy {
        PersonGroupMemberDao_Repo(db, this, db.personGroupMemberDao, _httpClient, clientId,
            _endpoint)
      }


  public override val personGroupMemberDao: PersonGroupMemberDao
    get() =  _PersonGroupMemberDao

  private val _EntityRoleDao: EntityRoleDao_Repo by lazy {
        EntityRoleDao_Repo(db, this, db.entityRoleDao, _httpClient, clientId, _endpoint)
      }


  public override val entityRoleDao: EntityRoleDao
    get() =  _EntityRoleDao

  private val _PersonPictureDao: PersonPictureDao_Repo by lazy {
        PersonPictureDao_Repo(db, this, db.personPictureDao, _httpClient, clientId, _endpoint)
      }


  public override val personPictureDao: PersonPictureDao
    get() =  _PersonPictureDao

  public override val connectivityStatusDao: ConnectivityStatusDao
    get() = throw IllegalStateException("ConnectivityStatusDao is not annotated with @Repository")

  private val _ContainerDao: ContainerDao_Repo by lazy {
        ContainerDao_Repo(db, this, db.containerDao, _httpClient, clientId, _endpoint)
      }


  public override val containerDao: ContainerDao
    get() =  _ContainerDao

  public override val containerEntryDao: ContainerEntryDao
    get() = throw IllegalStateException("ContainerEntryDao is not annotated with @Repository")

  public override val containerEntryFileDao: ContainerEntryFileDao
    get() = throw IllegalStateException("ContainerEntryFileDao is not annotated with @Repository")

  public override val containerETagDao: ContainerETagDao
    get() = throw IllegalStateException("ContainerETagDao is not annotated with @Repository")

  private val _VerbDao: VerbDao_Repo by lazy {
        VerbDao_Repo(db, this, db.verbDao, _httpClient, clientId, _endpoint)
      }


  public override val verbDao: VerbDao
    get() =  _VerbDao

  private val _XObjectDao: XObjectDao_Repo by lazy {
        XObjectDao_Repo(db, this, db.xObjectDao, _httpClient, clientId, _endpoint)
      }


  public override val xObjectDao: XObjectDao
    get() =  _XObjectDao

  private val _ReportDao: ReportDao_Repo by lazy {
        ReportDao_Repo(db, this, db.reportDao, _httpClient, clientId, _endpoint)
      }


  public override val reportDao: ReportDao
    get() =  _ReportDao

  public override val containerImportJobDao: ContainerImportJobDao
    get() = throw IllegalStateException("ContainerImportJobDao is not annotated with @Repository")

  private val _StatementDao: StatementDao_Repo by lazy {
        StatementDao_Repo(db, this, db.statementDao, _httpClient, clientId, _endpoint)
      }


  public override val statementDao: StatementDao
    get() =  _StatementDao

  private val _ContextXObjectStatementJoinDao: ContextXObjectStatementJoinDao_Repo by lazy {
        ContextXObjectStatementJoinDao_Repo(db, this, db.contextXObjectStatementJoinDao,
            _httpClient, clientId, _endpoint)
      }


  public override val contextXObjectStatementJoinDao: ContextXObjectStatementJoinDao
    get() =  _ContextXObjectStatementJoinDao

  private val _StateDao: StateDao_Repo by lazy {
        StateDao_Repo(db, this, db.stateDao, _httpClient, clientId, _endpoint)
      }


  public override val stateDao: StateDao
    get() =  _StateDao

  private val _StateContentDao: StateContentDao_Repo by lazy {
        StateContentDao_Repo(db, this, db.stateContentDao, _httpClient, clientId, _endpoint)
      }


  public override val stateContentDao: StateContentDao
    get() =  _StateContentDao

  private val _AgentDao: AgentDao_Repo by lazy {
        AgentDao_Repo(db, this, db.agentDao, _httpClient, clientId, _endpoint)
      }


  public override val agentDao: AgentDao
    get() =  _AgentDao

  private val _LearnerGroupDao: LearnerGroupDao_Repo by lazy {
        LearnerGroupDao_Repo(db, this, db.learnerGroupDao, _httpClient, clientId, _endpoint)
      }


  public override val learnerGroupDao: LearnerGroupDao
    get() =  _LearnerGroupDao

  private val _LearnerGroupMemberDao: LearnerGroupMemberDao_Repo by lazy {
        LearnerGroupMemberDao_Repo(db, this, db.learnerGroupMemberDao, _httpClient, clientId,
            _endpoint)
      }


  public override val learnerGroupMemberDao: LearnerGroupMemberDao
    get() =  _LearnerGroupMemberDao

  private val _GroupLearningSessionDao: GroupLearningSessionDao_Repo by lazy {
        GroupLearningSessionDao_Repo(db, this, db.groupLearningSessionDao, _httpClient, clientId,
            _endpoint)
      }


  public override val groupLearningSessionDao: GroupLearningSessionDao
    get() =  _GroupLearningSessionDao

  private val _ClazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao_Repo by lazy {
        ClazzLogAttendanceRecordDao_Repo(db, this, db.clazzLogAttendanceRecordDao, _httpClient,
            clientId, _endpoint)
      }


  public override val clazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao
    get() =  _ClazzLogAttendanceRecordDao

  private val _ClazzLogDao: ClazzLogDao_Repo by lazy {
        ClazzLogDao_Repo(db, this, db.clazzLogDao, _httpClient, clientId, _endpoint)
      }


  public override val clazzLogDao: ClazzLogDao
    get() =  _ClazzLogDao

  private val _ScheduleDao: ScheduleDao_Repo by lazy {
        ScheduleDao_Repo(db, this, db.scheduleDao, _httpClient, clientId, _endpoint)
      }


  public override val scheduleDao: ScheduleDao
    get() =  _ScheduleDao

  private val _HolidayCalendarDao: HolidayCalendarDao_Repo by lazy {
        HolidayCalendarDao_Repo(db, this, db.holidayCalendarDao, _httpClient, clientId, _endpoint)
      }


  public override val holidayCalendarDao: HolidayCalendarDao
    get() =  _HolidayCalendarDao

  private val _HolidayDao: HolidayDao_Repo by lazy {
        HolidayDao_Repo(db, this, db.holidayDao, _httpClient, clientId, _endpoint)
      }


  public override val holidayDao: HolidayDao
    get() =  _HolidayDao

  private val _SchoolDao: SchoolDao_Repo by lazy {
        SchoolDao_Repo(db, this, db.schoolDao, _httpClient, clientId, _endpoint)
      }


  public override val schoolDao: SchoolDao
    get() =  _SchoolDao

  private val _XLangMapEntryDao: XLangMapEntryDao_Repo by lazy {
        XLangMapEntryDao_Repo(db, this, db.xLangMapEntryDao, _httpClient, clientId, _endpoint)
      }


  public override val xLangMapEntryDao: XLangMapEntryDao
    get() =  _XLangMapEntryDao

  public override val locallyAvailableContainerDao: LocallyAvailableContainerDao
    get() = throw
        IllegalStateException("LocallyAvailableContainerDao is not annotated with @Repository")

  private val _SchoolMemberDao: SchoolMemberDao_Repo by lazy {
        SchoolMemberDao_Repo(db, this, db.schoolMemberDao, _httpClient, clientId, _endpoint)
      }


  public override val schoolMemberDao: SchoolMemberDao
    get() =  _SchoolMemberDao

  private val _ClazzAssignmentDao: ClazzAssignmentDao_Repo by lazy {
        ClazzAssignmentDao_Repo(db, this, db.clazzAssignmentDao, _httpClient, clientId, _endpoint)
      }


  public override val clazzAssignmentDao: ClazzAssignmentDao
    get() =  _ClazzAssignmentDao

  private val _ClazzAssignmentContentJoinDao: ClazzAssignmentContentJoinDao_Repo by lazy {
        ClazzAssignmentContentJoinDao_Repo(db, this, db.clazzAssignmentContentJoinDao, _httpClient,
            clientId, _endpoint)
      }


  public override val clazzAssignmentContentJoinDao: ClazzAssignmentContentJoinDao
    get() =  _ClazzAssignmentContentJoinDao

  private val _ClazzAssignmentRollUpDao: ClazzAssignmentRollUpDao_Repo by lazy {
        ClazzAssignmentRollUpDao_Repo(db, this, db.clazzAssignmentRollUpDao, _httpClient, clientId,
            _endpoint)
      }


  public override val clazzAssignmentRollUpDao: ClazzAssignmentRollUpDao
    get() =  _ClazzAssignmentRollUpDao

  private val _CourseAssignmentSubmissionDao: CourseAssignmentSubmissionDao_Repo by lazy {
        CourseAssignmentSubmissionDao_Repo(db, this, db.courseAssignmentSubmissionDao, _httpClient,
            clientId, _endpoint)
      }


  public override val courseAssignmentSubmissionDao: CourseAssignmentSubmissionDao
    get() =  _CourseAssignmentSubmissionDao

  private val _CourseAssignmentSubmissionAttachmentDao: CourseAssignmentSubmissionAttachmentDao_Repo
      by lazy {
        CourseAssignmentSubmissionAttachmentDao_Repo(db, this,
            db.courseAssignmentSubmissionAttachmentDao, _httpClient, clientId, _endpoint)
      }


  public override val courseAssignmentSubmissionAttachmentDao:
      CourseAssignmentSubmissionAttachmentDao
    get() =  _CourseAssignmentSubmissionAttachmentDao

  private val _CourseAssignmentMarkDao: CourseAssignmentMarkDao_Repo by lazy {
        CourseAssignmentMarkDao_Repo(db, this, db.courseAssignmentMarkDao, _httpClient, clientId,
            _endpoint)
      }


  public override val courseAssignmentMarkDao: CourseAssignmentMarkDao
    get() =  _CourseAssignmentMarkDao

  private val _CommentsDao: CommentsDao_Repo by lazy {
        CommentsDao_Repo(db, this, db.commentsDao, _httpClient, clientId, _endpoint)
      }


  public override val commentsDao: CommentsDao
    get() =  _CommentsDao

  public override val syncNodeDao: SyncNodeDao
    get() = throw IllegalStateException("SyncNodeDao is not annotated with @Repository")

  private val _SiteDao: SiteDao_Repo by lazy {
        SiteDao_Repo(db, this, db.siteDao, _httpClient, clientId, _endpoint)
      }


  public override val siteDao: SiteDao
    get() =  _SiteDao

  private val _SiteTermsDao: SiteTermsDao_Repo by lazy {
        SiteTermsDao_Repo(db, this, db.siteTermsDao, _httpClient, clientId, _endpoint)
      }


  public override val siteTermsDao: SiteTermsDao
    get() =  _SiteTermsDao

  private val _PersonParentJoinDao: PersonParentJoinDao_Repo by lazy {
        PersonParentJoinDao_Repo(db, this, db.personParentJoinDao, _httpClient, clientId, _endpoint)
      }


  public override val personParentJoinDao: PersonParentJoinDao
    get() =  _PersonParentJoinDao

  private val _ScopedGrantDao: ScopedGrantDao_Repo by lazy {
        ScopedGrantDao_Repo(db, this, db.scopedGrantDao, _httpClient, clientId, _endpoint)
      }


  public override val scopedGrantDao: ScopedGrantDao
    get() =  _ScopedGrantDao

  private val _ErrorReportDao: ErrorReportDao_Repo by lazy {
        ErrorReportDao_Repo(db, this, db.errorReportDao, _httpClient, clientId, _endpoint)
      }


  public override val errorReportDao: ErrorReportDao
    get() =  _ErrorReportDao

  private val _PersonAuth2Dao: PersonAuth2Dao_Repo by lazy {
        PersonAuth2Dao_Repo(db, this, db.personAuth2Dao, _httpClient, clientId, _endpoint)
      }


  public override val personAuth2Dao: PersonAuth2Dao
    get() =  _PersonAuth2Dao

  private val _UserSessionDao: UserSessionDao_Repo by lazy {
        UserSessionDao_Repo(db, this, db.userSessionDao, _httpClient, clientId, _endpoint)
      }


  public override val userSessionDao: UserSessionDao
    get() =  _UserSessionDao

  public override val contentJobItemDao: ContentJobItemDao
    get() = throw IllegalStateException("ContentJobItemDao is not annotated with @Repository")

  public override val contentJobDao: ContentJobDao
    get() = throw IllegalStateException("ContentJobDao is not annotated with @Repository")

  private val _CoursePictureDao: CoursePictureDao_Repo by lazy {
        CoursePictureDao_Repo(db, this, db.coursePictureDao, _httpClient, clientId, _endpoint)
      }


  public override val coursePictureDao: CoursePictureDao
    get() =  _CoursePictureDao

  private val _ContentEntryPictureDao: ContentEntryPictureDao_Repo by lazy {
        ContentEntryPictureDao_Repo(db, this, db.contentEntryPictureDao, _httpClient, clientId,
            _endpoint)
      }


  public override val contentEntryPictureDao: ContentEntryPictureDao
    get() =  _ContentEntryPictureDao

  private val _ChatDao: ChatDao_Repo by lazy {
        ChatDao_Repo(db, this, db.chatDao, _httpClient, clientId, _endpoint)
      }


  public override val chatDao: ChatDao
    get() =  _ChatDao

  private val _ChatMemberDao: ChatMemberDao_Repo by lazy {
        ChatMemberDao_Repo(db, this, db.chatMemberDao, _httpClient, clientId, _endpoint)
      }


  public override val chatMemberDao: ChatMemberDao
    get() =  _ChatMemberDao

  private val _MessageDao: MessageDao_Repo by lazy {
        MessageDao_Repo(db, this, db.messageDao, _httpClient, clientId, _endpoint)
      }


  public override val messageDao: MessageDao
    get() =  _MessageDao

  private val _MessageReadDao: MessageReadDao_Repo by lazy {
        MessageReadDao_Repo(db, this, db.messageReadDao, _httpClient, clientId, _endpoint)
      }


  public override val messageReadDao: MessageReadDao
    get() =  _MessageReadDao

  private val _CourseDiscussionDao: CourseDiscussionDao_Repo by lazy {
        CourseDiscussionDao_Repo(db, this, db.courseDiscussionDao, _httpClient, clientId, _endpoint)
      }


  public override val courseDiscussionDao: CourseDiscussionDao
    get() =  _CourseDiscussionDao

  private val _DiscussionTopicDao: DiscussionTopicDao_Repo by lazy {
        DiscussionTopicDao_Repo(db, this, db.discussionTopicDao, _httpClient, clientId, _endpoint)
      }


  public override val discussionTopicDao: DiscussionTopicDao
    get() =  _DiscussionTopicDao

  private val _DiscussionPostDao: DiscussionPostDao_Repo by lazy {
        DiscussionPostDao_Repo(db, this, db.discussionPostDao, _httpClient, clientId, _endpoint)
      }


  public override val discussionPostDao: DiscussionPostDao
    get() =  _DiscussionPostDao

  public override fun clearAllTables() = throw
      IllegalStateException("Cannot use a repository to clearAllTables!")

  public override suspend fun clearAllTablesAsync() = throw
      IllegalStateException("Cannot use this to run clearAllTablesAsync")

  public override fun addWeakConnectivityListener(listener: RepositoryConnectivityListener): Unit {
    _repositoryHelper.addWeakConnectivityListener(listener)
  }

  public override fun removeWeakConnectivityListener(listener: RepositoryConnectivityListener):
      Unit {
    _repositoryHelper.removeWeakConnectivityListener(listener)
  }

  public override fun createAllTables() = throw
      IllegalStateException("Cannot use a repository to createAllTables!")

  public override fun getInvalidationTracker(): InvalidationTracker = _db.getInvalidationTracker()

  public companion object {
    public const val _DB_NAME: String = "UmAppDatabase"
  }
}
