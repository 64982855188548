package com.ustadmobile.lib.db.entities

import com.ustadmobile.door.attachments.EntityWithAttachment
import kotlin.Int
import kotlin.String

public inline class CourseAssignmentSubmissionAttachment_EwaAdapter(
  private val entity: CourseAssignmentSubmissionAttachment,
) : EntityWithAttachment {
  public override var attachmentUri: String?
    get() = entity.casaUri
    set(`value`) {
      entity.casaUri = value
    }

  public override var attachmentMd5: String?
    get() = entity.casaMd5
    set(`value`) {
      entity.casaMd5 = value
    }

  public override var attachmentSize: Int
    get() = entity.casaSize
    set(`value`) {
      entity.casaSize = value
    }

  public override val tableName: String
    get() = "CourseAssignmentSubmissionAttachment"
}

public inline fun CourseAssignmentSubmissionAttachment.asEntityWithAttachment():
    EntityWithAttachment = CourseAssignmentSubmissionAttachment_EwaAdapter(this)
