package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.LanguageVariant
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class LanguageVariantDao : BaseDao<LanguageVariant> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByCode(countryCode: String): LanguageVariant?
}
