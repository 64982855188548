package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class VerbDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: VerbDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : VerbDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUrl(urlId: String?): VerbEntity? {
    val _result = _dao.findByUrl(urlId)
    return _result
  }

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> {
    val _result = _dao.findByUidList(uidList)
    return _result
  }

  public override suspend fun replaceList(entityList: List<VerbEntity>): Unit {
    _dao.replaceList(entityList)
  }

  public override fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay> {
    val _result = _dao.findAllVerbsAscList(uidList)
    return _result
  }

  public override fun findAllVerbsAsc(uidList: List<Long>): DataSourceFactory<Int, VerbDisplay> {
    val _result = _dao.findAllVerbsAsc(uidList)
    return _result
  }

  public override fun findAllVerbsDesc(uidList: List<Long>): DataSourceFactory<Int, VerbDisplay> {
    val _result = _dao.findAllVerbsDesc(uidList)
    return _result
  }

  public override fun insert(entity: VerbEntity): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: VerbEntity): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<VerbEntity>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<VerbEntity>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: VerbEntity): Unit {
    _dao.update(entity)
  }
}
