package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryContentCategoryJoinDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryContentCategoryJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentEntryContentCategoryJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun publicContentEntryContentCategoryJoins():
      List<ContentEntryContentCategoryJoin> {
    val _result = _dao.publicContentEntryContentCategoryJoins()
    return _result
  }

  public override fun findJoinByParentChildUuids(categoryUid: Long, contentEntry: Long):
      ContentEntryContentCategoryJoin? {
    val _result = _dao.findJoinByParentChildUuids(categoryUid, contentEntry)
    return _result
  }

  public override fun insert(entity: ContentEntryContentCategoryJoin): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ContentEntryContentCategoryJoin): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<ContentEntryContentCategoryJoin>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<ContentEntryContentCategoryJoin>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ContentEntryContentCategoryJoin): Unit {
    _dao.update(entity)
  }
}
