package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.CourseGroupMemberPerson
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseGroupMemberDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseGroupMemberDao,
) : CourseGroupMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByGroupSetAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> = _dao.findByGroupSetAsync(setUid, clazzUid)

  public override suspend fun findByGroupSetOrderedAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> = _dao.findByGroupSetOrderedAsync(setUid, clazzUid)

  public override suspend fun findByPersonUid(groupSetUid: Long, studentUid: Long):
      CourseGroupMember? = _dao.findByPersonUid(groupSetUid, studentUid)

  public override suspend fun insertListAsync(entityList: List<CourseGroupMember>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.cgmUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(243)
        it.cgmUid = _newPk
        _generatedPks += _newPk
      }
      it.cgmLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CourseGroupMember>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.cgmLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }

  public override fun insert(entity: CourseGroupMember): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseGroupMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cgmUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(243)
      entity.cgmUid = _newPk
    }
    entity.cgmLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cgmUid
  }

  public override fun insertList(entityList: List<CourseGroupMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<CourseGroupMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseGroupMember): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
