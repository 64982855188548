package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.StateContentEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class StateContentDao : BaseDao<StateContentEntity> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findAllStateContentWithStateUid(id: Long): List<StateContentEntity>

  public actual abstract fun findStateContentByKeyAndStateUid(key: String, stateUid: Long):
      StateContentEntity?

  public actual abstract fun setInActiveStateContentByKeyAndUid(
    isActive: Boolean,
    stateUid: Long,
    updateTime: Long,
  ): Unit
}
