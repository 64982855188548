package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseDiscussion
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseDiscussionDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseDiscussionDao,
) : CourseDiscussionDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  public override fun getCourseDiscussionByUid(courseDiscussionUid: Long):
      LiveData<CourseDiscussion?> = _dao.getCourseDiscussionByUid(courseDiscussionUid)

  public override suspend fun replaceListAsync(list: List<CourseDiscussion>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      if(it.courseDiscussionUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(130)
        it.courseDiscussionUid = _newPk
        _generatedPks += _newPk
      }
      it.courseDiscussionLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceListAsync(list)
  }

  public override fun insert(entity: CourseDiscussion): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseDiscussion): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.courseDiscussionUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(130)
      entity.courseDiscussionUid = _newPk
    }
    entity.courseDiscussionLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.courseDiscussionUid
  }

  public override fun insertList(entityList: List<CourseDiscussion>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<CourseDiscussion>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseDiscussion): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertListAsync(entityList: List<CourseDiscussion>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.courseDiscussionUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(130)
        it.courseDiscussionUid = _newPk
        _generatedPks += _newPk
      }
      it.courseDiscussionLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CourseDiscussion>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.courseDiscussionLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
