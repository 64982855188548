package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonGroup
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonGroupDao_JdbcKt(
  public val _db: RoomDatabase,
) : PersonGroupDao() {
  public val _insertAdapterPersonGroup_: EntityInsertionAdapter<PersonGroup> = object :
      EntityInsertionAdapter<PersonGroup>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO PersonGroup (groupUid, groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonGroup):
        Unit {
      if(entity.groupUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupUid)
      }
      stmt.setLong(2, entity.groupMasterCsn)
      stmt.setLong(3, entity.groupLocalCsn)
      stmt.setInt(4, entity.groupLastChangedBy)
      stmt.setLong(5, entity.groupLct)
      stmt.setString(6, entity.groupName)
      stmt.setBoolean(7, entity.groupActive)
      stmt.setInt(8, entity.personGroupFlag)
    }
  }

  public override fun insert(entity: PersonGroup): Long {
    val _retVal = _insertAdapterPersonGroup_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: PersonGroup): Long {
    val _retVal = _insertAdapterPersonGroup_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<PersonGroup>): Unit {
    _insertAdapterPersonGroup_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: PersonGroup): Int {
    var _result = 0
    val _sql =
        "UPDATE PersonGroup SET groupMasterCsn = ?, groupLocalCsn = ?, groupLastChangedBy = ?, groupLct = ?, groupName = ?, groupActive = ?, personGroupFlag = ? WHERE groupUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.groupMasterCsn)
      _stmt.setLong(2, entity.groupLocalCsn)
      _stmt.setInt(3, entity.groupLastChangedBy)
      _stmt.setLong(4, entity.groupLct)
      _stmt.setString(5, entity.groupName)
      _stmt.setBoolean(6, entity.groupActive)
      _stmt.setInt(7, entity.personGroupFlag)
      _stmt.setLong(8, entity.groupUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<PersonGroup>): Unit {
    val _sql =
        "UPDATE PersonGroup SET groupMasterCsn = ?, groupLocalCsn = ?, groupLastChangedBy = ?, groupLct = ?, groupName = ?, groupActive = ?, personGroupFlag = ? WHERE groupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.groupMasterCsn)
        _stmt.setLong(2, _entity.groupLocalCsn)
        _stmt.setInt(3, _entity.groupLastChangedBy)
        _stmt.setLong(4, _entity.groupLct)
        _stmt.setString(5, _entity.groupName)
        _stmt.setBoolean(6, _entity.groupActive)
        _stmt.setInt(7, _entity.personGroupFlag)
        _stmt.setLong(8, _entity.groupUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: PersonGroup): Unit {
    val _sql =
        "UPDATE PersonGroup SET groupMasterCsn = ?, groupLocalCsn = ?, groupLastChangedBy = ?, groupLct = ?, groupName = ?, groupActive = ?, personGroupFlag = ? WHERE groupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.groupMasterCsn)
      _stmt.setLong(2, entity.groupLocalCsn)
      _stmt.setInt(3, entity.groupLastChangedBy)
      _stmt.setLong(4, entity.groupLct)
      _stmt.setString(5, entity.groupName)
      _stmt.setBoolean(6, entity.groupActive)
      _stmt.setInt(7, entity.personGroupFlag)
      _stmt.setLong(8, entity.groupUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |      SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |    ,
    |             ? AS pgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN PersonGroup
    |                    ON PersonGroup.groupUid = PersonsWithPerm_GroupMember.groupMemberGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |      SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |    ,
    |             ? AS pgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN PersonGroup
    |                    ON PersonGroup.groupUid = PersonsWithPerm_GroupMember.groupMemberGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM ChangeLog
    |         JOIN PersonGroup
    |              ON ChangeLog.chTableId = 43
    |                AND ChangeLog.chEntityPk = PersonGroup.groupUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = PersonGroup.groupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |   WHERE 
    |            UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |        
    |     AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    | /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM ChangeLog
    |         JOIN PersonGroup
    |              ON ChangeLog.chTableId = 43
    |                AND ChangeLog.chEntityPk = PersonGroup.groupUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = PersonGroup.groupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |   WHERE 
    |            UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |        
    |     AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    |  ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                 2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |              
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |    */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                 2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |              
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                   
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |              2 
    |              
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 6
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */      
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |              2 
    |              
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 6
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |            
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person 
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |    */                     
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person 
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |              64
    |              
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Person.personUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */      
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |              64
    |              
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Person.personUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |            
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN School 
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |              536870912
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |    */                     
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN School 
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |              536870912
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |              536870912
    |              
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON School.schoolUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */      
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |              536870912
    |              
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON School.schoolUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |            
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): PersonGroup? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM PersonGroup WHERE groupUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_groupUid = _result.getLong("groupUid")
        val _tmp_groupMasterCsn = _result.getLong("groupMasterCsn")
        val _tmp_groupLocalCsn = _result.getLong("groupLocalCsn")
        val _tmp_groupLastChangedBy = _result.getInt("groupLastChangedBy")
        val _tmp_groupLct = _result.getLong("groupLct")
        val _tmp_groupName = _result.getString("groupName")
        val _tmp_groupActive = _result.getBoolean("groupActive")
        val _tmp_personGroupFlag = _result.getInt("personGroupFlag")
        PersonGroup().apply {
          this.groupUid = _tmp_groupUid
          this.groupMasterCsn = _tmp_groupMasterCsn
          this.groupLocalCsn = _tmp_groupLocalCsn
          this.groupLastChangedBy = _tmp_groupLastChangedBy
          this.groupLct = _tmp_groupLct
          this.groupName = _tmp_groupName
          this.groupActive = _tmp_groupActive
          this.personGroupFlag = _tmp_personGroupFlag
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): PersonGroup? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM PersonGroup WHERE groupUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_groupUid = _result.getLong("groupUid")
        val _tmp_groupMasterCsn = _result.getLong("groupMasterCsn")
        val _tmp_groupLocalCsn = _result.getLong("groupLocalCsn")
        val _tmp_groupLastChangedBy = _result.getInt("groupLastChangedBy")
        val _tmp_groupLct = _result.getLong("groupLct")
        val _tmp_groupName = _result.getString("groupName")
        val _tmp_groupActive = _result.getBoolean("groupActive")
        val _tmp_personGroupFlag = _result.getInt("personGroupFlag")
        PersonGroup().apply {
          this.groupUid = _tmp_groupUid
          this.groupMasterCsn = _tmp_groupMasterCsn
          this.groupLocalCsn = _tmp_groupLocalCsn
          this.groupLastChangedBy = _tmp_groupLastChangedBy
          this.groupLct = _tmp_groupLct
          this.groupName = _tmp_groupName
          this.groupActive = _tmp_groupActive
          this.personGroupFlag = _tmp_personGroupFlag
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<PersonGroup?> = LiveDataImpl(_db,
      listOf("PersonGroup"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM PersonGroup WHERE groupUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_groupUid = _result.getLong("groupUid")
          val _tmp_groupMasterCsn = _result.getLong("groupMasterCsn")
          val _tmp_groupLocalCsn = _result.getLong("groupLocalCsn")
          val _tmp_groupLastChangedBy = _result.getInt("groupLastChangedBy")
          val _tmp_groupLct = _result.getLong("groupLct")
          val _tmp_groupName = _result.getString("groupName")
          val _tmp_groupActive = _result.getBoolean("groupActive")
          val _tmp_personGroupFlag = _result.getInt("personGroupFlag")
          PersonGroup().apply {
            this.groupUid = _tmp_groupUid
            this.groupMasterCsn = _tmp_groupMasterCsn
            this.groupLocalCsn = _tmp_groupLocalCsn
            this.groupLastChangedBy = _tmp_groupLastChangedBy
            this.groupLct = _tmp_groupLct
            this.groupName = _tmp_groupName
            this.groupActive = _tmp_groupActive
            this.personGroupFlag = _tmp_personGroupFlag
          }
        }
      }
    }
  }

  public override suspend fun findNameByGroupUid(groupUid: Long): String? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        Select CASE
  |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
  |               ELSE PersonGroup.groupName 
  |               END AS name
  |          FROM PersonGroup
  |               LEFT JOIN Person
  |                         ON Person.personGroupUid = PersonGroup.groupUid
  |         WHERE PersonGroup.groupUid = ?
  |         LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,groupUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }
}
