package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionAttachment
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CourseAssignmentSubmissionAttachmentDao :
    BaseDao<CourseAssignmentSubmissionAttachment> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend
      fun insertListAsync(entityList: List<CourseAssignmentSubmissionAttachment>): Unit
}
