package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.SyncNode
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit

public class SyncNodeDao_JdbcKt(
  public val _db: RoomDatabase,
) : SyncNodeDao() {
  public val _insertAdapterSyncNode_upsert: EntityInsertionAdapter<SyncNode> = object :
      EntityInsertionAdapter<SyncNode>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO SyncNode (nodeClientId) VALUES(?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: SyncNode): Unit {
      stmt.setLong(1, entity.nodeClientId)
    }
  }

  public override fun replace(syncNode: SyncNode): Unit {
    _insertAdapterSyncNode_upsert.insert(syncNode)
  }

  public override suspend fun getLocalNodeClientId(): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT COALESCE(
  |               (SELECT nodeClientId 
  |                  FROM SyncNode 
  |                 LIMIT 1), 0)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }
}
