package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LearnerGroupMember
import com.ustadmobile.lib.db.entities.LearnerGroupMemberWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LearnerGroupMemberDao_JdbcKt(
  public val _db: RoomDatabase,
) : LearnerGroupMemberDao() {
  public val _insertAdapterLearnerGroupMember_: EntityInsertionAdapter<LearnerGroupMember> = object
      : EntityInsertionAdapter<LearnerGroupMember>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO LearnerGroupMember (learnerGroupMemberUid, learnerGroupMemberPersonUid, learnerGroupMemberLgUid, learnerGroupMemberRole, learnerGroupMemberActive, learnerGroupMemberMCSN, learnerGroupMemberCSN, learnerGroupMemberLCB, learnerGroupMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: LearnerGroupMember): Unit {
      if(entity.learnerGroupMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.learnerGroupMemberUid)
      }
      stmt.setLong(2, entity.learnerGroupMemberPersonUid)
      stmt.setLong(3, entity.learnerGroupMemberLgUid)
      stmt.setInt(4, entity.learnerGroupMemberRole)
      stmt.setBoolean(5, entity.learnerGroupMemberActive)
      stmt.setLong(6, entity.learnerGroupMemberMCSN)
      stmt.setLong(7, entity.learnerGroupMemberCSN)
      stmt.setInt(8, entity.learnerGroupMemberLCB)
      stmt.setLong(9, entity.learnerGroupMemberLct)
    }
  }

  public override fun insert(entity: LearnerGroupMember): Long {
    val _retVal = _insertAdapterLearnerGroupMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: LearnerGroupMember): Long {
    val _retVal = _insertAdapterLearnerGroupMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<LearnerGroupMember>): Unit {
    _insertAdapterLearnerGroupMember_.insertList(entityList)
  }

  public override fun updateList(entityList: List<LearnerGroupMember>): Unit {
    val _sql =
        "UPDATE LearnerGroupMember SET learnerGroupMemberPersonUid = ?, learnerGroupMemberLgUid = ?, learnerGroupMemberRole = ?, learnerGroupMemberActive = ?, learnerGroupMemberMCSN = ?, learnerGroupMemberCSN = ?, learnerGroupMemberLCB = ?, learnerGroupMemberLct = ? WHERE learnerGroupMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.learnerGroupMemberPersonUid)
        _stmt.setLong(2, _entity.learnerGroupMemberLgUid)
        _stmt.setInt(3, _entity.learnerGroupMemberRole)
        _stmt.setBoolean(4, _entity.learnerGroupMemberActive)
        _stmt.setLong(5, _entity.learnerGroupMemberMCSN)
        _stmt.setLong(6, _entity.learnerGroupMemberCSN)
        _stmt.setInt(7, _entity.learnerGroupMemberLCB)
        _stmt.setLong(8, _entity.learnerGroupMemberLct)
        _stmt.setLong(9, _entity.learnerGroupMemberUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: LearnerGroupMember): Unit {
    val _sql =
        "UPDATE LearnerGroupMember SET learnerGroupMemberPersonUid = ?, learnerGroupMemberLgUid = ?, learnerGroupMemberRole = ?, learnerGroupMemberActive = ?, learnerGroupMemberMCSN = ?, learnerGroupMemberCSN = ?, learnerGroupMemberLCB = ?, learnerGroupMemberLct = ? WHERE learnerGroupMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.learnerGroupMemberPersonUid)
      _stmt.setLong(2, entity.learnerGroupMemberLgUid)
      _stmt.setInt(3, entity.learnerGroupMemberRole)
      _stmt.setBoolean(4, entity.learnerGroupMemberActive)
      _stmt.setLong(5, entity.learnerGroupMemberMCSN)
      _stmt.setLong(6, entity.learnerGroupMemberCSN)
      _stmt.setInt(7, entity.learnerGroupMemberLCB)
      _stmt.setLong(8, entity.learnerGroupMemberLct)
      _stmt.setLong(9, entity.learnerGroupMemberUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |      SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmPk,
    |             ? AS lgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |             (SELECT lgmVersionId
    |                FROM LearnerGroupMemberReplicate
    |               WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |                 AND lgmDestination = ?), 0) 
    |      /*psql ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |             SET lgmPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |      SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmPk,
    |             ? AS lgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |             (SELECT lgmVersionId
    |                FROM LearnerGroupMemberReplicate
    |               WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |                 AND lgmDestination = ?), 0) 
    |       ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |             SET lgmPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |  SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmUid,
    |         UserSession.usClientNodeId AS lgmDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroupMember
    |              ON ChangeLog.chTableId = 300
    |                 AND ChangeLog.chEntityPk = LearnerGroupMember.learnerGroupMemberUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |         (SELECT lgmVersionId
    |            FROM LearnerGroupMemberReplicate
    |           WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |             AND lgmDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |     SET lgmPending = true
    |  */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |  SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmUid,
    |         UserSession.usClientNodeId AS lgmDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroupMember
    |              ON ChangeLog.chTableId = 300
    |                 AND ChangeLog.chEntityPk = LearnerGroupMember.learnerGroupMemberUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |         (SELECT lgmVersionId
    |            FROM LearnerGroupMemberReplicate
    |           WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |             AND lgmDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |     SET lgmPending = true
    |  
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findLearnerGroupMembersByGroupIdAndEntry(learnerGroupUid: Long,
      contentEntryUid: Long): DataSourceFactory<Int, LearnerGroupMemberWithPerson> = object :
      DataSourceFactory<Int, LearnerGroupMemberWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<LearnerGroupMemberWithPerson>> = LiveDataImpl(_db,
        listOf("LearnerGroupMember", "Person", "GroupLearningSession"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
      |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
      |        LEFT JOIN GroupLearningSession ON 
      |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
      |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
      |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
      |    ORDER BY learnerGroupMemberRole ASC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,learnerGroupUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_learnerGroupMemberUid = _result.getLong("learnerGroupMemberUid")
            val _tmp_learnerGroupMemberPersonUid = _result.getLong("learnerGroupMemberPersonUid")
            val _tmp_learnerGroupMemberLgUid = _result.getLong("learnerGroupMemberLgUid")
            val _tmp_learnerGroupMemberRole = _result.getInt("learnerGroupMemberRole")
            val _tmp_learnerGroupMemberActive = _result.getBoolean("learnerGroupMemberActive")
            val _tmp_learnerGroupMemberMCSN = _result.getLong("learnerGroupMemberMCSN")
            val _tmp_learnerGroupMemberCSN = _result.getLong("learnerGroupMemberCSN")
            val _tmp_learnerGroupMemberLCB = _result.getInt("learnerGroupMemberLCB")
            val _tmp_learnerGroupMemberLct = _result.getLong("learnerGroupMemberLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            LearnerGroupMemberWithPerson().apply {
              this.learnerGroupMemberUid = _tmp_learnerGroupMemberUid
              this.learnerGroupMemberPersonUid = _tmp_learnerGroupMemberPersonUid
              this.learnerGroupMemberLgUid = _tmp_learnerGroupMemberLgUid
              this.learnerGroupMemberRole = _tmp_learnerGroupMemberRole
              this.learnerGroupMemberActive = _tmp_learnerGroupMemberActive
              this.learnerGroupMemberMCSN = _tmp_learnerGroupMemberMCSN
              this.learnerGroupMemberCSN = _tmp_learnerGroupMemberCSN
              this.learnerGroupMemberLCB = _tmp_learnerGroupMemberLCB
              this.learnerGroupMemberLct = _tmp_learnerGroupMemberLct
              if(!_tmp_Person_isAllNull) {
                this.person = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("LearnerGroupMember",
        "Person", "GroupLearningSession"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
      |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
      |        LEFT JOIN GroupLearningSession ON 
      |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
      |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
      |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
      |    ORDER BY learnerGroupMemberRole ASC
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,learnerGroupUid)
        _stmt.setLong(2,contentEntryUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun findLearnerGroupMembersByGroupIdAndEntryList(learnerGroupUid: Long,
      contentEntryUid: Long): List<LearnerGroupMemberWithPerson> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
  |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
  |        LEFT JOIN GroupLearningSession ON 
  |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
  |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
  |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
  |    ORDER BY learnerGroupMemberRole ASC
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,learnerGroupUid)
    _stmt.setLong(2,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_learnerGroupMemberUid = _result.getLong("learnerGroupMemberUid")
        val _tmp_learnerGroupMemberPersonUid = _result.getLong("learnerGroupMemberPersonUid")
        val _tmp_learnerGroupMemberLgUid = _result.getLong("learnerGroupMemberLgUid")
        val _tmp_learnerGroupMemberRole = _result.getInt("learnerGroupMemberRole")
        val _tmp_learnerGroupMemberActive = _result.getBoolean("learnerGroupMemberActive")
        val _tmp_learnerGroupMemberMCSN = _result.getLong("learnerGroupMemberMCSN")
        val _tmp_learnerGroupMemberCSN = _result.getLong("learnerGroupMemberCSN")
        val _tmp_learnerGroupMemberLCB = _result.getInt("learnerGroupMemberLCB")
        val _tmp_learnerGroupMemberLct = _result.getLong("learnerGroupMemberLct")
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        LearnerGroupMemberWithPerson().apply {
          this.learnerGroupMemberUid = _tmp_learnerGroupMemberUid
          this.learnerGroupMemberPersonUid = _tmp_learnerGroupMemberPersonUid
          this.learnerGroupMemberLgUid = _tmp_learnerGroupMemberLgUid
          this.learnerGroupMemberRole = _tmp_learnerGroupMemberRole
          this.learnerGroupMemberActive = _tmp_learnerGroupMemberActive
          this.learnerGroupMemberMCSN = _tmp_learnerGroupMemberMCSN
          this.learnerGroupMemberCSN = _tmp_learnerGroupMemberCSN
          this.learnerGroupMemberLCB = _tmp_learnerGroupMemberLCB
          this.learnerGroupMemberLct = _tmp_learnerGroupMemberLct
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }
  }
}
