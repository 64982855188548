package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.DiscussionTopic
import com.ustadmobile.lib.db.entities.DiscussionTopicListDetail
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class DiscussionTopicDao : BaseDao<DiscussionTopic>,
    OneToManyJoinDao<DiscussionTopic> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun getListOfTopicsByDiscussion(discussionUid: Long):
      DataSourceFactory<Int, DiscussionTopicListDetail>

  public actual abstract suspend fun getTopicsByClazz(clazzUid: Long): List<DiscussionTopic>

  public actual abstract fun getDiscussionTopicByUid(discussionTopicUid: Long):
      LiveData<DiscussionTopic?>

  public actual abstract suspend fun updateActiveByUid(
    uid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit

  public actual abstract suspend fun replaceListAsync(list: List<DiscussionTopic>): Unit
}
