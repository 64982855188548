package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class LanguageDao_JdbcKt(
  public val _db: RoomDatabase,
) : LanguageDao() {
  public val _insertAdapterLanguage_: EntityInsertionAdapter<Language> = object :
      EntityInsertionAdapter<Language>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Language): Unit {
      if(entity.langUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.langUid)
      }
      stmt.setString(2, entity.name)
      stmt.setString(3, entity.iso_639_1_standard)
      stmt.setString(4, entity.iso_639_2_standard)
      stmt.setString(5, entity.iso_639_3_standard)
      stmt.setString(6, entity.Language_Type)
      stmt.setBoolean(7, entity.languageActive)
      stmt.setLong(8, entity.langLocalChangeSeqNum)
      stmt.setLong(9, entity.langMasterChangeSeqNum)
      stmt.setInt(10, entity.langLastChangedBy)
      stmt.setLong(11, entity.langLct)
    }
  }

  public val _insertAdapterLanguage_upsert: EntityInsertionAdapter<Language> = object :
      EntityInsertionAdapter<Language>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Language): Unit {
      if(entity.langUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.langUid)
      }
      stmt.setString(2, entity.name)
      stmt.setString(3, entity.iso_639_1_standard)
      stmt.setString(4, entity.iso_639_2_standard)
      stmt.setString(5, entity.iso_639_3_standard)
      stmt.setString(6, entity.Language_Type)
      stmt.setBoolean(7, entity.languageActive)
      stmt.setLong(8, entity.langLocalChangeSeqNum)
      stmt.setLong(9, entity.langMasterChangeSeqNum)
      stmt.setInt(10, entity.langLastChangedBy)
      stmt.setLong(11, entity.langLct)
    }
  }

  public override suspend fun insertListAsync(languageList: List<Language>): Unit {
    _insertAdapterLanguage_.insertListAsync(languageList)
  }

  public override fun replaceList(entityList: List<Language>): Unit {
    _insertAdapterLanguage_upsert.insertList(entityList)
  }

  public override fun insert(entity: Language): Long {
    val _retVal = _insertAdapterLanguage_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Language): Long {
    val _retVal = _insertAdapterLanguage_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Language>): Unit {
    _insertAdapterLanguage_.insertList(entityList)
  }

  public override fun update(entity: Language): Unit {
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.name)
      _stmt.setString(2, entity.iso_639_1_standard)
      _stmt.setString(3, entity.iso_639_2_standard)
      _stmt.setString(4, entity.iso_639_3_standard)
      _stmt.setString(5, entity.Language_Type)
      _stmt.setBoolean(6, entity.languageActive)
      _stmt.setLong(7, entity.langLocalChangeSeqNum)
      _stmt.setLong(8, entity.langMasterChangeSeqNum)
      _stmt.setInt(9, entity.langLastChangedBy)
      _stmt.setLong(10, entity.langLct)
      _stmt.setLong(11, entity.langUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateAsync(entity: Language): Int {
    var _result = 0
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.name)
      _stmt.setString(2, entity.iso_639_1_standard)
      _stmt.setString(3, entity.iso_639_2_standard)
      _stmt.setString(4, entity.iso_639_3_standard)
      _stmt.setString(5, entity.Language_Type)
      _stmt.setBoolean(6, entity.languageActive)
      _stmt.setLong(7, entity.langLocalChangeSeqNum)
      _stmt.setLong(8, entity.langMasterChangeSeqNum)
      _stmt.setInt(9, entity.langLastChangedBy)
      _stmt.setLong(10, entity.langLct)
      _stmt.setLong(11, entity.langUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<Language>): Unit {
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.name)
        _stmt.setString(2, _entity.iso_639_1_standard)
        _stmt.setString(3, _entity.iso_639_2_standard)
        _stmt.setString(4, _entity.iso_639_3_standard)
        _stmt.setString(5, _entity.Language_Type)
        _stmt.setBoolean(6, _entity.languageActive)
        _stmt.setLong(7, _entity.langLocalChangeSeqNum)
        _stmt.setLong(8, _entity.langMasterChangeSeqNum)
        _stmt.setInt(9, _entity.langLastChangedBy)
        _stmt.setLong(10, _entity.langLct)
        _stmt.setLong(11, _entity.langUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO LanguageReplicate(languagePk, languageDestination)
    |      SELECT DISTINCT Language.langUid AS languagePk,
    |             ? AS languageDestination
    |        FROM Language
    |       WHERE Language.langLct != COALESCE(
    |             (SELECT languageVersionId
    |                FROM LanguageReplicate
    |               WHERE languagePk = Language.langUid
    |                 AND languageDestination = ?), 0) 
    |      /*psql ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |             SET languagePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LanguageReplicate(languagePk, languageDestination)
    |      SELECT DISTINCT Language.langUid AS languagePk,
    |             ? AS languageDestination
    |        FROM Language
    |       WHERE Language.langLct != COALESCE(
    |             (SELECT languageVersionId
    |                FROM LanguageReplicate
    |               WHERE languagePk = Language.langUid
    |                 AND languageDestination = ?), 0) 
    |       ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |             SET languagePending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO LanguageReplicate(languagePk, languageDestination)
    |  SELECT DISTINCT Language.langUid AS languageUid,
    |         UserSession.usClientNodeId AS languageDestination
    |    FROM ChangeLog
    |         JOIN Language
    |             ON ChangeLog.chTableId = 13
    |                AND ChangeLog.chEntityPk = Language.langUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Language.langLct != COALESCE(
    |         (SELECT languageVersionId
    |            FROM LanguageReplicate
    |           WHERE languagePk = Language.langUid
    |             AND languageDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |     SET languagePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LanguageReplicate(languagePk, languageDestination)
    |  SELECT DISTINCT Language.langUid AS languageUid,
    |         UserSession.usClientNodeId AS languageDestination
    |    FROM ChangeLog
    |         JOIN Language
    |             ON ChangeLog.chTableId = 13
    |                AND ChangeLog.chEntityPk = Language.langUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Language.langLct != COALESCE(
    |         (SELECT languageVersionId
    |            FROM LanguageReplicate
    |           WHERE languagePk = Language.langUid
    |             AND languageDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |     SET languagePending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      DataSourceFactory<Int, Language> = object : DataSourceFactory<Int, Language>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<Language>> =
        LiveDataImpl(_db, listOf("Language"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT Language.* 
      |        FROM Language
      |        WHERE name LIKE ?
      |        ORDER BY CASE(?)
      |            WHEN 1 THEN Language.name 
      |            WHEN 3 THEN Language.iso_639_1_standard 
      |            WHEN 5 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Language.name 
      |            WHEN 4 THEN Language.iso_639_1_standard 
      |            WHEN 6 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setString(1,searchText)
        _stmt.setInt(2,sortOrder)
        _stmt.setInt(3,sortOrder)
        _stmt.setInt(4,_limit)
        _stmt.setInt(5,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_langUid = _result.getLong("langUid")
            val _tmp_name = _result.getString("name")
            val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
            val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
            val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
            val _tmp_Language_Type = _result.getString("Language_Type")
            val _tmp_languageActive = _result.getBoolean("languageActive")
            val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
            val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
            val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
            val _tmp_langLct = _result.getLong("langLct")
            Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Language"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT Language.* 
      |        FROM Language
      |        WHERE name LIKE ?
      |        ORDER BY CASE(?)
      |            WHEN 1 THEN Language.name 
      |            WHEN 3 THEN Language.iso_639_1_standard 
      |            WHEN 5 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Language.name 
      |            WHEN 4 THEN Language.iso_639_1_standard 
      |            WHEN 6 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END DESC
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setString(1,searchText)
        _stmt.setInt(2,sortOrder)
        _stmt.setInt(3,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findLanguagesList(): List<Language> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Language" )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override fun findByName(name: String): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Language WHERE name = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setString(1,name)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override fun findByTwoCode(langCode: String): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setString(1,langCode)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override suspend fun findByTwoCodeAsync(langCode: String): Language? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setString(1,langCode)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override fun findByThreeCode(langCode: String): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM LANGUAGE WHERE iso_639_3_standard = ? OR iso_639_2_standard = ? LIMIT 1 "
      )) { _stmt -> 
    _stmt.setString(1,langCode)
    _stmt.setString(2,langCode)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override fun totalLanguageCount(): Int =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT COUNT(*) FROM LANGUAGE" )) {
      _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  public override fun findByUid(primaryLanguageUid: Long): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setLong(1,primaryLanguageUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override suspend fun findByUidAsync(primaryLanguageUid: Long): Language? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setLong(1,primaryLanguageUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override fun findAllLanguageLive(): LiveData<List<Language>> = LiveDataImpl(_db,
      listOf("LANGUAGE"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM LANGUAGE" )) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_langUid = _result.getLong("langUid")
          val _tmp_name = _result.getString("name")
          val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
          val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
          val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
          val _tmp_Language_Type = _result.getString("Language_Type")
          val _tmp_languageActive = _result.getBoolean("languageActive")
          val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
          val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
          val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
          val _tmp_langLct = _result.getLong("langLct")
          Language().apply {
            this.langUid = _tmp_langUid
            this.name = _tmp_name
            this.iso_639_1_standard = _tmp_iso_639_1_standard
            this.iso_639_2_standard = _tmp_iso_639_2_standard
            this.iso_639_3_standard = _tmp_iso_639_3_standard
            this.Language_Type = _tmp_Language_Type
            this.languageActive = _tmp_languageActive
            this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
            this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
            this.langLastChangedBy = _tmp_langLastChangedBy
            this.langLct = _tmp_langLct
          }
        }
      }
    }
  }

  public override fun findByUidList(uidList: List<Long>): List<Long> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT langUid FROM LANGUAGE WHERE langUid IN (?)"
      ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE Language 
    |           SET languageActive = ?, 
    |               langLct = ?
    |         WHERE langUid IN (?)
    """.trimMargin() ,hasListParams = true)) { _stmt -> 
      _stmt.setBoolean(1,toggleVisibility)
      _stmt.setLong(2,updateTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedItem.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
