package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class LeavingReasonDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: LeavingReasonDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : LeavingReasonDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findAllReasons(): DataSourceFactory<Int, LeavingReason> {
    val _result = _dao.findAllReasons()
    return _result
  }

  public override fun findAllReasonsLive(): List<LeavingReason> {
    val _result = _dao.findAllReasonsLive()
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): LeavingReason? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> {
    val _result = _dao.findByUidList(uidList)
    return _result
  }

  public override fun findByUidLive(uid: Long): LiveData<LeavingReason?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel> {
    val _result = _dao.getReasonsFromUids(uidList)
    return _result
  }

  public override suspend fun replaceList(entityList: List<LeavingReason>): Unit {
    _dao.replaceList(entityList)
  }

  public override suspend fun updateAsync(entity: LeavingReason): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun insert(entity: LeavingReason): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: LeavingReason): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<LeavingReason>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<LeavingReason>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: LeavingReason): Unit {
    _dao.update(entity)
  }
}
