package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseTerminology
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseTerminologyDao_JdbcKt(
  public val _db: RoomDatabase,
) : CourseTerminologyDao() {
  public val _insertAdapterCourseTerminology_: EntityInsertionAdapter<CourseTerminology> = object :
      EntityInsertionAdapter<CourseTerminology>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO CourseTerminology (ctUid, ctTitle, ctTerminology, ctLct) VALUES(?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseTerminology): Unit {
      if(entity.ctUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ctUid)
      }
      stmt.setString(2, entity.ctTitle)
      stmt.setString(3, entity.ctTerminology)
      stmt.setLong(4, entity.ctLct)
    }
  }

  public override fun insert(entity: CourseTerminology): Long {
    val _retVal = _insertAdapterCourseTerminology_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseTerminology): Long {
    val _retVal = _insertAdapterCourseTerminology_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CourseTerminology>): Unit {
    _insertAdapterCourseTerminology_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: CourseTerminology): Int {
    var _result = 0
    val _sql =
        "UPDATE CourseTerminology SET ctTitle = ?, ctTerminology = ?, ctLct = ? WHERE ctUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.ctTitle)
      _stmt.setString(2, entity.ctTerminology)
      _stmt.setLong(3, entity.ctLct)
      _stmt.setLong(4, entity.ctUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<CourseTerminology>): Unit {
    val _sql =
        "UPDATE CourseTerminology SET ctTitle = ?, ctTerminology = ?, ctLct = ? WHERE ctUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.ctTitle)
        _stmt.setString(2, _entity.ctTerminology)
        _stmt.setLong(3, _entity.ctLct)
        _stmt.setLong(4, _entity.ctUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseTerminology): Unit {
    val _sql =
        "UPDATE CourseTerminology SET ctTitle = ?, ctTerminology = ?, ctLct = ? WHERE ctUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.ctTitle)
      _stmt.setString(2, entity.ctTerminology)
      _stmt.setLong(3, entity.ctLct)
      _stmt.setLong(4, entity.ctUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |      SELECT DISTINCT CourseTerminology.ctUid AS ctPk,
    |             ? AS ctDestination
    |        FROM CourseTerminology
    |       WHERE CourseTerminology.ctLct != COALESCE(
    |             (SELECT ctVersionId
    |                FROM CourseTerminologyReplicate
    |               WHERE ctPk = CourseTerminology.ctUid
    |                 AND ctDestination = ?), 0) 
    |      /*psql ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |             SET ctPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |      SELECT DISTINCT CourseTerminology.ctUid AS ctPk,
    |             ? AS ctDestination
    |        FROM CourseTerminology
    |       WHERE CourseTerminology.ctLct != COALESCE(
    |             (SELECT ctVersionId
    |                FROM CourseTerminologyReplicate
    |               WHERE ctPk = CourseTerminology.ctUid
    |                 AND ctDestination = ?), 0) 
    |       ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |             SET ctPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |  SELECT DISTINCT CourseTerminology.ctUid AS ctUid,
    |         UserSession.usClientNodeId AS ctDestination
    |    FROM ChangeLog
    |         JOIN CourseTerminology
    |             ON ChangeLog.chTableId = 450
    |                AND ChangeLog.chEntityPk = CourseTerminology.ctUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseTerminology.ctLct != COALESCE(
    |         (SELECT ctVersionId
    |            FROM CourseTerminologyReplicate
    |           WHERE ctPk = CourseTerminology.ctUid
    |             AND ctDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |     SET ctPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |  SELECT DISTINCT CourseTerminology.ctUid AS ctUid,
    |         UserSession.usClientNodeId AS ctDestination
    |    FROM ChangeLog
    |         JOIN CourseTerminology
    |             ON ChangeLog.chTableId = 450
    |                AND ChangeLog.chEntityPk = CourseTerminology.ctUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseTerminology.ctLct != COALESCE(
    |         (SELECT ctVersionId
    |            FROM CourseTerminologyReplicate
    |           WHERE ctPk = CourseTerminology.ctUid
    |             AND ctDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |     SET ctPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllCourseTerminology(): DataSourceFactory<Int, CourseTerminology> = object
      : DataSourceFactory<Int, CourseTerminology>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<CourseTerminology>> =
        LiveDataImpl(_db, listOf("CourseTerminology"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT *
      |         FROM CourseTerminology
      |     ORDER BY ctTitle   
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,_limit)
        _stmt.setInt(2,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_ctUid = _result.getLong("ctUid")
            val _tmp_ctTitle = _result.getString("ctTitle")
            val _tmp_ctTerminology = _result.getString("ctTerminology")
            val _tmp_ctLct = _result.getLong("ctLct")
            CourseTerminology().apply {
              this.ctUid = _tmp_ctUid
              this.ctTitle = _tmp_ctTitle
              this.ctTerminology = _tmp_ctTerminology
              this.ctLct = _tmp_ctLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("CourseTerminology"))
         {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT *
      |         FROM CourseTerminology
      |     ORDER BY ctTitle   
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findAllCourseTerminologyList(): List<CourseTerminology> =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT *
  |         FROM CourseTerminology
  |     ORDER BY ctTitle   
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_ctUid = _result.getLong("ctUid")
        val _tmp_ctTitle = _result.getString("ctTitle")
        val _tmp_ctTerminology = _result.getString("ctTerminology")
        val _tmp_ctLct = _result.getLong("ctLct")
        CourseTerminology().apply {
          this.ctUid = _tmp_ctUid
          this.ctTitle = _tmp_ctTitle
          this.ctTerminology = _tmp_ctTerminology
          this.ctLct = _tmp_ctLct
        }
      }
    }
  }

  public override suspend fun getTerminologyForClazz(clazzUid: Long): CourseTerminology? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT *
  |          FROM CourseTerminology
  |               JOIN Clazz 
  |               ON Clazz.clazzTerminologyUid = CourseTerminology.ctUid
  |         WHERE Clazz.clazzUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_ctUid = _result.getLong("ctUid")
        val _tmp_ctTitle = _result.getString("ctTitle")
        val _tmp_ctTerminology = _result.getString("ctTerminology")
        val _tmp_ctLct = _result.getLong("ctLct")
        CourseTerminology().apply {
          this.ctUid = _tmp_ctUid
          this.ctTitle = _tmp_ctTitle
          this.ctTerminology = _tmp_ctTerminology
          this.ctLct = _tmp_ctLct
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): CourseTerminology? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |         FROM CourseTerminology 
  |        WHERE ctUid = ?
  |        
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_ctUid = _result.getLong("ctUid")
        val _tmp_ctTitle = _result.getString("ctTitle")
        val _tmp_ctTerminology = _result.getString("ctTerminology")
        val _tmp_ctLct = _result.getLong("ctLct")
        CourseTerminology().apply {
          this.ctUid = _tmp_ctUid
          this.ctTitle = _tmp_ctTitle
          this.ctTerminology = _tmp_ctTerminology
          this.ctLct = _tmp_ctLct
        }
      }
    }
  }
}
