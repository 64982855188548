package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonGroup
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonGroupDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonGroupDao,
) : PersonGroupDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _dao.replicateOnChangeClazzBased()
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeClazzBased(newNodeId)
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    _dao.replicateOnChangePersonBased()
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodePersonBased(newNodeId)
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _dao.replicateOnChangeSchoolBased()
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeSchoolBased(newNodeId)
  }

  public override fun findByUid(uid: Long): PersonGroup? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUidAsync(uid: Long): PersonGroup? = _dao.findByUidAsync(uid)

  public override fun findByUidLive(uid: Long): LiveData<PersonGroup?> = _dao.findByUidLive(uid)

  public override suspend fun updateAsync(entity: PersonGroup): Int {
    entity.groupLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override suspend fun findNameByGroupUid(groupUid: Long): String? =
      _dao.findNameByGroupUid(groupUid)

  public override fun insert(entity: PersonGroup): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: PersonGroup): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.groupUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(43)
      entity.groupUid = _newPk
    }
    entity.groupLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.groupUid
  }

  public override fun insertList(entityList: List<PersonGroup>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<PersonGroup>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: PersonGroup): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
