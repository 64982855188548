package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazzAndAttendance
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson
import com.ustadmobile.lib.db.entities.PersonWithClazzEnrolmentDetails
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzEnrolmentDao : BaseDao<ClazzEnrolment> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateClazzEnrolmentOnChange(): Unit

  public actual abstract fun insertListAsync(entityList: List<ClazzEnrolment>): Unit

  public actual abstract suspend fun findByPersonUidAndClazzUidAsync(personUid: Long,
      clazzUid: Long): ClazzEnrolment?

  public actual abstract fun findAllEnrolmentsByPersonAndClazzUid(personUid: Long, clazzUid: Long):
      DataSourceFactory<Int, ClazzEnrolmentWithLeavingReason>

  public actual abstract suspend fun findEnrolmentWithLeavingReason(enrolmentUid: Long):
      ClazzEnrolmentWithLeavingReason?

  public actual abstract suspend fun updateDateLeftByUid(
    clazzEnrolmentUid: Long,
    endDate: Long,
    updateTime: Long,
  ): Unit

  public actual abstract suspend fun updateAsync(entity: ClazzEnrolment): Int

  public actual abstract fun findAllClazzesByPersonWithClazz(personUid: Long):
      DataSourceFactory<Int, ClazzEnrolmentWithClazzAndAttendance>

  public actual abstract suspend fun findMaxEndDateForEnrolment(
    selectedClazz: Long,
    selectedPerson: Long,
    selectedEnrolment: Long,
  ): Long

  public actual abstract suspend fun findAllClazzesByPersonWithClazzAsListAsync(personUid: Long):
      List<ClazzEnrolmentWithClazz>

  public actual abstract suspend fun getAllClazzEnrolledAtTimeAsync(
    clazzUid: Long,
    date: Long,
    roleFilter: Int,
    personUidFilter: Long,
  ): List<ClazzEnrolmentWithPerson>

  public actual abstract suspend fun findByUid(uid: Long): ClazzEnrolment?

  public actual abstract fun findByUidLive(uid: Long): LiveData<ClazzEnrolment?>

  public actual abstract suspend fun updateClazzEnrolmentActiveForPersonAndClazz(
    personUid: Long,
    clazzUid: Long,
    roleId: Int,
    active: Boolean,
    changeTime: Long,
  ): Int

  public actual abstract fun findByClazzUidAndRole(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
  ): DataSourceFactory<Int, PersonWithClazzEnrolmentDetails>

  public actual abstract fun updateClazzEnrolmentActiveForClazzEnrolment(
    clazzEnrolmentUid: Long,
    enrolled: Boolean,
    timeChanged: Long,
  ): Int

  public actual abstract suspend fun updateClazzEnrolmentRole(
    personUid: Long,
    clazzUid: Long,
    newRole: Int,
    oldRole: Int,
    updateTime: Long,
  ): Int
}
