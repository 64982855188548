package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import com.ustadmobile.lib.db.entities.SchoolWithMemberCountAndLocation
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SchoolDao_JdbcKt(
  public val _db: RoomDatabase,
) : SchoolDao() {
  public val _insertAdapterSchool_: EntityInsertionAdapter<School> = object :
      EntityInsertionAdapter<School>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO School (schoolUid, schoolName, schoolDesc, schoolAddress, schoolActive, schoolPhoneNumber, schoolGender, schoolHolidayCalendarUid, schoolFeatures, schoolLocationLong, schoolLocationLatt, schoolEmailAddress, schoolTeachersPersonGroupUid, schoolStudentsPersonGroupUid, schoolPendingStudentsPersonGroupUid, schoolCode, schoolMasterChangeSeqNum, schoolLocalChangeSeqNum, schoolLastChangedBy, schoolLct, schoolTimeZone) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: School): Unit {
      if(entity.schoolUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.schoolUid)
      }
      stmt.setString(2, entity.schoolName)
      stmt.setString(3, entity.schoolDesc)
      stmt.setString(4, entity.schoolAddress)
      stmt.setBoolean(5, entity.schoolActive)
      stmt.setString(6, entity.schoolPhoneNumber)
      stmt.setInt(7, entity.schoolGender)
      stmt.setLong(8, entity.schoolHolidayCalendarUid)
      stmt.setLong(9, entity.schoolFeatures)
      stmt.setDouble(10, entity.schoolLocationLong)
      stmt.setDouble(11, entity.schoolLocationLatt)
      stmt.setString(12, entity.schoolEmailAddress)
      stmt.setLong(13, entity.schoolTeachersPersonGroupUid)
      stmt.setLong(14, entity.schoolStudentsPersonGroupUid)
      stmt.setLong(15, entity.schoolPendingStudentsPersonGroupUid)
      stmt.setString(16, entity.schoolCode)
      stmt.setLong(17, entity.schoolMasterChangeSeqNum)
      stmt.setLong(18, entity.schoolLocalChangeSeqNum)
      stmt.setInt(19, entity.schoolLastChangedBy)
      stmt.setLong(20, entity.schoolLct)
      stmt.setString(21, entity.schoolTimeZone)
    }
  }

  public override fun insert(entity: School): Long {
    val _retVal = _insertAdapterSchool_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: School): Long {
    val _retVal = _insertAdapterSchool_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<School>): Unit {
    _insertAdapterSchool_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: School): Int {
    var _result = 0
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.schoolName)
      _stmt.setString(2, entity.schoolDesc)
      _stmt.setString(3, entity.schoolAddress)
      _stmt.setBoolean(4, entity.schoolActive)
      _stmt.setString(5, entity.schoolPhoneNumber)
      _stmt.setInt(6, entity.schoolGender)
      _stmt.setLong(7, entity.schoolHolidayCalendarUid)
      _stmt.setLong(8, entity.schoolFeatures)
      _stmt.setDouble(9, entity.schoolLocationLong)
      _stmt.setDouble(10, entity.schoolLocationLatt)
      _stmt.setString(11, entity.schoolEmailAddress)
      _stmt.setLong(12, entity.schoolTeachersPersonGroupUid)
      _stmt.setLong(13, entity.schoolStudentsPersonGroupUid)
      _stmt.setLong(14, entity.schoolPendingStudentsPersonGroupUid)
      _stmt.setString(15, entity.schoolCode)
      _stmt.setLong(16, entity.schoolMasterChangeSeqNum)
      _stmt.setLong(17, entity.schoolLocalChangeSeqNum)
      _stmt.setInt(18, entity.schoolLastChangedBy)
      _stmt.setLong(19, entity.schoolLct)
      _stmt.setString(20, entity.schoolTimeZone)
      _stmt.setLong(21, entity.schoolUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<School>): Unit {
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.schoolName)
        _stmt.setString(2, _entity.schoolDesc)
        _stmt.setString(3, _entity.schoolAddress)
        _stmt.setBoolean(4, _entity.schoolActive)
        _stmt.setString(5, _entity.schoolPhoneNumber)
        _stmt.setInt(6, _entity.schoolGender)
        _stmt.setLong(7, _entity.schoolHolidayCalendarUid)
        _stmt.setLong(8, _entity.schoolFeatures)
        _stmt.setDouble(9, _entity.schoolLocationLong)
        _stmt.setDouble(10, _entity.schoolLocationLatt)
        _stmt.setString(11, _entity.schoolEmailAddress)
        _stmt.setLong(12, _entity.schoolTeachersPersonGroupUid)
        _stmt.setLong(13, _entity.schoolStudentsPersonGroupUid)
        _stmt.setLong(14, _entity.schoolPendingStudentsPersonGroupUid)
        _stmt.setString(15, _entity.schoolCode)
        _stmt.setLong(16, _entity.schoolMasterChangeSeqNum)
        _stmt.setLong(17, _entity.schoolLocalChangeSeqNum)
        _stmt.setInt(18, _entity.schoolLastChangedBy)
        _stmt.setLong(19, _entity.schoolLct)
        _stmt.setString(20, _entity.schoolTimeZone)
        _stmt.setLong(21, _entity.schoolUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: School): Unit {
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.schoolName)
      _stmt.setString(2, entity.schoolDesc)
      _stmt.setString(3, entity.schoolAddress)
      _stmt.setBoolean(4, entity.schoolActive)
      _stmt.setString(5, entity.schoolPhoneNumber)
      _stmt.setInt(6, entity.schoolGender)
      _stmt.setLong(7, entity.schoolHolidayCalendarUid)
      _stmt.setLong(8, entity.schoolFeatures)
      _stmt.setDouble(9, entity.schoolLocationLong)
      _stmt.setDouble(10, entity.schoolLocationLatt)
      _stmt.setString(11, entity.schoolEmailAddress)
      _stmt.setLong(12, entity.schoolTeachersPersonGroupUid)
      _stmt.setLong(13, entity.schoolStudentsPersonGroupUid)
      _stmt.setLong(14, entity.schoolPendingStudentsPersonGroupUid)
      _stmt.setString(15, entity.schoolCode)
      _stmt.setLong(16, entity.schoolMasterChangeSeqNum)
      _stmt.setLong(17, entity.schoolLocalChangeSeqNum)
      _stmt.setInt(18, entity.schoolLastChangedBy)
      _stmt.setLong(19, entity.schoolLct)
      _stmt.setString(20, entity.schoolTimeZone)
      _stmt.setLong(21, entity.schoolUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO SchoolReplicate(schoolPk, schoolDestination)
    |      SELECT DISTINCT School.schoolUid AS schoolPk,
    |             ? AS schoolDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                  536870912
    |                  
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND School.schoolLct != COALESCE(
    |             (SELECT schoolVersionId
    |                FROM SchoolReplicate
    |               WHERE schoolPk = School.schoolUid
    |                 AND schoolDestination = ?), 0) 
    |      /*psql ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |             SET schoolPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolReplicate(schoolPk, schoolDestination)
    |      SELECT DISTINCT School.schoolUid AS schoolPk,
    |             ? AS schoolDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                  536870912
    |                  
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND School.schoolLct != COALESCE(
    |             (SELECT schoolVersionId
    |                FROM SchoolReplicate
    |               WHERE schoolPk = School.schoolUid
    |                 AND schoolDestination = ?), 0) 
    |       ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |             SET schoolPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO SchoolReplicate(schoolPk, schoolDestination)
    |  SELECT DISTINCT School.schoolUid AS schoolUid,
    |         UserSession.usClientNodeId AS schoolDestination
    |    FROM ChangeLog
    |         JOIN School
    |              ON ChangeLog.chTableId = 164
    |                  AND ChangeLog.chEntityPk = School.schoolUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND School.schoolLct != COALESCE(
    |         (SELECT schoolVersionId
    |            FROM SchoolReplicate
    |           WHERE schoolPk = School.schoolUid
    |             AND schoolDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |     SET schoolPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolReplicate(schoolPk, schoolDestination)
    |  SELECT DISTINCT School.schoolUid AS schoolUid,
    |         UserSession.usClientNodeId AS schoolDestination
    |    FROM ChangeLog
    |         JOIN School
    |              ON ChangeLog.chTableId = 164
    |                  AND ChangeLog.chEntityPk = School.schoolUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND School.schoolLct != COALESCE(
    |         (SELECT schoolVersionId
    |            FROM SchoolReplicate
    |           WHERE schoolPk = School.schoolUid
    |             AND schoolDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |     SET schoolPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByUidAsync(schoolUid: Long): School? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM School WHERE schoolUid = ? AND CAST(schoolActive AS INTEGER) = 1"
      )) { _stmt -> 
    _stmt.setLong(1,schoolUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        School().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
        }
      }
    }
  }

  public override suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      SchoolWithHolidayCalendar? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT School.*, HolidayCalendar.* FROM School 
  |            LEFT JOIN HolidayCalendar ON School.schoolHolidayCalendarUid = HolidayCalendar.umCalendarUid
  |            WHERE School.schoolUid = ?
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        var _tmp_HolidayCalendar_nullCount = 0
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarName = _result.getString("umCalendarName")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_HolidayCalendar_isAllNull = _tmp_HolidayCalendar_nullCount == 8
        SchoolWithHolidayCalendar().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
          if(!_tmp_HolidayCalendar_isAllNull) {
            this.holidayCalendar = HolidayCalendar().apply {
              this.umCalendarUid = _tmp_umCalendarUid
              this.umCalendarName = _tmp_umCalendarName
              this.umCalendarCategory = _tmp_umCalendarCategory
              this.umCalendarActive = _tmp_umCalendarActive
              this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
              this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
              this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
              this.umCalendarLct = _tmp_umCalendarLct
            }
          }
        }
      }
    }
  }

  public override suspend fun findBySchoolCode(code: String): School? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM School WHERE schoolCode = ?"
      )) { _stmt -> 
    _stmt.setString(1,code)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        School().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
        }
      }
    }
  }

  public override suspend fun findBySchoolCodeFromWeb(code: String): School? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM School WHERE schoolCode = ?"
      )) { _stmt -> 
    _stmt.setString(1,code)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        School().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
        }
      }
    }
  }

  public override suspend fun personHasPermissionWithSchool(
    accountPersonUid: Long,
    schoolUid: Long,
    permission: Long,
  ): Boolean = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |    Select EXISTS(
  |           SELECT School.schoolUid 
  |             FROM School
  |                  
  |            JOIN ScopedGrant
  |                 ON 
  |            ((ScopedGrant.sgTableId = -2
  |                    AND ScopedGrant.sgEntityUid = -2)
  |                OR (ScopedGrant.sgTableId = 164
  |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
  |        
  |                        AND (SCopedGrant.sgPermissions &
  |         ?) > 0
  |             JOIN PersonGroupMember AS PrsGrpMbr
  |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
  |                      AND PrsGrpMbr.groupMemberPersonUid = ?
  |            WHERE School.schoolUid = ?)      
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,permission)
    _stmt.setLong(2,accountPersonUid)
    _stmt.setLong(3,schoolUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override fun findAllActiveSchoolWithMemberCountAndLocationName(
    searchBit: String,
    personUid: Long,
    permission: Long,
    sortOrder: Int,
  ): DataSourceFactory<Int, SchoolWithMemberCountAndLocation> = object :
      DataSourceFactory<Int, SchoolWithMemberCountAndLocation>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<SchoolWithMemberCountAndLocation>> = LiveDataImpl(_db, listOf("SchoolMember",
        "Clazz", "PersonGroupMember", "ScopedGrant", "School"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |       SELECT School.*, 
      |              (SELECT COUNT(*) 
      |                  FROM SchoolMember 
      |                 WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
      |                   AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
      |                   AND SchoolMember.schoolMemberRole = 1003) as numStudents,
      |              (SELECT COUNT(*) 
      |                 FROM SchoolMember 
      |                WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
      |                  AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
      |                  AND SchoolMember.schoolMemberRole = 1004) as numTeachers, 
      |               '' as locationName,
      |              (SELECT COUNT(*) 
      |                 FROM Clazz 
      |                WHERE Clazz.clazzSchoolUid = School.schoolUid 
      |                  AND CAST(Clazz.clazzUid AS INTEGER) = 1 ) as clazzCount
      |         FROM PersonGroupMember
      |              
      |            JOIN ScopedGrant 
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                        AND (ScopedGrant.sgPermissions &
      |                 
      |        
      |                    ?
      |                    
      |                    ) > 0
      |            JOIN School
      |                 ON 
      |            ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                OR (ScopedGrant.sgTableId = 164
      |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
      |        
      |        
      |        WHERE PersonGroupMember.groupMemberPersonUid = ?
      |          AND PersonGroupMember.groupMemberActive 
      |          AND CAST(schoolActive AS INTEGER) = 1
      |          AND schoolName LIKE ?
      |     GROUP BY School.schoolUid
      |     ORDER BY CASE(?)
      |              WHEN 1 THEN School.schoolName
      |              ELSE ''
      |              END ASC,
      |              CASE(?)
      |              WHEN 2 THEN School.schoolName
      |              ELSE ''
      |              END DESC) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,permission)
        _stmt.setLong(2,personUid)
        _stmt.setString(3,searchBit)
        _stmt.setInt(4,sortOrder)
        _stmt.setInt(5,sortOrder)
        _stmt.setInt(6,_limit)
        _stmt.setInt(7,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_numStudents = _result.getInt("numStudents")
            val _tmp_numTeachers = _result.getInt("numTeachers")
            val _tmp_locationName = _result.getString("locationName")
            val _tmp_clazzCount = _result.getInt("clazzCount")
            val _tmp_schoolUid = _result.getLong("schoolUid")
            val _tmp_schoolName = _result.getString("schoolName")
            val _tmp_schoolDesc = _result.getString("schoolDesc")
            val _tmp_schoolAddress = _result.getString("schoolAddress")
            val _tmp_schoolActive = _result.getBoolean("schoolActive")
            val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
            val _tmp_schoolGender = _result.getInt("schoolGender")
            val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
            val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
            val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
            val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
            val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
            val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
            val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
            val _tmp_schoolPendingStudentsPersonGroupUid =
                _result.getLong("schoolPendingStudentsPersonGroupUid")
            val _tmp_schoolCode = _result.getString("schoolCode")
            val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
            val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
            val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
            val _tmp_schoolLct = _result.getLong("schoolLct")
            val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
            SchoolWithMemberCountAndLocation().apply {
              this.numStudents = _tmp_numStudents
              this.numTeachers = _tmp_numTeachers
              this.locationName = _tmp_locationName
              this.clazzCount = _tmp_clazzCount
              this.schoolUid = _tmp_schoolUid
              this.schoolName = _tmp_schoolName
              this.schoolDesc = _tmp_schoolDesc
              this.schoolAddress = _tmp_schoolAddress
              this.schoolActive = _tmp_schoolActive
              this.schoolPhoneNumber = _tmp_schoolPhoneNumber
              this.schoolGender = _tmp_schoolGender
              this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
              this.schoolFeatures = _tmp_schoolFeatures
              this.schoolLocationLong = _tmp_schoolLocationLong
              this.schoolLocationLatt = _tmp_schoolLocationLatt
              this.schoolEmailAddress = _tmp_schoolEmailAddress
              this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
              this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
              this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
              this.schoolCode = _tmp_schoolCode
              this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
              this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
              this.schoolLastChangedBy = _tmp_schoolLastChangedBy
              this.schoolLct = _tmp_schoolLct
              this.schoolTimeZone = _tmp_schoolTimeZone
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("SchoolMember",
        "Clazz", "PersonGroupMember", "ScopedGrant", "School"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |       SELECT School.*, 
      |              (SELECT COUNT(*) 
      |                  FROM SchoolMember 
      |                 WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
      |                   AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
      |                   AND SchoolMember.schoolMemberRole = 1003) as numStudents,
      |              (SELECT COUNT(*) 
      |                 FROM SchoolMember 
      |                WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
      |                  AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
      |                  AND SchoolMember.schoolMemberRole = 1004) as numTeachers, 
      |               '' as locationName,
      |              (SELECT COUNT(*) 
      |                 FROM Clazz 
      |                WHERE Clazz.clazzSchoolUid = School.schoolUid 
      |                  AND CAST(Clazz.clazzUid AS INTEGER) = 1 ) as clazzCount
      |         FROM PersonGroupMember
      |              
      |            JOIN ScopedGrant 
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                        AND (ScopedGrant.sgPermissions &
      |                 
      |        
      |                    ?
      |                    
      |                    ) > 0
      |            JOIN School
      |                 ON 
      |            ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                OR (ScopedGrant.sgTableId = 164
      |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
      |        
      |        
      |        WHERE PersonGroupMember.groupMemberPersonUid = ?
      |          AND PersonGroupMember.groupMemberActive 
      |          AND CAST(schoolActive AS INTEGER) = 1
      |          AND schoolName LIKE ?
      |     GROUP BY School.schoolUid
      |     ORDER BY CASE(?)
      |              WHEN 1 THEN School.schoolName
      |              ELSE ''
      |              END ASC,
      |              CASE(?)
      |              WHEN 2 THEN School.schoolName
      |              ELSE ''
      |              END DESC) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,permission)
        _stmt.setLong(2,personUid)
        _stmt.setString(3,searchBit)
        _stmt.setInt(4,sortOrder)
        _stmt.setInt(5,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }
}
