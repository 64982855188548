package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithCourseBlock
import com.ustadmobile.lib.db.entities.PersonGroupAssignmentSummary
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzAssignmentDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzAssignmentDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzAssignmentDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun getAllAssignmentsByClazzUidAsync(clazzUid: Long):
      List<ClazzAssignment> {
    val _result = _dao.getAllAssignmentsByClazzUidAsync(clazzUid)
    return _result
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  public override fun getProgressSummaryForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
  ): LiveData<AssignmentProgressSummary?> {
    val _result = _dao.getProgressSummaryForAssignment(assignmentUid, clazzUid, group)
    return _result
  }

  public override fun getSubmitterListForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
    searchText: String,
  ): DataSourceFactory<Int, PersonGroupAssignmentSummary> {
    val _result = _dao.getSubmitterListForAssignment(assignmentUid, clazzUid, group, searchText)
    return _result
  }

  public override suspend fun getSubmitterUid(assignmentUid: Long, personUid: Long): Long {
    val _result = _dao.getSubmitterUid(assignmentUid, personUid)
    return _result
  }

  public override suspend fun updateAsync(clazzAssignment: ClazzAssignment): Unit {
    _dao.updateAsync(clazzAssignment)
  }

  public override suspend fun findByUidAsync(uid: Long): ClazzAssignment? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override suspend fun getGroupUidFromAssignment(uid: Long): Long {
    val _result = _dao.getGroupUidFromAssignment(uid)
    return _result
  }

  public override suspend fun findByUidWithBlockAsync(uid: Long): ClazzAssignmentWithCourseBlock? {
    val _result = _dao.findByUidWithBlockAsync(uid)
    return _result
  }

  public override fun findClazzAssignment(): ClazzAssignment? {
    val _result = _dao.findClazzAssignment()
    return _result
  }

  public override fun findByUidLive(uid: Long): LiveData<ClazzAssignment?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override fun insert(entity: ClazzAssignment): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzAssignment): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<ClazzAssignment>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<ClazzAssignment>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ClazzAssignment): Unit {
    _dao.update(entity)
  }

  public override suspend fun insertListAsync(entityList: List<ClazzAssignment>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<ClazzAssignment>): Unit {
    _dao.updateListAsync(entityList)
  }
}
