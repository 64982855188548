package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ScheduleDao : BaseDao<Schedule>, OneToManyJoinDao<Schedule> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract override fun insert(entity: Schedule): Long

  public actual abstract suspend fun updateAsync(entity: Schedule): Int

  public actual abstract suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit

  public actual abstract fun findByUid(uid: Long): Schedule?

  public actual abstract suspend fun findByUidAsync(uid: Long): Schedule?

  public actual abstract fun findAllSchedulesByClazzUid(clazzUid: Long):
      DataSourceFactory<Int, Schedule>

  public actual abstract fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule>

  public actual abstract fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long):
      LiveData<List<Schedule>>

  public actual abstract suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule>
}
