package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.SchoolMember
import com.ustadmobile.lib.db.entities.SchoolMemberWithPerson
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SchoolMemberDao_JdbcKt(
  public val _db: RoomDatabase,
) : SchoolMemberDao() {
  public val _insertAdapterSchoolMember_: EntityInsertionAdapter<SchoolMember> = object :
      EntityInsertionAdapter<SchoolMember>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO SchoolMember (schoolMemberUid, schoolMemberPersonUid, schoolMemberSchoolUid, schoolMemberJoinDate, schoolMemberLeftDate, schoolMemberRole, schoolMemberActive, schoolMemberLocalChangeSeqNum, schoolMemberMasterChangeSeqNum, schoolMemberLastChangedBy, schoolMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: SchoolMember):
        Unit {
      if(entity.schoolMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.schoolMemberUid)
      }
      stmt.setLong(2, entity.schoolMemberPersonUid)
      stmt.setLong(3, entity.schoolMemberSchoolUid)
      stmt.setLong(4, entity.schoolMemberJoinDate)
      stmt.setLong(5, entity.schoolMemberLeftDate)
      stmt.setInt(6, entity.schoolMemberRole)
      stmt.setBoolean(7, entity.schoolMemberActive)
      stmt.setLong(8, entity.schoolMemberLocalChangeSeqNum)
      stmt.setLong(9, entity.schoolMemberMasterChangeSeqNum)
      stmt.setInt(10, entity.schoolMemberLastChangedBy)
      stmt.setLong(11, entity.schoolMemberLct)
    }
  }

  public override fun insert(entity: SchoolMember): Long {
    val _retVal = _insertAdapterSchoolMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: SchoolMember): Long {
    val _retVal = _insertAdapterSchoolMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<SchoolMember>): Unit {
    _insertAdapterSchoolMember_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: SchoolMember): Int {
    var _result = 0
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.schoolMemberPersonUid)
      _stmt.setLong(2, entity.schoolMemberSchoolUid)
      _stmt.setLong(3, entity.schoolMemberJoinDate)
      _stmt.setLong(4, entity.schoolMemberLeftDate)
      _stmt.setInt(5, entity.schoolMemberRole)
      _stmt.setBoolean(6, entity.schoolMemberActive)
      _stmt.setLong(7, entity.schoolMemberLocalChangeSeqNum)
      _stmt.setLong(8, entity.schoolMemberMasterChangeSeqNum)
      _stmt.setInt(9, entity.schoolMemberLastChangedBy)
      _stmt.setLong(10, entity.schoolMemberLct)
      _stmt.setLong(11, entity.schoolMemberUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<SchoolMember>): Unit {
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.schoolMemberPersonUid)
        _stmt.setLong(2, _entity.schoolMemberSchoolUid)
        _stmt.setLong(3, _entity.schoolMemberJoinDate)
        _stmt.setLong(4, _entity.schoolMemberLeftDate)
        _stmt.setInt(5, _entity.schoolMemberRole)
        _stmt.setBoolean(6, _entity.schoolMemberActive)
        _stmt.setLong(7, _entity.schoolMemberLocalChangeSeqNum)
        _stmt.setLong(8, _entity.schoolMemberMasterChangeSeqNum)
        _stmt.setInt(9, _entity.schoolMemberLastChangedBy)
        _stmt.setLong(10, _entity.schoolMemberLct)
        _stmt.setLong(11, _entity.schoolMemberUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: SchoolMember): Unit {
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.schoolMemberPersonUid)
      _stmt.setLong(2, entity.schoolMemberSchoolUid)
      _stmt.setLong(3, entity.schoolMemberJoinDate)
      _stmt.setLong(4, entity.schoolMemberLeftDate)
      _stmt.setInt(5, entity.schoolMemberRole)
      _stmt.setBoolean(6, entity.schoolMemberActive)
      _stmt.setLong(7, entity.schoolMemberLocalChangeSeqNum)
      _stmt.setLong(8, entity.schoolMemberMasterChangeSeqNum)
      _stmt.setInt(9, entity.schoolMemberLastChangedBy)
      _stmt.setLong(10, entity.schoolMemberLct)
      _stmt.setLong(11, entity.schoolMemberUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO SchoolMemberReplicate(smPk, smDestination)
    |      SELECT DISTINCT SchoolMember.schoolMemberUid AS smPk,
    |             ? AS smDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions &  64) > 0
    |             JOIN SchoolMember
    |                  ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND SchoolMember.schoolMemberLct != COALESCE(
    |             (SELECT smVersionId
    |                FROM SchoolMemberReplicate
    |               WHERE smPk = SchoolMember.schoolMemberUid
    |                 AND smDestination = ?), 0) 
    |      /*psql ON CONFLICT(smPk, smDestination) DO UPDATE
    |             SET smPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolMemberReplicate(smPk, smDestination)
    |      SELECT DISTINCT SchoolMember.schoolMemberUid AS smPk,
    |             ? AS smDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions &  64) > 0
    |             JOIN SchoolMember
    |                  ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND SchoolMember.schoolMemberLct != COALESCE(
    |             (SELECT smVersionId
    |                FROM SchoolMemberReplicate
    |               WHERE smPk = SchoolMember.schoolMemberUid
    |                 AND smDestination = ?), 0) 
    |       ON CONFLICT(smPk, smDestination) DO UPDATE
    |             SET smPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO SchoolMemberReplicate(smPk, smDestination)
    |  SELECT DISTINCT SchoolMember.schoolMemberUid AS smUid,
    |         UserSession.usClientNodeId AS smDestination
    |    FROM ChangeLog
    |         JOIN SchoolMember
    |              ON ChangeLog.chTableId = 200
    |                  AND ChangeLog.chEntityPk = SchoolMember.schoolMemberUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions &
    |        
    |              64
    |              
    |            ) > 0  
    |            JOIN PersonGroupMember 
    |                   ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |            JOIN UserSession
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                      AND UserSession.usStatus =1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SchoolMember.schoolMemberLct != COALESCE(
    |         (SELECT smVersionId
    |            FROM SchoolMemberReplicate
    |           WHERE smPk = SchoolMember.schoolMemberUid
    |             AND smDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(smPk, smDestination) DO UPDATE
    |     SET smPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolMemberReplicate(smPk, smDestination)
    |  SELECT DISTINCT SchoolMember.schoolMemberUid AS smUid,
    |         UserSession.usClientNodeId AS smDestination
    |    FROM ChangeLog
    |         JOIN SchoolMember
    |              ON ChangeLog.chTableId = 200
    |                  AND ChangeLog.chEntityPk = SchoolMember.schoolMemberUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions &
    |        
    |              64
    |              
    |            ) > 0  
    |            JOIN PersonGroupMember 
    |                   ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |            JOIN UserSession
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                      AND UserSession.usStatus =1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SchoolMember.schoolMemberLct != COALESCE(
    |         (SELECT smVersionId
    |            FROM SchoolMemberReplicate
    |           WHERE smPk = SchoolMember.schoolMemberUid
    |             AND smDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(smPk, smDestination) DO UPDATE
    |     SET smPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUidAsync(schoolMemberUid: Long): SchoolMember? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM SchoolMember WHERE schoolMemberUid = ?  AND CAST(schoolMemberActive AS INTEGER) = 1"
      )) { _stmt -> 
    _stmt.setLong(1,schoolMemberUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolMemberUid = _result.getLong("schoolMemberUid")
        val _tmp_schoolMemberPersonUid = _result.getLong("schoolMemberPersonUid")
        val _tmp_schoolMemberSchoolUid = _result.getLong("schoolMemberSchoolUid")
        val _tmp_schoolMemberJoinDate = _result.getLong("schoolMemberJoinDate")
        val _tmp_schoolMemberLeftDate = _result.getLong("schoolMemberLeftDate")
        val _tmp_schoolMemberRole = _result.getInt("schoolMemberRole")
        val _tmp_schoolMemberActive = _result.getBoolean("schoolMemberActive")
        val _tmp_schoolMemberLocalChangeSeqNum = _result.getLong("schoolMemberLocalChangeSeqNum")
        val _tmp_schoolMemberMasterChangeSeqNum = _result.getLong("schoolMemberMasterChangeSeqNum")
        val _tmp_schoolMemberLastChangedBy = _result.getInt("schoolMemberLastChangedBy")
        val _tmp_schoolMemberLct = _result.getLong("schoolMemberLct")
        SchoolMember().apply {
          this.schoolMemberUid = _tmp_schoolMemberUid
          this.schoolMemberPersonUid = _tmp_schoolMemberPersonUid
          this.schoolMemberSchoolUid = _tmp_schoolMemberSchoolUid
          this.schoolMemberJoinDate = _tmp_schoolMemberJoinDate
          this.schoolMemberLeftDate = _tmp_schoolMemberLeftDate
          this.schoolMemberRole = _tmp_schoolMemberRole
          this.schoolMemberActive = _tmp_schoolMemberActive
          this.schoolMemberLocalChangeSeqNum = _tmp_schoolMemberLocalChangeSeqNum
          this.schoolMemberMasterChangeSeqNum = _tmp_schoolMemberMasterChangeSeqNum
          this.schoolMemberLastChangedBy = _tmp_schoolMemberLastChangedBy
          this.schoolMemberLct = _tmp_schoolMemberLct
        }
      }
    }
  }

  public override suspend fun findBySchoolAndPersonAndRole(
    schoolUid: Long,
    personUid: Long,
    role: Int,
    timeFilter: Long,
  ): List<SchoolMember> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * FROM SchoolMember WHERE schoolMemberSchoolUid = ?
  |        AND schoolMemberPersonUid = ?
  |        AND (? = 0 OR schoolMemberRole = ?)
  |        AND (? = 0 OR ? BETWEEN SchoolMember.schoolMemberJoinDate AND SchoolMember.schoolMemberLeftDate) 
  |        AND CAST(schoolMemberActive AS INTEGER) = 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,schoolUid)
    _stmt.setLong(2,personUid)
    _stmt.setInt(3,role)
    _stmt.setInt(4,role)
    _stmt.setLong(5,timeFilter)
    _stmt.setLong(6,timeFilter)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_schoolMemberUid = _result.getLong("schoolMemberUid")
        val _tmp_schoolMemberPersonUid = _result.getLong("schoolMemberPersonUid")
        val _tmp_schoolMemberSchoolUid = _result.getLong("schoolMemberSchoolUid")
        val _tmp_schoolMemberJoinDate = _result.getLong("schoolMemberJoinDate")
        val _tmp_schoolMemberLeftDate = _result.getLong("schoolMemberLeftDate")
        val _tmp_schoolMemberRole = _result.getInt("schoolMemberRole")
        val _tmp_schoolMemberActive = _result.getBoolean("schoolMemberActive")
        val _tmp_schoolMemberLocalChangeSeqNum = _result.getLong("schoolMemberLocalChangeSeqNum")
        val _tmp_schoolMemberMasterChangeSeqNum = _result.getLong("schoolMemberMasterChangeSeqNum")
        val _tmp_schoolMemberLastChangedBy = _result.getInt("schoolMemberLastChangedBy")
        val _tmp_schoolMemberLct = _result.getLong("schoolMemberLct")
        SchoolMember().apply {
          this.schoolMemberUid = _tmp_schoolMemberUid
          this.schoolMemberPersonUid = _tmp_schoolMemberPersonUid
          this.schoolMemberSchoolUid = _tmp_schoolMemberSchoolUid
          this.schoolMemberJoinDate = _tmp_schoolMemberJoinDate
          this.schoolMemberLeftDate = _tmp_schoolMemberLeftDate
          this.schoolMemberRole = _tmp_schoolMemberRole
          this.schoolMemberActive = _tmp_schoolMemberActive
          this.schoolMemberLocalChangeSeqNum = _tmp_schoolMemberLocalChangeSeqNum
          this.schoolMemberMasterChangeSeqNum = _tmp_schoolMemberMasterChangeSeqNum
          this.schoolMemberLastChangedBy = _tmp_schoolMemberLastChangedBy
          this.schoolMemberLct = _tmp_schoolMemberLct
        }
      }
    }
  }

  public override fun findAllActiveMembersBySchoolAndRoleUid(
    schoolUid: Long,
    role: Int,
    sortOrder: Int,
    searchQuery: String,
    accountPersonUid: Long,
  ): DataSourceFactory<Int, SchoolMemberWithPerson> = object :
      DataSourceFactory<Int, SchoolMemberWithPerson>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<SchoolMemberWithPerson>> =
        LiveDataImpl(_db, listOf("PersonGroupMember", "ScopedGrant", "Person", "SchoolMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT SchoolMember.*, Person.*
      |          FROM PersonGroupMember
      |                
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions & 
      |                64 
      |                
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |          LEFT JOIN SchoolMember ON Person.personUid = SchoolMember.schoolMemberPersonUid 
      |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
      |         AND PersonGroupMember.groupMemberActive  
      |        AND SchoolMember.schoolMemberActive
      |        AND SchoolMember.schoolMemberSchoolUid = ? 
      |        AND SchoolMember.schoolMemberRole = ?
      |        AND Person.active
      |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
      |        GROUP BY Person.personUid, SchoolMember.schoolMemberUid
      |         ORDER BY CASE(?)
      |                WHEN 1 THEN Person.firstNames
      |                WHEN 3 THEN Person.lastName
      |                ELSE ''
      |            END ASC,
      |            CASE(?)
      |                WHEN 2 THEN Person.firstNames
      |                WHEN 4 THEN Person.lastName
      |                ELSE ''
      |            END DESC
      |            ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,schoolUid)
        _stmt.setInt(3,role)
        _stmt.setString(4,searchQuery)
        _stmt.setInt(5,sortOrder)
        _stmt.setInt(6,sortOrder)
        _stmt.setInt(7,_limit)
        _stmt.setInt(8,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_schoolMemberUid = _result.getLong("schoolMemberUid")
            val _tmp_schoolMemberPersonUid = _result.getLong("schoolMemberPersonUid")
            val _tmp_schoolMemberSchoolUid = _result.getLong("schoolMemberSchoolUid")
            val _tmp_schoolMemberJoinDate = _result.getLong("schoolMemberJoinDate")
            val _tmp_schoolMemberLeftDate = _result.getLong("schoolMemberLeftDate")
            val _tmp_schoolMemberRole = _result.getInt("schoolMemberRole")
            val _tmp_schoolMemberActive = _result.getBoolean("schoolMemberActive")
            val _tmp_schoolMemberLocalChangeSeqNum =
                _result.getLong("schoolMemberLocalChangeSeqNum")
            val _tmp_schoolMemberMasterChangeSeqNum =
                _result.getLong("schoolMemberMasterChangeSeqNum")
            val _tmp_schoolMemberLastChangedBy = _result.getInt("schoolMemberLastChangedBy")
            val _tmp_schoolMemberLct = _result.getLong("schoolMemberLct")
            var _tmp_Person_nullCount = 0
            val _tmp_personUid = _result.getLong("personUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_username = _result.getString("username")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_firstNames = _result.getString("firstNames")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_lastName = _result.getString("lastName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_emailAddr = _result.getString("emailAddr")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_phoneNum = _result.getString("phoneNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_gender = _result.getInt("gender")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_active = _result.getBoolean("active")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_admin = _result.getBoolean("admin")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personNotes = _result.getString("personNotes")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherName = _result.getString("fatherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_fatherNumber = _result.getString("fatherNumber")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherName = _result.getString("motherName")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_motherNum = _result.getString("motherNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personAddress = _result.getString("personAddress")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personOrgId = _result.getString("personOrgId")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personGroupUid = _result.getLong("personGroupUid")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personLct = _result.getLong("personLct")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personCountry = _result.getString("personCountry")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_personType = _result.getInt("personType")
            if(_result.wasNull()) _tmp_Person_nullCount++
            val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
            SchoolMemberWithPerson().apply {
              this.schoolMemberUid = _tmp_schoolMemberUid
              this.schoolMemberPersonUid = _tmp_schoolMemberPersonUid
              this.schoolMemberSchoolUid = _tmp_schoolMemberSchoolUid
              this.schoolMemberJoinDate = _tmp_schoolMemberJoinDate
              this.schoolMemberLeftDate = _tmp_schoolMemberLeftDate
              this.schoolMemberRole = _tmp_schoolMemberRole
              this.schoolMemberActive = _tmp_schoolMemberActive
              this.schoolMemberLocalChangeSeqNum = _tmp_schoolMemberLocalChangeSeqNum
              this.schoolMemberMasterChangeSeqNum = _tmp_schoolMemberMasterChangeSeqNum
              this.schoolMemberLastChangedBy = _tmp_schoolMemberLastChangedBy
              this.schoolMemberLct = _tmp_schoolMemberLct
              if(!_tmp_Person_isAllNull) {
                this.person = Person().apply {
                  this.personUid = _tmp_personUid
                  this.username = _tmp_username
                  this.firstNames = _tmp_firstNames
                  this.lastName = _tmp_lastName
                  this.emailAddr = _tmp_emailAddr
                  this.phoneNum = _tmp_phoneNum
                  this.gender = _tmp_gender
                  this.active = _tmp_active
                  this.admin = _tmp_admin
                  this.personNotes = _tmp_personNotes
                  this.fatherName = _tmp_fatherName
                  this.fatherNumber = _tmp_fatherNumber
                  this.motherName = _tmp_motherName
                  this.motherNum = _tmp_motherNum
                  this.dateOfBirth = _tmp_dateOfBirth
                  this.personAddress = _tmp_personAddress
                  this.personOrgId = _tmp_personOrgId
                  this.personGroupUid = _tmp_personGroupUid
                  this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                  this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                  this.personLastChangedBy = _tmp_personLastChangedBy
                  this.personLct = _tmp_personLct
                  this.personCountry = _tmp_personCountry
                  this.personType = _tmp_personType
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("PersonGroupMember",
        "ScopedGrant", "Person", "SchoolMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT SchoolMember.*, Person.*
      |          FROM PersonGroupMember
      |                
      |            JOIN ScopedGrant
      |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
      |                    AND (ScopedGrant.sgPermissions & 
      |                64 
      |                
      |                                                    ) > 0
      |            JOIN Person 
      |                 ON 
      |                ((ScopedGrant.sgTableId = -2
      |                    AND ScopedGrant.sgEntityUid = -2)
      |                 OR (ScopedGrant.sgTableId = 9
      |                    AND ScopedGrant.sgEntityUid = Person.personUid)
      |                 OR (ScopedGrant.sgTableId = 6       
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT clazzEnrolmentPersonUid
      |                          FROM ClazzEnrolment
      |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
      |                           AND ClazzEnrolment.clazzEnrolmentActive))
      |                 OR (ScopedGrant.sgTableId = 164
      |                    AND Person.personUid IN (
      |                        SELECT DISTINCT schoolMemberPersonUid
      |                          FROM SchoolMember
      |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
      |                           AND schoolMemberActive))
      |                           )    
      |        
      |        
      |          LEFT JOIN SchoolMember ON Person.personUid = SchoolMember.schoolMemberPersonUid 
      |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
      |         AND PersonGroupMember.groupMemberActive  
      |        AND SchoolMember.schoolMemberActive
      |        AND SchoolMember.schoolMemberSchoolUid = ? 
      |        AND SchoolMember.schoolMemberRole = ?
      |        AND Person.active
      |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
      |        GROUP BY Person.personUid, SchoolMember.schoolMemberUid
      |         ORDER BY CASE(?)
      |                WHEN 1 THEN Person.firstNames
      |                WHEN 3 THEN Person.lastName
      |                ELSE ''
      |            END ASC,
      |            CASE(?)
      |                WHEN 2 THEN Person.firstNames
      |                WHEN 4 THEN Person.lastName
      |                ELSE ''
      |            END DESC
      |            ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,accountPersonUid)
        _stmt.setLong(2,schoolUid)
        _stmt.setInt(3,role)
        _stmt.setString(4,searchQuery)
        _stmt.setInt(5,sortOrder)
        _stmt.setInt(6,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun findAllTest(
    schoolUid: Long,
    role: Int,
    searchQuery: String,
  ): List<SchoolMemberWithPerson> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT SchoolMember.*, Person.* FROM SchoolMember
  |        LEFT JOIN Person ON Person.personUid = SchoolMember.schoolMemberPersonUid
  |        WHERE CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1
  |        AND SchoolMember.schoolMemberSchoolUid = ? 
  |        AND SchoolMember.schoolMemberRole = ?
  |        AND CAST(Person.active AS INTEGER) = 1
  |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
  |        ORDER BY Person.firstNames DESC
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,schoolUid)
    _stmt.setInt(2,role)
    _stmt.setString(3,searchQuery)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_schoolMemberUid = _result.getLong("schoolMemberUid")
        val _tmp_schoolMemberPersonUid = _result.getLong("schoolMemberPersonUid")
        val _tmp_schoolMemberSchoolUid = _result.getLong("schoolMemberSchoolUid")
        val _tmp_schoolMemberJoinDate = _result.getLong("schoolMemberJoinDate")
        val _tmp_schoolMemberLeftDate = _result.getLong("schoolMemberLeftDate")
        val _tmp_schoolMemberRole = _result.getInt("schoolMemberRole")
        val _tmp_schoolMemberActive = _result.getBoolean("schoolMemberActive")
        val _tmp_schoolMemberLocalChangeSeqNum = _result.getLong("schoolMemberLocalChangeSeqNum")
        val _tmp_schoolMemberMasterChangeSeqNum = _result.getLong("schoolMemberMasterChangeSeqNum")
        val _tmp_schoolMemberLastChangedBy = _result.getInt("schoolMemberLastChangedBy")
        val _tmp_schoolMemberLct = _result.getLong("schoolMemberLct")
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        SchoolMemberWithPerson().apply {
          this.schoolMemberUid = _tmp_schoolMemberUid
          this.schoolMemberPersonUid = _tmp_schoolMemberPersonUid
          this.schoolMemberSchoolUid = _tmp_schoolMemberSchoolUid
          this.schoolMemberJoinDate = _tmp_schoolMemberJoinDate
          this.schoolMemberLeftDate = _tmp_schoolMemberLeftDate
          this.schoolMemberRole = _tmp_schoolMemberRole
          this.schoolMemberActive = _tmp_schoolMemberActive
          this.schoolMemberLocalChangeSeqNum = _tmp_schoolMemberLocalChangeSeqNum
          this.schoolMemberMasterChangeSeqNum = _tmp_schoolMemberMasterChangeSeqNum
          this.schoolMemberLastChangedBy = _tmp_schoolMemberLastChangedBy
          this.schoolMemberLct = _tmp_schoolMemberLct
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }
  }
}
