package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class VerbDao : BaseDao<VerbEntity> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByUrl(urlId: String?): VerbEntity?

  public actual abstract suspend fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract suspend fun replaceList(entityList: List<VerbEntity>): Unit

  public actual abstract fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay>

  public actual abstract fun findAllVerbsAsc(uidList: List<Long>):
      DataSourceFactory<Int, VerbDisplay>

  public actual abstract fun findAllVerbsDesc(uidList: List<Long>):
      DataSourceFactory<Int, VerbDisplay>
}
