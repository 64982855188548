package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.DiscussionTopic
import com.ustadmobile.lib.db.entities.DiscussionTopicListDetail
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class DiscussionTopicDao_JdbcKt(
  public val _db: RoomDatabase,
) : DiscussionTopicDao() {
  public val _insertAdapterDiscussionTopic_upsert: EntityInsertionAdapter<DiscussionTopic> = object
      : EntityInsertionAdapter<DiscussionTopic>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO DiscussionTopic (discussionTopicUid, discussionTopicTitle, discussionTopicDesc, discussionTopicStartDate, discussionTopicCourseDiscussionUid, discussionTopicVisible, discussionTopicArchive, discussionTopicIndex, discussionTopicClazzUid, discussionTopicLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: DiscussionTopic):
        Unit {
      if(entity.discussionTopicUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.discussionTopicUid)
      }
      stmt.setString(2, entity.discussionTopicTitle)
      stmt.setString(3, entity.discussionTopicDesc)
      stmt.setLong(4, entity.discussionTopicStartDate)
      stmt.setLong(5, entity.discussionTopicCourseDiscussionUid)
      stmt.setBoolean(6, entity.discussionTopicVisible)
      stmt.setBoolean(7, entity.discussionTopicArchive)
      stmt.setInt(8, entity.discussionTopicIndex)
      stmt.setLong(9, entity.discussionTopicClazzUid)
      stmt.setLong(10, entity.discussionTopicLct)
    }
  }

  public val _insertAdapterDiscussionTopic_: EntityInsertionAdapter<DiscussionTopic> = object :
      EntityInsertionAdapter<DiscussionTopic>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO DiscussionTopic (discussionTopicUid, discussionTopicTitle, discussionTopicDesc, discussionTopicStartDate, discussionTopicCourseDiscussionUid, discussionTopicVisible, discussionTopicArchive, discussionTopicIndex, discussionTopicClazzUid, discussionTopicLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: DiscussionTopic):
        Unit {
      if(entity.discussionTopicUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.discussionTopicUid)
      }
      stmt.setString(2, entity.discussionTopicTitle)
      stmt.setString(3, entity.discussionTopicDesc)
      stmt.setLong(4, entity.discussionTopicStartDate)
      stmt.setLong(5, entity.discussionTopicCourseDiscussionUid)
      stmt.setBoolean(6, entity.discussionTopicVisible)
      stmt.setBoolean(7, entity.discussionTopicArchive)
      stmt.setInt(8, entity.discussionTopicIndex)
      stmt.setLong(9, entity.discussionTopicClazzUid)
      stmt.setLong(10, entity.discussionTopicLct)
    }
  }

  public override suspend fun replaceListAsync(list: List<DiscussionTopic>): Unit {
    _insertAdapterDiscussionTopic_upsert.insertListAsync(list)
  }

  public override fun insert(entity: DiscussionTopic): Long {
    val _retVal = _insertAdapterDiscussionTopic_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: DiscussionTopic): Long {
    val _retVal = _insertAdapterDiscussionTopic_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<DiscussionTopic>): Unit {
    _insertAdapterDiscussionTopic_.insertList(entityList)
  }

  public override suspend fun insertListAsync(entityList: List<DiscussionTopic>): Unit {
    _insertAdapterDiscussionTopic_.insertListAsync(entityList)
  }

  public override fun updateList(entityList: List<DiscussionTopic>): Unit {
    val _sql =
        "UPDATE DiscussionTopic SET discussionTopicTitle = ?, discussionTopicDesc = ?, discussionTopicStartDate = ?, discussionTopicCourseDiscussionUid = ?, discussionTopicVisible = ?, discussionTopicArchive = ?, discussionTopicIndex = ?, discussionTopicClazzUid = ?, discussionTopicLct = ? WHERE discussionTopicUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.discussionTopicTitle)
        _stmt.setString(2, _entity.discussionTopicDesc)
        _stmt.setLong(3, _entity.discussionTopicStartDate)
        _stmt.setLong(4, _entity.discussionTopicCourseDiscussionUid)
        _stmt.setBoolean(5, _entity.discussionTopicVisible)
        _stmt.setBoolean(6, _entity.discussionTopicArchive)
        _stmt.setInt(7, _entity.discussionTopicIndex)
        _stmt.setLong(8, _entity.discussionTopicClazzUid)
        _stmt.setLong(9, _entity.discussionTopicLct)
        _stmt.setLong(10, _entity.discussionTopicUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: DiscussionTopic): Unit {
    val _sql =
        "UPDATE DiscussionTopic SET discussionTopicTitle = ?, discussionTopicDesc = ?, discussionTopicStartDate = ?, discussionTopicCourseDiscussionUid = ?, discussionTopicVisible = ?, discussionTopicArchive = ?, discussionTopicIndex = ?, discussionTopicClazzUid = ?, discussionTopicLct = ? WHERE discussionTopicUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.discussionTopicTitle)
      _stmt.setString(2, entity.discussionTopicDesc)
      _stmt.setLong(3, entity.discussionTopicStartDate)
      _stmt.setLong(4, entity.discussionTopicCourseDiscussionUid)
      _stmt.setBoolean(5, entity.discussionTopicVisible)
      _stmt.setBoolean(6, entity.discussionTopicArchive)
      _stmt.setInt(7, entity.discussionTopicIndex)
      _stmt.setLong(8, entity.discussionTopicClazzUid)
      _stmt.setLong(9, entity.discussionTopicLct)
      _stmt.setLong(10, entity.discussionTopicUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateListAsync(entityList: List<DiscussionTopic>): Unit {
    val _sql =
        "UPDATE DiscussionTopic SET discussionTopicTitle = ?, discussionTopicDesc = ?, discussionTopicStartDate = ?, discussionTopicCourseDiscussionUid = ?, discussionTopicVisible = ?, discussionTopicArchive = ?, discussionTopicIndex = ?, discussionTopicClazzUid = ?, discussionTopicLct = ? WHERE discussionTopicUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.discussionTopicTitle)
        _stmt.setString(2, _entity.discussionTopicDesc)
        _stmt.setLong(3, _entity.discussionTopicStartDate)
        _stmt.setLong(4, _entity.discussionTopicCourseDiscussionUid)
        _stmt.setBoolean(5, _entity.discussionTopicVisible)
        _stmt.setBoolean(6, _entity.discussionTopicArchive)
        _stmt.setInt(7, _entity.discussionTopicIndex)
        _stmt.setLong(8, _entity.discussionTopicClazzUid)
        _stmt.setLong(9, _entity.discussionTopicLct)
        _stmt.setLong(10, _entity.discussionTopicUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |      SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicPk,
    |             ? AS discussionTopicDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN DiscussionTopic 
    |                  ON DiscussionTopic.discussionTopicClazzUid = Clazz.clazzUid
    |                  
    |       WHERE DiscussionTopic.discussionTopicLct != COALESCE(
    |             (SELECT discussionTopicVersionId
    |                FROM discussionTopicReplicate
    |               WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                 AND discussionTopicDestination = ?), 0) 
    |      /*psql ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |      SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicPk,
    |             ? AS discussionTopicDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN DiscussionTopic 
    |                  ON DiscussionTopic.discussionTopicClazzUid = Clazz.clazzUid
    |                  
    |       WHERE DiscussionTopic.discussionTopicLct != COALESCE(
    |             (SELECT discussionTopicVersionId
    |                FROM discussionTopicReplicate
    |               WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                 AND discussionTopicDestination = ?), 0) 
    |       ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |          SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicUid,
    |                 UserSession.usClientNodeId AS discussionTopicDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionTopic
    |                     ON ChangeLog.chTableId = 131
    |                        AND ChangeLog.chEntityPk = DiscussionTopic.discussionTopicUid
    |                        
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = DiscussionTopic.discussionTopicClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionTopic.discussionTopicLct != COALESCE(
    |                 (SELECT discussionTopicVersionId
    |                    FROM discussionTopicReplicate
    |                   WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                     AND DiscussionTopicDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |          SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicUid,
    |                 UserSession.usClientNodeId AS discussionTopicDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionTopic
    |                     ON ChangeLog.chTableId = 131
    |                        AND ChangeLog.chEntityPk = DiscussionTopic.discussionTopicUid
    |                        
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = DiscussionTopic.discussionTopicClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionTopic.discussionTopicLct != COALESCE(
    |                 (SELECT discussionTopicVersionId
    |                    FROM discussionTopicReplicate
    |                   WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                     AND DiscussionTopicDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getListOfTopicsByDiscussion(discussionUid: Long):
      DataSourceFactory<Int, DiscussionTopicListDetail> = object :
      DataSourceFactory<Int, DiscussionTopicListDetail>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<DiscussionTopicListDetail>> = LiveDataImpl(_db, listOf("DiscussionPost",
        "DiscussionTopic"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT DiscussionTopic.*,
      |                ( 
      |                    SELECT COUNT(*) 
      |                      FROM DiscussionPost 
      |                     WHERE DiscussionPost.discussionPostDiscussionTopicUid = DiscussionTopic.discussionTopicUid
      |                ) as numPosts,
      |                (
      |                    0
      |                )as lastActiveTimestamp
      |          FROM DiscussionTopic     
      |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid = ? 
      |           AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
      |           AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER) = 0
      |      ORDER BY DiscussionTopic.discussionTopicIndex
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,discussionUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_numPosts = _result.getInt("numPosts")
            val _tmp_lastActiveTimestamp = _result.getLong("lastActiveTimestamp")
            val _tmp_discussionTopicUid = _result.getLong("discussionTopicUid")
            val _tmp_discussionTopicTitle = _result.getString("discussionTopicTitle")
            val _tmp_discussionTopicDesc = _result.getString("discussionTopicDesc")
            val _tmp_discussionTopicStartDate = _result.getLong("discussionTopicStartDate")
            val _tmp_discussionTopicCourseDiscussionUid =
                _result.getLong("discussionTopicCourseDiscussionUid")
            val _tmp_discussionTopicVisible = _result.getBoolean("discussionTopicVisible")
            val _tmp_discussionTopicArchive = _result.getBoolean("discussionTopicArchive")
            val _tmp_discussionTopicIndex = _result.getInt("discussionTopicIndex")
            val _tmp_discussionTopicClazzUid = _result.getLong("discussionTopicClazzUid")
            val _tmp_discussionTopicLct = _result.getLong("discussionTopicLct")
            DiscussionTopicListDetail().apply {
              this.numPosts = _tmp_numPosts
              this.lastActiveTimestamp = _tmp_lastActiveTimestamp
              this.discussionTopicUid = _tmp_discussionTopicUid
              this.discussionTopicTitle = _tmp_discussionTopicTitle
              this.discussionTopicDesc = _tmp_discussionTopicDesc
              this.discussionTopicStartDate = _tmp_discussionTopicStartDate
              this.discussionTopicCourseDiscussionUid = _tmp_discussionTopicCourseDiscussionUid
              this.discussionTopicVisible = _tmp_discussionTopicVisible
              this.discussionTopicArchive = _tmp_discussionTopicArchive
              this.discussionTopicIndex = _tmp_discussionTopicIndex
              this.discussionTopicClazzUid = _tmp_discussionTopicClazzUid
              this.discussionTopicLct = _tmp_discussionTopicLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("DiscussionPost",
        "DiscussionTopic"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT DiscussionTopic.*,
      |                ( 
      |                    SELECT COUNT(*) 
      |                      FROM DiscussionPost 
      |                     WHERE DiscussionPost.discussionPostDiscussionTopicUid = DiscussionTopic.discussionTopicUid
      |                ) as numPosts,
      |                (
      |                    0
      |                )as lastActiveTimestamp
      |          FROM DiscussionTopic     
      |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid = ? 
      |           AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
      |           AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER) = 0
      |      ORDER BY DiscussionTopic.discussionTopicIndex
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,discussionUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun getTopicsByClazz(clazzUid: Long): List<DiscussionTopic> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT DiscussionTopic.*
  |          FROM DiscussionTopic
  |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid IN 
  |                (SELECT CourseDiscussion.courseDiscussionUid 
  |                   FROM CourseDiscussion
  |                  WHERE CourseDiscussion.courseDiscussionClazzUid = ? ) 
  |          AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
  |          AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER)  = 0
  |                        
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_discussionTopicUid = _result.getLong("discussionTopicUid")
        val _tmp_discussionTopicTitle = _result.getString("discussionTopicTitle")
        val _tmp_discussionTopicDesc = _result.getString("discussionTopicDesc")
        val _tmp_discussionTopicStartDate = _result.getLong("discussionTopicStartDate")
        val _tmp_discussionTopicCourseDiscussionUid =
            _result.getLong("discussionTopicCourseDiscussionUid")
        val _tmp_discussionTopicVisible = _result.getBoolean("discussionTopicVisible")
        val _tmp_discussionTopicArchive = _result.getBoolean("discussionTopicArchive")
        val _tmp_discussionTopicIndex = _result.getInt("discussionTopicIndex")
        val _tmp_discussionTopicClazzUid = _result.getLong("discussionTopicClazzUid")
        val _tmp_discussionTopicLct = _result.getLong("discussionTopicLct")
        DiscussionTopic().apply {
          this.discussionTopicUid = _tmp_discussionTopicUid
          this.discussionTopicTitle = _tmp_discussionTopicTitle
          this.discussionTopicDesc = _tmp_discussionTopicDesc
          this.discussionTopicStartDate = _tmp_discussionTopicStartDate
          this.discussionTopicCourseDiscussionUid = _tmp_discussionTopicCourseDiscussionUid
          this.discussionTopicVisible = _tmp_discussionTopicVisible
          this.discussionTopicArchive = _tmp_discussionTopicArchive
          this.discussionTopicIndex = _tmp_discussionTopicIndex
          this.discussionTopicClazzUid = _tmp_discussionTopicClazzUid
          this.discussionTopicLct = _tmp_discussionTopicLct
        }
      }
    }
  }

  public override fun getDiscussionTopicByUid(discussionTopicUid: Long): LiveData<DiscussionTopic?>
      = LiveDataImpl(_db, listOf("DiscussionTopic"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT DiscussionTopic.*
    |          FROM DiscussionTopic
    |         WHERE DiscussionTopic.discussionTopicUid = ?
    |         
    |         
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,discussionTopicUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_discussionTopicUid = _result.getLong("discussionTopicUid")
          val _tmp_discussionTopicTitle = _result.getString("discussionTopicTitle")
          val _tmp_discussionTopicDesc = _result.getString("discussionTopicDesc")
          val _tmp_discussionTopicStartDate = _result.getLong("discussionTopicStartDate")
          val _tmp_discussionTopicCourseDiscussionUid =
              _result.getLong("discussionTopicCourseDiscussionUid")
          val _tmp_discussionTopicVisible = _result.getBoolean("discussionTopicVisible")
          val _tmp_discussionTopicArchive = _result.getBoolean("discussionTopicArchive")
          val _tmp_discussionTopicIndex = _result.getInt("discussionTopicIndex")
          val _tmp_discussionTopicClazzUid = _result.getLong("discussionTopicClazzUid")
          val _tmp_discussionTopicLct = _result.getLong("discussionTopicLct")
          DiscussionTopic().apply {
            this.discussionTopicUid = _tmp_discussionTopicUid
            this.discussionTopicTitle = _tmp_discussionTopicTitle
            this.discussionTopicDesc = _tmp_discussionTopicDesc
            this.discussionTopicStartDate = _tmp_discussionTopicStartDate
            this.discussionTopicCourseDiscussionUid = _tmp_discussionTopicCourseDiscussionUid
            this.discussionTopicVisible = _tmp_discussionTopicVisible
            this.discussionTopicArchive = _tmp_discussionTopicArchive
            this.discussionTopicIndex = _tmp_discussionTopicIndex
            this.discussionTopicClazzUid = _tmp_discussionTopicClazzUid
            this.discussionTopicLct = _tmp_discussionTopicLct
          }
        }
      }
    }
  }

  public override suspend fun updateActiveByUid(
    uid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE DiscussionTopic 
    |           SET discussionTopicVisible = ?, 
    |               discussionTopicLct = ?
    |         WHERE discussionTopicUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,uid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
