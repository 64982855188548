package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithCourseBlock
import com.ustadmobile.lib.db.entities.PersonGroupAssignmentSummary
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzAssignmentDao : BaseDao<ClazzAssignment>,
    OneToManyJoinDao<ClazzAssignment> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun getAllAssignmentsByClazzUidAsync(clazzUid: Long):
      List<ClazzAssignment>

  public actual abstract suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit

  public actual abstract fun getProgressSummaryForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
  ): LiveData<AssignmentProgressSummary?>

  public actual abstract fun getSubmitterListForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
    searchText: String,
  ): DataSourceFactory<Int, PersonGroupAssignmentSummary>

  public actual abstract suspend fun getSubmitterUid(assignmentUid: Long, personUid: Long): Long

  public actual abstract suspend fun updateAsync(clazzAssignment: ClazzAssignment): Unit

  public actual abstract suspend fun findByUidAsync(uid: Long): ClazzAssignment?

  public actual abstract suspend fun getGroupUidFromAssignment(uid: Long): Long

  public actual abstract suspend fun findByUidWithBlockAsync(uid: Long):
      ClazzAssignmentWithCourseBlock?

  public actual abstract fun findClazzAssignment(): ClazzAssignment?

  public actual abstract fun findByUidLive(uid: Long): LiveData<ClazzAssignment?>
}
