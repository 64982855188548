package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryContentCategoryJoinDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentEntryContentCategoryJoinDao,
) : ContentEntryContentCategoryJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun publicContentEntryContentCategoryJoins():
      List<ContentEntryContentCategoryJoin> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findJoinByParentChildUuids(categoryUid: Long, contentEntry: Long):
      ContentEntryContentCategoryJoin? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: ContentEntryContentCategoryJoin): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentEntryContentCategoryJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.ceccjUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(3)
      entity.ceccjUid = _newPk
    }
    entity.ceccjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.ceccjUid
  }

  public override fun insertList(entityList: List<ContentEntryContentCategoryJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ContentEntryContentCategoryJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContentEntryContentCategoryJoin): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
