package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentCategorySchema
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentCategorySchemaDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentCategorySchemaDao,
) : ContentCategorySchemaDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun publicContentCategorySchemas(): List<ContentCategorySchema> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findBySchemaUrl(schemaUrl: String): ContentCategorySchema? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: ContentCategorySchema): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentCategorySchema): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.contentCategorySchemaUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(2)
      entity.contentCategorySchemaUid = _newPk
    }
    entity.contentCategorySchemaLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.contentCategorySchemaUid
  }

  public override fun insertList(entityList: List<ContentCategorySchema>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ContentCategorySchema>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContentCategorySchema): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
