package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContentEntryContentCategoryJoinDao :
    BaseDao<ContentEntryContentCategoryJoin> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun publicContentEntryContentCategoryJoins():
      List<ContentEntryContentCategoryJoin>

  public actual abstract fun findJoinByParentChildUuids(categoryUid: Long, contentEntry: Long):
      ContentEntryContentCategoryJoin?
}
