package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Message
import com.ustadmobile.lib.db.entities.MessageWithPerson
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class MessageDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: MessageDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : MessageDao() {
  public override suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeChats(newNodeId)
  }

  public override suspend fun replicateOnNewNodePosts(newNodeId: Long): Unit {
    _dao.replicateOnNewNodePosts(newNodeId)
  }

  public override suspend fun replicateOnChangeChat(): Unit {
    _dao.replicateOnChangeChat()
  }

  public override suspend fun replicateOnChangePosts(): Unit {
    _dao.replicateOnChangePosts()
  }

  public override fun findAllMessagesByChatUid(
    entityUid: Long,
    tableId: Int,
    loggedInPersonUid: Long,
  ): DataSourceFactory<Int, MessageWithPerson> {
    val _result = _dao.findAllMessagesByChatUid(entityUid, tableId, loggedInPersonUid)
    return _result
  }

  public override fun insert(entity: Message): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Message): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<Message>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<Message>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Message): Unit {
    _dao.update(entity)
  }
}
