package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.PersonAuth2
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class PersonAuth2Dao {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(auths: List<PersonAuth2>): Unit

  public actual abstract suspend fun insertAsync(auth: PersonAuth2): Long

  public actual abstract suspend fun findByPersonUid(personUid: Long): PersonAuth2?

  public actual abstract suspend fun findByUsername(username: String): PersonAuth2?
}
