package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.LearnerGroupMember
import com.ustadmobile.lib.db.entities.LearnerGroupMemberWithPerson
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class LearnerGroupMemberDao : BaseDao<LearnerGroupMember> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findLearnerGroupMembersByGroupIdAndEntry(learnerGroupUid: Long,
      contentEntryUid: Long): DataSourceFactory<Int, LearnerGroupMemberWithPerson>

  public actual abstract suspend
      fun findLearnerGroupMembersByGroupIdAndEntryList(learnerGroupUid: Long,
      contentEntryUid: Long): List<LearnerGroupMemberWithPerson>
}
