package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.Chat
import com.ustadmobile.lib.db.entities.ChatWithLatestMessageAndCount
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class ChatDao : BaseDao<Chat> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findAllChatsForUser(searchBit: String, personUid: Long):
      DataSourceFactory<Int, ChatWithLatestMessageAndCount>

  public actual abstract suspend fun getTitleChat(chatUid: Long, personUid: Long): String?

  public actual abstract suspend fun getChatByOtherPerson(otherPersonUid: Long,
      loggedInPersonUid: Long): Chat?
}
