package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.hasListOrArrayParams
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Report
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ReportDao_JdbcKt(
  public val _db: RoomDatabase,
) : ReportDao() {
  public val _insertAdapterReport_upsert: EntityInsertionAdapter<Report> = object :
      EntityInsertionAdapter<Report>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Report): Unit {
      if(entity.reportUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.reportUid)
      }
      stmt.setLong(2, entity.reportOwnerUid)
      stmt.setInt(3, entity.xAxis)
      stmt.setInt(4, entity.reportDateRangeSelection)
      stmt.setLong(5, entity.fromDate)
      stmt.setInt(6, entity.fromRelTo)
      stmt.setInt(7, entity.fromRelOffSet)
      stmt.setInt(8, entity.fromRelUnit)
      stmt.setLong(9, entity.toDate)
      stmt.setInt(10, entity.toRelTo)
      stmt.setInt(11, entity.toRelOffSet)
      stmt.setInt(12, entity.toRelUnit)
      stmt.setString(13, entity.reportTitle)
      stmt.setString(14, entity.reportDescription)
      stmt.setString(15, entity.reportSeries)
      stmt.setBoolean(16, entity.reportInactive)
      stmt.setBoolean(17, entity.isTemplate)
      stmt.setInt(18, entity.priority)
      stmt.setInt(19, entity.reportTitleId)
      stmt.setInt(20, entity.reportDescId)
      stmt.setLong(21, entity.reportMasterChangeSeqNum)
      stmt.setLong(22, entity.reportLocalChangeSeqNum)
      stmt.setInt(23, entity.reportLastChangedBy)
      stmt.setLong(24, entity.reportLct)
    }
  }

  public val _insertAdapterReport_: EntityInsertionAdapter<Report> = object :
      EntityInsertionAdapter<Report>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Report): Unit {
      if(entity.reportUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.reportUid)
      }
      stmt.setLong(2, entity.reportOwnerUid)
      stmt.setInt(3, entity.xAxis)
      stmt.setInt(4, entity.reportDateRangeSelection)
      stmt.setLong(5, entity.fromDate)
      stmt.setInt(6, entity.fromRelTo)
      stmt.setInt(7, entity.fromRelOffSet)
      stmt.setInt(8, entity.fromRelUnit)
      stmt.setLong(9, entity.toDate)
      stmt.setInt(10, entity.toRelTo)
      stmt.setInt(11, entity.toRelOffSet)
      stmt.setInt(12, entity.toRelUnit)
      stmt.setString(13, entity.reportTitle)
      stmt.setString(14, entity.reportDescription)
      stmt.setString(15, entity.reportSeries)
      stmt.setBoolean(16, entity.reportInactive)
      stmt.setBoolean(17, entity.isTemplate)
      stmt.setInt(18, entity.priority)
      stmt.setInt(19, entity.reportTitleId)
      stmt.setInt(20, entity.reportDescId)
      stmt.setLong(21, entity.reportMasterChangeSeqNum)
      stmt.setLong(22, entity.reportLocalChangeSeqNum)
      stmt.setInt(23, entity.reportLastChangedBy)
      stmt.setLong(24, entity.reportLct)
    }
  }

  public override fun replaceList(entityList: List<Report>): Unit {
    _insertAdapterReport_upsert.insertList(entityList)
  }

  public override fun insert(entity: Report): Long {
    val _retVal = _insertAdapterReport_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Report): Long {
    val _retVal = _insertAdapterReport_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Report>): Unit {
    _insertAdapterReport_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: Report): Unit {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.reportOwnerUid)
      _stmt.setInt(2, entity.xAxis)
      _stmt.setInt(3, entity.reportDateRangeSelection)
      _stmt.setLong(4, entity.fromDate)
      _stmt.setInt(5, entity.fromRelTo)
      _stmt.setInt(6, entity.fromRelOffSet)
      _stmt.setInt(7, entity.fromRelUnit)
      _stmt.setLong(8, entity.toDate)
      _stmt.setInt(9, entity.toRelTo)
      _stmt.setInt(10, entity.toRelOffSet)
      _stmt.setInt(11, entity.toRelUnit)
      _stmt.setString(12, entity.reportTitle)
      _stmt.setString(13, entity.reportDescription)
      _stmt.setString(14, entity.reportSeries)
      _stmt.setBoolean(15, entity.reportInactive)
      _stmt.setBoolean(16, entity.isTemplate)
      _stmt.setInt(17, entity.priority)
      _stmt.setInt(18, entity.reportTitleId)
      _stmt.setInt(19, entity.reportDescId)
      _stmt.setLong(20, entity.reportMasterChangeSeqNum)
      _stmt.setLong(21, entity.reportLocalChangeSeqNum)
      _stmt.setInt(22, entity.reportLastChangedBy)
      _stmt.setLong(23, entity.reportLct)
      _stmt.setLong(24, entity.reportUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateList(entityList: List<Report>): Unit {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.reportOwnerUid)
        _stmt.setInt(2, _entity.xAxis)
        _stmt.setInt(3, _entity.reportDateRangeSelection)
        _stmt.setLong(4, _entity.fromDate)
        _stmt.setInt(5, _entity.fromRelTo)
        _stmt.setInt(6, _entity.fromRelOffSet)
        _stmt.setInt(7, _entity.fromRelUnit)
        _stmt.setLong(8, _entity.toDate)
        _stmt.setInt(9, _entity.toRelTo)
        _stmt.setInt(10, _entity.toRelOffSet)
        _stmt.setInt(11, _entity.toRelUnit)
        _stmt.setString(12, _entity.reportTitle)
        _stmt.setString(13, _entity.reportDescription)
        _stmt.setString(14, _entity.reportSeries)
        _stmt.setBoolean(15, _entity.reportInactive)
        _stmt.setBoolean(16, _entity.isTemplate)
        _stmt.setInt(17, _entity.priority)
        _stmt.setInt(18, _entity.reportTitleId)
        _stmt.setInt(19, _entity.reportDescId)
        _stmt.setLong(20, _entity.reportMasterChangeSeqNum)
        _stmt.setLong(21, _entity.reportLocalChangeSeqNum)
        _stmt.setInt(22, _entity.reportLastChangedBy)
        _stmt.setLong(23, _entity.reportLct)
        _stmt.setLong(24, _entity.reportUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Report): Unit {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.reportOwnerUid)
      _stmt.setInt(2, entity.xAxis)
      _stmt.setInt(3, entity.reportDateRangeSelection)
      _stmt.setLong(4, entity.fromDate)
      _stmt.setInt(5, entity.fromRelTo)
      _stmt.setInt(6, entity.fromRelOffSet)
      _stmt.setInt(7, entity.fromRelUnit)
      _stmt.setLong(8, entity.toDate)
      _stmt.setInt(9, entity.toRelTo)
      _stmt.setInt(10, entity.toRelOffSet)
      _stmt.setInt(11, entity.toRelUnit)
      _stmt.setString(12, entity.reportTitle)
      _stmt.setString(13, entity.reportDescription)
      _stmt.setString(14, entity.reportSeries)
      _stmt.setBoolean(15, entity.reportInactive)
      _stmt.setBoolean(16, entity.isTemplate)
      _stmt.setInt(17, entity.priority)
      _stmt.setInt(18, entity.reportTitleId)
      _stmt.setInt(19, entity.reportDescId)
      _stmt.setLong(20, entity.reportMasterChangeSeqNum)
      _stmt.setLong(21, entity.reportLocalChangeSeqNum)
      _stmt.setInt(22, entity.reportLastChangedBy)
      _stmt.setLong(23, entity.reportLct)
      _stmt.setLong(24, entity.reportUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNodeTemplates(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ReportReplicate(reportPk, reportDestination)
    |      SELECT DISTINCT Report.reportUid AS reportPk,
    |             ? AS reportDestination
    |        FROM Report
    |             JOIN UserSession
    |                  ON UserSession.usStatus = 1
    |                     AND CAST(Report.isTemplate AS INTEGER) = 1
    |       WHERE Report.reportLct != COALESCE(
    |             (SELECT reportVersionId
    |                FROM ReportReplicate
    |               WHERE reportPk = Report.reportUid
    |                 AND reportDestination = ?), 0) 
    |      /*psql ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |             SET reportPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ReportReplicate(reportPk, reportDestination)
    |      SELECT DISTINCT Report.reportUid AS reportPk,
    |             ? AS reportDestination
    |        FROM Report
    |             JOIN UserSession
    |                  ON UserSession.usStatus = 1
    |                     AND CAST(Report.isTemplate AS INTEGER) = 1
    |       WHERE Report.reportLct != COALESCE(
    |             (SELECT reportVersionId
    |                FROM ReportReplicate
    |               WHERE reportPk = Report.reportUid
    |                 AND reportDestination = ?), 0) 
    |       ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |             SET reportPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeTemplates(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ReportReplicate(reportPk, reportDestination)
    |  SELECT DISTINCT Report.reportUid AS reportUid,
    |         UserSession.usClientNodeId AS reportDestination
    |    FROM ChangeLog
    |         JOIN Report
    |              ON ChangeLog.chTableId = 101 
    |                 AND ChangeLog.chEntityPk = Report.reportUid
    |         JOIN UserSession
    |              ON UserSession.usStatus = 1
    |                 AND CAST(Report.isTemplate AS INTEGER) = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Report.reportLct != COALESCE(
    |         (SELECT reportVersionId
    |            FROM ReportReplicate
    |           WHERE reportPk = Report.reportUid
    |             AND reportDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |     SET reportPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ReportReplicate(reportPk, reportDestination)
    |  SELECT DISTINCT Report.reportUid AS reportUid,
    |         UserSession.usClientNodeId AS reportDestination
    |    FROM ChangeLog
    |         JOIN Report
    |              ON ChangeLog.chTableId = 101 
    |                 AND ChangeLog.chEntityPk = Report.reportUid
    |         JOIN UserSession
    |              ON UserSession.usStatus = 1
    |                 AND CAST(Report.isTemplate AS INTEGER) = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Report.reportLct != COALESCE(
    |         (SELECT reportVersionId
    |            FROM ReportReplicate
    |           WHERE reportPk = Report.reportUid
    |             AND reportDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |     SET reportPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getResults(query: DoorQuery): List<Report> =
      _db.prepareAndUseStatement(PreparedStatementConfig(query.getSql(), hasListParams =
      query.hasListOrArrayParams())
  ) { _stmt -> 
    query.bindToPreparedStmt(_stmt, _db)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_reportUid = _result.getLong("reportUid")
        val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
        val _tmp_xAxis = _result.getInt("xAxis")
        val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
        val _tmp_fromDate = _result.getLong("fromDate")
        val _tmp_fromRelTo = _result.getInt("fromRelTo")
        val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
        val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
        val _tmp_toDate = _result.getLong("toDate")
        val _tmp_toRelTo = _result.getInt("toRelTo")
        val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
        val _tmp_toRelUnit = _result.getInt("toRelUnit")
        val _tmp_reportTitle = _result.getString("reportTitle")
        val _tmp_reportDescription = _result.getString("reportDescription")
        val _tmp_reportSeries = _result.getString("reportSeries")
        val _tmp_reportInactive = _result.getBoolean("reportInactive")
        val _tmp_isTemplate = _result.getBoolean("isTemplate")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_reportTitleId = _result.getInt("reportTitleId")
        val _tmp_reportDescId = _result.getInt("reportDescId")
        val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
        val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
        val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
        val _tmp_reportLct = _result.getLong("reportLct")
        Report().apply {
          this.reportUid = _tmp_reportUid
          this.reportOwnerUid = _tmp_reportOwnerUid
          this.xAxis = _tmp_xAxis
          this.reportDateRangeSelection = _tmp_reportDateRangeSelection
          this.fromDate = _tmp_fromDate
          this.fromRelTo = _tmp_fromRelTo
          this.fromRelOffSet = _tmp_fromRelOffSet
          this.fromRelUnit = _tmp_fromRelUnit
          this.toDate = _tmp_toDate
          this.toRelTo = _tmp_toRelTo
          this.toRelOffSet = _tmp_toRelOffSet
          this.toRelUnit = _tmp_toRelUnit
          this.reportTitle = _tmp_reportTitle
          this.reportDescription = _tmp_reportDescription
          this.reportSeries = _tmp_reportSeries
          this.reportInactive = _tmp_reportInactive
          this.isTemplate = _tmp_isTemplate
          this.priority = _tmp_priority
          this.reportTitleId = _tmp_reportTitleId
          this.reportDescId = _tmp_reportDescId
          this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
          this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
          this.reportLastChangedBy = _tmp_reportLastChangedBy
          this.reportLct = _tmp_reportLct
        }
      }
    }
  }

  public override fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): DataSourceFactory<Int, Report> = object : DataSourceFactory<Int, Report>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<Report>> =
        LiveDataImpl(_db, listOf("REPORT"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND reportOwnerUid = ?
      |        AND isTemplate = ?
      |        AND reportTitle LIKE ?
      |        ORDER BY priority, CASE(?)
      |            WHEN 1 THEN Report.reportTitle
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Report.reportTitle
      |            ELSE ''
      |        END DESC
      |            ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setBoolean(2,isTemplate)
        _stmt.setString(3,searchBit)
        _stmt.setInt(4,sortOrder)
        _stmt.setInt(5,sortOrder)
        _stmt.setInt(6,_limit)
        _stmt.setInt(7,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_reportUid = _result.getLong("reportUid")
            val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
            val _tmp_xAxis = _result.getInt("xAxis")
            val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
            val _tmp_fromDate = _result.getLong("fromDate")
            val _tmp_fromRelTo = _result.getInt("fromRelTo")
            val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
            val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
            val _tmp_toDate = _result.getLong("toDate")
            val _tmp_toRelTo = _result.getInt("toRelTo")
            val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
            val _tmp_toRelUnit = _result.getInt("toRelUnit")
            val _tmp_reportTitle = _result.getString("reportTitle")
            val _tmp_reportDescription = _result.getString("reportDescription")
            val _tmp_reportSeries = _result.getString("reportSeries")
            val _tmp_reportInactive = _result.getBoolean("reportInactive")
            val _tmp_isTemplate = _result.getBoolean("isTemplate")
            val _tmp_priority = _result.getInt("priority")
            val _tmp_reportTitleId = _result.getInt("reportTitleId")
            val _tmp_reportDescId = _result.getInt("reportDescId")
            val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
            val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
            val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
            val _tmp_reportLct = _result.getLong("reportLct")
            Report().apply {
              this.reportUid = _tmp_reportUid
              this.reportOwnerUid = _tmp_reportOwnerUid
              this.xAxis = _tmp_xAxis
              this.reportDateRangeSelection = _tmp_reportDateRangeSelection
              this.fromDate = _tmp_fromDate
              this.fromRelTo = _tmp_fromRelTo
              this.fromRelOffSet = _tmp_fromRelOffSet
              this.fromRelUnit = _tmp_fromRelUnit
              this.toDate = _tmp_toDate
              this.toRelTo = _tmp_toRelTo
              this.toRelOffSet = _tmp_toRelOffSet
              this.toRelUnit = _tmp_toRelUnit
              this.reportTitle = _tmp_reportTitle
              this.reportDescription = _tmp_reportDescription
              this.reportSeries = _tmp_reportSeries
              this.reportInactive = _tmp_reportInactive
              this.isTemplate = _tmp_isTemplate
              this.priority = _tmp_priority
              this.reportTitleId = _tmp_reportTitleId
              this.reportDescId = _tmp_reportDescId
              this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
              this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
              this.reportLastChangedBy = _tmp_reportLastChangedBy
              this.reportLct = _tmp_reportLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("REPORT"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND reportOwnerUid = ?
      |        AND isTemplate = ?
      |        AND reportTitle LIKE ?
      |        ORDER BY priority, CASE(?)
      |            WHEN 1 THEN Report.reportTitle
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Report.reportTitle
      |            ELSE ''
      |        END DESC
      |            ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setBoolean(2,isTemplate)
        _stmt.setString(3,searchBit)
        _stmt.setInt(4,sortOrder)
        _stmt.setInt(5,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun findByUid(entityUid: Long): Report? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM Report WHERE reportUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,entityUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_reportUid = _result.getLong("reportUid")
        val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
        val _tmp_xAxis = _result.getInt("xAxis")
        val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
        val _tmp_fromDate = _result.getLong("fromDate")
        val _tmp_fromRelTo = _result.getInt("fromRelTo")
        val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
        val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
        val _tmp_toDate = _result.getLong("toDate")
        val _tmp_toRelTo = _result.getInt("toRelTo")
        val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
        val _tmp_toRelUnit = _result.getInt("toRelUnit")
        val _tmp_reportTitle = _result.getString("reportTitle")
        val _tmp_reportDescription = _result.getString("reportDescription")
        val _tmp_reportSeries = _result.getString("reportSeries")
        val _tmp_reportInactive = _result.getBoolean("reportInactive")
        val _tmp_isTemplate = _result.getBoolean("isTemplate")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_reportTitleId = _result.getInt("reportTitleId")
        val _tmp_reportDescId = _result.getInt("reportDescId")
        val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
        val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
        val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
        val _tmp_reportLct = _result.getLong("reportLct")
        Report().apply {
          this.reportUid = _tmp_reportUid
          this.reportOwnerUid = _tmp_reportOwnerUid
          this.xAxis = _tmp_xAxis
          this.reportDateRangeSelection = _tmp_reportDateRangeSelection
          this.fromDate = _tmp_fromDate
          this.fromRelTo = _tmp_fromRelTo
          this.fromRelOffSet = _tmp_fromRelOffSet
          this.fromRelUnit = _tmp_fromRelUnit
          this.toDate = _tmp_toDate
          this.toRelTo = _tmp_toRelTo
          this.toRelOffSet = _tmp_toRelOffSet
          this.toRelUnit = _tmp_toRelUnit
          this.reportTitle = _tmp_reportTitle
          this.reportDescription = _tmp_reportDescription
          this.reportSeries = _tmp_reportSeries
          this.reportInactive = _tmp_reportInactive
          this.isTemplate = _tmp_isTemplate
          this.priority = _tmp_priority
          this.reportTitleId = _tmp_reportTitleId
          this.reportDescId = _tmp_reportDescId
          this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
          this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
          this.reportLastChangedBy = _tmp_reportLastChangedBy
          this.reportLct = _tmp_reportLct
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<Report?> = LiveDataImpl(_db,
      listOf("Report"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * From Report WHERE  reportUid = ?"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_reportUid = _result.getLong("reportUid")
          val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
          val _tmp_xAxis = _result.getInt("xAxis")
          val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
          val _tmp_fromDate = _result.getLong("fromDate")
          val _tmp_fromRelTo = _result.getInt("fromRelTo")
          val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
          val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
          val _tmp_toDate = _result.getLong("toDate")
          val _tmp_toRelTo = _result.getInt("toRelTo")
          val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
          val _tmp_toRelUnit = _result.getInt("toRelUnit")
          val _tmp_reportTitle = _result.getString("reportTitle")
          val _tmp_reportDescription = _result.getString("reportDescription")
          val _tmp_reportSeries = _result.getString("reportSeries")
          val _tmp_reportInactive = _result.getBoolean("reportInactive")
          val _tmp_isTemplate = _result.getBoolean("isTemplate")
          val _tmp_priority = _result.getInt("priority")
          val _tmp_reportTitleId = _result.getInt("reportTitleId")
          val _tmp_reportDescId = _result.getInt("reportDescId")
          val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
          val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
          val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
          val _tmp_reportLct = _result.getLong("reportLct")
          Report().apply {
            this.reportUid = _tmp_reportUid
            this.reportOwnerUid = _tmp_reportOwnerUid
            this.xAxis = _tmp_xAxis
            this.reportDateRangeSelection = _tmp_reportDateRangeSelection
            this.fromDate = _tmp_fromDate
            this.fromRelTo = _tmp_fromRelTo
            this.fromRelOffSet = _tmp_fromRelOffSet
            this.fromRelUnit = _tmp_fromRelUnit
            this.toDate = _tmp_toDate
            this.toRelTo = _tmp_toRelTo
            this.toRelOffSet = _tmp_toRelOffSet
            this.toRelUnit = _tmp_toRelUnit
            this.reportTitle = _tmp_reportTitle
            this.reportDescription = _tmp_reportDescription
            this.reportSeries = _tmp_reportSeries
            this.reportInactive = _tmp_reportInactive
            this.isTemplate = _tmp_isTemplate
            this.priority = _tmp_priority
            this.reportTitleId = _tmp_reportTitleId
            this.reportDescId = _tmp_reportDescId
            this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
            this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
            this.reportLastChangedBy = _tmp_reportLastChangedBy
            this.reportLct = _tmp_reportLct
          }
        }
      }
    }
  }

  public override fun findAllActiveReportLive(isTemplate: Boolean): LiveData<List<Report>> =
      LiveDataImpl(_db, listOf("REPORT"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |SELECT * FROM REPORT WHERE NOT reportInactive 
    |        AND isTemplate = ?
    |        ORDER BY priority ASC
    |            
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,isTemplate)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_reportUid = _result.getLong("reportUid")
          val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
          val _tmp_xAxis = _result.getInt("xAxis")
          val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
          val _tmp_fromDate = _result.getLong("fromDate")
          val _tmp_fromRelTo = _result.getInt("fromRelTo")
          val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
          val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
          val _tmp_toDate = _result.getLong("toDate")
          val _tmp_toRelTo = _result.getInt("toRelTo")
          val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
          val _tmp_toRelUnit = _result.getInt("toRelUnit")
          val _tmp_reportTitle = _result.getString("reportTitle")
          val _tmp_reportDescription = _result.getString("reportDescription")
          val _tmp_reportSeries = _result.getString("reportSeries")
          val _tmp_reportInactive = _result.getBoolean("reportInactive")
          val _tmp_isTemplate = _result.getBoolean("isTemplate")
          val _tmp_priority = _result.getInt("priority")
          val _tmp_reportTitleId = _result.getInt("reportTitleId")
          val _tmp_reportDescId = _result.getInt("reportDescId")
          val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
          val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
          val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
          val _tmp_reportLct = _result.getLong("reportLct")
          Report().apply {
            this.reportUid = _tmp_reportUid
            this.reportOwnerUid = _tmp_reportOwnerUid
            this.xAxis = _tmp_xAxis
            this.reportDateRangeSelection = _tmp_reportDateRangeSelection
            this.fromDate = _tmp_fromDate
            this.fromRelTo = _tmp_fromRelTo
            this.fromRelOffSet = _tmp_fromRelOffSet
            this.fromRelUnit = _tmp_fromRelUnit
            this.toDate = _tmp_toDate
            this.toRelTo = _tmp_toRelTo
            this.toRelOffSet = _tmp_toRelOffSet
            this.toRelUnit = _tmp_toRelUnit
            this.reportTitle = _tmp_reportTitle
            this.reportDescription = _tmp_reportDescription
            this.reportSeries = _tmp_reportSeries
            this.reportInactive = _tmp_reportInactive
            this.isTemplate = _tmp_isTemplate
            this.priority = _tmp_priority
            this.reportTitleId = _tmp_reportTitleId
            this.reportDescId = _tmp_reportDescId
            this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
            this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
            this.reportLastChangedBy = _tmp_reportLastChangedBy
            this.reportLct = _tmp_reportLct
          }
        }
      }
    }
  }

  public override fun findAllActiveReportList(isTemplate: Boolean): List<Report> =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |SELECT * FROM REPORT WHERE NOT reportInactive 
  |        AND isTemplate = ?
  |        ORDER BY priority ASC
  |            
  """.trimMargin() )) { _stmt -> 
    _stmt.setBoolean(1,isTemplate)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_reportUid = _result.getLong("reportUid")
        val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
        val _tmp_xAxis = _result.getInt("xAxis")
        val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
        val _tmp_fromDate = _result.getLong("fromDate")
        val _tmp_fromRelTo = _result.getInt("fromRelTo")
        val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
        val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
        val _tmp_toDate = _result.getLong("toDate")
        val _tmp_toRelTo = _result.getInt("toRelTo")
        val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
        val _tmp_toRelUnit = _result.getInt("toRelUnit")
        val _tmp_reportTitle = _result.getString("reportTitle")
        val _tmp_reportDescription = _result.getString("reportDescription")
        val _tmp_reportSeries = _result.getString("reportSeries")
        val _tmp_reportInactive = _result.getBoolean("reportInactive")
        val _tmp_isTemplate = _result.getBoolean("isTemplate")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_reportTitleId = _result.getInt("reportTitleId")
        val _tmp_reportDescId = _result.getInt("reportDescId")
        val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
        val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
        val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
        val _tmp_reportLct = _result.getLong("reportLct")
        Report().apply {
          this.reportUid = _tmp_reportUid
          this.reportOwnerUid = _tmp_reportOwnerUid
          this.xAxis = _tmp_xAxis
          this.reportDateRangeSelection = _tmp_reportDateRangeSelection
          this.fromDate = _tmp_fromDate
          this.fromRelTo = _tmp_fromRelTo
          this.fromRelOffSet = _tmp_fromRelOffSet
          this.fromRelUnit = _tmp_fromRelUnit
          this.toDate = _tmp_toDate
          this.toRelTo = _tmp_toRelTo
          this.toRelOffSet = _tmp_toRelOffSet
          this.toRelUnit = _tmp_toRelUnit
          this.reportTitle = _tmp_reportTitle
          this.reportDescription = _tmp_reportDescription
          this.reportSeries = _tmp_reportSeries
          this.reportInactive = _tmp_reportInactive
          this.isTemplate = _tmp_isTemplate
          this.priority = _tmp_priority
          this.reportTitleId = _tmp_reportTitleId
          this.reportDescId = _tmp_reportDescId
          this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
          this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
          this.reportLastChangedBy = _tmp_reportLastChangedBy
          this.reportLct = _tmp_reportLct
        }
      }
    }
  }

  public override fun findByUidList(uidList: List<Long>): List<Long> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT reportUid FROM Report WHERE reportUid IN (?)"
      ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE Report 
    |           SET reportInactive = ?,
    |               reportLct = ? 
    |         WHERE reportUid IN (?)
    |    
    """.trimMargin() ,hasListParams = true)) { _stmt -> 
      _stmt.setBoolean(1,toggleVisibility)
      _stmt.setLong(2,updateTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedItem.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
