package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryContentCategoryJoinDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContentEntryContentCategoryJoinDao() {
  public val _insertAdapterContentEntryContentCategoryJoin_:
      EntityInsertionAdapter<ContentEntryContentCategoryJoin> = object :
      EntityInsertionAdapter<ContentEntryContentCategoryJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ContentEntryContentCategoryJoin (ceccjUid, ceccjContentEntryUid, ceccjContentCategoryUid, ceccjLocalChangeSeqNum, ceccjMasterChangeSeqNum, ceccjLastChangedBy, ceccjLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryContentCategoryJoin): Unit {
      if(entity.ceccjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ceccjUid)
      }
      stmt.setLong(2, entity.ceccjContentEntryUid)
      stmt.setLong(3, entity.ceccjContentCategoryUid)
      stmt.setLong(4, entity.ceccjLocalChangeSeqNum)
      stmt.setLong(5, entity.ceccjMasterChangeSeqNum)
      stmt.setInt(6, entity.ceccjLastChangedBy)
      stmt.setLong(7, entity.ceccjLct)
    }
  }

  public override fun insert(entity: ContentEntryContentCategoryJoin): Long {
    val _retVal = _insertAdapterContentEntryContentCategoryJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntryContentCategoryJoin): Long {
    val _retVal = _insertAdapterContentEntryContentCategoryJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContentEntryContentCategoryJoin>): Unit {
    _insertAdapterContentEntryContentCategoryJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<ContentEntryContentCategoryJoin>): Unit {
    val _sql =
        "UPDATE ContentEntryContentCategoryJoin SET ceccjContentEntryUid = ?, ceccjContentCategoryUid = ?, ceccjLocalChangeSeqNum = ?, ceccjMasterChangeSeqNum = ?, ceccjLastChangedBy = ?, ceccjLct = ? WHERE ceccjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.ceccjContentEntryUid)
        _stmt.setLong(2, _entity.ceccjContentCategoryUid)
        _stmt.setLong(3, _entity.ceccjLocalChangeSeqNum)
        _stmt.setLong(4, _entity.ceccjMasterChangeSeqNum)
        _stmt.setInt(5, _entity.ceccjLastChangedBy)
        _stmt.setLong(6, _entity.ceccjLct)
        _stmt.setLong(7, _entity.ceccjUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentEntryContentCategoryJoin): Unit {
    val _sql =
        "UPDATE ContentEntryContentCategoryJoin SET ceccjContentEntryUid = ?, ceccjContentCategoryUid = ?, ceccjLocalChangeSeqNum = ?, ceccjMasterChangeSeqNum = ?, ceccjLastChangedBy = ?, ceccjLct = ? WHERE ceccjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ceccjContentEntryUid)
      _stmt.setLong(2, entity.ceccjContentCategoryUid)
      _stmt.setLong(3, entity.ceccjLocalChangeSeqNum)
      _stmt.setLong(4, entity.ceccjMasterChangeSeqNum)
      _stmt.setInt(5, entity.ceccjLastChangedBy)
      _stmt.setLong(6, entity.ceccjLct)
      _stmt.setLong(7, entity.ceccjUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |      SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjPk,
    |             ? AS ceccjDestination
    |        FROM ContentEntryContentCategoryJoin
    |       WHERE ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |             (SELECT ceccjVersionId
    |                FROM ContentEntryContentCategoryJoinReplicate
    |               WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |                 AND ceccjDestination = ?), 0) 
    |      /*psql ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |             SET ceccjPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |      SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjPk,
    |             ? AS ceccjDestination
    |        FROM ContentEntryContentCategoryJoin
    |       WHERE ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |             (SELECT ceccjVersionId
    |                FROM ContentEntryContentCategoryJoinReplicate
    |               WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |                 AND ceccjDestination = ?), 0) 
    |       ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |             SET ceccjPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |  SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjUid,
    |         UserSession.usClientNodeId AS ceccjDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryContentCategoryJoin
    |             ON ChangeLog.chTableId = 3
    |                AND ChangeLog.chEntityPk = ContentEntryContentCategoryJoin.ceccjUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |         (SELECT ceccjVersionId
    |            FROM ContentEntryContentCategoryJoinReplicate
    |           WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |             AND ceccjDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |     SET ceccjPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |  SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjUid,
    |         UserSession.usClientNodeId AS ceccjDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryContentCategoryJoin
    |             ON ChangeLog.chTableId = 3
    |                AND ChangeLog.chEntityPk = ContentEntryContentCategoryJoin.ceccjUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |         (SELECT ceccjVersionId
    |            FROM ContentEntryContentCategoryJoinReplicate
    |           WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |             AND ceccjDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |     SET ceccjPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun publicContentEntryContentCategoryJoins():
      List<ContentEntryContentCategoryJoin> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT ContentEntryContentCategoryJoin.* FROM ContentEntryContentCategoryJoin LEFT JOIN ContentEntry ON ContentEntryContentCategoryJoin.ceccjContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publik"
      )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_ceccjUid = _result.getLong("ceccjUid")
        val _tmp_ceccjContentEntryUid = _result.getLong("ceccjContentEntryUid")
        val _tmp_ceccjContentCategoryUid = _result.getLong("ceccjContentCategoryUid")
        val _tmp_ceccjLocalChangeSeqNum = _result.getLong("ceccjLocalChangeSeqNum")
        val _tmp_ceccjMasterChangeSeqNum = _result.getLong("ceccjMasterChangeSeqNum")
        val _tmp_ceccjLastChangedBy = _result.getInt("ceccjLastChangedBy")
        val _tmp_ceccjLct = _result.getLong("ceccjLct")
        ContentEntryContentCategoryJoin().apply {
          this.ceccjUid = _tmp_ceccjUid
          this.ceccjContentEntryUid = _tmp_ceccjContentEntryUid
          this.ceccjContentCategoryUid = _tmp_ceccjContentCategoryUid
          this.ceccjLocalChangeSeqNum = _tmp_ceccjLocalChangeSeqNum
          this.ceccjMasterChangeSeqNum = _tmp_ceccjMasterChangeSeqNum
          this.ceccjLastChangedBy = _tmp_ceccjLastChangedBy
          this.ceccjLct = _tmp_ceccjLct
        }
      }
    }
  }

  public override fun findJoinByParentChildUuids(categoryUid: Long, contentEntry: Long):
      ContentEntryContentCategoryJoin? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * from ContentEntryContentCategoryJoin WHERE ceccjContentCategoryUid = ? AND ceccjContentEntryUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,categoryUid)
    _stmt.setLong(2,contentEntry)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_ceccjUid = _result.getLong("ceccjUid")
        val _tmp_ceccjContentEntryUid = _result.getLong("ceccjContentEntryUid")
        val _tmp_ceccjContentCategoryUid = _result.getLong("ceccjContentCategoryUid")
        val _tmp_ceccjLocalChangeSeqNum = _result.getLong("ceccjLocalChangeSeqNum")
        val _tmp_ceccjMasterChangeSeqNum = _result.getLong("ceccjMasterChangeSeqNum")
        val _tmp_ceccjLastChangedBy = _result.getInt("ceccjLastChangedBy")
        val _tmp_ceccjLct = _result.getLong("ceccjLct")
        ContentEntryContentCategoryJoin().apply {
          this.ceccjUid = _tmp_ceccjUid
          this.ceccjContentEntryUid = _tmp_ceccjContentEntryUid
          this.ceccjContentCategoryUid = _tmp_ceccjContentCategoryUid
          this.ceccjLocalChangeSeqNum = _tmp_ceccjLocalChangeSeqNum
          this.ceccjMasterChangeSeqNum = _tmp_ceccjMasterChangeSeqNum
          this.ceccjLastChangedBy = _tmp_ceccjLastChangedBy
          this.ceccjLct = _tmp_ceccjLct
        }
      }
    }
  }
}
