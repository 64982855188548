package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.AgentDao
import com.ustadmobile.core.db.dao.AgentDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ChatDao
import com.ustadmobile.core.db.dao.ChatDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ChatMemberDao
import com.ustadmobile.core.db.dao.ChatMemberDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ClazzAssignmentContentJoinDao
import com.ustadmobile.core.db.dao.ClazzAssignmentContentJoinDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ClazzAssignmentRollUpDao
import com.ustadmobile.core.db.dao.ClazzContentJoinDao
import com.ustadmobile.core.db.dao.ClazzContentJoinDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.ClazzLogDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.CommentsDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ConnectivityStatusDao
import com.ustadmobile.core.db.dao.ContainerDao
import com.ustadmobile.core.db.dao.ContainerDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContainerETagDao
import com.ustadmobile.core.db.dao.ContainerEntryDao
import com.ustadmobile.core.db.dao.ContainerEntryFileDao
import com.ustadmobile.core.db.dao.ContainerImportJobDao
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategoryDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ContentJobDao
import com.ustadmobile.core.db.dao.ContentJobItemDao
import com.ustadmobile.core.db.dao.ContextXObjectStatementJoinDao
import com.ustadmobile.core.db.dao.ContextXObjectStatementJoinDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionAttachmentDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionAttachmentDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseDiscussionDao
import com.ustadmobile.core.db.dao.CourseDiscussionDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CoursePictureDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.DiscussionPostDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.DiscussionTopicDao
import com.ustadmobile.core.db.dao.DiscussionTopicDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.EntityRoleDao
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ErrorReportDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.GroupLearningSessionDao
import com.ustadmobile.core.db.dao.GroupLearningSessionDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.HolidayDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LanguageVariantDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.LearnerGroupDao
import com.ustadmobile.core.db.dao.LearnerGroupDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.LearnerGroupMemberDao
import com.ustadmobile.core.db.dao.LearnerGroupMemberDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.LeavingReasonDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.LocallyAvailableContainerDao
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.MessageDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.MessageReadDao
import com.ustadmobile.core.db.dao.MessageReadDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.NetworkNodeDao
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuth2Dao_ReplicateWrapper
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.PersonPictureDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ReportDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.ScheduleDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.SchoolDao
import com.ustadmobile.core.db.dao.SchoolDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.SchoolMemberDao
import com.ustadmobile.core.db.dao.SchoolMemberDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.ScopedGrantDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.ScrapeQueueItemDao
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.SiteTermsDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.StateContentDao
import com.ustadmobile.core.db.dao.StateContentDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.StateDao
import com.ustadmobile.core.db.dao.StateDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.StatementDao
import com.ustadmobile.core.db.dao.StatementDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.UserSessionDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.VerbDao
import com.ustadmobile.core.db.dao.VerbDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.XLangMapEntryDao
import com.ustadmobile.core.db.dao.XLangMapEntryDao_ReplicateWrapper
import com.ustadmobile.core.db.dao.XObjectDao
import com.ustadmobile.core.db.dao.XObjectDao_ReplicateWrapper
import com.ustadmobile.door.DoorDatabaseReplicateWrapper
import com.ustadmobile.door.room.InvalidationTracker
import com.ustadmobile.door.room.RoomDatabase
import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.Runnable

@Suppress("REDUNDANT_PROJECTION", "ClassName")
public class UmAppDatabase_ReplicateWrapper(
  private val _db: UmAppDatabase,
) : UmAppDatabase(), DoorDatabaseReplicateWrapper {
  public override val dbVersion: Int
    get() = 107

  public override val dbName: String
    get() = "DoorWrapper for [${_db.toString()}]"

  public override val networkNodeDao: NetworkNodeDao
    get() = _db.networkNodeDao

  public val _PersonDao: PersonDao by lazy  {
        PersonDao_ReplicateWrapper(_db, _db.personDao)
      }


  public override val personDao: PersonDao
    get() = _PersonDao

  public val _ClazzDao: ClazzDao by lazy  {
        ClazzDao_ReplicateWrapper(_db, _db.clazzDao)
      }


  public override val clazzDao: ClazzDao
    get() = _ClazzDao

  public val _CourseBlockDao: CourseBlockDao by lazy  {
        CourseBlockDao_ReplicateWrapper(_db, _db.courseBlockDao)
      }


  public override val courseBlockDao: CourseBlockDao
    get() = _CourseBlockDao

  public val _CourseTerminologyDao: CourseTerminologyDao by lazy  {
        CourseTerminologyDao_ReplicateWrapper(_db, _db.courseTerminologyDao)
      }


  public override val courseTerminologyDao: CourseTerminologyDao
    get() = _CourseTerminologyDao

  public val _CourseGroupSetDao: CourseGroupSetDao by lazy  {
        CourseGroupSetDao_ReplicateWrapper(_db, _db.courseGroupSetDao)
      }


  public override val courseGroupSetDao: CourseGroupSetDao
    get() = _CourseGroupSetDao

  public val _CourseGroupMemberDao: CourseGroupMemberDao by lazy  {
        CourseGroupMemberDao_ReplicateWrapper(_db, _db.courseGroupMemberDao)
      }


  public override val courseGroupMemberDao: CourseGroupMemberDao
    get() = _CourseGroupMemberDao

  public val _ClazzEnrolmentDao: ClazzEnrolmentDao by lazy  {
        ClazzEnrolmentDao_ReplicateWrapper(_db, _db.clazzEnrolmentDao)
      }


  public override val clazzEnrolmentDao: ClazzEnrolmentDao
    get() = _ClazzEnrolmentDao

  public val _LeavingReasonDao: LeavingReasonDao by lazy  {
        LeavingReasonDao_ReplicateWrapper(_db, _db.leavingReasonDao)
      }


  public override val leavingReasonDao: LeavingReasonDao
    get() = _LeavingReasonDao

  public val _ContentEntryDao: ContentEntryDao by lazy  {
        ContentEntryDao_ReplicateWrapper(_db, _db.contentEntryDao)
      }


  public override val contentEntryDao: ContentEntryDao
    get() = _ContentEntryDao

  public val _ContentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao by lazy  {
        ContentEntryContentCategoryJoinDao_ReplicateWrapper(_db,
            _db.contentEntryContentCategoryJoinDao)
      }


  public override val contentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao
    get() = _ContentEntryContentCategoryJoinDao

  public val _ContentEntryParentChildJoinDao: ContentEntryParentChildJoinDao by lazy  {
        ContentEntryParentChildJoinDao_ReplicateWrapper(_db, _db.contentEntryParentChildJoinDao)
      }


  public override val contentEntryParentChildJoinDao: ContentEntryParentChildJoinDao
    get() = _ContentEntryParentChildJoinDao

  public val _ContentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao by lazy  {
        ContentEntryRelatedEntryJoinDao_ReplicateWrapper(_db, _db.contentEntryRelatedEntryJoinDao)
      }


  public override val contentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao
    get() = _ContentEntryRelatedEntryJoinDao

  public val _ClazzContentJoinDao: ClazzContentJoinDao by lazy  {
        ClazzContentJoinDao_ReplicateWrapper(_db, _db.clazzContentJoinDao)
      }


  public override val clazzContentJoinDao: ClazzContentJoinDao
    get() = _ClazzContentJoinDao

  public val _ContentCategorySchemaDao: ContentCategorySchemaDao by lazy  {
        ContentCategorySchemaDao_ReplicateWrapper(_db, _db.contentCategorySchemaDao)
      }


  public override val contentCategorySchemaDao: ContentCategorySchemaDao
    get() = _ContentCategorySchemaDao

  public val _ContentCategoryDao: ContentCategoryDao by lazy  {
        ContentCategoryDao_ReplicateWrapper(_db, _db.contentCategoryDao)
      }


  public override val contentCategoryDao: ContentCategoryDao
    get() = _ContentCategoryDao

  public val _LanguageDao: LanguageDao by lazy  {
        LanguageDao_ReplicateWrapper(_db, _db.languageDao)
      }


  public override val languageDao: LanguageDao
    get() = _LanguageDao

  public val _LanguageVariantDao: LanguageVariantDao by lazy  {
        LanguageVariantDao_ReplicateWrapper(_db, _db.languageVariantDao)
      }


  public override val languageVariantDao: LanguageVariantDao
    get() = _LanguageVariantDao

  public override val scrapeQueueItemDao: ScrapeQueueItemDao
    get() = _db.scrapeQueueItemDao

  public override val personAuthDao: PersonAuthDao
    get() = _db.personAuthDao

  public val _PersonGroupDao: PersonGroupDao by lazy  {
        PersonGroupDao_ReplicateWrapper(_db, _db.personGroupDao)
      }


  public override val personGroupDao: PersonGroupDao
    get() = _PersonGroupDao

  public val _PersonGroupMemberDao: PersonGroupMemberDao by lazy  {
        PersonGroupMemberDao_ReplicateWrapper(_db, _db.personGroupMemberDao)
      }


  public override val personGroupMemberDao: PersonGroupMemberDao
    get() = _PersonGroupMemberDao

  public override val entityRoleDao: EntityRoleDao
    get() = _db.entityRoleDao

  public val _PersonPictureDao: PersonPictureDao by lazy  {
        PersonPictureDao_ReplicateWrapper(_db, _db.personPictureDao)
      }


  public override val personPictureDao: PersonPictureDao
    get() = _PersonPictureDao

  public override val connectivityStatusDao: ConnectivityStatusDao
    get() = _db.connectivityStatusDao

  public val _ContainerDao: ContainerDao by lazy  {
        ContainerDao_ReplicateWrapper(_db, _db.containerDao)
      }


  public override val containerDao: ContainerDao
    get() = _ContainerDao

  public override val containerEntryDao: ContainerEntryDao
    get() = _db.containerEntryDao

  public override val containerEntryFileDao: ContainerEntryFileDao
    get() = _db.containerEntryFileDao

  public override val containerETagDao: ContainerETagDao
    get() = _db.containerETagDao

  public val _VerbDao: VerbDao by lazy  {
        VerbDao_ReplicateWrapper(_db, _db.verbDao)
      }


  public override val verbDao: VerbDao
    get() = _VerbDao

  public val _XObjectDao: XObjectDao by lazy  {
        XObjectDao_ReplicateWrapper(_db, _db.xObjectDao)
      }


  public override val xObjectDao: XObjectDao
    get() = _XObjectDao

  public val _ReportDao: ReportDao by lazy  {
        ReportDao_ReplicateWrapper(_db, _db.reportDao)
      }


  public override val reportDao: ReportDao
    get() = _ReportDao

  public override val containerImportJobDao: ContainerImportJobDao
    get() = _db.containerImportJobDao

  public val _StatementDao: StatementDao by lazy  {
        StatementDao_ReplicateWrapper(_db, _db.statementDao)
      }


  public override val statementDao: StatementDao
    get() = _StatementDao

  public val _ContextXObjectStatementJoinDao: ContextXObjectStatementJoinDao by lazy  {
        ContextXObjectStatementJoinDao_ReplicateWrapper(_db, _db.contextXObjectStatementJoinDao)
      }


  public override val contextXObjectStatementJoinDao: ContextXObjectStatementJoinDao
    get() = _ContextXObjectStatementJoinDao

  public val _StateDao: StateDao by lazy  {
        StateDao_ReplicateWrapper(_db, _db.stateDao)
      }


  public override val stateDao: StateDao
    get() = _StateDao

  public val _StateContentDao: StateContentDao by lazy  {
        StateContentDao_ReplicateWrapper(_db, _db.stateContentDao)
      }


  public override val stateContentDao: StateContentDao
    get() = _StateContentDao

  public val _AgentDao: AgentDao by lazy  {
        AgentDao_ReplicateWrapper(_db, _db.agentDao)
      }


  public override val agentDao: AgentDao
    get() = _AgentDao

  public val _LearnerGroupDao: LearnerGroupDao by lazy  {
        LearnerGroupDao_ReplicateWrapper(_db, _db.learnerGroupDao)
      }


  public override val learnerGroupDao: LearnerGroupDao
    get() = _LearnerGroupDao

  public val _LearnerGroupMemberDao: LearnerGroupMemberDao by lazy  {
        LearnerGroupMemberDao_ReplicateWrapper(_db, _db.learnerGroupMemberDao)
      }


  public override val learnerGroupMemberDao: LearnerGroupMemberDao
    get() = _LearnerGroupMemberDao

  public val _GroupLearningSessionDao: GroupLearningSessionDao by lazy  {
        GroupLearningSessionDao_ReplicateWrapper(_db, _db.groupLearningSessionDao)
      }


  public override val groupLearningSessionDao: GroupLearningSessionDao
    get() = _GroupLearningSessionDao

  public val _ClazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao by lazy  {
        ClazzLogAttendanceRecordDao_ReplicateWrapper(_db, _db.clazzLogAttendanceRecordDao)
      }


  public override val clazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao
    get() = _ClazzLogAttendanceRecordDao

  public val _ClazzLogDao: ClazzLogDao by lazy  {
        ClazzLogDao_ReplicateWrapper(_db, _db.clazzLogDao)
      }


  public override val clazzLogDao: ClazzLogDao
    get() = _ClazzLogDao

  public val _ScheduleDao: ScheduleDao by lazy  {
        ScheduleDao_ReplicateWrapper(_db, _db.scheduleDao)
      }


  public override val scheduleDao: ScheduleDao
    get() = _ScheduleDao

  public val _HolidayCalendarDao: HolidayCalendarDao by lazy  {
        HolidayCalendarDao_ReplicateWrapper(_db, _db.holidayCalendarDao)
      }


  public override val holidayCalendarDao: HolidayCalendarDao
    get() = _HolidayCalendarDao

  public val _HolidayDao: HolidayDao by lazy  {
        HolidayDao_ReplicateWrapper(_db, _db.holidayDao)
      }


  public override val holidayDao: HolidayDao
    get() = _HolidayDao

  public val _SchoolDao: SchoolDao by lazy  {
        SchoolDao_ReplicateWrapper(_db, _db.schoolDao)
      }


  public override val schoolDao: SchoolDao
    get() = _SchoolDao

  public val _XLangMapEntryDao: XLangMapEntryDao by lazy  {
        XLangMapEntryDao_ReplicateWrapper(_db, _db.xLangMapEntryDao)
      }


  public override val xLangMapEntryDao: XLangMapEntryDao
    get() = _XLangMapEntryDao

  public override val locallyAvailableContainerDao: LocallyAvailableContainerDao
    get() = _db.locallyAvailableContainerDao

  public val _SchoolMemberDao: SchoolMemberDao by lazy  {
        SchoolMemberDao_ReplicateWrapper(_db, _db.schoolMemberDao)
      }


  public override val schoolMemberDao: SchoolMemberDao
    get() = _SchoolMemberDao

  public val _ClazzAssignmentDao: ClazzAssignmentDao by lazy  {
        ClazzAssignmentDao_ReplicateWrapper(_db, _db.clazzAssignmentDao)
      }


  public override val clazzAssignmentDao: ClazzAssignmentDao
    get() = _ClazzAssignmentDao

  public val _ClazzAssignmentContentJoinDao: ClazzAssignmentContentJoinDao by lazy  {
        ClazzAssignmentContentJoinDao_ReplicateWrapper(_db, _db.clazzAssignmentContentJoinDao)
      }


  public override val clazzAssignmentContentJoinDao: ClazzAssignmentContentJoinDao
    get() = _ClazzAssignmentContentJoinDao

  public override val clazzAssignmentRollUpDao: ClazzAssignmentRollUpDao
    get() = _db.clazzAssignmentRollUpDao

  public val _CourseAssignmentSubmissionDao: CourseAssignmentSubmissionDao by lazy  {
        CourseAssignmentSubmissionDao_ReplicateWrapper(_db, _db.courseAssignmentSubmissionDao)
      }


  public override val courseAssignmentSubmissionDao: CourseAssignmentSubmissionDao
    get() = _CourseAssignmentSubmissionDao

  public val _CourseAssignmentSubmissionAttachmentDao: CourseAssignmentSubmissionAttachmentDao by
      lazy  {
        CourseAssignmentSubmissionAttachmentDao_ReplicateWrapper(_db,
            _db.courseAssignmentSubmissionAttachmentDao)
      }


  public override val courseAssignmentSubmissionAttachmentDao:
      CourseAssignmentSubmissionAttachmentDao
    get() = _CourseAssignmentSubmissionAttachmentDao

  public val _CourseAssignmentMarkDao: CourseAssignmentMarkDao by lazy  {
        CourseAssignmentMarkDao_ReplicateWrapper(_db, _db.courseAssignmentMarkDao)
      }


  public override val courseAssignmentMarkDao: CourseAssignmentMarkDao
    get() = _CourseAssignmentMarkDao

  public val _CommentsDao: CommentsDao by lazy  {
        CommentsDao_ReplicateWrapper(_db, _db.commentsDao)
      }


  public override val commentsDao: CommentsDao
    get() = _CommentsDao

  public override val syncNodeDao: SyncNodeDao
    get() = _db.syncNodeDao

  public val _SiteDao: SiteDao by lazy  {
        SiteDao_ReplicateWrapper(_db, _db.siteDao)
      }


  public override val siteDao: SiteDao
    get() = _SiteDao

  public val _SiteTermsDao: SiteTermsDao by lazy  {
        SiteTermsDao_ReplicateWrapper(_db, _db.siteTermsDao)
      }


  public override val siteTermsDao: SiteTermsDao
    get() = _SiteTermsDao

  public val _PersonParentJoinDao: PersonParentJoinDao by lazy  {
        PersonParentJoinDao_ReplicateWrapper(_db, _db.personParentJoinDao)
      }


  public override val personParentJoinDao: PersonParentJoinDao
    get() = _PersonParentJoinDao

  public val _ScopedGrantDao: ScopedGrantDao by lazy  {
        ScopedGrantDao_ReplicateWrapper(_db, _db.scopedGrantDao)
      }


  public override val scopedGrantDao: ScopedGrantDao
    get() = _ScopedGrantDao

  public val _ErrorReportDao: ErrorReportDao by lazy  {
        ErrorReportDao_ReplicateWrapper(_db, _db.errorReportDao)
      }


  public override val errorReportDao: ErrorReportDao
    get() = _ErrorReportDao

  public val _PersonAuth2Dao: PersonAuth2Dao by lazy  {
        PersonAuth2Dao_ReplicateWrapper(_db, _db.personAuth2Dao)
      }


  public override val personAuth2Dao: PersonAuth2Dao
    get() = _PersonAuth2Dao

  public val _UserSessionDao: UserSessionDao by lazy  {
        UserSessionDao_ReplicateWrapper(_db, _db.userSessionDao)
      }


  public override val userSessionDao: UserSessionDao
    get() = _UserSessionDao

  public override val contentJobItemDao: ContentJobItemDao
    get() = _db.contentJobItemDao

  public override val contentJobDao: ContentJobDao
    get() = _db.contentJobDao

  public val _CoursePictureDao: CoursePictureDao by lazy  {
        CoursePictureDao_ReplicateWrapper(_db, _db.coursePictureDao)
      }


  public override val coursePictureDao: CoursePictureDao
    get() = _CoursePictureDao

  public val _ContentEntryPictureDao: ContentEntryPictureDao by lazy  {
        ContentEntryPictureDao_ReplicateWrapper(_db, _db.contentEntryPictureDao)
      }


  public override val contentEntryPictureDao: ContentEntryPictureDao
    get() = _ContentEntryPictureDao

  public val _ChatDao: ChatDao by lazy  {
        ChatDao_ReplicateWrapper(_db, _db.chatDao)
      }


  public override val chatDao: ChatDao
    get() = _ChatDao

  public val _ChatMemberDao: ChatMemberDao by lazy  {
        ChatMemberDao_ReplicateWrapper(_db, _db.chatMemberDao)
      }


  public override val chatMemberDao: ChatMemberDao
    get() = _ChatMemberDao

  public val _MessageDao: MessageDao by lazy  {
        MessageDao_ReplicateWrapper(_db, _db.messageDao)
      }


  public override val messageDao: MessageDao
    get() = _MessageDao

  public val _MessageReadDao: MessageReadDao by lazy  {
        MessageReadDao_ReplicateWrapper(_db, _db.messageReadDao)
      }


  public override val messageReadDao: MessageReadDao
    get() = _MessageReadDao

  public val _CourseDiscussionDao: CourseDiscussionDao by lazy  {
        CourseDiscussionDao_ReplicateWrapper(_db, _db.courseDiscussionDao)
      }


  public override val courseDiscussionDao: CourseDiscussionDao
    get() = _CourseDiscussionDao

  public val _DiscussionTopicDao: DiscussionTopicDao by lazy  {
        DiscussionTopicDao_ReplicateWrapper(_db, _db.discussionTopicDao)
      }


  public override val discussionTopicDao: DiscussionTopicDao
    get() = _DiscussionTopicDao

  public val _DiscussionPostDao: DiscussionPostDao by lazy  {
        DiscussionPostDao_ReplicateWrapper(_db, _db.discussionPostDao)
      }


  public override val discussionPostDao: DiscussionPostDao
    get() = _DiscussionPostDao

  public override val realDatabase: RoomDatabase
    get() = _db

  public override fun createAllTables(): List<String> = _db.createAllTables()

  public override suspend fun clearAllTablesAsync(): Unit {
    _db.clearAllTablesAsync()
  }

  public override fun clearAllTables(): Unit {
    _db.clearAllTables()
  }

  public override fun runInTransaction(body: Runnable): Unit {
    _db.runInTransaction(body)
  }

  public override fun getInvalidationTracker(): InvalidationTracker = _db.getInvalidationTracker()
}
