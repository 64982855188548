package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ConnectivityStatus
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit

public class ConnectivityStatusDao_JdbcKt(
  public val _db: RoomDatabase,
) : ConnectivityStatusDao() {
  public val _insertAdapterConnectivityStatus_upsert: EntityInsertionAdapter<ConnectivityStatus> =
      object : EntityInsertionAdapter<ConnectivityStatus>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO ConnectivityStatus (csUid, connectivityState, wifiSsid, connectedOrConnecting) VALUES(?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ConnectivityStatus): Unit {
      stmt.setInt(1, entity.csUid)
      stmt.setInt(2, entity.connectivityState)
      stmt.setString(3, entity.wifiSsid)
      stmt.setBoolean(4, entity.connectedOrConnecting)
    }
  }

  public override fun insert(connectivityStatus: ConnectivityStatus): Long {
    val _retVal = _insertAdapterConnectivityStatus_upsert.insertAndReturnId(connectivityStatus)
    return _retVal
  }

  public override suspend fun insertAsync(connectivityStatus: ConnectivityStatus): Long {
    val _retVal = _insertAdapterConnectivityStatus_upsert.insertAndReturnIdAsync(connectivityStatus)
    return _retVal
  }

  public override fun statusLive(): LiveData<ConnectivityStatus?> = LiveDataImpl(_db,
      listOf("ConnectivityStatus"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1"
        )) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_csUid = _result.getInt("csUid")
          val _tmp_connectivityState = _result.getInt("connectivityState")
          val _tmp_wifiSsid = _result.getString("wifiSsid")
          val _tmp_connectedOrConnecting = _result.getBoolean("connectedOrConnecting")
          ConnectivityStatus().apply {
            this.csUid = _tmp_csUid
            this.connectivityState = _tmp_connectivityState
            this.wifiSsid = _tmp_wifiSsid
            this.connectedOrConnecting = _tmp_connectedOrConnecting
          }
        }
      }
    }
  }

  public override fun status(): ConnectivityStatus? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1"
      )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_csUid = _result.getInt("csUid")
        val _tmp_connectivityState = _result.getInt("connectivityState")
        val _tmp_wifiSsid = _result.getString("wifiSsid")
        val _tmp_connectedOrConnecting = _result.getBoolean("connectedOrConnecting")
        ConnectivityStatus().apply {
          this.csUid = _tmp_csUid
          this.connectivityState = _tmp_connectivityState
          this.wifiSsid = _tmp_wifiSsid
          this.connectedOrConnecting = _tmp_connectedOrConnecting
        }
      }
    }
  }

  public override suspend fun updateStateAsync(connectivityState: Int): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("UPDATE ConnectivityStatus SET connectivityState = ?"
        )) { _stmt -> 
      _stmt.setInt(1,connectivityState)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateState(connectivityState: Int, wifiSsid: String): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?"
        )) { _stmt -> 
      _stmt.setInt(1,connectivityState)
      _stmt.setString(2,wifiSsid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateStateSync(connectivityState: Int, wifiSsid: String): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?"
        )) { _stmt -> 
      _stmt.setInt(1,connectivityState)
      _stmt.setString(2,wifiSsid)
      _stmt.executeUpdate()
    }
  }
}
