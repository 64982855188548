package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentCategorySchema
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContentCategorySchemaDao : BaseDao<ContentCategorySchema> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun publicContentCategorySchemas(): List<ContentCategorySchema>

  public actual abstract fun findBySchemaUrl(schemaUrl: String): ContentCategorySchema?
}
