package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryButtonModel
import com.ustadmobile.lib.db.entities.ContentEntryContentJobItemParams
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.ContentEntryWithBlockAndLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentJobItemProgressAndStatus
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.DistinctCategorySchema
import com.ustadmobile.lib.db.entities.DownloadJobSizeInfo
import com.ustadmobile.lib.db.entities.LangUidAndName
import com.ustadmobile.lib.db.entities.Language
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContentEntryDao() {
  public val _insertAdapterContentEntry_: EntityInsertionAdapter<ContentEntry> = object :
      EntityInsertionAdapter<ContentEntry>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentEntry):
        Unit {
      if(entity.contentEntryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentEntryUid)
      }
      stmt.setString(2, entity.title)
      stmt.setString(3, entity.description)
      stmt.setString(4, entity.entryId)
      stmt.setString(5, entity.author)
      stmt.setString(6, entity.publisher)
      stmt.setInt(7, entity.licenseType)
      stmt.setString(8, entity.licenseName)
      stmt.setString(9, entity.licenseUrl)
      stmt.setString(10, entity.sourceUrl)
      stmt.setString(11, entity.thumbnailUrl)
      stmt.setLong(12, entity.lastModified)
      stmt.setLong(13, entity.primaryLanguageUid)
      stmt.setLong(14, entity.languageVariantUid)
      stmt.setInt(15, entity.contentFlags)
      stmt.setBoolean(16, entity.leaf)
      stmt.setBoolean(17, entity.publik)
      stmt.setBoolean(18, entity.ceInactive)
      stmt.setInt(19, entity.completionCriteria)
      stmt.setInt(20, entity.minScore)
      stmt.setInt(21, entity.contentTypeFlag)
      stmt.setLong(22, entity.contentOwner)
      stmt.setLong(23, entity.contentEntryLocalChangeSeqNum)
      stmt.setLong(24, entity.contentEntryMasterChangeSeqNum)
      stmt.setInt(25, entity.contentEntryLastChangedBy)
      stmt.setLong(26, entity.contentEntryLct)
    }
  }

  public val _insertAdapterContentEntry_upsert: EntityInsertionAdapter<ContentEntry> = object :
      EntityInsertionAdapter<ContentEntry>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentEntry):
        Unit {
      if(entity.contentEntryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentEntryUid)
      }
      stmt.setString(2, entity.title)
      stmt.setString(3, entity.description)
      stmt.setString(4, entity.entryId)
      stmt.setString(5, entity.author)
      stmt.setString(6, entity.publisher)
      stmt.setInt(7, entity.licenseType)
      stmt.setString(8, entity.licenseName)
      stmt.setString(9, entity.licenseUrl)
      stmt.setString(10, entity.sourceUrl)
      stmt.setString(11, entity.thumbnailUrl)
      stmt.setLong(12, entity.lastModified)
      stmt.setLong(13, entity.primaryLanguageUid)
      stmt.setLong(14, entity.languageVariantUid)
      stmt.setInt(15, entity.contentFlags)
      stmt.setBoolean(16, entity.leaf)
      stmt.setBoolean(17, entity.publik)
      stmt.setBoolean(18, entity.ceInactive)
      stmt.setInt(19, entity.completionCriteria)
      stmt.setInt(20, entity.minScore)
      stmt.setInt(21, entity.contentTypeFlag)
      stmt.setLong(22, entity.contentOwner)
      stmt.setLong(23, entity.contentEntryLocalChangeSeqNum)
      stmt.setLong(24, entity.contentEntryMasterChangeSeqNum)
      stmt.setInt(25, entity.contentEntryLastChangedBy)
      stmt.setLong(26, entity.contentEntryLct)
    }
  }

  public override suspend fun insertListAsync(entityList: List<ContentEntry>): Unit {
    _insertAdapterContentEntry_.insertListAsync(entityList)
  }

  public override fun replaceList(entries: List<ContentEntry>): Unit {
    _insertAdapterContentEntry_upsert.insertList(entries)
  }

  public override fun insertWithReplace(entry: ContentEntry): Unit {
    _insertAdapterContentEntry_upsert.insert(entry)
  }

  public override fun insert(entity: ContentEntry): Long {
    val _retVal = _insertAdapterContentEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntry): Long {
    val _retVal = _insertAdapterContentEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContentEntry>): Unit {
    _insertAdapterContentEntry_.insertList(entityList)
  }

  public override fun update(entity: ContentEntry): Unit {
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.title)
      _stmt.setString(2, entity.description)
      _stmt.setString(3, entity.entryId)
      _stmt.setString(4, entity.author)
      _stmt.setString(5, entity.publisher)
      _stmt.setInt(6, entity.licenseType)
      _stmt.setString(7, entity.licenseName)
      _stmt.setString(8, entity.licenseUrl)
      _stmt.setString(9, entity.sourceUrl)
      _stmt.setString(10, entity.thumbnailUrl)
      _stmt.setLong(11, entity.lastModified)
      _stmt.setLong(12, entity.primaryLanguageUid)
      _stmt.setLong(13, entity.languageVariantUid)
      _stmt.setInt(14, entity.contentFlags)
      _stmt.setBoolean(15, entity.leaf)
      _stmt.setBoolean(16, entity.publik)
      _stmt.setBoolean(17, entity.ceInactive)
      _stmt.setInt(18, entity.completionCriteria)
      _stmt.setInt(19, entity.minScore)
      _stmt.setInt(20, entity.contentTypeFlag)
      _stmt.setLong(21, entity.contentOwner)
      _stmt.setLong(22, entity.contentEntryLocalChangeSeqNum)
      _stmt.setLong(23, entity.contentEntryMasterChangeSeqNum)
      _stmt.setInt(24, entity.contentEntryLastChangedBy)
      _stmt.setLong(25, entity.contentEntryLct)
      _stmt.setLong(26, entity.contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateAsync(entity: ContentEntry): Int {
    var _result = 0
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.title)
      _stmt.setString(2, entity.description)
      _stmt.setString(3, entity.entryId)
      _stmt.setString(4, entity.author)
      _stmt.setString(5, entity.publisher)
      _stmt.setInt(6, entity.licenseType)
      _stmt.setString(7, entity.licenseName)
      _stmt.setString(8, entity.licenseUrl)
      _stmt.setString(9, entity.sourceUrl)
      _stmt.setString(10, entity.thumbnailUrl)
      _stmt.setLong(11, entity.lastModified)
      _stmt.setLong(12, entity.primaryLanguageUid)
      _stmt.setLong(13, entity.languageVariantUid)
      _stmt.setInt(14, entity.contentFlags)
      _stmt.setBoolean(15, entity.leaf)
      _stmt.setBoolean(16, entity.publik)
      _stmt.setBoolean(17, entity.ceInactive)
      _stmt.setInt(18, entity.completionCriteria)
      _stmt.setInt(19, entity.minScore)
      _stmt.setInt(20, entity.contentTypeFlag)
      _stmt.setLong(21, entity.contentOwner)
      _stmt.setLong(22, entity.contentEntryLocalChangeSeqNum)
      _stmt.setLong(23, entity.contentEntryMasterChangeSeqNum)
      _stmt.setInt(24, entity.contentEntryLastChangedBy)
      _stmt.setLong(25, entity.contentEntryLct)
      _stmt.setLong(26, entity.contentEntryUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<ContentEntry>): Unit {
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.title)
        _stmt.setString(2, _entity.description)
        _stmt.setString(3, _entity.entryId)
        _stmt.setString(4, _entity.author)
        _stmt.setString(5, _entity.publisher)
        _stmt.setInt(6, _entity.licenseType)
        _stmt.setString(7, _entity.licenseName)
        _stmt.setString(8, _entity.licenseUrl)
        _stmt.setString(9, _entity.sourceUrl)
        _stmt.setString(10, _entity.thumbnailUrl)
        _stmt.setLong(11, _entity.lastModified)
        _stmt.setLong(12, _entity.primaryLanguageUid)
        _stmt.setLong(13, _entity.languageVariantUid)
        _stmt.setInt(14, _entity.contentFlags)
        _stmt.setBoolean(15, _entity.leaf)
        _stmt.setBoolean(16, _entity.publik)
        _stmt.setBoolean(17, _entity.ceInactive)
        _stmt.setInt(18, _entity.completionCriteria)
        _stmt.setInt(19, _entity.minScore)
        _stmt.setInt(20, _entity.contentTypeFlag)
        _stmt.setLong(21, _entity.contentOwner)
        _stmt.setLong(22, _entity.contentEntryLocalChangeSeqNum)
        _stmt.setLong(23, _entity.contentEntryMasterChangeSeqNum)
        _stmt.setInt(24, _entity.contentEntryLastChangedBy)
        _stmt.setLong(25, _entity.contentEntryLct)
        _stmt.setLong(26, _entity.contentEntryUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT contentEntryUid AS ceUid,
    |                ? AS siteDestination
    |           FROM ContentEntry
    |          WHERE ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = ?), -1) 
    |         /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |                SET cePending = true
    |         */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT contentEntryUid AS ceUid,
    |                ? AS siteDestination
    |           FROM ContentEntry
    |          WHERE ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = ?), -1) 
    |          ON CONFLICT(cePk, ceDestination) DO UPDATE
    |                SET cePending = true
    |                
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT ContentEntry.contentEntryUid AS cePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntry
    |                    ON ChangeLog.chTableId = 42
    |                       AND ChangeLog.chEntityPk = ContentEntry.contentEntryUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = UserSession.usClientNodeId), 0)     
    |        /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |            SET cePending = true
    |         */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT ContentEntry.contentEntryUid AS cePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntry
    |                    ON ChangeLog.chTableId = 42
    |                       AND ChangeLog.chEntityPk = ContentEntry.contentEntryUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = UserSession.usClientNodeId), 0)     
    |         ON CONFLICT(cePk, ceDestination) DO UPDATE
    |            SET cePending = true
    |                        
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findEntryWithLanguageByEntryIdAsync(entryUuid: Long):
      ContentEntryWithLanguage? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT ContentEntry.*, Language.* FROM ContentEntry LEFT JOIN Language ON Language.langUid = ContentEntry.primaryLanguageUid WHERE ContentEntry.contentEntryUid=?"
      )) { _stmt -> 
    _stmt.setLong(1,entryUuid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        ContentEntryWithLanguage().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
          if(!_tmp_Language_isAllNull) {
            this.language = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
        }
      }
    }
  }

  public override suspend fun findEntryWithBlockAndLanguageByUidAsync(entityUid: Long):
      ContentEntryWithBlockAndLanguage? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentEntry.*, 
  |               Language.*,
  |               CourseBlock.*
  |          FROM ContentEntry
  |               LEFT JOIN Language 
  |               ON Language.langUid = ContentEntry.primaryLanguageUid 
  |               
  |               LEFT JOIN CourseBlock
  |               ON CourseBlock.cbType = 104
  |               AND CourseBlock.cbEntityUid = ?
  |               
  |         WHERE ContentEntry.contentEntryUid = ?       
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,entityUid)
    _stmt.setLong(2,entityUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        var _tmp_CourseBlock_nullCount = 0
        val _tmp_cbUid = _result.getLong("cbUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbType = _result.getInt("cbType")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbTitle = _result.getString("cbTitle")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbDescription = _result.getString("cbDescription")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbMaxPoints = _result.getInt("cbMaxPoints")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbMinPoints = _result.getInt("cbMinPoints")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbIndex = _result.getInt("cbIndex")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbActive = _result.getBoolean("cbActive")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbLct = _result.getLong("cbLct")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 19
        ContentEntryWithBlockAndLanguage().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
          if(!_tmp_Language_isAllNull) {
            this.language = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
          if(!_tmp_CourseBlock_isAllNull) {
            this.block = CourseBlock().apply {
              this.cbUid = _tmp_cbUid
              this.cbType = _tmp_cbType
              this.cbIndentLevel = _tmp_cbIndentLevel
              this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
              this.cbTitle = _tmp_cbTitle
              this.cbDescription = _tmp_cbDescription
              this.cbCompletionCriteria = _tmp_cbCompletionCriteria
              this.cbHideUntilDate = _tmp_cbHideUntilDate
              this.cbDeadlineDate = _tmp_cbDeadlineDate
              this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
              this.cbGracePeriodDate = _tmp_cbGracePeriodDate
              this.cbMaxPoints = _tmp_cbMaxPoints
              this.cbMinPoints = _tmp_cbMinPoints
              this.cbIndex = _tmp_cbIndex
              this.cbClazzUid = _tmp_cbClazzUid
              this.cbActive = _tmp_cbActive
              this.cbHidden = _tmp_cbHidden
              this.cbEntityUid = _tmp_cbEntityUid
              this.cbLct = _tmp_cbLct
            }
          }
        }
      }
    }
  }

  public override suspend fun findEntryWithContainerByEntryId(entryUuid: Long):
      ContentEntryWithMostRecentContainer? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |            SELECT ContentEntry.*, Container.* FROM ContentEntry LEFT 
  |                JOIN Container ON Container.containerUid = (
  |                    SELECT containerUid FROM Container WHERE containerContentEntryUid =  ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1) 
  |            WHERE ContentEntry.contentEntryUid=?
  |            
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,entryUuid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        var _tmp_Container_nullCount = 0
        val _tmp_containerUid = _result.getLong("containerUid")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLct = _result.getLong("cntLct")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_fileSize = _result.getLong("fileSize")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_mimeType = _result.getString("mimeType")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_remarks = _result.getString("remarks")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
        ContentEntryWithMostRecentContainer().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
          if(!_tmp_Container_isAllNull) {
            this.container = Container().apply {
              this.containerUid = _tmp_containerUid
              this.cntLocalCsn = _tmp_cntLocalCsn
              this.cntMasterCsn = _tmp_cntMasterCsn
              this.cntLastModBy = _tmp_cntLastModBy
              this.cntLct = _tmp_cntLct
              this.fileSize = _tmp_fileSize
              this.containerContentEntryUid = _tmp_containerContentEntryUid
              this.cntLastModified = _tmp_cntLastModified
              this.mimeType = _tmp_mimeType
              this.remarks = _tmp_remarks
              this.mobileOptimized = _tmp_mobileOptimized
              this.cntNumEntries = _tmp_cntNumEntries
            }
          }
        }
      }
    }
  }

  public override fun findEntryWithContainerByEntryIdLive(entryUuid: Long):
      LiveData<ContentEntryWithMostRecentContainer?> = LiveDataImpl(_db, listOf("ContentEntry",
      "Container"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |            SELECT ContentEntry.*, Container.* FROM ContentEntry LEFT 
    |                JOIN Container ON Container.containerUid = (
    |                    SELECT containerUid FROM Container WHERE containerContentEntryUid =  ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1) 
    |            WHERE ContentEntry.contentEntryUid=?
    |            
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,entryUuid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          var _tmp_Container_nullCount = 0
          val _tmp_containerUid = _result.getLong("containerUid")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_cntLct = _result.getLong("cntLct")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_fileSize = _result.getLong("fileSize")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_cntLastModified = _result.getLong("cntLastModified")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_mimeType = _result.getString("mimeType")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_remarks = _result.getString("remarks")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
          if(_result.wasNull()) _tmp_Container_nullCount++
          val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
          ContentEntryWithMostRecentContainer().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
            if(!_tmp_Container_isAllNull) {
              this.container = Container().apply {
                this.containerUid = _tmp_containerUid
                this.cntLocalCsn = _tmp_cntLocalCsn
                this.cntMasterCsn = _tmp_cntMasterCsn
                this.cntLastModBy = _tmp_cntLastModBy
                this.cntLct = _tmp_cntLct
                this.fileSize = _tmp_fileSize
                this.containerContentEntryUid = _tmp_containerContentEntryUid
                this.cntLastModified = _tmp_cntLastModified
                this.mimeType = _tmp_mimeType
                this.remarks = _tmp_remarks
                this.mobileOptimized = _tmp_mobileOptimized
                this.cntNumEntries = _tmp_cntNumEntries
              }
            }
          }
        }
      }
    }
  }

  public override fun findBySourceUrl(sourceUrl: String): ContentEntry? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM ContentEntry WHERE sourceUrl = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setString(1,sourceUrl)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override suspend fun findTitleByUidAsync(contentEntryUid: Long): String? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT title FROM ContentEntry WHERE contentEntryUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }

  public override fun getChildrenByParentUid(parentUid: Long): DataSourceFactory<Int, ContentEntry>
      = object : DataSourceFactory<Int, ContentEntry>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<ContentEntry>> =
        LiveDataImpl(_db, listOf("ContentEntry", "ContentEntryParentChildJoin"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) LIMIT ? OFFSET ?"
          )) { _stmt -> 
        _stmt.setLong(1,parentUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
            val _tmp_title = _result.getString("title")
            val _tmp_description = _result.getString("description")
            val _tmp_entryId = _result.getString("entryId")
            val _tmp_author = _result.getString("author")
            val _tmp_publisher = _result.getString("publisher")
            val _tmp_licenseType = _result.getInt("licenseType")
            val _tmp_licenseName = _result.getString("licenseName")
            val _tmp_licenseUrl = _result.getString("licenseUrl")
            val _tmp_sourceUrl = _result.getString("sourceUrl")
            val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
            val _tmp_lastModified = _result.getLong("lastModified")
            val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
            val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
            val _tmp_contentFlags = _result.getInt("contentFlags")
            val _tmp_leaf = _result.getBoolean("leaf")
            val _tmp_publik = _result.getBoolean("publik")
            val _tmp_ceInactive = _result.getBoolean("ceInactive")
            val _tmp_completionCriteria = _result.getInt("completionCriteria")
            val _tmp_minScore = _result.getInt("minScore")
            val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
            val _tmp_contentOwner = _result.getLong("contentOwner")
            val _tmp_contentEntryLocalChangeSeqNum =
                _result.getLong("contentEntryLocalChangeSeqNum")
            val _tmp_contentEntryMasterChangeSeqNum =
                _result.getLong("contentEntryMasterChangeSeqNum")
            val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
            val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
            ContentEntry().apply {
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ContentEntry",
        "ContentEntryParentChildJoin"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) "
          )) { _stmt -> 
        _stmt.setLong(1,parentUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun getChildrenByParentAsync(parentUid: Long): List<ContentEntry> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentEntry.*
  |          FROM ContentEntryParentChildJoin
  |               JOIN ContentEntry 
  |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
  |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override suspend fun getContentJobItemParamsByParentUid(
    parentUid: Long,
    limit: Int,
    offset: Int,
  ): List<ContentEntryContentJobItemParams> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentEntry.contentEntryUid AS contentEntryUid, ContentEntry.leaf AS leaf, 
  |               COALESCE(Container.containerUid, 0) AS mostRecentContainerUid,
  |               COALESCE(Container.fileSize, 0) AS mostRecentContainerSize
  |          FROM ContentEntryParentChildJoin
  |               JOIN ContentEntry 
  |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
  |               LEFT JOIN Container
  |                    ON containerUid = 
  |                        (SELECT COALESCE((
  |                                SELECT Container.containerUid 
  |                                  FROM Container
  |                                 WHERE Container.containerContentEntryUid = ContentEntry.contentEntryUid
  |                              ORDER BY Container.cntLastModified DESC
  |                                 LIMIT 1),0))
  |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
  |         LIMIT ?
  |        OFFSET ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.setInt(2,limit)
    _stmt.setInt(3,offset)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_mostRecentContainerUid = _result.getLong("mostRecentContainerUid")
        val _tmp_mostRecentContainerSize = _result.getLong("mostRecentContainerSize")
        ContentEntryContentJobItemParams().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.leaf = _tmp_leaf
          this.mostRecentContainerUid = _tmp_mostRecentContainerUid
          this.mostRecentContainerSize = _tmp_mostRecentContainerSize
        }
      }
    }
  }

  public override suspend fun getCountNumberOfChildrenByParentUUidAsync(parentUid: Long): Int =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT COUNT(*) FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  public override suspend fun getContentByUuidAsync(parentUid: Long): ContentEntry? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1"
      )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override suspend fun findAllLanguageRelatedEntriesAsync(entryUuid: Long):
      List<ContentEntry> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryRelatedEntryJoin ON ContentEntryRelatedEntryJoin.cerejRelatedEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryRelatedEntryJoin.relType = 1 AND ContentEntryRelatedEntryJoin.cerejRelatedEntryUid != ?"
      )) { _stmt -> 
    _stmt.setLong(1,entryUuid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override suspend fun findListOfCategoriesAsync(parentUid: Long):
      List<DistinctCategorySchema> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT DISTINCT ContentCategory.contentCategoryUid, ContentCategory.name AS categoryName, ContentCategorySchema.contentCategorySchemaUid, ContentCategorySchema.schemaName FROM ContentEntry LEFT JOIN ContentEntryContentCategoryJoin ON ContentEntryContentCategoryJoin.ceccjContentEntryUid = ContentEntry.contentEntryUid LEFT JOIN ContentCategory ON ContentCategory.contentCategoryUid = ContentEntryContentCategoryJoin.ceccjContentCategoryUid LEFT JOIN ContentCategorySchema ON ContentCategorySchema.contentCategorySchemaUid = ContentCategory.ctnCatContentCategorySchemaUid LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? AND ContentCategory.contentCategoryUid != 0 ORDER BY ContentCategory.name"
      )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentCategoryUid = _result.getLong("contentCategoryUid")
        val _tmp_categoryName = _result.getString("categoryName")
        val _tmp_contentCategorySchemaUid = _result.getLong("contentCategorySchemaUid")
        val _tmp_schemaName = _result.getString("schemaName")
        DistinctCategorySchema().apply {
          this.contentCategoryUid = _tmp_contentCategoryUid
          this.categoryName = _tmp_categoryName
          this.contentCategorySchemaUid = _tmp_contentCategorySchemaUid
          this.schemaName = _tmp_schemaName
        }
      }
    }
  }

  public override suspend fun findUniqueLanguagesInListAsync(parentUid: Long): List<Language> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT DISTINCT Language.* from Language LEFT JOIN ContentEntry ON ContentEntry.primaryLanguageUid = Language.langUid LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? ORDER BY Language.name"
      )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  public override suspend fun findUniqueLanguageWithParentUid(parentUid: Long): List<LangUidAndName>
      = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT DISTINCT Language.langUid, Language.name AS langName from Language
  |        LEFT JOIN ContentEntry ON ContentEntry.primaryLanguageUid = Language.langUid
  |        LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
  |        WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? ORDER BY Language.name
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_langName = _result.getString("langName")
        LangUidAndName().apply {
          this.langUid = _tmp_langUid
          this.langName = _tmp_langName
        }
      }
    }
  }

  public override suspend fun findByUidAsync(entryUid: Long): ContentEntry? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ContentEntry WHERE contentEntryUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,entryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override suspend fun findByUidWithLanguageAsync(uid: Long): ContentEntryWithLanguage? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentEntry.*, Language.*
  |          FROM ContentEntry
  |               LEFT JOIN Language 
  |                      ON Language.langUid = ContentEntry.primaryLanguageUid 
  |         WHERE ContentEntry.contentEntryUid = ?              
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        ContentEntryWithLanguage().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
          if(!_tmp_Language_isAllNull) {
            this.language = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
        }
      }
    }
  }

  public override fun findByUid(entryUid: Long): ContentEntry? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM ContentEntry WHERE contentEntryUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,entryUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override fun findByTitle(title: String): LiveData<ContentEntry?> = LiveDataImpl(_db,
      listOf("ContentEntry"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ContentEntry WHERE title = ?"
        )) { _stmt -> 
      _stmt.setString(1,title)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          ContentEntry().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
          }
        }
      }
    }
  }

  public override suspend fun isMeteredAllowedForEntry(contentEntryUid: Long): Boolean =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |       SELECT COALESCE((SELECT CAST(cjIsMeteredAllowed AS INTEGER) 
  |                FROM ContentJobItem 
  |                JOIN ContentJob
  |                    ON ContentJobItem.cjiJobUid = ContentJob.cjUid
  |               WHERE cjiContentEntryUid = ?
  |                AND cjiRecursiveStatus >= 4
  |                AND cjiRecursiveStatus <= 20 LIMIT 1),
  |                CAST(((SELECT connectivityState
  |                        FROM ConnectivityStatus
  |                       LIMIT 1) = 3) AS INTEGER),
  |                0) AS Status
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override suspend fun findBySourceUrlWithContentEntryStatusAsync(sourceUrl: String):
      ContentEntry? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT ContentEntry.* FROM ContentEntry WHERE ContentEntry.sourceUrl = ?"
      )) { _stmt -> 
    _stmt.setString(1,sourceUrl)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override fun getChildrenByParentUidWithCategoryFilterOrderByName(
    parentUid: Long,
    langParam: Long,
    categoryParam0: Long,
    personUid: Long,
    showHidden: Boolean,
    onlyFolder: Boolean,
    sortOrder: Int,
  ): DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> = object
      : DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
        LiveDataImpl(_db, listOf("ContentEntry", "ContentEntryParentChildJoin", "StatementEntity",
        "Container", "ContentEntryContentCategoryJoin"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
      |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                0 AS assignmentContentWeight,
      |                
      |                1 as totalContent, 
      |                
      |                0 as penalty
      |            FROM ContentEntry 
      |                    LEFT JOIN ContentEntryParentChildJoin 
      |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                    
      |                    LEFT JOIN StatementEntity
      |							ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
      |                    
      |                    LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)
      |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
      |            AND (? = 0 OR ContentEntry.primaryLanguageUid = ?) 
      |            AND (NOT ContentEntry.ceInactive OR ContentEntry.ceInactive = ?) 
      |            AND (NOT ContentEntry.leaf OR NOT ContentEntry.leaf = ?) 
      |            AND (ContentEntry.publik 
      |                 OR (SELECT username
      |                        FROM Person
      |                       WHERE personUid = ?) IS NOT NULL) 
      |            AND 
      |            (? = 0 OR ? 
      |                IN (SELECT ceccjContentCategoryUid 
      |                      FROM ContentEntryContentCategoryJoin 
      |                     WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
      |            ORDER BY ContentEntryParentChildJoin.childIndex,
      |                     CASE(?)
      |                     WHEN 1 THEN ContentEntry.title
      |                     ELSE ''
      |                     END ASC,
      |                     CASE(?)
      |                     WHEN 2 THEN ContentEntry.title
      |                     ELSE ''
      |                     END DESC,             
      |                     ContentEntry.contentEntryUid) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,parentUid)
        _stmt.setLong(3,langParam)
        _stmt.setLong(4,langParam)
        _stmt.setBoolean(5,showHidden)
        _stmt.setBoolean(6,onlyFolder)
        _stmt.setLong(7,personUid)
        _stmt.setLong(8,categoryParam0)
        _stmt.setLong(9,categoryParam0)
        _stmt.setInt(10,sortOrder)
        _stmt.setInt(11,sortOrder)
        _stmt.setInt(12,_limit)
        _stmt.setInt(13,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_assignmentContentWeight = _result.getInt("assignmentContentWeight")
            val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
            val _tmp_title = _result.getString("title")
            val _tmp_description = _result.getString("description")
            val _tmp_entryId = _result.getString("entryId")
            val _tmp_author = _result.getString("author")
            val _tmp_publisher = _result.getString("publisher")
            val _tmp_licenseType = _result.getInt("licenseType")
            val _tmp_licenseName = _result.getString("licenseName")
            val _tmp_licenseUrl = _result.getString("licenseUrl")
            val _tmp_sourceUrl = _result.getString("sourceUrl")
            val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
            val _tmp_lastModified = _result.getLong("lastModified")
            val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
            val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
            val _tmp_contentFlags = _result.getInt("contentFlags")
            val _tmp_leaf = _result.getBoolean("leaf")
            val _tmp_publik = _result.getBoolean("publik")
            val _tmp_ceInactive = _result.getBoolean("ceInactive")
            val _tmp_completionCriteria = _result.getInt("completionCriteria")
            val _tmp_minScore = _result.getInt("minScore")
            val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
            val _tmp_contentOwner = _result.getLong("contentOwner")
            val _tmp_contentEntryLocalChangeSeqNum =
                _result.getLong("contentEntryLocalChangeSeqNum")
            val _tmp_contentEntryMasterChangeSeqNum =
                _result.getLong("contentEntryMasterChangeSeqNum")
            val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
            val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
            var _tmp_Container_nullCount = 0
            val _tmp_containerUid = _result.getLong("containerUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLct = _result.getLong("cntLct")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_fileSize = _result.getLong("fileSize")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModified = _result.getLong("cntLastModified")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mimeType = _result.getString("mimeType")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_remarks = _result.getString("remarks")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
            var _tmp_ContentEntryParentChildJoin_nullCount = 0
            val _tmp_cepcjUid = _result.getLong("cepcjUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLct = _result.getLong("cepcjLct")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_childIndex = _result.getInt("childIndex")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
            var _tmp_ContentEntryStatementScoreProgress_nullCount = 0
            val _tmp_resultScore = _result.getInt("resultScore")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultMax = _result.getInt("resultMax")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultScaled = _result.getFloat("resultScaled")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultWeight = _result.getInt("resultWeight")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_contentComplete = _result.getBoolean("contentComplete")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_progress = _result.getInt("progress")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_success = _result.getByte("success")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_penalty = _result.getInt("penalty")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalContent = _result.getInt("totalContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_ContentEntryStatementScoreProgress_isAllNull = _tmp_ContentEntryStatementScoreProgress_nullCount == 10
            ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer().apply {
              this.assignmentContentWeight = _tmp_assignmentContentWeight
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
              if(!_tmp_Container_isAllNull) {
                this.mostRecentContainer = Container().apply {
                  this.containerUid = _tmp_containerUid
                  this.cntLocalCsn = _tmp_cntLocalCsn
                  this.cntMasterCsn = _tmp_cntMasterCsn
                  this.cntLastModBy = _tmp_cntLastModBy
                  this.cntLct = _tmp_cntLct
                  this.fileSize = _tmp_fileSize
                  this.containerContentEntryUid = _tmp_containerContentEntryUid
                  this.cntLastModified = _tmp_cntLastModified
                  this.mimeType = _tmp_mimeType
                  this.remarks = _tmp_remarks
                  this.mobileOptimized = _tmp_mobileOptimized
                  this.cntNumEntries = _tmp_cntNumEntries
                }
              }
              if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
                this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                  this.cepcjUid = _tmp_cepcjUid
                  this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                  this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                  this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                  this.cepcjLct = _tmp_cepcjLct
                  this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                  this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                  this.childIndex = _tmp_childIndex
                }
              }
              if(!_tmp_ContentEntryStatementScoreProgress_isAllNull) {
                this.scoreProgress = ContentEntryStatementScoreProgress().apply {
                  this.resultScore = _tmp_resultScore
                  this.resultMax = _tmp_resultMax
                  this.resultScaled = _tmp_resultScaled
                  this.resultWeight = _tmp_resultWeight
                  this.contentComplete = _tmp_contentComplete
                  this.progress = _tmp_progress
                  this.success = _tmp_success
                  this.penalty = _tmp_penalty
                  this.totalContent = _tmp_totalContent
                  this.totalCompletedContent = _tmp_totalCompletedContent
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ContentEntry",
        "ContentEntryParentChildJoin", "StatementEntity", "Container",
        "ContentEntryContentCategoryJoin"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
      |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                0 AS assignmentContentWeight,
      |                
      |                1 as totalContent, 
      |                
      |                0 as penalty
      |            FROM ContentEntry 
      |                    LEFT JOIN ContentEntryParentChildJoin 
      |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                    
      |                    LEFT JOIN StatementEntity
      |							ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
      |                    
      |                    LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)
      |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
      |            AND (? = 0 OR ContentEntry.primaryLanguageUid = ?) 
      |            AND (NOT ContentEntry.ceInactive OR ContentEntry.ceInactive = ?) 
      |            AND (NOT ContentEntry.leaf OR NOT ContentEntry.leaf = ?) 
      |            AND (ContentEntry.publik 
      |                 OR (SELECT username
      |                        FROM Person
      |                       WHERE personUid = ?) IS NOT NULL) 
      |            AND 
      |            (? = 0 OR ? 
      |                IN (SELECT ceccjContentCategoryUid 
      |                      FROM ContentEntryContentCategoryJoin 
      |                     WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
      |            ORDER BY ContentEntryParentChildJoin.childIndex,
      |                     CASE(?)
      |                     WHEN 1 THEN ContentEntry.title
      |                     ELSE ''
      |                     END ASC,
      |                     CASE(?)
      |                     WHEN 2 THEN ContentEntry.title
      |                     ELSE ''
      |                     END DESC,             
      |                     ContentEntry.contentEntryUid) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,parentUid)
        _stmt.setLong(3,langParam)
        _stmt.setLong(4,langParam)
        _stmt.setBoolean(5,showHidden)
        _stmt.setBoolean(6,onlyFolder)
        _stmt.setLong(7,personUid)
        _stmt.setLong(8,categoryParam0)
        _stmt.setLong(9,categoryParam0)
        _stmt.setInt(10,sortOrder)
        _stmt.setInt(11,sortOrder)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun getContentFromMyCourses(personUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> =
      object :
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
        LiveDataImpl(_db, listOf("CourseBlock", "ContentEntry", "ContentEntryParentChildJoin",
        "StatementEntity", "Container", "ClazzEnrolment"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
      |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                0 AS assignmentContentWeight,
      |                
      |                1 as totalContent, 
      |                
      |                0 as penalty
      |          FROM CourseBlock
      |               JOIN ContentEntry 
      |                    ON CourseBlock.cbType = 104
      |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |               LEFT JOIN ContentEntryParentChildJoin 
      |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
      |               LEFT JOIN StatementEntity
      |							ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
      |               LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)  
      |                               
      |         WHERE CourseBlock.cbClazzUid IN
      |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
      |                  FROM ClazzEnrolment
      |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_assignmentContentWeight = _result.getInt("assignmentContentWeight")
            val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
            val _tmp_title = _result.getString("title")
            val _tmp_description = _result.getString("description")
            val _tmp_entryId = _result.getString("entryId")
            val _tmp_author = _result.getString("author")
            val _tmp_publisher = _result.getString("publisher")
            val _tmp_licenseType = _result.getInt("licenseType")
            val _tmp_licenseName = _result.getString("licenseName")
            val _tmp_licenseUrl = _result.getString("licenseUrl")
            val _tmp_sourceUrl = _result.getString("sourceUrl")
            val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
            val _tmp_lastModified = _result.getLong("lastModified")
            val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
            val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
            val _tmp_contentFlags = _result.getInt("contentFlags")
            val _tmp_leaf = _result.getBoolean("leaf")
            val _tmp_publik = _result.getBoolean("publik")
            val _tmp_ceInactive = _result.getBoolean("ceInactive")
            val _tmp_completionCriteria = _result.getInt("completionCriteria")
            val _tmp_minScore = _result.getInt("minScore")
            val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
            val _tmp_contentOwner = _result.getLong("contentOwner")
            val _tmp_contentEntryLocalChangeSeqNum =
                _result.getLong("contentEntryLocalChangeSeqNum")
            val _tmp_contentEntryMasterChangeSeqNum =
                _result.getLong("contentEntryMasterChangeSeqNum")
            val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
            val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
            var _tmp_Container_nullCount = 0
            val _tmp_containerUid = _result.getLong("containerUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLct = _result.getLong("cntLct")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_fileSize = _result.getLong("fileSize")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModified = _result.getLong("cntLastModified")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mimeType = _result.getString("mimeType")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_remarks = _result.getString("remarks")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
            var _tmp_ContentEntryParentChildJoin_nullCount = 0
            val _tmp_cepcjUid = _result.getLong("cepcjUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLct = _result.getLong("cepcjLct")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_childIndex = _result.getInt("childIndex")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
            var _tmp_ContentEntryStatementScoreProgress_nullCount = 0
            val _tmp_resultScore = _result.getInt("resultScore")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultMax = _result.getInt("resultMax")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultScaled = _result.getFloat("resultScaled")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultWeight = _result.getInt("resultWeight")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_contentComplete = _result.getBoolean("contentComplete")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_progress = _result.getInt("progress")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_success = _result.getByte("success")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_penalty = _result.getInt("penalty")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalContent = _result.getInt("totalContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_ContentEntryStatementScoreProgress_isAllNull = _tmp_ContentEntryStatementScoreProgress_nullCount == 10
            ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer().apply {
              this.assignmentContentWeight = _tmp_assignmentContentWeight
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
              if(!_tmp_Container_isAllNull) {
                this.mostRecentContainer = Container().apply {
                  this.containerUid = _tmp_containerUid
                  this.cntLocalCsn = _tmp_cntLocalCsn
                  this.cntMasterCsn = _tmp_cntMasterCsn
                  this.cntLastModBy = _tmp_cntLastModBy
                  this.cntLct = _tmp_cntLct
                  this.fileSize = _tmp_fileSize
                  this.containerContentEntryUid = _tmp_containerContentEntryUid
                  this.cntLastModified = _tmp_cntLastModified
                  this.mimeType = _tmp_mimeType
                  this.remarks = _tmp_remarks
                  this.mobileOptimized = _tmp_mobileOptimized
                  this.cntNumEntries = _tmp_cntNumEntries
                }
              }
              if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
                this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                  this.cepcjUid = _tmp_cepcjUid
                  this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                  this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                  this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                  this.cepcjLct = _tmp_cepcjLct
                  this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                  this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                  this.childIndex = _tmp_childIndex
                }
              }
              if(!_tmp_ContentEntryStatementScoreProgress_isAllNull) {
                this.scoreProgress = ContentEntryStatementScoreProgress().apply {
                  this.resultScore = _tmp_resultScore
                  this.resultMax = _tmp_resultMax
                  this.resultScaled = _tmp_resultScaled
                  this.resultWeight = _tmp_resultWeight
                  this.contentComplete = _tmp_contentComplete
                  this.progress = _tmp_progress
                  this.success = _tmp_success
                  this.penalty = _tmp_penalty
                  this.totalContent = _tmp_totalContent
                  this.totalCompletedContent = _tmp_totalCompletedContent
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("CourseBlock",
        "ContentEntry", "ContentEntryParentChildJoin", "StatementEntity", "Container",
        "ClazzEnrolment"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
      |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                0 AS assignmentContentWeight,
      |                
      |                1 as totalContent, 
      |                
      |                0 as penalty
      |          FROM CourseBlock
      |               JOIN ContentEntry 
      |                    ON CourseBlock.cbType = 104
      |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |               LEFT JOIN ContentEntryParentChildJoin 
      |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
      |               LEFT JOIN StatementEntity
      |							ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
      |               LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)  
      |                               
      |         WHERE CourseBlock.cbClazzUid IN
      |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
      |                  FROM ClazzEnrolment
      |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun getContentByOwner(personUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> =
      object :
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
        LiveDataImpl(_db, listOf("ContentEntry", "ContentEntryParentChildJoin", "StatementEntity",
        "Container"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
      |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                0 AS assignmentContentWeight,
      |                
      |                1 as totalContent, 
      |                
      |                0 as penalty
      |          FROM ContentEntry
      |               LEFT JOIN ContentEntryParentChildJoin 
      |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
      |               LEFT JOIN StatementEntity
      |							ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
      |               LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)  
      |         WHERE ContentEntry.contentOwner = ?
      |           AND NOT EXISTS(
      |               SELECT ContentEntryParentChildJoin.cepcjUid 
      |                 FROM ContentEntryParentChildJoin
      |                WHERE ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid)
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_assignmentContentWeight = _result.getInt("assignmentContentWeight")
            val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
            val _tmp_title = _result.getString("title")
            val _tmp_description = _result.getString("description")
            val _tmp_entryId = _result.getString("entryId")
            val _tmp_author = _result.getString("author")
            val _tmp_publisher = _result.getString("publisher")
            val _tmp_licenseType = _result.getInt("licenseType")
            val _tmp_licenseName = _result.getString("licenseName")
            val _tmp_licenseUrl = _result.getString("licenseUrl")
            val _tmp_sourceUrl = _result.getString("sourceUrl")
            val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
            val _tmp_lastModified = _result.getLong("lastModified")
            val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
            val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
            val _tmp_contentFlags = _result.getInt("contentFlags")
            val _tmp_leaf = _result.getBoolean("leaf")
            val _tmp_publik = _result.getBoolean("publik")
            val _tmp_ceInactive = _result.getBoolean("ceInactive")
            val _tmp_completionCriteria = _result.getInt("completionCriteria")
            val _tmp_minScore = _result.getInt("minScore")
            val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
            val _tmp_contentOwner = _result.getLong("contentOwner")
            val _tmp_contentEntryLocalChangeSeqNum =
                _result.getLong("contentEntryLocalChangeSeqNum")
            val _tmp_contentEntryMasterChangeSeqNum =
                _result.getLong("contentEntryMasterChangeSeqNum")
            val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
            val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
            var _tmp_Container_nullCount = 0
            val _tmp_containerUid = _result.getLong("containerUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLct = _result.getLong("cntLct")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_fileSize = _result.getLong("fileSize")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModified = _result.getLong("cntLastModified")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mimeType = _result.getString("mimeType")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_remarks = _result.getString("remarks")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
            var _tmp_ContentEntryParentChildJoin_nullCount = 0
            val _tmp_cepcjUid = _result.getLong("cepcjUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLct = _result.getLong("cepcjLct")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_childIndex = _result.getInt("childIndex")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
            var _tmp_ContentEntryStatementScoreProgress_nullCount = 0
            val _tmp_resultScore = _result.getInt("resultScore")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultMax = _result.getInt("resultMax")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultScaled = _result.getFloat("resultScaled")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultWeight = _result.getInt("resultWeight")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_contentComplete = _result.getBoolean("contentComplete")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_progress = _result.getInt("progress")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_success = _result.getByte("success")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_penalty = _result.getInt("penalty")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalContent = _result.getInt("totalContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_ContentEntryStatementScoreProgress_isAllNull = _tmp_ContentEntryStatementScoreProgress_nullCount == 10
            ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer().apply {
              this.assignmentContentWeight = _tmp_assignmentContentWeight
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
              if(!_tmp_Container_isAllNull) {
                this.mostRecentContainer = Container().apply {
                  this.containerUid = _tmp_containerUid
                  this.cntLocalCsn = _tmp_cntLocalCsn
                  this.cntMasterCsn = _tmp_cntMasterCsn
                  this.cntLastModBy = _tmp_cntLastModBy
                  this.cntLct = _tmp_cntLct
                  this.fileSize = _tmp_fileSize
                  this.containerContentEntryUid = _tmp_containerContentEntryUid
                  this.cntLastModified = _tmp_cntLastModified
                  this.mimeType = _tmp_mimeType
                  this.remarks = _tmp_remarks
                  this.mobileOptimized = _tmp_mobileOptimized
                  this.cntNumEntries = _tmp_cntNumEntries
                }
              }
              if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
                this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                  this.cepcjUid = _tmp_cepcjUid
                  this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                  this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                  this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                  this.cepcjLct = _tmp_cepcjLct
                  this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                  this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                  this.childIndex = _tmp_childIndex
                }
              }
              if(!_tmp_ContentEntryStatementScoreProgress_isAllNull) {
                this.scoreProgress = ContentEntryStatementScoreProgress().apply {
                  this.resultScore = _tmp_resultScore
                  this.resultMax = _tmp_resultMax
                  this.resultScaled = _tmp_resultScaled
                  this.resultWeight = _tmp_resultWeight
                  this.contentComplete = _tmp_contentComplete
                  this.progress = _tmp_progress
                  this.success = _tmp_success
                  this.penalty = _tmp_penalty
                  this.totalContent = _tmp_totalContent
                  this.totalCompletedContent = _tmp_totalCompletedContent
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ContentEntry",
        "ContentEntryParentChildJoin", "StatementEntity", "Container"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
      |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                0 AS assignmentContentWeight,
      |                
      |                1 as totalContent, 
      |                
      |                0 as penalty
      |          FROM ContentEntry
      |               LEFT JOIN ContentEntryParentChildJoin 
      |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
      |               LEFT JOIN StatementEntity
      |							ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
      |               LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)  
      |         WHERE ContentEntry.contentOwner = ?
      |           AND NOT EXISTS(
      |               SELECT ContentEntryParentChildJoin.cepcjUid 
      |                 FROM ContentEntryParentChildJoin
      |                WHERE ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid)
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,personUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun getChildrenByAll(parentUid: Long): List<ContentEntry> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override fun findLiveContentEntry(parentUid: Long): LiveData<ContentEntry?> =
      LiveDataImpl(_db, listOf("ContentEntry"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1"
        )) { _stmt -> 
      _stmt.setLong(1,parentUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          ContentEntry().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
          }
        }
      }
    }
  }

  public override fun getContentEntryUidFromXapiObjectId(objectId: String): Long =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |SELECT COALESCE((SELECT contentEntryUid 
  |                                      FROM ContentEntry 
  |                                     WHERE entryId = ? 
  |                                     LIMIT 1),0) AS ID
  """.trimMargin() )) { _stmt -> 
    _stmt.setString(1,objectId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override fun findSimilarIdEntryForKhan(sourceUrl: String): List<ContentEntry> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM ContentEntry WHERE sourceUrl LIKE ?"
      )) { _stmt -> 
    _stmt.setString(1,sourceUrl)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override suspend fun getRecursiveDownloadTotals(contentEntryUid: Long):
      DownloadJobSizeInfo? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        WITH RECURSIVE 
  |               ContentEntry_recursive(contentEntryUid, containerSize) AS (
  |               SELECT contentEntryUid, 
  |                            (SELECT COALESCE((SELECT fileSize 
  |                                           FROM Container 
  |                                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
  |                                       ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize 
  |                 FROM ContentEntry 
  |                WHERE contentEntryUid = ?
  |                  AND NOT ceInactive
  |        UNION 
  |            SELECT ContentEntry.contentEntryUid, 
  |                (SELECT COALESCE((SELECT fileSize 
  |                                    FROM Container 
  |                                   WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
  |                                ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize  
  |                  FROM ContentEntry
  |             LEFT JOIN ContentEntryParentChildJoin 
  |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid,
  |                            ContentEntry_recursive
  |                  WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid
  |                    AND NOT ceInactive)
  |        SELECT COUNT(*) AS numEntries, 
  |               SUM(containerSize) AS totalSize 
  |          FROM ContentEntry_recursive
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_numEntries = _result.getInt("numEntries")
        val _tmp_totalSize = _result.getLong("totalSize")
        DownloadJobSizeInfo().apply {
          this.numEntries = _tmp_numEntries
          this.totalSize = _tmp_totalSize
        }
      }
    }
  }

  public override fun getAllEntriesRecursively(contentEntryUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndMostRecentContainer> = object :
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndMostRecentContainer>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ContentEntryWithParentChildJoinAndMostRecentContainer>> = LiveDataImpl(_db,
        listOf("ContentEntry", "ContentEntryParentChildJoin", "Container"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (WITH RECURSIVE ContentEntry_recursive(
      |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
      |            
      |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
      |            
      |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
      |            ) AS (
      |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
      |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
      |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
      |            FROM 
      |            ContentEntry
      |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
      |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
      |            WHERE ContentEntry.contentEntryUid = ?
      |            UNION
      |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
      |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
      |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
      |            FROM 
      |            ContentEntry
      |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
      |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
      |            ContentEntry_recursive
      |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
      |            SELECT * FROM ContentEntry_recursive) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,contentEntryUid)
        _stmt.setInt(2,_limit)
        _stmt.setInt(3,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
            val _tmp_title = _result.getString("title")
            val _tmp_description = _result.getString("description")
            val _tmp_entryId = _result.getString("entryId")
            val _tmp_author = _result.getString("author")
            val _tmp_publisher = _result.getString("publisher")
            val _tmp_licenseType = _result.getInt("licenseType")
            val _tmp_licenseName = _result.getString("licenseName")
            val _tmp_licenseUrl = _result.getString("licenseUrl")
            val _tmp_sourceUrl = _result.getString("sourceUrl")
            val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
            val _tmp_lastModified = _result.getLong("lastModified")
            val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
            val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
            val _tmp_contentFlags = _result.getInt("contentFlags")
            val _tmp_leaf = _result.getBoolean("leaf")
            val _tmp_publik = _result.getBoolean("publik")
            val _tmp_ceInactive = _result.getBoolean("ceInactive")
            val _tmp_completionCriteria = _result.getInt("completionCriteria")
            val _tmp_minScore = _result.getInt("minScore")
            val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
            val _tmp_contentOwner = _result.getLong("contentOwner")
            val _tmp_contentEntryLocalChangeSeqNum =
                _result.getLong("contentEntryLocalChangeSeqNum")
            val _tmp_contentEntryMasterChangeSeqNum =
                _result.getLong("contentEntryMasterChangeSeqNum")
            val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
            val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
            var _tmp_Container_nullCount = 0
            val _tmp_containerUid = _result.getLong("containerUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLct = _result.getLong("cntLct")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_fileSize = _result.getLong("fileSize")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModified = _result.getLong("cntLastModified")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mimeType = _result.getString("mimeType")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_remarks = _result.getString("remarks")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
            var _tmp_ContentEntryParentChildJoin_nullCount = 0
            val _tmp_cepcjUid = _result.getLong("cepcjUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLct = _result.getLong("cepcjLct")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_childIndex = _result.getInt("childIndex")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
            ContentEntryWithParentChildJoinAndMostRecentContainer().apply {
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
              if(!_tmp_Container_isAllNull) {
                this.mostRecentContainer = Container().apply {
                  this.containerUid = _tmp_containerUid
                  this.cntLocalCsn = _tmp_cntLocalCsn
                  this.cntMasterCsn = _tmp_cntMasterCsn
                  this.cntLastModBy = _tmp_cntLastModBy
                  this.cntLct = _tmp_cntLct
                  this.fileSize = _tmp_fileSize
                  this.containerContentEntryUid = _tmp_containerContentEntryUid
                  this.cntLastModified = _tmp_cntLastModified
                  this.mimeType = _tmp_mimeType
                  this.remarks = _tmp_remarks
                  this.mobileOptimized = _tmp_mobileOptimized
                  this.cntNumEntries = _tmp_cntNumEntries
                }
              }
              if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
                this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                  this.cepcjUid = _tmp_cepcjUid
                  this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                  this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                  this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                  this.cepcjLct = _tmp_cepcjLct
                  this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                  this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                  this.childIndex = _tmp_childIndex
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ContentEntry",
        "ContentEntryParentChildJoin", "Container"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (WITH RECURSIVE ContentEntry_recursive(
      |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
      |            
      |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
      |            
      |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
      |            ) AS (
      |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
      |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
      |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
      |            FROM 
      |            ContentEntry
      |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
      |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
      |            WHERE ContentEntry.contentEntryUid = ?
      |            UNION
      |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
      |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
      |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
      |            FROM 
      |            ContentEntry
      |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
      |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
      |            ContentEntry_recursive
      |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
      |            SELECT * FROM ContentEntry_recursive) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,contentEntryUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun getAllEntriesRecursivelyAsList(contentEntryUid: Long):
      List<ContentEntryWithParentChildJoinAndMostRecentContainer> =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |WITH RECURSIVE ContentEntry_recursive(
  |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
  |            
  |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
  |            
  |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
  |            ) AS (
  |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
  |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
  |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
  |            FROM 
  |            ContentEntry
  |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
  |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
  |            WHERE ContentEntry.contentEntryUid = ?
  |            UNION
  |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
  |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
  |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
  |            FROM 
  |            ContentEntry
  |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
  |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
  |            ContentEntry_recursive
  |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
  |            SELECT * FROM ContentEntry_recursive
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        var _tmp_Container_nullCount = 0
        val _tmp_containerUid = _result.getLong("containerUid")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLct = _result.getLong("cntLct")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_fileSize = _result.getLong("fileSize")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_mimeType = _result.getString("mimeType")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_remarks = _result.getString("remarks")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
        var _tmp_ContentEntryParentChildJoin_nullCount = 0
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_childIndex = _result.getInt("childIndex")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
        ContentEntryWithParentChildJoinAndMostRecentContainer().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
          if(!_tmp_Container_isAllNull) {
            this.mostRecentContainer = Container().apply {
              this.containerUid = _tmp_containerUid
              this.cntLocalCsn = _tmp_cntLocalCsn
              this.cntMasterCsn = _tmp_cntMasterCsn
              this.cntLastModBy = _tmp_cntLastModBy
              this.cntLct = _tmp_cntLct
              this.fileSize = _tmp_fileSize
              this.containerContentEntryUid = _tmp_containerContentEntryUid
              this.cntLastModified = _tmp_cntLastModified
              this.mimeType = _tmp_mimeType
              this.remarks = _tmp_remarks
              this.mobileOptimized = _tmp_mobileOptimized
              this.cntNumEntries = _tmp_cntNumEntries
            }
          }
          if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
            this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
              this.cepcjUid = _tmp_cepcjUid
              this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
              this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
              this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
              this.cepcjLct = _tmp_cepcjLct
              this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
              this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
              this.childIndex = _tmp_childIndex
            }
          }
        }
      }
    }
  }

  public override fun updateContentEntryInActive(
    contentEntryUid: Long,
    ceInactive: Boolean,
    changedTime: Long,
  ): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("""
    |
    |            UPDATE ContentEntry 
    |               SET ceInactive = ?,
    |                   contentEntryLct = ?        
    |            WHERE ContentEntry.contentEntryUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,ceInactive)
      _stmt.setLong(2,changedTime)
      _stmt.setLong(3,contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  public override fun updateContentEntryContentFlag(
    contentFlag: Int,
    contentEntryUid: Long,
    changedTime: Long,
  ): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("""
    |
    |        UPDATE ContentEntry 
    |           SET contentTypeFlag = ?,
    |               contentEntryLct = ? 
    |         WHERE ContentEntry.contentEntryUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setInt(1,contentFlag)
      _stmt.setLong(2,changedTime)
      _stmt.setLong(3,contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun getContentEntryFromUids(contentEntryUids: List<Long>):
      List<UidAndLabel> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |Select ContentEntry.contentEntryUid AS uid, ContentEntry.title As labelName 
  |                    from ContentEntry WHERE contentEntryUid IN (?)
  """.trimMargin() ,hasListParams = true)) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        contentEntryUids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_uid = _result.getLong("uid")
        val _tmp_labelName = _result.getString("labelName")
        UidAndLabel().apply {
          this.uid = _tmp_uid
          this.labelName = _tmp_labelName
        }
      }
    }
  }

  public override fun findAllLive(): LiveData<List<ContentEntryWithLanguage>> = LiveDataImpl(_db,
      listOf("ContentEntry", "Language"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT ContentEntry.*, Language.* FROM ContentEntry LEFT JOIN Language ON Language.langUid = ContentEntry.primaryLanguageUid"
        )) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          var _tmp_Language_nullCount = 0
          val _tmp_langUid = _result.getLong("langUid")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_name = _result.getString("name")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_Language_Type = _result.getString("Language_Type")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_languageActive = _result.getBoolean("languageActive")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langLct = _result.getLong("langLct")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
          ContentEntryWithLanguage().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
            if(!_tmp_Language_isAllNull) {
              this.language = Language().apply {
                this.langUid = _tmp_langUid
                this.name = _tmp_name
                this.iso_639_1_standard = _tmp_iso_639_1_standard
                this.iso_639_2_standard = _tmp_iso_639_2_standard
                this.iso_639_3_standard = _tmp_iso_639_3_standard
                this.Language_Type = _tmp_Language_Type
                this.languageActive = _tmp_languageActive
                this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
                this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
                this.langLastChangedBy = _tmp_langLastChangedBy
                this.langLct = _tmp_langLct
              }
            }
          }
        }
      }
    }
  }

  public override suspend fun personHasPermissionWithContentEntry(
    accountPersonUid: Long,
    contentEntryUid: Long,
    permission: Long,
  ): Boolean = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT EXISTS(SELECT 1 FROM ContentEntry WHERE ContentEntry.contentEntryUid = ? AND ? IN (
  |            SELECT DISTINCT Person.PersonUid FROM Person
  |            LEFT JOIN PersonGroupMember ON Person.personUid = PersonGroupMember.groupMemberPersonUid
  |            LEFT JOIN EntityRole ON EntityRole.erGroupUid = PersonGroupMember.groupMemberGroupUid
  |            LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid
  |            WHERE 
  |            CAST(Person.admin AS INTEGER) = 1
  |            OR 
  |            (EntityRole.ertableId = 42 AND 
  |            EntityRole.erEntityUid = ContentEntry.contentEntryUid AND
  |            (Role.rolePermissions &  
  |         ? ) > 0)))
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.setLong(2,accountPersonUid)
    _stmt.setLong(3,permission)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  public override suspend fun updateContentEntryActiveByContentJobUid(
    jobId: Long,
    inactive: Boolean,
    changedTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentEntry
    |           SET ceInactive = ?,
    |               contentEntryLct = ?
    |         WHERE contentEntryUid IN 
    |               (SELECT cjiContentEntryUid 
    |                  FROM ContentJobItem
    |                 WHERE cjiJobUid = ?
    |                   AND CAST(ContentJobItem.cjiContentDeletedOnCancellation AS INTEGER) = 1)
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,inactive)
      _stmt.setLong(2,changedTime)
      _stmt.setLong(3,jobId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun toggleVisibilityContentEntryItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentEntry 
    |           SET ceInactive = ?, 
    |               contentEntryLct = ? 
    |         WHERE contentEntryUid IN (?)
    """.trimMargin() ,hasListParams = true)) { _stmt -> 
      _stmt.setBoolean(1,toggleVisibility)
      _stmt.setLong(2,changedTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedItem.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend
      fun findContentEntriesWhereIsLeafAndLatestContainerHasNoEntriesOrHasZeroFileSize():
      List<ContentEntry> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |SELECT ContentEntry.*
  |  FROM ContentEntry
  |       JOIN Container ON Container.containerUid = 
  |       (SELECT containerUid 
  |          FROM Container
  |         WHERE Container.containercontententryUid = ContentEntry.contentEntryUid
  |           AND Container.cntLastModified = 
  |               (SELECT MAX(ContainerInternal.cntLastModified)
  |                  FROM Container ContainerInternal
  |                 WHERE ContainerInternal.containercontententryUid = ContentEntry.contentEntryUid))
  | WHERE ContentEntry.leaf 
  |   AND NOT ContentEntry.ceInactive
  |   AND (NOT EXISTS 
  |       (SELECT ContainerEntry.ceUid
  |          FROM ContainerEntry
  |         WHERE ContainerEntry.ceContainerUid = Container.containerUid)
  |        OR Container.fileSize = 0)   
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  public override suspend fun buttonsToShowForContentEntry(contentEntryUid: Long,
      platformDownloadEnabled: Boolean): ContentEntryButtonModel? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        WITH ContentEntryContainerUids AS 
  |             (SELECT Container.containerUid
  |                FROM Container
  |               WHERE Container.containerContentEntryUid = ?
  |                   AND Container.fileSize > 0),
  |                   
  |             
  |            LatestDownloadedContainer(containerUid) AS
  |             (SELECT COALESCE(
  |                     (SELECT containerUid
  |                        FROM Container
  |                       WHERE Container.containerContentEntryUid = ? 
  |                         AND EXISTS(
  |                             SELECT 1
  |                               FROM ContainerEntry
  |                              WHERE ContainerEntry.ceContainerUid = Container.containerUid)
  |                    ORDER BY cntLastModified DESC
  |                       LIMIT 1), 0))
  |        ,
  |                            
  |             
  |            ActiveContentJobItems(cjiRecursiveStatus, cjiPluginId) AS
  |             (SELECT cjiRecursiveStatus, cjiPluginId
  |                FROM ContentJobItem
  |               WHERE cjiContentEntryUid = ?
  |                 AND cjiStatus BETWEEN 4 AND 20)
  |        ,
  |                  
  |            ShowDownload(showDownload) AS 
  |            (SELECT CAST(? AS INTEGER) = 1
  |                AND (SELECT containerUid FROM LatestDownloadedContainer) = 0
  |                AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0
  |                AND (SELECT COUNT(*) FROM ContentEntryContainerUids) > 0)
  |                   
  |        SELECT (SELECT showDownload FROM ShowDownload)
  |               AS showDownloadButton,
  |        
  |               CAST(? AS INTEGER) = 0
  |               OR (SELECT containerUid FROM LatestDownloadedContainer) != 0          
  |               AS showOpenButton,
  |       
  |               (SELECT NOT showDownload FROM ShowDownload)
  |           AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0    
  |           AND (SELECT COALESCE(
  |                       (SELECT cntLastModified
  |                          FROM Container
  |                         WHERE containerContentEntryUid = ?
  |                           AND fileSize > 0
  |                      ORDER BY cntLastModified DESC), 0)) 
  |               > (SELECT COALESCE(
  |                         (SELECT cntLastModified
  |                            FROM Container
  |                           WHERE Container.containerUid = 
  |                                 (SELECT LatestDownloadedContainer.containerUid
  |                                    FROM LatestDownloadedContainer)), 0)) 
  |               AS showUpdateButton,
  |               
  |               CAST(? AS INTEGER) = 1
  |           AND (SELECT containerUid FROM LatestDownloadedContainer) != 0
  |           AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0    
  |               AS showDeleteButton,
  |               
  |               (SELECT COUNT(*) 
  |                  FROM ActiveContentJobItems 
  |                 WHERE cjiPluginId = 10) > 0
  |               AS showManageDownloadButton
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.setLong(2,contentEntryUid)
    _stmt.setLong(3,contentEntryUid)
    _stmt.setBoolean(4,platformDownloadEnabled)
    _stmt.setBoolean(5,platformDownloadEnabled)
    _stmt.setLong(6,contentEntryUid)
    _stmt.setBoolean(7,platformDownloadEnabled)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_showDownloadButton = _result.getBoolean("showDownloadButton")
        val _tmp_showOpenButton = _result.getBoolean("showOpenButton")
        val _tmp_showUpdateButton = _result.getBoolean("showUpdateButton")
        val _tmp_showDeleteButton = _result.getBoolean("showDeleteButton")
        val _tmp_showManageDownloadButton = _result.getBoolean("showManageDownloadButton")
        ContentEntryButtonModel().apply {
          this.showDownloadButton = _tmp_showDownloadButton
          this.showOpenButton = _tmp_showOpenButton
          this.showUpdateButton = _tmp_showUpdateButton
          this.showDeleteButton = _tmp_showDeleteButton
          this.showManageDownloadButton = _tmp_showManageDownloadButton
        }
      }
    }
  }

  public override suspend fun statusForDownloadDialog(contentEntryUid: Long): Int =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentJobItem.cjiRecursiveStatus AS status
  |         FROM ContentJobItem
  |        WHERE ContentJobItem.cjiContentEntryUid = ?
  |          AND ContentJobItem.cjiPluginId != 14
  |          AND ContentJobItem.cjiStatus BETWEEN 4 AND 25
  |          AND NOT EXISTS(
  |              SELECT 1
  |                FROM ContentJobItem ContentJobItemInternal
  |               WHERE ContentJobItemInternal.cjiContentEntryUid = ?
  |                 AND ContentJobItemInternal.cjiPluginId = 14
  |                 AND ContentJobItemInternal.cjiFinishTime > ContentJobItem.cjiStartTime)
  |     ORDER BY ContentJobItem.cjiFinishTime DESC
  |        LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.setLong(2,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  public override suspend fun statusForContentEntryList(contentEntryUid: Long):
      ContentJobItemProgressAndStatus? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentJobItem.cjiRecursiveStatus AS status, 
  |               ContentJobItem.cjiRecursiveProgress AS progress,
  |               ContentJobItem.cjiRecursiveTotal AS total
  |         FROM ContentJobItem
  |        WHERE ContentJobItem.cjiContentEntryUid = ?
  |          AND ContentJobItem.cjiPluginId != 14
  |          AND ContentJobItem.cjiStatus BETWEEN 4 AND 25
  |          AND NOT EXISTS(
  |              SELECT 1
  |                FROM ContentJobItem ContentJobItemInternal
  |               WHERE ContentJobItemInternal.cjiContentEntryUid = ?
  |                 AND ContentJobItemInternal.cjiPluginId = 14
  |                 AND ContentJobItemInternal.cjiFinishTime > ContentJobItem.cjiStartTime)
  |     ORDER BY ContentJobItem.cjiFinishTime DESC
  |        LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.setLong(2,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_status = _result.getInt("status")
        val _tmp_progress = _result.getLong("progress")
        val _tmp_total = _result.getLong("total")
        ContentJobItemProgressAndStatus().apply {
          this.status = _tmp_status
          this.progress = _tmp_progress
          this.total = _tmp_total
        }
      }
    }
  }
}
