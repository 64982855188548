package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.StateContentEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class StateContentDao_JdbcKt(
  public val _db: RoomDatabase,
) : StateContentDao() {
  public val _insertAdapterStateContentEntity_: EntityInsertionAdapter<StateContentEntity> = object
      : EntityInsertionAdapter<StateContentEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO StateContentEntity (stateContentUid, stateContentStateUid, stateContentKey, stateContentValue, isIsactive, stateContentMasterChangeSeqNum, stateContentLocalChangeSeqNum, stateContentLastChangedBy, stateContentLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: StateContentEntity): Unit {
      if(entity.stateContentUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.stateContentUid)
      }
      stmt.setLong(2, entity.stateContentStateUid)
      stmt.setString(3, entity.stateContentKey)
      stmt.setString(4, entity.stateContentValue)
      stmt.setBoolean(5, entity.isIsactive)
      stmt.setLong(6, entity.stateContentMasterChangeSeqNum)
      stmt.setLong(7, entity.stateContentLocalChangeSeqNum)
      stmt.setInt(8, entity.stateContentLastChangedBy)
      stmt.setLong(9, entity.stateContentLct)
    }
  }

  public override fun insert(entity: StateContentEntity): Long {
    val _retVal = _insertAdapterStateContentEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: StateContentEntity): Long {
    val _retVal = _insertAdapterStateContentEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<StateContentEntity>): Unit {
    _insertAdapterStateContentEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<StateContentEntity>): Unit {
    val _sql =
        "UPDATE StateContentEntity SET stateContentStateUid = ?, stateContentKey = ?, stateContentValue = ?, isIsactive = ?, stateContentMasterChangeSeqNum = ?, stateContentLocalChangeSeqNum = ?, stateContentLastChangedBy = ?, stateContentLct = ? WHERE stateContentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.stateContentStateUid)
        _stmt.setString(2, _entity.stateContentKey)
        _stmt.setString(3, _entity.stateContentValue)
        _stmt.setBoolean(4, _entity.isIsactive)
        _stmt.setLong(5, _entity.stateContentMasterChangeSeqNum)
        _stmt.setLong(6, _entity.stateContentLocalChangeSeqNum)
        _stmt.setInt(7, _entity.stateContentLastChangedBy)
        _stmt.setLong(8, _entity.stateContentLct)
        _stmt.setLong(9, _entity.stateContentUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: StateContentEntity): Unit {
    val _sql =
        "UPDATE StateContentEntity SET stateContentStateUid = ?, stateContentKey = ?, stateContentValue = ?, isIsactive = ?, stateContentMasterChangeSeqNum = ?, stateContentLocalChangeSeqNum = ?, stateContentLastChangedBy = ?, stateContentLct = ? WHERE stateContentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.stateContentStateUid)
      _stmt.setString(2, entity.stateContentKey)
      _stmt.setString(3, entity.stateContentValue)
      _stmt.setBoolean(4, entity.isIsactive)
      _stmt.setLong(5, entity.stateContentMasterChangeSeqNum)
      _stmt.setLong(6, entity.stateContentLocalChangeSeqNum)
      _stmt.setInt(7, entity.stateContentLastChangedBy)
      _stmt.setLong(8, entity.stateContentLct)
      _stmt.setLong(9, entity.stateContentUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO StateContentEntityReplicate(scePk, sceDestination)
    |      SELECT DISTINCT StateContentEntity.stateContentUid AS scePk,
    |             ? AS sceDestination
    |        FROM StateContentEntity
    |       WHERE StateContentEntity.stateContentLct != COALESCE(
    |             (SELECT sceVersionId
    |                FROM StateContentEntityReplicate
    |               WHERE scePk = StateContentEntity.stateContentUid
    |                 AND sceDestination = ?), 0) 
    |      /*psql ON CONFLICT(scePk, sceDestination) DO UPDATE
    |             SET scePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO StateContentEntityReplicate(scePk, sceDestination)
    |      SELECT DISTINCT StateContentEntity.stateContentUid AS scePk,
    |             ? AS sceDestination
    |        FROM StateContentEntity
    |       WHERE StateContentEntity.stateContentLct != COALESCE(
    |             (SELECT sceVersionId
    |                FROM StateContentEntityReplicate
    |               WHERE scePk = StateContentEntity.stateContentUid
    |                 AND sceDestination = ?), 0) 
    |       ON CONFLICT(scePk, sceDestination) DO UPDATE
    |             SET scePending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO StateContentEntityReplicate(scePk, sceDestination)
    |  SELECT DISTINCT StateContentEntity.stateContentUid AS sceUid,
    |         UserSession.usClientNodeId AS sceDestination
    |    FROM ChangeLog
    |         JOIN StateContentEntity
    |             ON ChangeLog.chTableId = 72
    |                AND ChangeLog.chEntityPk = StateContentEntity.stateContentUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StateContentEntity.stateContentLct != COALESCE(
    |         (SELECT sceVersionId
    |            FROM StateContentEntityReplicate
    |           WHERE scePk = StateContentEntity.stateContentUid
    |             AND sceDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(scePk, sceDestination) DO UPDATE
    |     SET scePending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO StateContentEntityReplicate(scePk, sceDestination)
    |  SELECT DISTINCT StateContentEntity.stateContentUid AS sceUid,
    |         UserSession.usClientNodeId AS sceDestination
    |    FROM ChangeLog
    |         JOIN StateContentEntity
    |             ON ChangeLog.chTableId = 72
    |                AND ChangeLog.chEntityPk = StateContentEntity.stateContentUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StateContentEntity.stateContentLct != COALESCE(
    |         (SELECT sceVersionId
    |            FROM StateContentEntityReplicate
    |           WHERE scePk = StateContentEntity.stateContentUid
    |             AND sceDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(scePk, sceDestination) DO UPDATE
    |     SET scePending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllStateContentWithStateUid(id: Long): List<StateContentEntity> =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM StateContentEntity WHERE stateContentStateUid = ? AND isIsactive"
      )) { _stmt -> 
    _stmt.setLong(1,id)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_stateContentUid = _result.getLong("stateContentUid")
        val _tmp_stateContentStateUid = _result.getLong("stateContentStateUid")
        val _tmp_stateContentKey = _result.getString("stateContentKey")
        val _tmp_stateContentValue = _result.getString("stateContentValue")
        val _tmp_isIsactive = _result.getBoolean("isIsactive")
        val _tmp_stateContentMasterChangeSeqNum = _result.getLong("stateContentMasterChangeSeqNum")
        val _tmp_stateContentLocalChangeSeqNum = _result.getLong("stateContentLocalChangeSeqNum")
        val _tmp_stateContentLastChangedBy = _result.getInt("stateContentLastChangedBy")
        val _tmp_stateContentLct = _result.getLong("stateContentLct")
        StateContentEntity().apply {
          this.stateContentUid = _tmp_stateContentUid
          this.stateContentStateUid = _tmp_stateContentStateUid
          this.stateContentKey = _tmp_stateContentKey
          this.stateContentValue = _tmp_stateContentValue
          this.isIsactive = _tmp_isIsactive
          this.stateContentMasterChangeSeqNum = _tmp_stateContentMasterChangeSeqNum
          this.stateContentLocalChangeSeqNum = _tmp_stateContentLocalChangeSeqNum
          this.stateContentLastChangedBy = _tmp_stateContentLastChangedBy
          this.stateContentLct = _tmp_stateContentLct
        }
      }
    }
  }

  public override fun findStateContentByKeyAndStateUid(key: String, stateUid: Long):
      StateContentEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * FROM StateContentEntity WHERE stateContentStateUid = ? AND stateContentKey = ? AND isIsactive"
      )) { _stmt -> 
    _stmt.setLong(1,stateUid)
    _stmt.setString(2,key)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_stateContentUid = _result.getLong("stateContentUid")
        val _tmp_stateContentStateUid = _result.getLong("stateContentStateUid")
        val _tmp_stateContentKey = _result.getString("stateContentKey")
        val _tmp_stateContentValue = _result.getString("stateContentValue")
        val _tmp_isIsactive = _result.getBoolean("isIsactive")
        val _tmp_stateContentMasterChangeSeqNum = _result.getLong("stateContentMasterChangeSeqNum")
        val _tmp_stateContentLocalChangeSeqNum = _result.getLong("stateContentLocalChangeSeqNum")
        val _tmp_stateContentLastChangedBy = _result.getInt("stateContentLastChangedBy")
        val _tmp_stateContentLct = _result.getLong("stateContentLct")
        StateContentEntity().apply {
          this.stateContentUid = _tmp_stateContentUid
          this.stateContentStateUid = _tmp_stateContentStateUid
          this.stateContentKey = _tmp_stateContentKey
          this.stateContentValue = _tmp_stateContentValue
          this.isIsactive = _tmp_isIsactive
          this.stateContentMasterChangeSeqNum = _tmp_stateContentMasterChangeSeqNum
          this.stateContentLocalChangeSeqNum = _tmp_stateContentLocalChangeSeqNum
          this.stateContentLastChangedBy = _tmp_stateContentLastChangedBy
          this.stateContentLct = _tmp_stateContentLct
        }
      }
    }
  }

  public override fun setInActiveStateContentByKeyAndUid(
    isActive: Boolean,
    stateUid: Long,
    updateTime: Long,
  ): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("""
    |
    |        UPDATE StateContentEntity 
    |           SET isIsactive = ?,  
    |               stateContentLct = ?
    |         WHERE stateContentUid = ?
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,isActive)
      _stmt.setLong(2,updateTime)
      _stmt.setLong(3,stateUid)
      _stmt.executeUpdate()
    }
  }
}
