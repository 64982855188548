package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.PersonPicture
import kotlin.Long
import kotlin.Unit

public actual abstract class PersonPictureDao : BaseDao<PersonPicture> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun findByPersonUidAsync(personUid: Long): PersonPicture?

  public actual abstract fun findByPersonUidLive(personUid: Long): LiveData<PersonPicture?>

  public actual abstract suspend fun updateAsync(personPicture: PersonPicture): Unit
}
