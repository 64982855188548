package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ErrorReport
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ErrorReportDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ErrorReportDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ErrorReportDao() {
  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertAsync(errorReport: ErrorReport): Long {
    val _result = _dao.insertAsync(errorReport)
    return _result
  }

  public override suspend fun findByUidAsync(errUid: Long): ErrorReport? {
    val _result = _dao.findByUidAsync(errUid)
    return _result
  }

  public override suspend fun findByErrorCode(errCode: Int): List<ErrorReport> {
    val _result = _dao.findByErrorCode(errCode)
    return _result
  }
}
