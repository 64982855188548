package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.ClazzAssignmentContentJoin
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzAssignmentContentJoinDao : BaseDao<ClazzAssignmentContentJoin> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend
      fun findAllContentByClazzAssignmentUidAsync(clazzAssignmentUid: Long, personUid: Long):
      List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>

  public actual abstract fun findAllContentByClazzAssignmentUidDF(clazzAssignmentUid: Long,
      personUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>
}
