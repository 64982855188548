package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseDiscussion
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseDiscussionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseDiscussionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseDiscussionDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  public override fun getCourseDiscussionByUid(courseDiscussionUid: Long):
      LiveData<CourseDiscussion?> {
    val _result = _dao.getCourseDiscussionByUid(courseDiscussionUid)
    return _result
  }

  public override suspend fun replaceListAsync(list: List<CourseDiscussion>): Unit {
    _dao.replaceListAsync(list)
  }

  public override fun insert(entity: CourseDiscussion): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: CourseDiscussion): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<CourseDiscussion>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<CourseDiscussion>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: CourseDiscussion): Unit {
    _dao.update(entity)
  }

  public override suspend fun insertListAsync(entityList: List<CourseDiscussion>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CourseDiscussion>): Unit {
    _dao.updateListAsync(entityList)
  }
}
