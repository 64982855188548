package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentMarkDao_JdbcKt(
  public val _db: RoomDatabase,
) : CourseAssignmentMarkDao() {
  public val _insertAdapterCourseAssignmentMark_: EntityInsertionAdapter<CourseAssignmentMark> =
      object : EntityInsertionAdapter<CourseAssignmentMark>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO CourseAssignmentMark (camUid, camAssignmentUid, camSubmitterUid, camMark, camPenalty, camLct) VALUES(?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseAssignmentMark): Unit {
      if(entity.camUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.camUid)
      }
      stmt.setLong(2, entity.camAssignmentUid)
      stmt.setLong(3, entity.camSubmitterUid)
      stmt.setFloat(4, entity.camMark)
      stmt.setInt(5, entity.camPenalty)
      stmt.setLong(6, entity.camLct)
    }
  }

  public override fun insert(entity: CourseAssignmentMark): Long {
    val _retVal = _insertAdapterCourseAssignmentMark_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseAssignmentMark): Long {
    val _retVal = _insertAdapterCourseAssignmentMark_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CourseAssignmentMark>): Unit {
    _insertAdapterCourseAssignmentMark_.insertList(entityList)
  }

  public override fun updateList(entityList: List<CourseAssignmentMark>): Unit {
    val _sql =
        "UPDATE CourseAssignmentMark SET camAssignmentUid = ?, camSubmitterUid = ?, camMark = ?, camPenalty = ?, camLct = ? WHERE camUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.camAssignmentUid)
        _stmt.setLong(2, _entity.camSubmitterUid)
        _stmt.setFloat(3, _entity.camMark)
        _stmt.setInt(4, _entity.camPenalty)
        _stmt.setLong(5, _entity.camLct)
        _stmt.setLong(6, _entity.camUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseAssignmentMark): Unit {
    val _sql =
        "UPDATE CourseAssignmentMark SET camAssignmentUid = ?, camSubmitterUid = ?, camMark = ?, camPenalty = ?, camLct = ? WHERE camUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.camAssignmentUid)
      _stmt.setLong(2, entity.camSubmitterUid)
      _stmt.setFloat(3, entity.camMark)
      _stmt.setInt(4, entity.camPenalty)
      _stmt.setLong(5, entity.camLct)
      _stmt.setLong(6, entity.camUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |      SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |             ? AS camDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentMark
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |                    
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentMark.camLct != COALESCE(
    |             (SELECT camVersionId
    |                FROM CourseAssignmentMarkReplicate
    |               WHERE camPk = CourseAssignmentMark.camUid
    |                 AND camDestination = ?), 0) 
    |      /*psql ON CONFLICT(camPk, camDestination) DO UPDATE
    |             SET camPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |      SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |             ? AS camDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentMark
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |                    
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentMark.camLct != COALESCE(
    |             (SELECT camVersionId
    |                FROM CourseAssignmentMarkReplicate
    |               WHERE camPk = CourseAssignmentMark.camUid
    |                 AND camDestination = ?), 0) 
    |       ON CONFLICT(camPk, camDestination) DO UPDATE
    |             SET camPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |  SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |         UserSession.usClientNodeId AS camDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentMark
    |             ON ChangeLog.chTableId = 523
    |                AND ChangeLog.chEntityPk = CourseAssignmentMark.camUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentMark.camLct != COALESCE(
    |         (SELECT camVersionId
    |            FROM CourseAssignmentMarkReplicate
    |           WHERE camPk = CourseAssignmentMark.camUid
    |             AND camDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(camPk, camDestination) DO UPDATE
    |     SET camPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |  SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |         UserSession.usClientNodeId AS camDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentMark
    |             ON ChangeLog.chTableId = 523
    |                AND ChangeLog.chEntityPk = CourseAssignmentMark.camUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentMark.camLct != COALESCE(
    |         (SELECT camVersionId
    |            FROM CourseAssignmentMarkReplicate
    |           WHERE camPk = CourseAssignmentMark.camUid
    |             AND camDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(camPk, camDestination) DO UPDATE
    |     SET camPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long,
      submitterUid: Long): LiveData<CourseAssignmentMark?> = LiveDataImpl(_db,
      listOf("CourseAssignmentMark"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM CourseAssignmentMark               
    |         WHERE camAssignmentUid = ?
    |           AND camSubmitterUid = ?
    |      ORDER BY camLct DESC
    |         LIMIT 1
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,assignmentUid)
      _stmt.setLong(2,submitterUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_camUid = _result.getLong("camUid")
          val _tmp_camAssignmentUid = _result.getLong("camAssignmentUid")
          val _tmp_camSubmitterUid = _result.getLong("camSubmitterUid")
          val _tmp_camMark = _result.getFloat("camMark")
          val _tmp_camPenalty = _result.getInt("camPenalty")
          val _tmp_camLct = _result.getLong("camLct")
          CourseAssignmentMark().apply {
            this.camUid = _tmp_camUid
            this.camAssignmentUid = _tmp_camAssignmentUid
            this.camSubmitterUid = _tmp_camSubmitterUid
            this.camMark = _tmp_camMark
            this.camPenalty = _tmp_camPenalty
            this.camLct = _tmp_camLct
          }
        }
      }
    }
  }

  public override fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark? = _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM CourseAssignmentMark
  |         WHERE camAssignmentUid = ?
  |           AND camSubmitterUid = ?
  |      ORDER BY camLct DESC
  |         LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,assignmentUid)
    _stmt.setLong(2,submitterUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_camUid = _result.getLong("camUid")
        val _tmp_camAssignmentUid = _result.getLong("camAssignmentUid")
        val _tmp_camSubmitterUid = _result.getLong("camSubmitterUid")
        val _tmp_camMark = _result.getFloat("camMark")
        val _tmp_camPenalty = _result.getInt("camPenalty")
        val _tmp_camLct = _result.getLong("camLct")
        CourseAssignmentMark().apply {
          this.camUid = _tmp_camUid
          this.camAssignmentUid = _tmp_camAssignmentUid
          this.camSubmitterUid = _tmp_camSubmitterUid
          this.camMark = _tmp_camMark
          this.camPenalty = _tmp_camPenalty
          this.camLct = _tmp_camLct
        }
      }
    }
  }

  public override suspend fun findNextSubmitterToMarkForAssignment(assignmentUid: Long,
      submitterUid: Long): Long = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |         SELECT COALESCE((
  |            SELECT casSubmitterUid
  |              FROM CourseAssignmentSubmission
  |              
  |                   LEFT JOIN CourseAssignmentMark
  |                   ON CourseAssignmentMark.camSubmitterUid = CourseAssignmentSubmission.casSubmitterUid
  |                   AND CourseAssignmentMark.camAssignmentUid = ?
  |                   
  |             WHERE CourseAssignmentSubmission.casSubmitterUid != ?
  |               AND CourseAssignmentMark.camUid IS NULL
  |          GROUP BY casSubmitterUid
  |         LIMIT 1),0)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,assignmentUid)
    _stmt.setLong(2,submitterUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }
}
