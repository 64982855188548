package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionWithAttachment
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseAssignmentSubmissionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseAssignmentSubmissionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseAssignmentSubmissionDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<CourseAssignmentSubmission>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override fun getAllSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long):
      DataSourceFactory<Int, CourseAssignmentSubmissionWithAttachment> {
    val _result = _dao.getAllSubmissionsFromSubmitter(assignmentUid, submitterUid)
    return _result
  }

  public override suspend fun countFileSubmissionFromStudent(assignmentUid: Long,
      submitterUid: Long): Int {
    val _result = _dao.countFileSubmissionFromStudent(assignmentUid, submitterUid)
    return _result
  }

  public override suspend fun countSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): Int {
    val _result = _dao.countSubmissionsFromSubmitter(assignmentUid, submitterUid)
    return _result
  }

  public override fun getStatusOfAssignmentForSubmitter(assignmentUid: Long, submitterUid: Long):
      LiveData<Int> {
    val _result = _dao.getStatusOfAssignmentForSubmitter(assignmentUid, submitterUid)
    return _result
  }

  public override suspend fun findLastSubmissionFromStudent(submitterUid: Long,
      assignmentUid: Long): CourseAssignmentSubmission? {
    val _result = _dao.findLastSubmissionFromStudent(submitterUid, assignmentUid)
    return _result
  }

  public override fun checkNoSubmissionsMade(assignmentUid: Long): LiveData<Boolean> {
    val _result = _dao.checkNoSubmissionsMade(assignmentUid)
    return _result
  }

  public override fun insert(entity: CourseAssignmentSubmission): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: CourseAssignmentSubmission): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<CourseAssignmentSubmission>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<CourseAssignmentSubmission>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: CourseAssignmentSubmission): Unit {
    _dao.update(entity)
  }
}
