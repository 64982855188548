package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentJob
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit

public class ContentJobDao_JdbcKt(
  public val _db: RoomDatabase,
) : ContentJobDao() {
  public val _insertAdapterContentJob_: EntityInsertionAdapter<ContentJob> = object :
      EntityInsertionAdapter<ContentJob>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ContentJob (cjUid, toUri, cjProgress, cjTotal, cjNotificationTitle, cjIsMeteredAllowed, params) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentJob):
        Unit {
      if(entity.cjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cjUid)
      }
      stmt.setString(2, entity.toUri)
      stmt.setLong(3, entity.cjProgress)
      stmt.setLong(4, entity.cjTotal)
      stmt.setString(5, entity.cjNotificationTitle)
      stmt.setBoolean(6, entity.cjIsMeteredAllowed)
      stmt.setString(7, entity.params)
    }
  }

  public override suspend fun insertAsync(contentJob: ContentJob): Long {
    val _retVal = _insertAdapterContentJob_.insertAndReturnIdAsync(contentJob)
    return _retVal
  }

  public override fun findByUid(cjUid: Long): ContentJob? =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT ContentJob.*
  |          FROM ContentJob
  |         WHERE cjUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,cjUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cjUid = _result.getLong("cjUid")
        val _tmp_toUri = _result.getString("toUri")
        val _tmp_cjProgress = _result.getLong("cjProgress")
        val _tmp_cjTotal = _result.getLong("cjTotal")
        val _tmp_cjNotificationTitle = _result.getString("cjNotificationTitle")
        val _tmp_cjIsMeteredAllowed = _result.getBoolean("cjIsMeteredAllowed")
        val _tmp_params = _result.getString("params")
        ContentJob().apply {
          this.cjUid = _tmp_cjUid
          this.toUri = _tmp_toUri
          this.cjProgress = _tmp_cjProgress
          this.cjTotal = _tmp_cjTotal
          this.cjNotificationTitle = _tmp_cjNotificationTitle
          this.cjIsMeteredAllowed = _tmp_cjIsMeteredAllowed
          this.params = _tmp_params
        }
      }
    }
  }

  public override suspend fun findByUidAsync(cjUid: Long): ContentJob? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT ContentJob.*
  |          FROM ContentJob
  |         WHERE cjUid = ? 
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,cjUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cjUid = _result.getLong("cjUid")
        val _tmp_toUri = _result.getString("toUri")
        val _tmp_cjProgress = _result.getLong("cjProgress")
        val _tmp_cjTotal = _result.getLong("cjTotal")
        val _tmp_cjNotificationTitle = _result.getString("cjNotificationTitle")
        val _tmp_cjIsMeteredAllowed = _result.getBoolean("cjIsMeteredAllowed")
        val _tmp_params = _result.getString("params")
        ContentJob().apply {
          this.cjUid = _tmp_cjUid
          this.toUri = _tmp_toUri
          this.cjProgress = _tmp_cjProgress
          this.cjTotal = _tmp_cjTotal
          this.cjNotificationTitle = _tmp_cjNotificationTitle
          this.cjIsMeteredAllowed = _tmp_cjIsMeteredAllowed
          this.params = _tmp_params
        }
      }
    }
  }

  public override fun findLiveDataByUid(cjUid: Long): LiveData<ContentJob?> = LiveDataImpl(_db,
      listOf("ContentJob"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT ContentJob.*
    |          FROM ContentJob
    |         WHERE cjUid = ?
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,cjUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_cjUid = _result.getLong("cjUid")
          val _tmp_toUri = _result.getString("toUri")
          val _tmp_cjProgress = _result.getLong("cjProgress")
          val _tmp_cjTotal = _result.getLong("cjTotal")
          val _tmp_cjNotificationTitle = _result.getString("cjNotificationTitle")
          val _tmp_cjIsMeteredAllowed = _result.getBoolean("cjIsMeteredAllowed")
          val _tmp_params = _result.getString("params")
          ContentJob().apply {
            this.cjUid = _tmp_cjUid
            this.toUri = _tmp_toUri
            this.cjProgress = _tmp_cjProgress
            this.cjTotal = _tmp_cjTotal
            this.cjNotificationTitle = _tmp_cjNotificationTitle
            this.cjIsMeteredAllowed = _tmp_cjIsMeteredAllowed
            this.params = _tmp_params
          }
        }
      }
    }
  }

  public override suspend fun updateDestinationDir(cjUid: Long, toUri: String): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJob
    |           SET toUri = ?
    |         WHERE cjUid = ?
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setString(1,toUri)
      _stmt.setLong(2,cjUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findMeteredAllowedLiveData(contentJobId: Long): LiveData<Boolean> =
      LiveDataImpl(_db, listOf("ContentJob"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT COALESCE((SELECT ContentJob.cjIsMeteredAllowed
    |          FROM ContentJob
    |         WHERE cjUid = ?
    |         LIMIT 1), 0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT COALESCE((SELECT ContentJob.cjIsMeteredAllowed
    |          FROM ContentJob
    |         WHERE cjUid = ?
    |         LIMIT 1), FALSE)
    |    
    """.trimMargin())) { _stmt -> 
      _stmt.setLong(1,contentJobId)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(false) {
          _result.getBoolean(1)
        }
      }
    }
  }

  public override suspend fun updateMeteredAllowedForEntry(contentEntryUid: Long,
      meteredAllowed: Boolean): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ContentJob 
    |           SET cjIsMeteredAllowed = ?
    |         WHERE cjUid IN (SELECT cjiJobUid 
    |                           FROM ContentJobItem
    |                          WHERE cjiContentEntryUid = ?
    |                             OR cjiParentContentEntryUid = ?)
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,meteredAllowed)
      _stmt.setLong(2,contentEntryUid)
      _stmt.setLong(3,contentEntryUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun isConnectivityAcceptableForJob(jobId: Long): Boolean =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |          WITH ConnectivityStateCte(state) AS 
  |             (SELECT COALESCE(
  |                     (SELECT connectivityState 
  |                        FROM ConnectivityStatus 
  |                       LIMIT 1), 0))
  |   
  |           SELECT COALESCE((
  |                  SELECT 1 
  |                    FROM ContentJob 
  |                   WHERE cjUid = ?
  |                    AND ((cjIsMeteredAllowed 
  |                         AND (SELECT state FROM ConnectivityStateCte) = 3)
  |			             OR (SELECT state FROM ConnectivityStateCte) = 4)
  |                  ) ,0)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,jobId)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }
}
