package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class DiscussionPostDao : BaseDao<DiscussionPost> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun getPostsByDiscussionTopic(discussionTopicUid: Long):
      DataSourceFactory<Int, DiscussionPostWithDetails>

  public actual abstract suspend fun getPostTitle(postUid: Long): String?

  public actual abstract suspend fun findByUid(uid: Long): DiscussionPost?

  public actual abstract suspend fun findWithDetailsByUid(uid: Long): DiscussionPostWithDetails?

  public actual abstract fun findWithDetailsByUidLive(uid: Long):
      LiveData<DiscussionPostWithDetails?>

  public actual abstract suspend fun updateAsync(entity: DiscussionPost): Int
}
