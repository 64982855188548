package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ClazzAssignmentContentJoin
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzAssignmentContentJoinDao_JdbcKt(
  public val _db: RoomDatabase,
) : ClazzAssignmentContentJoinDao() {
  public val _insertAdapterClazzAssignmentContentJoin_:
      EntityInsertionAdapter<ClazzAssignmentContentJoin> = object :
      EntityInsertionAdapter<ClazzAssignmentContentJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ClazzAssignmentContentJoin (cacjUid, cacjContentUid, cacjAssignmentUid, cacjActive, cacjWeight, cacjMCSN, cacjLCSN, cacjLCB, cacjLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ClazzAssignmentContentJoin): Unit {
      if(entity.cacjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cacjUid)
      }
      stmt.setLong(2, entity.cacjContentUid)
      stmt.setLong(3, entity.cacjAssignmentUid)
      stmt.setBoolean(4, entity.cacjActive)
      stmt.setInt(5, entity.cacjWeight)
      stmt.setLong(6, entity.cacjMCSN)
      stmt.setLong(7, entity.cacjLCSN)
      stmt.setInt(8, entity.cacjLCB)
      stmt.setLong(9, entity.cacjLct)
    }
  }

  public override fun insert(entity: ClazzAssignmentContentJoin): Long {
    val _retVal = _insertAdapterClazzAssignmentContentJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzAssignmentContentJoin): Long {
    val _retVal = _insertAdapterClazzAssignmentContentJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ClazzAssignmentContentJoin>): Unit {
    _insertAdapterClazzAssignmentContentJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<ClazzAssignmentContentJoin>): Unit {
    val _sql =
        "UPDATE ClazzAssignmentContentJoin SET cacjContentUid = ?, cacjAssignmentUid = ?, cacjActive = ?, cacjWeight = ?, cacjMCSN = ?, cacjLCSN = ?, cacjLCB = ?, cacjLct = ? WHERE cacjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cacjContentUid)
        _stmt.setLong(2, _entity.cacjAssignmentUid)
        _stmt.setBoolean(3, _entity.cacjActive)
        _stmt.setInt(4, _entity.cacjWeight)
        _stmt.setLong(5, _entity.cacjMCSN)
        _stmt.setLong(6, _entity.cacjLCSN)
        _stmt.setInt(7, _entity.cacjLCB)
        _stmt.setLong(8, _entity.cacjLct)
        _stmt.setLong(9, _entity.cacjUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzAssignmentContentJoin): Unit {
    val _sql =
        "UPDATE ClazzAssignmentContentJoin SET cacjContentUid = ?, cacjAssignmentUid = ?, cacjActive = ?, cacjWeight = ?, cacjMCSN = ?, cacjLCSN = ?, cacjLCB = ?, cacjLct = ? WHERE cacjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cacjContentUid)
      _stmt.setLong(2, entity.cacjAssignmentUid)
      _stmt.setBoolean(3, entity.cacjActive)
      _stmt.setInt(4, entity.cacjWeight)
      _stmt.setLong(5, entity.cacjMCSN)
      _stmt.setLong(6, entity.cacjLCSN)
      _stmt.setInt(7, entity.cacjLCB)
      _stmt.setLong(8, entity.cacjLct)
      _stmt.setLong(9, entity.cacjUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |      SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |             ? AS cacjDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |               JOIN ClazzAssignmentContentJoin
    |                    ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid     
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1             
    |         AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |             (SELECT cacjVersionId
    |                FROM ClazzAssignmentContentJoinReplicate
    |               WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |                 AND cacjDestination = ?), 0) 
    |      /*psql ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |             SET cacjPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |      SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |             ? AS cacjDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |               JOIN ClazzAssignmentContentJoin
    |                    ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid     
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1             
    |         AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |             (SELECT cacjVersionId
    |                FROM ClazzAssignmentContentJoinReplicate
    |               WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |                 AND cacjDestination = ?), 0) 
    |       ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |             SET cacjPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |  SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |         UserSession.usClientNodeId AS cacjDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignmentContentJoin
    |             ON ChangeLog.chTableId = 521
    |                AND ChangeLog.chEntityPk = ClazzAssignmentContentJoin.cacjUid
    |         JOIN ClazzAssignment
    |              ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |         (SELECT cacjVersionId
    |            FROM ClazzAssignmentContentJoinReplicate
    |           WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |             AND cacjDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |     SET cacjPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |  SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |         UserSession.usClientNodeId AS cacjDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignmentContentJoin
    |             ON ChangeLog.chTableId = 521
    |                AND ChangeLog.chEntityPk = ClazzAssignmentContentJoin.cacjUid
    |         JOIN ClazzAssignment
    |              ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |         (SELECT cacjVersionId
    |            FROM ClazzAssignmentContentJoinReplicate
    |           WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |             AND cacjDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |     SET cacjPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findAllContentByClazzAssignmentUidAsync(clazzAssignmentUid: Long,
      personUid: Long): List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
  |                            Container.*, 
  |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
  |                                           
  |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
  |                                                         
  |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
  |                            
  |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
  |                                 
  |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
  |                             
  |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
  |                               
  |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
  |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
  |                        
  |                             0 as assignmentContentWeight,
  |                             1 as totalContent
  |                           
  |                             
  |                      FROM ClazzAssignmentContentJoin
  |                            LEFT JOIN ContentEntry 
  |                            ON ContentEntry.contentEntryUid = cacjContentUid 
  |                            
  |                            LEFT JOIN ContentEntryParentChildJoin 
  |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
  |                           
  |                            LEFT JOIN ClazzAssignmentRollUp
  |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
  |                                AND cachePersonUid = ?
  |                                AND cacheClazzAssignmentUid = ?
  |                                                        
  |                            
  |                            LEFT JOIN Container 
  |                            ON Container.containerUid = 
  |                                (SELECT containerUid 
  |                                   FROM Container 
  |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
  |                               ORDER BY cntLastModified DESC LIMIT 1)
  |                               
  |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
  |                      AND ClazzAssignmentContentJoin.cacjActive
  |                      AND NOT ContentEntry.ceInactive
  |                      AND (ContentEntry.publik OR ? != 0)
  |                      ORDER BY ContentEntry.title ASC , 
  |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
  |                               
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.setLong(2,clazzAssignmentUid)
    _stmt.setLong(3,clazzAssignmentUid)
    _stmt.setLong(4,personUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_assignmentContentWeight = _result.getInt("assignmentContentWeight")
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        var _tmp_Container_nullCount = 0
        val _tmp_containerUid = _result.getLong("containerUid")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLct = _result.getLong("cntLct")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_fileSize = _result.getLong("fileSize")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_mimeType = _result.getString("mimeType")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_remarks = _result.getString("remarks")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        if(_result.wasNull()) _tmp_Container_nullCount++
        val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
        var _tmp_ContentEntryParentChildJoin_nullCount = 0
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_childIndex = _result.getInt("childIndex")
        if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
        val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
        var _tmp_ContentEntryStatementScoreProgress_nullCount = 0
        val _tmp_resultScore = _result.getInt("resultScore")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_resultMax = _result.getInt("resultMax")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_resultScaled = _result.getFloat("resultScaled")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_resultWeight = _result.getInt("resultWeight")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_contentComplete = _result.getBoolean("contentComplete")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_progress = _result.getInt("progress")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_success = _result.getByte("success")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_penalty = _result.getInt("penalty")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_totalContent = _result.getInt("totalContent")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
        if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
        val _tmp_ContentEntryStatementScoreProgress_isAllNull = _tmp_ContentEntryStatementScoreProgress_nullCount == 10
        ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer().apply {
          this.assignmentContentWeight = _tmp_assignmentContentWeight
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
          if(!_tmp_Container_isAllNull) {
            this.mostRecentContainer = Container().apply {
              this.containerUid = _tmp_containerUid
              this.cntLocalCsn = _tmp_cntLocalCsn
              this.cntMasterCsn = _tmp_cntMasterCsn
              this.cntLastModBy = _tmp_cntLastModBy
              this.cntLct = _tmp_cntLct
              this.fileSize = _tmp_fileSize
              this.containerContentEntryUid = _tmp_containerContentEntryUid
              this.cntLastModified = _tmp_cntLastModified
              this.mimeType = _tmp_mimeType
              this.remarks = _tmp_remarks
              this.mobileOptimized = _tmp_mobileOptimized
              this.cntNumEntries = _tmp_cntNumEntries
            }
          }
          if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
            this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
              this.cepcjUid = _tmp_cepcjUid
              this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
              this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
              this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
              this.cepcjLct = _tmp_cepcjLct
              this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
              this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
              this.childIndex = _tmp_childIndex
            }
          }
          if(!_tmp_ContentEntryStatementScoreProgress_isAllNull) {
            this.scoreProgress = ContentEntryStatementScoreProgress().apply {
              this.resultScore = _tmp_resultScore
              this.resultMax = _tmp_resultMax
              this.resultScaled = _tmp_resultScaled
              this.resultWeight = _tmp_resultWeight
              this.contentComplete = _tmp_contentComplete
              this.progress = _tmp_progress
              this.success = _tmp_success
              this.penalty = _tmp_penalty
              this.totalContent = _tmp_totalContent
              this.totalCompletedContent = _tmp_totalCompletedContent
            }
          }
        }
      }
    }
  }

  public override fun findAllContentByClazzAssignmentUidDF(clazzAssignmentUid: Long,
      personUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> =
      object :
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
        LiveDataImpl(_db, listOf("ClazzAssignmentContentJoin", "ContentEntry",
        "ContentEntryParentChildJoin", "ClazzAssignmentRollUp", "Container"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
      |                            Container.*, 
      |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
      |                                           
      |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
      |                                                         
      |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
      |                            
      |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
      |                                 
      |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
      |                             
      |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
      |                               
      |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
      |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                        
      |                             0 as assignmentContentWeight,
      |                             1 as totalContent
      |                           
      |                             
      |                      FROM ClazzAssignmentContentJoin
      |                            LEFT JOIN ContentEntry 
      |                            ON ContentEntry.contentEntryUid = cacjContentUid 
      |                            
      |                            LEFT JOIN ContentEntryParentChildJoin 
      |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                           
      |                            LEFT JOIN ClazzAssignmentRollUp
      |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
      |                                AND cachePersonUid = ?
      |                                AND cacheClazzAssignmentUid = ?
      |                                                        
      |                            
      |                            LEFT JOIN Container 
      |                            ON Container.containerUid = 
      |                                (SELECT containerUid 
      |                                   FROM Container 
      |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
      |                               ORDER BY cntLastModified DESC LIMIT 1)
      |                               
      |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
      |                      AND ClazzAssignmentContentJoin.cacjActive
      |                      AND NOT ContentEntry.ceInactive
      |                      AND (ContentEntry.publik OR ? != 0)
      |                      ORDER BY ContentEntry.title ASC , 
      |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
      |                               ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,clazzAssignmentUid)
        _stmt.setLong(3,clazzAssignmentUid)
        _stmt.setLong(4,personUid)
        _stmt.setInt(5,_limit)
        _stmt.setInt(6,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_assignmentContentWeight = _result.getInt("assignmentContentWeight")
            val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
            val _tmp_title = _result.getString("title")
            val _tmp_description = _result.getString("description")
            val _tmp_entryId = _result.getString("entryId")
            val _tmp_author = _result.getString("author")
            val _tmp_publisher = _result.getString("publisher")
            val _tmp_licenseType = _result.getInt("licenseType")
            val _tmp_licenseName = _result.getString("licenseName")
            val _tmp_licenseUrl = _result.getString("licenseUrl")
            val _tmp_sourceUrl = _result.getString("sourceUrl")
            val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
            val _tmp_lastModified = _result.getLong("lastModified")
            val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
            val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
            val _tmp_contentFlags = _result.getInt("contentFlags")
            val _tmp_leaf = _result.getBoolean("leaf")
            val _tmp_publik = _result.getBoolean("publik")
            val _tmp_ceInactive = _result.getBoolean("ceInactive")
            val _tmp_completionCriteria = _result.getInt("completionCriteria")
            val _tmp_minScore = _result.getInt("minScore")
            val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
            val _tmp_contentOwner = _result.getLong("contentOwner")
            val _tmp_contentEntryLocalChangeSeqNum =
                _result.getLong("contentEntryLocalChangeSeqNum")
            val _tmp_contentEntryMasterChangeSeqNum =
                _result.getLong("contentEntryMasterChangeSeqNum")
            val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
            val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
            var _tmp_Container_nullCount = 0
            val _tmp_containerUid = _result.getLong("containerUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLct = _result.getLong("cntLct")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_fileSize = _result.getLong("fileSize")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModified = _result.getLong("cntLastModified")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mimeType = _result.getString("mimeType")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_remarks = _result.getString("remarks")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
            var _tmp_ContentEntryParentChildJoin_nullCount = 0
            val _tmp_cepcjUid = _result.getLong("cepcjUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLct = _result.getLong("cepcjLct")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_childIndex = _result.getInt("childIndex")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
            var _tmp_ContentEntryStatementScoreProgress_nullCount = 0
            val _tmp_resultScore = _result.getInt("resultScore")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultMax = _result.getInt("resultMax")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultScaled = _result.getFloat("resultScaled")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultWeight = _result.getInt("resultWeight")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_contentComplete = _result.getBoolean("contentComplete")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_progress = _result.getInt("progress")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_success = _result.getByte("success")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_penalty = _result.getInt("penalty")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalContent = _result.getInt("totalContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_ContentEntryStatementScoreProgress_isAllNull = _tmp_ContentEntryStatementScoreProgress_nullCount == 10
            ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer().apply {
              this.assignmentContentWeight = _tmp_assignmentContentWeight
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
              if(!_tmp_Container_isAllNull) {
                this.mostRecentContainer = Container().apply {
                  this.containerUid = _tmp_containerUid
                  this.cntLocalCsn = _tmp_cntLocalCsn
                  this.cntMasterCsn = _tmp_cntMasterCsn
                  this.cntLastModBy = _tmp_cntLastModBy
                  this.cntLct = _tmp_cntLct
                  this.fileSize = _tmp_fileSize
                  this.containerContentEntryUid = _tmp_containerContentEntryUid
                  this.cntLastModified = _tmp_cntLastModified
                  this.mimeType = _tmp_mimeType
                  this.remarks = _tmp_remarks
                  this.mobileOptimized = _tmp_mobileOptimized
                  this.cntNumEntries = _tmp_cntNumEntries
                }
              }
              if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
                this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                  this.cepcjUid = _tmp_cepcjUid
                  this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                  this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                  this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                  this.cepcjLct = _tmp_cepcjLct
                  this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                  this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                  this.childIndex = _tmp_childIndex
                }
              }
              if(!_tmp_ContentEntryStatementScoreProgress_isAllNull) {
                this.scoreProgress = ContentEntryStatementScoreProgress().apply {
                  this.resultScore = _tmp_resultScore
                  this.resultMax = _tmp_resultMax
                  this.resultScaled = _tmp_resultScaled
                  this.resultWeight = _tmp_resultWeight
                  this.contentComplete = _tmp_contentComplete
                  this.progress = _tmp_progress
                  this.success = _tmp_success
                  this.penalty = _tmp_penalty
                  this.totalContent = _tmp_totalContent
                  this.totalCompletedContent = _tmp_totalCompletedContent
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db,
        listOf("ClazzAssignmentContentJoin", "ContentEntry", "ContentEntryParentChildJoin",
        "ClazzAssignmentRollUp", "Container"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
      |                            Container.*, 
      |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
      |                                           
      |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
      |                                                         
      |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
      |                            
      |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
      |                                 
      |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
      |                             
      |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
      |                               
      |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
      |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                        
      |                             0 as assignmentContentWeight,
      |                             1 as totalContent
      |                           
      |                             
      |                      FROM ClazzAssignmentContentJoin
      |                            LEFT JOIN ContentEntry 
      |                            ON ContentEntry.contentEntryUid = cacjContentUid 
      |                            
      |                            LEFT JOIN ContentEntryParentChildJoin 
      |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                           
      |                            LEFT JOIN ClazzAssignmentRollUp
      |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
      |                                AND cachePersonUid = ?
      |                                AND cacheClazzAssignmentUid = ?
      |                                                        
      |                            
      |                            LEFT JOIN Container 
      |                            ON Container.containerUid = 
      |                                (SELECT containerUid 
      |                                   FROM Container 
      |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
      |                               ORDER BY cntLastModified DESC LIMIT 1)
      |                               
      |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
      |                      AND ClazzAssignmentContentJoin.cacjActive
      |                      AND NOT ContentEntry.ceInactive
      |                      AND (ContentEntry.publik OR ? != 0)
      |                      ORDER BY ContentEntry.title ASC , 
      |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
      |                               ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,personUid)
        _stmt.setLong(2,clazzAssignmentUid)
        _stmt.setLong(3,clazzAssignmentUid)
        _stmt.setLong(4,personUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }
}
