package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonGroupMember
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PersonGroupMemberDao_JdbcKt(
  public val _db: RoomDatabase,
) : PersonGroupMemberDao() {
  public val _insertAdapterPersonGroupMember_: EntityInsertionAdapter<PersonGroupMember> = object :
      EntityInsertionAdapter<PersonGroupMember>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO PersonGroupMember (groupMemberUid, groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: PersonGroupMember): Unit {
      if(entity.groupMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupMemberUid)
      }
      stmt.setBoolean(2, entity.groupMemberActive)
      stmt.setLong(3, entity.groupMemberPersonUid)
      stmt.setLong(4, entity.groupMemberGroupUid)
      stmt.setLong(5, entity.groupMemberMasterCsn)
      stmt.setLong(6, entity.groupMemberLocalCsn)
      stmt.setInt(7, entity.groupMemberLastChangedBy)
      stmt.setLong(8, entity.groupMemberLct)
    }
  }

  public override fun insert(entity: PersonGroupMember): Long {
    val _retVal = _insertAdapterPersonGroupMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: PersonGroupMember): Long {
    val _retVal = _insertAdapterPersonGroupMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<PersonGroupMember>): Unit {
    _insertAdapterPersonGroupMember_.insertList(entityList)
  }

  public override fun updateList(entityList: List<PersonGroupMember>): Unit {
    val _sql =
        "UPDATE PersonGroupMember SET groupMemberActive = ?, groupMemberPersonUid = ?, groupMemberGroupUid = ?, groupMemberMasterCsn = ?, groupMemberLocalCsn = ?, groupMemberLastChangedBy = ?, groupMemberLct = ? WHERE groupMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setBoolean(1, _entity.groupMemberActive)
        _stmt.setLong(2, _entity.groupMemberPersonUid)
        _stmt.setLong(3, _entity.groupMemberGroupUid)
        _stmt.setLong(4, _entity.groupMemberMasterCsn)
        _stmt.setLong(5, _entity.groupMemberLocalCsn)
        _stmt.setInt(6, _entity.groupMemberLastChangedBy)
        _stmt.setLong(7, _entity.groupMemberLct)
        _stmt.setLong(8, _entity.groupMemberUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: PersonGroupMember): Unit {
    val _sql =
        "UPDATE PersonGroupMember SET groupMemberActive = ?, groupMemberPersonUid = ?, groupMemberGroupUid = ?, groupMemberMasterCsn = ?, groupMemberLocalCsn = ?, groupMemberLastChangedBy = ?, groupMemberLct = ? WHERE groupMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setBoolean(1, entity.groupMemberActive)
      _stmt.setLong(2, entity.groupMemberPersonUid)
      _stmt.setLong(3, entity.groupMemberGroupUid)
      _stmt.setLong(4, entity.groupMemberMasterCsn)
      _stmt.setLong(5, entity.groupMemberLocalCsn)
      _stmt.setInt(6, entity.groupMemberLastChangedBy)
      _stmt.setLong(7, entity.groupMemberLct)
      _stmt.setLong(8, entity.groupMemberUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |      SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |             ? AS pgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroupMember.groupMemberLct != COALESCE(
    |             (SELECT pgmVersionId
    |                FROM PersonGroupMemberReplicate
    |               WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                 AND pgmDestination = ?), 0) 
    |      /*psql ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |             SET pgmPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |      SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |             ? AS pgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroupMember.groupMemberLct != COALESCE(
    |             (SELECT pgmVersionId
    |                FROM PersonGroupMemberReplicate
    |               WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                 AND pgmDestination = ?), 0) 
    |       ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |             SET pgmPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |  SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |         UserSession.usClientNodeId AS pgmDestination
    |    FROM ChangeLog
    |         JOIN PersonGroupMember
    |             ON ChangeLog.chTableId = 44
    |                AND ChangeLog.chEntityPk = PersonGroupMember.groupMemberUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonGroupMember.groupMemberLct != COALESCE(
    |         (SELECT pgmVersionId
    |            FROM PersonGroupMemberReplicate
    |           WHERE pgmPk = PersonGroupMember.groupMemberUid
    |             AND pgmDestination = UserSession.usClientNodeId), 0)
    |     /*psql ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |         SET pgmPending = true
    |      */               
    |     
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |  SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |         UserSession.usClientNodeId AS pgmDestination
    |    FROM ChangeLog
    |         JOIN PersonGroupMember
    |             ON ChangeLog.chTableId = 44
    |                AND ChangeLog.chEntityPk = PersonGroupMember.groupMemberUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonGroupMember.groupMemberLct != COALESCE(
    |         (SELECT pgmVersionId
    |            FROM PersonGroupMemberReplicate
    |           WHERE pgmPk = PersonGroupMember.groupMemberUid
    |             AND pgmDestination = UserSession.usClientNodeId), 0)
    |      ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |         SET pgmPending = true
    |                     
    |     
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |  SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |         UserSession.usClientNodeId AS pgmDestination
    |    FROM ChangeLog
    |         JOIN PersonGroupMember
    |             ON ChangeLog.chTableId = 44
    |                AND ChangeLog.chEntityPk = PersonGroupMember.groupMemberUid
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroupMember.groupMemberUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |              
    |   WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |     AND PersonGroupMember.groupMemberLct != COALESCE(
    |              (SELECT pgmVersionId
    |                 FROM PersonGroupMemberReplicate
    |                WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                  AND pgmDestination = UserSession.usClientNodeId), 0)
    |  /*psql ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |     SET pgmPending = true
    |    */                   
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |  SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |         UserSession.usClientNodeId AS pgmDestination
    |    FROM ChangeLog
    |         JOIN PersonGroupMember
    |             ON ChangeLog.chTableId = 44
    |                AND ChangeLog.chEntityPk = PersonGroupMember.groupMemberUid
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroupMember.groupMemberUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |              
    |   WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |     AND PersonGroupMember.groupMemberLct != COALESCE(
    |              (SELECT pgmVersionId
    |                 FROM PersonGroupMemberReplicate
    |                WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                  AND pgmDestination = UserSession.usClientNodeId), 0)
    |   ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |     SET pgmPending = true
    |                       
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |      SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |             ? AS pgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ScopedGrant ScopedGrantEntity
    |                  ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                     AND ScopedGrantEntity.sgTableId = 6
    |             JOIN PersonGroupMember PersonGroupMemberEntity
    |                  ON PersonGroupMemberEntity.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1  
    |         AND PersonGroupMember.groupMemberLct != COALESCE(
    |             (SELECT pgmVersionId
    |                FROM PersonGroupMemberReplicate
    |               WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                 AND pgmDestination = ?), 0) 
    |      /*psql ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |             SET pgmPending = true
    |      */                
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |      SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |             ? AS pgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ScopedGrant ScopedGrantEntity
    |                  ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                     AND ScopedGrantEntity.sgTableId = 6
    |             JOIN PersonGroupMember PersonGroupMemberEntity
    |                  ON PersonGroupMemberEntity.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1  
    |         AND PersonGroupMember.groupMemberLct != COALESCE(
    |             (SELECT pgmVersionId
    |                FROM PersonGroupMemberReplicate
    |               WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                 AND pgmDestination = ?), 0) 
    |       ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |             SET pgmPending = true
    |                      
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |  SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |         UserSession.usClientNodeId AS pgmDestination
    |    FROM ChangeLog
    |         JOIN PersonGroupMember
    |             ON ChangeLog.chTableId = 44
    |                AND ChangeLog.chEntityPk = PersonGroupMember.groupMemberUid
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroupMember.groupMemberUid = ScopedGrantEntity.sgGroupUid
    |         JOIN School 
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |              536870912
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |        
    |   WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |     AND PersonGroupMember.groupMemberLct != COALESCE(
    |              (SELECT pgmVersionId
    |                 FROM PersonGroupMemberReplicate
    |                WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                  AND pgmDestination = UserSession.usClientNodeId), 0)
    |  /*psql ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |     SET pgmPending = true
    |    */                   
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |  SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |         UserSession.usClientNodeId AS pgmDestination
    |    FROM ChangeLog
    |         JOIN PersonGroupMember
    |             ON ChangeLog.chTableId = 44
    |                AND ChangeLog.chEntityPk = PersonGroupMember.groupMemberUid
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroupMember.groupMemberUid = ScopedGrantEntity.sgGroupUid
    |         JOIN School 
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |              536870912
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |        
    |   WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |     AND PersonGroupMember.groupMemberLct != COALESCE(
    |              (SELECT pgmVersionId
    |                 FROM PersonGroupMemberReplicate
    |                WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                  AND pgmDestination = UserSession.usClientNodeId), 0)
    |   ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |     SET pgmPending = true
    |                       
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |      SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |             ? AS pgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                  536870912
    |                  
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |             JOIN ScopedGrant ScopedGrantEntity
    |                  ON School.schoolUid = ScopedGrantEntity.sgEntityUid
    |                     AND ScopedGrantEntity.sgTableId = 164
    |             JOIN PersonGroupMember PersonGroupMemberEntity
    |                  ON PersonGroupMemberEntity.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1  
    |         AND PersonGroupMember.groupMemberLct != COALESCE(
    |             (SELECT pgmVersionId
    |                FROM PersonGroupMemberReplicate
    |               WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                 AND pgmDestination = ?), 0) 
    |      /*psql ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |             SET pgmPending = true
    |      */                
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupMemberReplicate(pgmPk, pgmDestination)
    |      SELECT DISTINCT PersonGroupMember.groupMemberUid AS pgmUid,
    |             ? AS pgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                  536870912
    |                  
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |             JOIN ScopedGrant ScopedGrantEntity
    |                  ON School.schoolUid = ScopedGrantEntity.sgEntityUid
    |                     AND ScopedGrantEntity.sgTableId = 164
    |             JOIN PersonGroupMember PersonGroupMemberEntity
    |                  ON PersonGroupMemberEntity.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1  
    |         AND PersonGroupMember.groupMemberLct != COALESCE(
    |             (SELECT pgmVersionId
    |                FROM PersonGroupMemberReplicate
    |               WHERE pgmPk = PersonGroupMember.groupMemberUid
    |                 AND pgmDestination = ?), 0) 
    |       ON CONFLICT(pgmPk, pgmDestination) DO UPDATE
    |             SET pgmPending = true
    |                      
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findAllGroupWherePersonIsIn(personUid: Long): List<PersonGroupMember>
      =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM PersonGroupMember WHERE groupMemberPersonUid = ? AND PersonGroupMember.groupMemberActive"
      )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_groupMemberUid = _result.getLong("groupMemberUid")
        val _tmp_groupMemberActive = _result.getBoolean("groupMemberActive")
        val _tmp_groupMemberPersonUid = _result.getLong("groupMemberPersonUid")
        val _tmp_groupMemberGroupUid = _result.getLong("groupMemberGroupUid")
        val _tmp_groupMemberMasterCsn = _result.getLong("groupMemberMasterCsn")
        val _tmp_groupMemberLocalCsn = _result.getLong("groupMemberLocalCsn")
        val _tmp_groupMemberLastChangedBy = _result.getInt("groupMemberLastChangedBy")
        val _tmp_groupMemberLct = _result.getLong("groupMemberLct")
        PersonGroupMember().apply {
          this.groupMemberUid = _tmp_groupMemberUid
          this.groupMemberActive = _tmp_groupMemberActive
          this.groupMemberPersonUid = _tmp_groupMemberPersonUid
          this.groupMemberGroupUid = _tmp_groupMemberGroupUid
          this.groupMemberMasterCsn = _tmp_groupMemberMasterCsn
          this.groupMemberLocalCsn = _tmp_groupMemberLocalCsn
          this.groupMemberLastChangedBy = _tmp_groupMemberLastChangedBy
          this.groupMemberLct = _tmp_groupMemberLct
        }
      }
    }
  }

  public override suspend fun checkPersonBelongsToGroup(groupUid: Long, personUid: Long):
      List<PersonGroupMember> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT * FROM PersonGroupMember WHERE groupMemberGroupUid = ? 
  |             AND groupMemberPersonUid = ? AND PersonGroupMember.groupMemberActive
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,groupUid)
    _stmt.setLong(2,personUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_groupMemberUid = _result.getLong("groupMemberUid")
        val _tmp_groupMemberActive = _result.getBoolean("groupMemberActive")
        val _tmp_groupMemberPersonUid = _result.getLong("groupMemberPersonUid")
        val _tmp_groupMemberGroupUid = _result.getLong("groupMemberGroupUid")
        val _tmp_groupMemberMasterCsn = _result.getLong("groupMemberMasterCsn")
        val _tmp_groupMemberLocalCsn = _result.getLong("groupMemberLocalCsn")
        val _tmp_groupMemberLastChangedBy = _result.getInt("groupMemberLastChangedBy")
        val _tmp_groupMemberLct = _result.getLong("groupMemberLct")
        PersonGroupMember().apply {
          this.groupMemberUid = _tmp_groupMemberUid
          this.groupMemberActive = _tmp_groupMemberActive
          this.groupMemberPersonUid = _tmp_groupMemberPersonUid
          this.groupMemberGroupUid = _tmp_groupMemberGroupUid
          this.groupMemberMasterCsn = _tmp_groupMemberMasterCsn
          this.groupMemberLocalCsn = _tmp_groupMemberLocalCsn
          this.groupMemberLastChangedBy = _tmp_groupMemberLastChangedBy
          this.groupMemberLct = _tmp_groupMemberLct
        }
      }
    }
  }

  public override suspend fun moveGroupAsync(
    personUid: Long,
    newGroup: Long,
    oldGroup: Long,
    changeTime: Long,
  ): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        UPDATE PersonGroupMember 
  |           SET groupMemberGroupUid = ?,
  |               groupMemberLct = ?
  |         WHERE groupMemberPersonUid = ? 
  |           AND groupMemberGroupUid = ? 
  |           AND PersonGroupMember.groupMemberActive
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,newGroup)
    _stmt.setLong(2,changeTime)
    _stmt.setLong(3,personUid)
    _stmt.setLong(4,oldGroup)
    _stmt.executeUpdateAsyncKmp()
  }

  public override suspend fun updateGroupMemberActive(
    activeStatus: Boolean,
    personUid: Long,
    groupUid: Long,
    updateTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE PersonGroupMember 
    |           SET groupMemberActive = ?,
    |               groupMemberLct = ?
    |        WHERE groupMemberPersonUid = ? 
    |          AND groupMemberGroupUid = ? 
    |          AND PersonGroupMember.groupMemberActive
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,activeStatus)
      _stmt.setLong(2,updateTime)
      _stmt.setLong(3,personUid)
      _stmt.setLong(4,groupUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByPersonUidAndGroupUid(personUid: Long, groupUid: Long):
      PersonGroupMember? = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT PersonGroupMember.*
  |          FROM PersonGroupMember
  |         WHERE PersonGroupMember.groupMemberPersonUid = ?
  |           AND PersonGroupMember.groupMemberGroupUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.setLong(2,groupUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_groupMemberUid = _result.getLong("groupMemberUid")
        val _tmp_groupMemberActive = _result.getBoolean("groupMemberActive")
        val _tmp_groupMemberPersonUid = _result.getLong("groupMemberPersonUid")
        val _tmp_groupMemberGroupUid = _result.getLong("groupMemberGroupUid")
        val _tmp_groupMemberMasterCsn = _result.getLong("groupMemberMasterCsn")
        val _tmp_groupMemberLocalCsn = _result.getLong("groupMemberLocalCsn")
        val _tmp_groupMemberLastChangedBy = _result.getInt("groupMemberLastChangedBy")
        val _tmp_groupMemberLct = _result.getLong("groupMemberLct")
        PersonGroupMember().apply {
          this.groupMemberUid = _tmp_groupMemberUid
          this.groupMemberActive = _tmp_groupMemberActive
          this.groupMemberPersonUid = _tmp_groupMemberPersonUid
          this.groupMemberGroupUid = _tmp_groupMemberGroupUid
          this.groupMemberMasterCsn = _tmp_groupMemberMasterCsn
          this.groupMemberLocalCsn = _tmp_groupMemberLocalCsn
          this.groupMemberLastChangedBy = _tmp_groupMemberLastChangedBy
          this.groupMemberLct = _tmp_groupMemberLct
        }
      }
    }
  }
}
