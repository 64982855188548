package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoin
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLangName
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLanguage
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContentEntryRelatedEntryJoinDao : BaseDao<ContentEntryRelatedEntryJoin>
    {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun publicContentEntryRelatedEntryJoins():
      List<ContentEntryRelatedEntryJoin>

  public actual abstract fun findPrimaryByTranslation(contentEntryUid: Long):
      ContentEntryRelatedEntryJoin?

  public actual abstract suspend fun findAllTranslationsForContentEntryAsync(contentEntryUid: Long):
      List<ContentEntryRelatedEntryJoinWithLangName>

  public actual abstract fun findAllTranslationsWithContentEntryUid(contentEntryUid: Long):
      DataSourceFactory<Int, ContentEntryRelatedEntryJoinWithLanguage>

  public actual abstract override fun update(entity: ContentEntryRelatedEntryJoin): Unit
}
