package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ClazzLog
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzLogDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzLogDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzLogDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override fun replace(entity: ClazzLog): Long {
    val _result = _dao.replace(entity)
    return _result
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUid(uid: Long): ClazzLog? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): ClazzLog? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun findByUidLive(uid: Long): LiveData<ClazzLog?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      DataSourceFactory<Int, ClazzLog> {
    val _result = _dao.findByClazzUidAsFactory(clazzUid, excludeStatus)
    return _result
  }

  public override suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int):
      List<ClazzLog> {
    val _result = _dao.findByClazzUidAsync(clazzUid, excludeStatus)
    return _result
  }

  public override suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> {
    val _result = _dao.findByClazzUidWithinTimeRangeAsync(clazzUid, fromTime, toTime,
        excludeStatusFilter, limit)
    return _result
  }

  public override fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> {
    val _result = _dao.findByClazzUidWithinTimeRange(clazzUid, fromTime, toTime,
        excludeStatusFilter, limit)
    return _result
  }

  public override fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int,
  ): LiveData<List<ClazzLog>> {
    val _result = _dao.findByClazzUidWithinTimeRangeLive(clazzUid, fromTime, toTime, statusFilter)
    return _result
  }

  public override fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int):
      LiveData<Boolean> {
    val _result = _dao.clazzHasScheduleLive(clazzUid, excludeStatusFilter)
    return _result
  }

  public override fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long,
  ): Unit {
    _dao.updateStatusByClazzLogUid(clazzLogUid, newStatus, timeChanged)
  }

  public override suspend fun updateAsync(clazzLog: ClazzLog): Unit {
    _dao.updateAsync(clazzLog)
  }

  public override fun insert(entity: ClazzLog): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzLog): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<ClazzLog>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<ClazzLog>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ClazzLog): Unit {
    _dao.update(entity)
  }
}
