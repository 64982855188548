package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContentEntryParentChildJoinDao : BaseDao<ContentEntryParentChildJoin> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(entityList: List<ContentEntryParentChildJoin>):
      Unit

  public actual abstract fun publicContentEntryParentChildJoins(): List<ContentEntryParentChildJoin>

  public actual abstract fun all(): List<ContentEntryParentChildJoin>

  public actual abstract fun findParentByChildUuids(childEntryContentUid: Long):
      ContentEntryParentChildJoin?

  public actual abstract fun findListOfParentsByChildUuid(childEntryContentUid: Long):
      List<ContentEntryParentChildJoin>

  public actual abstract fun findListOfChildsByParentUuid(parentUid: Long):
      List<ContentEntryParentChildJoin>

  public actual abstract fun findJoinByParentChildUuids(parentUid: Long, childUid: Long):
      ContentEntryParentChildJoin?

  public actual abstract suspend fun selectTopEntries(): List<ContentEntry>

  public actual abstract fun replaceList(entries: List<ContentEntryParentChildJoin>): Unit

  public actual abstract fun insertWithReplace(parentChildJoinDao: ContentEntryParentChildJoin):
      Unit

  public actual abstract suspend fun moveListOfEntriesToNewParent(
    contentEntryUid: Long,
    selectedItems: List<Long>,
    updateTime: Long,
  ): Unit
}
