package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ParentEnrolmentRequired
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonParentJoinWithMinorPerson
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class PersonParentJoinDao {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertAsync(entity: PersonParentJoin): Long

  public actual abstract suspend fun findByUidWithMinorAsync(uid: Long):
      PersonParentJoinWithMinorPerson?

  public actual abstract suspend fun findByUidWithMinorAsyncFromWeb(uid: Long):
      PersonParentJoinWithMinorPerson?

  public actual abstract suspend fun findByMinorPersonUid(minorPersonUid: Long):
      List<PersonParentJoin>

  public actual abstract suspend
      fun findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid: Long,
      clazzUidFilter: Long): List<ParentEnrolmentRequired>

  public actual abstract suspend fun isParentOf(userPersonUid: Long, minorPersonUid: Long): Boolean

  public actual abstract suspend fun updateAsync(personParentJoin: PersonParentJoin): Unit

  public actual abstract suspend fun isMinorApproved(minorPersonUid: Long): Boolean
}
