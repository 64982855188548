package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import kotlin.Long
import kotlin.Unit

public actual abstract class CourseAssignmentMarkDao : BaseDao<CourseAssignmentMark> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long,
      submitterUid: Long): LiveData<CourseAssignmentMark?>

  public actual abstract fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark?

  public actual abstract suspend fun findNextSubmitterToMarkForAssignment(assignmentUid: Long,
      submitterUid: Long): Long
}
