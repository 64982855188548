package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.MessageRead
import kotlin.Long
import kotlin.Unit

public actual abstract class MessageReadDao : BaseDao<MessageRead> {
  public actual abstract suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit
}
