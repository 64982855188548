package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Language
import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SiteTermsDao_JdbcKt(
  public val _db: RoomDatabase,
) : SiteTermsDao() {
  public val _insertAdapterSiteTerms_: EntityInsertionAdapter<SiteTerms> = object :
      EntityInsertionAdapter<SiteTerms>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO SiteTerms (sTermsUid, termsHtml, sTermsLang, sTermsLangUid, sTermsActive, sTermsLastChangedBy, sTermsPrimaryCsn, sTermsLocalCsn, sTermsLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: SiteTerms): Unit {
      if(entity.sTermsUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.sTermsUid)
      }
      stmt.setString(2, entity.termsHtml)
      stmt.setString(3, entity.sTermsLang)
      stmt.setLong(4, entity.sTermsLangUid)
      stmt.setBoolean(5, entity.sTermsActive)
      stmt.setInt(6, entity.sTermsLastChangedBy)
      stmt.setLong(7, entity.sTermsPrimaryCsn)
      stmt.setLong(8, entity.sTermsLocalCsn)
      stmt.setLong(9, entity.sTermsLct)
    }
  }

  public override suspend fun insertAsync(siteTerms: SiteTerms): Long {
    val _retVal = _insertAdapterSiteTerms_.insertAndReturnIdAsync(siteTerms)
    return _retVal
  }

  public override suspend fun insertListAsync(entityList: List<SiteTerms>): Unit {
    _insertAdapterSiteTerms_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<SiteTerms>): Unit {
    val _sql =
        "UPDATE SiteTerms SET termsHtml = ?, sTermsLang = ?, sTermsLangUid = ?, sTermsActive = ?, sTermsLastChangedBy = ?, sTermsPrimaryCsn = ?, sTermsLocalCsn = ?, sTermsLct = ? WHERE sTermsUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.termsHtml)
        _stmt.setString(2, _entity.sTermsLang)
        _stmt.setLong(3, _entity.sTermsLangUid)
        _stmt.setBoolean(4, _entity.sTermsActive)
        _stmt.setInt(5, _entity.sTermsLastChangedBy)
        _stmt.setLong(6, _entity.sTermsPrimaryCsn)
        _stmt.setLong(7, _entity.sTermsLocalCsn)
        _stmt.setLong(8, _entity.sTermsLct)
        _stmt.setLong(9, _entity.sTermsUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO SiteTermsReplicate(stPk, stDestination)
    |      SELECT DISTINCT SiteTerms.sTermsUid AS stPk,
    |             ? AS stDestination
    |        FROM SiteTerms
    |       WHERE SiteTerms.sTermsLct != COALESCE(
    |             (SELECT stVersionId
    |                FROM SiteTermsReplicate
    |               WHERE stPk = SiteTerms.sTermsUid
    |                 AND stDestination = ?), 0) 
    |      /*psql ON CONFLICT(stPk, stDestination) DO UPDATE
    |             SET stPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteTermsReplicate(stPk, stDestination)
    |      SELECT DISTINCT SiteTerms.sTermsUid AS stPk,
    |             ? AS stDestination
    |        FROM SiteTerms
    |       WHERE SiteTerms.sTermsLct != COALESCE(
    |             (SELECT stVersionId
    |                FROM SiteTermsReplicate
    |               WHERE stPk = SiteTerms.sTermsUid
    |                 AND stDestination = ?), 0) 
    |       ON CONFLICT(stPk, stDestination) DO UPDATE
    |             SET stPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO SiteTermsReplicate(stPk, stDestination)
    |  SELECT DISTINCT SiteTerms.sTermsUid AS stUid,
    |         UserSession.usClientNodeId AS stDestination
    |    FROM ChangeLog
    |         JOIN SiteTerms
    |             ON ChangeLog.chTableId = 272
    |                AND ChangeLog.chEntityPk = SiteTerms.sTermsUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SiteTerms.sTermsLct != COALESCE(
    |         (SELECT stVersionId
    |            FROM SiteTermsReplicate
    |           WHERE stPk = SiteTerms.sTermsUid
    |             AND stDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(stPk, stDestination) DO UPDATE
    |     SET stPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteTermsReplicate(stPk, stDestination)
    |  SELECT DISTINCT SiteTerms.sTermsUid AS stUid,
    |         UserSession.usClientNodeId AS stDestination
    |    FROM ChangeLog
    |         JOIN SiteTerms
    |             ON ChangeLog.chTableId = 272
    |                AND ChangeLog.chEntityPk = SiteTerms.sTermsUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SiteTerms.sTermsLct != COALESCE(
    |         (SELECT stVersionId
    |            FROM SiteTermsReplicate
    |           WHERE stPk = SiteTerms.sTermsUid
    |             AND stDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(stPk, stDestination) DO UPDATE
    |     SET stPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findSiteTerms(langCode: String): SiteTerms? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * FROM SiteTerms WHERE sTermsUid = coalesce(
  |            (SELECT sTermsUid FROM SiteTerms st_int WHERE st_int.sTermsLang = ? LIMIT 1),
  |            (SELECT sTermsUid FROM SiteTerms st_int WHERE st_int.sTermsLang = 'en' LIMIT 1),
  |            0)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setString(1,langCode)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_sTermsUid = _result.getLong("sTermsUid")
        val _tmp_termsHtml = _result.getString("termsHtml")
        val _tmp_sTermsLang = _result.getString("sTermsLang")
        val _tmp_sTermsLangUid = _result.getLong("sTermsLangUid")
        val _tmp_sTermsActive = _result.getBoolean("sTermsActive")
        val _tmp_sTermsLastChangedBy = _result.getInt("sTermsLastChangedBy")
        val _tmp_sTermsPrimaryCsn = _result.getLong("sTermsPrimaryCsn")
        val _tmp_sTermsLocalCsn = _result.getLong("sTermsLocalCsn")
        val _tmp_sTermsLct = _result.getLong("sTermsLct")
        SiteTerms().apply {
          this.sTermsUid = _tmp_sTermsUid
          this.termsHtml = _tmp_termsHtml
          this.sTermsLang = _tmp_sTermsLang
          this.sTermsLangUid = _tmp_sTermsLangUid
          this.sTermsActive = _tmp_sTermsActive
          this.sTermsLastChangedBy = _tmp_sTermsLastChangedBy
          this.sTermsPrimaryCsn = _tmp_sTermsPrimaryCsn
          this.sTermsLocalCsn = _tmp_sTermsLocalCsn
          this.sTermsLct = _tmp_sTermsLct
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): SiteTerms? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM SiteTerms WHERE sTermsUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_sTermsUid = _result.getLong("sTermsUid")
        val _tmp_termsHtml = _result.getString("termsHtml")
        val _tmp_sTermsLang = _result.getString("sTermsLang")
        val _tmp_sTermsLangUid = _result.getLong("sTermsLangUid")
        val _tmp_sTermsActive = _result.getBoolean("sTermsActive")
        val _tmp_sTermsLastChangedBy = _result.getInt("sTermsLastChangedBy")
        val _tmp_sTermsPrimaryCsn = _result.getLong("sTermsPrimaryCsn")
        val _tmp_sTermsLocalCsn = _result.getLong("sTermsLocalCsn")
        val _tmp_sTermsLct = _result.getLong("sTermsLct")
        SiteTerms().apply {
          this.sTermsUid = _tmp_sTermsUid
          this.termsHtml = _tmp_termsHtml
          this.sTermsLang = _tmp_sTermsLang
          this.sTermsLangUid = _tmp_sTermsLangUid
          this.sTermsActive = _tmp_sTermsActive
          this.sTermsLastChangedBy = _tmp_sTermsLastChangedBy
          this.sTermsPrimaryCsn = _tmp_sTermsPrimaryCsn
          this.sTermsLocalCsn = _tmp_sTermsLocalCsn
          this.sTermsLct = _tmp_sTermsLct
        }
      }
    }
  }

  public override fun findAllTermsAsFactory(): DataSourceFactory<Int, SiteTermsWithLanguage> =
      object : DataSourceFactory<Int, SiteTermsWithLanguage>() {
    public override fun getData(_offset: Int, _limit: Int): LiveData<List<SiteTermsWithLanguage>> =
        LiveDataImpl(_db, listOf("SiteTerms", "Language"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT SiteTerms.*, Language.* 
      |        FROM SiteTerms 
      |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
      |        WHERE CAST(sTermsActive AS INTEGER) = 1
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,_limit)
        _stmt.setInt(2,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_sTermsUid = _result.getLong("sTermsUid")
            val _tmp_termsHtml = _result.getString("termsHtml")
            val _tmp_sTermsLang = _result.getString("sTermsLang")
            val _tmp_sTermsLangUid = _result.getLong("sTermsLangUid")
            val _tmp_sTermsActive = _result.getBoolean("sTermsActive")
            val _tmp_sTermsLastChangedBy = _result.getInt("sTermsLastChangedBy")
            val _tmp_sTermsPrimaryCsn = _result.getLong("sTermsPrimaryCsn")
            val _tmp_sTermsLocalCsn = _result.getLong("sTermsLocalCsn")
            val _tmp_sTermsLct = _result.getLong("sTermsLct")
            var _tmp_Language_nullCount = 0
            val _tmp_langUid = _result.getLong("langUid")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_name = _result.getString("name")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_Language_Type = _result.getString("Language_Type")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_languageActive = _result.getBoolean("languageActive")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_langLct = _result.getLong("langLct")
            if(_result.wasNull()) _tmp_Language_nullCount++
            val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
            SiteTermsWithLanguage().apply {
              this.sTermsUid = _tmp_sTermsUid
              this.termsHtml = _tmp_termsHtml
              this.sTermsLang = _tmp_sTermsLang
              this.sTermsLangUid = _tmp_sTermsLangUid
              this.sTermsActive = _tmp_sTermsActive
              this.sTermsLastChangedBy = _tmp_sTermsLastChangedBy
              this.sTermsPrimaryCsn = _tmp_sTermsPrimaryCsn
              this.sTermsLocalCsn = _tmp_sTermsLocalCsn
              this.sTermsLct = _tmp_sTermsLct
              if(!_tmp_Language_isAllNull) {
                this.stLanguage = Language().apply {
                  this.langUid = _tmp_langUid
                  this.name = _tmp_name
                  this.iso_639_1_standard = _tmp_iso_639_1_standard
                  this.iso_639_2_standard = _tmp_iso_639_2_standard
                  this.iso_639_3_standard = _tmp_iso_639_3_standard
                  this.Language_Type = _tmp_Language_Type
                  this.languageActive = _tmp_languageActive
                  this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
                  this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
                  this.langLastChangedBy = _tmp_langLastChangedBy
                  this.langLct = _tmp_langLct
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("SiteTerms",
        "Language"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT SiteTerms.*, Language.* 
      |        FROM SiteTerms 
      |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
      |        WHERE CAST(sTermsActive AS INTEGER) = 1
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT SiteTerms.*, Language.*
  |        FROM SiteTerms
  |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
  |        WHERE CAST(sTermsActive AS INTEGER) = 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_sTermsUid = _result.getLong("sTermsUid")
        val _tmp_termsHtml = _result.getString("termsHtml")
        val _tmp_sTermsLang = _result.getString("sTermsLang")
        val _tmp_sTermsLangUid = _result.getLong("sTermsLangUid")
        val _tmp_sTermsActive = _result.getBoolean("sTermsActive")
        val _tmp_sTermsLastChangedBy = _result.getInt("sTermsLastChangedBy")
        val _tmp_sTermsPrimaryCsn = _result.getLong("sTermsPrimaryCsn")
        val _tmp_sTermsLocalCsn = _result.getLong("sTermsLocalCsn")
        val _tmp_sTermsLct = _result.getLong("sTermsLct")
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        SiteTermsWithLanguage().apply {
          this.sTermsUid = _tmp_sTermsUid
          this.termsHtml = _tmp_termsHtml
          this.sTermsLang = _tmp_sTermsLang
          this.sTermsLangUid = _tmp_sTermsLangUid
          this.sTermsActive = _tmp_sTermsActive
          this.sTermsLastChangedBy = _tmp_sTermsLastChangedBy
          this.sTermsPrimaryCsn = _tmp_sTermsPrimaryCsn
          this.sTermsLocalCsn = _tmp_sTermsLocalCsn
          this.sTermsLct = _tmp_sTermsLct
          if(!_tmp_Language_isAllNull) {
            this.stLanguage = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
        }
      }
    }
  }

  public override suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE SiteTerms 
    |           SET sTermsActive = ?,
    |               sTermsLct = ?
    |         WHERE sTermsUid = ?
    |        
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,sTermsUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
