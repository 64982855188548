package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContextXObjectStatementJoin
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContextXObjectStatementJoinDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContextXObjectStatementJoinDao,
) : ContextXObjectStatementJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByStatementAndObjectUid(statementUid: Long, objectUid: Long):
      ContextXObjectStatementJoin? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: ContextXObjectStatementJoin): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContextXObjectStatementJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.contextXObjectStatementJoinUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(66)
      entity.contextXObjectStatementJoinUid = _newPk
    }
    entity.contextXObjectLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.contextXObjectStatementJoinUid
  }

  public override fun insertList(entityList: List<ContextXObjectStatementJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ContextXObjectStatementJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContextXObjectStatementJoin): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
