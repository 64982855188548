package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithMetrics
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.CourseBlockWithCompleteEntity
import com.ustadmobile.lib.db.entities.CourseBlockWithEntityDb
import com.ustadmobile.lib.db.entities.CourseDiscussion
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseBlockDao_JdbcKt(
  public val _db: RoomDatabase,
) : CourseBlockDao() {
  public val _insertAdapterCourseBlock_upsert: EntityInsertionAdapter<CourseBlock> = object :
      EntityInsertionAdapter<CourseBlock>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbActive, cbHidden, cbEntityUid, cbLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseBlock):
        Unit {
      if(entity.cbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cbUid)
      }
      stmt.setInt(2, entity.cbType)
      stmt.setInt(3, entity.cbIndentLevel)
      stmt.setLong(4, entity.cbModuleParentBlockUid)
      stmt.setString(5, entity.cbTitle)
      stmt.setString(6, entity.cbDescription)
      stmt.setInt(7, entity.cbCompletionCriteria)
      stmt.setLong(8, entity.cbHideUntilDate)
      stmt.setLong(9, entity.cbDeadlineDate)
      stmt.setInt(10, entity.cbLateSubmissionPenalty)
      stmt.setLong(11, entity.cbGracePeriodDate)
      stmt.setInt(12, entity.cbMaxPoints)
      stmt.setInt(13, entity.cbMinPoints)
      stmt.setInt(14, entity.cbIndex)
      stmt.setLong(15, entity.cbClazzUid)
      stmt.setBoolean(16, entity.cbActive)
      stmt.setBoolean(17, entity.cbHidden)
      stmt.setLong(18, entity.cbEntityUid)
      stmt.setLong(19, entity.cbLct)
    }
  }

  public val _insertAdapterCourseBlock_: EntityInsertionAdapter<CourseBlock> = object :
      EntityInsertionAdapter<CourseBlock>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbActive, cbHidden, cbEntityUid, cbLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseBlock):
        Unit {
      if(entity.cbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cbUid)
      }
      stmt.setInt(2, entity.cbType)
      stmt.setInt(3, entity.cbIndentLevel)
      stmt.setLong(4, entity.cbModuleParentBlockUid)
      stmt.setString(5, entity.cbTitle)
      stmt.setString(6, entity.cbDescription)
      stmt.setInt(7, entity.cbCompletionCriteria)
      stmt.setLong(8, entity.cbHideUntilDate)
      stmt.setLong(9, entity.cbDeadlineDate)
      stmt.setInt(10, entity.cbLateSubmissionPenalty)
      stmt.setLong(11, entity.cbGracePeriodDate)
      stmt.setInt(12, entity.cbMaxPoints)
      stmt.setInt(13, entity.cbMinPoints)
      stmt.setInt(14, entity.cbIndex)
      stmt.setLong(15, entity.cbClazzUid)
      stmt.setBoolean(16, entity.cbActive)
      stmt.setBoolean(17, entity.cbHidden)
      stmt.setLong(18, entity.cbEntityUid)
      stmt.setLong(19, entity.cbLct)
    }
  }

  public override suspend fun replaceListAsync(list: List<CourseBlock>): Unit {
    _insertAdapterCourseBlock_upsert.insertListAsync(list)
  }

  public override fun insert(entity: CourseBlock): Long {
    val _retVal = _insertAdapterCourseBlock_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseBlock): Long {
    val _retVal = _insertAdapterCourseBlock_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CourseBlock>): Unit {
    _insertAdapterCourseBlock_.insertList(entityList)
  }

  public override suspend fun insertListAsync(entityList: List<CourseBlock>): Unit {
    _insertAdapterCourseBlock_.insertListAsync(entityList)
  }

  public override suspend fun updateAsync(entity: CourseBlock): Int {
    var _result = 0
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.cbType)
      _stmt.setInt(2, entity.cbIndentLevel)
      _stmt.setLong(3, entity.cbModuleParentBlockUid)
      _stmt.setString(4, entity.cbTitle)
      _stmt.setString(5, entity.cbDescription)
      _stmt.setInt(6, entity.cbCompletionCriteria)
      _stmt.setLong(7, entity.cbHideUntilDate)
      _stmt.setLong(8, entity.cbDeadlineDate)
      _stmt.setInt(9, entity.cbLateSubmissionPenalty)
      _stmt.setLong(10, entity.cbGracePeriodDate)
      _stmt.setInt(11, entity.cbMaxPoints)
      _stmt.setInt(12, entity.cbMinPoints)
      _stmt.setInt(13, entity.cbIndex)
      _stmt.setLong(14, entity.cbClazzUid)
      _stmt.setBoolean(15, entity.cbActive)
      _stmt.setBoolean(16, entity.cbHidden)
      _stmt.setLong(17, entity.cbEntityUid)
      _stmt.setLong(18, entity.cbLct)
      _stmt.setLong(19, entity.cbUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<CourseBlock>): Unit {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setInt(1, _entity.cbType)
        _stmt.setInt(2, _entity.cbIndentLevel)
        _stmt.setLong(3, _entity.cbModuleParentBlockUid)
        _stmt.setString(4, _entity.cbTitle)
        _stmt.setString(5, _entity.cbDescription)
        _stmt.setInt(6, _entity.cbCompletionCriteria)
        _stmt.setLong(7, _entity.cbHideUntilDate)
        _stmt.setLong(8, _entity.cbDeadlineDate)
        _stmt.setInt(9, _entity.cbLateSubmissionPenalty)
        _stmt.setLong(10, _entity.cbGracePeriodDate)
        _stmt.setInt(11, _entity.cbMaxPoints)
        _stmt.setInt(12, _entity.cbMinPoints)
        _stmt.setInt(13, _entity.cbIndex)
        _stmt.setLong(14, _entity.cbClazzUid)
        _stmt.setBoolean(15, _entity.cbActive)
        _stmt.setBoolean(16, _entity.cbHidden)
        _stmt.setLong(17, _entity.cbEntityUid)
        _stmt.setLong(18, _entity.cbLct)
        _stmt.setLong(19, _entity.cbUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseBlock): Unit {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.cbType)
      _stmt.setInt(2, entity.cbIndentLevel)
      _stmt.setLong(3, entity.cbModuleParentBlockUid)
      _stmt.setString(4, entity.cbTitle)
      _stmt.setString(5, entity.cbDescription)
      _stmt.setInt(6, entity.cbCompletionCriteria)
      _stmt.setLong(7, entity.cbHideUntilDate)
      _stmt.setLong(8, entity.cbDeadlineDate)
      _stmt.setInt(9, entity.cbLateSubmissionPenalty)
      _stmt.setLong(10, entity.cbGracePeriodDate)
      _stmt.setInt(11, entity.cbMaxPoints)
      _stmt.setInt(12, entity.cbMinPoints)
      _stmt.setInt(13, entity.cbIndex)
      _stmt.setLong(14, entity.cbClazzUid)
      _stmt.setBoolean(15, entity.cbActive)
      _stmt.setBoolean(16, entity.cbHidden)
      _stmt.setLong(17, entity.cbEntityUid)
      _stmt.setLong(18, entity.cbLct)
      _stmt.setLong(19, entity.cbUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateListAsync(entityList: List<CourseBlock>): Unit {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setInt(1, _entity.cbType)
        _stmt.setInt(2, _entity.cbIndentLevel)
        _stmt.setLong(3, _entity.cbModuleParentBlockUid)
        _stmt.setString(4, _entity.cbTitle)
        _stmt.setString(5, _entity.cbDescription)
        _stmt.setInt(6, _entity.cbCompletionCriteria)
        _stmt.setLong(7, _entity.cbHideUntilDate)
        _stmt.setLong(8, _entity.cbDeadlineDate)
        _stmt.setInt(9, _entity.cbLateSubmissionPenalty)
        _stmt.setLong(10, _entity.cbGracePeriodDate)
        _stmt.setInt(11, _entity.cbMaxPoints)
        _stmt.setInt(12, _entity.cbMinPoints)
        _stmt.setInt(13, _entity.cbIndex)
        _stmt.setLong(14, _entity.cbClazzUid)
        _stmt.setBoolean(15, _entity.cbActive)
        _stmt.setBoolean(16, _entity.cbHidden)
        _stmt.setLong(17, _entity.cbEntityUid)
        _stmt.setLong(18, _entity.cbLct)
        _stmt.setLong(19, _entity.cbUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |    REPLACE INTO CourseBlockReplicate(cbPk, cbDestination)
    |      SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |             ? AS cbDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN CourseBlock
    |                    ON CourseBlock.cbClazzUid = Clazz.clazzUid                
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseBlock.cbLct != COALESCE(
    |             (SELECT cbVersionId
    |                FROM CourseBlockReplicate
    |               WHERE cbPk = CourseBlock.cbUid
    |                 AND cbDestination = ?), 0) 
    |      /*psql ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |             SET cbPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseBlockReplicate(cbPk, cbDestination)
    |      SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |             ? AS cbDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN CourseBlock
    |                    ON CourseBlock.cbClazzUid = Clazz.clazzUid                
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseBlock.cbLct != COALESCE(
    |             (SELECT cbVersionId
    |                FROM CourseBlockReplicate
    |               WHERE cbPk = CourseBlock.cbUid
    |                 AND cbDestination = ?), 0) 
    |       ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |             SET cbPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         REPLACE INTO CourseBlockReplicate(cbPk, cbDestination)
    |  SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |         UserSession.usClientNodeId AS cbDestination
    |    FROM ChangeLog
    |         JOIN CourseBlock
    |             ON ChangeLog.chTableId = 124
    |                AND ChangeLog.chEntityPk = CourseBlock.cbUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = CourseBlock.cbClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseBlock.cbLct != COALESCE(
    |         (SELECT cbVersionId
    |            FROM CourseBlockReplicate
    |           WHERE cbPk = CourseBlock.cbUid
    |             AND cbDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |     SET cbPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseBlockReplicate(cbPk, cbDestination)
    |  SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |         UserSession.usClientNodeId AS cbDestination
    |    FROM ChangeLog
    |         JOIN CourseBlock
    |             ON ChangeLog.chTableId = 124
    |                AND ChangeLog.chEntityPk = CourseBlock.cbUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = CourseBlock.cbClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseBlock.cbLct != COALESCE(
    |         (SELECT cbVersionId
    |            FROM CourseBlockReplicate
    |           WHERE cbPk = CourseBlock.cbUid
    |             AND cbDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |     SET cbPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByUidAsync(uid: Long): CourseBlock? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM CourseBlock WHERE cbUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cbUid = _result.getLong("cbUid")
        val _tmp_cbType = _result.getInt("cbType")
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        val _tmp_cbTitle = _result.getString("cbTitle")
        val _tmp_cbDescription = _result.getString("cbDescription")
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        val _tmp_cbMaxPoints = _result.getInt("cbMaxPoints")
        val _tmp_cbMinPoints = _result.getInt("cbMinPoints")
        val _tmp_cbIndex = _result.getInt("cbIndex")
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        val _tmp_cbActive = _result.getBoolean("cbActive")
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        val _tmp_cbLct = _result.getLong("cbLct")
        CourseBlock().apply {
          this.cbUid = _tmp_cbUid
          this.cbType = _tmp_cbType
          this.cbIndentLevel = _tmp_cbIndentLevel
          this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
          this.cbTitle = _tmp_cbTitle
          this.cbDescription = _tmp_cbDescription
          this.cbCompletionCriteria = _tmp_cbCompletionCriteria
          this.cbHideUntilDate = _tmp_cbHideUntilDate
          this.cbDeadlineDate = _tmp_cbDeadlineDate
          this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
          this.cbGracePeriodDate = _tmp_cbGracePeriodDate
          this.cbMaxPoints = _tmp_cbMaxPoints
          this.cbMinPoints = _tmp_cbMinPoints
          this.cbIndex = _tmp_cbIndex
          this.cbClazzUid = _tmp_cbClazzUid
          this.cbActive = _tmp_cbActive
          this.cbHidden = _tmp_cbHidden
          this.cbEntityUid = _tmp_cbEntityUid
          this.cbLct = _tmp_cbLct
        }
      }
    }
  }

  public override suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long):
      List<CourseBlockWithEntityDb> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM CourseBlock 
  |               LEFT JOIN ClazzAssignment as assignment
  |               ON assignment.caUid = CourseBlock.cbEntityUid
  |               AND CourseBlock.cbType = 103
  |               LEFT JOIN CourseDiscussion as courseDiscussion
  |               ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
  |               AND CourseBlock.cbType = 105
  |               LEFT JOIN ContentEntry as entry
  |               ON entry.contentEntryUid = CourseBlock.cbEntityUid
  |               AND CourseBlock.cbType = 104
  |               
  |               LEFT JOIN Language
  |               ON Language.langUid = entry.primaryLanguageUid
  |                AND CourseBlock.cbType = 104
  |               
  |         WHERE cbClazzUid = ?
  |           AND cbActive
  |      ORDER BY cbIndex
  |          
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cbUid = _result.getLong("cbUid")
        val _tmp_cbType = _result.getInt("cbType")
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        val _tmp_cbTitle = _result.getString("cbTitle")
        val _tmp_cbDescription = _result.getString("cbDescription")
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        val _tmp_cbMaxPoints = _result.getInt("cbMaxPoints")
        val _tmp_cbMinPoints = _result.getInt("cbMinPoints")
        val _tmp_cbIndex = _result.getInt("cbIndex")
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        val _tmp_cbActive = _result.getBoolean("cbActive")
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        val _tmp_cbLct = _result.getLong("cbLct")
        var _tmp_ClazzAssignment_nullCount = 0
        val _tmp_caUid = _result.getLong("caUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caTitle = _result.getString("caTitle")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caDescription = _result.getString("caDescription")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caGroupUid = _result.getLong("caGroupUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caActive = _result.getBoolean("caActive")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caFileType = _result.getInt("caFileType")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caMarkingType = _result.getInt("caMarkingType")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caTextLimit = _result.getInt("caTextLimit")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caClazzUid = _result.getLong("caClazzUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caLct = _result.getLong("caLct")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_ClazzAssignment_isAllNull = _tmp_ClazzAssignment_nullCount == 23
        var _tmp_ContentEntry_nullCount = 0
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_title = _result.getString("title")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_description = _result.getString("description")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_entryId = _result.getString("entryId")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_author = _result.getString("author")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publisher = _result.getString("publisher")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseType = _result.getInt("licenseType")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseName = _result.getString("licenseName")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentFlags = _result.getInt("contentFlags")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_leaf = _result.getBoolean("leaf")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publik = _result.getBoolean("publik")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_minScore = _result.getInt("minScore")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentOwner = _result.getLong("contentOwner")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 26
        var _tmp_CourseDiscussion_nullCount = 0
        val _tmp_courseDiscussionUid = _result.getLong("courseDiscussionUid")
        if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
        val _tmp_courseDiscussionTitle = _result.getString("courseDiscussionTitle")
        if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
        val _tmp_courseDiscussionDesc = _result.getString("courseDiscussionDesc")
        if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
        val _tmp_courseDiscussionClazzUid = _result.getLong("courseDiscussionClazzUid")
        if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
        val _tmp_courseDiscussionActive = _result.getBoolean("courseDiscussionActive")
        if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
        val _tmp_courseDiscussionLct = _result.getLong("courseDiscussionLct")
        if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
        val _tmp_CourseDiscussion_isAllNull = _tmp_CourseDiscussion_nullCount == 6
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        CourseBlockWithEntityDb().apply {
          this.cbUid = _tmp_cbUid
          this.cbType = _tmp_cbType
          this.cbIndentLevel = _tmp_cbIndentLevel
          this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
          this.cbTitle = _tmp_cbTitle
          this.cbDescription = _tmp_cbDescription
          this.cbCompletionCriteria = _tmp_cbCompletionCriteria
          this.cbHideUntilDate = _tmp_cbHideUntilDate
          this.cbDeadlineDate = _tmp_cbDeadlineDate
          this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
          this.cbGracePeriodDate = _tmp_cbGracePeriodDate
          this.cbMaxPoints = _tmp_cbMaxPoints
          this.cbMinPoints = _tmp_cbMinPoints
          this.cbIndex = _tmp_cbIndex
          this.cbClazzUid = _tmp_cbClazzUid
          this.cbActive = _tmp_cbActive
          this.cbHidden = _tmp_cbHidden
          this.cbEntityUid = _tmp_cbEntityUid
          this.cbLct = _tmp_cbLct
          if(!_tmp_ClazzAssignment_isAllNull) {
            this.assignment = ClazzAssignment().apply {
              this.caUid = _tmp_caUid
              this.caTitle = _tmp_caTitle
              this.caDescription = _tmp_caDescription
              this.caGroupUid = _tmp_caGroupUid
              this.caActive = _tmp_caActive
              this.caClassCommentEnabled = _tmp_caClassCommentEnabled
              this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
              this.caCompletionCriteria = _tmp_caCompletionCriteria
              this.caRequireFileSubmission = _tmp_caRequireFileSubmission
              this.caFileType = _tmp_caFileType
              this.caSizeLimit = _tmp_caSizeLimit
              this.caNumberOfFiles = _tmp_caNumberOfFiles
              this.caSubmissionPolicy = _tmp_caSubmissionPolicy
              this.caMarkingType = _tmp_caMarkingType
              this.caRequireTextSubmission = _tmp_caRequireTextSubmission
              this.caTextLimitType = _tmp_caTextLimitType
              this.caTextLimit = _tmp_caTextLimit
              this.caXObjectUid = _tmp_caXObjectUid
              this.caClazzUid = _tmp_caClazzUid
              this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
              this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
              this.caLastChangedBy = _tmp_caLastChangedBy
              this.caLct = _tmp_caLct
            }
          }
          if(!_tmp_ContentEntry_isAllNull) {
            this.entry = ContentEntry().apply {
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
            }
          }
          if(!_tmp_CourseDiscussion_isAllNull) {
            this.courseDiscussion = CourseDiscussion().apply {
              this.courseDiscussionUid = _tmp_courseDiscussionUid
              this.courseDiscussionTitle = _tmp_courseDiscussionTitle
              this.courseDiscussionDesc = _tmp_courseDiscussionDesc
              this.courseDiscussionClazzUid = _tmp_courseDiscussionClazzUid
              this.courseDiscussionActive = _tmp_courseDiscussionActive
              this.courseDiscussionLct = _tmp_courseDiscussionLct
            }
          }
          if(!_tmp_Language_isAllNull) {
            this.language = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
        }
      }
    }
  }

  public override fun findAllCourseBlockByClazzUidLive(
    clazzUid: Long,
    personUid: Long,
    collapseList: List<Long>,
    currentTime: Long,
  ): DataSourceFactory<Int, CourseBlockWithCompleteEntity> = object :
      DataSourceFactory<Int, CourseBlockWithCompleteEntity>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<CourseBlockWithCompleteEntity>> = LiveDataImpl(_db, listOf("CourseBlock",
        "ClazzAssignment", "CourseDiscussion", "ContentEntry", "CourseAssignmentMark",
        "StatementEntity", "Container", "ContentEntryParentChildJoin", "PersonGroupMember", "Clazz",
        "ScopedGrant", "ClazzEnrolment", "CourseAssignmentSubmission", "CourseGroupMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |         WITH CtePermissionCheck (hasPermission) 
      |            AS (SELECT EXISTS( 
      |               SELECT PrsGrpMbr.groupMemberPersonUid
      |                  FROM Clazz
      |                       
      |            JOIN ScopedGrant
      |                 ON 
      |            ((ScopedGrant.sgTableId = -2
      |                                AND ScopedGrant.sgEntityUid = -2)
      |                            OR (ScopedGrant.sgTableId = 6
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
      |                            OR (ScopedGrant.sgTableId = 164
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
      |        
      |                    AND (ScopedGrant.sgPermissions & 
      |        
      |                          549755813888
      |                          
      |                                                       ) > 0
      |             JOIN PersonGroupMember AS PrsGrpMbr
      |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
      |        
      |                 WHERE Clazz.clazzUid = ?
      |                   AND PrsGrpMbr.groupMemberPersonUid = ?)), 
      |                   
      |        
      |            SubmitterList (submitterId, assignmentUid)
      |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId,
      |                       ClazzAssignment.caUid AS assignmentUid
      |                  
      |                  FROM ClazzEnrolment
      |                  
      |                       JOIN Person 
      |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
      |                        
      |                       JOIN ClazzAssignment
      |                       ON ClazzAssignment.caClazzUid = ?
      |
      |                       JOIN CourseBlock
      |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
      |                       AND CourseBlock.cbType = 103
      |                       
      |                 WHERE ClazzAssignment.caGroupUid = 0
      |                   AND clazzEnrolmentClazzUid = ?
      |                   AND clazzEnrolmentActive
      |                   AND clazzEnrolmentRole = 1000
      |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
      |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
      |              GROUP BY submitterId, assignmentUid
      |            UNION                 
      |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
      |                    ClazzAssignment.caUid AS assignmentUid
      |               FROM CourseGroupMember
      |                    JOIN ClazzAssignment
      |                    ON ClazzAssignment.caClazzUid = ?
      |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
      |                AND ClazzAssignment.caGroupUid != 0
      |                AND CourseGroupMember.cgmGroupNumber != 0
      |           GROUP BY submitterId, assignmentUid
      |            )
      |                   
      |                   
      |
      |        SELECT CourseBlock.*, ClazzAssignment.*, ContentEntry.*, CourseDiscussion.*, ContentEntryParentChildJoin.*, 
      |               Container.*, CourseAssignmentMark.*, (CourseBlock.cbUid NOT IN (?)) AS expanded,
      |               
      |               COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                
      |                0 AS assignmentContentWeight,
      |                1 as totalContent, 
      |                0 as penalty,
      |                
      |                (SELECT hasPermission FROM CtePermissionCheck) AS hasMetricsPermission,
      |                
      |             
      |                 (SELECT COUNT(*) 
      |                    FROM SubmitterList 
      |                   WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid) 
      |                        AS totalStudents, 
      | 
      |               0 AS notSubmittedStudents,
      |               
      |               (CASE WHEN (SELECT hasPermission 
      |                          FROM CtePermissionCheck)
      |                     THEN (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
      |                             FROM CourseAssignmentSubmission
      |                                   LEFT JOIN CourseAssignmentMark
      |                                   ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                                   AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
      |                            WHERE CourseAssignmentMark.camUid IS NULL
      |                              AND CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
      |                              AND CourseAssignmentSubmission.casSubmitterUid IN 
      |                                                    (SELECT submitterId 
      |                                                      FROM SubmitterList
      |                                                     WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))  
      |                      ELSE 0 END) AS submittedStudents,         
      |               
      |                (CASE WHEN (SELECT hasPermission 
      |                           FROM CtePermissionCheck)       
      |                   THEN (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
      |                           FROM CourseAssignmentMark
      |                            
      |                             JOIN CourseAssignmentSubmission
      |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
      |                             
      |                          WHERE CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid 
      |                            AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
      |                                                                            FROM SubmitterList
      |                                                                           WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))
      |                   ELSE 0 END) AS markedStudents,
      |                   
      |                   COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
      |                          THEN 2 
      |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
      |                          THEN 1 
      |                          ELSE 0 END), 
      |                               0) AS fileSubmissionStatus
      |                
      |                
      |          FROM CourseBlock 
      |          
      |               LEFT JOIN CourseBlock AS parentBlock
      |               ON CourseBlock.cbModuleParentBlockUid = parentBlock.cbUid
      |               AND CourseBlock.cbTYpe != 100
      |          
      |               LEFT JOIN ClazzAssignment
      |               ON ClazzAssignment.caUid = CourseBlock.cbEntityUid
      |               AND CourseBlock.cbType = 103
      |               
      |               LEFT JOIN ContentEntry
      |               ON ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               AND NOT ceInactive
      |               AND CourseBlock.cbType = 104
      |               
      |               LEFT JOIN CourseDiscussion 
      |                      ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
      |                     AND CourseBlock.cbType = 105
      |               
      |               LEFT JOIN ContentEntryParentChildJoin 
      |               ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
      |               
      |               LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)
      |                       
      |              LEFT JOIN StatementEntity
      |				     ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, 
      |                                        extensionProgress DESC, 
      |                                        resultSuccess DESC 
      |                                  LIMIT 1) 
      |                                  
      |               LEFT JOIN CourseAssignmentSubmission
      |                ON casUid = (SELECT casUid 
      |                                     FROM CourseAssignmentSubmission
      |                                    WHERE casAssignmentUid = ClazzAssignment.caUid
      |                                      AND casSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
      |                                                                          THEN ? 
      |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
      |                                                                          THEN 0 
      |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
      |                                                                          END) as submitterUid
      |                                                               FROM ClazzAssignment AS ref
      |                                                                    LEFT JOIN CourseGroupMember
      |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
      |                                                                     AND cgmPersonUid = ?
      |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
      |                                 ORDER BY casTimestamp DESC
      |                                    LIMIT 1)
      |                                          
      |               LEFT JOIN CourseAssignmentMark
      |                      ON camUid = (SELECT camUid 
      |                                     FROM CourseAssignmentMark
      |                                    WHERE camAssignmentUid = ClazzAssignment.caUid
      |                                      AND camSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
      |                                                                          THEN ? 
      |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
      |                                                                          THEN 0 
      |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
      |                                                                          END) as submitterUid
      |                                                               FROM ClazzAssignment AS ref
      |                                                                    LEFT JOIN CourseGroupMember
      |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
      |                                                                     AND cgmPersonUid = ?
      |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
      |                                 ORDER BY camLct DESC
      |                                    LIMIT 1)       
      |         WHERE CourseBlock.cbClazzUid = ?
      |           AND CourseBlock.cbActive
      |           AND NOT CourseBlock.cbHidden
      |           AND ? > CourseBlock.cbHideUntilDate
      |           AND ? > COALESCE(parentBlock.cbHideUntilDate,0)
      |           AND CourseBlock.cbModuleParentBlockUid NOT IN (?)
      |      ORDER BY CourseBlock.cbIndex
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.setLong(2,personUid)
        _stmt.setLong(3,clazzUid)
        _stmt.setLong(4,clazzUid)
        _stmt.setLong(5,clazzUid)
        _stmt.setArray(6, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            collapseList.toTypedArray()))
        _stmt.setLong(7,personUid)
        _stmt.setLong(8,personUid)
        _stmt.setLong(9,personUid)
        _stmt.setLong(10,personUid)
        _stmt.setLong(11,personUid)
        _stmt.setLong(12,clazzUid)
        _stmt.setLong(13,currentTime)
        _stmt.setLong(14,currentTime)
        _stmt.setArray(15, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            collapseList.toTypedArray()))
        _stmt.setInt(16,_limit)
        _stmt.setInt(17,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_expanded = _result.getBoolean("expanded")
            val _tmp_cbUid = _result.getLong("cbUid")
            val _tmp_cbType = _result.getInt("cbType")
            val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
            val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
            val _tmp_cbTitle = _result.getString("cbTitle")
            val _tmp_cbDescription = _result.getString("cbDescription")
            val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
            val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
            val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
            val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
            val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
            val _tmp_cbMaxPoints = _result.getInt("cbMaxPoints")
            val _tmp_cbMinPoints = _result.getInt("cbMinPoints")
            val _tmp_cbIndex = _result.getInt("cbIndex")
            val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
            val _tmp_cbActive = _result.getBoolean("cbActive")
            val _tmp_cbHidden = _result.getBoolean("cbHidden")
            val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
            val _tmp_cbLct = _result.getLong("cbLct")
            var _tmp_ClazzAssignmentWithMetrics_nullCount = 0
            val _tmp_fileSubmissionStatus = _result.getInt("fileSubmissionStatus")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caUid = _result.getLong("caUid")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caTitle = _result.getString("caTitle")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caDescription = _result.getString("caDescription")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caGroupUid = _result.getLong("caGroupUid")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caActive = _result.getBoolean("caActive")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caFileType = _result.getInt("caFileType")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caMarkingType = _result.getInt("caMarkingType")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caTextLimit = _result.getInt("caTextLimit")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caClazzUid = _result.getLong("caClazzUid")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_caLct = _result.getLong("caLct")
            if(_result.wasNull()) _tmp_ClazzAssignmentWithMetrics_nullCount++
            val _tmp_ClazzAssignmentWithMetrics_isAllNull = _tmp_ClazzAssignmentWithMetrics_nullCount == 24
            var _tmp_AssignmentProgressSummary_nullCount = 0
            val _tmp_notSubmittedStudents = _result.getInt("notSubmittedStudents")
            if(_result.wasNull()) _tmp_AssignmentProgressSummary_nullCount++
            val _tmp_submittedStudents = _result.getInt("submittedStudents")
            if(_result.wasNull()) _tmp_AssignmentProgressSummary_nullCount++
            val _tmp_totalStudents = _result.getInt("totalStudents")
            if(_result.wasNull()) _tmp_AssignmentProgressSummary_nullCount++
            val _tmp_markedStudents = _result.getInt("markedStudents")
            if(_result.wasNull()) _tmp_AssignmentProgressSummary_nullCount++
            val _tmp_hasMetricsPermission = _result.getBoolean("hasMetricsPermission")
            if(_result.wasNull()) _tmp_AssignmentProgressSummary_nullCount++
            val _tmp_AssignmentProgressSummary_isAllNull = _tmp_AssignmentProgressSummary_nullCount == 5
            var _tmp_CourseAssignmentMark_nullCount = 0
            val _tmp_camUid = _result.getLong("camUid")
            if(_result.wasNull()) _tmp_CourseAssignmentMark_nullCount++
            val _tmp_camAssignmentUid = _result.getLong("camAssignmentUid")
            if(_result.wasNull()) _tmp_CourseAssignmentMark_nullCount++
            val _tmp_camSubmitterUid = _result.getLong("camSubmitterUid")
            if(_result.wasNull()) _tmp_CourseAssignmentMark_nullCount++
            val _tmp_camMark = _result.getFloat("camMark")
            if(_result.wasNull()) _tmp_CourseAssignmentMark_nullCount++
            val _tmp_camPenalty = _result.getInt("camPenalty")
            if(_result.wasNull()) _tmp_CourseAssignmentMark_nullCount++
            val _tmp_camLct = _result.getLong("camLct")
            if(_result.wasNull()) _tmp_CourseAssignmentMark_nullCount++
            val _tmp_CourseAssignmentMark_isAllNull = _tmp_CourseAssignmentMark_nullCount == 6
            var _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount = 0
            val _tmp_assignmentContentWeight = _result.getInt("assignmentContentWeight")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_title = _result.getString("title")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_description = _result.getString("description")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_entryId = _result.getString("entryId")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_author = _result.getString("author")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_publisher = _result.getString("publisher")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_licenseType = _result.getInt("licenseType")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_licenseName = _result.getString("licenseName")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_licenseUrl = _result.getString("licenseUrl")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_sourceUrl = _result.getString("sourceUrl")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_lastModified = _result.getLong("lastModified")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentFlags = _result.getInt("contentFlags")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_leaf = _result.getBoolean("leaf")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_publik = _result.getBoolean("publik")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_ceInactive = _result.getBoolean("ceInactive")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_completionCriteria = _result.getInt("completionCriteria")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_minScore = _result.getInt("minScore")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentOwner = _result.getLong("contentOwner")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentEntryLocalChangeSeqNum =
                _result.getLong("contentEntryLocalChangeSeqNum")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentEntryMasterChangeSeqNum =
                _result.getLong("contentEntryMasterChangeSeqNum")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
            if(_result.wasNull())
                _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount++
            val _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_isAllNull = _tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_nullCount == 27
            var _tmp_Container_nullCount = 0
            val _tmp_containerUid = _result.getLong("containerUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLct = _result.getLong("cntLct")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_fileSize = _result.getLong("fileSize")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntLastModified = _result.getLong("cntLastModified")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mimeType = _result.getString("mimeType")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_remarks = _result.getString("remarks")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
            if(_result.wasNull()) _tmp_Container_nullCount++
            val _tmp_Container_isAllNull = _tmp_Container_nullCount == 12
            var _tmp_ContentEntryParentChildJoin_nullCount = 0
            val _tmp_cepcjUid = _result.getLong("cepcjUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjLct = _result.getLong("cepcjLct")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_childIndex = _result.getInt("childIndex")
            if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
            val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 8
            var _tmp_ContentEntryStatementScoreProgress_nullCount = 0
            val _tmp_resultScore = _result.getInt("resultScore")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultMax = _result.getInt("resultMax")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultScaled = _result.getFloat("resultScaled")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_resultWeight = _result.getInt("resultWeight")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_contentComplete = _result.getBoolean("contentComplete")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_progress = _result.getInt("progress")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_success = _result.getByte("success")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_penalty = _result.getInt("penalty")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalContent = _result.getInt("totalContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_totalCompletedContent = _result.getInt("totalCompletedContent")
            if(_result.wasNull()) _tmp_ContentEntryStatementScoreProgress_nullCount++
            val _tmp_ContentEntryStatementScoreProgress_isAllNull = _tmp_ContentEntryStatementScoreProgress_nullCount == 10
            var _tmp_CourseDiscussion_nullCount = 0
            val _tmp_courseDiscussionUid = _result.getLong("courseDiscussionUid")
            if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
            val _tmp_courseDiscussionTitle = _result.getString("courseDiscussionTitle")
            if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
            val _tmp_courseDiscussionDesc = _result.getString("courseDiscussionDesc")
            if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
            val _tmp_courseDiscussionClazzUid = _result.getLong("courseDiscussionClazzUid")
            if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
            val _tmp_courseDiscussionActive = _result.getBoolean("courseDiscussionActive")
            if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
            val _tmp_courseDiscussionLct = _result.getLong("courseDiscussionLct")
            if(_result.wasNull()) _tmp_CourseDiscussion_nullCount++
            val _tmp_CourseDiscussion_isAllNull = _tmp_CourseDiscussion_nullCount == 6
            CourseBlockWithCompleteEntity().apply {
              this.expanded = _tmp_expanded
              this.cbUid = _tmp_cbUid
              this.cbType = _tmp_cbType
              this.cbIndentLevel = _tmp_cbIndentLevel
              this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
              this.cbTitle = _tmp_cbTitle
              this.cbDescription = _tmp_cbDescription
              this.cbCompletionCriteria = _tmp_cbCompletionCriteria
              this.cbHideUntilDate = _tmp_cbHideUntilDate
              this.cbDeadlineDate = _tmp_cbDeadlineDate
              this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
              this.cbGracePeriodDate = _tmp_cbGracePeriodDate
              this.cbMaxPoints = _tmp_cbMaxPoints
              this.cbMinPoints = _tmp_cbMinPoints
              this.cbIndex = _tmp_cbIndex
              this.cbClazzUid = _tmp_cbClazzUid
              this.cbActive = _tmp_cbActive
              this.cbHidden = _tmp_cbHidden
              this.cbEntityUid = _tmp_cbEntityUid
              this.cbLct = _tmp_cbLct
              if(!_tmp_ClazzAssignmentWithMetrics_isAllNull) {
                this.assignment = ClazzAssignmentWithMetrics().apply {
                  this.fileSubmissionStatus = _tmp_fileSubmissionStatus
                  this.caUid = _tmp_caUid
                  this.caTitle = _tmp_caTitle
                  this.caDescription = _tmp_caDescription
                  this.caGroupUid = _tmp_caGroupUid
                  this.caActive = _tmp_caActive
                  this.caClassCommentEnabled = _tmp_caClassCommentEnabled
                  this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
                  this.caCompletionCriteria = _tmp_caCompletionCriteria
                  this.caRequireFileSubmission = _tmp_caRequireFileSubmission
                  this.caFileType = _tmp_caFileType
                  this.caSizeLimit = _tmp_caSizeLimit
                  this.caNumberOfFiles = _tmp_caNumberOfFiles
                  this.caSubmissionPolicy = _tmp_caSubmissionPolicy
                  this.caMarkingType = _tmp_caMarkingType
                  this.caRequireTextSubmission = _tmp_caRequireTextSubmission
                  this.caTextLimitType = _tmp_caTextLimitType
                  this.caTextLimit = _tmp_caTextLimit
                  this.caXObjectUid = _tmp_caXObjectUid
                  this.caClazzUid = _tmp_caClazzUid
                  this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
                  this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
                  this.caLastChangedBy = _tmp_caLastChangedBy
                  this.caLct = _tmp_caLct
                  if(!_tmp_AssignmentProgressSummary_isAllNull) {
                    this.progressSummary = AssignmentProgressSummary().apply {
                      this.notSubmittedStudents = _tmp_notSubmittedStudents
                      this.submittedStudents = _tmp_submittedStudents
                      this.totalStudents = _tmp_totalStudents
                      this.markedStudents = _tmp_markedStudents
                      this.hasMetricsPermission = _tmp_hasMetricsPermission
                    }
                  }
                  if(!_tmp_CourseAssignmentMark_isAllNull) {
                    this.mark = CourseAssignmentMark().apply {
                      this.camUid = _tmp_camUid
                      this.camAssignmentUid = _tmp_camAssignmentUid
                      this.camSubmitterUid = _tmp_camSubmitterUid
                      this.camMark = _tmp_camMark
                      this.camPenalty = _tmp_camPenalty
                      this.camLct = _tmp_camLct
                    }
                  }
                }
              }
              if(!_tmp_ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer_isAllNull) {
                this.entry =
                    ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer().apply {
                  this.assignmentContentWeight = _tmp_assignmentContentWeight
                  this.contentEntryUid = _tmp_contentEntryUid
                  this.title = _tmp_title
                  this.description = _tmp_description
                  this.entryId = _tmp_entryId
                  this.author = _tmp_author
                  this.publisher = _tmp_publisher
                  this.licenseType = _tmp_licenseType
                  this.licenseName = _tmp_licenseName
                  this.licenseUrl = _tmp_licenseUrl
                  this.sourceUrl = _tmp_sourceUrl
                  this.thumbnailUrl = _tmp_thumbnailUrl
                  this.lastModified = _tmp_lastModified
                  this.primaryLanguageUid = _tmp_primaryLanguageUid
                  this.languageVariantUid = _tmp_languageVariantUid
                  this.contentFlags = _tmp_contentFlags
                  this.leaf = _tmp_leaf
                  this.publik = _tmp_publik
                  this.ceInactive = _tmp_ceInactive
                  this.completionCriteria = _tmp_completionCriteria
                  this.minScore = _tmp_minScore
                  this.contentTypeFlag = _tmp_contentTypeFlag
                  this.contentOwner = _tmp_contentOwner
                  this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                  this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                  this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                  this.contentEntryLct = _tmp_contentEntryLct
                  if(!_tmp_Container_isAllNull) {
                    this.mostRecentContainer = Container().apply {
                      this.containerUid = _tmp_containerUid
                      this.cntLocalCsn = _tmp_cntLocalCsn
                      this.cntMasterCsn = _tmp_cntMasterCsn
                      this.cntLastModBy = _tmp_cntLastModBy
                      this.cntLct = _tmp_cntLct
                      this.fileSize = _tmp_fileSize
                      this.containerContentEntryUid = _tmp_containerContentEntryUid
                      this.cntLastModified = _tmp_cntLastModified
                      this.mimeType = _tmp_mimeType
                      this.remarks = _tmp_remarks
                      this.mobileOptimized = _tmp_mobileOptimized
                      this.cntNumEntries = _tmp_cntNumEntries
                    }
                  }
                  if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
                    this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                      this.cepcjUid = _tmp_cepcjUid
                      this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                      this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                      this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                      this.cepcjLct = _tmp_cepcjLct
                      this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                      this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                      this.childIndex = _tmp_childIndex
                    }
                  }
                  if(!_tmp_ContentEntryStatementScoreProgress_isAllNull) {
                    this.scoreProgress = ContentEntryStatementScoreProgress().apply {
                      this.resultScore = _tmp_resultScore
                      this.resultMax = _tmp_resultMax
                      this.resultScaled = _tmp_resultScaled
                      this.resultWeight = _tmp_resultWeight
                      this.contentComplete = _tmp_contentComplete
                      this.progress = _tmp_progress
                      this.success = _tmp_success
                      this.penalty = _tmp_penalty
                      this.totalContent = _tmp_totalContent
                      this.totalCompletedContent = _tmp_totalCompletedContent
                    }
                  }
                }
              }
              if(!_tmp_CourseDiscussion_isAllNull) {
                this.courseDiscussion = CourseDiscussion().apply {
                  this.courseDiscussionUid = _tmp_courseDiscussionUid
                  this.courseDiscussionTitle = _tmp_courseDiscussionTitle
                  this.courseDiscussionDesc = _tmp_courseDiscussionDesc
                  this.courseDiscussionClazzUid = _tmp_courseDiscussionClazzUid
                  this.courseDiscussionActive = _tmp_courseDiscussionActive
                  this.courseDiscussionLct = _tmp_courseDiscussionLct
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("CourseBlock",
        "ClazzAssignment", "CourseDiscussion", "ContentEntry", "CourseAssignmentMark",
        "StatementEntity", "Container", "ContentEntryParentChildJoin", "PersonGroupMember", "Clazz",
        "ScopedGrant", "ClazzEnrolment", "CourseAssignmentSubmission", "CourseGroupMember"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |         WITH CtePermissionCheck (hasPermission) 
      |            AS (SELECT EXISTS( 
      |               SELECT PrsGrpMbr.groupMemberPersonUid
      |                  FROM Clazz
      |                       
      |            JOIN ScopedGrant
      |                 ON 
      |            ((ScopedGrant.sgTableId = -2
      |                                AND ScopedGrant.sgEntityUid = -2)
      |                            OR (ScopedGrant.sgTableId = 6
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
      |                            OR (ScopedGrant.sgTableId = 164
      |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
      |        
      |                    AND (ScopedGrant.sgPermissions & 
      |        
      |                          549755813888
      |                          
      |                                                       ) > 0
      |             JOIN PersonGroupMember AS PrsGrpMbr
      |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
      |        
      |                 WHERE Clazz.clazzUid = ?
      |                   AND PrsGrpMbr.groupMemberPersonUid = ?)), 
      |                   
      |        
      |            SubmitterList (submitterId, assignmentUid)
      |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId,
      |                       ClazzAssignment.caUid AS assignmentUid
      |                  
      |                  FROM ClazzEnrolment
      |                  
      |                       JOIN Person 
      |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
      |                        
      |                       JOIN ClazzAssignment
      |                       ON ClazzAssignment.caClazzUid = ?
      |
      |                       JOIN CourseBlock
      |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
      |                       AND CourseBlock.cbType = 103
      |                       
      |                 WHERE ClazzAssignment.caGroupUid = 0
      |                   AND clazzEnrolmentClazzUid = ?
      |                   AND clazzEnrolmentActive
      |                   AND clazzEnrolmentRole = 1000
      |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
      |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
      |              GROUP BY submitterId, assignmentUid
      |            UNION                 
      |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
      |                    ClazzAssignment.caUid AS assignmentUid
      |               FROM CourseGroupMember
      |                    JOIN ClazzAssignment
      |                    ON ClazzAssignment.caClazzUid = ?
      |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
      |                AND ClazzAssignment.caGroupUid != 0
      |                AND CourseGroupMember.cgmGroupNumber != 0
      |           GROUP BY submitterId, assignmentUid
      |            )
      |                   
      |                   
      |
      |        SELECT CourseBlock.*, ClazzAssignment.*, ContentEntry.*, CourseDiscussion.*, ContentEntryParentChildJoin.*, 
      |               Container.*, CourseAssignmentMark.*, (CourseBlock.cbUid NOT IN (?)) AS expanded,
      |               
      |               COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
      |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
      |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
      |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
      |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
      |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
      |                
      |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
      |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
      |                
      |                0 AS assignmentContentWeight,
      |                1 as totalContent, 
      |                0 as penalty,
      |                
      |                (SELECT hasPermission FROM CtePermissionCheck) AS hasMetricsPermission,
      |                
      |             
      |                 (SELECT COUNT(*) 
      |                    FROM SubmitterList 
      |                   WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid) 
      |                        AS totalStudents, 
      | 
      |               0 AS notSubmittedStudents,
      |               
      |               (CASE WHEN (SELECT hasPermission 
      |                          FROM CtePermissionCheck)
      |                     THEN (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
      |                             FROM CourseAssignmentSubmission
      |                                   LEFT JOIN CourseAssignmentMark
      |                                   ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                                   AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
      |                            WHERE CourseAssignmentMark.camUid IS NULL
      |                              AND CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
      |                              AND CourseAssignmentSubmission.casSubmitterUid IN 
      |                                                    (SELECT submitterId 
      |                                                      FROM SubmitterList
      |                                                     WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))  
      |                      ELSE 0 END) AS submittedStudents,         
      |               
      |                (CASE WHEN (SELECT hasPermission 
      |                           FROM CtePermissionCheck)       
      |                   THEN (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
      |                           FROM CourseAssignmentMark
      |                            
      |                             JOIN CourseAssignmentSubmission
      |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
      |                             
      |                          WHERE CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid 
      |                            AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
      |                                                                            FROM SubmitterList
      |                                                                           WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))
      |                   ELSE 0 END) AS markedStudents,
      |                   
      |                   COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
      |                          THEN 2 
      |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
      |                          THEN 1 
      |                          ELSE 0 END), 
      |                               0) AS fileSubmissionStatus
      |                
      |                
      |          FROM CourseBlock 
      |          
      |               LEFT JOIN CourseBlock AS parentBlock
      |               ON CourseBlock.cbModuleParentBlockUid = parentBlock.cbUid
      |               AND CourseBlock.cbTYpe != 100
      |          
      |               LEFT JOIN ClazzAssignment
      |               ON ClazzAssignment.caUid = CourseBlock.cbEntityUid
      |               AND CourseBlock.cbType = 103
      |               
      |               LEFT JOIN ContentEntry
      |               ON ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               AND NOT ceInactive
      |               AND CourseBlock.cbType = 104
      |               
      |               LEFT JOIN CourseDiscussion 
      |                      ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
      |                     AND CourseBlock.cbType = 105
      |               
      |               LEFT JOIN ContentEntryParentChildJoin 
      |               ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
      |               
      |               LEFT JOIN Container 
      |                    ON Container.containerUid = 
      |                        (SELECT containerUid 
      |                           FROM Container 
      |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
      |                       ORDER BY cntLastModified DESC LIMIT 1)
      |                       
      |              LEFT JOIN StatementEntity
      |				     ON StatementEntity.statementUid = 
      |                                (SELECT statementUid 
      |							       FROM StatementEntity 
      |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
      |							        AND StatementEntity.statementPersonUid = ?
      |							        AND contentEntryRoot 
      |                               ORDER BY resultScoreScaled DESC, 
      |                                        extensionProgress DESC, 
      |                                        resultSuccess DESC 
      |                                  LIMIT 1) 
      |                                  
      |               LEFT JOIN CourseAssignmentSubmission
      |                ON casUid = (SELECT casUid 
      |                                     FROM CourseAssignmentSubmission
      |                                    WHERE casAssignmentUid = ClazzAssignment.caUid
      |                                      AND casSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
      |                                                                          THEN ? 
      |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
      |                                                                          THEN 0 
      |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
      |                                                                          END) as submitterUid
      |                                                               FROM ClazzAssignment AS ref
      |                                                                    LEFT JOIN CourseGroupMember
      |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
      |                                                                     AND cgmPersonUid = ?
      |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
      |                                 ORDER BY casTimestamp DESC
      |                                    LIMIT 1)
      |                                          
      |               LEFT JOIN CourseAssignmentMark
      |                      ON camUid = (SELECT camUid 
      |                                     FROM CourseAssignmentMark
      |                                    WHERE camAssignmentUid = ClazzAssignment.caUid
      |                                      AND camSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
      |                                                                          THEN ? 
      |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
      |                                                                          THEN 0 
      |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
      |                                                                          END) as submitterUid
      |                                                               FROM ClazzAssignment AS ref
      |                                                                    LEFT JOIN CourseGroupMember
      |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
      |                                                                     AND cgmPersonUid = ?
      |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
      |                                 ORDER BY camLct DESC
      |                                    LIMIT 1)       
      |         WHERE CourseBlock.cbClazzUid = ?
      |           AND CourseBlock.cbActive
      |           AND NOT CourseBlock.cbHidden
      |           AND ? > CourseBlock.cbHideUntilDate
      |           AND ? > COALESCE(parentBlock.cbHideUntilDate,0)
      |           AND CourseBlock.cbModuleParentBlockUid NOT IN (?)
      |      ORDER BY CourseBlock.cbIndex
      |    ) 
      """.trimMargin() ,hasListParams = true)) { _stmt -> 
        _stmt.setLong(1,clazzUid)
        _stmt.setLong(2,personUid)
        _stmt.setLong(3,clazzUid)
        _stmt.setLong(4,clazzUid)
        _stmt.setLong(5,clazzUid)
        _stmt.setArray(6, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            collapseList.toTypedArray()))
        _stmt.setLong(7,personUid)
        _stmt.setLong(8,personUid)
        _stmt.setLong(9,personUid)
        _stmt.setLong(10,personUid)
        _stmt.setLong(11,personUid)
        _stmt.setLong(12,clazzUid)
        _stmt.setLong(13,currentTime)
        _stmt.setLong(14,currentTime)
        _stmt.setArray(15, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
            collapseList.toTypedArray()))
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE CourseBlock 
    |           SET cbActive = ?, 
    |               cbLct = ?
    |         WHERE cbUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,cbUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
