package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseDiscussion
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseDiscussionDao_JdbcKt(
  public val _db: RoomDatabase,
) : CourseDiscussionDao() {
  public val _insertAdapterCourseDiscussion_upsert: EntityInsertionAdapter<CourseDiscussion> =
      object : EntityInsertionAdapter<CourseDiscussion>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO CourseDiscussion (courseDiscussionUid, courseDiscussionTitle, courseDiscussionDesc, courseDiscussionClazzUid, courseDiscussionActive, courseDiscussionLct) VALUES(?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseDiscussion):
        Unit {
      if(entity.courseDiscussionUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.courseDiscussionUid)
      }
      stmt.setString(2, entity.courseDiscussionTitle)
      stmt.setString(3, entity.courseDiscussionDesc)
      stmt.setLong(4, entity.courseDiscussionClazzUid)
      stmt.setBoolean(5, entity.courseDiscussionActive)
      stmt.setLong(6, entity.courseDiscussionLct)
    }
  }

  public val _insertAdapterCourseDiscussion_: EntityInsertionAdapter<CourseDiscussion> = object :
      EntityInsertionAdapter<CourseDiscussion>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO CourseDiscussion (courseDiscussionUid, courseDiscussionTitle, courseDiscussionDesc, courseDiscussionClazzUid, courseDiscussionActive, courseDiscussionLct) VALUES(?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseDiscussion):
        Unit {
      if(entity.courseDiscussionUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.courseDiscussionUid)
      }
      stmt.setString(2, entity.courseDiscussionTitle)
      stmt.setString(3, entity.courseDiscussionDesc)
      stmt.setLong(4, entity.courseDiscussionClazzUid)
      stmt.setBoolean(5, entity.courseDiscussionActive)
      stmt.setLong(6, entity.courseDiscussionLct)
    }
  }

  public override suspend fun replaceListAsync(list: List<CourseDiscussion>): Unit {
    _insertAdapterCourseDiscussion_upsert.insertListAsync(list)
  }

  public override fun insert(entity: CourseDiscussion): Long {
    val _retVal = _insertAdapterCourseDiscussion_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseDiscussion): Long {
    val _retVal = _insertAdapterCourseDiscussion_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CourseDiscussion>): Unit {
    _insertAdapterCourseDiscussion_.insertList(entityList)
  }

  public override suspend fun insertListAsync(entityList: List<CourseDiscussion>): Unit {
    _insertAdapterCourseDiscussion_.insertListAsync(entityList)
  }

  public override fun updateList(entityList: List<CourseDiscussion>): Unit {
    val _sql =
        "UPDATE CourseDiscussion SET courseDiscussionTitle = ?, courseDiscussionDesc = ?, courseDiscussionClazzUid = ?, courseDiscussionActive = ?, courseDiscussionLct = ? WHERE courseDiscussionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.courseDiscussionTitle)
        _stmt.setString(2, _entity.courseDiscussionDesc)
        _stmt.setLong(3, _entity.courseDiscussionClazzUid)
        _stmt.setBoolean(4, _entity.courseDiscussionActive)
        _stmt.setLong(5, _entity.courseDiscussionLct)
        _stmt.setLong(6, _entity.courseDiscussionUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseDiscussion): Unit {
    val _sql =
        "UPDATE CourseDiscussion SET courseDiscussionTitle = ?, courseDiscussionDesc = ?, courseDiscussionClazzUid = ?, courseDiscussionActive = ?, courseDiscussionLct = ? WHERE courseDiscussionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.courseDiscussionTitle)
      _stmt.setString(2, entity.courseDiscussionDesc)
      _stmt.setLong(3, entity.courseDiscussionClazzUid)
      _stmt.setBoolean(4, entity.courseDiscussionActive)
      _stmt.setLong(5, entity.courseDiscussionLct)
      _stmt.setLong(6, entity.courseDiscussionUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateListAsync(entityList: List<CourseDiscussion>): Unit {
    val _sql =
        "UPDATE CourseDiscussion SET courseDiscussionTitle = ?, courseDiscussionDesc = ?, courseDiscussionClazzUid = ?, courseDiscussionActive = ?, courseDiscussionLct = ? WHERE courseDiscussionUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.courseDiscussionTitle)
        _stmt.setString(2, _entity.courseDiscussionDesc)
        _stmt.setLong(3, _entity.courseDiscussionClazzUid)
        _stmt.setBoolean(4, _entity.courseDiscussionActive)
        _stmt.setLong(5, _entity.courseDiscussionLct)
        _stmt.setLong(6, _entity.courseDiscussionUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |      SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionPk,
    |             ? AS courseDiscussionDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN CourseDiscussion 
    |                  ON CourseDiscussion.courseDiscussionClazzUid = Clazz.clazzUid
    |                  
    |       WHERE CourseDiscussion.courseDiscussionLct != COALESCE(
    |             (SELECT courseDiscussionVersionId
    |                FROM courseDiscussionReplicate
    |               WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                 AND courseDiscussionDestination = ?), 0) 
    |      /*psql ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |      SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionPk,
    |             ? AS courseDiscussionDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN CourseDiscussion 
    |                  ON CourseDiscussion.courseDiscussionClazzUid = Clazz.clazzUid
    |                  
    |       WHERE CourseDiscussion.courseDiscussionLct != COALESCE(
    |             (SELECT courseDiscussionVersionId
    |                FROM courseDiscussionReplicate
    |               WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                 AND courseDiscussionDestination = ?), 0) 
    |       ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        REPLACE INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |          SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionUid,
    |                 UserSession.usClientNodeId AS courseDiscussionDestination
    |            FROM ChangeLog
    |                 JOIN CourseDiscussion
    |                     ON ChangeLog.chTableId = 130
    |                        AND ChangeLog.chEntityPk = CourseDiscussion.courseDiscussionUid
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = CourseDiscussion.courseDiscussionClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND CourseDiscussion.courseDiscussionLct != COALESCE(
    |                 (SELECT courseDiscussionVersionId
    |                    FROM courseDiscussionReplicate
    |                   WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                     AND courseDiscussionDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |          SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionUid,
    |                 UserSession.usClientNodeId AS courseDiscussionDestination
    |            FROM ChangeLog
    |                 JOIN CourseDiscussion
    |                     ON ChangeLog.chTableId = 130
    |                        AND ChangeLog.chEntityPk = CourseDiscussion.courseDiscussionUid
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = CourseDiscussion.courseDiscussionClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND CourseDiscussion.courseDiscussionLct != COALESCE(
    |                 (SELECT courseDiscussionVersionId
    |                    FROM courseDiscussionReplicate
    |                   WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                     AND courseDiscussionDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |                         
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE CourseDiscussion 
    |           SET courseDiscussionActive = ?, 
    |               courseDiscussionLct = ?
    |         WHERE courseDiscussionUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,cbUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getCourseDiscussionByUid(courseDiscussionUid: Long):
      LiveData<CourseDiscussion?> = LiveDataImpl(_db, listOf("CourseDiscussion"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        SELECT CourseDiscussion.* 
    |          FROM CourseDiscussion
    |         WHERE CourseDiscussion.courseDiscussionUid = ? 
    |           AND CAST(CourseDiscussion.courseDiscussionActive AS INTEGER) = 1 
    |         
    |         
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,courseDiscussionUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_courseDiscussionUid = _result.getLong("courseDiscussionUid")
          val _tmp_courseDiscussionTitle = _result.getString("courseDiscussionTitle")
          val _tmp_courseDiscussionDesc = _result.getString("courseDiscussionDesc")
          val _tmp_courseDiscussionClazzUid = _result.getLong("courseDiscussionClazzUid")
          val _tmp_courseDiscussionActive = _result.getBoolean("courseDiscussionActive")
          val _tmp_courseDiscussionLct = _result.getLong("courseDiscussionLct")
          CourseDiscussion().apply {
            this.courseDiscussionUid = _tmp_courseDiscussionUid
            this.courseDiscussionTitle = _tmp_courseDiscussionTitle
            this.courseDiscussionDesc = _tmp_courseDiscussionDesc
            this.courseDiscussionClazzUid = _tmp_courseDiscussionClazzUid
            this.courseDiscussionActive = _tmp_courseDiscussionActive
            this.courseDiscussionLct = _tmp_courseDiscussionLct
          }
        }
      }
    }
  }
}
