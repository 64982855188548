package com.ustadmobile.core.db.dao

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonAuth2
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonAuth2Dao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonAuth2Dao,
) : PersonAuth2Dao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(auths: List<PersonAuth2>): Unit {
    val _generatedPks = mutableListOf<Long>()
    auths.iterator().forEach  {
      it.pauthLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(auths)
  }

  public override suspend fun insertAsync(auth: PersonAuth2): Long {
    auth.pauthLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.insertAsync(auth)
  }

  public override suspend fun findByPersonUid(personUid: Long): PersonAuth2? =
      _dao.findByPersonUid(personUid)

  public override suspend fun findByUsername(username: String): PersonAuth2? =
      _dao.findByUsername(username)
}
