package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.AgentEntity
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class AgentDao : BaseDao<AgentEntity> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun getAgentByAnyId(
    openId: String?,
    mbox: String?,
    account: String?,
    homepage: String?,
    sha1: String?,
  ): AgentEntity?

  public actual abstract suspend fun getAgentFromPersonUsername(endpoint: String, username: String):
      AgentEntity?
}
