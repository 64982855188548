package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.SchoolMember
import com.ustadmobile.lib.db.entities.SchoolMemberWithPerson
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class SchoolMemberDao : BaseDao<SchoolMember> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByUidAsync(schoolMemberUid: Long): SchoolMember?

  public actual abstract suspend fun updateAsync(entity: SchoolMember): Int

  public actual abstract suspend fun findBySchoolAndPersonAndRole(
    schoolUid: Long,
    personUid: Long,
    role: Int,
    timeFilter: Long,
  ): List<SchoolMember>

  public actual abstract fun findAllActiveMembersBySchoolAndRoleUid(
    schoolUid: Long,
    role: Int,
    sortOrder: Int,
    searchQuery: String,
    accountPersonUid: Long,
  ): DataSourceFactory<Int, SchoolMemberWithPerson>

  public actual abstract suspend fun findAllTest(
    schoolUid: Long,
    role: Int,
    searchQuery: String,
  ): List<SchoolMemberWithPerson>
}
