package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.ContentEntryPicture
import kotlin.Long
import kotlin.Unit

public actual abstract class ContentEntryPictureDao : BaseDao<ContentEntryPicture> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun findByContentEntryUidAsync(entryUid: Long):
      ContentEntryPicture?

  public actual abstract fun findByContentEntryUidLive(entryUid: Long):
      LiveData<ContentEntryPicture?>

  public actual abstract suspend fun updateAsync(ContentEntryPicture: ContentEntryPicture): Unit
}
