package com.ustadmobile.lib.db.entities

class PersonUidAndPasswordHash {
    var passwordHash: String? = null

    var personUid: Long = 0

    var firstNames: String? = null

    var lastName: String? = null

    var admin: Boolean = false
}