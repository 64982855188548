package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentCategory
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentCategoryDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentCategoryDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentCategoryDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun publicContentCategories(): List<ContentCategory> {
    val _result = _dao.publicContentCategories()
    return _result
  }

  public override fun findCategoryBySchemaIdAndName(schemaId: Long, name: String):
      ContentCategory? {
    val _result = _dao.findCategoryBySchemaIdAndName(schemaId, name)
    return _result
  }

  public override fun insert(entity: ContentCategory): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ContentCategory): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<ContentCategory>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<ContentCategory>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ContentCategory): Unit {
    _dao.update(entity)
  }
}
