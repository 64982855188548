package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.Site
import kotlin.Long
import kotlin.String
import kotlin.Unit

public actual abstract class SiteDao {
  public actual abstract fun getSite(): Site?

  public actual abstract suspend fun getSiteAsync(): Site?

  public actual abstract suspend fun getSiteAuthSaltAsync(): String?

  public actual abstract suspend fun replaceAsync(site: Site): Long

  public actual abstract fun insert(site: Site): Long

  public actual abstract suspend fun updateAsync(workspace: Site): Unit

  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit
}
