package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithCourseBlock
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.PersonGroupAssignmentSummary
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzAssignmentDao_JdbcKt(
  public val _db: RoomDatabase,
) : ClazzAssignmentDao() {
  public val _insertAdapterClazzAssignment_: EntityInsertionAdapter<ClazzAssignment> = object :
      EntityInsertionAdapter<ClazzAssignment>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ClazzAssignment (caUid, caTitle, caDescription, caGroupUid, caActive, caClassCommentEnabled, caPrivateCommentsEnabled, caCompletionCriteria, caRequireFileSubmission, caFileType, caSizeLimit, caNumberOfFiles, caSubmissionPolicy, caMarkingType, caRequireTextSubmission, caTextLimitType, caTextLimit, caXObjectUid, caClazzUid, caLocalChangeSeqNum, caMasterChangeSeqNum, caLastChangedBy, caLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzAssignment):
        Unit {
      if(entity.caUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.caUid)
      }
      stmt.setString(2, entity.caTitle)
      stmt.setString(3, entity.caDescription)
      stmt.setLong(4, entity.caGroupUid)
      stmt.setBoolean(5, entity.caActive)
      stmt.setBoolean(6, entity.caClassCommentEnabled)
      stmt.setBoolean(7, entity.caPrivateCommentsEnabled)
      stmt.setInt(8, entity.caCompletionCriteria)
      stmt.setBoolean(9, entity.caRequireFileSubmission)
      stmt.setInt(10, entity.caFileType)
      stmt.setInt(11, entity.caSizeLimit)
      stmt.setInt(12, entity.caNumberOfFiles)
      stmt.setInt(13, entity.caSubmissionPolicy)
      stmt.setInt(14, entity.caMarkingType)
      stmt.setBoolean(15, entity.caRequireTextSubmission)
      stmt.setInt(16, entity.caTextLimitType)
      stmt.setInt(17, entity.caTextLimit)
      stmt.setLong(18, entity.caXObjectUid)
      stmt.setLong(19, entity.caClazzUid)
      stmt.setLong(20, entity.caLocalChangeSeqNum)
      stmt.setLong(21, entity.caMasterChangeSeqNum)
      stmt.setInt(22, entity.caLastChangedBy)
      stmt.setLong(23, entity.caLct)
    }
  }

  public override fun insert(entity: ClazzAssignment): Long {
    val _retVal = _insertAdapterClazzAssignment_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzAssignment): Long {
    val _retVal = _insertAdapterClazzAssignment_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ClazzAssignment>): Unit {
    _insertAdapterClazzAssignment_.insertList(entityList)
  }

  public override suspend fun insertListAsync(entityList: List<ClazzAssignment>): Unit {
    _insertAdapterClazzAssignment_.insertListAsync(entityList)
  }

  public override suspend fun updateAsync(clazzAssignment: ClazzAssignment): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, clazzAssignment.caTitle)
      _stmt.setString(2, clazzAssignment.caDescription)
      _stmt.setLong(3, clazzAssignment.caGroupUid)
      _stmt.setBoolean(4, clazzAssignment.caActive)
      _stmt.setBoolean(5, clazzAssignment.caClassCommentEnabled)
      _stmt.setBoolean(6, clazzAssignment.caPrivateCommentsEnabled)
      _stmt.setInt(7, clazzAssignment.caCompletionCriteria)
      _stmt.setBoolean(8, clazzAssignment.caRequireFileSubmission)
      _stmt.setInt(9, clazzAssignment.caFileType)
      _stmt.setInt(10, clazzAssignment.caSizeLimit)
      _stmt.setInt(11, clazzAssignment.caNumberOfFiles)
      _stmt.setInt(12, clazzAssignment.caSubmissionPolicy)
      _stmt.setInt(13, clazzAssignment.caMarkingType)
      _stmt.setBoolean(14, clazzAssignment.caRequireTextSubmission)
      _stmt.setInt(15, clazzAssignment.caTextLimitType)
      _stmt.setInt(16, clazzAssignment.caTextLimit)
      _stmt.setLong(17, clazzAssignment.caXObjectUid)
      _stmt.setLong(18, clazzAssignment.caClazzUid)
      _stmt.setLong(19, clazzAssignment.caLocalChangeSeqNum)
      _stmt.setLong(20, clazzAssignment.caMasterChangeSeqNum)
      _stmt.setInt(21, clazzAssignment.caLastChangedBy)
      _stmt.setLong(22, clazzAssignment.caLct)
      _stmt.setLong(23, clazzAssignment.caUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateList(entityList: List<ClazzAssignment>): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.caTitle)
        _stmt.setString(2, _entity.caDescription)
        _stmt.setLong(3, _entity.caGroupUid)
        _stmt.setBoolean(4, _entity.caActive)
        _stmt.setBoolean(5, _entity.caClassCommentEnabled)
        _stmt.setBoolean(6, _entity.caPrivateCommentsEnabled)
        _stmt.setInt(7, _entity.caCompletionCriteria)
        _stmt.setBoolean(8, _entity.caRequireFileSubmission)
        _stmt.setInt(9, _entity.caFileType)
        _stmt.setInt(10, _entity.caSizeLimit)
        _stmt.setInt(11, _entity.caNumberOfFiles)
        _stmt.setInt(12, _entity.caSubmissionPolicy)
        _stmt.setInt(13, _entity.caMarkingType)
        _stmt.setBoolean(14, _entity.caRequireTextSubmission)
        _stmt.setInt(15, _entity.caTextLimitType)
        _stmt.setInt(16, _entity.caTextLimit)
        _stmt.setLong(17, _entity.caXObjectUid)
        _stmt.setLong(18, _entity.caClazzUid)
        _stmt.setLong(19, _entity.caLocalChangeSeqNum)
        _stmt.setLong(20, _entity.caMasterChangeSeqNum)
        _stmt.setInt(21, _entity.caLastChangedBy)
        _stmt.setLong(22, _entity.caLct)
        _stmt.setLong(23, _entity.caUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzAssignment): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.caTitle)
      _stmt.setString(2, entity.caDescription)
      _stmt.setLong(3, entity.caGroupUid)
      _stmt.setBoolean(4, entity.caActive)
      _stmt.setBoolean(5, entity.caClassCommentEnabled)
      _stmt.setBoolean(6, entity.caPrivateCommentsEnabled)
      _stmt.setInt(7, entity.caCompletionCriteria)
      _stmt.setBoolean(8, entity.caRequireFileSubmission)
      _stmt.setInt(9, entity.caFileType)
      _stmt.setInt(10, entity.caSizeLimit)
      _stmt.setInt(11, entity.caNumberOfFiles)
      _stmt.setInt(12, entity.caSubmissionPolicy)
      _stmt.setInt(13, entity.caMarkingType)
      _stmt.setBoolean(14, entity.caRequireTextSubmission)
      _stmt.setInt(15, entity.caTextLimitType)
      _stmt.setInt(16, entity.caTextLimit)
      _stmt.setLong(17, entity.caXObjectUid)
      _stmt.setLong(18, entity.caClazzUid)
      _stmt.setLong(19, entity.caLocalChangeSeqNum)
      _stmt.setLong(20, entity.caMasterChangeSeqNum)
      _stmt.setInt(21, entity.caLastChangedBy)
      _stmt.setLong(22, entity.caLct)
      _stmt.setLong(23, entity.caUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateListAsync(entityList: List<ClazzAssignment>): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.caTitle)
        _stmt.setString(2, _entity.caDescription)
        _stmt.setLong(3, _entity.caGroupUid)
        _stmt.setBoolean(4, _entity.caActive)
        _stmt.setBoolean(5, _entity.caClassCommentEnabled)
        _stmt.setBoolean(6, _entity.caPrivateCommentsEnabled)
        _stmt.setInt(7, _entity.caCompletionCriteria)
        _stmt.setBoolean(8, _entity.caRequireFileSubmission)
        _stmt.setInt(9, _entity.caFileType)
        _stmt.setInt(10, _entity.caSizeLimit)
        _stmt.setInt(11, _entity.caNumberOfFiles)
        _stmt.setInt(12, _entity.caSubmissionPolicy)
        _stmt.setInt(13, _entity.caMarkingType)
        _stmt.setBoolean(14, _entity.caRequireTextSubmission)
        _stmt.setInt(15, _entity.caTextLimitType)
        _stmt.setInt(16, _entity.caTextLimit)
        _stmt.setLong(17, _entity.caXObjectUid)
        _stmt.setLong(18, _entity.caClazzUid)
        _stmt.setLong(19, _entity.caLocalChangeSeqNum)
        _stmt.setLong(20, _entity.caMasterChangeSeqNum)
        _stmt.setInt(21, _entity.caLastChangedBy)
        _stmt.setLong(22, _entity.caLct)
        _stmt.setLong(23, _entity.caUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzAssignmentReplicate(caPk, caDestination)
    |      SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |             ? AS caDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzAssignment.caLct != COALESCE(
    |             (SELECT caVersionId
    |                FROM ClazzAssignmentReplicate
    |               WHERE caPk = ClazzAssignment.caUid
    |                 AND caDestination = ?), 0) 
    |      /*psql ON CONFLICT(caPk, caDestination) DO UPDATE
    |             SET caPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentReplicate(caPk, caDestination)
    |      SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |             ? AS caDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzAssignment.caLct != COALESCE(
    |             (SELECT caVersionId
    |                FROM ClazzAssignmentReplicate
    |               WHERE caPk = ClazzAssignment.caUid
    |                 AND caDestination = ?), 0) 
    |       ON CONFLICT(caPk, caDestination) DO UPDATE
    |             SET caPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzAssignmentReplicate(caPk, caDestination)
    |  SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |         UserSession.usClientNodeId AS caDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignment
    |             ON ChangeLog.chTableId = 520
    |                AND ChangeLog.chEntityPk = ClazzAssignment.caUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignment.caLct != COALESCE(
    |         (SELECT caVersionId
    |            FROM ClazzAssignmentReplicate
    |           WHERE caPk = ClazzAssignment.caUid
    |             AND caDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(caPk, caDestination) DO UPDATE
    |     SET caPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentReplicate(caPk, caDestination)
    |  SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |         UserSession.usClientNodeId AS caDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignment
    |             ON ChangeLog.chTableId = 520
    |                AND ChangeLog.chEntityPk = ClazzAssignment.caUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignment.caLct != COALESCE(
    |         (SELECT caVersionId
    |            FROM ClazzAssignmentReplicate
    |           WHERE caPk = ClazzAssignment.caUid
    |             AND caDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(caPk, caDestination) DO UPDATE
    |     SET caPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun getAllAssignmentsByClazzUidAsync(clazzUid: Long):
      List<ClazzAssignment> = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ClazzAssignment
  |         WHERE ClazzAssignment.caClazzUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_caUid = _result.getLong("caUid")
        val _tmp_caTitle = _result.getString("caTitle")
        val _tmp_caDescription = _result.getString("caDescription")
        val _tmp_caGroupUid = _result.getLong("caGroupUid")
        val _tmp_caActive = _result.getBoolean("caActive")
        val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
        val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
        val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
        val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
        val _tmp_caFileType = _result.getInt("caFileType")
        val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
        val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
        val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
        val _tmp_caMarkingType = _result.getInt("caMarkingType")
        val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
        val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
        val _tmp_caTextLimit = _result.getInt("caTextLimit")
        val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
        val _tmp_caClazzUid = _result.getLong("caClazzUid")
        val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
        val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
        val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
        val _tmp_caLct = _result.getLong("caLct")
        ClazzAssignment().apply {
          this.caUid = _tmp_caUid
          this.caTitle = _tmp_caTitle
          this.caDescription = _tmp_caDescription
          this.caGroupUid = _tmp_caGroupUid
          this.caActive = _tmp_caActive
          this.caClassCommentEnabled = _tmp_caClassCommentEnabled
          this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
          this.caCompletionCriteria = _tmp_caCompletionCriteria
          this.caRequireFileSubmission = _tmp_caRequireFileSubmission
          this.caFileType = _tmp_caFileType
          this.caSizeLimit = _tmp_caSizeLimit
          this.caNumberOfFiles = _tmp_caNumberOfFiles
          this.caSubmissionPolicy = _tmp_caSubmissionPolicy
          this.caMarkingType = _tmp_caMarkingType
          this.caRequireTextSubmission = _tmp_caRequireTextSubmission
          this.caTextLimitType = _tmp_caTextLimitType
          this.caTextLimit = _tmp_caTextLimit
          this.caXObjectUid = _tmp_caXObjectUid
          this.caClazzUid = _tmp_caClazzUid
          this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
          this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
          this.caLastChangedBy = _tmp_caLastChangedBy
          this.caLct = _tmp_caLct
        }
      }
    }
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |        UPDATE ClazzAssignment 
    |           SET caActive = ?, 
    |               caLct = ?
    |         WHERE caUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,cbUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getProgressSummaryForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
  ): LiveData<AssignmentProgressSummary?> = LiveDataImpl(_db, listOf("ClazzEnrolment", "Person",
      "ClazzAssignment", "CourseBlock", "CourseGroupMember", "CourseAssignmentSubmission",
      "CourseAssignmentMark"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |            
    |            WITH SubmitterList (submitterId, name)
    |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
    |                       Person.firstNames || ' ' || Person.lastName AS name
    |                  FROM ClazzEnrolment
    |                  
    |                       JOIN Person 
    |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                        
    |                       JOIN ClazzAssignment
    |                       ON ClazzAssignment.caUid = ?
    |
    |                       JOIN CourseBlock
    |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |                       AND CourseBlock.cbType = 103
    |                       
    |                 WHERE ClazzAssignment.caGroupUid = 0
    |                   AND clazzEnrolmentClazzUid = ?
    |                   AND clazzEnrolmentActive
    |                   AND clazzEnrolmentRole = 1000
    |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
    |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
    |              GROUP BY submitterId, name
    |            UNION                 
    |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
    |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
    |               FROM CourseGroupMember
    |                    JOIN ClazzAssignment
    |                    ON ClazzAssignment.caUid = ?
    |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
    |                AND ClazzAssignment.caGroupUid != 0
    |                AND CourseGroupMember.cgmGroupNumber != 0
    |           GROUP BY submitterId, name
    |            )
    |        
    |            
    |            SELECT (SELECT COUNT(*) FROM SubmitterList) AS totalStudents,
    |            
    |                    0 as notSubmittedStudents,
    |                    
    |                    (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
    |                      FROM CourseAssignmentSubmission
    |                           LEFT JOIN CourseAssignmentMark
    |                           ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
    |                           AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
    |                     WHERE CourseAssignmentSubmission.casAssignmentUid = ?
    |                       AND CourseAssignmentMark.camUid IS NULL
    |                       AND CourseAssignmentSubmission.casSubmitterUid IN (SELECT submitterId 
    |                                                                            FROM SubmitterList))
    |                      AS submittedStudents,
    |                     
    |                     
    |                     (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
    |                        FROM CourseAssignmentMark
    |                            
    |                             JOIN CourseAssignmentSubmission
    |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
    |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
    |                             
    |                       WHERE CourseAssignmentMark.camAssignmentUid = ?
    |                         AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
    |                                                                            FROM SubmitterList))
    |                         AS markedStudents, 
    |                         
    |                         'TRUE' AS hasMetricsPermission
    |                         
    |         FROM  ClazzAssignment
    |        WHERE caActive
    |          AND caClazzUid = ? 
    |          AND caUid = ?                  
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,assignmentUid)
      _stmt.setLong(2,clazzUid)
      _stmt.setString(3,group)
      _stmt.setLong(4,assignmentUid)
      _stmt.setLong(5,assignmentUid)
      _stmt.setLong(6,assignmentUid)
      _stmt.setLong(7,clazzUid)
      _stmt.setLong(8,assignmentUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_notSubmittedStudents = _result.getInt("notSubmittedStudents")
          val _tmp_submittedStudents = _result.getInt("submittedStudents")
          val _tmp_totalStudents = _result.getInt("totalStudents")
          val _tmp_markedStudents = _result.getInt("markedStudents")
          val _tmp_hasMetricsPermission = _result.getBoolean("hasMetricsPermission")
          AssignmentProgressSummary().apply {
            this.notSubmittedStudents = _tmp_notSubmittedStudents
            this.submittedStudents = _tmp_submittedStudents
            this.totalStudents = _tmp_totalStudents
            this.markedStudents = _tmp_markedStudents
            this.hasMetricsPermission = _tmp_hasMetricsPermission
          }
        }
      }
    }
  }

  public override fun getSubmitterListForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
    searchText: String,
  ): DataSourceFactory<Int, PersonGroupAssignmentSummary> = object :
      DataSourceFactory<Int, PersonGroupAssignmentSummary>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<PersonGroupAssignmentSummary>> = LiveDataImpl(_db, listOf("ClazzEnrolment",
        "Person", "ClazzAssignment", "CourseBlock", "CourseGroupMember", "CourseAssignmentMark",
        "CourseAssignmentSubmission", "Comments"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |         
      |            WITH SubmitterList (submitterId, name)
      |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
      |                       Person.firstNames || ' ' || Person.lastName AS name
      |                  FROM ClazzEnrolment
      |                  
      |                       JOIN Person 
      |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
      |                        
      |                       JOIN ClazzAssignment
      |                       ON ClazzAssignment.caUid = ?
      |
      |                       JOIN CourseBlock
      |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
      |                       AND CourseBlock.cbType = 103
      |                       
      |                 WHERE ClazzAssignment.caGroupUid = 0
      |                   AND clazzEnrolmentClazzUid = ?
      |                   AND clazzEnrolmentActive
      |                   AND clazzEnrolmentRole = 1000
      |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
      |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
      |              GROUP BY submitterId, name
      |            UNION                 
      |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
      |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
      |               FROM CourseGroupMember
      |                    JOIN ClazzAssignment
      |                    ON ClazzAssignment.caUid = ?
      |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
      |                AND ClazzAssignment.caGroupUid != 0
      |                AND CourseGroupMember.cgmGroupNumber != 0
      |           GROUP BY submitterId, name
      |            )
      |        
      |        
      |         SELECT submitterId AS submitterUid,
      |                name, 
      |                
      |                 COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
      |                          THEN 2 
      |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
      |                          THEN 1 
      |                          ELSE 0 END), 
      |                               0) AS fileSubmissionStatus,
      |                
      |                (CASE WHEN ClazzAssignment.caGroupUid = 0 
      |                 THEN 'TRUE' 
      |                 ELSE 'FALSE' END) AS isGroupAssignment,
      |                 
      |                 cm.commentsText AS latestPrivateComment 
      |
      |           FROM SubmitterList
      |                JOIN ClazzAssignment
      |                ON ClazzAssignment.caUid = ?
      |           
      |                LEFT JOIN CourseAssignmentMark
      |                ON CourseAssignmentMark.camUid = (SELECT camUid
      |                                                    FROM CourseAssignmentMark
      |                                                   WHERE camAssignmentUid = ?
      |                                                     AND camSubmitterUid = SubmitterList.submitterId
      |                                                ORDER BY camLct DESC 
      |                                                   LIMIT 1)
      |                
      |                LEFT JOIN CourseAssignmentSubmission
      |                ON CourseAssignmentSubmission.casUid = (SELECT casUid
      |                                                          FROM CourseAssignmentSubmission
      |                                                         WHERE casAssignmentUid = ?
      |                                                           AND casSubmitterUid = SubmitterList.submitterId
      |                                                      ORDER BY casTimestamp DESC 
      |                                                         LIMIT 1)
      |                LEFT JOIN Comments AS cm 
      |                    ON cm.commentsUid = (
      |                                 SELECT Comments.commentsUid 
      |                                   FROM Comments 
      |                                  WHERE Comments.commentsEntityType = 520
      |                                    AND commentsEntityUid = ?
      |                                    AND NOT commentsInActive
      |                                    AND NOT commentsPublic
      |                                    AND (CASE WHEN ClazzAssignment.caGroupUid = 0
      |                                              THEN commentsPersonUid = SubmitterList.submitterId
      |                                              ELSE commentSubmitterUid = SubmitterList.submitterId END)
      |                               ORDER BY commentsDateTimeAdded DESC LIMIT 1)                                                      
      |                                                                      
      |          WHERE name LIKE ?
      |       ORDER BY name 
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,assignmentUid)
        _stmt.setLong(2,clazzUid)
        _stmt.setString(3,group)
        _stmt.setLong(4,assignmentUid)
        _stmt.setLong(5,assignmentUid)
        _stmt.setLong(6,assignmentUid)
        _stmt.setLong(7,assignmentUid)
        _stmt.setLong(8,assignmentUid)
        _stmt.setString(9,searchText)
        _stmt.setInt(10,_limit)
        _stmt.setInt(11,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_submitterUid = _result.getLong("submitterUid")
            val _tmp_name = _result.getString("name")
            val _tmp_latestPrivateComment = _result.getString("latestPrivateComment")
            val _tmp_fileSubmissionStatus = _result.getInt("fileSubmissionStatus")
            val _tmp_isGroupAssignment = _result.getBoolean("isGroupAssignment")
            PersonGroupAssignmentSummary().apply {
              this.submitterUid = _tmp_submitterUid
              this.name = _tmp_name
              this.latestPrivateComment = _tmp_latestPrivateComment
              this.fileSubmissionStatus = _tmp_fileSubmissionStatus
              this.isGroupAssignment = _tmp_isGroupAssignment
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("ClazzEnrolment",
        "Person", "ClazzAssignment", "CourseBlock", "CourseGroupMember", "CourseAssignmentMark",
        "CourseAssignmentSubmission", "Comments"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |         
      |            WITH SubmitterList (submitterId, name)
      |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
      |                       Person.firstNames || ' ' || Person.lastName AS name
      |                  FROM ClazzEnrolment
      |                  
      |                       JOIN Person 
      |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
      |                        
      |                       JOIN ClazzAssignment
      |                       ON ClazzAssignment.caUid = ?
      |
      |                       JOIN CourseBlock
      |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
      |                       AND CourseBlock.cbType = 103
      |                       
      |                 WHERE ClazzAssignment.caGroupUid = 0
      |                   AND clazzEnrolmentClazzUid = ?
      |                   AND clazzEnrolmentActive
      |                   AND clazzEnrolmentRole = 1000
      |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
      |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
      |              GROUP BY submitterId, name
      |            UNION                 
      |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
      |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
      |               FROM CourseGroupMember
      |                    JOIN ClazzAssignment
      |                    ON ClazzAssignment.caUid = ?
      |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
      |                AND ClazzAssignment.caGroupUid != 0
      |                AND CourseGroupMember.cgmGroupNumber != 0
      |           GROUP BY submitterId, name
      |            )
      |        
      |        
      |         SELECT submitterId AS submitterUid,
      |                name, 
      |                
      |                 COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
      |                          THEN 2 
      |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
      |                          THEN 1 
      |                          ELSE 0 END), 
      |                               0) AS fileSubmissionStatus,
      |                
      |                (CASE WHEN ClazzAssignment.caGroupUid = 0 
      |                 THEN 'TRUE' 
      |                 ELSE 'FALSE' END) AS isGroupAssignment,
      |                 
      |                 cm.commentsText AS latestPrivateComment 
      |
      |           FROM SubmitterList
      |                JOIN ClazzAssignment
      |                ON ClazzAssignment.caUid = ?
      |           
      |                LEFT JOIN CourseAssignmentMark
      |                ON CourseAssignmentMark.camUid = (SELECT camUid
      |                                                    FROM CourseAssignmentMark
      |                                                   WHERE camAssignmentUid = ?
      |                                                     AND camSubmitterUid = SubmitterList.submitterId
      |                                                ORDER BY camLct DESC 
      |                                                   LIMIT 1)
      |                
      |                LEFT JOIN CourseAssignmentSubmission
      |                ON CourseAssignmentSubmission.casUid = (SELECT casUid
      |                                                          FROM CourseAssignmentSubmission
      |                                                         WHERE casAssignmentUid = ?
      |                                                           AND casSubmitterUid = SubmitterList.submitterId
      |                                                      ORDER BY casTimestamp DESC 
      |                                                         LIMIT 1)
      |                LEFT JOIN Comments AS cm 
      |                    ON cm.commentsUid = (
      |                                 SELECT Comments.commentsUid 
      |                                   FROM Comments 
      |                                  WHERE Comments.commentsEntityType = 520
      |                                    AND commentsEntityUid = ?
      |                                    AND NOT commentsInActive
      |                                    AND NOT commentsPublic
      |                                    AND (CASE WHEN ClazzAssignment.caGroupUid = 0
      |                                              THEN commentsPersonUid = SubmitterList.submitterId
      |                                              ELSE commentSubmitterUid = SubmitterList.submitterId END)
      |                               ORDER BY commentsDateTimeAdded DESC LIMIT 1)                                                      
      |                                                                      
      |          WHERE name LIKE ?
      |       ORDER BY name 
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,assignmentUid)
        _stmt.setLong(2,clazzUid)
        _stmt.setString(3,group)
        _stmt.setLong(4,assignmentUid)
        _stmt.setLong(5,assignmentUid)
        _stmt.setLong(6,assignmentUid)
        _stmt.setLong(7,assignmentUid)
        _stmt.setLong(8,assignmentUid)
        _stmt.setString(9,searchText)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun getSubmitterUid(assignmentUid: Long, personUid: Long): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT (CASE WHEN ClazzAssignment.caGroupUid = 0 
  |                     THEN ? 
  |                     WHEN CourseGroupMember.cgmUid IS NULL 
  |                     THEN 0 
  |                     ELSE CourseGroupMember.cgmGroupNumber END) as submitterUid
  |          FROM ClazzAssignment
  |               LEFT JOIN CourseGroupMember
  |               ON cgmSetUid = ClazzAssignment.caGroupUid
  |               AND cgmPersonUid = ?
  |         WHERE caUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,personUid)
    _stmt.setLong(2,personUid)
    _stmt.setLong(3,assignmentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): ClazzAssignment? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ClazzAssignment 
  |         WHERE caUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_caUid = _result.getLong("caUid")
        val _tmp_caTitle = _result.getString("caTitle")
        val _tmp_caDescription = _result.getString("caDescription")
        val _tmp_caGroupUid = _result.getLong("caGroupUid")
        val _tmp_caActive = _result.getBoolean("caActive")
        val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
        val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
        val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
        val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
        val _tmp_caFileType = _result.getInt("caFileType")
        val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
        val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
        val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
        val _tmp_caMarkingType = _result.getInt("caMarkingType")
        val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
        val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
        val _tmp_caTextLimit = _result.getInt("caTextLimit")
        val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
        val _tmp_caClazzUid = _result.getLong("caClazzUid")
        val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
        val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
        val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
        val _tmp_caLct = _result.getLong("caLct")
        ClazzAssignment().apply {
          this.caUid = _tmp_caUid
          this.caTitle = _tmp_caTitle
          this.caDescription = _tmp_caDescription
          this.caGroupUid = _tmp_caGroupUid
          this.caActive = _tmp_caActive
          this.caClassCommentEnabled = _tmp_caClassCommentEnabled
          this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
          this.caCompletionCriteria = _tmp_caCompletionCriteria
          this.caRequireFileSubmission = _tmp_caRequireFileSubmission
          this.caFileType = _tmp_caFileType
          this.caSizeLimit = _tmp_caSizeLimit
          this.caNumberOfFiles = _tmp_caNumberOfFiles
          this.caSubmissionPolicy = _tmp_caSubmissionPolicy
          this.caMarkingType = _tmp_caMarkingType
          this.caRequireTextSubmission = _tmp_caRequireTextSubmission
          this.caTextLimitType = _tmp_caTextLimitType
          this.caTextLimit = _tmp_caTextLimit
          this.caXObjectUid = _tmp_caXObjectUid
          this.caClazzUid = _tmp_caClazzUid
          this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
          this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
          this.caLastChangedBy = _tmp_caLastChangedBy
          this.caLct = _tmp_caLct
        }
      }
    }
  }

  public override suspend fun getGroupUidFromAssignment(uid: Long): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |          SELECT COALESCE((
  |           SELECT caGroupUid
  |           FROM ClazzAssignment
  |          WHERE caUid = ?),-1)
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  public override suspend fun findByUidWithBlockAsync(uid: Long): ClazzAssignmentWithCourseBlock? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ClazzAssignment
  |               LEFT JOIN CourseBlock
  |               ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
  |               AND CourseBlock.cbType = 103
  |         WHERE caUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_caUid = _result.getLong("caUid")
        val _tmp_caTitle = _result.getString("caTitle")
        val _tmp_caDescription = _result.getString("caDescription")
        val _tmp_caGroupUid = _result.getLong("caGroupUid")
        val _tmp_caActive = _result.getBoolean("caActive")
        val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
        val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
        val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
        val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
        val _tmp_caFileType = _result.getInt("caFileType")
        val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
        val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
        val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
        val _tmp_caMarkingType = _result.getInt("caMarkingType")
        val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
        val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
        val _tmp_caTextLimit = _result.getInt("caTextLimit")
        val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
        val _tmp_caClazzUid = _result.getLong("caClazzUid")
        val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
        val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
        val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
        val _tmp_caLct = _result.getLong("caLct")
        var _tmp_CourseBlock_nullCount = 0
        val _tmp_cbUid = _result.getLong("cbUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbType = _result.getInt("cbType")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbTitle = _result.getString("cbTitle")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbDescription = _result.getString("cbDescription")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbMaxPoints = _result.getInt("cbMaxPoints")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbMinPoints = _result.getInt("cbMinPoints")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbIndex = _result.getInt("cbIndex")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbActive = _result.getBoolean("cbActive")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbLct = _result.getLong("cbLct")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 19
        ClazzAssignmentWithCourseBlock().apply {
          this.caUid = _tmp_caUid
          this.caTitle = _tmp_caTitle
          this.caDescription = _tmp_caDescription
          this.caGroupUid = _tmp_caGroupUid
          this.caActive = _tmp_caActive
          this.caClassCommentEnabled = _tmp_caClassCommentEnabled
          this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
          this.caCompletionCriteria = _tmp_caCompletionCriteria
          this.caRequireFileSubmission = _tmp_caRequireFileSubmission
          this.caFileType = _tmp_caFileType
          this.caSizeLimit = _tmp_caSizeLimit
          this.caNumberOfFiles = _tmp_caNumberOfFiles
          this.caSubmissionPolicy = _tmp_caSubmissionPolicy
          this.caMarkingType = _tmp_caMarkingType
          this.caRequireTextSubmission = _tmp_caRequireTextSubmission
          this.caTextLimitType = _tmp_caTextLimitType
          this.caTextLimit = _tmp_caTextLimit
          this.caXObjectUid = _tmp_caXObjectUid
          this.caClazzUid = _tmp_caClazzUid
          this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
          this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
          this.caLastChangedBy = _tmp_caLastChangedBy
          this.caLct = _tmp_caLct
          if(!_tmp_CourseBlock_isAllNull) {
            this.block = CourseBlock().apply {
              this.cbUid = _tmp_cbUid
              this.cbType = _tmp_cbType
              this.cbIndentLevel = _tmp_cbIndentLevel
              this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
              this.cbTitle = _tmp_cbTitle
              this.cbDescription = _tmp_cbDescription
              this.cbCompletionCriteria = _tmp_cbCompletionCriteria
              this.cbHideUntilDate = _tmp_cbHideUntilDate
              this.cbDeadlineDate = _tmp_cbDeadlineDate
              this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
              this.cbGracePeriodDate = _tmp_cbGracePeriodDate
              this.cbMaxPoints = _tmp_cbMaxPoints
              this.cbMinPoints = _tmp_cbMinPoints
              this.cbIndex = _tmp_cbIndex
              this.cbClazzUid = _tmp_cbClazzUid
              this.cbActive = _tmp_cbActive
              this.cbHidden = _tmp_cbHidden
              this.cbEntityUid = _tmp_cbEntityUid
              this.cbLct = _tmp_cbLct
            }
          }
        }
      }
    }
  }

  public override fun findClazzAssignment(): ClazzAssignment? =
      _db.prepareAndUseStatement(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM ClazzAssignment LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_caUid = _result.getLong("caUid")
        val _tmp_caTitle = _result.getString("caTitle")
        val _tmp_caDescription = _result.getString("caDescription")
        val _tmp_caGroupUid = _result.getLong("caGroupUid")
        val _tmp_caActive = _result.getBoolean("caActive")
        val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
        val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
        val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
        val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
        val _tmp_caFileType = _result.getInt("caFileType")
        val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
        val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
        val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
        val _tmp_caMarkingType = _result.getInt("caMarkingType")
        val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
        val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
        val _tmp_caTextLimit = _result.getInt("caTextLimit")
        val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
        val _tmp_caClazzUid = _result.getLong("caClazzUid")
        val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
        val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
        val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
        val _tmp_caLct = _result.getLong("caLct")
        ClazzAssignment().apply {
          this.caUid = _tmp_caUid
          this.caTitle = _tmp_caTitle
          this.caDescription = _tmp_caDescription
          this.caGroupUid = _tmp_caGroupUid
          this.caActive = _tmp_caActive
          this.caClassCommentEnabled = _tmp_caClassCommentEnabled
          this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
          this.caCompletionCriteria = _tmp_caCompletionCriteria
          this.caRequireFileSubmission = _tmp_caRequireFileSubmission
          this.caFileType = _tmp_caFileType
          this.caSizeLimit = _tmp_caSizeLimit
          this.caNumberOfFiles = _tmp_caNumberOfFiles
          this.caSubmissionPolicy = _tmp_caSubmissionPolicy
          this.caMarkingType = _tmp_caMarkingType
          this.caRequireTextSubmission = _tmp_caRequireTextSubmission
          this.caTextLimitType = _tmp_caTextLimitType
          this.caTextLimit = _tmp_caTextLimit
          this.caXObjectUid = _tmp_caXObjectUid
          this.caClazzUid = _tmp_caClazzUid
          this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
          this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
          this.caLastChangedBy = _tmp_caLastChangedBy
          this.caLct = _tmp_caLct
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<ClazzAssignment?> = LiveDataImpl(_db,
      listOf("ClazzAssignment"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |SELECT * 
    |                      FROM ClazzAssignment 
    |                     WHERE caUid = ?
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_caUid = _result.getLong("caUid")
          val _tmp_caTitle = _result.getString("caTitle")
          val _tmp_caDescription = _result.getString("caDescription")
          val _tmp_caGroupUid = _result.getLong("caGroupUid")
          val _tmp_caActive = _result.getBoolean("caActive")
          val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
          val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
          val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
          val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
          val _tmp_caFileType = _result.getInt("caFileType")
          val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
          val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
          val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
          val _tmp_caMarkingType = _result.getInt("caMarkingType")
          val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
          val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
          val _tmp_caTextLimit = _result.getInt("caTextLimit")
          val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
          val _tmp_caClazzUid = _result.getLong("caClazzUid")
          val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
          val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
          val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
          val _tmp_caLct = _result.getLong("caLct")
          ClazzAssignment().apply {
            this.caUid = _tmp_caUid
            this.caTitle = _tmp_caTitle
            this.caDescription = _tmp_caDescription
            this.caGroupUid = _tmp_caGroupUid
            this.caActive = _tmp_caActive
            this.caClassCommentEnabled = _tmp_caClassCommentEnabled
            this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
            this.caCompletionCriteria = _tmp_caCompletionCriteria
            this.caRequireFileSubmission = _tmp_caRequireFileSubmission
            this.caFileType = _tmp_caFileType
            this.caSizeLimit = _tmp_caSizeLimit
            this.caNumberOfFiles = _tmp_caNumberOfFiles
            this.caSubmissionPolicy = _tmp_caSubmissionPolicy
            this.caMarkingType = _tmp_caMarkingType
            this.caRequireTextSubmission = _tmp_caRequireTextSubmission
            this.caTextLimitType = _tmp_caTextLimitType
            this.caTextLimit = _tmp_caTextLimit
            this.caXObjectUid = _tmp_caXObjectUid
            this.caClazzUid = _tmp_caClazzUid
            this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
            this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
            this.caLastChangedBy = _tmp_caLastChangedBy
            this.caLct = _tmp_caLct
          }
        }
      }
    }
  }
}
