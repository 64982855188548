package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionAttachment
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionWithAttachment
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentSubmissionDao_JdbcKt(
  public val _db: RoomDatabase,
) : CourseAssignmentSubmissionDao() {
  public val _insertAdapterCourseAssignmentSubmission_:
      EntityInsertionAdapter<CourseAssignmentSubmission> = object :
      EntityInsertionAdapter<CourseAssignmentSubmission>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO CourseAssignmentSubmission (casUid, casAssignmentUid, casSubmitterUid, casSubmitterPersonUid, casText, casType, casTimestamp) VALUES(?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseAssignmentSubmission): Unit {
      if(entity.casUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.casUid)
      }
      stmt.setLong(2, entity.casAssignmentUid)
      stmt.setLong(3, entity.casSubmitterUid)
      stmt.setLong(4, entity.casSubmitterPersonUid)
      stmt.setString(5, entity.casText)
      stmt.setInt(6, entity.casType)
      stmt.setLong(7, entity.casTimestamp)
    }
  }

  public override suspend fun insertListAsync(entityList: List<CourseAssignmentSubmission>): Unit {
    _insertAdapterCourseAssignmentSubmission_.insertListAsync(entityList)
  }

  public override fun insert(entity: CourseAssignmentSubmission): Long {
    val _retVal = _insertAdapterCourseAssignmentSubmission_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseAssignmentSubmission): Long {
    val _retVal = _insertAdapterCourseAssignmentSubmission_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CourseAssignmentSubmission>): Unit {
    _insertAdapterCourseAssignmentSubmission_.insertList(entityList)
  }

  public override fun updateList(entityList: List<CourseAssignmentSubmission>): Unit {
    val _sql =
        "UPDATE CourseAssignmentSubmission SET casAssignmentUid = ?, casSubmitterUid = ?, casSubmitterPersonUid = ?, casText = ?, casType = ?, casTimestamp = ? WHERE casUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.casAssignmentUid)
        _stmt.setLong(2, _entity.casSubmitterUid)
        _stmt.setLong(3, _entity.casSubmitterPersonUid)
        _stmt.setString(4, _entity.casText)
        _stmt.setInt(5, _entity.casType)
        _stmt.setLong(6, _entity.casTimestamp)
        _stmt.setLong(7, _entity.casUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseAssignmentSubmission): Unit {
    val _sql =
        "UPDATE CourseAssignmentSubmission SET casAssignmentUid = ?, casSubmitterUid = ?, casSubmitterPersonUid = ?, casText = ?, casType = ?, casTimestamp = ? WHERE casUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.casAssignmentUid)
      _stmt.setLong(2, entity.casSubmitterUid)
      _stmt.setLong(3, entity.casSubmitterPersonUid)
      _stmt.setString(4, entity.casText)
      _stmt.setInt(5, entity.casType)
      _stmt.setLong(6, entity.casTimestamp)
      _stmt.setLong(7, entity.casUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |      SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |             ? AS casDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentSubmission
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |             (SELECT casVersionId
    |                FROM CourseAssignmentSubmissionReplicate
    |               WHERE casPk = CourseAssignmentSubmission.casUid
    |                 AND casDestination = ?), 0) 
    |      /*psql ON CONFLICT(casPk, casDestination) DO UPDATE
    |             SET casPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |      SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |             ? AS casDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentSubmission
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |             (SELECT casVersionId
    |                FROM CourseAssignmentSubmissionReplicate
    |               WHERE casPk = CourseAssignmentSubmission.casUid
    |                 AND casDestination = ?), 0) 
    |       ON CONFLICT(casPk, casDestination) DO UPDATE
    |             SET casPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.setLong(3,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |  SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |         UserSession.usClientNodeId AS casDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentSubmission
    |             ON ChangeLog.chTableId = 522
    |                AND ChangeLog.chEntityPk = CourseAssignmentSubmission.casUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |         (SELECT casVersionId
    |            FROM CourseAssignmentSubmissionReplicate
    |           WHERE casPk = CourseAssignmentSubmission.casUid
    |             AND casDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(casPk, casDestination) DO UPDATE
    |     SET casPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |  SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |         UserSession.usClientNodeId AS casDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentSubmission
    |             ON ChangeLog.chTableId = 522
    |                AND ChangeLog.chEntityPk = CourseAssignmentSubmission.casUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |         (SELECT casVersionId
    |            FROM CourseAssignmentSubmissionReplicate
    |           WHERE casPk = CourseAssignmentSubmission.casUid
    |             AND casDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(casPk, casDestination) DO UPDATE
    |     SET casPending = true
    |                 
    | 
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getAllSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long):
      DataSourceFactory<Int, CourseAssignmentSubmissionWithAttachment> = object :
      DataSourceFactory<Int, CourseAssignmentSubmissionWithAttachment>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<CourseAssignmentSubmissionWithAttachment>> = LiveDataImpl(_db,
        listOf("CourseAssignmentSubmission", "CourseAssignmentSubmissionAttachment"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (
      |        SELECT * 
      |          FROM CourseAssignmentSubmission
      |          
      |               LEFT JOIN CourseAssignmentSubmissionAttachment
      |               ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
      |               
      |         WHERE casAssignmentUid = ?
      |           AND casSubmitterUid = ?
      |      ORDER BY casTimestamp DESC
      |    ) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,assignmentUid)
        _stmt.setLong(2,submitterUid)
        _stmt.setInt(3,_limit)
        _stmt.setInt(4,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_casUid = _result.getLong("casUid")
            val _tmp_casAssignmentUid = _result.getLong("casAssignmentUid")
            val _tmp_casSubmitterUid = _result.getLong("casSubmitterUid")
            val _tmp_casSubmitterPersonUid = _result.getLong("casSubmitterPersonUid")
            val _tmp_casText = _result.getString("casText")
            val _tmp_casType = _result.getInt("casType")
            val _tmp_casTimestamp = _result.getLong("casTimestamp")
            var _tmp_CourseAssignmentSubmissionAttachment_nullCount = 0
            val _tmp_casaUid = _result.getLong("casaUid")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_casaSubmissionUid = _result.getLong("casaSubmissionUid")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_casaMimeType = _result.getString("casaMimeType")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_casaFileName = _result.getString("casaFileName")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_casaUri = _result.getString("casaUri")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_casaMd5 = _result.getString("casaMd5")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_casaSize = _result.getInt("casaSize")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_casaTimestamp = _result.getLong("casaTimestamp")
            if(_result.wasNull()) _tmp_CourseAssignmentSubmissionAttachment_nullCount++
            val _tmp_CourseAssignmentSubmissionAttachment_isAllNull = _tmp_CourseAssignmentSubmissionAttachment_nullCount == 8
            CourseAssignmentSubmissionWithAttachment().apply {
              this.casUid = _tmp_casUid
              this.casAssignmentUid = _tmp_casAssignmentUid
              this.casSubmitterUid = _tmp_casSubmitterUid
              this.casSubmitterPersonUid = _tmp_casSubmitterPersonUid
              this.casText = _tmp_casText
              this.casType = _tmp_casType
              this.casTimestamp = _tmp_casTimestamp
              if(!_tmp_CourseAssignmentSubmissionAttachment_isAllNull) {
                this.attachment = CourseAssignmentSubmissionAttachment().apply {
                  this.casaUid = _tmp_casaUid
                  this.casaSubmissionUid = _tmp_casaSubmissionUid
                  this.casaMimeType = _tmp_casaMimeType
                  this.casaFileName = _tmp_casaFileName
                  this.casaUri = _tmp_casaUri
                  this.casaMd5 = _tmp_casaMd5
                  this.casaSize = _tmp_casaSize
                  this.casaTimestamp = _tmp_casaTimestamp
                }
              }
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db,
        listOf("CourseAssignmentSubmission", "CourseAssignmentSubmissionAttachment"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (
      |        SELECT * 
      |          FROM CourseAssignmentSubmission
      |          
      |               LEFT JOIN CourseAssignmentSubmissionAttachment
      |               ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
      |               
      |         WHERE casAssignmentUid = ?
      |           AND casSubmitterUid = ?
      |      ORDER BY casTimestamp DESC
      |    ) 
      """.trimMargin() )) { _stmt -> 
        _stmt.setLong(1,assignmentUid)
        _stmt.setLong(2,submitterUid)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override suspend fun countFileSubmissionFromStudent(assignmentUid: Long,
      submitterUid: Long): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Count(casUid)
  |          FROM CourseAssignmentSubmission
  |         WHERE casAssignmentUid = ?
  |           AND casSubmitterUid = ?
  |           AND casType = 2
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,assignmentUid)
    _stmt.setLong(2,submitterUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  public override suspend fun countSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT Count(casUid)
  |          FROM CourseAssignmentSubmission
  |         WHERE casAssignmentUid = ?
  |           AND casSubmitterUid = ?
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,assignmentUid)
    _stmt.setLong(2,submitterUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  public override fun getStatusOfAssignmentForSubmitter(assignmentUid: Long, submitterUid: Long):
      LiveData<Int> = LiveDataImpl(_db, listOf("CourseAssignmentSubmission",
      "CourseAssignmentMark"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |           SELECT COALESCE((
    |                SELECT (CASE WHEN CourseAssignmentMark.camAssignmentUid IS NOT NULL 
    |                             THEN 2
    |                             ELSE 1 
    |                             END) AS status
    |                  FROM CourseAssignmentSubmission
    |                       
    |                       LEFT JOIN CourseAssignmentMark
    |                       ON CourseAssignmentMark.camAssignmentUid = ?
    |                       AND CourseAssignmentMark.camSubmitterUid = ?
    |                       
    |                 WHERE CourseAssignmentSubmission.casAssignmentUid = ?
    |                   AND CourseAssignmentSubmission.casSubmitterUid = ?
    |                 LIMIT 1
    |           ),0) AS Status
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,assignmentUid)
      _stmt.setLong(2,submitterUid)
      _stmt.setLong(3,assignmentUid)
      _stmt.setLong(4,submitterUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  public override suspend fun findLastSubmissionFromStudent(submitterUid: Long,
      assignmentUid: Long): CourseAssignmentSubmission? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |
  |        SELECT * 
  |          FROM CourseAssignmentSubmission
  |         WHERE CourseAssignmentSubmission.casAssignmentUid = ?
  |           AND CourseAssignmentSubmission.casSubmitterUid = ?
  |      ORDER BY casTimestamp DESC
  |         LIMIT 1
  |    
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,assignmentUid)
    _stmt.setLong(2,submitterUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_casUid = _result.getLong("casUid")
        val _tmp_casAssignmentUid = _result.getLong("casAssignmentUid")
        val _tmp_casSubmitterUid = _result.getLong("casSubmitterUid")
        val _tmp_casSubmitterPersonUid = _result.getLong("casSubmitterPersonUid")
        val _tmp_casText = _result.getString("casText")
        val _tmp_casType = _result.getInt("casType")
        val _tmp_casTimestamp = _result.getLong("casTimestamp")
        CourseAssignmentSubmission().apply {
          this.casUid = _tmp_casUid
          this.casAssignmentUid = _tmp_casAssignmentUid
          this.casSubmitterUid = _tmp_casSubmitterUid
          this.casSubmitterPersonUid = _tmp_casSubmitterPersonUid
          this.casText = _tmp_casText
          this.casType = _tmp_casType
          this.casTimestamp = _tmp_casTimestamp
        }
      }
    }
  }

  public override fun checkNoSubmissionsMade(assignmentUid: Long): LiveData<Boolean> =
      LiveDataImpl(_db, listOf("CourseAssignmentSubmission"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |         SELECT NOT EXISTS(SELECT 1
    |                        FROM CourseAssignmentSubmission
    |                       WHERE CourseAssignmentSubmission.casAssignmentUid = ?
    |                       LIMIT 1)
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,assignmentUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(false) {
          _result.getBoolean(1)
        }
      }
    }
  }
}
