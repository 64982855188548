package com.ustadmobile.core.db.dao

import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.CourseTerminology
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CourseTerminologyDao : BaseDao<CourseTerminology> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findAllCourseTerminology(): DataSourceFactory<Int, CourseTerminology>

  public actual abstract fun findAllCourseTerminologyList(): List<CourseTerminology>

  public actual abstract suspend fun getTerminologyForClazz(clazzUid: Long): CourseTerminology?

  public actual abstract suspend fun findByUidAsync(uid: Long): CourseTerminology?

  public actual abstract suspend fun updateAsync(entity: CourseTerminology): Int
}
