package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.ClazzLog
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzLogDao : BaseDao<ClazzLog> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract fun replace(entity: ClazzLog): Long

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findByUid(uid: Long): ClazzLog?

  public actual abstract suspend fun findByUidAsync(uid: Long): ClazzLog?

  public actual abstract fun findByUidLive(uid: Long): LiveData<ClazzLog?>

  public actual abstract fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      DataSourceFactory<Int, ClazzLog>

  public actual abstract suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int):
      List<ClazzLog>

  public actual abstract suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog>

  public actual abstract fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog>

  public actual abstract fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int,
  ): LiveData<List<ClazzLog>>

  public actual abstract fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int):
      LiveData<Boolean>

  public actual abstract fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long,
  ): Unit

  public actual abstract suspend fun updateAsync(clazzLog: ClazzLog): Unit
}
