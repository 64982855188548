package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Schedule
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ScheduleDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ScheduleDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ScheduleDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun insert(entity: Schedule): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun updateAsync(entity: Schedule): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _dao.updateScheduleActivated(scheduleUid, active, changeTime)
  }

  public override fun findByUid(uid: Long): Schedule? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): Schedule? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun findAllSchedulesByClazzUid(clazzUid: Long): DataSourceFactory<Int, Schedule> {
    val _result = _dao.findAllSchedulesByClazzUid(clazzUid)
    return _result
  }

  public override fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule> {
    val _result = _dao.findAllSchedulesByClazzUidAsList(clazzUid)
    return _result
  }

  public override fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long):
      LiveData<List<Schedule>> {
    val _result = _dao.findAllSchedulesByClazzUidAsLiveList(clazzUid)
    return _result
  }

  public override suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule> {
    val _result = _dao.findAllSchedulesByClazzUidAsync(clazzUid)
    return _result
  }

  public override suspend fun insertAsync(entity: Schedule): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<Schedule>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<Schedule>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Schedule): Unit {
    _dao.update(entity)
  }

  public override suspend fun insertListAsync(entityList: List<Schedule>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<Schedule>): Unit {
    _dao.updateListAsync(entityList)
  }
}
