package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.LiveDataImpl
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class HolidayCalendarDao_JdbcKt(
  public val _db: RoomDatabase,
) : HolidayCalendarDao() {
  public val _insertAdapterHolidayCalendar_upsert: EntityInsertionAdapter<HolidayCalendar> = object
      : EntityInsertionAdapter<HolidayCalendar>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT OR REPLACE INTO HolidayCalendar (umCalendarUid, umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: HolidayCalendar):
        Unit {
      if(entity.umCalendarUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.umCalendarUid)
      }
      stmt.setString(2, entity.umCalendarName)
      stmt.setInt(3, entity.umCalendarCategory)
      stmt.setBoolean(4, entity.umCalendarActive)
      stmt.setLong(5, entity.umCalendarMasterChangeSeqNum)
      stmt.setLong(6, entity.umCalendarLocalChangeSeqNum)
      stmt.setInt(7, entity.umCalendarLastChangedBy)
      stmt.setLong(8, entity.umCalendarLct)
    }
  }

  public val _insertAdapterHolidayCalendar_: EntityInsertionAdapter<HolidayCalendar> = object :
      EntityInsertionAdapter<HolidayCalendar>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO HolidayCalendar (umCalendarUid, umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: HolidayCalendar):
        Unit {
      if(entity.umCalendarUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.umCalendarUid)
      }
      stmt.setString(2, entity.umCalendarName)
      stmt.setInt(3, entity.umCalendarCategory)
      stmt.setBoolean(4, entity.umCalendarActive)
      stmt.setLong(5, entity.umCalendarMasterChangeSeqNum)
      stmt.setLong(6, entity.umCalendarLocalChangeSeqNum)
      stmt.setInt(7, entity.umCalendarLastChangedBy)
      stmt.setLong(8, entity.umCalendarLct)
    }
  }

  public override fun replaceList(list: List<HolidayCalendar>): Unit {
    _insertAdapterHolidayCalendar_upsert.insertList(list)
  }

  public override fun insert(entity: HolidayCalendar): Long {
    val _retVal = _insertAdapterHolidayCalendar_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: HolidayCalendar): Long {
    val _retVal = _insertAdapterHolidayCalendar_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<HolidayCalendar>): Unit {
    _insertAdapterHolidayCalendar_.insertList(entityList)
  }

  public override suspend fun updateAsync(entity: HolidayCalendar): Int {
    var _result = 0
    val _sql =
        "UPDATE HolidayCalendar SET umCalendarName = ?, umCalendarCategory = ?, umCalendarActive = ?, umCalendarMasterChangeSeqNum = ?, umCalendarLocalChangeSeqNum = ?, umCalendarLastChangedBy = ?, umCalendarLct = ? WHERE umCalendarUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.umCalendarName)
      _stmt.setInt(2, entity.umCalendarCategory)
      _stmt.setBoolean(3, entity.umCalendarActive)
      _stmt.setLong(4, entity.umCalendarMasterChangeSeqNum)
      _stmt.setLong(5, entity.umCalendarLocalChangeSeqNum)
      _stmt.setInt(6, entity.umCalendarLastChangedBy)
      _stmt.setLong(7, entity.umCalendarLct)
      _stmt.setLong(8, entity.umCalendarUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun updateList(entityList: List<HolidayCalendar>): Unit {
    val _sql =
        "UPDATE HolidayCalendar SET umCalendarName = ?, umCalendarCategory = ?, umCalendarActive = ?, umCalendarMasterChangeSeqNum = ?, umCalendarLocalChangeSeqNum = ?, umCalendarLastChangedBy = ?, umCalendarLct = ? WHERE umCalendarUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.umCalendarName)
        _stmt.setInt(2, _entity.umCalendarCategory)
        _stmt.setBoolean(3, _entity.umCalendarActive)
        _stmt.setLong(4, _entity.umCalendarMasterChangeSeqNum)
        _stmt.setLong(5, _entity.umCalendarLocalChangeSeqNum)
        _stmt.setInt(6, _entity.umCalendarLastChangedBy)
        _stmt.setLong(7, _entity.umCalendarLct)
        _stmt.setLong(8, _entity.umCalendarUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: HolidayCalendar): Unit {
    val _sql =
        "UPDATE HolidayCalendar SET umCalendarName = ?, umCalendarCategory = ?, umCalendarActive = ?, umCalendarMasterChangeSeqNum = ?, umCalendarLocalChangeSeqNum = ?, umCalendarLastChangedBy = ?, umCalendarLct = ? WHERE umCalendarUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.umCalendarName)
      _stmt.setInt(2, entity.umCalendarCategory)
      _stmt.setBoolean(3, entity.umCalendarActive)
      _stmt.setLong(4, entity.umCalendarMasterChangeSeqNum)
      _stmt.setLong(5, entity.umCalendarLocalChangeSeqNum)
      _stmt.setInt(6, entity.umCalendarLastChangedBy)
      _stmt.setLong(7, entity.umCalendarLct)
      _stmt.setLong(8, entity.umCalendarUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |      SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcPk,
    |             ? AS hcDestination
    |        FROM HolidayCalendar
    |             JOIN UserSession
    |                  ON UserSession.usClientNodeId = ?
    |        --notpsql 
    |       WHERE HolidayCalendar.umCalendarLct != COALESCE(
    |             (SELECT hcVersionId
    |                FROM HolidayCalendarReplicate
    |               WHERE hcPk = HolidayCalendar.umCalendarUid
    |                 AND hcDestination = UserSession.usClientNodeId), 0) 
    |         --endnotpsql        
    |      /*psql ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |      SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcPk,
    |             ? AS hcDestination
    |        FROM HolidayCalendar
    |             JOIN UserSession
    |                  ON UserSession.usClientNodeId = ?
    |       ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |  SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcUid,
    |         UserSession.usClientNodeId AS hcDestination
    |    FROM ChangeLog
    |         JOIN HolidayCalendar
    |             ON ChangeLog.chTableId = 28
    |                AND ChangeLog.chEntityPk = HolidayCalendar.umCalendarUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     --notpsql 
    |      AND HolidayCalendar.umCalendarLct != COALESCE(
    |             (SELECT hcVersionId
    |                FROM HolidayCalendarReplicate
    |               WHERE hcPk = HolidayCalendar.umCalendarUid
    |                 AND hcDestination = UserSession.usClientNodeId), 0) 
    |         --endnotpsql    
    |   /*psql ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk     
    |        */                                           
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |  SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcUid,
    |         UserSession.usClientNodeId AS hcDestination
    |    FROM ChangeLog
    |         JOIN HolidayCalendar
    |             ON ChangeLog.chTableId = 28
    |                AND ChangeLog.chEntityPk = HolidayCalendar.umCalendarUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |    ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk     
    |                                                   
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllHolidaysWithEntriesCount():
      DataSourceFactory<Int, HolidayCalendarWithNumEntries> = object :
      DataSourceFactory<Int, HolidayCalendarWithNumEntries>() {
    public override fun getData(_offset: Int, _limit: Int):
        LiveData<List<HolidayCalendarWithNumEntries>> = LiveDataImpl(_db, listOf("Holiday",
        "HolidayCalendar"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT * FROM (SELECT HolidayCalendar.* ,
      |            (SELECT COUNT(*) FROM Holiday 
      |               WHERE holHolidayCalendarUid = HolidayCalendar.umCalendarUid 
      |               AND CAST(holActive AS INTEGER) = 1) AS numEntries 
      |             FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND 
      |             umCalendarCategory = 1) LIMIT ? OFFSET ?
      """.trimMargin() )) { _stmt -> 
        _stmt.setInt(1,_limit)
        _stmt.setInt(2,_offset)
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapRows {
            val _tmp_numEntries = _result.getInt("numEntries")
            val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
            val _tmp_umCalendarName = _result.getString("umCalendarName")
            val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
            val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
            val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
            val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
            val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
            val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
            HolidayCalendarWithNumEntries().apply {
              this.numEntries = _tmp_numEntries
              this.umCalendarUid = _tmp_umCalendarUid
              this.umCalendarName = _tmp_umCalendarName
              this.umCalendarCategory = _tmp_umCalendarCategory
              this.umCalendarActive = _tmp_umCalendarActive
              this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
              this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
              this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
              this.umCalendarLct = _tmp_umCalendarLct
            }
          }
        }
      }
    }

    public override fun getLength(): LiveData<Int> = LiveDataImpl(_db, listOf("Holiday",
        "HolidayCalendar"))  {
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
      |SELECT COUNT(*) FROM (SELECT HolidayCalendar.* ,
      |            (SELECT COUNT(*) FROM Holiday 
      |               WHERE holHolidayCalendarUid = HolidayCalendar.umCalendarUid 
      |               AND CAST(holActive AS INTEGER) = 1) AS numEntries 
      |             FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND 
      |             umCalendarCategory = 1) 
      """.trimMargin() )) { _stmt -> 
        _stmt.executeQueryAsyncKmp().useResults{ _result -> 
          _result.mapNextRow(0) {
            _result.getInt(1)
          }
        }
      }
    }
  }

  public override fun findAllHolidaysLiveData(): LiveData<List<HolidayCalendar>> = LiveDataImpl(_db,
      listOf("HolidayCalendar"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND umCalendarCategory = 1"
        )) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
          val _tmp_umCalendarName = _result.getString("umCalendarName")
          val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
          val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
          val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
          val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
          val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
          val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
          HolidayCalendar().apply {
            this.umCalendarUid = _tmp_umCalendarUid
            this.umCalendarName = _tmp_umCalendarName
            this.umCalendarCategory = _tmp_umCalendarCategory
            this.umCalendarActive = _tmp_umCalendarActive
            this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
            this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
            this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
            this.umCalendarLct = _tmp_umCalendarLct
          }
        }
      }
    }
  }

  public override fun findByUidLive(uid: Long): LiveData<HolidayCalendar?> = LiveDataImpl(_db,
      listOf("HolidayCalendar"))  {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE umCalendarUid = ? AND CAST(umCalendarActive AS INTEGER) = 1"
        )) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
          val _tmp_umCalendarName = _result.getString("umCalendarName")
          val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
          val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
          val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
          val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
          val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
          val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
          HolidayCalendar().apply {
            this.umCalendarUid = _tmp_umCalendarUid
            this.umCalendarName = _tmp_umCalendarName
            this.umCalendarCategory = _tmp_umCalendarCategory
            this.umCalendarActive = _tmp_umCalendarActive
            this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
            this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
            this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
            this.umCalendarLct = _tmp_umCalendarLct
          }
        }
      }
    }
  }

  public override suspend fun findByUid(uid: Long): HolidayCalendar? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE umCalendarUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_umCalendarName = _result.getString("umCalendarName")
        val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
        val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
        val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
        val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
        val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
        val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
        HolidayCalendar().apply {
          this.umCalendarUid = _tmp_umCalendarUid
          this.umCalendarName = _tmp_umCalendarName
          this.umCalendarCategory = _tmp_umCalendarCategory
          this.umCalendarActive = _tmp_umCalendarActive
          this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
          this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
          this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
          this.umCalendarLct = _tmp_umCalendarLct
        }
      }
    }
  }

  public override suspend fun findByUidAsync(uid: Long): HolidayCalendar? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE umCalendarUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_umCalendarName = _result.getString("umCalendarName")
        val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
        val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
        val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
        val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
        val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
        val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
        HolidayCalendar().apply {
          this.umCalendarUid = _tmp_umCalendarUid
          this.umCalendarName = _tmp_umCalendarName
          this.umCalendarCategory = _tmp_umCalendarCategory
          this.umCalendarActive = _tmp_umCalendarActive
          this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
          this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
          this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
          this.umCalendarLct = _tmp_umCalendarLct
        }
      }
    }
  }
}
