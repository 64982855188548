package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ErrorReport
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ErrorReportDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ErrorReportDao,
) : ErrorReportDao() {
  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertAsync(errorReport: ErrorReport): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(errorReport.errUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(419)
      errorReport.errUid = _newPk
    }
    errorReport.errLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(errorReport)
    return errorReport.errUid
  }

  public override suspend fun findByUidAsync(errUid: Long): ErrorReport? =
      _dao.findByUidAsync(errUid)

  public override suspend fun findByErrorCode(errCode: Int): List<ErrorReport> =
      _dao.findByErrorCode(errCode)
}
