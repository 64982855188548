package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.ScopedGrant
import com.ustadmobile.lib.db.entities.ScopedGrantAndName
import com.ustadmobile.lib.db.entities.ScopedGrantWithName
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ScopedGrantDao {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun replicateOnChangeClazzBased(): Unit

  public actual abstract suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChangePersonBased(): Unit

  public actual abstract suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChangeSchoolBased(): Unit

  public actual abstract suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit

  public actual abstract suspend fun insertAsync(scopedGrant: ScopedGrant): Long

  public actual abstract suspend fun insertListAsync(scopedGrantList: List<ScopedGrant>): Unit

  public actual abstract suspend fun updateAsync(scopedGrant: ScopedGrant): Unit

  public actual abstract suspend fun updateListAsync(scopedGrantList: List<ScopedGrant>): Unit

  public actual abstract suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long):
      List<ScopedGrantAndName>

  public actual abstract fun findByTableIdAndEntityUidWithNameAsDataSource(tableId: Int,
      entityUid: Long): DataSourceFactory<Int, ScopedGrantWithName>

  public actual abstract fun findByTableIdAndEntityIdSync(tableId: Int, entityUid: Long):
      List<ScopedGrant>

  public actual abstract suspend fun findByUid(sgUid: Long): ScopedGrant?

  public actual abstract fun findByUidLiveWithName(sgUid: Long): LiveData<ScopedGrantWithName?>
}
