package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseGroupSet
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseGroupSetDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseGroupSetDao,
) : CourseGroupSetDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun updateAsync(entity: CourseGroupSet): Int {
    entity.cgsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun findAllCourseGroupSetForClazz(clazzUid: Long):
      DataSourceFactory<Int, CourseGroupSet> = _dao.findAllCourseGroupSetForClazz(clazzUid)

  public override fun findAllCourseGroupSetForClazzList(clazzUid: Long): List<CourseGroupSet> =
      throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUidAsync(uid: Long): CourseGroupSet? = _dao.findByUidAsync(uid)

  public override fun insert(entity: CourseGroupSet): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseGroupSet): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cgsUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(242)
      entity.cgsUid = _newPk
    }
    entity.cgsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cgsUid
  }

  public override fun insertList(entityList: List<CourseGroupSet>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<CourseGroupSet>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseGroupSet): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
