package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazzAndAttendance
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson
import com.ustadmobile.lib.db.entities.PersonWithClazzEnrolmentDetails
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzEnrolmentDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzEnrolmentDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzEnrolmentDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateClazzEnrolmentOnChange(): Unit {
    _dao.replicateClazzEnrolmentOnChange()
  }

  public override fun insertListAsync(entityList: List<ClazzEnrolment>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun findByPersonUidAndClazzUidAsync(personUid: Long, clazzUid: Long):
      ClazzEnrolment? {
    val _result = _dao.findByPersonUidAndClazzUidAsync(personUid, clazzUid)
    return _result
  }

  public override fun findAllEnrolmentsByPersonAndClazzUid(personUid: Long, clazzUid: Long):
      DataSourceFactory<Int, ClazzEnrolmentWithLeavingReason> {
    val _result = _dao.findAllEnrolmentsByPersonAndClazzUid(personUid, clazzUid)
    return _result
  }

  public override suspend fun findEnrolmentWithLeavingReason(enrolmentUid: Long):
      ClazzEnrolmentWithLeavingReason? {
    val _result = _dao.findEnrolmentWithLeavingReason(enrolmentUid)
    return _result
  }

  public override suspend fun updateDateLeftByUid(
    clazzEnrolmentUid: Long,
    endDate: Long,
    updateTime: Long,
  ): Unit {
    _dao.updateDateLeftByUid(clazzEnrolmentUid, endDate, updateTime)
  }

  public override suspend fun updateAsync(entity: ClazzEnrolment): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun findAllClazzesByPersonWithClazz(personUid: Long):
      DataSourceFactory<Int, ClazzEnrolmentWithClazzAndAttendance> {
    val _result = _dao.findAllClazzesByPersonWithClazz(personUid)
    return _result
  }

  public override suspend fun findMaxEndDateForEnrolment(
    selectedClazz: Long,
    selectedPerson: Long,
    selectedEnrolment: Long,
  ): Long {
    val _result = _dao.findMaxEndDateForEnrolment(selectedClazz, selectedPerson, selectedEnrolment)
    return _result
  }

  public override suspend fun findAllClazzesByPersonWithClazzAsListAsync(personUid: Long):
      List<ClazzEnrolmentWithClazz> {
    val _result = _dao.findAllClazzesByPersonWithClazzAsListAsync(personUid)
    return _result
  }

  public override suspend fun getAllClazzEnrolledAtTimeAsync(
    clazzUid: Long,
    date: Long,
    roleFilter: Int,
    personUidFilter: Long,
  ): List<ClazzEnrolmentWithPerson> {
    val _result = _dao.getAllClazzEnrolledAtTimeAsync(clazzUid, date, roleFilter, personUidFilter)
    return _result
  }

  public override suspend fun findByUid(uid: Long): ClazzEnrolment? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override fun findByUidLive(uid: Long): LiveData<ClazzEnrolment?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override suspend fun updateClazzEnrolmentActiveForPersonAndClazz(
    personUid: Long,
    clazzUid: Long,
    roleId: Int,
    active: Boolean,
    changeTime: Long,
  ): Int {
    val _result = _dao.updateClazzEnrolmentActiveForPersonAndClazz(personUid, clazzUid, roleId,
        active, changeTime)
    return _result
  }

  public override fun findByClazzUidAndRole(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
  ): DataSourceFactory<Int, PersonWithClazzEnrolmentDetails> {
    val _result = _dao.findByClazzUidAndRole(clazzUid, roleId, sortOrder, searchText, filter,
        accountPersonUid, currentTime)
    return _result
  }

  public override fun updateClazzEnrolmentActiveForClazzEnrolment(
    clazzEnrolmentUid: Long,
    enrolled: Boolean,
    timeChanged: Long,
  ): Int {
    val _result = _dao.updateClazzEnrolmentActiveForClazzEnrolment(clazzEnrolmentUid, enrolled,
        timeChanged)
    return _result
  }

  public override suspend fun updateClazzEnrolmentRole(
    personUid: Long,
    clazzUid: Long,
    newRole: Int,
    oldRole: Int,
    updateTime: Long,
  ): Int {
    val _result = _dao.updateClazzEnrolmentRole(personUid, clazzUid, newRole, oldRole, updateTime)
    return _result
  }

  public override fun insert(entity: ClazzEnrolment): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzEnrolment): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<ClazzEnrolment>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<ClazzEnrolment>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ClazzEnrolment): Unit {
    _dao.update(entity)
  }
}
