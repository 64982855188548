package com.ustadmobile.core.db

import com.ustadmobile.door.ext.deleteFromChangeLog
import com.ustadmobile.door.ext.withDoorTransactionAsync
import com.ustadmobile.door.replication.ReplicationRunOnChangeRunner
import com.ustadmobile.door.util.systemTimeInMillis
import io.github.aakira.napier.Napier
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.Set

@Suppress("LocalVariableName", "RedundantVisibilityModifier", "unused", "ClassName")
public class UmAppDatabase_ReplicationRunOnChangeRunner(
  private val _db: UmAppDatabase,
) : ReplicationRunOnChangeRunner {
  private suspend fun UmAppDatabase.handleClazzLogChanged(): Set<String> {
    clazzLogDao.replicateOnChange()
    deleteFromChangeLog(14)
    return setOf("ClazzLog")
  }

  private suspend fun UmAppDatabase.handleClazzLogAttendanceRecordChanged(): Set<String> {
    clazzLogAttendanceRecordDao.replicateOnChange()
    deleteFromChangeLog(15)
    return setOf("ClazzLogAttendanceRecord")
  }

  private suspend fun UmAppDatabase.handleScheduleChanged(): Set<String> {
    scheduleDao.replicateOnChange()
    deleteFromChangeLog(21)
    return setOf("Schedule")
  }

  private suspend fun UmAppDatabase.handleHolidayCalendarChanged(): Set<String> {
    holidayCalendarDao.replicateOnChange()
    deleteFromChangeLog(28)
    return setOf("HolidayCalendar")
  }

  private suspend fun UmAppDatabase.handleHolidayChanged(): Set<String> {
    holidayDao.replicateOnChange()
    deleteFromChangeLog(99)
    return setOf("Holiday")
  }

  private suspend fun UmAppDatabase.handlePersonChanged(): Set<String> {
    personDao.replicateOnChange()
    deleteFromChangeLog(9)
    return setOf("Person")
  }

  private suspend fun UmAppDatabase.handleClazzChanged(): Set<String> {
    clazzDao.replicateOnChange()
    deleteFromChangeLog(6)
    return setOf("Clazz")
  }

  private suspend fun UmAppDatabase.handleClazzEnrolmentChanged(): Set<String> {
    clazzEnrolmentDao.replicateClazzEnrolmentOnChange()
    deleteFromChangeLog(65)
    return setOf("ClazzEnrolment")
  }

  private suspend fun UmAppDatabase.handleLeavingReasonChanged(): Set<String> {
    leavingReasonDao.replicateOnChange()
    deleteFromChangeLog(410)
    return setOf("LeavingReason")
  }

  private suspend fun UmAppDatabase.handleContentEntryChanged(): Set<String> {
    contentEntryDao.replicateOnChange()
    deleteFromChangeLog(42)
    return setOf("ContentEntry")
  }

  private suspend fun UmAppDatabase.handleContentEntryContentCategoryJoinChanged(): Set<String> {
    contentEntryContentCategoryJoinDao.replicateOnChange()
    deleteFromChangeLog(3)
    return setOf("ContentEntryContentCategoryJoin")
  }

  private suspend fun UmAppDatabase.handleContentEntryParentChildJoinChanged(): Set<String> {
    contentEntryParentChildJoinDao.replicateOnChange()
    deleteFromChangeLog(7)
    return setOf("ContentEntryParentChildJoin")
  }

  private suspend fun UmAppDatabase.handleContentEntryRelatedEntryJoinChanged(): Set<String> {
    contentEntryRelatedEntryJoinDao.replicateOnChange()
    deleteFromChangeLog(8)
    return setOf("ContentEntryRelatedEntryJoin")
  }

  private suspend fun UmAppDatabase.handleContentCategorySchemaChanged(): Set<String> {
    contentCategorySchemaDao.replicateOnChange()
    deleteFromChangeLog(2)
    return setOf("ContentCategorySchema")
  }

  private suspend fun UmAppDatabase.handleContentCategoryChanged(): Set<String> {
    contentCategoryDao.replicateOnChange()
    deleteFromChangeLog(1)
    return setOf("ContentCategory")
  }

  private suspend fun UmAppDatabase.handleLanguageChanged(): Set<String> {
    languageDao.replicateOnChange()
    deleteFromChangeLog(13)
    return setOf("Language")
  }

  private suspend fun UmAppDatabase.handleLanguageVariantChanged(): Set<String> {
    languageVariantDao.replicateOnChange()
    deleteFromChangeLog(10)
    return setOf("LanguageVariant")
  }

  private suspend fun UmAppDatabase.handlePersonGroupChanged(): Set<String> {
    personGroupDao.replicateOnChange()
    personGroupDao.replicateOnChangeClazzBased()
    personGroupDao.replicateOnChangePersonBased()
    personGroupDao.replicateOnChangeSchoolBased()
    deleteFromChangeLog(43)
    return setOf("PersonGroup")
  }

  private suspend fun UmAppDatabase.handlePersonGroupMemberChanged(): Set<String> {
    personGroupMemberDao.replicateOnChange()
    personGroupMemberDao.replicateOnChangeClazzBased()
    personGroupMemberDao.replicateOnChangeSchoolBased()
    deleteFromChangeLog(44)
    return setOf("PersonGroupMember")
  }

  private suspend fun UmAppDatabase.handlePersonPictureChanged(): Set<String> {
    personPictureDao.replicateOnChange()
    deleteFromChangeLog(50)
    return setOf("PersonPicture")
  }

  private suspend fun UmAppDatabase.handleContainerChanged(): Set<String> {
    containerDao.replicateOnChange()
    deleteFromChangeLog(51)
    return setOf("Container")
  }

  private suspend fun UmAppDatabase.handleVerbEntityChanged(): Set<String> {
    verbDao.replicateOnChange()
    deleteFromChangeLog(62)
    return setOf("VerbEntity")
  }

  private suspend fun UmAppDatabase.handleXObjectEntityChanged(): Set<String> {
    xObjectDao.replicateOnChange()
    deleteFromChangeLog(64)
    return setOf("XObjectEntity")
  }

  private suspend fun UmAppDatabase.handleStatementEntityChanged(): Set<String> {
    statementDao.replicateOnChange()
    deleteFromChangeLog(60)
    return setOf("StatementEntity")
  }

  private suspend fun UmAppDatabase.handleContextXObjectStatementJoinChanged(): Set<String> {
    contextXObjectStatementJoinDao.replicateOnChange()
    deleteFromChangeLog(66)
    return setOf("ContextXObjectStatementJoin")
  }

  private suspend fun UmAppDatabase.handleAgentEntityChanged(): Set<String> {
    agentDao.replicateOnChange()
    deleteFromChangeLog(68)
    return setOf("AgentEntity")
  }

  private suspend fun UmAppDatabase.handleStateEntityChanged(): Set<String> {
    stateDao.replicateOnChange()
    deleteFromChangeLog(70)
    return setOf("StateEntity")
  }

  private suspend fun UmAppDatabase.handleStateContentEntityChanged(): Set<String> {
    stateContentDao.replicateOnChange()
    deleteFromChangeLog(72)
    return setOf("StateContentEntity")
  }

  private suspend fun UmAppDatabase.handleXLangMapEntryChanged(): Set<String> {
    xLangMapEntryDao.replicateOnChange()
    deleteFromChangeLog(74)
    return setOf("XLangMapEntry")
  }

  private suspend fun UmAppDatabase.handleSchoolChanged(): Set<String> {
    schoolDao.replicateOnChange()
    deleteFromChangeLog(164)
    return setOf("School")
  }

  private suspend fun UmAppDatabase.handleSchoolMemberChanged(): Set<String> {
    schoolMemberDao.replicateOnChange()
    deleteFromChangeLog(200)
    return setOf("SchoolMember")
  }

  private suspend fun UmAppDatabase.handleCommentsChanged(): Set<String> {
    commentsDao.replicateOnChange()
    deleteFromChangeLog(208)
    return setOf("Comments")
  }

  private suspend fun UmAppDatabase.handleReportChanged(): Set<String> {
    reportDao.replicateOnChangeTemplates()
    deleteFromChangeLog(101)
    return setOf("Report")
  }

  private suspend fun UmAppDatabase.handleSiteChanged(): Set<String> {
    siteDao.replicateOnChange()
    deleteFromChangeLog(189)
    return setOf("Site")
  }

  private suspend fun UmAppDatabase.handleLearnerGroupChanged(): Set<String> {
    learnerGroupDao.replicateOnChange()
    deleteFromChangeLog(301)
    return setOf("LearnerGroup")
  }

  private suspend fun UmAppDatabase.handleLearnerGroupMemberChanged(): Set<String> {
    learnerGroupMemberDao.replicateOnChange()
    deleteFromChangeLog(300)
    return setOf("LearnerGroupMember")
  }

  private suspend fun UmAppDatabase.handleGroupLearningSessionChanged(): Set<String> {
    groupLearningSessionDao.replicateOnChange()
    deleteFromChangeLog(302)
    return setOf("GroupLearningSession")
  }

  private suspend fun UmAppDatabase.handleSiteTermsChanged(): Set<String> {
    siteTermsDao.replicateOnChange()
    deleteFromChangeLog(272)
    return setOf("SiteTerms")
  }

  private suspend fun UmAppDatabase.handleClazzContentJoinChanged(): Set<String> {
    clazzContentJoinDao.replicateOnChange()
    deleteFromChangeLog(134)
    return setOf("ClazzContentJoin")
  }

  private suspend fun UmAppDatabase.handlePersonParentJoinChanged(): Set<String> {
    personParentJoinDao.replicateOnChange()
    deleteFromChangeLog(512)
    return setOf("PersonParentJoin")
  }

  private suspend fun UmAppDatabase.handleScopedGrantChanged(): Set<String> {
    scopedGrantDao.replicateOnChange()
    scopedGrantDao.replicateOnChangeClazzBased()
    scopedGrantDao.replicateOnChangePersonBased()
    scopedGrantDao.replicateOnChangeSchoolBased()
    deleteFromChangeLog(48)
    return setOf("ScopedGrant")
  }

  private suspend fun UmAppDatabase.handleErrorReportChanged(): Set<String> {
    errorReportDao.replicateOnChange()
    deleteFromChangeLog(419)
    return setOf("ErrorReport")
  }

  private suspend fun UmAppDatabase.handleClazzAssignmentChanged(): Set<String> {
    clazzAssignmentDao.replicateOnChange()
    deleteFromChangeLog(520)
    return setOf("ClazzAssignment")
  }

  private suspend fun UmAppDatabase.handleClazzAssignmentContentJoinChanged(): Set<String> {
    clazzAssignmentContentJoinDao.replicateOnChange()
    deleteFromChangeLog(521)
    return setOf("ClazzAssignmentContentJoin")
  }

  private suspend fun UmAppDatabase.handleCourseAssignmentSubmissionChanged(): Set<String> {
    courseAssignmentSubmissionDao.replicateOnChange()
    deleteFromChangeLog(522)
    return setOf("CourseAssignmentSubmission")
  }

  private suspend fun UmAppDatabase.handleCourseAssignmentSubmissionAttachmentChanged():
      Set<String> {
    courseAssignmentSubmissionAttachmentDao.replicateOnChange()
    deleteFromChangeLog(90)
    return setOf("CourseAssignmentSubmissionAttachment")
  }

  private suspend fun UmAppDatabase.handleCourseAssignmentMarkChanged(): Set<String> {
    courseAssignmentMarkDao.replicateOnChange()
    deleteFromChangeLog(523)
    return setOf("CourseAssignmentMark")
  }

  private suspend fun UmAppDatabase.handlePersonAuth2Changed(): Set<String> {
    personAuth2Dao.replicateOnChange()
    deleteFromChangeLog(678)
    return setOf("PersonAuth2")
  }

  private suspend fun UmAppDatabase.handleUserSessionChanged(): Set<String> {
    userSessionDao.updateReplicationTrackers()
    deleteFromChangeLog(679)
    return setOf("UserSession")
  }

  private suspend fun UmAppDatabase.handleCourseBlockChanged(): Set<String> {
    courseBlockDao.replicateOnChange()
    deleteFromChangeLog(124)
    return setOf("CourseBlock")
  }

  private suspend fun UmAppDatabase.handleCourseTerminologyChanged(): Set<String> {
    courseTerminologyDao.replicateOnChange()
    deleteFromChangeLog(450)
    return setOf("CourseTerminology")
  }

  private suspend fun UmAppDatabase.handleCourseGroupSetChanged(): Set<String> {
    courseGroupSetDao.replicateOnChange()
    deleteFromChangeLog(242)
    return setOf("CourseGroupSet")
  }

  private suspend fun UmAppDatabase.handleCourseGroupMemberChanged(): Set<String> {
    courseGroupMemberDao.replicateOnChange()
    deleteFromChangeLog(243)
    return setOf("CourseGroupMember")
  }

  private suspend fun UmAppDatabase.handleCoursePictureChanged(): Set<String> {
    coursePictureDao.replicateOnChange()
    deleteFromChangeLog(125)
    return setOf("CoursePicture")
  }

  private suspend fun UmAppDatabase.handleContentEntryPictureChanged(): Set<String> {
    contentEntryPictureDao.replicateOnChange()
    deleteFromChangeLog(138)
    return setOf("ContentEntryPicture")
  }

  private suspend fun UmAppDatabase.handleChatChanged(): Set<String> {
    chatDao.replicateOnChange()
    deleteFromChangeLog(127)
    return setOf("Chat")
  }

  private suspend fun UmAppDatabase.handleChatMemberChanged(): Set<String> {
    chatMemberDao.replicateOnChange()
    deleteFromChangeLog(128)
    return setOf("ChatMember")
  }

  private suspend fun UmAppDatabase.handleMessageChanged(): Set<String> {
    messageDao.replicateOnChangeChat()
    messageDao.replicateOnChangePosts()
    deleteFromChangeLog(126)
    return setOf("Message")
  }

  private suspend fun UmAppDatabase.handleMessageReadChanged(): Set<String> {
    messageReadDao.replicateOnChange()
    deleteFromChangeLog(129)
    return setOf("MessageRead")
  }

  private suspend fun UmAppDatabase.handleCourseDiscussionChanged(): Set<String> {
    courseDiscussionDao.replicateOnChange()
    deleteFromChangeLog(130)
    return setOf("CourseDiscussion")
  }

  private suspend fun UmAppDatabase.handleDiscussionTopicChanged(): Set<String> {
    discussionTopicDao.replicateOnChange()
    deleteFromChangeLog(131)
    return setOf("DiscussionTopic")
  }

  private suspend fun UmAppDatabase.handleDiscussionPostChanged(): Set<String> {
    discussionPostDao.replicateOnChange()
    deleteFromChangeLog(132)
    return setOf("DiscussionPost")
  }

  public override suspend fun runReplicationRunOnChange(tableNames: Set<String>): Set<String> {
    val _checkPendingNotifications = mutableSetOf<String>()
    _db.withDoorTransactionAsync {
      _transactionDb ->
      if("ClazzLog" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleClazzLogChanged())
      }
      if("ClazzLogAttendanceRecord" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleClazzLogAttendanceRecordChanged())
      }
      if("Schedule" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleScheduleChanged())
      }
      if("HolidayCalendar" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleHolidayCalendarChanged())
      }
      if("Holiday" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleHolidayChanged())
      }
      if("Person" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handlePersonChanged())
      }
      if("Clazz" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleClazzChanged())
      }
      if("ClazzEnrolment" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleClazzEnrolmentChanged())
      }
      if("LeavingReason" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleLeavingReasonChanged())
      }
      if("ContentEntry" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContentEntryChanged())
      }
      if("ContentEntryContentCategoryJoin" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContentEntryContentCategoryJoinChanged())
      }
      if("ContentEntryParentChildJoin" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContentEntryParentChildJoinChanged())
      }
      if("ContentEntryRelatedEntryJoin" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContentEntryRelatedEntryJoinChanged())
      }
      if("ContentCategorySchema" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContentCategorySchemaChanged())
      }
      if("ContentCategory" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContentCategoryChanged())
      }
      if("Language" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleLanguageChanged())
      }
      if("LanguageVariant" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleLanguageVariantChanged())
      }
      if("PersonGroup" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handlePersonGroupChanged())
      }
      if("PersonGroupMember" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handlePersonGroupMemberChanged())
      }
      if("PersonPicture" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handlePersonPictureChanged())
      }
      if("Container" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContainerChanged())
      }
      if("VerbEntity" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleVerbEntityChanged())
      }
      if("XObjectEntity" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleXObjectEntityChanged())
      }
      if("StatementEntity" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleStatementEntityChanged())
      }
      if("ContextXObjectStatementJoin" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContextXObjectStatementJoinChanged())
      }
      if("AgentEntity" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleAgentEntityChanged())
      }
      if("StateEntity" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleStateEntityChanged())
      }
      if("StateContentEntity" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleStateContentEntityChanged())
      }
      if("XLangMapEntry" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleXLangMapEntryChanged())
      }
      if("School" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleSchoolChanged())
      }
      if("SchoolMember" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleSchoolMemberChanged())
      }
      if("Comments" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCommentsChanged())
      }
      if("Report" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleReportChanged())
      }
      if("Site" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleSiteChanged())
      }
      if("LearnerGroup" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleLearnerGroupChanged())
      }
      if("LearnerGroupMember" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleLearnerGroupMemberChanged())
      }
      if("GroupLearningSession" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleGroupLearningSessionChanged())
      }
      if("SiteTerms" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleSiteTermsChanged())
      }
      if("ClazzContentJoin" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleClazzContentJoinChanged())
      }
      if("PersonParentJoin" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handlePersonParentJoinChanged())
      }
      if("ScopedGrant" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleScopedGrantChanged())
      }
      if("ErrorReport" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleErrorReportChanged())
      }
      if("ClazzAssignment" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleClazzAssignmentChanged())
      }
      if("ClazzAssignmentContentJoin" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleClazzAssignmentContentJoinChanged())
      }
      if("CourseAssignmentSubmission" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseAssignmentSubmissionChanged())
      }
      if("CourseAssignmentSubmissionAttachment" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseAssignmentSubmissionAttachmentChanged())
      }
      if("CourseAssignmentMark" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseAssignmentMarkChanged())
      }
      if("PersonAuth2" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handlePersonAuth2Changed())
      }
      if("UserSession" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleUserSessionChanged())
      }
      if("CourseBlock" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseBlockChanged())
      }
      if("CourseTerminology" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseTerminologyChanged())
      }
      if("CourseGroupSet" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseGroupSetChanged())
      }
      if("CourseGroupMember" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseGroupMemberChanged())
      }
      if("CoursePicture" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCoursePictureChanged())
      }
      if("ContentEntryPicture" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleContentEntryPictureChanged())
      }
      if("Chat" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleChatChanged())
      }
      if("ChatMember" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleChatMemberChanged())
      }
      if("Message" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleMessageChanged())
      }
      if("MessageRead" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleMessageReadChanged())
      }
      if("CourseDiscussion" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleCourseDiscussionChanged())
      }
      if("DiscussionTopic" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleDiscussionTopicChanged())
      }
      if("DiscussionPost" in tableNames) {
        _checkPendingNotifications.addAll(_transactionDb.handleDiscussionPostChanged())
      }
      Unit
    }
    return _checkPendingNotifications
  }

  public override suspend fun runOnNewNode(newNodeId: Long): Set<String> {
    _db.withDoorTransactionAsync {
      _transactionDb -> 
      var fnTimeCounter = 0L
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran PersonDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.clazzDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ClazzDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseBlockDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseBlockDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseTerminologyDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseTerminologyDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseGroupSetDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseGroupSetDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseGroupMemberDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseGroupMemberDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.clazzEnrolmentDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ClazzEnrolmentDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.leavingReasonDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran LeavingReasonDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contentEntryDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContentEntryDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contentEntryContentCategoryJoinDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContentEntryContentCategoryJoinDao#replicateOnNewNode in " +
          (systemTimeInMillis() - fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contentEntryParentChildJoinDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContentEntryParentChildJoinDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contentEntryRelatedEntryJoinDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContentEntryRelatedEntryJoinDao#replicateOnNewNode in " +
          (systemTimeInMillis() - fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.clazzContentJoinDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ClazzContentJoinDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contentCategorySchemaDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContentCategorySchemaDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contentCategoryDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContentCategoryDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.languageDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran LanguageDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.languageVariantDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran LanguageVariantDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personGroupDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran PersonGroupDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personGroupDao.replicateOnNewNodeClazzBased(newNodeId)
      Napier.d("Ran PersonGroupDao#replicateOnNewNodeClazzBased in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personGroupDao.replicateOnNewNodePersonBased(newNodeId)
      Napier.d("Ran PersonGroupDao#replicateOnNewNodePersonBased in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personGroupDao.replicateOnNewNodeSchoolBased(newNodeId)
      Napier.d("Ran PersonGroupDao#replicateOnNewNodeSchoolBased in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personGroupMemberDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran PersonGroupMemberDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personGroupMemberDao.replicateOnNewNodeClazzBased(newNodeId)
      Napier.d("Ran PersonGroupMemberDao#replicateOnNewNodeClazzBased in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personGroupMemberDao.replicateOnNewNodeSchoolBased(newNodeId)
      Napier.d("Ran PersonGroupMemberDao#replicateOnNewNodeSchoolBased in " +
          (systemTimeInMillis() - fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personPictureDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran PersonPictureDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.containerDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContainerDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.verbDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran VerbDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.xObjectDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran XObjectDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.reportDao.replicateOnNewNodeTemplates(newNodeId)
      Napier.d("Ran ReportDao#replicateOnNewNodeTemplates in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.statementDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran StatementDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contextXObjectStatementJoinDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContextXObjectStatementJoinDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.stateDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran StateDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.stateContentDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran StateContentDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.agentDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran AgentDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.learnerGroupDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran LearnerGroupDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.learnerGroupMemberDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran LearnerGroupMemberDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.groupLearningSessionDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran GroupLearningSessionDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.clazzLogAttendanceRecordDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ClazzLogAttendanceRecordDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.clazzLogDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ClazzLogDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.scheduleDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ScheduleDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.holidayCalendarDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran HolidayCalendarDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.holidayDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran HolidayDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.schoolDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran SchoolDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.xLangMapEntryDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran XLangMapEntryDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.schoolMemberDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran SchoolMemberDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.clazzAssignmentDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ClazzAssignmentDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.clazzAssignmentContentJoinDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ClazzAssignmentContentJoinDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseAssignmentSubmissionDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseAssignmentSubmissionDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseAssignmentSubmissionAttachmentDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseAssignmentSubmissionAttachmentDao#replicateOnNewNode in " +
          (systemTimeInMillis() - fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseAssignmentMarkDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseAssignmentMarkDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.commentsDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CommentsDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.siteDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran SiteDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.siteTermsDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran SiteTermsDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personParentJoinDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran PersonParentJoinDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.scopedGrantDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ScopedGrantDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.scopedGrantDao.replicateOnNewNodeClazzBased(newNodeId)
      Napier.d("Ran ScopedGrantDao#replicateOnNewNodeClazzBased in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.scopedGrantDao.replicateOnNewNodePersonBased(newNodeId)
      Napier.d("Ran ScopedGrantDao#replicateOnNewNodePersonBased in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.personAuth2Dao.replicateOnNewNode(newNodeId)
      Napier.d("Ran PersonAuth2Dao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.userSessionDao.updateReplicationTrackersOnNewNode(newNodeId)
      Napier.d("Ran UserSessionDao#updateReplicationTrackersOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.coursePictureDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CoursePictureDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.contentEntryPictureDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ContentEntryPictureDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.chatDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ChatDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.chatMemberDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran ChatMemberDao#replicateOnNewNode in " + (systemTimeInMillis() - fnTimeCounter) +
          "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.messageDao.replicateOnNewNodeChats(newNodeId)
      Napier.d("Ran MessageDao#replicateOnNewNodeChats in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.messageDao.replicateOnNewNodePosts(newNodeId)
      Napier.d("Ran MessageDao#replicateOnNewNodePosts in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.messageReadDao.replicateOnNewNodeChats(newNodeId)
      Napier.d("Ran MessageReadDao#replicateOnNewNodeChats in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.courseDiscussionDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran CourseDiscussionDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.discussionTopicDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran DiscussionTopicDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
      fnTimeCounter = systemTimeInMillis()
      _transactionDb.discussionPostDao.replicateOnNewNode(newNodeId)
      Napier.d("Ran DiscussionPostDao#replicateOnNewNode in " + (systemTimeInMillis() -
          fnTimeCounter) + "ms")
    }
    return setOf("Person", "Clazz", "CourseBlock", "CourseTerminology", "CourseGroupSet",
        "CourseGroupMember", "ClazzEnrolment", "LeavingReason", "ContentEntry",
        "ContentEntryContentCategoryJoin", "ContentEntryParentChildJoin",
        "ContentEntryRelatedEntryJoin", "ClazzContentJoin", "ContentCategorySchema",
        "ContentCategory", "Language", "LanguageVariant", "PersonGroup", "PersonGroupMember",
        "PersonPicture", "Container", "VerbEntity", "XObjectEntity", "Report", "StatementEntity",
        "ContextXObjectStatementJoin", "StateEntity", "StateContentEntity", "AgentEntity",
        "LearnerGroup", "LearnerGroupMember", "GroupLearningSession", "ClazzLogAttendanceRecord",
        "ClazzLog", "Schedule", "HolidayCalendar", "Holiday", "School", "XLangMapEntry",
        "SchoolMember", "ClazzAssignment", "ClazzAssignmentContentJoin",
        "CourseAssignmentSubmission", "CourseAssignmentSubmissionAttachment",
        "CourseAssignmentMark", "Comments", "Site", "SiteTerms", "PersonParentJoin", "ScopedGrant",
        "PersonAuth2", "UserSession", "CoursePicture", "ContentEntryPicture", "Chat", "ChatMember",
        "Message", "MessageRead", "CourseDiscussion", "DiscussionTopic", "DiscussionPost")
  }
}
