package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.CourseGroupMemberPerson
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseGroupMemberDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseGroupMemberDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseGroupMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByGroupSetAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> {
    val _result = _dao.findByGroupSetAsync(setUid, clazzUid)
    return _result
  }

  public override suspend fun findByGroupSetOrderedAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> {
    val _result = _dao.findByGroupSetOrderedAsync(setUid, clazzUid)
    return _result
  }

  public override suspend fun findByPersonUid(groupSetUid: Long, studentUid: Long):
      CourseGroupMember? {
    val _result = _dao.findByPersonUid(groupSetUid, studentUid)
    return _result
  }

  public override suspend fun insertListAsync(entityList: List<CourseGroupMember>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CourseGroupMember>): Unit {
    _dao.updateListAsync(entityList)
  }

  public override fun insert(entity: CourseGroupMember): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: CourseGroupMember): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<CourseGroupMember>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<CourseGroupMember>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: CourseGroupMember): Unit {
    _dao.update(entity)
  }
}
