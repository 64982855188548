package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionWithAttachment
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class CourseAssignmentSubmissionDao : BaseDao<CourseAssignmentSubmission> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(entityList: List<CourseAssignmentSubmission>):
      Unit

  public actual abstract fun getAllSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): DataSourceFactory<Int, CourseAssignmentSubmissionWithAttachment>

  public actual abstract suspend fun countFileSubmissionFromStudent(assignmentUid: Long,
      submitterUid: Long): Int

  public actual abstract suspend fun countSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): Int

  public actual abstract fun getStatusOfAssignmentForSubmitter(assignmentUid: Long,
      submitterUid: Long): LiveData<Int>

  public actual abstract suspend fun findLastSubmissionFromStudent(submitterUid: Long,
      assignmentUid: Long): CourseAssignmentSubmission?

  public actual abstract fun checkNoSubmissionsMade(assignmentUid: Long): LiveData<Boolean>
}
