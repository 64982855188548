package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ClazzAssignmentContentJoin
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzAssignmentContentJoinDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ClazzAssignmentContentJoinDao,
) : ClazzAssignmentContentJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findAllContentByClazzAssignmentUidAsync(clazzAssignmentUid: Long,
      personUid: Long): List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> =
      _dao.findAllContentByClazzAssignmentUidAsync(clazzAssignmentUid, personUid)

  public override fun findAllContentByClazzAssignmentUidDF(clazzAssignmentUid: Long,
      personUid: Long):
      DataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> =
      _dao.findAllContentByClazzAssignmentUidDF(clazzAssignmentUid, personUid)

  public override fun insert(entity: ClazzAssignmentContentJoin): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzAssignmentContentJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cacjUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(521)
      entity.cacjUid = _newPk
    }
    entity.cacjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cacjUid
  }

  public override fun insertList(entityList: List<ClazzAssignmentContentJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<ClazzAssignmentContentJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzAssignmentContentJoin): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
