package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ClazzAssignmentRollUp
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ClazzAssignmentRollUpDao : BaseDao<ClazzAssignmentRollUp> {
  public actual abstract suspend fun cacheBestStatements(
    clazzUid: Long,
    assignmentUid: Long,
    personUid: Long,
  ): Unit

  public actual abstract suspend fun deleteCachedInactiveContent(caUid: Long): Unit

  public actual abstract suspend fun invalidateCacheByAssignment(changedAssignmentUid: Long): Unit

  public actual abstract suspend
      fun invalidateCacheByAssignmentList(changedAssignmentUid: List<Long>): Unit
}
