package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ChatMember
import kotlin.Long
import kotlin.Unit

public actual abstract class ChatMemberDao : BaseDao<ChatMember> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit
}
