package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.ContentJobItem
import com.ustadmobile.lib.db.entities.ContentJobItemAndContentJob
import com.ustadmobile.lib.db.entities.ContentJobItemProgress
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContentJobItemDao {
  public actual abstract suspend fun findNextItemsInQueue(contentJobUid: Long, limit: Int):
      List<ContentJobItemAndContentJob>

  public actual abstract suspend fun findActiveContentJobItems(contentEntryUid: Long):
      List<ContentJobItemProgress>

  public actual abstract suspend fun insertJobItem(jobItem: ContentJobItem): Long

  public actual abstract suspend fun insertJobItems(jobItems: List<ContentJobItem>): Unit

  public actual abstract suspend fun updateItemStatus(cjiUid: Long, status: Int): Unit

  public actual abstract suspend fun isJobDone(jobUid: Long): Boolean

  public actual abstract fun findRootJobItemByJobId(jobUid: Long): ContentJobItem?

  public actual abstract suspend fun updateItemProgress(
    cjiUid: Long,
    cjiProgress: Long,
    cjiTotal: Long,
  ): Unit

  public actual abstract suspend fun updateConnectivityNeeded(contentJobItemId: Long,
      connectivityNeeded: Boolean): Unit

  public actual abstract suspend fun updateContainerProcessed(contentJobItemId: Long,
      cjiContainerProcessed: Boolean): Unit

  public actual abstract suspend fun updateJobItemAttemptCountAndStatus(
    cjiUid: Long,
    attemptCount: Int,
    status: Int,
  ): Unit

  public actual abstract suspend fun updateStartTimeForJob(cjiUid: Long, startTime: Long): Unit

  public actual abstract suspend fun updateFinishTimeForJob(cjiUid: Long, finishTime: Long): Unit

  public actual abstract suspend fun updateContentEntryUid(cjiUid: Long, contentEntryUid: Long):
      Unit

  public actual abstract suspend fun updateContentJobItemContainer(cjiUid: Long,
      containerUid: Long): Unit

  public actual abstract suspend fun findAll(): List<ContentJobItem>

  public actual abstract suspend fun findByUidAsync(cjiUid: Long): ContentJobItem?

  public actual abstract suspend fun getActiveContentJobIdByContentEntryUid(contentEntryUid: Long):
      Long

  public actual abstract suspend fun updateUploadSessionUuid(cjiUid: Long,
      uploadSessionUuid: String): Unit

  public actual abstract fun findAllByJobId(jobId: Long): List<ContentJobItem>

  public actual abstract fun getJobItemByUidLive(uid: Long): LiveData<ContentJobItem?>

  public actual abstract suspend fun getContainerUidByJobItemUid(uid: Long): Long

  public actual abstract suspend fun updateAllStatusesByJobUid(jobUid: Long, newStatus: Int): Unit

  public actual abstract suspend fun findAllContainersByJobUid(jobUid: Long): List<Long>
}
