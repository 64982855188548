package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.CoursePicture
import kotlin.Long
import kotlin.Unit

public actual abstract class CoursePictureDao : BaseDao<CoursePicture> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun findByClazzUidAsync(clazzUid: Long): CoursePicture?

  public actual abstract fun findByClazzUidLive(clazzUid: Long): LiveData<CoursePicture?>

  public actual abstract suspend fun updateAsync(coursePicture: CoursePicture): Unit
}
