package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.SchoolMember
import com.ustadmobile.lib.db.entities.SchoolMemberWithPerson
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SchoolMemberDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: SchoolMemberDao,
) : SchoolMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUidAsync(schoolMemberUid: Long): SchoolMember? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun updateAsync(entity: SchoolMember): Int {
    entity.schoolMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override suspend fun findBySchoolAndPersonAndRole(
    schoolUid: Long,
    personUid: Long,
    role: Int,
    timeFilter: Long,
  ): List<SchoolMember> = _dao.findBySchoolAndPersonAndRole(schoolUid, personUid, role, timeFilter)

  public override fun findAllActiveMembersBySchoolAndRoleUid(
    schoolUid: Long,
    role: Int,
    sortOrder: Int,
    searchQuery: String,
    accountPersonUid: Long,
  ): DataSourceFactory<Int, SchoolMemberWithPerson> =
      _dao.findAllActiveMembersBySchoolAndRoleUid(schoolUid, role, sortOrder, searchQuery,
      accountPersonUid)

  public override suspend fun findAllTest(
    schoolUid: Long,
    role: Int,
    searchQuery: String,
  ): List<SchoolMemberWithPerson> = _dao.findAllTest(schoolUid, role, searchQuery)

  public override fun insert(entity: SchoolMember): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: SchoolMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.schoolMemberUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(200)
      entity.schoolMemberUid = _newPk
    }
    entity.schoolMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.schoolMemberUid
  }

  public override fun insertList(entityList: List<SchoolMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<SchoolMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: SchoolMember): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
