package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class LanguageDao : BaseDao<Language> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(languageList: List<Language>): Unit

  public actual abstract fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      DataSourceFactory<Int, Language>

  public actual abstract fun findLanguagesList(): List<Language>

  public actual abstract fun findByName(name: String): Language?

  public actual abstract fun findByTwoCode(langCode: String): Language?

  public actual abstract suspend fun findByTwoCodeAsync(langCode: String): Language?

  public actual abstract fun findByThreeCode(langCode: String): Language?

  public actual abstract fun totalLanguageCount(): Int

  public actual abstract override fun update(entity: Language): Unit

  public actual abstract fun findByUid(primaryLanguageUid: Long): Language?

  public actual abstract suspend fun findByUidAsync(primaryLanguageUid: Long): Language?

  public actual abstract suspend fun updateAsync(entity: Language): Int

  public actual abstract fun findAllLanguageLive(): LiveData<List<Language>>

  public actual abstract fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ): Unit

  public actual abstract fun replaceList(entityList: List<Language>): Unit
}
