package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.Report
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ReportDao : BaseDao<Report> {
  public actual abstract suspend fun replicateOnNewNodeTemplates(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChangeTemplates(): Unit

  public actual abstract fun getResults(query: DoorQuery): List<Report>

  public actual abstract fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): DataSourceFactory<Int, Report>

  public actual abstract suspend fun findByUid(entityUid: Long): Report?

  public actual abstract suspend fun updateAsync(entity: Report): Unit

  public actual abstract fun findByUidLive(uid: Long): LiveData<Report?>

  public actual abstract fun findAllActiveReportLive(isTemplate: Boolean): LiveData<List<Report>>

  public actual abstract fun findAllActiveReportList(isTemplate: Boolean): List<Report>

  public actual abstract fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ): Unit

  public actual abstract fun replaceList(entityList: List<Report>): Unit
}
