package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.DiscussionTopic
import com.ustadmobile.lib.db.entities.DiscussionTopicListDetail
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class DiscussionTopicDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: DiscussionTopicDao,
) : DiscussionTopicDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun getListOfTopicsByDiscussion(discussionUid: Long):
      DataSourceFactory<Int, DiscussionTopicListDetail> =
      _dao.getListOfTopicsByDiscussion(discussionUid)

  public override suspend fun getTopicsByClazz(clazzUid: Long): List<DiscussionTopic> =
      _dao.getTopicsByClazz(clazzUid)

  public override fun getDiscussionTopicByUid(discussionTopicUid: Long): LiveData<DiscussionTopic?>
      = _dao.getDiscussionTopicByUid(discussionTopicUid)

  public override suspend fun updateActiveByUid(
    uid: Long,
    active: Boolean,
    changeTime: Long,
  ): Unit {
    _dao.updateActiveByUid(uid, active, changeTime)
  }

  public override suspend fun replaceListAsync(list: List<DiscussionTopic>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      if(it.discussionTopicUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(131)
        it.discussionTopicUid = _newPk
        _generatedPks += _newPk
      }
      it.discussionTopicLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceListAsync(list)
  }

  public override fun insert(entity: DiscussionTopic): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: DiscussionTopic): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.discussionTopicUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(131)
      entity.discussionTopicUid = _newPk
    }
    entity.discussionTopicLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.discussionTopicUid
  }

  public override fun insertList(entityList: List<DiscussionTopic>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<DiscussionTopic>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: DiscussionTopic): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertListAsync(entityList: List<DiscussionTopic>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.discussionTopicUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(131)
        it.discussionTopicUid = _newPk
        _generatedPks += _newPk
      }
      it.discussionTopicLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<DiscussionTopic>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.discussionTopicLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
