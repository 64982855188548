package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.EntityRole
import com.ustadmobile.lib.db.entities.EntityRoleWithNameAndRole
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class EntityRoleDao {
  public actual abstract suspend fun userHasTableLevelPermission(accountPersonUid: Long,
      permission: Long): Boolean

  public actual abstract suspend fun findByEntitiyAndPersonGroupAndRole(
    tableId: Int,
    entityUid: Long,
    groupUid: Long,
    roleUid: Long,
  ): List<EntityRole>

  public actual abstract fun filterByPersonWithExtra(personGroupUid: Long):
      DataSourceFactory<Int, EntityRoleWithNameAndRole>

  public actual abstract suspend fun filterByPersonWithExtraAsList(personGroupUid: Long):
      List<EntityRoleWithNameAndRole>

  public actual abstract suspend fun findByUidAsync(uid: Long): EntityRole?

  public actual abstract fun findByUidLive(uid: Long): LiveData<EntityRole?>

  public actual abstract suspend fun updateAsync(entity: EntityRole): Int

  public actual abstract suspend fun insertOrReplace(entity: EntityRole): Unit
}
