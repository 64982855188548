package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzLogAttendanceRecordDao_JdbcKt(
  public val _db: RoomDatabase,
) : ClazzLogAttendanceRecordDao() {
  public val _insertAdapterClazzLogAttendanceRecord_:
      EntityInsertionAdapter<ClazzLogAttendanceRecord> = object :
      EntityInsertionAdapter<ClazzLogAttendanceRecord>(_db) {
    public override fun makeSql(returnsId: Boolean) =
        "INSERT INTO ClazzLogAttendanceRecord (clazzLogAttendanceRecordUid, clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ClazzLogAttendanceRecord): Unit {
      if(entity.clazzLogAttendanceRecordUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogAttendanceRecordUid)
      }
      stmt.setLong(2, entity.clazzLogAttendanceRecordClazzLogUid)
      stmt.setLong(3, entity.clazzLogAttendanceRecordPersonUid)
      stmt.setInt(4, entity.attendanceStatus)
      stmt.setLong(5, entity.clazzLogAttendanceRecordMasterChangeSeqNum)
      stmt.setLong(6, entity.clazzLogAttendanceRecordLocalChangeSeqNum)
      stmt.setInt(7, entity.clazzLogAttendanceRecordLastChangedBy)
      stmt.setLong(8, entity.clazzLogAttendanceRecordLastChangedTime)
    }
  }

  public override suspend fun insertListAsync(entities: List<ClazzLogAttendanceRecord>): Unit {
    _insertAdapterClazzLogAttendanceRecord_.insertListAsync(entities)
  }

  public override fun insert(entity: ClazzLogAttendanceRecord): Long {
    val _retVal = _insertAdapterClazzLogAttendanceRecord_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzLogAttendanceRecord): Long {
    val _retVal = _insertAdapterClazzLogAttendanceRecord_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ClazzLogAttendanceRecord>): Unit {
    _insertAdapterClazzLogAttendanceRecord_.insertList(entityList)
  }

  public override suspend fun updateListAsync(entities: List<ClazzLogAttendanceRecord>): Unit {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entities) {
        _stmt.setLong(1, _entity.clazzLogAttendanceRecordClazzLogUid)
        _stmt.setLong(2, _entity.clazzLogAttendanceRecordPersonUid)
        _stmt.setInt(3, _entity.attendanceStatus)
        _stmt.setLong(4, _entity.clazzLogAttendanceRecordMasterChangeSeqNum)
        _stmt.setLong(5, _entity.clazzLogAttendanceRecordLocalChangeSeqNum)
        _stmt.setInt(6, _entity.clazzLogAttendanceRecordLastChangedBy)
        _stmt.setLong(7, _entity.clazzLogAttendanceRecordLastChangedTime)
        _stmt.setLong(8, _entity.clazzLogAttendanceRecordUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun updateList(entityList: List<ClazzLogAttendanceRecord>): Unit {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.clazzLogAttendanceRecordClazzLogUid)
        _stmt.setLong(2, _entity.clazzLogAttendanceRecordPersonUid)
        _stmt.setInt(3, _entity.attendanceStatus)
        _stmt.setLong(4, _entity.clazzLogAttendanceRecordMasterChangeSeqNum)
        _stmt.setLong(5, _entity.clazzLogAttendanceRecordLocalChangeSeqNum)
        _stmt.setInt(6, _entity.clazzLogAttendanceRecordLastChangedBy)
        _stmt.setLong(7, _entity.clazzLogAttendanceRecordLastChangedTime)
        _stmt.setLong(8, _entity.clazzLogAttendanceRecordUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzLogAttendanceRecord): Unit {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzLogAttendanceRecordClazzLogUid)
      _stmt.setLong(2, entity.clazzLogAttendanceRecordPersonUid)
      _stmt.setInt(3, entity.attendanceStatus)
      _stmt.setLong(4, entity.clazzLogAttendanceRecordMasterChangeSeqNum)
      _stmt.setLong(5, entity.clazzLogAttendanceRecordLocalChangeSeqNum)
      _stmt.setInt(6, entity.clazzLogAttendanceRecordLastChangedBy)
      _stmt.setLong(7, entity.clazzLogAttendanceRecordLastChangedTime)
      _stmt.setLong(8, entity.clazzLogAttendanceRecordUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |      SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |             ? AS clarDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2048 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |             JOIN ClazzLogAttendanceRecord 
    |                  ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid
    |       WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = ?), 0) 
    |      /*psql ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |             SET clarPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |      SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |             ? AS clarDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2048 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |             JOIN ClazzLogAttendanceRecord 
    |                  ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid
    |       WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = ?), 0) 
    |       ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |             SET clarPending = true
    |             
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.setLong(1,newNodeId)
      _stmt.setLong(2,newNodeId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |  SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |         UserSession.usClientNodeId AS clarDestination
    |    FROM ChangeLog
    |         JOIN ClazzLogAttendanceRecord 
    |              ON ChangeLog.chTableId = 15 
    |             AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid = ChangeLog.chEntityPk
    |         JOIN ClazzLog
    |              ON ClazzLog.clazzLogUid = ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = UserSession.usClientNodeId), 0) 
    | /*psql ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |     SET clarPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |  SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |         UserSession.usClientNodeId AS clarDestination
    |    FROM ChangeLog
    |         JOIN ClazzLogAttendanceRecord 
    |              ON ChangeLog.chTableId = 15 
    |             AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid = ChangeLog.chEntityPk
    |         JOIN ClazzLog
    |              ON ClazzLog.clazzLogUid = ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = UserSession.usClientNodeId), 0) 
    |  ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |     SET clarPending = true
    |                 
    |    
    |""".trimMargin())) { _stmt -> 
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): ClazzLogAttendanceRecord? =
      _db.prepareAndUseStatement(PreparedStatementConfig("SELECT * from ClazzLogAttendanceRecord WHERE clazzLogAttendanceRecordUid = ?"
      )) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzLogAttendanceRecordUid = _result.getLong("clazzLogAttendanceRecordUid")
        val _tmp_clazzLogAttendanceRecordClazzLogUid =
            _result.getLong("clazzLogAttendanceRecordClazzLogUid")
        val _tmp_clazzLogAttendanceRecordPersonUid =
            _result.getLong("clazzLogAttendanceRecordPersonUid")
        val _tmp_attendanceStatus = _result.getInt("attendanceStatus")
        val _tmp_clazzLogAttendanceRecordMasterChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordMasterChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLocalChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordLocalChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLastChangedBy =
            _result.getInt("clazzLogAttendanceRecordLastChangedBy")
        val _tmp_clazzLogAttendanceRecordLastChangedTime =
            _result.getLong("clazzLogAttendanceRecordLastChangedTime")
        ClazzLogAttendanceRecord().apply {
          this.clazzLogAttendanceRecordUid = _tmp_clazzLogAttendanceRecordUid
          this.clazzLogAttendanceRecordClazzLogUid = _tmp_clazzLogAttendanceRecordClazzLogUid
          this.clazzLogAttendanceRecordPersonUid = _tmp_clazzLogAttendanceRecordPersonUid
          this.attendanceStatus = _tmp_attendanceStatus
          this.clazzLogAttendanceRecordMasterChangeSeqNum =
              _tmp_clazzLogAttendanceRecordMasterChangeSeqNum
          this.clazzLogAttendanceRecordLocalChangeSeqNum =
              _tmp_clazzLogAttendanceRecordLocalChangeSeqNum
          this.clazzLogAttendanceRecordLastChangedBy = _tmp_clazzLogAttendanceRecordLastChangedBy
          this.clazzLogAttendanceRecordLastChangedTime =
              _tmp_clazzLogAttendanceRecordLastChangedTime
        }
      }
    }
  }

  public override suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig("""
  |SELECT ClazzLogAttendanceRecord.*, Person.*
  |         FROM ClazzLogAttendanceRecord 
  |         LEFT JOIN Person ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid
  |         WHERE clazzLogAttendanceRecordClazzLogUid = ?
  """.trimMargin() )) { _stmt -> 
    _stmt.setLong(1,clazzLogUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzLogAttendanceRecordUid = _result.getLong("clazzLogAttendanceRecordUid")
        val _tmp_clazzLogAttendanceRecordClazzLogUid =
            _result.getLong("clazzLogAttendanceRecordClazzLogUid")
        val _tmp_clazzLogAttendanceRecordPersonUid =
            _result.getLong("clazzLogAttendanceRecordPersonUid")
        val _tmp_attendanceStatus = _result.getInt("attendanceStatus")
        val _tmp_clazzLogAttendanceRecordMasterChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordMasterChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLocalChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordLocalChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLastChangedBy =
            _result.getInt("clazzLogAttendanceRecordLastChangedBy")
        val _tmp_clazzLogAttendanceRecordLastChangedTime =
            _result.getLong("clazzLogAttendanceRecordLastChangedTime")
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        ClazzLogAttendanceRecordWithPerson().apply {
          this.clazzLogAttendanceRecordUid = _tmp_clazzLogAttendanceRecordUid
          this.clazzLogAttendanceRecordClazzLogUid = _tmp_clazzLogAttendanceRecordClazzLogUid
          this.clazzLogAttendanceRecordPersonUid = _tmp_clazzLogAttendanceRecordPersonUid
          this.attendanceStatus = _tmp_attendanceStatus
          this.clazzLogAttendanceRecordMasterChangeSeqNum =
              _tmp_clazzLogAttendanceRecordMasterChangeSeqNum
          this.clazzLogAttendanceRecordLocalChangeSeqNum =
              _tmp_clazzLogAttendanceRecordLocalChangeSeqNum
          this.clazzLogAttendanceRecordLastChangedBy = _tmp_clazzLogAttendanceRecordLastChangedBy
          this.clazzLogAttendanceRecordLastChangedTime =
              _tmp_clazzLogAttendanceRecordLastChangedTime
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }
  }

  public override fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long,
  ): Unit {
    _db.prepareAndUseStatement(PreparedStatementConfig("""
    |
    |        UPDATE ClazzLogAttendanceRecord
    |           SET clazzLogAttendanceRecordClazzLogUid = ?,
    |               clazzLogAttendanceRecordLastChangedTime = ?
    |        WHERE clazzLogAttendanceRecordClazzLogUid = ?
    |    
    """.trimMargin() )) { _stmt -> 
      _stmt.setLong(1,newClazzLogUid)
      _stmt.setLong(2,changedTime)
      _stmt.setLong(3,oldClazzLogUid)
      _stmt.executeUpdate()
    }
  }
}
