package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ScopedGrant
import com.ustadmobile.lib.db.entities.ScopedGrantAndName
import com.ustadmobile.lib.db.entities.ScopedGrantWithName
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScopedGrantDao_ReplicateWrapper(
  private val _db: RoomDatabase,
  private val _dao: ScopedGrantDao,
) : ScopedGrantDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _dao.replicateOnChangeClazzBased()
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeClazzBased(newNodeId)
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    _dao.replicateOnChangePersonBased()
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodePersonBased(newNodeId)
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _dao.replicateOnChangeSchoolBased()
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeSchoolBased(newNodeId)
  }

  public override suspend fun insertAsync(scopedGrant: ScopedGrant): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(scopedGrant.sgUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(48)
      scopedGrant.sgUid = _newPk
    }
    scopedGrant.sgLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(scopedGrant)
    return scopedGrant.sgUid
  }

  public override suspend fun insertListAsync(scopedGrantList: List<ScopedGrant>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    scopedGrantList.iterator().forEach  {
      if(it.sgUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(48)
        it.sgUid = _newPk
        _generatedPks += _newPk
      }
      it.sgLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(scopedGrantList)
  }

  public override suspend fun updateAsync(scopedGrant: ScopedGrant): Unit {
    scopedGrant.sgLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(scopedGrant)
  }

  public override suspend fun updateListAsync(scopedGrantList: List<ScopedGrant>): Unit {
    val _generatedPks = mutableListOf<Long>()
    scopedGrantList.iterator().forEach  {
      it.sgLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(scopedGrantList)
  }

  public override suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long):
      List<ScopedGrantAndName> = _dao.findByTableIdAndEntityUid(tableId, entityUid)

  public override fun findByTableIdAndEntityUidWithNameAsDataSource(tableId: Int, entityUid: Long):
      DataSourceFactory<Int, ScopedGrantWithName> =
      _dao.findByTableIdAndEntityUidWithNameAsDataSource(tableId, entityUid)

  public override fun findByTableIdAndEntityIdSync(tableId: Int, entityUid: Long): List<ScopedGrant>
      = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUid(sgUid: Long): ScopedGrant? = _dao.findByUid(sgUid)

  public override fun findByUidLiveWithName(sgUid: Long): LiveData<ScopedGrantWithName?> =
      _dao.findByUidLiveWithName(sgUid)
}
