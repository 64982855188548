package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContainerUidAndMimeType
import com.ustadmobile.lib.db.entities.ContainerWithContentEntry
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual abstract class ContainerDao : BaseDao<Container> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract suspend fun insertListAsync(containerList: List<Container>): Unit

  public actual abstract suspend
      fun getMostRecentDownloadedContainerForContentEntryAsync(contentEntry: Long): Container?

  public actual abstract fun getMostRecentContainerForContentEntry(contentEntryUid: Long):
      Container?

  public actual abstract fun findByUid(uid: Long): Container?

  public actual abstract suspend fun findSizeByUid(uid: Long): Long

  public actual abstract suspend
      fun findRecentContainerToBeMonitoredWithEntriesUid(contentEntries: List<Long>):
      List<Container>

  public actual abstract suspend fun findContainersForContentEntryUid(contentEntryUid: Long):
      List<Container>

  public actual abstract fun hasContainerWithFilesToDownload(contentEntryUid: Long):
      LiveData<Boolean>

  public actual abstract suspend fun findContainerWithFilesByContentEntryUid(contentEntryUid: Long):
      Container?

  public actual abstract fun findAllPublikContainers(): List<Container>

  public actual abstract suspend fun findByUidAsync(containerUid: Long): Container?

  public actual abstract suspend fun updateContainerSizeAndNumEntriesAsync(containerUid: Long,
      changeTime: Long): Unit

  public actual abstract fun findLocalAvailabilityByUid(containerUid: Long): Long

  public actual abstract fun findAllWithId(containerUid: Long): List<Container>

  public actual abstract fun findKhanContainers(): List<ContainerWithContentEntry>

  public actual abstract fun deleteByUid(containerUid: Long): Unit

  public actual abstract fun updateMimeType(mimeType: String, containerUid: Long): Unit

  public actual abstract suspend
      fun getMostRecentContainerForContentEntryAsync(contentEntryUid: Long): Container?

  public actual abstract suspend
      fun getMostRecentContainerUidForContentEntryAsync(contentEntryUid: Long): Long

  public actual abstract suspend
      fun getMostRecentAvailableContainerUidAndMimeType(contentEntryUid: Long,
      downloadRequired: Boolean): ContainerUidAndMimeType?

  public actual abstract fun replaceList(entries: List<Container>): Unit

  public actual abstract fun insertWithReplace(container: Container): Unit

  public actual abstract suspend fun getContainerSizeByUid(containerUid: Long): Long
}
