package com.ustadmobile.core.db.dao

import com.ustadmobile.door.lifecycle.LiveData
import com.ustadmobile.door.paging.DataSourceFactory
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public actual abstract class LeavingReasonDao : BaseDao<LeavingReason> {
  public actual abstract suspend fun replicateOnNewNode(newNodeId: Long): Unit

  public actual abstract suspend fun replicateOnChange(): Unit

  public actual abstract fun findAllReasons(): DataSourceFactory<Int, LeavingReason>

  public actual abstract fun findAllReasonsLive(): List<LeavingReason>

  public actual abstract suspend fun findByUidAsync(uid: Long): LeavingReason?

  public actual abstract suspend fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract fun findByUidLive(uid: Long): LiveData<LeavingReason?>

  public actual abstract suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel>

  public actual abstract suspend fun replaceList(entityList: List<LeavingReason>): Unit

  public actual abstract suspend fun updateAsync(entity: LeavingReason): Int
}
