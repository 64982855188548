package com.ustadmobile.lib.db.entities

import com.ustadmobile.door.attachments.EntityWithAttachment
import kotlin.Int
import kotlin.String

public inline class CoursePicture_EwaAdapter(
  private val entity: CoursePicture,
) : EntityWithAttachment {
  public override var attachmentUri: String?
    get() = entity.coursePictureUri
    set(`value`) {
      entity.coursePictureUri = value
    }

  public override var attachmentMd5: String?
    get() = entity.coursePictureMd5
    set(`value`) {
      entity.coursePictureMd5 = value
    }

  public override var attachmentSize: Int
    get() = entity.coursePictureFileSize
    set(`value`) {
      entity.coursePictureFileSize = value
    }

  public override val tableName: String
    get() = "CoursePicture"
}

public inline fun CoursePicture.asEntityWithAttachment(): EntityWithAttachment =
    CoursePicture_EwaAdapter(this)
